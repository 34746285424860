import {firestore} from '../firebaseIndex'






///////////////////////////////////////////////////////////////////////////////
export const getLastCheckResultByRoom = async (domainId, contractId, groupId, ControlModeType, roomId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('CheckResults')
            .doc(groupId)
            .collection(ControlModeType)
            .where('room_id', "==", roomId)
            .orderBy('end_check', "desc")
            .limit( 1 )
            .get();

        const resultList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        
        if (resultList.length >= 0){
            return resultList[0]
        }else{
            return null;
        }
    } catch (e) {
        console.log("getCheckResultListByRoom error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getSecondaryCheckResult = async (domainId, contractId, groupId, ControlModeType, ParentResultId) => {
    try {
        const res = await firestore.collection('DOMAINS')
        .doc(domainId)
        .collection('contracts')
        .doc(contractId)
        .collection('CheckResults')
        .doc(groupId)
        .collection(ControlModeType)
        .doc(ParentResultId)
        .collection("SecondaryCheckResults")
        .get();

        const resultList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        
        return resultList
    } catch (e) {
        console.log("getSecondaryCheckResult error: ", e)
        return { error: e }
    }
}