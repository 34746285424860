import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, Card, Form, Spinner, ButtonGroup } from 'react-bootstrap';

import writeXlsxFile from 'write-excel-file'
import { addMonths, endOfMonth, format, set, setDate, getDay } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import DateTime from 'react-datetime';

import EditContext from "../contexts/edit.js"
import DomainContext from '../contexts/domainContext.js';
import LocaleContext from '../contexts/locale.js'
import GlobalDataContext from '../contexts/globaldataContext.js';


import { getSelectedElementB, getObjectValue } from "../../util/ui_utils.js"

import AlertSection from "../AlertSection.js"
import { OneTicket } from '../../pages/TicketView.js';
import PrintFooter from '../generic/PrintFooter.js';
import { interventionType_dict } from '../constants/global_definitions.js';

const getTypeLabel = (intervention_data) => {
	const type = intervention_data.type
	if (type === 'ordinary') {
		return 'ordinario'
	} else if (type === 'periodic') {
		return 'periodico'
	} else {
		return 'intervento'
	}
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalExportPlanning = ({ element_list, t }) => {
	const { contract, structureElements, roomtype_dict } = useContext(GlobalDataContext)
	const { domain } = useContext(DomainContext)

	const structure_dict = structureElements.all_structure_dict
	const pavillon_dict = structureElements.all_pavillon_dict
	const floor_dict = structureElements.all_floor_dict
	const department_dict = structureElements.all_department_dict

	const edit = useContext(EditContext)

	const componentRef = useRef();
	const childRefAlert = useRef()

	const [showPrePrint, setShowPrePrint] = useState(false)
	const [filtered_element_list, setFilteredElementList] = useState(element_list)
	const [printParams, setPrintParam] = useState([])
	const [date_range, setDateRange] = useState({ start: new Date, end: addMonths(new Date(), 1) })


	const intervention_type_dict = interventionType_dict()


	const handleShowPrePrint = async () => {
		setShowPrePrint(true)

	}



	const handleClosePrePrint = () => {
		setShowPrePrint(false)
	}


	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle
	});


	const changeSelectedParam = (el_id, param, setParam) => {
		let _param = [...param]
		if (param.indexOf(el_id) >= 0) {
			const c = param.indexOf(el_id)
			_param.splice(c, 1)
		} else {
			_param.push(el_id)
		}
		console.log("UPDATE WA", _param)
		setParam(_param)
	}


	const updateDate = (el) => {
		console.log(el)
	}



	useEffect(() => {


	}, [printParams])

	useEffect(() => {
		setDateRange({
			start: setDate(new Date(), 1),
			end: endOfMonth(set(new Date(), { hours: 23, minutes: 59 }))
		})

	}, [])

	const HEADER_ROW = [
		{ value: 'Inizio' },
		{ value: 'Fine' },
		{ value: 'Intervento' },
		{ value: 'Tipologia' },
		{ value: 'Prestazione' },
		{ value: 'Note' },
		{ value: 'Refill' },
		{ value: "Numero locale" },
		{ value: "struttura" },
		{ value: "padiglione" },
		{ value: "piano" },
		{ value: "reparto" },
		{ value: "Area di rischio" },
		{ value: "cdc" },
		{ value: "dimensione" }
	]
	const COLUMNS = [
		{ width: 15 },
		{ width: 15 },
		{ width: 25 },
		{ width: 25 },
		{ width: 50 },
		{ width: 10 },
		{},
		{ width: 40 },
		{ width: 40 },
		{ width: 40 },
		{ width: 40 },
		{ width: 40 },
		{ width: 10 },
		{},
		{ width: 10 }
	]

	// -----------------------------------------------
	const onHandleExport = async () => {
		let row_list = [HEADER_ROW]
		for (const ev of element_list) {
			let common_data = [
				{
					type: Date,
					format: 'mm/dd/yyyy',
					value: ev.start
				},
				{
					type: Date,
					format: 'mm/dd/yyyy',
					value: ev.end
				},

			]
			if (ev.extendedProps.intervention_data) {
				const int_data = ev.extendedProps.intervention_data
				common_data = [...common_data,
				{
					type: String,
					value: int_data.name + ""
				},
				{
					type: String,
					value: intervention_type_dict[ev.extendedProps.type].title
				},
				{
					type: String,
					value: int_data.service.name
				},
				{
					type: String,
					value: ev.extendedProps.note || ''
				},
				{
					type: Boolean,
					value: int_data.enable_refill ? true : false

				}

				]
				if (int_data.room_list) {
					for (const room of int_data.room_list) {
						const _room = structureElements.all_room_dict[room.id]
						const rt = roomtype_dict[_room.roomtype.id]
						let wd = undefined
						try {
							const i = getSelectedElementB(ev.extendedProps.intervention_data.service.id, roomtype_dict[_room.roomtype.id].service_list, 'service', 'id')
							wd = ev.extendedProps.intervention_data.custom_weekdays && ev.extendedProps.intervention_data.custom_weekdays[room.id] ?
								ev.extendedProps.intervention_data.custom_weekdays[room.id] :
								i.weekdays
						} catch (err) {


						}
						if (wd && wd.indexOf(getDay(ev.start).toString()) !== -1) {
							const room_row = [
								...common_data,
								{
									type: String,
									value: _room.name + ""
								},
								{
									type: String,
									value: getObjectValue(structure_dict, _room.parent_list[0], 'name')
								},
								{
									type: String,
									value: getObjectValue(pavillon_dict, _room.parent_list[1], 'name')
								},
								{
									type: String,
									value: getObjectValue(floor_dict, _room.parent_list[2], 'name')
								},
								{
									type: String,
									value: getObjectValue(department_dict, _room.parent_list[3], 'name')
								},
								{
									type: String,
									value: getObjectValue(_room.risk_area, 'name', 'it')
								},
								{
									type: String,
									value: _room.cdc + ""
								},
								{
									type: String,
									value: _room.size + ""
								}
							]
							console.log(room_row)
							row_list.push(room_row)


						}


					}

				}

			}

		}


		const fileXlsx = await writeXlsxFile(row_list, {
			columns: COLUMNS,
			fileName: `Claudit-${domain}_${contract.name}_planning_interventi_${format(new Date(), 'yyyyMMdd')}.xlsx`
		})

		//const blob = new Blob(fileXlsx)
		//saveAs (blob, "export.xlsx");

	};


	return (
		<>
			<Button variant={"outline-primary"} size="sm" onClick={() => handleShowPrePrint()}>
				<FontAwesomeIcon icon="file-export" />Esporta
			</Button>
			<Modal show={showPrePrint} onHide={handleClosePrePrint} backdrop="static" scrollable={true}>
				<Modal.Header closeButton>
					<AlertSection ref={childRefAlert} />
					<Modal.Title>
						Esportazione interventi
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						Verranno esportati gli interventi attualmente visualizzati
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row className="h4">
							<Col className="text-right">
								<Button variant="secondary" onClick={handleClosePrePrint} className="mr-1">
									{t('modal.close')}
								</Button>
								{element_list && element_list.length > 0 &&
									<Button variant="outline-info" onClick={onHandleExport} ><FontAwesomeIcon icon="file-export" />Esporta xlsx</Button>
								}
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}


const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  div.page-footer{
	display:none;
  }
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
	display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


const pageStyleOld = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
	.preprint_page {
	  width:210mm !important;
	  height: 297mm;
	  margin-top:2mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.print_page {
	  width:210mm !important;
	  height: 297mm;
	  margin-top:2mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	  .single_label {
		width: 50mm;
		height:50mm;
	  }
  div.page-footer{
	display:none;
  }
  @media print {
	html, body, .preprint_page {
	  width:210mm !important;
	  height: 297mm;
	  margin-top:2mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
		display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
	  .single_label {
		width: 50mm !important;
		height: 50mm !important;
	  }
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


