import {firestore} from '../firebaseIndex'

/*
data = {
    name = "string"

}

*/

const addCleaningCompany = async (domainId, contractId, data) => {
    try{
        const res = await firestore.collection('DOMAINS')
                                    .doc(domainId)
                                    .collection('contracts')
                                    .doc(contractId)
                                    .collection("cleaningCompanies")
                                    .add(data)
        
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    }catch(e){
        console.log("addCleaningCompany error:", e)
        return { error: e}
    }
}

const editCleaningCompany = async (domainId, contractId, data) => {
    try{
        const companyId = data.id;
        const company = firestore.collection('DOMAINS')
                                .doc(domainId)
                                .collection('contracts')
                                .doc(contractId)
                                .collection('cleaningCompanies')
                                .doc(companyId);

        const res = await company.set(
            data
        );
        return res;
    }
    catch(e) {
        console.log(e)
        return {error: e}
    }
}

const getCleaningCompanyList = async (domainId, contractId) => {
    const snapshot = await firestore.collection('DOMAINS')
                                    .doc(domainId)
                                    .collection('contracts')
                                    .doc(contractId)
                                    .collection('cleaningCompanies')
                                    .get()
    const CleaningCompanyList = snapshot.docs.map( doc => {
        const firebaseData = doc.data()
        const data = {
            ...firebaseData,
            id: doc.id,
        }
        return data
    })
    return CleaningCompanyList;
}

export {addCleaningCompany};
export {editCleaningCompany};
export {getCleaningCompanyList};

