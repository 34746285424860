import React, { createContext, useState, useEffect } from 'react'

const InterventionreportContext = createContext()

export const InterventionreportProvider = (props) => {
    const [intervention_dict, setInterventionDict] = useState( {} )
    const [used_service_list, setUsedServiceList] = useState([])
    const [requested_interventionreport, setRequestedInterventionreport] = useState(0)
    const [fee_interventionreport, setFeeInterventionreport] = useState(0)

    const addDictIntervention = (intervention) => {
        setInterventionDict((i) => ({...i, [intervention.id]: intervention}))
    }

    
    return (

        <InterventionreportContext.Provider
            value={{
                intervention_dict,
                setInterventionDict,
                used_service_list,
                setUsedServiceList,
                addDictIntervention,
                setRequestedInterventionreport,
                setFeeInterventionreport,
            }}>
        {props.children}

        </InterventionreportContext.Provider>
    )
}
export default InterventionreportContext