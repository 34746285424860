import {
    addStructure,
    addPavillon,
    addFloor,
    addDepartment,
    getStructureById,
    getPavillonList,
    getFloorList,
    getDepartmentList,
    updateDepartment,
    updateFloor,
    updatePavillon,
    updateStructure
} from './dbBaseCompany'
import { addRoom, getRoomList } from './dbRoom'

export const importFullStruct = async (domainId, contractId, allData, itemCounter, cbOne, cbAll) => {

    let myres = null

    let done_room = 0
    const total_room = itemCounter
    // WRITE STRUCTURES ====================================================================================    
    myres = await Object.keys(allData).map(async (struct_pivot, k) => {
        const Structure = allData[struct_pivot]
        let dbStructureData = {id: allData[struct_pivot].id}
        if (allData[struct_pivot].id === undefined) {
            console.log("NEW STRUCTURE")


            const structureData = {
                name: struct_pivot,
                code: '',
                type: {},
                vat: '',
                cf: '',
                address: {
                    street: '',
                    number: '',
                    zip: '',
                    city: '',
                    state: ''
                },
                phone_list: [],
                email: '',
                pec: '',
                website: '',
                logo: '',
                referent_list: [],
                sdi: '',
                note: '',
                parent_list: [],
                mq: Structure.mq,
                size: Structure.mq,
                room_count: Structure.room_count,
                risk_area_summary: Structure.risk_area_summary,
                risk_area_summary_active: Structure.risk_area_summary_active,
                risk_area_summary_suspended: Structure.risk_area_summary_suspended,
                risk_area_summary_decommissioned: Structure.risk_area_summary_decommissioned,
                type_name: "structure",
                level: 0,
                childs: []
            }
            dbStructureData = await addStructure(domainId, contractId, structureData)
        }else{
            console.log("ADD STRUCTURE")
        }


        // WRITE PAVILLONS ====================================================================================
        myres = await Object.keys(Structure.pavillons).map(async (pavillon_pivot, k2) => {
            const Pavillon = Structure.pavillons[pavillon_pivot]
            let pavillonData = {
                name: pavillon_pivot,
                mq: Pavillon.mq,
                size: Pavillon.mq,
                room_count: Pavillon.room_count,
                note: '',
                parent_list: [dbStructureData.id],
                risk_area_summary: Pavillon.risk_area_summary,
                risk_area_summary_active: Pavillon.risk_area_summary_active,
                risk_area_summary_suspended: Pavillon.risk_area_summary_suspended,
                risk_area_summary_decommissioned: Pavillon.risk_area_summary_decommissioned,
                level: 1,
                childs: []
            }
            const dbPavillonData = await addPavillon(domainId, contractId, dbStructureData.id, pavillonData)
            console.log(`PAVILLON ${pavillon_pivot}`)

            // WRITE FLOORS ====================================================================================
            myres = await Object.keys(Pavillon.floors).map(async (floor_pivot, k3) => {
                const Floor = Pavillon.floors[floor_pivot]
                let floorData = {
                    name: floor_pivot,
                    mq: Floor.mq,
                    size: Floor.mq,
                    room_count: Floor.room_count,
                    note: '',
                    parent_list: [dbStructureData.id, dbPavillonData.id],
                    risk_area_summary: Floor.risk_area_summary,
                    risk_area_summary_active: Floor.risk_area_summary_active,
                    risk_area_summary_suspended: Floor.risk_area_summary_suspended,
                    risk_area_summary_decommissioned: Floor.risk_area_summary_decommissioned,
                    level: 2,
                    childs: []
                }
                const dbFloorData = await addFloor(domainId, contractId, dbStructureData.id, dbPavillonData.id, floorData)
                console.log(`PAVILLON ${pavillon_pivot} - FLOOR ${floor_pivot}`)

                // WRITE DEPARTMENTS ====================================================================================                
                myres = await Object.keys(Floor.departments).map(async (department_pivot, k4) => {
                    const Department = Floor.departments[department_pivot]
                    let departmentData = {
                        name: department_pivot,
                        mq: Department.mq,
                        size: Department.mq,
                        room_count: Department.room_count,
                        note: '',
                        parent_list: [dbStructureData.id, dbPavillonData.id, dbFloorData.id],
                        risk_area_summary: Department.risk_area_summary,
                        risk_area_summary_active: Department.risk_area_summary_active,
                        risk_area_summary_suspended: Department.risk_area_summary_suspended,
                        risk_area_summary_decommissioned: Department.risk_area_summary_decommissioned,
                        level: 3,
                        childs: []
                    }

                    const dbDepartmentData = await addDepartment(domainId, contractId, dbStructureData.id, dbPavillonData.id, dbFloorData.id, departmentData)
                    console.log(`PAVILLON ${pavillon_pivot} - FLOOR ${floor_pivot} - DEPARTMENT ${department_pivot}`)

                    // WRITE ROOMS ====================================================================================
                    myres = await Department.rooms.map(async (room, rkey) => {
                        const useRoomType = (room.roomtype !== undefined) && (room.roomtype.id !== undefined)
                        const useRisk = (room.risk_area !== undefined) && (room.risk_area.Id !== undefined)
                        const roomData = {
                            name: room.name,
                            mq: room.mq,
                            size: room.mq,
                            cdc: room.cdc,
                            glass_percentage: room.glass_percentage,
                            note: room.note || '',
                            roomtype: useRoomType ? { id: room.roomtype.id, name: room.roomtype.name } : {},
                            risk_area_summary: [],
                            type_name: "",
                            is_decommissioned: false,
                            level: 4,
                            end_item: true,
                            risk_area: useRisk ? room.risk_area : (useRoomType ? room.roomtype.risk_area : {}),
                            environment_element_list: useRoomType ? room.roomtype.environment_element_list : [],
                            floor: useRoomType ? room.roomtype.floor : {},
                            status: room.status || 'active',
                            floor_status: useRoomType ? room.roomtype.floor_status : {},
                            parent_list: [dbStructureData.id, dbPavillonData.id, dbFloorData.id, dbDepartmentData.id],
                            furniture_list: useRoomType ? room.roomtype.furniture_list : [],
                            economic_material_list: useRoomType ? room.roomtype.economic_material_list : [],
                            check_list: []

                        }

                        console.log(`PAVILLON ${pavillon_pivot} - FLOOR ${floor_pivot} - DEPARTMENT ${department_pivot} - ROOM: ${room.name}`)
                        const dbroom = await addRoom(domainId, contractId, dbStructureData.id, dbPavillonData.id, dbFloorData.id, dbDepartmentData.id, roomData)
                        console.log(`PAVILLON ${pavillon_pivot} - FLOOR ${floor_pivot} - DEPARTMENT ${department_pivot} - ROOM: ${room.name} [${dbroom.id}]`)
                        cbOne(room.name)
                        console.log(Object.keys(allData).length, k, struct_pivot, Department.rooms.length, rkey)
                        done_room += 1
                        console.log(done_room, total_room)
                        if (rkey === Department.rooms.length && k === Object.keys(allData).length) {
                            cbAll()
                        }
                        if (done_room === total_room) {
                            cbAll()
                        }
                    })
                })
            })
        })
    })

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> fine importazione <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
    return "OK"
}


////////////////////////////////////////////////////////////////////////////////////////
export const recalcStructureMq = async (domainId, contractId, structureId) => {

    // READ ALL DATA:
    const dbStructureData = await getStructureById(domainId, contractId, structureId)
    //let structure = {data: dbStructureData,
    //    pavillons: {} }

    const dbPavList = await getPavillonList(domainId, contractId, structureId)
    let pavillonCollect = { risk_area_summary: {}, size: 0, room_count: 0 }

    for (const pavDataIndex in dbPavList) {
        //    structure.pavillons[ pavData.id ] = { data: pavData,
        //                                    floors: {} }
        const pavData = dbPavList[pavDataIndex]
        const dbFloorData = await getFloorList(domainId, contractId, structureId, pavData.id)
        let floorCollect = { risk_area_summary: {}, size: 0, room_count: 0 }

        for (const floorDataIndex in dbFloorData) {
            //    structure.pavillons[ pavData.id ].floors[floorData.id] = { data: floorData,
            //                                                            departments: {} }

            const floorData = dbFloorData[floorDataIndex]
            const dbDepartData = await getDepartmentList(domainId, contractId, structureId, pavData.id, floorData.id)
            let departCollect = { risk_area_summary: {}, size: 0, room_count: 0 }

            for (const departDataIndex in dbDepartData) {

                const departData = dbDepartData[departDataIndex]
                let roomsCollect = { risk_area_summary: {}, size: 0, room_count: 0 }

                const dbRoomData = await getRoomList(domainId, contractId, structureId, pavData.id, floorData.id, departData.id)

                for (const roomDataIndex in dbRoomData) {
                    const roomData = dbRoomData[roomDataIndex]

                    roomsCollect.size += parseFloat(roomData.size)
                    roomsCollect.room_count += 1
                    if (roomsCollect.risk_area_summary[roomData.risk_area.Id]) {
                        roomsCollect.risk_area_summary[roomData.risk_area.Id].size += parseFloat(roomData.size)
                        roomsCollect.risk_area_summary[roomData.risk_area.Id].room_count += 1
                    } else {
                        roomsCollect.risk_area_summary[roomData.risk_area.Id] = {
                            size: parseFloat(roomData.size),
                            room_count: 1,
                            color: roomData.risk_area.color
                        }
                    }

                }
                console.log("roomsCollect: ", roomsCollect)
                // ----> UPDATE DEPARTMENT in db
                updateDepartment(domainId, contractId, structureId, pavData.id, floorData.id, departData.id, roomsCollect)

                departCollect.size += roomsCollect.size
                departCollect.room_count += roomsCollect.room_count
                for (const ra in roomsCollect.risk_area_summary) {
                    if (departCollect.risk_area_summary[ra]) {
                        departCollect.risk_area_summary[ra].size += roomsCollect.risk_area_summary[ra].size
                        departCollect.risk_area_summary[ra].room_count += roomsCollect.risk_area_summary[ra].room_count
                    } else {
                        departCollect.risk_area_summary[ra] = {
                            size: roomsCollect.risk_area_summary[ra].size,
                            room_count: roomsCollect.risk_area_summary[ra].room_count,
                            color: roomsCollect.risk_area_summary[ra].color
                        }
                    }
                }

            }

            console.log("departCollect: ", departCollect)
            // ------> UPDATE FLOOR in db
            updateFloor(domainId, contractId, structureId, pavData.id, floorData.id, departCollect)

            floorCollect.size += departCollect.size
            floorCollect.room_count += departCollect.room_count
            for (const ra in departCollect.risk_area_summary) {
                if (floorCollect.risk_area_summary[ra]) {
                    floorCollect.risk_area_summary[ra].size += departCollect.risk_area_summary[ra].size
                    floorCollect.risk_area_summary[ra].room_count += departCollect.risk_area_summary[ra].room_count
                } else {
                    floorCollect.risk_area_summary[ra] = {
                        size: departCollect.risk_area_summary[ra].size,
                        room_count: departCollect.risk_area_summary[ra].room_count,
                        color: departCollect.risk_area_summary[ra].color
                    }
                }
            }

        }

        console.log("floorCollect: ", floorCollect)
        // ---------> UPDATE PAVILLON in db
        updatePavillon(domainId, contractId, structureId, pavData.id, floorCollect)

        pavillonCollect.size += floorCollect.size
        pavillonCollect.room_count += floorCollect.room_count
        for (const ra in floorCollect.risk_area_summary) {
            if (pavillonCollect.risk_area_summary[ra]) {
                pavillonCollect.risk_area_summary[ra].size += floorCollect.risk_area_summary[ra].size
                pavillonCollect.risk_area_summary[ra].room_count += floorCollect.risk_area_summary[ra].room_count
            } else {
                pavillonCollect.risk_area_summary[ra] = {
                    size: floorCollect.risk_area_summary[ra].size,
                    room_count: floorCollect.risk_area_summary[ra].room_count,
                    color: floorCollect.risk_area_summary[ra].color
                }
            }
        }

    }

    console.log("pavillonCollect: ", pavillonCollect)
    // -----> UPDATE STRUCT in db
    updateStructure(domainId, contractId, structureId, pavillonCollect)

}