import {firestore} from '../firebaseIndex'


///////////////////////////////////////////////////////////////////////////////
export const addBaseObjectType = async (domainId, type, data ) => {
    console.log(domainId, type, data)
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('SETTINGS')
            .doc('baseObjectTypeList')
            .collection(type)
            .add(data);
        const newdata = {
            ...data,
            Id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addBaseObjectType error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getBaseObjectTypeById = async (domainId, type, baseobjectId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('SETTINGS')
            .doc('baseObjectTypeList')
			.collection(type)
			.doc(baseobjectId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            Id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getBaseObjectTypeById error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getBaseObjectTypeList = async (domainId, type) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('SETTINGS')
            .doc('baseObjectTypeList')
            .collection(type)
            .get();

        const baseobjectList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                Id : doc.id,
            }
            return data
        })
        return baseobjectList;  
    } catch (e) {
        console.log("getBaseObjectTypeList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editBaseObjectType = async (domainId, type, baseobjectId, data) => {
    try {
        const baseobject = await  firestore.collection('DOMAINS')
            .doc(domainId).collection('SETTINGS')
            .doc('baseObjectTypeList')
            .collection(type)
            .doc(baseobjectId);

        const res = await baseobject.set(
            data
        ).then( () => {
			console.log("editBaseObjectType - edit successfully")
			return data
		}).catch( error => {
			console.log("editBaseObjectType - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editBaseObjectType error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const delBaseObjectType = async (domainId, type, baseobjectId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('SETTINGS')
            .doc('baseObjectTypeList')
            .collection(type)
            .doc(baseobjectId).delete();
        return res
    }  catch (e) {
        console.log("delBaseObjectType error: ", e)
        return { error: e }
    }
}