import React, { useContext, useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Form, Button, InputGroup, Card, Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext'

import { getFloatNumber, getAmountValue, checkComponentPresence, getValue, getSelectedElement } from "../util/ui_utils"

import AlertSection from "../components/AlertSection.js"
import { ModalInitButton, ServiceBadge } from '../components/StructureGraphicElements.js'
import { payment_mode_dict, servicePaymentModeParams_dict } from './constants/global_definitions';
import EditContractContext from './contexts/editContractContext';


//fee / request

export const ServiceamountList = ({ element, functions, t, contract_month, canWrite, service_list }) => {

	const serviceamount_list = element.serviceamount_list || []
	let totalamount = { fee: 0, year: 0, contract: 0, month: 0 }


	serviceamount_list.forEach(element => {

		try {
			totalamount.month += getAmountValue(getFloatNumber(element.price))
		} catch (err) { }

		try {
			totalamount.year += getAmountValue(element.total_year)
		} catch (err) { }

		try {
			totalamount.contract += getAmountValue(element.total_contract)
		} catch (err) { }
	});


	const handleDeleteServiceamount = (index) => {
		let editlist = [...element.serviceamount_list]
		editlist.splice(index, 1)
		functions.edit({ name: 'serviceamount_list', value: editlist })
	}


	const setServiceAmountList = (editlist) => {
		functions.edit({ name: 'serviceamount_list', value: editlist })
	}

	return (
		<Card className="mb-3">
			<Card.Header>
				<Container fluid>
					<Row >
						<Col className="h4 text-primary">
							{t('global.labels.serviceamount_periodic')}
						</Col>
						<Col className="text-right">
							{canWrite ?
								<ModalServiceAmount t={t} service_list={service_list} is_new={true} canWrite={canWrite} item_list={serviceamount_list} setItem={setServiceAmountList} contract_month={contract_month} />
								: ''}
						</Col>
					</Row>

				</Container>
			</Card.Header>
			<Card.Body>

				<Container fluid>
					{element.serviceamount_list && element.serviceamount_list.length > 0 ?
						<>
							<Row className="mb-1">
								<Col className="bg-secondary text-light ml-1" sm="4">{t('global.labels.name')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('contract.labels.month_value')}</Col>
								<Col className="bg-secondary text-light ml-1">{t('contract.labels.year_value')}</Col>
								<Col className="bg-secondary text-light ml-1">{t('contract.labels.total_value')}<br /><i>({contract_month[0]} mesi)</i></Col>
								<Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
							</Row>
							{element.serviceamount_list.map((i, key) => (
								<Row key={key}>
									<Col sm="4">
										{i.service && i.service.id ?
											<ServiceBadge service={getSelectedElement(i.service.id, service_list, 'id')} freq={true} />
											: ''}
									</Col>
									<Col>
										<CurrencyInput
											name='total_year'
											value={i.price}
											allowDecimals={true}
											decimalsLimit={2}
											decimalSeparator=","
											className="form-control text-right text-dark"
											groupSeparator=" "
											prefix="€"
											disabled={true}
											precision={2}
										/>
									</Col>
									<Col className="text-right">
										<CurrencyInput
											name='total_year'
											value={i.total_year.toFixed(2)}
											allowDecimals={true}
											decimalsLimit={2}
											decimalSeparator=","
											className="form-control text-right text-dark"
											groupSeparator=" "
											prefix="€"
											disabled={true}
											precision={2}
										/>
									</Col>
									<Col className="text-right">
										<CurrencyInput
											name='total_contract'
											value={i.total_contract.toFixed(2)}
											allowDecimals={true}
											decimalsLimit={2}
											decimalSeparator=","
											className="form-control text-right text-dark"
											groupSeparator=" "
											prefix="€"
											disabled={true}
											precision={2}
										/>
									</Col>
									<Col className="text-center" sm="1">
										<ModalServiceAmount t={t} item={i} service_list={service_list} index={key} canWrite={canWrite} item_list={serviceamount_list} setItem={setServiceAmountList} contract_month={contract_month} />
										{canWrite ?
											<Button variant="outline-danger" size="sm" onClick={() => handleDeleteServiceamount(key)}><FontAwesomeIcon icon="trash" /></Button>
											: ''}
									</Col>
								</Row>
							))}
						</>
						:
						<Row>
							<Col className="font-italic">
								{t('global.messages.no_serviceamount')}

							</Col>
						</Row>
					}

				</Container>
			</Card.Body>
			{element.serviceamount_list && element.serviceamount_list.length > 0 ?
				<Card.Footer>

					<Container fluid>
						<Row >
							<Col className="border-right ml-1" sm="4">
								{t('global.labels.total')}
							</Col>
							<Col className="border-right ml-1 text-right" >
								<CurrencyInput
									name='total_contractM'
									value={totalamount.month.toFixed(2)}
									allowDecimals={true}
									decimalsLimit={2}
									decimalSeparator=","
									className="form-control-plaintext text-right text-dark"
									groupSeparator=" "
									prefix="€"
									disabled={true}
									precision={2}
								/>
							</Col>
							<Col className="border-right ml-1 text-right" >
								<CurrencyInput
									name='total_contractY'
									value={totalamount.year.toFixed(2)}
									allowDecimals={true}
									decimalsLimit={2}
									decimalSeparator=","
									className="form-control-plaintext text-right text-dark"
									groupSeparator=" "
									prefix="€"
									disabled={true}
									precision={2}
								/>
							</Col>
							<Col className="border-right ml-1 text-right" >
								<CurrencyInput
									name='total_contractT'
									value={totalamount.contract.toFixed(2)}
									allowDecimals={true}
									decimalsLimit={2}
									decimalSeparator=","
									className="form-control-plaintext text-right text-dark"
									groupSeparator=" "
									prefix="€"
									disabled={true}
									precision={2}
								/>
							</Col>
							<Col className="border-right ml-1" sm="1">
							</Col>

						</Row>

					</Container>
				</Card.Footer>
				: ''}
		</Card>
	)
}






export const ServiceamountHourList = ({ element, functions, t, canWrite, service_list }) => {

	const { structure_list } = useContext(EditContractContext)
	const serviceamounthour_list = element.serviceamounthour_list || []
	const sp = servicePaymentModeParams_dict({ t })
	let total_sum = 0
	serviceamounthour_list.forEach(element => {

		try {
			total_sum += getFloatNumber(element.total)
		} catch (err) { }

	});
	total_sum = total_sum.toFixed(2)

	const newserviceamount = {
		description: '',
		price: 0,
		type: 'request',
		count: 0,
		total: 0
	}



	const handleDeleteServiceamount = (index) => {
		let editlist = [...element.serviceamounthour_list]
		editlist.splice(index, 1)
		functions.edit({ name: 'serviceamounthour_list', value: editlist })
	}


	const setServiceAmountHourList = (editlist) => {
		functions.edit({ name: 'serviceamounthour_list', value: editlist })
	}
	return (
		<Card className="mb-3">
			<Card.Header>
				<Container fluid>
					<Row>
						<Col className="h4 text-primary">
							{t('global.labels.serviceamount_request')}
						</Col>
						<Col className="text-right">
							{canWrite ?
								<ModalServiceAmountHour t={t} is_new={true} service_list={service_list} canWrite={canWrite} item_list={serviceamounthour_list} setItem={setServiceAmountHourList} />
								: ''}
						</Col>
					</Row>

				</Container>
			</Card.Header>
			<Card.Body>
				<Container fluid>
					{element.serviceamounthour_list && element.serviceamounthour_list.length > 0 ?
						<>
							<Row className="mb-1">
								<Col className="bg-secondary text-light ml-1" sm="4">{t('global.labels.name')} </Col>
								<Col className="bg-secondary text-light ml-1">Franchigia nel periodo (per struttura)</Col>
								<Col className="bg-secondary text-light ml-1" sm="2">{t('contract.labels.unit_value')}</Col>
								<Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
							</Row>
							{element.serviceamounthour_list.map((i, key) => (
								<Row key={key} className="border-bottom">
									<Col sm="4">
										{i.service && i.service.id ?
											<ServiceBadge service={getSelectedElement(i.service.id, service_list, 'id')} freq={true} />
											: <>{i.description}</>}
									</Col>
									{sp[i.competence_period] ?
										<Col>
											{structure_list.map((s, k) => (
												<Row key={s.id} className={k< structure_list.length-1? "border-bottom border-info": 'mb-1'}>
													<Col>
														{s.name}
													</Col>
														<Col>
													{i.structure_exemption && i.structure_exemption[s.id] ?
															i.structure_exemption[s.id].exemption_count
														: '0'}&nbsp;
															{payment_mode_dict[i.payment_mode]? payment_mode_dict[i.payment_mode].label_unit: '---'}
															&nbsp;/&nbsp;{sp[i.competence_period].label}

														</Col>
												</Row>
											))}
										</Col>
										:
										<Col></Col>
									}
									<Col sm="2">
										{i.price ?
											<CurrencyInput
												name='total_fee'
												value={parseFloat(i.price).toFixed(2)}
												allowDecimals={true}
												decimalsLimit={2}
												decimalSeparator=","
												className="form-control text-right"
												groupSeparator=" "
												prefix={`€/${payment_mode_dict[i.payment_mode]? payment_mode_dict[i.payment_mode].label_unit_simple: '---'} `}
												disabled={true}
												precision={2}
											/>
											: ''}
									</Col>
									<Col className="text-center" sm="1">
										<ModalServiceAmountHour t={t} item={i} service_list={service_list} index={key} canWrite={canWrite} item_list={serviceamounthour_list} setItem={setServiceAmountHourList} />
										{canWrite ?
											<Button variant="outline-danger" size="sm" onClick={() => handleDeleteServiceamount(key)}><FontAwesomeIcon icon="trash" /></Button>
											: <> </>
										}
									</Col>
								</Row>
							))}
						</>
						:
						<Row>
							<Col className="font-italic">
								{t('global.messages.no_serviceamount')}
							</Col>
						</Row>
					}


				</Container>

			</Card.Body>
		</Card>
	)
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalServiceAmount({ item, setItem, t, is_new, small, item_list, index, canWrite, service_list, contract_month }) {

	const childRefAlert = useRef()

	const newserviceamount = {
		service: {},
		price: 0,
		type: 'periodic',
		count: 0,
		total_year: 0,
		total_contract: 0
	}


	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newserviceamount)
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newserviceamount.media_list = []
		setCurrentElement(newserviceamount)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}



	const onChangeHandlerService = (s_id) => {
		const service = getSelectedElement(s_id, service_list, 'id')
		setCurrentElement({ ...curElement, 'service': { id: s_id, name: service.name } })
	}

	const onChangeHandlerPrice = (value) => {
		const _price = getAmountValue(getFloatNumber(value))
		setCurrentElement({ ...curElement, price: _price, total_year: _price * 12, total_contract: _price * contract_month[0] })
	}

	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		let edit_list = [...item_list]
		if (is_new) {
			edit_list.push(curElement)
		} else {
			edit_list.splice(index, 1, curElement)
			console.log(index, item_list)
		}
		setItem(edit_list)
		setShow(false);
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('serviceamount.buttons.serviceamount_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('serviceamounts.labels.new_serviceamount')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('serviceamounts.labels.edit_serviceamount')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('serviceamounts.labels.view_serviceamount')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.service')} </Form.Label>
							<Col sm="4">
								<Form.Control as="select" className="mb-2" name="service" value={getValue(curElement.service, 'id')} onChange={(event) => onChangeHandlerService(event.target.value)} >
									<option value="-1" >{t('roomtype.labels.select_service')}</option>
									{service_list && service_list.filter(s => s.type === 'fee').length > 0

										? [service_list.filter(s => s.type === 'fee').map((f, k) => (
											<option key={k} value={f.id}>{f.name} </option>
										))]

										: ''
									}
								</Form.Control>
							</Col>
							<Col sm="6">
								{curElement.service ?
									<ServiceBadge service={getSelectedElement(curElement.service.id, service_list, 'id')} freq={true} />
									: ''}
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
							<Form.Label column sm="2">{t('contract.labels.month_value')}</Form.Label>
							<Col sm="4">
								<InputGroup className="mb-2" >
									<Form.Control type="number" name="price" min="0" step="0.1" value={curElement.price} onChange={(event) => onChangeHandlerPrice(event.currentTarget.value)} />
									<InputGroup.Append>
										<InputGroup.Text>
											&euro;
										</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}

	const newserviceamount = {
		service: {},
		description: '',
		custom: false,
		price: 0,
		type: 'request',
		count: 0,
		competence_period: 'month',
		payment_mode: 'activity',
		exemption_count: 0,
		total: 0,
	}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalServiceAmountHour({ item, setItem, t, is_new, small, item_list, index, canWrite, service_list, contract_month }) {

	const childRefAlert = useRef()
	const { structure_list } = useContext(EditContractContext)



	const [showEdit, setShow] = useState(false)
	const [curElement, setCurElement] = useState(newserviceamount)
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newserviceamount.media_list = []
		setCurElement(newserviceamount)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}



	const onChangeHandlerService = (s_id) => {
		const service = getSelectedElement(s_id, service_list, 'id')
		if (service.id) {
			setCurElement({ ...curElement, 'service': { id: s_id, name: service.name } })
		} else {
			setCurElement({ ...curElement, 'service': {} })


		}
	}

	const onChangeHandlerElement = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curElement[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	const onChangeHandlerExemption = (s_id, el) => {
		let { name, value, type } = el
		let editdict = curElement.structure_exemption || {}
		editdict[s_id] = { exemption_count: value }
		console.log(editdict)
		setCurElement({ ...curElement, structure_exemption: editdict })
	}


	const onChangeHandlerPrice = (value) => {
		const _price = getAmountValue(getFloatNumber(value))
		setCurElement({ ...curElement, price: _price })
	}

	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		let edit_list = [...item_list]
		if (is_new) {
			edit_list.push(curElement)
		} else {
			edit_list.splice(index, 1, curElement)
			console.log(index, item_list)
		}
		setItem(edit_list)
		setShow(false);
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('serviceamount.buttons.serviceamount_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('serviceamounts.labels.new_serviceamount_hour')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('serviceamounts.labels.edit_serviceamount_hour')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('serviceamounts.labels.view_serviceamount_hour')}</span>
								</>
							]
						}
						: {curElement.description}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.service')} </Form.Label>
							<Col sm="4">
								<Form.Control as="select" className="mb-2" name="service" value={getValue(curElement.service, 'id')} onChange={(event) => onChangeHandlerService(event.target.value)} >
									<option value="-1" >{t('roomtype.labels.select_service')}</option>
									{service_list && service_list.filter(s => s.type === 'requested').length > 0

										? [service_list.filter(s => s.type === 'requested').map((f, k) => (
											<option key={k} value={f.id}>{f.name} </option>
										))]

										: ''
									}
								</Form.Control>
							</Col>
							<Col sm="6">
								{curElement.service && curElement.service.id ?
									<ServiceBadge service={getSelectedElement(curElement.service.id, service_list, 'id')} freq={true} />
									: ''}

							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.payment_mode')}</Form.Label>
							<Col>
							{Object.values(payment_mode_dict).map((p, k) => (
								<Form.Check type="radio" className="ml-2" name="payment_mode" value={p.id} checked={curElement.payment_mode === p.id} label={p.label} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />

							))}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">
								{payment_mode_dict[curElement.payment_mode]? payment_mode_dict[curElement.payment_mode].label_mode: '--'}
							</Form.Label>
							<Col sm="4">
								<InputGroup className="mb-2" >
									<Form.Control type="number" name="price" min="0" step="0.1" value={curElement.price} onChange={(event) => onChangeHandlerPrice(event.currentTarget.value)} />
									<InputGroup.Append>
										<InputGroup.Text>
											&euro;/
											{payment_mode_dict[curElement.payment_mode]? payment_mode_dict[curElement.payment_mode].label_unit: ''}
										</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Col>
						</Form.Group>
						{console.log("AMOUNT STRU", structure_list)}
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.competence_period')}</Form.Label>
							<Col>
								<Form.Check type="radio" className="ml-2" name="competence_period" value="month" checked={curElement.competence_period === "month"} label={t('service.labels.competence_period_month')} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
								<Form.Check type="radio" className="ml-2" name="competence_period" value="year" checked={curElement.competence_period === "year"} label={t('service.labels.competence_period_year')} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
								<Form.Check type="radio" className="ml-2" name="competence_period" value="contract" checked={curElement.competence_period === "contract"} label={t('service.labels.competence_period_contract')} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">Franchigia nel periodo</Form.Label>
							<Col>
								<Row className="mb-1">
									<Col className="bg-secondary mr-1 text-light">
										Struttura
									</Col>
									<Col className="bg-secondary mr-1 text-light">
									</Col>
								</Row>
								{structure_list.map((s, k) => (
									<Row key={k}>
										<Col>
											{s.name}
										</Col>
										<Col>
											<InputGroup className="mb-2" >
												<Form.Control type="number" name="exemption_count" min="0" step="1" value={curElement.structure_exemption && curElement.structure_exemption[s.id] ? curElement.structure_exemption[s.id].exemption_count : 0} onChange={(event) => onChangeHandlerExemption(s.id, event.currentTarget)} />
												<InputGroup.Append>
													<InputGroup.Text>
											{payment_mode_dict[curElement.payment_mode]? payment_mode_dict[curElement.payment_mode].label_unit: '---'}
													</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

										</Col>
									</Row>

								))}
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} disabled={!curElement.service.id} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



export default ServiceamountList