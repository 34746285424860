import {firestore} from '../firebaseIndex'

const addCustomerSatisfaction = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactions')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addCustomerSatisfaction error: ", e)
        return { error: e }
    }
}

const getCustomerSatisfactionById = async (domainId, contractId, customersatisfactionId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactions')
            .doc(customersatisfactionId)
            .doc();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getCustomerSatisfactionById error: ", e)
        return { error: e }
    }
}

const getCustomerSatisfactionList = async (domainId, contractId, structure ) => {
    try {
        let res = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactions')
        if (structure !== null && structure !== undefined) {
            res = res.where('structure', 'array-contains', structure)
        }
        res = await res.get();

        const customersatisfactionList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return customersatisfactionList;

    } catch (e) {
        console.log("getCustomerSatisfactionList error: ", e)
        return { error: e }
    }
}


const editCustomerSatisfaction = async (domainId, contractId, customersatisfactionId, data) => {
    try {
        const customersatisfaction = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactions')
            .doc(customersatisfactionId);

        const res = await customersatisfaction.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editProduct error: ", e)
        return { error: e }
    }
}


const deleteCustomerSatisfaction = async (domainId, contractId, customersatisfactionId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactions')
            .doc(customersatisfactionId).delete();
        return res
    }  catch (e) {
        console.log("deleteCustomerSatisfaction error: ", e)
        return { error: e }
    }
}




export { addCustomerSatisfaction };
export { getCustomerSatisfactionById };
export { getCustomerSatisfactionList };
export { editCustomerSatisfaction };
export { deleteCustomerSatisfaction };