import React, { createContext, useState, useEffect } from 'react'

const DashboardContext = createContext()

export const DashboardProvider = (props) => {
	const [showCompact, setShowCompact] = useState(false)
	const [loading, setLoading] = useState(true)
	const [loading_step, setLoadingStep] = useState(0)
	const [globalloading, setGlobalLoading] = useState(true)
	const [loadingGraph, setLoadingGraph] = useState(true)
	const [intervention_dict, setInterventionDict] = useState({})
	const [operating_unit, setOperatingUnit] = useState({ done: 0, undone: 0, doing: 0 })
	const [risk_area_summary, setRiskAreaSummary] = useState([[], []])
	const [risk_area_new_summary, setRASummary] = useState({})
	const [today_room_dict, setTodayRoom] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [zoomIndex, setZoomIndex] = useState(2)
	const [event_status, setEventStatus] = useState({})
	const [filterAppliedStructure, setFilterAppliedStructure] = useState()
	const [first_load, setFirstLoad] = useState(true)
	const [structure_list, setStructureList] = useState([])
	const [permission_data, setPermissionData] = useState()
	const [hour_slot_list, setHourSlotList] = useState([])

	const [graphViewMode, setGraphViewMode] = useState('count')

	const [ui_list, setUiList] = useState({})


    
    return (

        <DashboardContext.Provider
            value={{
                intervention_dict, setInterventionDict,
                operating_unit, setOperatingUnit,
                risk_area_new_summary, setRASummary,
                risk_area_summary, setRiskAreaSummary,
                today_room_dict, setTodayRoom,
				loadingGraph, setLoadingGraph,
				filterAppliedStructure, setFilterAppliedStructure,
				structure_list, setStructureList,
				permission_data, setPermissionData,
				event_status, setEventStatus,
				hour_slot_list, setHourSlotList,
				loading_step, setLoadingStep
            }}>
        {props.children}

        </DashboardContext.Provider>
    )
}
export default DashboardContext