import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addCtrlTool = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('controltools')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addCtrlTool error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const getCtrlToolById = async (domainId, contractId, ctrltoolId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('controltools')
            .get(ctrltoolId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getCtrlTool error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getCtrlToolList = async (domainId, contractId, subject=null) => {
    try {
        if (subject === null || subject === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('controltools')
                .get();

            const ctrltoolList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return ctrltoolList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('controltools')
                .where('subject', '==', subject)
                .get();

            const ctrltoolList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return ctrltoolList;
        }
    } catch (e) {
        console.log("getCtrlToolList error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const editCtrlTool = async (domainId, contractId, ctrltoolId, data) => {
    try {
        const ctrltool =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('controltools')
            .doc(ctrltoolId);

        const res = await ctrltool.set(data)
            .then( () => {
                console.log("editCtrlTool - edit successfully")
                return data
            }).catch( error => {
                console.log("editCtrlTool - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editCtrlTool error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteCtrlTool = async (domainId, contractId, ctrltoolId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('controltools')
            .doc(ctrltoolId).delete();
        return res
    }  catch (e) {
        console.log("deleteCtrlTool error: ", e)
        return { error: e }
    }
}



