export default {
    disciplinare: {
        paths:["M30,17.1c0-0.1,0-0.1,0-0.2l-4.6-8.1c-0.1-0.3-0.6-0.3-0.7,0l-4.6,8.1c0,0.1,0,0.1,0,0.2h0c0.6,2.2,2.6,3.9,5,3.9S29.4,19.3,30,17.1L30,17.1z M20.9,17.1L25,9.8l4.1,7.3H20.9z",
        "M2,17.1C2.6,19.3,4.6,21,7,21s4.4-1.7,5-3.9h0c0-0.1,0-0.1,0-0.2L7.3,8.8c-0.1-0.3-0.6-0.3-0.7,0l-4.6,8.1C2,17,2,17,2,17.1L2,17.1z M2.9,17.1L7,9.8l4.1,7.3H2.9z",
        "M26.8,8.8c-0.1-0.4-0.5-0.7-1-0.6c-1.4,0.3-2.1-0.2-3.1-0.9c-1.2-0.9-2.8-2-5.7-1.4V5.7c0.6-0.3,1-1,1-1.7c0-1.1-0.9-2-2-2s-2,0.9-2,2c0,0.7,0.4,1.4,1,1.7v0.2c-2.9-0.6-4.4,0.5-5.7,1.4C8.3,8,7.6,8.6,6.2,8.2c-0.4-0.1-0.9,0.2-1,0.6c-0.1,0.4,0.2,0.9,0.6,1C6.2,9.9,6.6,9.9,7,9.9c1.4,0,2.4-0.7,3.3-1.3c1.2-0.9,2.3-1.6,4.7-1V23l-3,3c-0.6,0-1,0.4-1,1s0.4,1,1,1h8c0.6,0,1-0.4,1-1s-0.4-1-1-1l-3-3V7.6c2.4-0.6,3.6,0.2,4.7,1c0.9,0.6,1.8,1.3,3.3,1.3c0.4,0,0.8,0,1.2-0.2C26.6,9.7,26.9,9.2,26.8,8.8z"
        ],
        viewBox:"0 0 32 40",
        style:"enable-background:new 0 0 32 40;"
    },
    comunicazioni:{
        viewBox:"0 0 100 125",
        style:"enable-background:new 0 0 100 125;",
        polygons:["31.4,47 75,47 75,55.4 94.1,36.7 75,18 75,26.5 31.4,26.5 	",
                "25.6,74.3 69.1,74.3 69.1,53.8 25.6,53.8 25.6,45.3 6.5,64 25.6,82.7"]
       },
    documenti:{
        viewBox:"0 0 50 62.5",
        style:"enable-background:new 0 0 50 62.5;",
        paths:["M18.3,3.9H3.9c-1.4,0-2.5,1.1-2.5,2.5v37.1c0,1.4,1.1,2.5,2.5,2.5h14.4c1.4,0,2.5-1.1,2.5-2.5V6.4C20.8,5.1,19.7,3.9,18.3,3.9z M11.1,42.1c-2.4,0-4.4-2-4.4-4.4c0-2.4,2-4.4,4.4-4.4s4.4,2,4.4,4.4C15.5,40.1,13.5,42.1,11.1,42.1zM16.8,27.8c0,1.4-1.1,2.5-2.5,2.5H7.9c-1.4,0-2.5-1.1-2.5-2.5V10.4c0-1.4,1.1-2.5,2.5-2.5h6.4c1.4,0,2.5,1.1,2.5,2.5V27.8z",
            "M14.3,8.9H7.9c-0.8,0-1.5,0.7-1.5,1.5v17.4c0,0.8,0.7,1.5,1.5,1.5h6.4c0.8,0,1.5-0.7,1.5-1.5V10.4c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5H8.4c-0.3,0-0.5-0.2-0.5-0.5S8.2,20.6,8.4,20.6z M13.7,25.6H8.6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h5.2c0.3,0,0.5,0.2,0.5,0.5S14,25.6,13.7,25.6z",
            "M11.1,34.3c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4C14.5,35.8,13,34.3,11.1,34.3z",
            "M46.5,5.3L32.3,3.1c-1.4-0.2-2.6,0.8-2.8,2.1l-5.6,36.7c-0.2,1.4,0.7,2.6,2.1,2.8l14.2,2.2c0.1,0,0.3,0,0.4,0c1.2,0,2.3-0.9,2.5-2.1l5.6-36.7C48.8,6.8,47.8,5.5,46.5,5.3z M38.6,38.2c-0.3,2.2-2.2,3.7-4.4,3.7c-0.2,0-0.4,0-0.7-0.1c-1.2-0.2-2.2-0.8-2.9-1.8c-0.7-0.9-1-2.1-0.8-3.3c0.4-2.4,2.6-4.1,5-3.7c1.2,0.2,2.2,0.8,2.9,1.7C38.5,35.9,38.8,37.1,38.6,38.2zM44,11.4l-2.6,17.2c-0.2,1.2-1.2,2.1-2.5,2.1c-0.1,0-0.3,0-0.4,0l-6.3-1c-1.4-0.2-2.3-1.5-2.1-2.8l2.6-17.2c0.2-1.3,1.5-2.3,2.8-2.1l6.3,1c0.7,0.1,1.2,0.5,1.6,1C44,10.1,44.1,10.7,44,11.4z",
            "M34.8,34.2c-0.2,0-0.4,0-0.5,0c-1.7,0-3.1,1.2-3.4,2.9c-0.1,0.9,0.1,1.8,0.6,2.5c0.5,0.7,1.3,1.2,2.2,1.4c1.9,0.3,3.6-1,3.9-2.9c0.1-0.9-0.1-1.8-0.6-2.5C36.5,34.8,35.7,34.3,34.8,34.2z",
            "M41.8,9.5l-6.3-1c-0.1,0-0.2,0-0.2,0c-0.7,0-1.4,0.5-1.5,1.3L31.1,27c-0.1,0.8,0.4,1.6,1.3,1.7l6.3,1c0.8,0.1,1.6-0.5,1.7-1.3L43,11.3c0.1-0.4,0-0.8-0.3-1.1C42.5,9.8,42.2,9.6,41.8,9.5z M38.8,26C38.8,26,38.7,26,38.8,26l-5.2-0.8c-0.3,0-0.5-0.3-0.4-0.6c0-0.3,0.3-0.5,0.6-0.4l5.1,0.8c0.3,0,0.5,0.3,0.4,0.6C39.2,25.8,39,26,38.8,26z M39.3,22C39.3,22,39.2,22,39.3,22l-5.2-0.8c-0.3,0-0.5-0.3-0.4-0.6c0-0.3,0.3-0.5,0.6-0.4l5.1,0.8c0.3,0,0.5,0.3,0.4,0.6C39.7,21.8,39.5,22,39.3,22z M39.9,18C39.9,18,39.8,18,39.9,18l-5.2-0.8c-0.3,0-0.5-0.3-0.4-0.6c0-0.3,0.3-0.5,0.6-0.4L40,17c0.3,0,0.5,0.3,0.4,0.6C40.3,17.9,40.1,18,39.9,18z M40.5,14.1C40.5,14.1,40.4,14.1,40.5,14.1l-5.2-0.8c-0.3,0-0.5-0.3-0.4-0.6c0-0.3,0.3-0.5,0.6-0.4l5.1,0.8c0.3,0,0.5,0.3,0.4,0.6C41,13.9,40.7,14.1,40.5,14.1z"]
    },
    impostazioni:{
        viewBox:"0 0 100 125",
        style:"enable-background:new 0 0 100 125;",
        paths:["M44.4,63.6c0,4.5-3.6,8.1-8.1,8.1c-4.5,0-8.1-3.6-8.1-8.1s3.6-8.1,8.1-8.1c0,0,0,0,0,0C40.8,55.6,44.4,59.2,44.4,63.6z"+
	 "M4.4,57.4c-1.9,0.3-3.4,2-3.4,4v4.5c0,2,1.4,3.7,3.4,4l5.3,0.9c0.6,2.3,1.5,4.5,2.7,6.6l-3.1,4.4c-1.1,1.6-0.9,3.8,0.5,5.2l3.2,3.2"+
     "c1.4,1.4,3.6,1.6,5.2,0.4l4.4-3.1c2.1,1.2,4.3,2.1,6.6,2.7l0.9,5.3c0.3,1.9,2,3.4,4,3.4h4.5c2,0,3.7-1.4,4-3.4l0.9-5.3"+
     "c2.3-0.6,4.5-1.5,6.6-2.7l4.4,3.1c1.6,1.1,3.8,1,5.2-0.4L63,87c1.4-1.4,1.6-3.6,0.5-5.2l-3.1-4.4c1.2-2.1,2.1-4.3,2.7-6.6l5.3-0.9"+
     "c1.9-0.3,3.4-2,3.4-4v-3h-2.4c-4.5,0-8.3-3.2-9.2-7.6c-2.8,1.9-6.5,2.1-9.5,0.6c4.3,7.9,1.3,17.7-6.6,22c-7.9,4.3-17.7,1.3-22-6.6"+
     "s-1.3-17.7,6.6-22c4.8-2.6,10.6-2.6,15.4,0c-1.6-3-1.3-6.7,0.6-9.5c-4.4-0.8-7.6-4.7-7.6-9.2v-2.4h-3c-2,0-3.7,1.4-4,3.4l-0.9,5.3"+
     "c-2.3,0.6-4.5,1.5-6.6,2.7l-4.4-3.1c-1.6-1.1-3.8-0.9-5.1,0.5l-3.2,3.2c-1.4,1.4-1.6,3.6-0.4,5.2l3.1,4.4c-1.2,2.1-2.1,4.3-2.7,6.6"+
     "L4.4,57.4z M99,30.7c0,1.6-1.1,2.9-2.7,3.2l-4.2,0.7c-0.5,1.8-1.2,3.6-2.2,5.2l2.4,3.5c0.9,1.3,0.7,3-0.4,4.1l-2.5,2.5"+
     "c-1.1,1.1-2.8,1.3-4.1,0.4L82,47.8c-1.6,0.9-3.4,1.7-5.2,2.2L76,54.1c-0.3,1.5-1.6,2.7-3.2,2.7h-3.5c-1.6,0-2.9-1.1-3.2-2.7"+
     "l-0.7-4.2c-1.8-0.5-3.6-1.2-5.2-2.2l-3.5,2.4c-1.3,0.9-3,0.7-4.1-0.4l-2.5-2.5c-1.1-1.1-1.3-2.8-0.4-4.1l2.4-3.5"+
     "c-0.9-1.6-1.7-3.4-2.2-5.2l-4.2-0.7c-1.5-0.3-2.7-1.6-2.7-3.2v-3.5c0-1.5,1.1-2.9,2.7-3.1l4.2-0.7c0.5-1.8,1.2-3.6,2.2-5.3l-2.4-3.5"+
     "c-0.9-1.3-0.7-3,0.4-4.1l2.5-2.5c1.1-1.1,2.8-1.3,4.1-0.4l3.5,2.5c1.6-0.9,3.4-1.7,5.2-2.2l0.7-4.2C66.4,2.1,67.8,1,69.3,1h3.5"+
     "c1.5,0,2.9,1.1,3.1,2.7l0.7,4.2c1.8,0.5,3.6,1.2,5.3,2.2l3.5-2.4c1.3-0.9,3-0.7,4.1,0.4l2.5,2.5c1.1,1.1,1.3,2.8,0.4,4.1L90,18"+
     "c0.9,1.6,1.7,3.4,2.2,5.2l4.2,0.7c1.5,0.3,2.7,1.6,2.7,3.2V30.7z M80.7,28.9c0-5.3-4.3-9.6-9.6-9.6s-9.6,4.3-9.6,9.6"+
     "s4.3,9.6,9.6,9.6S80.7,34.2,80.7,28.9L80.7,28.9z"]
    },

    report:{
        viewBox:"0 0 100 125",
        style:"enable-background:new 0 0 100 125;",
        paths:[
            "M77,20.5V7.6c0-1.1-0.9-2-2-2H25c-1.1,0-2,0.9-2,2v12.9h4V9.6h46v10.9H77z",
            
            "M85.5,24.5h-71C9.3,24.5,5,28.7,5,34c0,0,0,0,0,0v28c0,5.3,4.3,9.5,9.5,9.5c0,0,0,0,0,0h5l2.8-17.1c0.5-2.9,3-5,5.9-5h43.5"+
	        "c2.9,0,4.7-0.6,5.9,5l2.8,17.1h5c5.3,0,9.5-4.3,9.5-9.5V34C95,28.7,90.7,24.5,85.5,24.5z M84.5,38.5c-2,0-3.5-1.6-3.5-3.5"+
            "s1.6-3.5,3.5-3.5c2,0,3.5,1.6,3.5,3.5c0,0,0,0,0,0C88,36.9,86.4,38.5,84.5,38.5C84.5,38.5,84.5,38.5,84.5,38.5L84.5,38.5z",
            
            "M36.6,57.9h26.8c0.5,0,0.9,0.4,0.9,0.9V61c0,0.5-0.4,0.9-0.9,0.9l0,0H36.6c-0.5,0-0.9-0.4-0.9-0.9v0v-2.1"+
            "C35.7,58.4,36.1,57.9,36.6,57.9z",
            
            "M35.5,68.8h29.1c0.5,0,0.9,0.4,0.9,0.9l0,0v2.1c0,0.5-0.4,0.9-0.9,0.9l0,0H35.5c-0.5,0-0.9-0.4-0.9-0.9c0,0,0,0,0,0v-2.1"+
            "C34.5,69.2,34.9,68.8,35.5,68.8C35.5,68.8,35.5,68.8,35.5,68.8z",
    
            "M34.3,79.6h31.4c0.5,0,0.9,0.4,0.9,0.9l0,0v2.1c0,0.5-0.4,0.9-0.9,0.9l0,0H34.3c-0.5,0-0.9-0.4-0.9-0.9l0,0v-2.1"+
            "C33.4,80,33.8,79.6,34.3,79.6L34.3,79.6z",
    
            "M28.2,53.4c-1,0-1.8,0.7-2,1.7l-6.2,37c-0.2,1.1,0.6,2.1,1.6,2.3c0.1,0,0.2,0,0.3,0h55.8c1.1,0,2-0.9,2-2c0-0.1,0-0.2,0-0.3",
	        "l-6.2-37c-0.2-1-1-1.7-2-1.7h-2.4l6.2,37H24.4l6.2-37H28.2z"
        ]
    },

    servizi:{
        viewBox:"0 0 100 125",
        style:"enable-background:new 0 0 100 125;",
		paths:["M27.4,25.2c2.3,0,3.4-1.2,3.4-3.4V8.4c0-2.3-1.2-3.4-3.4-3.4S24,6.2,24,8.4v13.3C24,24.1,25.1,25.2,27.4,25.2z",

		"M72.6,25.2c2.3,0,3.4-1.2,3.4-3.4V8.4C76,6.2,74.9,5,72.6,5c-2.3,0-3.4,1.2-3.4,3.4v13.3C69.2,24.1,70.3,25.2,72.6,25.2z",

		"M36.7,51.7h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7C34.5,51,35.1,51.7,36.7,51.7"+
	    "z",

		"M55.1,51.7h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7C52.8,51,53.4,51.7,55.1,51.7"+
	    "z",

		"M73.4,51.7h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7C71.1,51,71.8,51.7,73.4,51.7"+
	    "z",

		"M18.4,67.7h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7"+
	    "C16.1,67.1,16.8,67.7,18.4,67.7z",

		"M36.7,67.7h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7"+
	    "C34.5,67.1,35.1,67.7,36.7,67.7z",
    
		"M55.1,67.7h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7"+
	    "C52.8,67.1,53.4,67.7,55.1,67.7z",

		"M73.4,67.7h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7"+
	    "C71.1,67.1,71.8,67.7,73.4,67.7z",

		"M18.4,83.8h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7"+
	    "C16.1,83.1,16.8,83.8,18.4,83.8z",

		"M36.7,83.8h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7"+
	    "C34.5,83.1,35.1,83.8,36.7,83.8z",

		"M55.1,83.8h8c1.6,0,2.3-0.6,2.3-2.3v-5.7c0-1.6-0.6-2.3-2.3-2.3h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7"+
	    "C52.8,83.1,53.4,83.8,55.1,83.8z",

		"M81.6,73.5h-8c-1.6,0-2.3,0.6-2.3,2.3v5.7c0,1.6,0.6,2.3,2.3,2.3h8c1.6,0,2.3-0.6,2.3-2.3v-5.7"+
	    "C83.9,74.1,83.2,73.5,81.6,73.5z",

		"M84.7,12.3h-6.9v4.7c1.6,1.4,2.6,3.5,2.6,5.8c0,4.3-3.5,7.7-7.7,7.7c-4.3,0-7.7-3.5-7.7-7.7c0-2.3,1-4.3,2.6-5.8v-4.7H32.6"+
	    "v4.7c1.6,1.4,2.6,3.5,2.6,5.8c0,4.3-3.5,7.7-7.7,7.7c-4.3,0-7.7-3.5-7.7-7.7c0-2.3,1-4.3,2.6-5.8v-4.7h-6.9C7.9,12.3,5,15.2,5,22.6"+
	    "v8.8V36v48.7C5,92.1,7.9,95,15.3,95h69.4C92.1,95,95,92.1,95,84.7V36v-4.6v-8.8C95,15.2,92.1,12.3,84.7,12.3z M89.3,85.8"+
	    "c0,2.5-1,3.4-3.4,3.4H14.2c-2.5,0-3.4-1-3.4-3.4V35.4h78.5C89.3,35.4,89.3,85.8,89.3,85.8z"]
    },

    superfici:{
        viewBox:"0 0 100 125",
        style:"enable-background:new 0 0 100 125;",
        paths:["M51.8,37.4c-1.2,0.4-3.3,0.4-4.5,0l-8-2.9c-1.1-0.4-1-1.1,0.2-1.5l8-2.6c1.1-0.4,3-0.4,4.1,0l8,2.6"+
			"c1.2,0.4,1.3,1.1,0.2,1.5L51.8,37.4z",
            
            "M68.8,43.4c-1.2,0.5-3.4,0.5-4.9,0l-9.3-3.3c-1.3-0.5-1.4-1.2-0.1-1.7l8-3c1.1-0.4,3.1-0.4,4.4,0l9.2,3"+
			"c1.4,0.5,1.7,1.2,0.6,1.7L68.8,43.4z",
            
            "M88.3,50.2c-1.2,0.6-3.6,0.6-5.3,0l-10.8-3.9c-1.5-0.5-1.7-1.4-0.5-2l7.9-3.4c1.1-0.5,3.2-0.5,4.7,0L95,44.4"+
			"c1.6,0.5,2.1,1.4,1,2L88.3,50.2z",
            
            "M36.2,43.1c-1.4,0.5-3.6,0.5-4.9,0l-8-3.3c-1.1-0.5-0.9-1.2,0.5-1.7l9.1-2.9c1.3-0.4,3.3-0.4,4.4,0l8,2.9"+
			"c1.2,0.5,1.2,1.2-0.1,1.7L36.2,43.1z",
            
            "M53.3,50.1c-1.4,0.6-3.8,0.6-5.3,0l-9.3-3.8c-1.3-0.5-1.2-1.4,0.2-1.9l9.2-3.4c1.3-0.5,3.4-0.5,4.7,0l9.3,3.4"+
			"c1.4,0.5,1.6,1.4,0.3,1.9L53.3,50.1z",
		
            "M73,58c-1.4,0.7-4,0.7-5.7,0l-10.9-4.5c-1.5-0.6-1.6-1.7-0.2-2.3l9.2-3.9c1.3-0.6,3.6-0.6,5.1,0l10.8,3.9"+
			"c1.7,0.6,2,1.6,0.8,2.3L73,58z",
            
            "M17,50c-1.7,0.6-4,0.6-5.2,0L4,46.2c-1.1-0.5-0.6-1.4,1-1.9l10.5-3.4c1.5-0.5,3.6-0.5,4.7,0l8,3.4c1.2,0.5,1,1.4-0.5,1.9"+
			"L17,50z",
            
            "M33.9,58.2c-1.7,0.7-4.3,0.7-5.7,0L19,53.6c-1.3-0.6-1-1.7,0.7-2.3l10.7-4c1.5-0.6,3.8-0.6,5.1,0l9.3,4"+
			"c1.4,0.6,1.4,1.6-0.1,2.3L33.9,58.2z",
            
            "M53.7,67.6c-1.7,0.9-4.5,0.9-6.2,0l-10.9-5.4c-1.5-0.8-1.4-2,0.3-2.7l10.8-4.7c1.5-0.7,4-0.7,5.5,0l10.9,4.7"+
			"c1.7,0.7,1.9,1.9,0.4,2.7L53.7,67.6z"]
    },

    verifiche:{
        viewBox:"0 0 100 125",
        style:"enable-background:new 0 0 100 125;",
        paths:["M94.3,82.3L61.9,55.4c10.4-14,7.5-33.8-6.6-44.2s-33.8-7.5-44.2,6.6s-7.5,33.8,6.6,44.2c11.2,8.3,26.4,8.3,37.6,0l26.9,32.4"+
	        "C85.5,98,98,85.5,94.3,82.3z M18.3,54.8c-10.1-10.1-10.1-26.4,0-36.5s26.4-10.1,36.5,0s10.1,26.4,0,36.5c0,0,0,0,0,0"+
            "C44.7,64.9,28.4,64.9,18.3,54.8z",
    
        "M47.8,18c-1.7-1-3.5-1.8-5.3-2.3c-7.6-2.1-15.7,0-21.3,5.5c-1.3,1.3-2.5,2.8-3.4,4.4c-0.6,1-0.2,2.2,0.7,2.7"+
	    "c1,0.6,2.2,0.2,2.7-0.7c0,0,0,0,0,0C22,26.3,23,25.1,24,24c4.5-4.5,11.2-6.3,17.4-4.5c1.5,0.4,3,1.1,4.4,1.9"+
	    "c0.9,0.6,2.2,0.3,2.7-0.7C49.1,19.8,48.8,18.6,47.8,18L47.8,18z"]
    },

    anagrafiche:{
        viewBox:"0 0 100 125",
        style:"enable-background:new 0 0 100 125;",
        paths:["M35,36v-8H11c-1.1,0-2,0.9-2,2v50c0,1.1,0.9,2,2,2h78c1.1,0,2-0.9,2-2V38H37C35.9,38,35,37.1,35,36z M67,52h16"+
	        "c1.1,0,2,0.9,2,2s-0.9,2-2,2H67c-1.1,0-2-0.9-2-2S65.9,52,67,52z M67,62h16c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H67c-1.1,0-2-0.9-2-2"+
	        "C65,62.9,65.9,62,67,62z M67,72h16c1.1,0,2,0.9,2,2s-0.9,2-2,2H67c-1.1,0-2-0.9-2-2S65.9,72,67,72z M91,30v4H39v-6h50"+
	        "C90.1,28,91,28.9,91,30z M85,24H15v-4c0-1.1,0.9-2,2-2h66c1.1,0,2,0.9,2,2V24z"]
    },

    contratto:{
        viewBox:"0 0 64 80",
        style:"enable-background:new 0 0 64 80;",
        paths:["M11,60h42V4H11V60z M23,58h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S23.6,58,23,58z M49.8,50.3"+
		"c0.4,0.4,0.3,1-0.1,1.4c0,0,0,0,0,0l-7,6c-0.4,0.4-1,0.3-1.4-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l-1-3c-0.2-0.5,0.1-1.1,0.6-1.3"+
		"c0.5-0.2,1.1,0.1,1.3,0.6l0.5,1.6l5.9-5C48.8,49.9,49.4,49.9,49.8,50.3C49.8,50.3,49.8,50.3,49.8,50.3z M32,6c2.2,0,4,1.8,4,4"+
		"s-1.8,4-4,4s-4-1.8-4-4C28,7.8,29.8,6,32,6z M16,18h32c0.6,0,1,0.4,1,1s-0.4,1-1,1H16c-0.6,0-1-0.4-1-1S15.4,18,16,18z M16,24h32"+
		"c0.6,0,1,0.4,1,1s-0.4,1-1,1H16c-0.6,0-1-0.4-1-1S15.4,24,16,24z M16,29h32c0.6,0,1,0.4,1,1s-0.4,1-1,1H16c-0.6,0-1-0.4-1-1"+
		"S15.4,29,16,29z M16,35h32c0.6,0,1,0.4,1,1s-0.4,1-1,1H16c-0.6,0-1-0.4-1-1S15.4,35,16,35z M16,40h32c0.6,0,1,0.4,1,1s-0.4,1-1,1"+
		"H16c-0.6,0-1-0.4-1-1S15.4,40,16,40z M16,52h8c0.6,0,1,0.4,1,1s-0.4,1-1,1h-8c-0.6,0-1-0.4-1-1S15.4,52,16,52z"],
        circles:[ {cx:"32", cy:"10", r:"2"} ]
    }
}