import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addWorkshift = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('workshiftcollection')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addWorkshift error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getWorkshiftById = async (domainId, contractId, workshiftId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('workshiftcollection')
            .doc(workshiftId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getWorkshift error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getWorkshiftList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('workshiftcollection')
            .get();

        const workshiftList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return workshiftList;
        
    } catch (e) {
        console.log("getWorkshiftList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editWorkshift = async (domainId, contractId, workshiftId, data) => {
    try {
        const workshift =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('workshiftcollection')
            .doc(workshiftId);

        const res = await workshift.set(data)
            .then( () => {
                console.log("editWorkshift - edit successfully")
                return data
            }).catch( error => {
                console.log("editWorkshift - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editWorkshift error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteWorkshift = async (domainId, contractId, workshiftId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('workshiftcollection')
            .doc(workshiftId).delete();
        return res
    }  catch (e) {
        console.log("deleteWorkshift error: ", e)
        return { error: e }
    }
}


