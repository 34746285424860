const systemparams = {
    sw_name: 'Claudit',
    sw_version: '1.102.051',
    last_update: '23/11/2024',
    version_history: {
        '1.102.051': 'gestione licenze archiviate',
        '1.102.050': 'gestione licenze archiviate',
        '1.102.049': 'gestitore errore colore',
        '1.102.048': 'corretto analisi controlli',
        '1.102.047': 'corretta visualizzazione dashboard',
        '1.102.046': 'nuovo caricamento roomTypeList',
        '1.102.045': 'nuove opzioni per stampa report',
        '1.102.044': 'nuovi parametri su report interventi',
        '1.102.043': 'coretta apertura file su messaggi',
        '1.102.042': 'piccole correzioni su grafici dashboard',
        '1.102.041': 'corretti errori di visualizzazione',
        '1.102.040': 'visualizzato tutto il patch sui messaggi',
        '1.102.039': 'parametrizzata data per inizio modifica interventi',
        '1.102.038': 'dati operatore su trace',
        '1.102.037': 'stampa fatturazione e tipologia locale su messaggi',
        '1.102.036': 'gestione strutture non presenti per dipendenti',
        '1.102.035': 'piccola correzione caricament pagine',
        '1.102.034': 'guasti come trace',
        '1.102.033': 'abilitato filtro su variazioni',
        '1.102.032': 'piccole correzioni e vari aggiornamenti dell\'interfaccia',
        '1.102.031': 'eseguito su reclami',
        '1.102.030': 'migliorato layout messaggi',
        '1.102.029': 'inserito preview file',
        '1.102.028': 'inserito download file',
        '1.102.027': 'refresh caricamento versione',
        '1.102.026': 'refresh caricamento versione',
        '1.102.025': 'corretto errore dettagli messaggi',
        '1.102.024': 'corretto errore caricamento documento',
        '1.102.023': 'corretto errore risposta messaggi',
        '1.102.022': 'corretti errori di visualizzazione documenti',
        '1.102.021': 'sistemato blocco su attrezzature',
        '1.102.020': 'migliore visualizzazione dei reparti nel filtro utenti e corretto data a mano',
        '1.102.019': 'gestione errori locali inesistenti',
        '1.102.018': 'corretto filtro strutture',
        '1.102.017': 'gestione errori piani inesistenti e correzione permessi inteventi ordinari',
        '1.102.016': 'aggiornato layout trace',
        '1.102.015': 'importazione locale con area di rischio numerica',
        '1.102.014': 'corretta stampa su trace',
        '1.102.013': 'inserita matricola su trace',
        '1.102.012': 'aggiornato logo',
        '1.102.011': 'gestito problema di importazione area di rischio',
        '1.102.010': 'cancellata durata su trace',
        '1.102.009': 'corretto inseimento tag a mano da web',
        '1.102.008': 'corretto inseimento tag a mano da web',
        '1.102.007': 'gestione cancellazione strutture su planning',
        '1.102.006': 'corretto inserimento tag',
        '1.101.007': 'piccole correzion grafiche',
        '1.101.006': 'importazione massiva struttura su struttura esistente',
        '1.101.005': 'sistemati nuovi label e nuobe immagini',
        '1.101.004': 'aggiornata la visualizzazione dei guasti',
        '1.101.003': 'sistemato lettura dati non presenti',
        '1.101.002': 'sistemato alcune cose',
        '1.101.001': 'molte nuove funzionalità',
        '1.100.005': 'corretto errore su prodotti',
        '1.100.004': 'gestiti errori su planning',
        '1.100.003': 'esportaione su excel',
        '1.100.002': 'gestione locali non presenti su area di lavoro',
        '1.100.001': 'nuova gestione dei parametri su url',
        '1.99.011': 'inserita invalidazione con globaladmin',
        '1.99.010': 'corretta visualizzazione con 0 locali',
        '1.99.009': 'tooltip operazioni su interventi locali',
        '1.99.008': 'nuova legenda su archivio controlli',
        '1.99.007': 'corretta visualizzazione locali con prodblemi di programmazione',
        '1.99.004': 'corretto caricamento variabili',
        '1.99.003': 'tempo validazione interventi e modifica interventi',
        '1.99.002': 'prestazione secondaria su interventi',
        '1.98.009': 'filtro refill su materiale conomale (prodotti e materiale)',
        '1.98.008': 'aggiunti i workflow',
        '1.98.007': 'corretto salvataggio reparti',
        '1.98.006': 'filtri su stampa qrcode',
        '1.98.005': 'stampa qrcode su foglio personalizzato', 
        '1.98.004': 'visualizzazione refill su registro trace', 
        '1.98.003': 'gestione refill lato portale web',
        '1.98.002': 'corretta gestione delle richieste',
        '1.98.001': 'nuova gestione delle richieste',
        '1.97.054': 'corretto errore homepage',
        '1.97.053': 'aggiunta categoria "residenziale"',
        '1.97.052': 'piccole correzioni',
        '1.97.051': 'nuovi parametri su importazione locali',
        '1.97.050': 'sistemato layout aree di rischio homepage',
        '1.97.049': 'aggiunta gestione franchigia nei preventivi',
        '1.97.048': 'corretto altri errori di layout',
        '1.97.047': 'corretto alcuni errori di layout',
        '1.97.046': 'nuovi render',
        '1.97.045': 'copia parametri tipologia di locale, nuovo cartellino',
        '1.97.044': 'footer di stampa con dati aziendali',
        '1.97.043': 'visualizzazione area di lavoro su report interventi multipli',
        '1.97.042': 'correzione locali report interventi',
        '1.97.041': 'aggiornamento label',
        '1.97.040': 'data su report interventi',
        '1.97.039': 'gestione guasti',
        '1.97.031': 'nuove immagini per tipologia locale',
        '1.97.030': 'visualizza interventi dentro modifica locale',
        '1.97.029': 'nuove immagini per tipoligie locali',
        '1.97.028': 'filtro per referenti aree di lavoro nel planning',
        '1.97.027': 'filtro per aree di lavoro nel planning',
        '1.97.026': 'corretti dati nei piani di campionamento',
        '1.97.025': 'corretta visualizzazione locali report interventi',
        '1.97.024': 'nuova interfaccia per creazione/modifica interventi periodici',
        '1.97.023': 'alert su utenti non più presenti assegnati ad aree di lavoro',
        '1.96.022': 'corretta gestione dei permessi nella dashboard',
        '1.96.021': 'aggiornato template cartellini',
        '1.96.020': 'nuova interfaccia per la selezione dei rendering',
        '1.96.019': 'permessi per area di lavoro',
        '1.96.018': 'nuovo layout cartellini operatori',
        '1.96.017': 'corretta visualizzazione messaggi inoltrati',
        '1.95.016': 'aggiunto filtro per invio messaggi',
        '1.95.015': 'aggiunto dei controlli nella gestione utenti',
        '1.95.014': 'inserito inoltro dei messaggi',
        '1.95.013': 'uniformata visualizzazione totali nei piani di campionamento. Gestito locali malformati.',
        '1.95.012': 'inserito prospetto uni en 13549',
        '1.95.011': 'aggiunti documenti di sistema',
        '1.95.010': 'corretti alcuni testi e note testuali',
        '1.95.009': 'validazione e filtro dei report interventi',
        '1.95.008': 'referenti di area di lavoro legati ad utente claudit',
        '1.95.007': 'corretta data di fine piano di campionamento',
        '1.95.006': 'report inteventi aree di lavoro/strutture',
        '1.95.005': 'calendario dashboard dinamico',
        '1.95.004': 'aggiunto filtro strutture nella dashboard',
        '1.95.003': 'segnalazione nella dashboard di contratto non avviato',
        '1.95.002': 'Nuove immagini nelle tipologie di locali',
        '1.95.001': 'Fasce di attività negli interventi ordinari, Modifica e cancellazione di singoli interventi dal calendario',
        '0.9': 'icona di note su struttura, ',
        '0.8': 'configurazione controllo di processo e controllo di risultato',
        '0.7':'',
        '0.6':'',
        '0.5': 'refactor gestione struttura, gestione oraria nelle aree di lavoro e negli interventi ordinari, nuova interfaccia elenchi'
    }
}

export default systemparams
