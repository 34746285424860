import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addDefinitionItem = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('definition')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addDefinitionItem error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const getDefinitionItemById = async (domainId, contractId, definitionitemId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('definition')
            .get(definitionitemId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getDefinitionItem error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getDefinitionItemList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('definition')
            .get();

        const definitionitemList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        
        return definitionitemList;
    } catch (e) {
        console.log("getDefinitionItemList error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const editDefinitionItem = async (domainId, contractId, definitionitemId, data) => {
    try {
        const definitionitem =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('definition')
            .doc(definitionitemId);

        const res = await definitionitem.set(data)
            .then( () => {
                console.log("editDefinitionItem - edit successfully")
                return data
            }).catch( error => {
                console.log("editDefinitionItem - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editDefinitionItem error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteDefinitionItem = async (domainId, contractId, definitionitemId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('definition')
            .doc(definitionitemId).delete();
        return res
    }  catch (e) {
        console.log("deleteDefinitionItem error: ", e)
        return { error: e }
    }
}








