import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { translate, useTranslate } from 'react-polyglot'



import AlertSection from "../components/AlertSection.js"

import { resetUserCredential } from '../components/FirebaseApi/user_api'




function ModalUserPassword({ name, user_id, childRefAlertOr }) {
	const t = useTranslate()

	const childRefAlert = useRef()

	const item = {
		password: '',
		confirmPassword: ''
	}
	const [curElement, setCurrentElement] = useState(item)
	const [editedElement, setEditedElement] = useState(false)
	const [showEdit, setShow] = useState(false)

	const handleClose = () => setShow(false)

	const handleShowEdit = () => {
		setShow(true)
		setEditedElement(false)
	}

	const handleSavePw = async () => {
		const return_data = await resetUserCredential(user_id, curElement.password)
		if (return_data.error) {
			childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error.response.data.message + "." + return_data.error.response.data.error.message)
		} else {
			childRefAlertOr.current.addAlert('success', "Password modificata correttamente")
			setShow(false)
			setEditedElement(false)
			setCurrentElement(item)
		}
	}

	const onChangeHandler = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (event.checked) {
				//				oldcheck[value] = true;
				oldcheck.push(value)
			} else {
				oldcheck.splice(oldcheck.indexOf(value), 1);
				//delete oldcheck[value]
			}
			value = oldcheck
		}
		console.log("user change", name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	function checkPassword(limit) {
		if (curElement.password && curElement.password.length > limit && curElement.confirmPassword !== curElement.password) {
			return true
		} else {
			return false
		}
	}

	return (
		<>
			<Button onClick={() => handleShowEdit()} variant="info" size="sm"><FontAwesomeIcon icon="key" /> {t('users.buttons.edit_password')}</Button>
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title variant="info">
						{t('users.modal.passwordedit')} | {name} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AlertSection ref={childRefAlert} />
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.password')}</Form.Label>
							<Col sm="8">
								<Form.Control type="password" name="password" value={curElement.password} className="mb-2" onChange={(event) => onChangeHandler(event.currentTarget)} />
								{curElement.password && curElement.password.length < 6 ?
									<Form.Text className="text-danger">
										{t('users.messages.password_problem_short')}
									</Form.Text>
									: ''}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.password_check')}</Form.Label>
							<Col sm="8">
								<Form.Control type="password" name="confirmPassword" value={curElement.confirmPassword} className="mb-2" onChange={(event) => onChangeHandler(event.currentTarget)} />
								{checkPassword(0) ?
									<Form.Text className="text-danger">
										{t('users.messages.password_problem')}
									</Form.Text>
									: ''}
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} disabled={checkPassword(5)} onClick={handleSavePw}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}




export default translate()(ModalUserPassword)