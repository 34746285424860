import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, FormControl, InputGroup, Nav, Tab, ProgressBar, Image, ButtonGroup, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { format } from 'date-fns'
import { useReactToPrint } from 'react-to-print';
import DateTime from 'react-datetime';

import styles from './constants/styles.js'

import LocaleContext from './contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';


import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"
import { ModalInitButton, AddListButton, RoomInfoBadge } from '../components/StructureGraphicElements.js'

import { getSelectedElement, getValue, myTimestampToDate, checkComponentPresence, getElementPreview } from "../util/ui_utils.js"

import firebase from 'firebase/app'

import { addMicrobioCheck as addMicrobiologicalCheck2, editMicrobio as editMicrobiologicalCheck2 } from '../Firebase2/Storage2/dbMicrobiologicalCheck';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';
import PrintFooter from './generic/PrintFooter.js';



const section = 'microbiologicalcheck'

function ModalMicrobiologicalCheck({ item, setItem, t, is_new, small, index, item_list, setParentList, ui_list, canWrite, functions }) {
	const { domain, domain_data } = useContext(DomainContext)
	const {contract, contractId} = useContext(GlobalDataContext)

	const componentRef = useRef();
	const childRefAlert = useRef()

	const newmicrobiologicalcheck = {
		targets: '',
		techniques: '',
		expected_result: '',
		room_list: [],
		structure_list: [],
		department_list: [],
		noncompliance_action_list: [],
		reference_standards: '',
		result: false,
		executionDate: firebase.firestore.Timestamp.now(),
		samplingDate: firebase.firestore.Timestamp.now(),
		registerDate: firebase.firestore.Timestamp.now(),
		media_list: []
	}

	const [showMicrobiologicalCheckEdit, setShowMicrobiologicalCheckEdit] = useState(false)
	const [curElement, setCurElement] = useState(newmicrobiologicalcheck)
	const [editedElement, setEditedElement] = useState(false)

	const [isInvalid, setIsInvalid] = useState(false)

	const handleCloseMicrobiologicalCheck = () => setShowMicrobiologicalCheckEdit(false);


	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle,
		documentTitle: `Controllo Microbiologico (${format(myTimestampToDate(curElement.samplingDate), 'dd/MM/yyyy')})
						`,
	});

	const handleSaveMicrobiologicalCheck = async () => {

		setShowMicrobiologicalCheckEdit(false);

		let error = false;
		if (curElement.room_list.length < 2) {
			error = true;
			childRefAlert.current.addAlert('danger', 'inserire almeno un locale')
		}
		if (is_new) {
			let return_data = await addMicrobiologicalCheck2(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { id: return_data.id }
					console.log("-----edit media----", editTrackData)
					let trackret_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				let newlist = [...item_list]
				newlist.push(return_data)
				setParentList(newlist)
				setShowMicrobiologicalCheckEdit(false)
			}

		} else {
			let return_data = await editMicrobiologicalCheck2(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setParentList(edit_list)
				setShowMicrobiologicalCheckEdit(false)
			}
		}
	}


	const handleShowNewMicrobiologicalCheck = () => {
		console.log('show new')
		setIsInvalid(false)
		setCurElement(newmicrobiologicalcheck)
		setShowMicrobiologicalCheckEdit(true)
		setEditedElement(false)
	}


	const handleShowEditMicrobiologicalCheck = () => {
		if (!item.color) {
			item.color = { ...newmicrobiologicalcheck.color }
		}
		setIsInvalid(false)
		setCurElement(item)
		setShowMicrobiologicalCheckEdit(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 100)
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curElement[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'

		}
		console.log(name, value)
		setCurElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}



	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('structure.labels.microbiologicalcheck_add')} action_new={handleShowNewMicrobiologicalCheck} action_edit={handleShowEditMicrobiologicalCheck} action_info={handleShowEditMicrobiologicalCheck} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showMicrobiologicalCheckEdit} onHide={handleCloseMicrobiologicalCheck} dialogClassName="custom-modal" backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('microbiologicalcheck.labels.new_microbiologicalcheck')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('microbiologicalcheck.labels.edit')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('microbiologicalcheck.labels.info_microbiologicalcheck')}</span>
								</>
							]
						}
						: {curElement.firstname} {curElement.lastname}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Tab.Container defaultActiveKey="tabDetails">
							<Row>
								<Col sm={2}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('products.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabMedia" >{t('products.labels.imagesdocs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col >
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails" ref={componentRef}>
											<style type="text/css" media="print">{"\
  @page {\ size: portrait;\ }\
"}</style>
											<Row className="printonly_visible mb-2">
												<Col xs="2">
													<img
														src={domain !== 'admin' && (domain_data && domain_data.logo) ? domain_data.logo : "./Logo.Claudit.registrato.png"}
														height="40"
														className="d-inline-block align-center"
														alt={domain_data && domain_data.logo ? domain_data.name : "Claudit"}
													/>
												</Col>
												<Col>
													Risultato: Controllo microbiologico
												</Col>
												<Col xs="2">
													{getElementPreview(contract, '40px')}
												</Col>
											</Row>
											<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler, updateData: functions.updateData }} ui_list={ui_list} canWrite={canWrite} />
											<Row className="printonly_visible">
												<Col>
											<PrintFooter />
												</Col>
											</Row>
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSaveMicrobiologicalCheck }} section={section} canWrite={canWrite} />
										</Tab.Pane>

									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseMicrobiologicalCheck}>
						{t('modal.close')}
					</Button>
					<Button variant="info" onClick={handlePrint}>
						<FontAwesomeIcon icon="print" />  {t('modal.print')}
					</Button>
					{canWrite ?
						<Button form="companyForm" disabled={isInvalid} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveMicrobiologicalCheck}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}





function ElementFormDetails({ element, functions, ui_list, t, is_new, uploadingState, canWrite }) {
	const { locale } = useContext(LocaleContext)

	return (
		<>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('microbiologicalcheck.labels.sampling_date')}</Form.Label>
				<Col sm="3">
					<DateTime initialValue={myTimestampToDate(element.samplingDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="executionDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'executionDate', value: firebase.firestore.Timestamp.fromDate(e.toDate()) })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('microbiologicalcheck.labels.execution_date')}</Form.Label>
				<Col sm="3">
					<DateTime initialValue={myTimestampToDate(element.executionDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="executionDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'executionDate', value: firebase.firestore.Timestamp.fromDate(e.toDate()) })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('microbiologicalcheck.labels.register_date')}</Form.Label>
				<Col sm="3">
					<DateTime initialValue={myTimestampToDate(element.registerDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="registerDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'registerDate', value: firebase.firestore.Timestamp.fromDate(e.toDate()) })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">Esito</Form.Label>
				<Col>
					<Form.Check type="radio" name="result" value={false} checked={!element.result} label={"Negativo"} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				<Col>
					<Form.Check type="radio" name="result" value={true} checked={element.result} label={"Positivo"} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<ElementRoomListForm element={element} functions={functions} t={t} canWrite={canWrite} ui_list={ui_list} />
		</>
	)
}



/////////////////////////////////////////////////////////////////////////////////////
function ElementRoomListForm({ element, functions, t, ui_list, canWrite }) {
	const { locale } = useContext(LocaleContext)
	const { domain } = useContext(DomainContext)
	const { roomtype_dict, contract, structureElements } = useContext(GlobalDataContext)
	const newoneroom = {
		target_list: [],
		note: ''
	}


	const [showComponentEdit, setShowComponentEdit] = useState(false)

	const handleCloseComponent = () => setShowComponentEdit(false);

	const handleShowNewComponent = () => {
		setShowComponentEdit(true)
	}

	const handleDeleteRoom = (el) => {
		console.log(el)
		let editlist = element.room_list
		const c = checkComponentPresence(el, element.room_list, 'id')
		editlist.splice(c, 1)
		functions.edit({ name: 'room_list', value: editlist })

		/* 		const same_structure = editworkareas[c].room_list.map(r => r.parent_list[0] === room.parent_list[0])
				const same_department = editworkareas[c].room_list.map(r => r.parent_list[3] === room.parent_list[3])
				if (same_structure.length === 0) {
					const index = editworkareas[c].structure_list.indexOf(room.parent_list[0])
					if (index > 0) {
						editworkareas[c].structure_list.splice(index, 1)
					}
				}
				if (same_department.length === 0) {
					const index = editworkareas[c].department_list.indexOf(room.parent_list[3])
					if (index > 0) {
						editworkareas[c].department_list.splice(index, 1)
					}
				} */
	}


	const handleAddRoom = (el) => {
		console.log(el)
		let editlist = [...element.room_list]
		const c = checkComponentPresence(el, element.room_list, 'id')

		const room = (({ id, roomtype, risk_area, name, parent_list }) => ({ id, roomtype, risk_area, name, parent_list }))(el);

		if (c >= 0) {
			alert('element present')
		} else {
			editlist.push({ ...room, ...newoneroom })
		}
		functions.edit({ name: 'room_list', value: editlist })
		/* 	if (element.structure_list.indexOf(room.parent_list[0]) === -1) {
				wstructure_list.push(room.parent_list[0])
			}
			if (wpavillon_list.indexOf(room.parent_list[1]) === -1) {
				wpavillon_list.push(room.parent_list[1])
			}
			if (wfloor_list.indexOf(room.parent_list[2]) === -1) {
				wfloor_list.push(room.parent_list[2])
			}
			if (wdepartment_list.indexOf(room.parent_list[3]) === -1) {
				wdepartment_list.push(room.parent_list[3])
			} */
	}


	const handleUpdateRoom = (el) => {
		let editlist = [...element.room_list]
		const c = checkComponentPresence(el, element.room_list, 'id')
		editlist.splice(c, 1, el)
		functions.edit({ name: 'room_list', value: editlist })
	}




	return (
		<>

			<Form.Group as={Row} controlId="formStructureComponent" >
				<Col>
					<Row className="mb-1">
						<Col>{t('workareas.labels.room_list')}</Col>
						{canWrite ?
							<Col className="text-right">

								{showComponentEdit ?
									<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComponent()}><FontAwesomeIcon icon="check" />{t('structure.labels.component_modend')}</Button>
									:
									<Button variant="outline-primary" size="sm" onClick={() => handleShowNewComponent()}><FontAwesomeIcon icon="plus-square" />{t('microbiologicalcheck.labels.room_add')}</Button>
								}
							</Col>
							: ''}
					</Row>
					{showComponentEdit ?
						<Row className="border-bottom h5">
							<Col sm="7">
							</Col>
							<Col>
								<Row className="text-info">
									<Col>{t('microbiologicalchecks.labels.room_avaiable_list')}</Col>

								</Row>
							</Col>
						</Row>
						: ''}
					<Row >

						{element.room_list && element.room_list.length > 0 ?
							<Col>
								<Row className="mb-1" >
									<Col className="bg-secondary text-light ml-1">{t('global.labels.room')} </Col>
									<Col className="bg-secondary text-light ml-1">{t('global.labels.type')} </Col>
									<Col className="bg-secondary text-light ml-1">{t('micriobiologicalcheck.labels.result')}</Col>
									{canWrite ?
										<Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
										: ''}
								</Row>
								<Row className="source_list_container">
									<Col>
										{element.room_list
											.map((i, key) => (
												<Row key={key} className="border-bottom pb-1">
													<Col>
														<RoomInfoBadge parent_list={i.parent_list} structure_list={structureElements.structure_list} pavillon_dict={structureElements.all_pavillon_dict} floor_dict={structureElements.all_floor_dict} department_dict={structureElements.all_department_dict} />
														{i.name}</Col>
													<Col className="text-muted">
														{getValue(roomtype_dict[i.roomtype.id], 'name')}
													</Col>
													<Col>{i.result}</Col>

													{canWrite ?
														<Col sm="1" className="text-center p-0">
															<ButtonGroup>

																<ModalOneroom item={i} is_new={false} setItem={handleUpdateRoom} canWrite={canWrite} t={t} roomtype={roomtype_dict[i.roomtype.id]} />
																<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteRoom(i)} /> </Button>
															</ButtonGroup>
														</Col>
														: ''}
												</Row>
											))
										}
									</Col>
								</Row>
							</Col>
							: <Col className="font-italic"> {t('microbiologicalcheck.messages.no_room')}</Col>
						}
						{showComponentEdit ?
							<>
								<Col className="editSection" sm="6">
									{ui_list.room_list && ui_list.room_list.length > 0 ?
										<>
											<Row className="mb-1" >
												<Col className="bg-info text-light ml-1 p-0 text-center" sm="1">{t('global.labels.add')}</Col>
												<Col className="bg-info text-light ml-1">{t('structure.labels.component_name')} </Col>
											</Row>
											<Row className="source_list_container">
												<Col>
													{ui_list.room_list
														.map((room, key) => (
															<Row key={key} className={styles.source_row}>
																<Col className="p-0 text-center" sm="1">
																	<AddListButton i={room} element_list={element.room_list} add_action={handleAddRoom} />
																</Col>
																<Col>
																	<RoomInfoBadge parent_list={room.parent_list} structure_list={structureElements.structure_list} pavillon_dict={structureElements.all_pavillon_dict} floor_dict={structureElements.all_floor_dict} department_dict={structureElements.all_department_dict} />
																	{room.name}
																</Col>
																<Col className="text-muted">
																	{getValue(roomtype_dict[room.roomtype.id], 'name')}
																</Col>
															</Row>
														))}

												</Col>
											</Row>
										</>
										: <i> {t('structure.messages.no_microbiologicalroom')}</i>
									}
								</Col>
							</>
							: ''
						}
					</Row>
				</Col>
			</Form.Group>

		</>
	)
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalOneroom({ item, index, roomtype, setItem, t, is_new, small, parent_list, canWrite }) {
	const newonetarget = {
		result: '',
		selected_noncompliance_action_list: [],
		note: '',
	}
	const [showOneroomEdit, setShowOneroomEdit] = useState(false)
	const [curOneroom, setCurOneroom] = useState(item)
	const [editedElement, setEditedElement] = useState(false)

	const handleCloseOneroom = () => setShowOneroomEdit(false);
	const handleSaveOneroom = async () => {
		setShowOneroomEdit(false);
		setItem(curOneroom)
	}


	const handleShowEditOneroom = () => {
		setCurOneroom(item)
		setShowOneroomEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerOneroom = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curOneroom[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurOneroom({ ...curOneroom, [name]: value })
		setEditedElement(true)

	}


	const [showComponentEdit, setShowComponentEdit] = useState(false)

	const handleCloseComponent = () => setShowComponentEdit(false);

	const handleShowNewComponent = () => {
		setShowComponentEdit(true)
	}

	const handleDeleteTarget = (el) => {
		console.log(el)
		let editlist = curOneroom.target_list
		const c = checkComponentPresence(el, curOneroom.target_list, 'id')
		editlist.splice(c, 1)
		setCurOneroom({ ...curOneroom, target_list: editlist })

	}


	const handleAddTarget = (el) => {
		console.log("preADD,", el, curOneroom)
		let editlist = [...curOneroom.target_list]
		const c = checkComponentPresence(el, curOneroom.target_list, 'id')

		if (c >= 0) {
			alert('element present')
		} else {
			editlist.push({ ...el, ...newonetarget })
		}
		console.log("ADD", { ...el, ...newonetarget })
		setCurOneroom({ ...curOneroom, target_list: editlist })

	}


	const handleUpdateTarget = (el) => {
		let editlist = [...curOneroom.target_list]
		const c = checkComponentPresence(el, curOneroom.target_list, 'id')
		if (c >= 0) {

			editlist.splice(c, 1, el)
			console.log("UPDATE ROOM TARGET LIST", editlist)
			setCurOneroom({ ...curOneroom, target_list: editlist })
		}
	}


	return (
		<>

			<Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowEditOneroom()}><FontAwesomeIcon icon="pencil-alt" /> </Button>

			<Modal show={showOneroomEdit} onHide={handleCloseOneroom} backdrop="static" className="bg-secondary" dialogClassName="custom-modal">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('microbiologicalcheck.labels.new_oneroom')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('microbiologicalcheck.labels.edit_oneroom')}</span>
							</>
						}
						&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Row className="mb-1">
							<Col>{t('workareas.labels.target_list')}</Col>
							{canWrite ?
								<Col className="text-right">

									{showComponentEdit ?
										<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComponent()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
										:
										<Button variant="outline-primary" size="sm" onClick={() => handleShowNewComponent()}><FontAwesomeIcon icon="plus-square" /> {t('microbiologicalcheck.labels.target_add')}</Button>
									}
								</Col>
								: ''}
						</Row>
						{showComponentEdit ?
							<Row className="border-bottom h5">
								<Col sm="7">
								</Col>
								<Col>
									<Row className="text-info">
										<Col>{t('microbiologicalchecks.labels.target_avaiable_list')}</Col>
									</Row>
								</Col>
							</Row>
							: ''}
						<Row >
							{curOneroom.target_list && curOneroom.target_list.length > 0
								?
								<Col>
									<Row className="mb-1" >
										<Col className="bg-secondary text-light ml-1 text-center" sm="3">{t('micriobiologicalcheck.labels.target')}</Col>
										<Col className="bg-secondary text-light ml-1 text-center" sm="2">{t('micriobiologicalcheck.labels.techniques')}</Col>
										<Col className="bg-secondary text-light ml-1 text-center" sm="2">{t('micriobiologicalcheck.labels.expected_result')}</Col>
										<Col className="bg-secondary text-light ml-1 text-center">{t('micriobiologicalcheck.labels.result')}</Col>
										<Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
									</Row>
									{curOneroom.target_list.map((objective, k) => (
										<Row key={k} className='border-bottom mb-1 pb-1'>
											<Col sm="3">
												{objective.targets}
											</Col>
											<Col sm="2">
												{objective.techniques}
											</Col>
											<Col sm="2">
												{objective.expected_result} UFC/piastra
											</Col>
											<Col className="font-weight-bold">
												{objective.result} UFC/piastra
												{objective.selected_noncompliance_action_list.length > 0 ?
													<> <br />
														{objective.selected_noncompliance_action_list.map(o => {
															<Badge key={o}>{objective.action_list[o].description}</Badge>
														})}
													</>
													: ''}
											</Col>
											<Col sm="1">
												<ButtonGroup>
													<ModalOneTarget item={objective} index={k} setItem={handleUpdateTarget} t={t} />
													<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteTarget(objective)} /> </Button>
												</ButtonGroup>
											</Col>
										</Row>
									))}
								</Col>
								: <>
									<Col className="font-italic"> {t('targettypes.messages.no_targets')}</Col>
								</>
							}
							{showComponentEdit ?
								<>
									<Col className="editSection" sm="6">
										{roomtype.target_list && roomtype.target_list.length > 0 ?
											<>
												<Row className="mb-1" >
													<Col className="bg-info text-light ml-1 p-0 text-center" sm="1">{t('global.labels.add')}</Col>
													<Col className="bg-info text-light ml-1">{t('global.labels.description')} </Col>
												</Row>
												<Row className="source_list_container">
													<Col>
														{roomtype.target_list
															.map((target, key) => (
																<Row key={key} className={styles.source_row}>
																	<Col className="p-0 text-center" sm="1">
																		<AddListButton i={target} add_action={handleAddTarget} />
																	</Col>
																	<Col>
																		{target.targets}
																	</Col>
																	<Col className="text-muted">
																		{target.expected_result}
																	</Col>
																</Row>
															))}

													</Col>
												</Row>
											</>
											: <i> {t('structure.messages.no_microbiologicaltarget')}</i>
										}
									</Col>
								</>
								: ''
							}
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseOneroom}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveOneroom}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ModalOneTarget({ item, index, setItem, t, is_new, small, parent_list, canWrite }) {
	const [showTargetEdit, setShowTargetEdit] = useState(false)
	const [curTarget, setCurTarget] = useState(item)
	const [editedElement, setEditedElement] = useState(false)

	const handleCloseTarget = () => setShowTargetEdit(false);
	const handleSaveTarget = async () => {
		setShowTargetEdit(false);
		setItem(curTarget)
	}

	const handleShowEditTarget = () => {
		setCurTarget(item)
		setShowTargetEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerTarget = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curTarget[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(parseInt(value))
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurTarget({ ...curTarget, [name]: value })
		setEditedElement(true)

	}




	return (
		<>
			<Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowEditTarget()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			<Modal show={showTargetEdit} onHide={handleCloseTarget} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('roomtypes.labels.new_target')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('roomtypes.labels.edit_result')}</span>
							</>
						}
						&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('micriobiologicalcheck.labels.result')}</Form.Label>
							<Col sm="8">
								<InputGroup className="mb-2" >
									<Form.Control type="text" name="result" value={curTarget.result} onChange={(event) => onChangeHandlerTarget(event.target)} />
									<InputGroup.Append>
										<InputGroup.Text>
											UFC/piastra
										</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Col>
						</Form.Group>
						<Row>
							<Col >{t('operations.labels.noncompliance_action')}</Col>
						</Row>
						{curTarget.action_list && curTarget.action_list.length > 0 ?
							<>
								{curTarget.action_list.map((a, ak) => (
									<Row key={ak} className='border-bottom mb-1 pb-1'>
										<Col sm="1">
											<Form.Check type="checkbox" className="ml-2" name="selected_noncompliance_action_list" value={ak} checked={curTarget.selected_noncompliance_action_list.indexOf(ak) >= 0} label="" onChange={(event) => onChangeHandlerTarget(event.currentTarget)} />
										</Col>
										<Col sm="3">
											{a.min_value}
										</Col>
										<Col>
											{a.description}
										</Col>
									</Row>

								))}
							</> : ''}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseTarget}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveTarget}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}


export default translate()(ModalMicrobiologicalCheck)


const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  @media print{
	  .printonly_visible{
		  display:parent;
	  }
  }
  
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;

