import { firestore } from '../firebaseIndex'


/*
Ticket = {
    timestampSend: firebase.firestore.FieldValue.serverTimestamp(),
    userId: '',
    userName: '',
    messageText: '',
    timestampRead: firebase.firestore.FieldValue.serverTimestamp(),
    location: '',
    department: ''.



}
*/

export const addTicket = async (domainId, contractId, data) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('messages')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addTicket error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTicketById = async (domainId, contractId, messageId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('messages')
            .doc(messageId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id: res.id,
        }
        return data;
    } catch (e) {
        console.log("getTicket error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTicketList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('messages')
            .get();

        const messageList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return messageList;
    } catch (e) {
        console.log("getTicket error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTicketListForUser = async (domainId, contractId, userId, type) => {
    try {
        let query = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('messages')
            .where('userId', '==', userId)
        if (type && type !== undefined) {
            query = query.where('type', '==', type)
        }
        const res = await query.get();

        const messageList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return messageList;
    } catch (e) {
        console.log("getTicket error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTicketListForGroup = async (domainId, contractId, groupId, limit, query_cursor, type) => {
    console.log(domainId, contractId, groupId, limit, query_cursor)
    try {
        let query = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('messages')
            .where('receiver_group', '==', groupId)

        if (type && type !== undefined) {
            query = query.where('type', '==', type)
        }
        query = query.orderBy('createdAt', 'desc')
            .limit(limit)

        // if there is a pivot for query starting point
        if (query_cursor && query_cursor !== undefined) {
            query = query.startAfter(query_cursor)
        }

        const snapshot = await query.get();
        //console.log(snapshot)

        if (snapshot.docs.length > 0) {    // there is one or more results
            const last = snapshot.docs[snapshot.docs.length - 1];

            const messageList = snapshot.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id: doc.id,
                }
                return data
            })

            return {
                messageList: messageList,
                query_cursor: last
            };

        } else {  // no results
            return {
                messageList: [],
                query_cursor: undefined
            };
        }

    } catch (e) {
        console.log("getTicket error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editTicket = async (domainId, contractId, messageId, data) => {
    try {
        const message = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('messages')
            .doc(messageId);

        const res = await message.set(
            data
        ).then(() => {
            console.log("editTicket - edit successfully")
            return data
        }).catch(error => {
            console.log("editTicket - edit error: ", error)
            return { error: error }
        });

        return res
    } catch (e) {
        console.log("editTicket error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteTicket = async (domainId, contractId, messageId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('messages')
            .doc(messageId).delete();
        return res
    } catch (e) {
        console.log("deleteTicket error: ", e)
        return { error: e }
    }
}