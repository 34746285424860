import React, { useContext, useState, useEffect, createRef, useLayoutEffect } from 'react';
import { Container, Row, Col, Button, Modal, Spinner, Badge, OverlayTrigger, Popover, ListGroup, Tooltip, ButtonGroup, Form, Image } from 'react-bootstrap';

import { translate } from 'react-polyglot'
import { format, getDay } from 'date-fns'
import { useParams } from "react-router-dom"
import 'moment/locale/it';
import 'moment/locale/de';
import { it } from 'date-fns/locale'


import LocaleContext from "../components/contexts/locale.js"
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"

import { getValue, myTimestampToDate, compareName, getFloatNumber, getBaseObjectValue, getSelectedElementB, getTimeValue, compareDateParam, compareStartDateParam, rgba2hex, getSelectedElement } from "../util/ui_utils.js"





/* 
let contractId = sessionStorage.getItem('contractId')
const contract = JSON.parse(sessionStorage.getItem('contract')) 
const domain_data = JSON.parse(sessionStorage.getItem('domain_data'))
*/
//console.log("DOMAIN_:DATA", domain_data)
//console.log("CONTRACT ID", contractId)


let department_dict = {}

let structure_list = []


const two_row_limit = 5

const popoverTiming = { show: 250, hide: 100 }

const priority_dict = {
	"0": 'bassa',
	"1": "media",
	"2": "alta"
}

const slot_duration_list = [
	'0:05:00',
	'0:15:00',
	'0:30:00',
	'1:00:00',
]

const filterWeekDay = function (_wd, start, custom_weekdays, room_id) {
	const wd = custom_weekdays && custom_weekdays[room_id] ? custom_weekdays[room_id] : _wd
	if (wd) {
		return wd.indexOf(getDay(myTimestampToDate(start)).toString()) !== -1
	} else {
		return false
	}
}



export function compareWorkareaParam(a, b) {
	if (a.extendedProps.workarea_name < b.extendedProps.workarea_name) {
		return -1
	} else if (a.extendedProps.workarea_name > b.extendedProps.workarea_name) {
		return 1
	}
	if (myTimestampToDate(a.start) < myTimestampToDate(b.start)) {
		return -1;
	}
	if (myTimestampToDate(a.start) > myTimestampToDate(b.start)) {
		return 1;
	}

	return 0;
}



const mailcount = 7;
let load_page = false

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const HomeView = ({ t, uData }) => {
	const { domain } = useContext(DomainContext)
	const { contract  } = useContext(GlobalDataContext)
	const { initContract } = InitContractHelper()
	let { cid } = useParams();

	let contractId = cid








	const initValues = async () => {
		//getEventList()
		//contractId = sessionStorage.getItem('contractId')
		/* 		console.log("DB----", contractId, domain)
				if (userData && userData.contract_dict) {
					console.log(userData.contract_dict)
				} */

		console.log("HOME, contractId, domain", contractId, domain)
	}

	useEffect(() => {
		load_page = false
		async function loadData() {
			let contractId = cid
			console.log("HOME reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("HOME init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useLayoutEffect(() => {
		console.log('HOME loadingcontract', contract, load_page)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])



	//=======================================================================================================
	return (
		<>
			<Container className="titleStickyTop" fluid>

				<Row>
					<Col>
						<PageTitle title={t('navs.pages.catalog')} />
					</Col>
				</Row>
			</Container>

			<Container className="justify-content-center" fluid>


			</Container>
			<Footer />

		</>
	)
}







export default translate()(HomeView);

