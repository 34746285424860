import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { BlockPicker } from 'react-color';
import reactCSS from 'reactcss'


import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import { compareName } from "../util/ui_utils.js"


import { addService as addService2, editService as editService2 } from '../Firebase2/Storage2/dbService';




//const contractId = sessionStorage.getItem('contractId')


function ModalService({ item, setItem, t, is_new, small, index, item_list, setParentList, canWrite }) {
	const { domain } = useContext(DomainContext)
	const { contractId, setService_dict } = useContext(GlobalDataContext)

	const newservice = {
		name: '',
		code: '',
		element_list: [],
		color: { r: 255, g: 255, b: 255, a: 1 },
		type: 'ordinary',
		hour_price: 0
	}

	const defaultcolors = [
		'#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#607d8b", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800",
	]

	const [showServiceEdit, setShowServiceEdit] = useState(false)
	const [curService, setCurService] = useState(newservice)
	const [editedElement, setEditedElement] = useState(false)
	const [displayColorPicker, setShowColorPicker] = useState(false)
	const [color, setCurColor] = useState({ r: 100, g: 0, b: 0, a: 1 })

	const styles = reactCSS({
		'default': {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: `rgba(${curService.color.r}, ${curService.color.g}, ${curService.color.b}, ${curService.color.a})`,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		},
	});


	const handleCloseService = () => setShowServiceEdit(false);

	const handleSaveService = async () => {

		let newservice_list = [...item_list]
		if (is_new) {
			const return_data = await addService2(domain, contractId, curService)
			newservice_list.push(return_data)
			console.log(newservice_list)
			newservice_list = newservice_list.sort(compareName)
			setItem(newservice_list)

		} else {

			newservice_list.splice(index, 1, curService)
			setParentList(newservice_list)
			const return_data = await editService2(domain, contractId, curService.id, curService)

		}
		let service_dict = Object.assign({}, ...newservice_list.map((x) => ({ [x.id]: x })));
		sessionStorage.setItem('service_dict', JSON.stringify(service_dict))
		setService_dict(service_dict)
		setShowServiceEdit(false);
	}


	const handleShowNewService = () => {
		console.log('show new')
		setCurService(newservice)
		setShowServiceEdit(true)
		setEditedElement(false)
	}


	const handleShowEditService = () => {
		if (!item.color) {
			item.color = { ...newservice.color }
		}
		setCurService(item)
		setShowServiceEdit(true)
		setTimeout(function () {
			setEditedElement(false)

		}, 100)
	}


	const onChangeHandlerService = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curService[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurService({ ...curService, [name]: value })
		setEditedElement(true)
	}


	const onChangeHandlerColor = (el) => {
		setCurService({ ...curService, color: el.rgb })
	}
	const handleChangeColor = (c) => {
		console.log('set color', c)
		setCurColor(c.rgb)
	}
	const handleShowColorPicker = () => {
		setShowColorPicker(!displayColorPicker)
	}
	const handleCloseColorPicker = () => {
		setShowColorPicker(false)
	}
	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNewService()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNewService()}><FontAwesomeIcon icon='plus-square' /> {t('structure.labels.service_add')}</Button>
				)
				: <Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEditService()}><FontAwesomeIcon icon={canWrite ? "pencil-alt" : "info-circle"} /> </Button>
			}
			<Modal show={showServiceEdit} onHide={handleCloseService} dialogClassName="custom-modal" backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('roomtypes.labels.new_service')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('roomtypes.labels.edit_service')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('roomtypes.labels.info_service')}</span>
								</>
							]
						}
						: {curService.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="name" value={curService.name} onChange={(event) => onChangeHandlerService(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="code" value={curService.code} onChange={(event) => onChangeHandlerService(event.target)} />
							</Col>
						</Form.Group>
						<Row className="border-bottom">
							<Col sm="2">{t('objecttype.labels.color')}</Col>
							<Col>
								<Button variant="outline-info" onClick={handleShowColorPicker}>
									<div style={styles.color} />
								</Button>
								{displayColorPicker ? <div style={styles.popover}>
									<div style={styles.cover} onClick={handleCloseColorPicker} />
									<BlockPicker color={color} colors={defaultcolors} triangle="hide" onChange={handleChangeColor} onChangeComplete={onChangeHandlerColor} />
								</div> : null}
							</Col>
						</Row>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
							<Col>
								<Form.Check type="radio" className="ml-2" name="type" value="ordinary" checked={curService.type && curService.type === "ordinary"} label={t('workarea.labels.ordinary')} onChange={(event) => onChangeHandlerService(event.currentTarget)} />
								<Form.Check type="radio" className="ml-2" name="type" value="periodic" checked={curService.type && curService.type === "periodic"} label={t('workarea.labels.periodic')} onChange={(event) => onChangeHandlerService(event.currentTarget)} />
								<Form.Check type="radio" className="ml-2" name="type" value="requested" checked={curService.type && curService.type === "requested"} label={t('workarea.labels.requested')} onChange={(event) => onChangeHandlerService(event.currentTarget)} />
								<Form.Check type="radio" className="ml-2" name="type" value="fee" checked={curService.type && curService.type === "fee"} label={t('workarea.labels.fee')} onChange={(event) => onChangeHandlerService(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('service.labels.length')}</Form.Label>
							<Col sm="8">
								<InputGroup>
									<Form.Control type="number" min="0" name="length" value={curService.length || 0} onChange={(event) => onChangeHandlerService(event.target)} />
									<InputGroup.Append>
										<InputGroup.Text id="basic-addon1">Ore</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseService}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveService}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}





export default translate()(ModalService)