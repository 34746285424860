import React from 'react'
import { useDrag } from 'react-dnd'
import { ItemTypes } from './constants/itemtypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const style = {
  position: 'absolute',
  color: 'deeppink',
  padding: '0.2rem 0.1em',
  cursor: 'move',
}
const style_sel = {
  position: 'absolute',
  backgroundColor:'yellow',
  borderRadius: '5px',
  padding: '0.2rem 0.1em',
  zIndex:100000,
  cursor: 'move',
}
export const ControllerPoint = ({ id, left, top, hideSourceOnDrag, children, selected, setSelectedElement, service_color, zoom }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, left, top, type: ItemTypes.BOX },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag} />
  }
  let mystyle = style
  if (selected) {
    console.log('selected----------')
    mystyle = style_sel
  }
  mystyle.color = service_color
  left = left*zoom
  top = top*zoom
  return (
    <div ref={drag} style={{ ...mystyle, left, top }} className={selected ? "h3" : "h3"} onClick={(el) => setSelectedElement(id)}>
      <FontAwesomeIcon icon="map-marker-alt" /> {children}
    </div>
  )
}