import React, { useState, useEffect, useRef, useCallback, useContext, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Badge, Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { format, setDay, getDay } from 'date-fns'
import { it } from 'date-fns/locale'
import { useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'
/* import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import 'moment/locale/it';
import 'moment/locale/de'; */
import { RRule } from 'rrule'

import styles from '../components/constants/styles.js'

import GlobalDataContext from './contexts/globaldataContext.js';
import RoomContext, { RoomProvider } from '../components/contexts/roomList.js';


//import EditContext, { EditProvider } from "../components/contexts/edit.js"
import { getSelectedElement, checkComponentPresence, myTimestampToDate, rgba2hex, getTimeValue, compareName, getValue, getSelectedElementB } from "../util/ui_utils.js"

import { ItemProgress, WeekDays, renderFrequency, ServiceBadge, RiskareaBadge, StructureGroupIcon, RoomInfoBadge, Frequency } from '../components/StructureGraphicElements.js'
import InterventionContext from './contexts/interventionContext.js';

import { popoverTiming } from './constants/global_definitions.js';

const ItemTypes = {
	CARD: 'card',
}

const cardcontainerstyle = {
	border: '1px dashed #0099cc',
	padding: '0.5rem 1rem',
	marginBottom: '.5rem',
	backgroundColor: 'white',
}

const cardstyle = {
	cursor: 'move'
}

const RoomEditRow = ({ id, card, index, moveCard, removeCard, ui_list, intervention, t, updateCustomFrequency }) => {
	const { structureElements, roomtype_dict, service_dict } = useContext(GlobalDataContext)
	const room = structureElements.all_room_dict[card.id]
	const rt = room && room.roomtype && room.roomtype.id ? roomtype_dict[room.roomtype.id] : undefined
	const { secondary_service_list } = useContext(InterventionContext)
	const ref = useRef(null)
	const [cardHover, toggleHover] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curRoom, setCurRoom] = useState({ weekdays: [] })
	const [, drop] = useDrop({
		accept: ItemTypes.CARD,
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of service
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})

	const [{ isDragging }, drag] = useDrag({
		item: { type: ItemTypes.CARD, id, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	drag(drop(ref))


	if (room !== undefined) {


		return (
			<>
				<Row ref={ref} style={cardstyle} className={`mb-1 pb-1 ` + (isDragging ? ' border-primary bg-primary text-light mb-2 mt-2' : 'border-bottom')} >
					<Col>
						{room.name}
					</Col>
					<Col className="text-muted" sm="2">
						{roomtype_dict[room.roomtype.id] ? roomtype_dict[room.roomtype.id].name : ''}
					</Col>
					<Col sm="3" className="p-0">
						<Row className="p-0">
							<Col className="p-0 text-center">
								<Frequency roomtype_id={room.roomtype.id} service_id={intervention.service.id} roomtype_dict={roomtype_dict} customFrequency={intervention.custom_weekdays && intervention.custom_weekdays[card.id] ? intervention.custom_weekdays[card.id] : undefined} />
							</Col>
							{intervention.service.type === 'ordinary' ?

								<Col className="p-0" sm="4">
									<CustomFrequency intervention={intervention} t={t} updateCustomFrequency={updateCustomFrequency} room={room} ui_list={ui_list} />
								</Col>
								: ''}

						</Row>
						{secondary_service_list && secondary_service_list.length > 0 ?
							secondary_service_list.map((s) => (

								<Row key={s} className="bg-light border-top border-light mt-1 text-secondary">
									<Col className="p-0 text-center">
										{service_dict[s].name}<br />
										{console.log("SECONDARY", intervention.secondary_custom_weekdays, card.id, s)}
										{rt.service_list && rt.service_list.filter(sr => sr.service && sr.service.id === s).length > 0 ?
											<Frequency roomtype_id={room.roomtype.id} service_id={s} roomtype_dict={roomtype_dict}
												customFrequency={intervention.secondary_custom_weekdays && intervention.secondary_custom_weekdays[card.id] && intervention.secondary_custom_weekdays[card.id][s]
													? intervention.secondary_custom_weekdays[card.id][s] : []} />
											:
											<i>Prestazione non presente su questo locale</i>}
									</Col>
									{intervention.service.type === 'ordinary' && rt.service_list && rt.service_list.filter(sr => sr.service && sr.service.id === s).length > 0 ?

										<Col className="p-0" sm="4">
											<CustomFrequency intervention={intervention} t={t} updateCustomFrequency={updateCustomFrequency} room={room} ui_list={ui_list} secondary={s} />
										</Col>
										: ''}
								</Row>
							))
							: <></>}
					</Col>
					<Col sm="1" className="text-center">{room.size}</Col>
					<Col sm="1 text-center">
						<RiskareaBadge riskarea={ui_list.risk_area_dict[room.risk_area.Id]} />
					</Col>
					<Col sm="1" className="text-center">
						<Button variant="danger" size="sm" onClick={(event) => removeCard(card, index)}><FontAwesomeIcon icon="trash" /></Button>
					</Col>
				</Row>
			</>

		)
	} else {
		return (
				<Row ref={ref} style={{...cardstyle,backgroundColor:'lightsalmon'}} className={`mb-1 pb-1 ` + (isDragging ? ' border-primary bg-primary text-light mb-2 mt-2' : 'border-bottom')} >
				<Col>
					Locale eliminato
				</Col>
					<Col sm="1" className="text-center">
						<Button variant="danger" size="sm" onClick={(event) => removeCard(card, index)}><FontAwesomeIcon icon="trash" /></Button>
					</Col>

			</Row>
		)

	}
}

/////////////////////////////////////////////////////////////////////////////////////////////
const CustomFrequency = ({ intervention, updateCustomFrequency, room, ui_list, t, secondary }) => {
	const [freq, setFreq] = useState([])
	const [origFreq, setOrigFreq] = useState([])
	const [editedElement, setEditedElement] = useState(false)
	const [showCustomFrequency, setShowCustomFrequency] = useState(false)

	const handleCloseCustomFrequency = () => { setShowCustomFrequency(false) }

	const handleSaveCustomFrequency = () => {
		updateCustomFrequency({ room_id: room.id, value: freq }, secondary)
		setShowCustomFrequency(false)
	}

	const onChangeHandlerElement = (el) => {
		let { value, type } = el
		let oldcheck = [...freq]
		if (el.checked) {
			if (oldcheck.indexOf(value) < 0) {
				oldcheck.push(value)
			}
		} else {
			if (oldcheck.indexOf(value) >= 0) {
				oldcheck.splice(oldcheck.indexOf(value), 1)
			}
		}
		console.log("frequency new value", oldcheck)
		setFreq(oldcheck)
		setEditedElement(true)
	}

	useEffect(() => {
		if (setShowCustomFrequency) {
			const roomtype = ui_list.roomtype_dict[room.roomtype.id]
			for (let s = 0; s < roomtype.service_list.length; s++) {
				if (roomtype.service_list[s].service && roomtype.service_list[s].service.id === intervention.service.id) {
					setOrigFreq([...roomtype.service_list[s].weekdays])
					if (secondary) {
						if (intervention.secondary_custom_weekdays && intervention.secondary_custom_weekdays[room.id] && intervention.secondary_custom_weekdays[room.id][secondary]) {
							setFreq([...intervention['secondary_custom_weekdays'][room.id][secondary]])
						} else {
							setFreq([...roomtype.service_list[s].weekdays])
						}
					} else {
						if (intervention.custom_weekdays && intervention.custom_weekdays[room.id]) {
							setFreq([...intervention['custom_weekdays'][room.id]])
						} else {
							setFreq([...roomtype.service_list[s].weekdays])
						}
					}
				}
			}
		}
	}, [showCustomFrequency])

	const btn_string = () => {
		if (secondary) {
			if (intervention.secondary_custom_weekdays && intervention.secondary_custom_weekdays[room.id] && intervention.secondary_custom_weekdays[room.id][secondary]) {
				return 'Mod.'
			}

		} else {
			if (intervention.custom_weekdays && intervention.custom_weekdays[room.id]) {
				return 'Mod.'
			}
		}
		return ''
	}

	return (
		<>
			<Button size="sm" variant="info" onClick={setShowCustomFrequency} >{btn_string()}<FontAwesomeIcon icon="pencil-alt" /> </Button>
			<Modal show={showCustomFrequency} onHide={handleCloseCustomFrequency} backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('roomtypes.labels.edit_frequency')} ({t('structure.labels.room')} {room.name})</span>
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2"> {t('service.labels.frequency')}</Form.Label>
							<Col>
								{intervention.service.type === 'ordinary' ?
									<Form.Group as={Row} controlId="interventionFrequency1" className="pb-1">
										<Col >
											<Form.Check type="checkbox" name="weekdays" checked={freq.indexOf("1") >= 0} value="1" label={format(setDay(new Date(), 1), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" checked={freq.indexOf("2") >= 0} value="2" label={format(setDay(new Date(), 2), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" checked={freq.indexOf("3") >= 0} value="3" label={format(setDay(new Date(), 3), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" checked={freq.indexOf("4") >= 0} value="4" label={format(setDay(new Date(), 4), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" checked={freq.indexOf("5") >= 0} value="5" label={format(setDay(new Date(), 5), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" checked={freq.indexOf("6") >= 0} value="6" label={format(setDay(new Date(), 6), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" checked={freq.indexOf("0") >= 0} value="0" label={format(setDay(new Date(), 0), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
									</Form.Group>
									: ''
								}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2"> {t('service.labels.default_frequency')}</Form.Label>
							<Col>
								{intervention.service.type === 'ordinary' ?
									<Form.Group as={Row} controlId="interventionFrequency1" className="pb-1">
										<Col >
											<Form.Check type="checkbox" name="weekdays" disabled={true} checked={origFreq.indexOf("1") >= 0} value="1" label={format(setDay(new Date(), 1), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" disabled={true} checked={origFreq.indexOf("2") >= 0} value="2" label={format(setDay(new Date(), 2), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" disabled={true} checked={origFreq.indexOf("3") >= 0} value="3" label={format(setDay(new Date(), 3), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" disabled={true} checked={origFreq.indexOf("4") >= 0} value="4" label={format(setDay(new Date(), 4), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" disabled={true} checked={origFreq.indexOf("5") >= 0} value="5" label={format(setDay(new Date(), 5), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" disabled={true} checked={origFreq.indexOf("6") >= 0} value="6" label={format(setDay(new Date(), 6), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
										<Col>
											<Form.Check type="checkbox" name="weekdays" disabled={true} checked={origFreq.indexOf("0") >= 0} value="0" label={format(setDay(new Date(), 0), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
										</Col>
									</Form.Group>
									: ''
								}
							</Col>
						</Form.Group>

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseCustomFrequency}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveCustomFrequency}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>

	)
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const RoomEditRowSource = ({ room, handleAddServiceRoom, intervention_room_list, service_id, ui_list }) => {
	const { structureElements, roomtype_dict, risk_area_dict } = useContext(GlobalDataContext)

	const _room = structureElements.all_room_dict[room.id]
	return (
		<Row className={styles.source_row} >
			<Col className="p-0 text-center" sm="1">
				{checkComponentPresence(_room, intervention_room_list, 'id') !== -1 ?
					<Button size="sm" variant="secondary" disabled><FontAwesomeIcon icon="check" /><FontAwesomeIcon icon="plus-square" /> </Button>
					:
					<Button size="sm" variant="light" onClick={() => handleAddServiceRoom(room.id)}><FontAwesomeIcon icon="arrow-left" /><FontAwesomeIcon icon="plus-square" /> </Button>
				}
			</Col>
			<Col>
				<RoomInfoBadge parent_list={_room.parent_list} structure_list={ui_list.structure_list} pavillon_dict={structureElements.all_pavillon_dict} floor_dict={structureElements.all_floor_dict} department_dict={structureElements.all_department_dict} />
				{_room.name}
			</Col>
			<Col className="text-muted" sm="2">
				{roomtype_dict[_room.roomtype.id].name}
			</Col>
			<Col sm="3" className="p-0">
				{renderFrequency(_room.roomtype.id, service_id, ui_list)}
			</Col>
			<Col sm="1" className="text-center">{_room.size}</Col>
			<Col sm="1 text-center">
				<RiskareaBadge riskarea={risk_area_dict[_room.risk_area.Id]} />
			</Col>
		</Row>
	)
}





/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const EditRoomListContainer = ({ intervention, rooms, functions, ui_list, t, externalTrigger }) => {

	const { contract } = useContext(GlobalDataContext)
	//	const [roomGlobal, setRoomGlobal] = useContext(RoomContext)
	const [room_list, setRoomList] = useState([])
	const [isFirst, setIsFirst] = useState(0)
	console.log("new", rooms, "trigger:", externalTrigger, "old", room_list)
	const childRefDelete = useRef()

	const moveRoom = useCallback(
		(dragIndex, hoverIndex) => {
			const dragCard = room_list[dragIndex]
			let new_room_list = update(room_list, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragCard],
				],
			})
			functions.update(new_room_list)
			setRoomList(new_room_list)
		},
		[room_list],
	)

	const removeRoom = useCallback(
		(data, index) => {
			const dragRoom = room_list[data.index]
			console.log("DELETE", data, index)
			let new_room_list = update(room_list, {
				$splice: [
					[index, 1]
				],
			})
			functions.update(new_room_list)
			const cb = () => {
				setRoomList(new_room_list)
			}
			cb()
			//childRefDelete.current.confirmDelete(t('interventions.messages.delete_room_title'), t('interventions.messages.delete_room_message'), cb)
		},
		[room_list],
	)

	const renderRoom = (room, index) => {
		//console.log(card.name)
		return (
			<RoomEditRow
				key={room.id}
				index={index}
				id={room.id}
				card={room}
				intervention={intervention}
				ui_list={ui_list}
				moveCard={moveRoom}
				removeCard={removeRoom}
				updateCustomFrequency={functions.updateCustomFrequency}
				t={t}
			/>
		)
	}
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		console.log('update room list rooms', room_list)
		if (isFirst > 2) {
			//functions.setEdit(true)
		} else {
			setIsFirst(isFirst + 1)
		}

		setLoading(false)
		//		functions.update({ name: 'room_list', value: rooms })
	}, [room_list]);

	useEffect(() => {
		console.log("INIT DIALOG ROOMS", rooms)
		setRoomList(rooms)
	}, [])

	useEffect(() => {
		console.log("external trigger update", rooms)
		setRoomList(rooms)
	}, [externalTrigger])



	return (
		<>
			<Row >
				<Col>
					{room_list && room_list.length > 0
						? <div style={cardcontainerstyle}>
							<Row className="p-1 text-light section_title">
								<Col className="ml-1 text-info" >{t('workarea.labels.roomlist')}</Col>
							</Row>
							<Row className="p-1 section_title mb-1">
								<Col className="ml-1 bg-light text-secondary" >{t('global.labels.name')}</Col>
								<Col className="ml-1 bg-light text-secondary" sm="2">{t('structures.labels.room_type')}</Col>
								<Col className="ml-1 bg-light text-secondary" sm="3">{t('workareas.labels.frequency')}</Col>
								<Col className="ml-1 bg-light text-secondary text-center" sm="1" >m<sup>2</sup></Col>
								<Col className="ml-1 bg-light text-secondary text-center" sm="1" >{contract.type === 'civil' ? t('global.labels.standard_cleaning') : t('global.labels.risk_area')}</Col>
								<Col className="ml-1 bg-light text-secondary text-center" sm="1">{t('global.labels.actions')}</Col>
							</Row>
							{room_list.map((room, c) => renderRoom(room, c))}
						</div>
						: <i>{t('workareas.messages.no_intervention_room')}</i>
					}
				</Col>
			</Row>
		</>
	)
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const OneRoom = ({ room, index, intervention, ui_list, t }) => {
	const { roomtype_dict, risk_area_dict, structureElements, service_dict } = useContext(GlobalDataContext)

	const _room = structureElements.all_room_dict[room.id] || room
	const rt = roomtype_dict[_room.roomtype.id]
	return (

		<>
			<Row key={intervention.id + "_" + room.id} className='element_row border-bottom pb-1 mt-1'>
				<Col>
					<RoomInfoBadge parent_list={_room.parent_list} structure_list={ui_list.structure_list} pavillon_dict={structureElements.all_pavillon_dict} floor_dict={structureElements.all_floor_dict} department_dict={structureElements.all_department_dict} />
					{_room.name}
				</Col>
				<Col className="text-muted">
					{rt.name}
				</Col>
				<Col sm="2" className="text-center">
					<Row>
						<Col>
							<Frequency roomtype_id={_room.roomtype.id} service_id={intervention.service.id} roomtype_dict={roomtype_dict} customFrequency={intervention.custom_weekdays && intervention.custom_weekdays[room.id] ? intervention.custom_weekdays[room.id] : undefined} />
						</Col>
					</Row>
					{intervention.secondary_service_list && intervention.secondary_service_list.length > 0 && intervention.secondary_custom_weekdays && intervention.secondary_custom_weekdays[_room.id] ?
						intervention.secondary_service_list.map((s) => (

							<Row key={s} className="bg-light border-top border-light mt-1 text-secondary">
								<Col className="p-0 text-center">
									{service_dict[s].name}<br />
									{rt.service_list && rt.service_list.filter(sr => sr.service && sr.service.id === s).length > 0 ?
										<Frequency roomtype_id={room.roomtype.id} service_id={s} roomtype_dict={roomtype_dict}
											customFrequency={intervention.secondary_custom_weekdays && intervention.secondary_custom_weekdays[_room.id] && intervention.secondary_custom_weekdays[_room.id][s]
												? intervention.secondary_custom_weekdays[_room.id][s] : []} />
										:
										<i>Prestazione non presente su questo locale</i>}
								</Col>
							</Row>))


						: ''}
				</Col>
				<Col sm="1" className="text-center">{_room.size}</Col>
				<Col sm="2 text-center">
					{_room.risk_area &&
						<RiskareaBadge riskarea={risk_area_dict[_room.risk_area.Id]} />
					}
				</Col>
				<Col sm="1">
					{getValue(_room, 'cdc')}
				</Col>
				<Col sm="1">

				</Col>
			</Row>
		</>
	)

}

const InterventionDataSection = ({ intervention_data, room_id, start }) => {
	const { service_dict } = useContext(GlobalDataContext)
	if (intervention_data
		&& intervention_data.secondary_service_list
		&& intervention_data.secondary_service_list.length > 0
		&& intervention_data.secondary_custom_weekdays
		&& intervention_data.secondary_custom_weekdays[room_id]) {
		return (

			intervention_data.secondary_service_list
				.filter(s => intervention_data.secondary_custom_weekdays
					&& intervention_data.secondary_custom_weekdays[room_id]
					&& intervention_data.secondary_custom_weekdays[room_id][s]
					&& intervention_data.secondary_custom_weekdays[room_id][s].indexOf(getDay(start).toString()) !== -1)
				.map((s) => (
					<Row key={s} className="bg-light border-top border-info mt-1 text-secondary">
						<Col className="">
							{intervention_data.secondary_custom_weekdays && intervention_data.secondary_custom_weekdays[room_id] && intervention_data.secondary_custom_weekdays[room_id][s] && intervention_data.secondary_custom_weekdays[room_id][s].indexOf(getDay(start).toString()) !== -1 ?
								<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" className="text-sucess" overlay={<Tooltip>Prestazione secondaria in programma per la giornata</Tooltip>}>
									<><FontAwesomeIcon icon="plus-square" /> {service_dict[s] && service_dict[s].name}</>
								</OverlayTrigger>
								: ''}
						</Col>
					</Row>
				))
		)
	} else {
		return (<></>)
	}

}


const OneEventRoom = ({ room, rt, today_room, one_event_status, do_print, intervention_data, start, index }) => {
	console.log("EVENT STATUS", one_event_status)
	if (room) {
		return (
			<>
					{do_print && (index % 20 === 0 && index !== 0) &&
						<div className="page-break" />
						}
						

			<Row key={room.id} className="border-bottom element_row_inverted">
				<Col className="text-muted" sm="6">
					<RoomInfoBadge parent_list={room.parent_list} expanded={true} />
				</Col>
				<Col>
					<Row>
						<Col>
							{renderRoomstatus(today_room)} {room.name} <i>{getRoomtypeData(rt)}</i>
						</Col>
					</Row>
				</Col>
				<InterventionDataSection room_id={room.id} intervention_data={intervention_data} start={start} />
				{one_event_status ?
					<Col>
						<Row>
							<RoomDoneData rd={one_event_status.room_done_status[room.id]} do_print={do_print} />
							<Col>
								{one_event_status.operator_data && <>
									<FontAwesomeIcon icon="id-card-alt" />
									{one_event_status.operator_data.register}
								</>}
							</Col>
						</Row>
						<Row>
							{one_event_status.refill_status &&
								<RoomRefillData rd={one_event_status.refill_status[room.id]} do_print={do_print} />
							}
						</Row>
					</Col>
					: <Col>
					</Col>}
			</Row>
			</>

		)

	} else {

		return (
			<Row>
				Locale eliminato
			</Row>
		)
	}

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const EventRoomList = ({ room_list, roomtype_dict, today_room_dict, one_event_status, do_print, intervention_data, start }) => {
	if (room_list) {
		return (
			<Col>
				{room_list.map((room, k) => (
						<OneEventRoom
							key={k}
							room={room}
							index={k}
							rt={room ? roomtype_dict[room.roomtype.id] : undefined}
							today_room={today_room_dict[room.id]}
							one_event_status={one_event_status}
							intervention_data={intervention_data}
							start={start}
							do_print={do_print}
						/>
				))}
			</Col>

		)
	} else {
		return ('')
	}

}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getRoomtypeData = (rt) => {
	try {
		return <> - {rt.name}</>
	} catch (err) {
		return ''
	}
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const renderRoomstatus = (room) => {
	const now = new Date()
	console.log("ROOM", room)

	const actual_status = room.status_planning ? room.status_planning.filter(s => (
		myTimestampToDate(s.startDate) <= now && (!s.with_end || (s.with_end && myTimestampToDate(s.endDate) >= now))
	)) : undefined
	return (
		<>
			{room.status === 'suspended' ?
				<OverlayTrigger
					placement="right"
					delay={popoverTiming}
					overlay={<Tooltip id="button-tooltip">locale sospeso
						{actual_status && actual_status.with_end &&
							<>fino al {format(myTimestampToDate(actual_status.endDate), 'dd/MM/yyyy')}</>}

					</Tooltip>}
					message="locale sospeso"
				>

					<FontAwesomeIcon icon={'hourglass'} className="text-danger mr-1" />
				</OverlayTrigger>
				: ''}
			{room.status === 'decommissioned' ?
				<OverlayTrigger
					placement="right"
					delay={popoverTiming}
					overlay={<Tooltip id="button-tooltip">locale dismesso
						{actual_status && actual_status.with_end &&
							<>fino al {format(myTimestampToDate(actual_status.endDate), 'dd/MM/yyyy')}</>}
					</Tooltip>}
					message="locale dismesso"
				>

					<FontAwesomeIcon icon={'exclamation-circle'} className="text-dark mr-1" />
				</OverlayTrigger>
				: ''}
		</>
	)
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const RoomRefillData = ({ rd, do_print }) => {
	if (rd) {
		console.log("RD", rd)
		return (
			<Col>
				<Row>
					<Col>
						{Object.entries(rd).length}	x <FontAwesomeIcon icon="pump-soap" />
					</Col>
				</Row>
				{Object.entries(rd).map((d, dk) => (
					<Row key={dk}>
						{Object.entries(d[1]).map((e, ek) => (

							<>
								<Col key={ek}>
									{console.log(e[1], ek, e[1][ek])}
									<b>{e[1].dispenser_name}:</b>
								</Col>
								<Col>
									{e[1].material_name} <Badge>{e[1].quantity} {e[1].measure}</Badge>
								</Col>
							</>
						))}
					</Row>
				))}
			</Col>
		)

	} else {
		return (
			<></>
		)

	}

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const RoomDoneData = ({ rd, do_print }) => {
	if (rd) {

		if (rd.done) {

			if (do_print) {
				return (
					<Col>
						<Badge style={{ fontSize: 10 }} variant="success">&nbsp;&nbsp;</Badge>&nbsp;eseguito{/*  ({format(myTimestampToDate(rd.lastUpdate), 'HH:mm')}) */}
					</Col>
				)

			} else {
				return (
					<Col>
						<Badge style={{ fontSize: 10 }} variant="success">eseguito {/* ({format(myTimestampToDate(rd.lastUpdate), 'HH:mm')})*/}</Badge>
					</Col>
				)

			}

		} else {
			if (do_print) {
				return (
					<Col>
						<Badge style={{ fontSize: 10 }} variant="danger">&nbsp;&nbsp;</Badge>&nbsp;
						Non eseguito {/* ({format(myTimestampToDate(rd.lastUpdate), 'HH:mm')}) */}: {rd.undoneMotivation}
					</Col>
				)

			} else {
				return (
					<Col>
						<Badge style={{ fontSize: 10 }} variant="danger">Non eseguito {/* ({format(myTimestampToDate(rd.lastUpdate), 'HH:mm')}) */}: {rd.undoneMotivation}</Badge>
					</Col>
				)

			}

		}
	} else {
		return (
			<Col >
				<Badge style={{ fontSize: 10 }}>

					Dati incompleti
				</Badge>
			</Col>
		)

	}
}




////////////////////////////////////////////////////////////////////////////////////////////
export const filterRoom = (ev, room, rt, secondary) => {
	//console.log(ev.extendedProps.intervention_data.service.id, rt)
	let wd = undefined
	if (ev.extendedProps) {
		if (secondary) {
			//console.log(secondary, ev.extendedProps.intervention_data.secondary_custom_weekdays, room.id)
			wd = ev.extendedProps.intervention_data.secondary_custom_weekdays && ev.extendedProps.intervention_data.secondary_custom_weekdays[room.id] && ev.extendedProps.intervention_data.secondary_custom_weekdays[room.id][secondary]
				? ev.extendedProps.intervention_data.secondary_custom_weekdays[room.id][secondary]
				: undefined
			//console.log(wd, getDay(ev.start))
		} else {
			wd = ev.extendedProps.intervention_data.custom_weekdays && ev.extendedProps.intervention_data.custom_weekdays[room.id]
				? ev.extendedProps.intervention_data.custom_weekdays[room.id]
				: getSelectedElementB(ev.extendedProps.intervention_data.service.id, rt.service_list, 'service', 'id').weekdays

		}
	}
	if (wd === undefined) {
		return false
	}
	return wd.indexOf(getDay(ev.start).toString()) !== -1
}