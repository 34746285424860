import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Form, Button, } from 'react-bootstrap';

export const PhoneList = ({ element, functions, t, canWrite }) => {

	const newphone = {
		number: '',
		description: ''
	}

	const handleAddNewPhone = () => {
		let newphone_list = []
		if (element.phone_list){
			newphone_list = [...element.phone_list]
		}
		newphone_list.push(newphone)
		functions.edit({ name: 'phone_list', value: newphone_list })
	}
	const handleEditPhone = (el, index) => {
		let editlist = [...element.phone_list]
		let { name, value } = el
		let editphone = { ...editlist[index], [name]: value }
		editlist.splice(index, 1, editphone)
		functions.edit({ name: 'phone_list', value: editlist })
	}
	const handleDeletePhone = (index) => {
		let editlist = element.phone_list
		console.log('index old', index, editlist)
		editlist.splice(index, 1)
		functions.edit({ name: 'phone_list', value: editlist })
	}

	return (

		<Form.Group as={Row} controlId="formCompanyCF" className="border-bottom">
			<Form.Label column sm="2">{t('global.labels.phone')}</Form.Label>
			{element.phone_list && element.phone_list.length > 0
				?
				<Col>
					<Row>
						<Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleAddNewPhone()}><FontAwesomeIcon icon="plus-square" /> {t('company.labels.phone_add')}</Button></Col>
					</Row>
					{element.phone_list.map((i, key) => (
						<Row key={key}>
							<Col sm="4">
								<Form.Control type="text" name="description" value={i.description} onChange={(event) => handleEditPhone(event.currentTarget, key)} />
								<Form.Text className="text-muted">
									{t('global.labels.description')}
								</Form.Text>
							</Col>
							<Col sm="4">
								<Form.Control type="text" name="number" value={i.number} onChange={(event) => handleEditPhone(event.currentTarget, key)} />
								<Form.Text className="text-muted">
									{t('global.labels.number')}
								</Form.Text>
							</Col>
							<Col className="text-right">
								{key === element.phone_list.length - 1 ?
									<Button variant="outline-danger" size="sm" onClick={() => handleDeletePhone(key)}><FontAwesomeIcon icon="trash" /></Button>
									: <> </>
								}
							</Col>
						</Row>
					))}
				</Col>
				: <> <Col className="font-italic"> {t('global.messages.no_phone')}</Col>
					<Col className="text-right">
						{canWrite ?
							<Button variant="outline-primary" size="sm" onClick={() => handleAddNewPhone()}><FontAwesomeIcon icon="plus-square" /> {t('company.labels.phone_add')}</Button>
							: ''}
					</Col>
				</>
			}
		</Form.Group>
	)
}

export default PhoneList