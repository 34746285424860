import {firestore} from '../firebaseIndex'


///////////////////////////////////////////////////////////////////////////////
export const addWorkContract = async (domainId,  data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workcontracts')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addWorkContract error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getWorkContractById = async (domainId,  workcontractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workcontracts')
			.doc(workcontractId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getWorkContract error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getWorkContractList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workcontracts')
            .get();

        const workcontractList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return workcontractList;  
    } catch (e) {
        console.log("getWorkContract error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const editWorkContract = async (domainId,  workcontractId, data) => {
    try {
        const workcontract = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workcontracts')
            .doc(workcontractId);

        const res = await workcontract.set(
            data
        ).then( () => {
			console.log("editWorkContract - edit successfully")
			return data
		}).catch( error => {
			console.log("editWorkContract - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editWorkContract error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const deleteWorkContract = async (domainId,  workcontractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workcontracts')
            .doc(workcontractId).delete();
        return res
    }  catch (e) {
        console.log("deleteWorkContract error: ", e)
        return { error: e }
    }
}