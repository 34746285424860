import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Form, Button, } from 'react-bootstrap';

export const LaboratoryList = ({ element, functions, t, canWrite }) => {

	const newlaboratory = {
		number: '',
		description: ''
	}

	const handleAddNewLaboratory = () => {
		let newlaboratory_list = (element.laboratory_list)? [...element.laboratory_list]: []
		newlaboratory_list.push(newlaboratory)
		functions.edit({ name: 'laboratory_list', value: newlaboratory_list })
	}
	const handleEditLaboratory = (el, index) => {
		let editlist = [...element.laboratory_list]
		let { name, value } = el
		let editlaboratory = { ...editlist[index], [name]: value }
		editlist.splice(index, 1, editlaboratory)
		functions.edit({ name: 'laboratory_list', value: editlist })
	}
	const handleDeleteLaboratory = (index) => {
		let editlist = [...element.laboratory_list]
		console.log('index old', index, editlist)
		editlist.splice(index, 1)
		functions.edit({ name: 'laboratory_list', value: editlist })
	}

	return (

		<Form.Group as={Row} controlId="formCompanyCF" className="border-bottom">
			<Form.Label column sm="2">{t('contract.labels.analisys_laboratory_list')}</Form.Label>
			<Col>
					<Row>
						<Col>
                    <Form.Text className="text-muted">
                        Accreditato da ACCREDIA
                    </Form.Text>
						</Col>
						<Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleAddNewLaboratory()}><FontAwesomeIcon icon="plus-square" /> {t('contract.labels.laboratory_add')}</Button></Col>
					</Row>
			{element.laboratory_list && element.laboratory_list.length > 0
				?
				<>
					{element.laboratory_list.map((i, key) => (
						<Row key={key}>
							<Col>
								<Form.Control type="text" name="description" value={i.description} onChange={(event) => handleEditLaboratory(event.currentTarget, key)} />
							</Col>
							<Col className="text-right">
								<Button variant="outline-danger" size="sm" onClick={() => handleDeleteLaboratory(key)}><FontAwesomeIcon icon="trash" /></Button>
							</Col>
						</Row>
					))}
				</>
				:<>
				</>
			}
			</Col>
		</Form.Group>
	)
}

export default LaboratoryList