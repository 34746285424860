import React, { createContext, useState } from 'react'

const RoomContext = createContext()

export const RoomProvider = (props) => {
    const [roomGlobal, setRoomGlobal] = useState( [] )
    return (

        <RoomContext.Provider
            value={[
                roomGlobal,
                setRoomGlobal
            ]}>
        {props.children}

        </RoomContext.Provider>
    )
}
export default RoomContext