import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import DomainContext from './contexts/domainContext'


import ModalBaseObject from "./ModalBaseObject"
import AlertSection from "./AlertSection.js"
import ElementFormMedia from "./FormMedia.js"

import { getSelectedElement, checkComponentPresence } from "../util/ui_utils.js"
import { ModalInitButton } from './StructureGraphicElements.js'


import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { addFailureType as addFailureType2, editFailureType as editFailureType2 } from '../Firebase2/Storage2/dbFailureType';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';




const section = 'failuretype'
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalFailureType({ item, setItem, t, is_new, small, item_list, canWrite, ui_list }) {
	const { domain } = useContext(DomainContext)

	let { cid } = useParams();
	let contractId = cid

	const childRefAlert = useRef()
	const newfailuretype = {
		code: '',
		description: '',
		destination: ['client']
	}

	const initValues = async () => {
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newfailuretype)
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newfailuretype.media_list = []
		setCurrentElement(newfailuretype)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addFailureType2(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editFailureType2(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('failuretype.buttons.failuretype_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('failuretypes.labels.new_failuretype')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('failuretypes.labels.edit_failuretype')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('failuretypes.labels.view_failuretype')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={9}>
									<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)

	return (
		<>
			<Form.Group as={Row} controlId="failuretypeName" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="description" value={element.description} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="failuretypeCode" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1">
				<Form.Label column sm="2">{t('global.labels.destination')}</Form.Label>
				<Col>
					<Form.Control as="select" value={element.destination} name="destination" className="mb-2" onChange={(event) => functions.edit({ name: 'destination', type: 'select', value: event.target.value })}  >
						<option key="client" value="client" >{t('failuretypes.labels.select_client')}</option>
						<option key="supplier" value="supplier" >{t('failuretypes.labels.select_supplier')}</option>
					</Form.Control>
				</Col>
			</Form.Group>
			{console.log(ui_list)}
                                <Form.Group as={Row} controlId="userGroups" className="pb-1">
                                    <Form.Label column sm="2">{t('users.labels.messagegroup')}</Form.Label>
                                    <Col sm="3">
                                        {ui_list.msggroup_list && ui_list.msggroup_list.length > 0 ?
                                            <Form.Control as="select" className="mb-2" name="message_group_id" value={element.message_group_id} onChange={(event) => functions.edit({name: 'message_group_id',type: 'select', value:  event.target.value})} >
                                                <option value="-1" >{t('users.labels.select_group')}</option>
                                                    {ui_list.msggroup_list.map((f, k) => (
                                                        <option key={k} value={f.id} >{f.name}</option>
                                                    ))}
                                            </Form.Control>
                                            : <i>{t('user.messages.no_messagegroup')}</i>}
                                    </Col>
                                </Form.Group>
		</>
	)
}


export default translate()(ModalFailureType)