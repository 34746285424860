import React, { Component, useState, useContext , useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, FormControl, Row, Col, Form, Button, Tab, Nav, Spinner } from 'react-bootstrap';
import axios from 'axios';
import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
import { format, setDay } from 'date-fns'
import { it } from 'date-fns/locale'
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
//import "@nateradebaugh/react-datetime/scss/styles.scss";
import CurrencyInput from 'react-currency-input-field';
import {GFUNC_DOMAIN} from '../components/constants/cloudfunctionparams'

const newuser = {
	id: 0,
	username: '',
	avatar: '',
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
	last_login: new Date(),
	creation_date: new Date(),
	permission_list: [],
	is_active: true,
	category: 'admin',
	note: ''
}
const element_list = [
	{
		id: 1,
		username: 'test',
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
		avatar: '',
		last_login: new Date(),
		creation_date: new Date(),
		permission_list: ['products', 'registries', 'workareas'],
		is_active: true,
		category: 'admin',
		note: 'first user'
	}
]

const UsersView = (props) => {
	const t = props.t
	const { locale } = useContext(LocaleContext)
    const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([])
	const [lang, setLang] = useState('en')
        const isFirstRun = useRef(true);
        useEffect(() => {
            if (isFirstRun.current) {
                isFirstRun.current = false;
                return;
            }
            if (props.errors) {
                setErrors(props.errors);
            }
        }, [props]);

	const page_list = {

		registries: { name: "registries", label: t('global.pages.registries') },
		supplies: { name: "supplies", label: t('global.pages.supplies') },
		workareas: { name: 'workareas', label: t('global.pages.workarea') },
		equipement_development: { name: "equipement_development", label: t('global.pages.equipment_dev') },
		settings: { name: 'settings', label: t('global.pages.settings') },
		agenda: { name: 'agenda', label: t('global.pages.agenda') },
		products: { name: "products", label: t('global.pages.products') }
	}

	const [showEditUser, setShowUser] = useState(false);
	const [newEditUser, setNewUser] = useState(true);
	const [curElement, setCurrentElement] = useState(newuser)

	const handleClose = () => setShowUser(false);
	const handleSave = () => {
                setLoading(true);
		console.log(curElement)
		axios
			.post(GFUNC_DOMAIN+'/signup', curElement)
			.then((response) => {
				localStorage.setItem('AuthToken', `${response.data.token}`);
                setLoading(false);
	//			props.history.push('/');
				setShowUser(false);
			})
			.catch((error) => {
				setErrors( error.response.data)
				console.log(error)
                setLoading(false);
			});
	}
	const handleShowNew = () => {
		setCurrentElement(newuser)
		setNewUser(true)
		setShowUser(true)
	}
	const handleSubmit = (event) => {
		console.log(event)
		console.log('submit')
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		console.log(form)
		/* 			if (form.checkValidity() === false) {
					} */
	}
	const handleShowEdit = (el) => {
		setCurrentElement(el)
		setNewUser(true)
		setNewUser(false)
		setShowUser(true)
	}
	const onChangeHandler = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (event.checked) {
				oldcheck[value] = value;
			} else {
				delete oldcheck[value];
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}

	const renderPermissions = (p_list) => {
		return <>
			{p_list.map((i, k) => (
				<>
					{page_list[i]
						? <>{page_list[i].name}, </>
						: <i className="text-secondary">{i}, </i>
					}
				</>
			))}
		</>
	}
	return (
		<div>

			{/* <SubNavBarSettings curpage={'users'} t={t} /> */}
			<Container fluid>
				<Row className="mt-1">
					<Col className="h3">
					{t('navs.settings.users')}
					</Col>
					<Col sm={8}>
						<Form inline>
							<FormControl type="text" placeholder={t('search')} className="" />
							<Button variant="outline-secondary"><FontAwesomeIcon icon="search" /></Button>
						</Form>
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('users.buttons.add_new')}</Button>
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ml-1 text-muted small"  sm="1">{t('users.labels.id')}</Col>
							<Col className="bg-secondary ml-1">{t('users.labels.username')}</Col>
							<Col className="bg-secondary ml-1">{t('users.labels.category')}</Col>
							<Col className="bg-secondary ml-1">{t('users.labels.permissions')}</Col>
							<Col className="bg-secondary ml-1" sm="1">{t('users.labels.is_active')}</Col>
							<Col className="bg-secondary ml-1" sm="1"></Col>
						</Row>

						{element_list.map((i, key) => (
							<Row className="oneCompany p-1 border-bottom">
								<Col sm="1" className="text-muted small">{i.id}</Col>
								<Col>{i.username}</Col>
								<Col>{i.category}</Col>
								<Col>{renderPermissions(i.permission_list)}</Col>
								<Col sm="1">{i.is_active ? <FontAwesomeIcon icon="check" /> : ''}</Col>
								<Col sm="1">
									<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit(i)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
									<Button variant="danger" size="sm"><FontAwesomeIcon icon='trash' /> </Button>
								</Col>

							</Row>
						))}
					</>
					: <> <Row><Col>
						{t('users.messages.no_user')}
					</Col></Row>
					</>
				}
				<Modal show={showEditUser} onHide={handleClose} className="bg-secondary" size="xl">
					<Modal.Header closeButton>
						{newEditUser && <Modal.Title variant="primary">{t('users.modal.titlenew')}</Modal.Title>}
						{!newEditUser && <Modal.Title variant="info">{t('users.modal.titleedit')}</Modal.Title>}
					</Modal.Header>
					<Modal.Body>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item variant="secondary">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('users.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link variant="secondary" eventKey="tabLogs">{t('users.labels.logs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} page_list={page_list} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabLogs">
											<ElementFormLogs element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						<Button className="btn-myprimary" 
							form="companyForm" onClick={handleSave}
							disabled={loading || curElement.email.length<3 ||curElement.password.length<3}
							>
							<FontAwesomeIcon icon="save" />
							{loading ? <Spinner animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner> : ''}	
							{t('modal.save')}
						</Button>
					</Modal.Footer>
				</Modal>
			</Container>

		</div >
	);
}

export default translate()(UsersView)


function ElementFormDetails({ element, functions, page_list, t }) {
	return (
		<>
			<Form.Group as={Row} controlId="userFirstName" className="border-bottom">
				<Form.Label column sm="2">{t('users.labels.firstname')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="firstName" value={element.firstName} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="userFirstName" className="border-bottom">
				<Form.Label column sm="2">{t('users.labels.lastname')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="lastName" value={element.lastName} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="userFirstName" className="border-bottom">
				<Form.Label column sm="2">{t('users.labels.username')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="email" value={element.email} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="userFirstName" className="border-bottom">
				<Form.Label column sm="2">{t('users.labels.password')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="password" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="userFirstName" className="border-bottom">
				<Form.Label column sm="2">{t('users.labels.password')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="confirmPassword" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formUserCategory" className="border-bottom">
				<Form.Label column sm="2">{t('users.labels.category')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" defaultValue="1" className="mb-2" name="category" onChange={(event) => functions.edit(event.target)} >
						<option value="1" checked={(element.category === "-1")}>{t('users.labels.select_category')}</option>
						<option value="admin" checked={(element.category === "admin")}>admin</option>
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formQ" className="border-bottom">
				<Form.Label column sm="2">{t('users.labels.permissions')} </Form.Label>
				<Col>
					<Row>
						{Object.keys(page_list).map((i, k) => (
							<Col sm="4" key={i}>
								<Form.Check type="checkbox" checked={(element.permission_list.indexOf(page_list[i].name) >= 0)} value={page_list[i].name} label={page_list[i].label} />
							</Col>
						))}
					</Row>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formMachinIsActive" className="border-bottom">
				<Form.Label column sm="2">{t('users.labels.is_active')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="is_active" value={true} checked={(element && element.is_active)} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					<Col>
					<Form.Check type="radio" name="is_active" value={false} checked={(element && !element.is_active)} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="userNote" className="border-bottom">
				<Form.Label column sm="2">{t('users.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}

function ElementFormLogs({ element, functions, t }) {

	return (
		<>
			<Form.Group as={Row} controlId="formUserCF">
				<Col>
					<Row>
					</Row>
					{element.log_list && element.log_list.length > 0 ?
						<>
							<Row className="bg-secondary text-light">
								<Col>{t('users.labels.log_description')} </Col>
								<Col>{t('users.labels.log_date')} </Col>
								<Col>&nbsp;</Col>
							</Row>
							{element.log_list.map((i, key) => (
								<Row>
									<Col></Col>
									<Col>{i.is_recursive}</Col>
									<Col>{i.description}</Col>
									<Col>{format(i.date, 'dd/MM/yy')}</Col>
									<Col>{i.price}</Col>
									<Col>
										<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" /> </Button>
										<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
									</Col>
								</Row>))
							}
						</>
						: <> {t('users.messages.no_log')}</>
					}
				</Col>
			</Form.Group>
		</>
	)
}