import {firestore} from '../firebaseIndex'


const addWorkarea = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
		.doc(domainId)
		.collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('workareas')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addWorkarea error: ", e)
        return { error: e }
    }
}

const getWorkareaById = async (domainId, contractId, workareaId) => {
    try {
        const res = await firestore.collection('DOMAINS')
		.doc(domainId)
		.collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
			.collection('workareas')
			.doc(workareaId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getWorkarea error: ", e)
        return { error: e }
    }
}

const getWorkareaList = async (domainId, contractId, structure=null) => {
    try {
        if (structure === null) {
            const res = await firestore.collection('DOMAINS')
			.doc(domainId)
			.collection('contracts')
                .doc(contractId)
                .collection('baseCompany')
                .doc('BASE')
                .collection('workareas')
                .get();

            const workareaList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return workareaList;
        } else {
            const res = await firestore.collection('DOMAINS')
			.doc(domainId)
			.collection('contracts')
                .doc(contractId)
                .collection('baseCompany')
                .doc('BASE')
                .collection('workareas')
                .where('structures', 'array-contains', structure)
                .get();

            const workareaList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return workareaList;
        }
    } catch (e) {
        console.log("getWorkarea error: ", e)
        return { error: e }
    }
}


const editWorkarea = async (domainId, contractId, workareaId, data) => {
    try {
        const workarea = firestore.collection('DOMAINS')
		.doc(domainId)
		.collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('workareas')
            .doc(workareaId);

        const res = await workarea.set(
            data
        ).then( () => {
			console.log("editWorkarea - edit successfully")
			return data
		}).catch( error => {
			console.log("editWorkarea - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editWorkarea error: ", e)
        return { error: e }
    }
}


const deleteWorkarea = async (domainId, contractId, workareaId) => {
    try {
        const res = await firestore.collection('DOMAINS')
		.doc(domainId)
		.collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('workareas')
            .doc(workareaId).delete();
        return res
    }  catch (e) {
        console.log("deleteWorkarea error: ", e)
        return { error: e }
    }
}




export { addWorkarea };
export { getWorkareaById };
export { getWorkareaList };
export { editWorkarea };
export { deleteWorkarea };