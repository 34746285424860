import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, FormControl, Row, Col, Button, Spinner, ButtonGroup, InputGroup, Modal, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { format } from 'date-fns'

import DomainContext from '../components/contexts/domainContext'
import LocaleContext from '../components/contexts/locale.js'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"


import ModalEmployeeTraining from '../components/ModalEmployeeTraining';
import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"

import { checkComponentPresence, getElementCount, getSelectedElement, getValue, propComparator, getQualificationParam, compareName, myTimestampToDate } from "../util/ui_utils.js"



import { getEmployerList, deleteEmployer } from '../Firebase2/Storage2/dbEmployers';
import { getCleaningCompanyList as getCleaningCompanyList2 } from "../Firebase2/Storage2/dbCleaningCompany"
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { getWorkContractList as getWorkContractList2, } from "../Firebase2/Storage2/dbWorkContract"
import { getTrainingList as getTrainingList2} from "../Firebase2/Storage2/dbTraining"



let load_page = false



const TrainingReportView = ({ t, uData }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, structureElements } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()
	let { cid } = useParams();
	let contractId = cid

	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [cleaningcompany_list, setCleaningCompanyList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('trainingreport') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {
			contractId = contract.id
			const qualification_title = await getBaseObjectTypeList2(domain, 'qualification_title')
			const study_titles = await getBaseObjectTypeList2(domain, 'study_titles')
			const newcelement_list = await getCleaningCompanyList2(domain, contractId)
			let contract_list = await getWorkContractList2(domain, contractId)
			setCleaningCompanyList(newcelement_list)
			console.log("cleaningCompany", newcelement_list)
			let newtraining_list = await getTrainingList2(domain, contractId)
			setUiList({ ...ui_list, study_titles, qualification_title, contract_list, cleaningcompany_list: newcelement_list, training_list: newtraining_list.sort(compareName) })
			let _employers_list = await getEmployerList(domain, contractId, {})
			console.log("employeslist", _employers_list)
			_employers_list = _employers_list.sort(propComparator('lastname'))
			setElementList(_employers_list)
			setLoading(false)
		} else {
			console.log('problem')
		}
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("EMPLOYES reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("EMPLOYES init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useEffect(() => {
		console.log('EMPLOYES loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])

	const initNextValue = async () => {
		for (let c = 0; c < cleaningcompany_list.length; c++) {
			const newelement_list = await getEmployerList(domain, contractId, { companyId: c.id })
			console.log("employeslist", newelement_list)
			setElementList([...element_list, ...newelement_list])
		}
	}

	useEffect(() => {
		setLoading(false)
		initNextValue()
	}, [cleaningcompany_list])




	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteEmployer(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('employes.messages.delete_employes_title'), t('employes.messages.delete_employes'), cb)
	}


	const handleChangeSearch = (s) => {
		console.log(s)
		if (s.value.length > 1) {
			setSearchTerm(s.value)
		} else {
			setSearchTerm('')
		}
	}

	const getExpirationTime = (i) => {
		try {
			if (i.timing === 'temporarywork') {
				console.log(i.work_expiration)
				return (<>({format(myTimestampToDate(i.work_expiration), 'dd/MM/yyyy')})</>)
			} else {
				return ('')
			}

		} catch (err) {
			return (<></>)
		}

	}


	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col sm="6">
						<PageTitle title={t('global.pages.trainingreport')} />
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className=" p-1 text-light">
						<Col className="bg-secondary ml-1" sm="2">{t('employes.labels.register')}</Col>
						<Col className="bg-secondary ml-1" sm="2">{t('employes.labels.firstname')}</Col>
						<Col className="bg-secondary ml-1">{t('employes.labels.lastname')}</Col>
						<Col className="bg-secondary ml-1">{t('employes.labels.qualification')}</Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>

				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.firstname.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) || (i.lastname.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) || (i.register.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row key={key} className="element_row p-1 border-bottom">
										<Col sm="2">{i.register}</Col>
										<Col sm="2"><b>{i.firstname}</b></Col>
										<Col><b>{i.lastname}</b></Col>
										<Col>{getQualificationParam(i, ui_list.contract_list, 'name')}</Col>
										<Col className="text-center" sm="1">
											<ModalEmployeeTraining item={i} is_new={false} item_list={element_list} ui_list={ui_list} functions={{ updateData: onUpdateData }} setParentList={setElementList} canWrite={canWrite} t={t} />
										</Col>

									</Row>
								))}
							</>
							:
							<Row>
								<Col className="font-italic">
									{t('employes.messages.no_employe')}
								</Col>
							</Row>
						}
					</>
				}

			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('global.labels.employers') })} />

		</>
	);
}


////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
export const ModalFilter = ({ t, ui_list, filterAppliedList, setFilterAppliedList, defaultfilter, intervention_type }) => {
	//	const { intervention_dict } = useContext(InterventionContext)
	const { service_dict, structureElements } = useContext(GlobalDataContext)

	const [showFilter, setShowFilter] = useState(false)


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			setFilterAppliedList({ ...filterAppliedList, [section]: [] })
		} else {
			setFilterAppliedList(defaultfilter)
		}
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.contract.length > 0 ?
					<>
						{ui_list.contract_list.map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.contract.indexOf(d.id) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.contract.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('contract', d.id)}>
								{d.name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.contract.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('contract')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>

						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col>
								{t('global.labels.contracts')}
							</Col>
						</Row>
						<Row>
							{ui_list.contract_list && ui_list.contract_list.length > 0 ?
								<>
									{ui_list.contract_list.map((d, k) => (
										<Col sm="4" key={k}>
											<Button block size="sm" className="ml-1 mb-1 text-left" variant={filterAppliedList.contract.indexOf(d.id) === -1 ? "outline-secondary" : "secondary"}
												onClick={(e) => updateAppliedFilter('contract', d.id)}>
												{d.name}
											</Button>
										</Col>
									))}
								</>
								: ''
							}
						</Row>
						<Row>
							<Col>
								{t('global.labels.qualification')}
							</Col>
						</Row>
						<Row>
							<Col>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



export default translate()(TrainingReportView)





