import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Spinner, ButtonGroup, Badge, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalWorkflow from "../components/modal/ModalWorkflow"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"

import { InitContractHelper } from '../util/contract.js';
import { checkComponentPresence, compareName, getElementCount, rgba2hex, getStructureFromWorkareas, getTimeValue } from "../util/ui_utils.js"
import { ItemProgress, WeekDays, ServiceBadge, ModalInitButton, renderActivityPeriodListPopover } from '../components/StructureGraphicElements.js'
import FrequencySelector from '../components/FrequencySelector'

import { getWorkflowList as getWorkflowList2, deleteWorkflow as deleteWorkflow2, editWorkflow } from '../Firebase2/Storage2/dbWorkflow';

import { getInterventionById } from '../Firebase2/Storage2/dbIntervention'
import { getWorkareaList } from '../Firebase2/Storage2/dbWorkarea';
import InterventionContext from '../components/contexts/interventionContext';
import { InterventionProvider } from '../components/contexts/interventionContext';
import SearchContext, { SearchProvider } from '../components/contexts/searchContext';


//const contractId = sessionStorage.getItem('contractId')
const companyId = '0'
let load_page = false
let max_page_count = 6

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function filterPermissionData(w, structure_list, department_list) {
	//console.log("filter permission", structure_list, department_list)
	let ispermitted = false
	if (structure_list.length === 0) {
		ispermitted = true
	} else {
		if (department_list.length > 0) {
			if (w.department_list.filter(e => department_list.indexOf(e) !== -1).length > 0) {
				ispermitted = true
			}

		} else {
			if (w.structure_list.filter(e => structure_list.indexOf(e) !== -1).length > 0) {
				ispermitted = true
			}

		}
		/* if (department_list.length > 0) {
			console.log(w.department_list, department_list,w.department_list.some(d => department_list.filter(p => p === d) !== -1) )
			ispermitted = w.department_list.some(d => department_list.filter(p => p === d) !== -1)

		} else {
			ispermitted = w.structure_list.some(d => structure_list.filter(p => p === d !== -1))
			//ispermitted = w.structure_list.some(d => structure_list.filter(p => p.id === d.id)!== -1 )
		} */
	}
	return ispermitted

}
const WorkflowsView = ({ t, uData }) => {
	return (
		<InterventionProvider>
			<SearchProvider>
				<WorkflowsViewContent t={t} uData={uData} />
			</SearchProvider>
		</InterventionProvider>
	)
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const WorkflowsViewContent = ({ t, uData }) => {

	const { domain, domain_data } = useContext(DomainContext)
	const { contract, service_dict, structureElements, all_workarea_dict } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()
	let { cid } = useParams();
	let contractId = cid

	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [workarea_list, setWorkareaList] = useState([])
	//	const [searchTerm, setSearchTerm] = useState('')
	const { searchTerm, setSearchTerm } = useContext(SearchContext)
	const [canWrite, setWritePage] = useState(false)

	let _structure_list = structureElements.structure_list






	const initValues = async () => {
		console.log(uData)
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('workflow') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		const userData = uData
		console.log(domain, contractId, companyId)
		if (contract && domain.length > 0) {
			_structure_list = structureElements.structure_list
			console.log("structure list", _structure_list)
			let permission_department_list = []
			let permission_structure_list = []
			let permission_workarea_list = []
			if (userData && userData.contract_dict && userData.contract_dict[contractId]) {
				console.log('userData contract', userData.contract_dict[contractId])
				if (userData.contract_dict[contractId][0].mode === 'workarea') {
					permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
					permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
					console.log("permission_structure_list", permission_structure_list)
					_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
				} else {
					if (userData.contract_dict[contractId][0].structure) {
						if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
							permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
							console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
						}
						if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
							permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
							_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

						}
						console.log("permission structure_list", permission_structure_list)
					}
				}
			}

			let newelement_list = await getWorkflowList2(domain, contractId)
			newelement_list.sort(compareName)
			setElementList(newelement_list)
			let _workarea_list = await getWorkareaList(domain, contractId)
			let tintervention_count = 0
			console.log(_workarea_list)
			if (permission_workarea_list.length > 0) {
				_workarea_list = _workarea_list.filter(w => permission_workarea_list.indexOf(w.id)).filter(w => (w.service_summary && w.service_summary.length > 0))
			} else {
				_workarea_list = _workarea_list.filter(w => filterPermissionData(w, permission_structure_list, permission_department_list)).filter(w => (w.service_summary && w.service_summary.length > 0))
			}
			setWorkareaList(_workarea_list)

			setLoading(false)
			load_page = true
		} else {
			console.log("init contract return")

		}
	}


	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("iWORKFLOW reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("WORKFLOW init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
		return () => {
			load_page = false
		}
	}, [])




	useEffect(() => {
		console.log('workflow loadingcontract', load_page)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteWorkflow2(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('workflow.messages.delete_workflow_title'), t('workflow.messages.delete_workflow', { w_name: el.name }), cb)
	}










	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('global.pages.workflow')} />
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite ?
							<ModalWorkflow is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} canWrite={canWrite}  workarea_list={workarea_list}/>
							: ''}
					</Col>
				</Row>
				{!loading && element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1">{t('global.labels.name')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.interventions')}</Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}
			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col>
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:

					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row key={key} className="element_row p-1 border-bottom " >
										<Col><b>{i.name}</b></Col>
										<Col sm="1" className="p0 text-center">
											{i.intervention_list.length}
										</Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												<ModalWorkflow is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite} workarea_list={workarea_list}/>
												{canWrite ?
													<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							: <>
								<Row>
									<Col className="font-italic">
										{t('dpis.messages.no_workflow')}
									</Col>
								</Row>
							</>
						}
					</>
				}



			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('workflow.labels.workflow') })} />
		</>
	);
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneIntervention = ({ intervention_id, workarea, editWorkflowIntervention, t, globalEdit, count, velement_list }) => {

	const { domain, loadingdomain } = useContext(DomainContext)
	const { contractId, service_dict } = useContext(GlobalDataContext)
	const { intervention_dict, addDictIntervention, setInterventionDict } = useContext(InterventionContext)
	const { searchTerm } = useContext(SearchContext)

	const [intervention, setIntervention] = useState()
	const [loading, setLoading] = useState(false)
	const [editedElement, setEditedElement] = useState(false)




	const w = workarea

	useEffect(() => {

		async function fetchData() {
			setLoading(true)

			let intervention = intervention_dict[intervention_id]

			if (!intervention) {
				if (typeof intervention_id === 'string') {
					intervention = await getInterventionById(domain, contractId, intervention_id)
					//					console.log("SET", domain, contractId, intervention_id, intervention)
					//updateIntervention(intervention)
					setInterventionDict((i) => ({ ...i, [intervention.id]: intervention }))
					//					updateInterventionDict(intervention_id,  intervention)
					setIntervention(intervention)
				}
			} else {
				setIntervention(intervention)
			}
			setLoading(false)
		}
		fetchData()
	}, [])



	if (intervention) {
		if (searchTerm.length === 0 || (intervention.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)) {

			return (
				<Col>
					<Row className="mb-1 border-bottom border-light element_row">
						<Col>
							<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={<Tooltip>Numero Workflow a cui è associato questo intervento ({count})</Tooltip>}>
								<Badge variant={count > 0 ? "info" : 'warning'}>{count}</Badge>
							</OverlayTrigger>
							&nbsp;
							<b>{intervention.name}</b>
						</Col>
						<Col>
							<ServiceBadge service={service_dict[intervention.service.id]} />&nbsp;
							{intervention.enable_refill ?
								<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={<Tooltip>Ricarica dispenser</Tooltip>}>
									<FontAwesomeIcon icon="pump-soap" />
								</OverlayTrigger>
								: ''}
						</Col>
						<Col sm="1" className="text-center">
							<FontAwesomeIcon icon="user" /> &times; {intervention.operating_units}

						</Col>
						<Col sm="2" className="text-center p-0">
							{intervention.frequency_mode === 'ordinary' ?
								<>
									{intervention.use_activity_period ?
										<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderActivityPeriodListPopover(intervention.period_list)}>
											<Badge variant="secondary">{intervention.period_list.length} periodi</Badge>
										</OverlayTrigger>
										: ''}
									{getTimeValue(intervention.start)} - {getTimeValue(intervention.end)} <br />
									{intervention.weekdays ?
										<WeekDays weekdays={intervention.weekdays} />
										: ''}
								</>
								:
								<FrequencySelector element={intervention} mode='read' />
							}
						</Col>
						<Col sm="7">
							<Row>
								{velement_list.map((g, k) => (
									<Col key={k} className="text-center">
										{globalEdit ?
											<Form.Check type="checkbox" name={`interventiongroup_${intervention_id}_${g.id}`} value={intervention_id} checked={g.intervention_list && g.intervention_list.indexOf(intervention_id) >= 0} label="" onChange={(event) => editWorkflowIntervention(intervention_id, g.id)} />
											:
											<>
												{g.intervention_list && g.intervention_list.indexOf(intervention_id) >= 0 ?
													<FontAwesomeIcon icon="check" />
													: ''}
											</>
										}
									</Col>

								))}
							</Row>
						</Col>
					</Row>

				</Col>
			)
		} else {
			return (
				<></>
			)
		}
	} else {
		return <>
			<Spinner animation="grow" role="status" variant="info" />
		</>
	}

}






export default translate()(WorkflowsView)



