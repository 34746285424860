import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, Form, ButtonGroup, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useDrag, useDrop } from 'react-dnd'

import GlobalDataContext from './contexts/globaldataContext.js';

import ModalRoomtypeService from './ModalRoomtypeService'
import FrequencySelector from './FrequencySelector'
import { ServiceBadge, FrequencyRender } from './StructureGraphicElements.js'
import ModalRoomtypeActivitiesCopy from './ModalRoomtypeActivitiesCopy.js';




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormActivities({ element, functions, t, ui_list, canWrite }) {


	const handleAddService = (el) => {
		console.log(el)
		let editlist = element.service_list
		try {
        let { name, value, type } = el

			editlist.push(el)
			/* 			const c = checkComponentPresence(el, element.service_list, 'id')
						if (c >= 0) {
							alert('service present')
						} else {
						} */
		} catch (err) {
			editlist = [el]
		}
		functions.edit({ name: 'service_list', value: editlist })
	}

	const handleUpdateService  = (el, index) =>{

		let editlist = element.service_list
		editlist.splice(index, 1, el)
		functions.edit({ name: 'service_list', value: editlist })

	}

	const handleUpdateFromList = async (el) => {
		console.log('update', el)
		functions.edit({ name: 'service_list', value: el })
	}


	const removeService =({index, card}) => {
		let editlist = element.service_list
		editlist.splice(index, 1)
		functions.edit({ name: 'service_list', value: editlist })
	}
	return (
		<>

			<Form.Group as={Row} controlId="formServiceComponent" >
				<Col>
					{canWrite ?
						<Row>
							<Col className="mb-1 text-right">
								<ModalRoomtypeService is_new={true} t={t} item={element.service_list} setItem={handleAddService} canWrite={canWrite} />
								{canWrite &&
									<ModalRoomtypeActivitiesCopy t={t} item={element.service_list} setItem={handleUpdateFromList} canWrite={canWrite} />
								}
							</Col>
						</Row>
						: ''}
					<Row className="border-bottom h5">
						<Col sm="7" className="text-primary">
							{t('global.labels.activity_list')}
						</Col>
					</Row>
					<Row className="border-right mb-1">

						{element.service_list && element.service_list.length > 0 ?
							<Col>
								<Row className="mb-1">
									<Col className="bg-secondary text-light ml-1">{t('global.labels.service')} </Col>
									<Col className="bg-secondary text-light ml-1">{t('service.labels.operation_list')} </Col>
									<Col className="bg-secondary text-light ml-1">{t('service.labels.indicative_frequency')} </Col>
									<Col className="bg-secondary text-light ml-1" sm="1">{t('structure.labels.hourlyield')} </Col>
									<Col className="bg-secondary text-light ml-1 text-center" sm="1" >{t('global.labels.actions')}</Col>
								</Row>
								{/* 								<DndProvider backend={HTML5Backend}>
									<ServiceListContainer element_id={element.id} services={element.service_list} element={element} t={t} functions={{ update: handleUpdateFromList }} ui_list={ui_list} canWrite={canWrite} />
								</DndProvider> */}
								{element.service_list.map((card, k) => (

									<Row key={k} className='border-bottom mb-1 pb-1'>
										<Col>
											<ServiceBadge service={ui_list.service_dict[card.service.id]} freq={true} />
										</Col>
										<Col>
											<OperationSummary element={card} ui_list={ui_list} />
										</Col>
										<Col>
											<FrequencyRender element={card} />
										</Col>
										<Col sm="1">
											{card.hourlyyield} m<sup>2</sup> / h
										</Col>
										<Col sm="1 p-0 text-center">
											{canWrite ?
												<ButtonGroup>
													<ModalRoomtypeService is_new={false} item={card} setItem={handleUpdateService} index={k} canWrite={canWrite} />
													<Button variant="outline-danger" size="sm" onClick={() => removeService({ index: k, card: card })}><FontAwesomeIcon icon='trash' /> </Button>
												</ButtonGroup>
												: ''
											}
										</Col>
									</Row>
								)


								)}
							</Col>
							: <Col> {t('structure.messages.no_activity')}</Col>
						}
					</Row>
				</Col>
			</Form.Group>

		</>
	)
}



const ItemTypes = {
	CARD: 'card',
}
const cardstyle = {
	cursor: 'move',
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ServiceRow = ({ id, card, index, moveCard, removeCard, element, updateCard, ui_list, canWrite }) => {
	const ref = useRef(null)
	const { service_dict } = useContext(GlobalDataContext)
	const [cardHover, toggleHover] = useState(false)
	const [, drop] = useDrop({
		accept: ItemTypes.CARD,
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			if (dragIndex === hoverIndex) {
				return
			}
			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			const clientOffset = monitor.getClientOffset()
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			moveCard(dragIndex, hoverIndex)
			item.index = hoverIndex
		},
	})
	const [{ isDragging }, drag] = useDrag({
		item: { type: ItemTypes.CARD, id, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})
	drag(drop(ref))
	const editService = (el, index) => {
		console.log('freq el', el, index)
		updateCard(el, index)
		//		onUpdateFrequency(el, card, index)
	}
	if (card.service) {
		return (
			<Row ref={ref} className={isDragging ? ' border border-primary text-light' : 'border-bottom mb-1 pb-1'} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
				<Col>
					<ServiceBadge service={service_dict[card.service.id]} freq={true} />
				</Col>
				<Col>
					<OperationSummary element={card} ui_list={ui_list} />
				</Col>
				<Col>
					<FrequencyRender element={card} />
				</Col>
				<Col sm="1">
					{card.hourlyyield} m<sup>2</sup> / h
				</Col>
				<Col sm="1 p-0 text-center">
					{canWrite ?
						<ButtonGroup>
							<ModalRoomtypeService is_new={false} item={card} setItem={editService} index={index} canWrite={canWrite} />
							<Button variant="outline-danger" size="sm" onClick={() => removeCard({ index: index, card: card })}><FontAwesomeIcon icon='trash' /> </Button>
						</ButtonGroup>
						: ''
					}
				</Col>
			</Row>

		)

	} else {

		return (
			<Row ref={ref} className={isDragging ? ' border border-primary text-light' : 'border-bottom mb-1 pb-1'} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
				<Col>
					<Button className="mr-1" variant="secondary" size="sm" >{index + 1}</Button>
					{card.name}
				</Col>
				<Col>
					<OperationSummary element={card} ui_list={ui_list} />
				</Col>
				<Col>
					<FrequencySelector element={card} functions={{ edit: editService }} />
				</Col>
				<Col sm="1 p-0 text-center">
					{cardHover ?
						<>
							<Button variant="outline-danger" size="sm" onClick={() => removeCard({ index: index, card: card })}><FontAwesomeIcon icon='trash' /> </Button>
						</> : ''
					}
				</Col>
			</Row>

		)
	}
}
/* 
const ServiceListContainer = ({ services, i_index, element, functions, t, ui_list, canWrite }) => {
	const [service_list, setService] = useState(services)
	const childRefDelete = useRef()
	const moveService = useCallback(
		(dragIndex, hoverIndex) => {
			const dragService = service_list[dragIndex]
			console.log(dragService, dragIndex)
			setService(
				update(service_list, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragService],
					],
				}),
			)
		},
		[service_list],
	)
	const removeService = useCallback(
		(data) => {
			const dragService = service_list[data.index]
			const cb = () => {
				console.log("removeCard, cb", dragService, data)
				setService(
					update(service_list, {
						$splice: [
							[data.index, 1]
						],
					}),
				)
			}
			//TODO delete element name
			childRefDelete.current.confirmDelete(t('service.messages.delete_activity_title'), t('service.messages.delete_activity_message'), cb)
			//			functions.delete(cb)
		},
		[service_list],
	)
	const onUpdateCard = (editservice, index) => {
		setService(
			update(service_list, {
				$splice: [
					[index, 1, editservice],
				],
			}),
		)
	}
	const renderService = (card, index) => {
		return (
			<ServiceRow
				key={card.id}
				index={index}
				id={card.id}
				card={card}
				element={element}
				moveCard={moveService}
				removeCard={removeService}
				ui_list={ui_list}
				updateCard={onUpdateCard}
				canWrite={canWrite}
			/>
		)
	}
	useEffect(() => {
		//		console.log('new rooms', service_list)
		functions.update(service_list)
	},
		[service_list]
	);
	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			{service_list && service_list.length > 0
				? <div style={cardstyle}>
					{service_list.map((service, c) => renderService(service, c, element))}
				</div>
				: <>{t('roomtype.messages.no_activity')}</>
			}
		</>
	)
}

 */


const OperationSummary = ({ element, ui_list }) => {
	const { operation_dict } = useContext(GlobalDataContext)

	const OperationRow = ({ op, k }) => {
		if (operation_dict[op.id]) {
			return (
				<Row><Col><Badge variant="secondary">{k + 1}</Badge> {operation_dict[op.id].name}</Col></Row>
			)
		} else {
			console.log("ERROR", op, operation_dict[op.id])
			return (
				<Row><Col><Badge variant="dark">{k + 1}</Badge>
					<OverlayTrigger
						placement="auto"
						delay={{ show: 250, hide: 150 }}
						overlay=
						{<Tooltip id="button-tooltip">operazione non trovata</Tooltip>}
					><Badge variant="warning" size="md" className="mr-1">
							<FontAwesomeIcon icon="exclamation-triangle" />
						</Badge>
					</OverlayTrigger>
					{op.name}</Col></Row>
			)
		}
	}
	if (element.operation_list && element.operation_list.length > 0) {
		try {

			return (
				<>
					{element.operation_list.map((i, k) => (
						<OperationRow key={k} k={k} op={i} />
					))}
				</>
			)
		} catch (err) {
			return (
				<>
					{element.operation_list.map((i, k) => (
						<Row key={k}><Col><Badge variant="secondary">{k + 1}</Badge> {i.id}</Col></Row>
					))}
				</>
			)

		}
	} else {
		return (

			<></>
		)
	}

}
export default translate()(ElementFormActivities)