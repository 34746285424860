import React from 'react';


// < MATERIAL UI >
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// </ MATERIAL UI > 


// < NAV MENU ITEM >
import {NavBarCommunication} from './NavItemCommunication'
import {NavBarContract} from './NavItemContract'
import {NavBarSettings} from './NavItemSettings'
import {NavBarProceduralGuideline} from './NavItemProceduralGuideline'
import {NavBarSurfaceManagement} from './NavItemSurfaceManagement'
import {NavBarServicePlanning} from './NavItemServicePlanning'
import {NavBarChecks} from './NavItemChecks'
import {NavBarDocuments} from './NavItemDocuments'
import {NavBarReports} from './NavItemReports'
// </ NAV MENU ITEM>


// --------------------------------------------
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    companyname: {
        padding: theme.spacing(1, 1),
        color: 'black',
    },
    search: {
        position: 'relative',
        borderRadius: '5px',//theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        color: 'black'
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        color: 'black'
    },

}));

// =========================================================
export const NavBarGlobal = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    //------------------------
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
        </Menu>
    );


    // -------------------------
    return (
        <div className={classes.grow}>
            <AppBar position="static" style={{ backgroundColor: "#dddddd" }}>
                <Toolbar >
                    <div className={classes.root}>
                    <Paper elevation={3}>
                    <Grid container>
                        <Grid item xs={12} >
                            {/* FIXME: inserire l'immagine da DATABASE */}
                            <img  className={classes.companyname} 
                                width='64px' 
                                src='https://firebasestorage.googleapis.com/v0/b/clouditbase.appspot.com/o/demo%2Fglobal%2Fdemo.jpg?alt=media&token=ed95ad0f-3a17-46c2-b995-ccc92fa7e109' 
                                alt={window.sessionStorage.getItem('domain')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.companyname} variant="h6" noWrap>
                                {window.sessionStorage.getItem('domain')}
                            </Typography>
                        </Grid>
                    </Grid>
                    </Paper>
                    </div>

                    <NavBarContract />
                    <NavBarCommunication />
                    <NavBarProceduralGuideline/>
                    <NavBarSurfaceManagement/>
                    <NavBarServicePlanning/>
                    <NavBarChecks/>
                    <NavBarDocuments/>
                    <NavBarReports/>
                    <NavBarSettings/>
                    

                    <div className={classes.grow} />
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>

                    <div className={classes.sectionDesktop}>
                        <IconButton aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton aria-label="show 17 new notifications" color="inherit">
                            <Badge badgeContent={17} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </div>
    );
}