import {firestore} from '../firebaseIndex'



///////////////////////////////////////////////////////////////////////////////
//
//  ENVIRONMENT ELEMENT
//
///////////////////////////////////////////////////////////////////////////////
const addEnvironmentElement = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("environmentElements")
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addEnvironmentElement error: ", e)
        return { error: e }
    }
}

const getEnvironmentElementById = async (domainId, contractId, environmentElementId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("environmentElements")
            .get(environmentElementId);

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getEnvironmentElement error: ", e)
        return { error: e }
    }
}

const getEnvironmentElementList = async (domainId, contractId, category=null) => {
    try {
        if (category === null) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('baseCompany')
                .doc('BASE')
                .collection("environmentElements")
                .get();

            const List = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return List;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('baseCompany')
                .doc('BASE')
                .collection("environmentElements")
                .where('category', 'array-contains', category)
                .get();

            const List = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return List;
        }
    } catch (e) {
        console.log("getEnvironmentElement error: ", e)
        return { error: e }
    }
}


const editEnvironmentElement = async (domainId, contractId, environmentElementId, data) => {
    try {
        const element = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("environmentElements")
            .doc(environmentElementId);

        const res = await element.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editEnvironmentElement error: ", e)
        return { error: e }
    }
}


const deleteEnvironmentElement = async (domainId, contractId, environmentElementId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("environmentElements")
            .doc(environmentElementId).delete();
        return res
    }  catch (e) {
        console.log("deleteEnvironmentElement error: ", e)
        return { error: e }
    }
}




export { addEnvironmentElement };
export { getEnvironmentElementById };
export { getEnvironmentElementList };
export { editEnvironmentElement };
export { deleteEnvironmentElement };




///////////////////////////////////////////////////////////////////////////////
//
//  FURNITURE
//
///////////////////////////////////////////////////////////////////////////////

const addFurniture = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("furnitures")
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addFurniture error: ", e)
        return { error: e }
    }
}

const getFurnitureById = async (domainId, contractId, FurnitureId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("furnitures")
            .get(FurnitureId);

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getFurniture error: ", e)
        return { error: e }
    }
}

const getFurnitureList = async (domainId, contractId, category=null) => {
    try {
        if (category === null) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('baseCompany')
                .doc('BASE')
                .collection("furnitures")
                .get();

            const List = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return List;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('baseCompany')
                .doc('BASE')
                .collection("furnitures")
                .where('category', 'array-contains', category)
                .get();

            const List = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return List;
        }
    } catch (e) {
        console.log("getFurnitureList error: ", e)
        return { error: e }
    }
}


const editFurniture = async (domainId, contractId, FurnitureId, data) => {
    try {
        const element = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("furnitures")
            .doc(FurnitureId);

        const res = await element.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editFurniture error: ", e)
        return { error: e }
    }
}


const deleteFurniture = async (domainId, contractId, FurnitureId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("furnitures")
            .doc(FurnitureId).delete();
        return res
    }  catch (e) {
        console.log("deleteFurniture error: ", e)
        return { error: e }
    }
}




export { addFurniture };
export { getFurnitureById };
export { getFurnitureList };
export { editFurniture };
export { deleteFurniture };




///////////////////////////////////////////////////////////////////////////////
//
//  ECONOMICAL MATERIAL
//
///////////////////////////////////////////////////////////////////////////////

const addEconomicalMaterial = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("economicalMaterials")
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addEconomicalMaterial error: ", e)
        return { error: e }
    }
}

const getEconomicalMaterialById = async (domainId, contractId, EconomicalMaterialId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("economicalMaterials")
            .get(EconomicalMaterialId);

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getEconomicalMaterial error: ", e)
        return { error: e }
    }
}

const getEconomicalMaterialList = async (domainId, contractId, category=null) => {
    try {
        if (category === null) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('baseCompany')
                .doc('BASE')
                .collection("economicalMaterials")
                .get();

            const List = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return List;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('baseCompany')
                .doc('BASE')
                .collection("economicalMaterials")
                .where('category', 'array-contains', category)
                .get();

            const List = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return List;
        }
    } catch (e) {
        console.log("getEconomicalMaterialList error: ", e)
        return { error: e }
    }
}


const editEconomicalMaterial = async (domainId, contractId, EconomicalMaterialId, data) => {
    try {
        const element = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("economicalMaterials")
            .doc(EconomicalMaterialId);

        const res = await element.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editEconomicalMaterial error: ", e)
        return { error: e }
    }
}


const deleteEconomicalMaterial = async (domainId, contractId, EconomicalMaterialId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection("economicalMaterials")
            .doc(EconomicalMaterialId).delete();
        return res
    }  catch (e) {
        console.log("deleteEconomicalMaterial error: ", e)
        return { error: e }
    }
}




export { addEconomicalMaterial };
export { getEconomicalMaterialById };
export { getEconomicalMaterialList };
export { editEconomicalMaterial };
export { deleteEconomicalMaterial };