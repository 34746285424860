import React, { useState,  useEffect, useRef,  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import {  checkComponentPresence } from "../util/ui_utils.js"
import AlertSection from "../components/AlertSection.js"

import { editTemplate } from '../components/FirebaseApi/template_api';





function ModalTemplate({ item, setItem, t, is_new, small, item_list, index, canWrite }) {

	const childRefAlert = useRef()
	const initValues = async () => {
	}


	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState({})
	const [editedElement, setEditedElement] = useState(false)



	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)

		let return_data = await editTemplate(curElement.id, curElement) 
		console.log("edit", return_data)
		if (return_data && return_data.error) {
			childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
		} else {
			console.log(item_list)
			let edit_list = [...item_list]
			const c = checkComponentPresence(curElement, item_list, 'id')
			edit_list.splice(c, 1, curElement)
			setItem(edit_list)
			setShow(false);
		}
	}

	const handleArchive = async () => {
		curElement.isArchived = true
		let return_data =  await editTemplate( curElement.id, curElement)
		console.log("edit", return_data)
		if (return_data && return_data.error) {
			childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
		} else {
			console.log(item_list)
			let edit_list = [...item_list]
			const c = checkComponentPresence(curElement, item_list, 'id')
			edit_list.splice(c, 1, curElement)
			setItem(edit_list)
			setShow(false);
		}
	}
	return (
		<>
			<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title variant="info">{t('templates.modal.titleedit')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="templateName" className="border-bottom pb-1">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="name" value={curElement.name} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						{item.isArchived ?
							<Form.Group as={Row} controlId="templateName" className="border-bottom">
								<Form.Label column sm="2">{t('global.labels.is_archived')}</Form.Label>
								<Col sm="8">
									<Form.Check type="radio" name="isArchived" value={true} checked={curElement.isArchived} label={t('global.labels.yes')} onChange={(event) => onChangeHandler(event.currentTarget)} />
									<Form.Check type="radio" name="idArchived" value={false} checked={!curElement.isArchived} label={t('global.labels.no')} onChange={(event) => onChangeHandler(event.currentTarget)} />
								</Col>
							</Form.Group>
							: ''
						}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{!item.isArchived?
					<Button variant="outline-danger" onClick={handleArchive}>
						<FontAwesomeIcon icon="archive" /> {t('modal.archive')}
					</Button>
					:''
					}
					<Button variant={editedElement ? "warning" : "primary"} form="companyForm" onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



export default translate()(ModalTemplate)