import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addEvent = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('agenda')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addEvent error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const addEventBatch = async (domainId, contractId, data_array ) => {
    try {

        var batch = firestore.batch()
        
        data_array.forEach((doc) => {
            batch.set( firestore.collection('DOMAINS').doc(domainId).collection('contracts').doc(contractId).collection('agenda').doc(), doc);
          }
        )
          // Commit the batch
        const res = await batch.commit().then(  function () {
              return true;
          });
        return res
        /*
        const res = await firestore.collection('contracts')
            .doc(contractId)
            .collection('agenda')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
        */
    } catch (e) {
        console.log("addEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getEventById = async (domainId, contractId, eventId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('agenda')
            .get(eventId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getEventList = async (domainId, contractId, subject=null) => {
    try {
        if (subject === null || subject === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('agenda')
                .get();

            const eventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return eventList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('agenda')
                .where('subject', '==', subject)
                .get();

            const eventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return eventList;
        }
    } catch (e) {
        console.log("getEventList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getEventRange = async (domainId, contractId, startDate, endDate) => {
    try {
       const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('agenda')
                .where('start', ">=", startDate)
                .where('start', "<=", endDate)
                .get();

            const eventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return eventList;
        
    } catch (e) {
        console.log("getEventList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editEvent = async (domainId, contractId, eventId, data) => {
    try {
        const event =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('agenda')
            .doc(eventId);

        const res = await event.set(data)
            .then( () => {
                console.log("editEvent - edit successfully")
                return data
            }).catch( error => {
                console.log("editEvent - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteEvent = async (domainId, contractId, eventId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('agenda')
            .doc(eventId).delete();
        return res
    }  catch (e) {
        console.log("deleteEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteEventBatch = async (domainId, contractId, interventionId) => {
    try {
        const event_ref = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('agenda')
            .where("extendedProps.intervention_id", "==", interventionId)
        

        const retValue = await event_ref
            .get()
            .then( snapshot => {
                let batchArray = []
                let operationCount = 0;
                let batchIndex = 0;
                batchArray.push(firestore.batch());

                snapshot.docs.forEach( doc => {
                    batchArray[batchIndex].delete(doc.ref)
                    operationCount++;
                    if( operationCount === 499){
                        batchArray.push(firestore.batch());
                        operationCount = 0;
                        batchIndex++;
                    }
                });

                batchArray.forEach( async batch => await batch.commit())

            })
        return retValue

    }  catch (e) {
        console.log("deleteEventBatch error: ", e)
        return { error: e }
    }
}



