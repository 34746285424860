
import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Form, Button, Card, ButtonGroup, Spinner, ToggleButtonGroup, OverlayTrigger, Tooltip, InputGroup, } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import CurrencyInput from 'react-currency-input-field';
import { format, setMonth, setDay, endOfMonth, differenceInMonths, subDays, addDays } from 'date-fns'
import { it } from 'date-fns/locale'
import _ from "lodash";
import { useReactToPrint } from 'react-to-print';

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import EditContractContext, { EditContractProvider } from '../components/contexts/editContractContext'
import { InitContractHelper } from "../util/contract"

import { servicePaymentModeParams_dict, payment_mode_dict } from '../components/constants/global_definitions';

import { month_list } from '../components/constants/global_definitions';
import { compareOrder, getValue, rgba2hex, checkComponentPresence, myTimestampToDate, setMyDate, getAmountValue, getFloatNumber, getSelectedElement, getMonthLength } from '../util/ui_utils.js';
import { RiskareaBadge, ServiceBadge } from "../components/StructureGraphicElements"

import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import Footer from "../components/Footer"


import { addAmount, editAmount, getAmountRange } from '../Firebase2/Storage2/dbAmountRecords.js';
import { getStatusSnapshotRange } from '../Firebase2/Storage2/dbStatusSnapshot.js';
import { getEventRange } from '../Firebase2/Storage2/dbAgenda';



import firebase from 'firebase/app'
import { getInterventionById } from '../Firebase2/Storage2/dbIntervention.js';
import { YearSelector } from '../components/YearSelector.js';
import PrintFooter from '../components/generic/PrintFooter.js';

let load_page = false


const AmountView = ({ t, uData }) => {
	return (
		<EditContractProvider>
			<AmountViewContent t={t} uData={uData} />
		</EditContractProvider>
	)
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const AmountViewContent = ({ t, uData }) => {
	const { domain } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict } = useContext(GlobalDataContext)
	const { structure_list, setStructureList } = useContext(EditContractContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid
	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(false)
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)

	const now = new Date()
	const [selectedYear, setSelectedYear] = useState(now.getFullYear())

	const getContractMonths = () => {
		const value = parseInt(differenceInMonths(myTimestampToDate(contract.endDate), myTimestampToDate(contract.startDate))) + 1
		let value_ext = 0
		if (contract.extension_month && contract.extension_month_active) {
			value_ext = parseInt(contract.extension_month)
		}
		return [value, value_ext]
	}


	const initValues = async () => {
		const userData = uData
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('economicreport') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		load_page = true

		const contract_month = getContractMonths()
		let _structure_list = structureElements.structure_list
		let edit_permission_department_list = []
		let permission_structure_list = []
		console.log("SERVICEAMOUNT", contract.serviceamounthour_list, structure_list)
		let _start_date = firebase.firestore.Timestamp.fromDate(new Date(selectedYear, 0, 1))
		let _end_date = firebase.firestore.Timestamp.fromDate(new Date(selectedYear, 11, 31))
		if (contract.serviceamounthour_list && contract.serviceamounthour_list.filter(s => s.competence_period === 'contract').length > 0) {
			console.log("...***********ALL CONTRACTé******==============")
			_start_date = contract.startDate
			_end_date = contract.endDate
		}
		if (userData && userData.contract_dict) {
			console.log('userData contract', userData.contract_dict[contractId])
			if (userData.contract_dict[contractId] && userData.contract_dict[contractId][0].structure) {
				if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
					edit_permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
					console.log("permission department_list", edit_permission_department_list, userData.contract_dict[contractId][0].department)
				}
				if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
					permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
					_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
				}
				console.log("permission structure_list", permission_structure_list)
			}
		}
		for (let i = 0; i < _structure_list.length; i++) {
			let return_data = await getAmountRange(domain, contractId, _start_date, _end_date, _structure_list[i].id)
			console.log("**** amount month list", return_data)
			//return_data.map(r => console.log(myTimestampToDate(r.date), myTimestampToDate(r.lastEdit)))
			_structure_list[i].amount_summary_list = return_data
		}
		setStructureList(_structure_list)
		setUiList({ ...ui_list, risk_area_list, risk_area_dict, roomtype_list, roomtype_dict, contract_month })
		//		setElementList(return_data)
		setLoading(false)
	}


	useEffect(() => {
		load_page = false
		async function loadData() {
			let contractId = cid
			console.log("MONTH AMOUNT reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("MONTH AMOUNT init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])


	useEffect(() => {
		console.log('MONTH AMOUNT change contract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])


	const updateStructureAmount = (_structure_list) => {
		setStructureList(_structure_list)

	}
	useEffect(() => {
		if (contract) {

			console.log(selectedYear)
			initValues()
		}

	}, [selectedYear])

	const changeYear = (cc) => {
		setSelectedYear(cc)
	}





	const TotalAmount = ({ s, m, y }) => {
		try {

			const t = s.amount_summary_list.filter(a => a.year === y && a.month === m)[0].total_amount
			return (
				<>{t.toFixed(2)}</>
			)
		} catch (err) {
			return ("-")
		}
	}
	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />



			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>

						<PageTitle title={t('global.pages.amount_report')} />
					</Col>
					{!loading && contract ?
						<Col>
							<YearSelector startDate={contract.startDate} endDate={contract.endDate} selectedYear={selectedYear} changeYear={changeYear} />
						</Col>
						: ''}
				</Row>
				{loading && !contract ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>

						</Col>
					</Row>
					:
					<>
						{structure_list && structure_list.length > 0 ?
							<Row className="mb-1">
								<Col className="bg-secondary ml-1 text-light" sm="2">
									{t('global.labels.name')}
								</Col>
								<Col>
									<Row>
										{month_list.map((m, mk) => (

											<Col key={mk} className="bg-secondary ml-1 text-light" >
												{m.label}
											</Col>
										))}
									</Row>
								</Col>
							</Row>
							: ''}
					</>
				}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col>
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:
					<Row className="mt-1">
						{structure_list && structure_list.length > 0 ?
							<Col>
								{structure_list.map((s, key) => (
									<Row className="mb-1 pb-1 border-bottom" key={key}>
										<Col className="font-weight-bold" sm="2">
											{s.name} {selectedYear}
										</Col>
										<Col>
											<Row>
												{month_list.map((m, mk) => (
													<Col key={mk}>
														<ModalStructureAmount t={t} item={s} structure={s} item_list={structure_list} month={m} year={selectedYear} ui_list={ui_list} canWrite={canWrite} setItem={updateStructureAmount} />
														<TotalAmount s={s} m={m.id} y={selectedYear} />

													</Col>
												))}
											</Row>
										</Col>
									</Row>
								))}
							</Col>
							:
							<Col className="font-italic">
								{t('structure.labels.no_structure')}
							</Col>
						}
					</Row>

				}

			</Container>
			<Footer />
		</>
	);
}

const getStartDate = (start) => {
	let st = myTimestampToDate(start)
	st.setHours(0, 0, 0)
	return (firebase.firestore.Timestamp.fromDate(st))
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalStructureAmount = ({ item, structure, control_mode, canWrite, ui_list, t, item_list, setItem, year, month }) => {
	const { contract, } = useContext(GlobalDataContext)

	const [total_amount, setTotalAmount] = useState(0)
	const [ra_amount, setRaAmount] = useState(0)
	const [requested_amount, setRequestedAmount] = useState(0)
	const [fee_amount, setFeeAmount] = useState(0)
	const [month_data, setMonthData] = useState([])
	const [is_new, setIsNew] = useState(true)


	//	const _serviceamounthour_list = _.cloneDeep(contract.serviceamounthour_list)

	const newfeedata = { name: '', hour_price: 0, hours: 0, total: 0 }
	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contractId } = useContext(GlobalDataContext)
	const [loading, setLoading] = useState(false)


	const childRefAlert = useRef()
	const componentRef = useRef();

	const [showPlanEdit, setShowAmountEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curStructure, setCurStructure] = useState()
	const [curMonthAmount, setCurMonthAmount] = useState()

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle,
		documentTitle: `Info Fatturazione mensile: ${month.label} ${year}: ${structure.name}`
	});

	const handleShowEditMonthAmount = async () => {
		setCurStructure(structure)
		const start_month = new Date(year, month.id, 1)
		const end_month = endOfMonth(start_month)
		const startDate = format(start_month, 'yyyy_MM_dd')
		const endDate = format(addDays(end_month, 1), 'yyyy_MM_dd')
		setShowAmountEdit(true)
		const _month_data = await getStatusSnapshotRange(domain, contractId, structure.id, startDate, endDate)
		//console.log("SNAPSHOT LIST", _month_data)
		setMonthData(_month_data)
		const prev_data = structure.amount_summary_list.filter(a => a.year === year && a.month === month.id)
		if (prev_data.length > 0) {
			setCurMonthAmount(prev_data[0])
			setIsNew(false)
		} else {
			setIsNew(true)
			const newmonth_amount = {
				risk_area_summary: {},
				month: month.id,
				year: year,
				date: getStartDate(setMyDate(start_month)),
				risk_area: { total_mq: 0, undone_mq: 0, undone_days: 0, },
				risk_area_summary: JSON.parse(JSON.stringify(contract.risk_area_summary)),
				serviceamounthour_list: contract.serviceamounthour_list ? JSON.parse(JSON.stringify(contract.serviceamounthour_list)) : [],
				structure_id: structure.id,
				fee_amount: 0,
				ra_amount: 0,
				requested_amount: 0,
				lastEdit: setMyDate(new Date())
			}
			//console.log("____data___", contract.serviceamounthour_list, newmonth_amount.serviceamounthour_list)
			setCurMonthAmount(newmonth_amount)

		}
		setEditedElement(false)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)

	}


	const handleClosePlan = () => {
		setShowAmountEdit(false)
	}

	const updateTotal = () => {
		console.log("update total", ra_amount, fee_amount, requested_amount)
		const _total = ra_amount + fee_amount + requested_amount
		setTotalAmount(_total)
	}


	useEffect(() => {
		updateTotal()
	}, [ra_amount, fee_amount, requested_amount])

	const handleSaveAmountSummary = async () => {
		console.log("____________________________________SAVE_____________________________", curMonthAmount)
		let t_element_list = [...item_list]
		const c = checkComponentPresence(structure, item_list, 'id')
		//console.log(t_element_list, c)
		let _structure = t_element_list[c]
		curMonthAmount.total_amount = total_amount
		if (is_new) {
			const return_data = await addAmount(domain, contractId, curMonthAmount)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				_structure.amount_summary_list = [..._structure.amount_summary_list, curMonthAmount]
				t_element_list.splice(c, 1, _structure)
				setItem(t_element_list)
				console.log("SET LIST", t_element_list)
				setShowAmountEdit(false);
			}
		} else {
			let return_data = await editAmount(domain, contractId, curMonthAmount.id, curMonthAmount)
			//console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const ca = checkComponentPresence(curMonthAmount, _structure.amount_summary_list, 'id')
				if (ca >= 0) {
					_structure.amount_summary_list.splice(ca, 1, curMonthAmount)
				}
				t_element_list.splice(c, 1, _structure)
				setItem(t_element_list)
				//console.log("SET LIST", t_element_list)
				setShowAmountEdit(false);
			}
		}
	}





	const getMonthStatus = () => {
		const m_startdate = new Date(year, month.id, 1)
		const m_enddate = endOfMonth(m_startdate)
		if (m_enddate >= myTimestampToDate(contract.startDate) && m_startdate <= myTimestampToDate(contract.endDate)) {
			return false
		} else {
			return true
		}
	}



	useEffect(() => {
		setLoading(true)
		console.log("....CUR MONTH AMOUNT", curMonthAmount)
		setLoading(false)
	}, [curMonthAmount])


	return (
		<>
			<AlertSection ref={childRefAlert} />
			<Button disabled={getMonthStatus()} className="mr-1" variant="info" size="sm" onClick={() => handleShowEditMonthAmount()}><FontAwesomeIcon icon="eye" /> </Button>
			{curStructure ?
				<Modal show={showPlanEdit} onHide={handleClosePlan} dialogClassName="custom-modal" bsclass="full-modal" backdrop="static" scrollable={true}>
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						<Modal.Title>
							{is_new ?
								'nuovo' : 'modifica'}&nbsp;
							<FontAwesomeIcon icon="info" /> <span className="text-info">{t('contract.labels.view_month_amount')}</span> {month.label} {year}: {structure.name}&nbsp;
							{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid ref={componentRef}>
							<div className="printonly_visible mb-2">
								<FontAwesomeIcon icon="info" /> <span className="text-info">{t('contract.labels.view_month_amount')}</span> {month.label} {year}: {structure.name}&nbsp;
							</div>
							{!loading && curMonthAmount ?
								<>
									{console.log("RENDER MONTH DATA", month_data, curMonthAmount)}
									<MonthRiskareaAmountList t={t} ui_list={ui_list} month={month} functions={{ setSubTotal: setRaAmount, setItem: setCurMonthAmount }} setItem={setRaAmount} item={curMonthAmount} month_data={month_data} />
									<MonthServiceamountList t={t} ui_list={ui_list} month={month} functions={{ setSubTotal: setFeeAmount, setItem: setCurMonthAmount }} setItem={setFeeAmount} item={curMonthAmount} />
									<MonthServiceamountRequestList t={t} ui_list={ui_list} month={month} functions={{ setSubTotal: setRequestedAmount, setItem: setCurMonthAmount }} item={curMonthAmount} month_item={item} structure={structure} />
								</>
								: <></>}
							<div className="printonly_visible mb-2">

								<Row className="h4">
									<Col className="text-right">
										Totale mese {month.label}
									</Col>
									<Col className="font-weight-bold">
										<CurrencyInput
											name={"totalamountmonth"}
											value={(total_amount).toFixed(2)}
											allowDecimals={true}
											decimalsLimit={2}
											decimalSeparator=","
											className=" text-right text-light font-weight-bold h5 bg-info border-0 p-2 mr-4"
											groupSeparator=" "
											prefix="€"
											disabled={true}
											precision={2}
										/>
									</Col>
									<Col className="text-right">
									</Col>
								</Row>
								<PrintFooter />
							</div>
						</Container>

					</Modal.Body>
					<Modal.Footer>
						<Container fluid>
							<Row className="h4">
								<Col className="text-right">
									Totale mese {month.label}
								</Col>
								<Col className="font-weight-bold">
									<CurrencyInput
										name={"totalamountmonth"}
										value={(total_amount).toFixed(2)}
										allowDecimals={true}
										decimalsLimit={2}
										decimalSeparator=","
										className=" text-right text-light font-weight-bold h5 bg-info border-0 p-2 mr-4"
										groupSeparator=" "
										prefix="€"
										disabled={true}
										precision={2}
									/>
								</Col>
								<Col className="text-right">
									<Button variant="secondary" onClick={handleClosePlan} className="mr-1">
										{t('modal.close')}
									</Button>
									<Button variant="info" onClick={handlePrint}>
										<FontAwesomeIcon icon="print" />  {t('modal.print')}
									</Button>
									{canWrite ?
										<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveAmountSummary}>
											<FontAwesomeIcon icon="save" /> {t('modal.save')}
										</Button>
										: ''}
								</Col>
							</Row>
						</Container>
					</Modal.Footer>
				</Modal>
				: ''}
		</>
	)
}





const MonthRiskareaAmountList = ({ ui_list, functions, t, canWrite, item, month_data, month }) => {


	const { locale } = useContext(LocaleContext)
	const { contract } = useContext(GlobalDataContext)
	const [_month_data, setMonthData] = useState(month_data)

	const [risk_area_amount, setRiskareaAmount] = useState(item.risk_area_amount || {})
	const [risk_area_totalamount, setRiskAreaTotalAmount] = useState({ year: 0, contract: 0, full_size: 0, full_month: 0, closed_size: 0, closed_days: 0, closed_price: 0, net_month: 0 })
	const [risk_area_summary, setRiskareaSummary] = useState()
	//    const [service_amount, setServiceAmount] = useState(element.service_amount || [])
	//const [service_hour_amount, setServiceHourAmount] = useState(element.service_hour_amount || [])


	const default_working_days = 30



	useLayoutEffect(() => {
		console.log("UPDATE new month data")
		let temp_total = { mq: 0, year: 0, contract: 0, full_size: 0, full_month: 0, closed_size: 0, closed_days: 0, closed_price: 0, net_month: 0 }
		let _month_riskarea_summary = JSON.parse(JSON.stringify(contract.risk_area_summary))//FIXME ottenere da fine mese precendete

		const ra_summary_month_data = month_data
		try {
			//console.log(contract.risk_area_summary, contract.risk_area_amount)
			for (const ra_id in _month_riskarea_summary) {
				if (contract.risk_area_amount[ra_id]) {
					_month_riskarea_summary[ra_id] = {
						..._month_riskarea_summary[ra_id],
						closed_days: 0,
						closed_size: 0,
						closed_price: 0,
						one_price: getFloatNumber(contract.risk_area_amount[ra_id]),
						price: getFloatNumber(contract.risk_area_amount[ra_id]) * getFloatNumber(_month_riskarea_summary[ra_id].size),
						price_day: getFloatNumber(contract.risk_area_amount[ra_id]) / contract.working_days || default_working_days,
						net_month: getFloatNumber(contract.risk_area_amount[ra_id]) * getFloatNumber(_month_riskarea_summary[ra_id].size)
					}

				} else {
					_month_riskarea_summary[ra_id] = {
						..._month_riskarea_summary[ra_id],
						closed_days: 0,
						closed_size: 0,
						closed_price: 0,
						one_price: 0,
						price_day: 0,
						price: 0,
						net_month: 0
					}

				}
			}
			//console.log("MONTH SUMMARY pre", _month_riskarea_summary)
			for (const one_day of ra_summary_month_data) {
				//console.log("ONE DAY DATA", one_day, one_day.risk_area_summary_suspended, one_day.risk_area_summary_decommissioned)
				for (const ra_k in one_day.risk_area_summary_suspended) {
					const one_ra_data = one_day.risk_area_summary_suspended[ra_k]
					try {
						const ra_day_price = _month_riskarea_summary[ra_k].price_day
						const one_ra_price = one_ra_data.size * ra_day_price
						//console.log("susp", one_ra_data.size, ra_k, _month_riskarea_summary[ra_k].price, _month_riskarea_summary[ra_k].closed_price, one_ra_price)
						if (one_ra_data.room_count > 0) {

							_month_riskarea_summary[ra_k] = {
								..._month_riskarea_summary[ra_k],
								closed_days: _month_riskarea_summary[ra_k].closed_days + 1,
								closed_size: _month_riskarea_summary[ra_k].closed_size + one_ra_data.size,
								closed_price: _month_riskarea_summary[ra_k].closed_price + one_ra_price,
								net_month: _month_riskarea_summary[ra_k].price - (_month_riskarea_summary[ra_k].closed_price + one_ra_price)
							}
						}
					} catch (err) {
						console.log("susp ERROR")
					}

				}
				for (const ra_k in one_day.risk_area_summary_decommissioned) {
					const one_ra_data = one_day.risk_area_summary_decommissioned[ra_k]
					try {
						const ra_day_price = _month_riskarea_summary[ra_k].price_day
						const one_ra_price = one_ra_data.size * ra_day_price
						//						console.log("decommi", one_ra_data.size, ra_k, _month_riskarea_summary[ra_k].price, _month_riskarea_summary[ra_k].closed_price, one_ra_price)
						if (one_ra_data.room_count > 0) {
							_month_riskarea_summary[ra_k] = {
								..._month_riskarea_summary[ra_k],
								closed_days: _month_riskarea_summary[ra_k].closed_days + 1,
								closed_size: _month_riskarea_summary[ra_k].closed_size + one_ra_data.size,
								closed_price: _month_riskarea_summary[ra_k].closed_price + one_ra_price,
								net_month: _month_riskarea_summary[ra_k].price - (_month_riskarea_summary[ra_k].closed_price + one_ra_price)
							}
						}
					} catch (err) {
						console.log("decommissionedERROR")
						//console.warn(err)

					}

				}
			}


			ui_list.risk_area_list.filter(a => Object.keys(_month_riskarea_summary).indexOf(a.Id) >= 0).map((f) => {
				const month_value = getAmountValue((contract.risk_area_amount[f.Id] * _month_riskarea_summary[f.Id].size.toFixed(2)).toFixed(2))
				//					console.log(month_value, contract.risk_area_amount[f.Id], _startmonth_riskarea_summary[f.Id].size)
				console.log(f.Id, _month_riskarea_summary[f.Id], _month_riskarea_summary[f.Id].net_month)
				temp_total.full_size = temp_total.full_size + _month_riskarea_summary[f.Id].size
				temp_total.full_month = temp_total.full_month + month_value
				temp_total.year = temp_total.year + getAmountValue((month_value * 12).toFixed(2))
				temp_total.contract = temp_total.contract + getAmountValue((month_value * ui_list.contract_month[0]).toFixed(2))
				temp_total.closed_days = temp_total.closed_days + _month_riskarea_summary[f.Id].closed_days
				temp_total.closed_size = temp_total.closed_size + _month_riskarea_summary[f.Id].closed_size
				temp_total.closed_price = temp_total.closed_price + _month_riskarea_summary[f.Id].closed_price
				temp_total.net_month = temp_total.net_month + _month_riskarea_summary[f.Id].net_month

			})
		} catch (err) { console.error(err) }

		//console.log(_month_riskarea_summary)
		setRiskareaSummary(_month_riskarea_summary)
		//setRaAmount(temp_total.month)
		setRiskAreaTotalAmount(temp_total)
		//console.log("TOTAL RISK AREA", temp_total)
		functions.setSubTotal(temp_total.net_month)
		//		functions.edit({ name: 'risk_area_amount', value: risk_area_amount })

	}, [month_data])


	useEffect(() => {
		console.log("****************  CHANGE MONTH", month)

	}, [month])


	return (
		<>

			<Card className="mb-3">
				<Card.Header className="h4 text-primary">
					{contract && contract.type === 'civil' ?
						t('global.labels.standard_cleaning_summary')
						:
						t('global.labels.risk_area_summary')
					}
					{month_data.length === 0 ? <><FontAwesomeIcon icon="exclamation-triangle" /><small>dati non presenti</small></> :
						<small>
						</small>
					}
				</Card.Header>
				<Card.Body>
					<Container fluid>
						<Row>
							<Col>{contract.working_days || default_working_days} giorni medi lavorativi mensili</Col>
							<Col className="text-light">
								{month_data.length} giorni
							</Col>
						</Row>
						{risk_area_summary && Object.keys(risk_area_summary).length > 0 ?
							<>
								<Row className="mb-1">
									<Col className="bg-secondary text-light ml-1 text-left">{contract.type === 'civil' ? t('global.labels.standard_cleaning') : t('global.labels.risk_area')} </Col>
									<Col className="bg-secondary text-light ml-1">m<sup>2</sup> totali</Col>
									<Col className="bg-secondary text-light ml-1">m<sup>2</sup> chiusura</Col>
									<Col className="bg-secondary text-light ml-1">giorni chiusura</Col>
									<Col className="bg-secondary text-light ml-1">{t('contract.labels.value')} / m<sup>2</sup> / mese</Col>
									<Col className="bg-secondary text-light ml-1">{t('contract.labels.value')} / m<sup>2</sup> / giorno</Col>
									<Col className="bg-secondary text-light ml-1">{t('contract.labels.value')} totale al mese</Col>
									<Col className="bg-secondary text-light ml-1">{t('contract.labels.closedvalue')}</Col>
									<Col className="bg-secondary text-light ml-1 font-weight-bold">{t('contract.labels.net_monthvalue')}</Col>
								</Row>
								{ui_list.risk_area_list.filter(a => Object.keys(risk_area_summary).indexOf(a.Id) >= 0).map((f, k) => (
									<Row key={k} className="element_row pb-1 mb-1 border-bottom">
										<Col className="text-left">
											<RiskareaBadge riskarea={f} />
										</Col>
										<Col className="text-right">
											{risk_area_summary[f.Id].size.toFixed(2)}
										</Col>

										<Col className="text-right">
											{risk_area_summary[f.Id].closed_size.toFixed(0)}
										</Col>

										<Col className="text-right">
											{risk_area_summary[f.Id].closed_days}
										</Col>

										<Col>
											<CurrencyInput
												name={"ramonth_" + f.id}
												value={risk_area_summary[f.Id].one_price.toFixed(2)}
												allowDecimals={true}
												decimalsLimit={2}
												decimalSeparator=","
												className="form-control-plaintext text-right text-dark"
												groupSeparator=" "
												prefix="€"
												disabled={true}
												precision={2}
											/>
										</Col>
										<Col>
											<CurrencyInput
												name={"ramonth_" + f.id}
												value={risk_area_summary[f.Id].price_day.toFixed(2)}
												allowDecimals={true}
												decimalsLimit={2}
												decimalSeparator=","
												className="form-control-plaintext text-right text-dark"
												groupSeparator=" "
												prefix="€"
												disabled={true}
												precision={2}
											/>
										</Col>

										<Col>
											<CurrencyInput
												name={"ratotalmonth_" + f.id}
												value={(risk_area_summary[f.Id].price.toFixed(2))}
												allowDecimals={true}
												decimalsLimit={2}
												decimalSeparator=","
												className="form-control-plaintext text-right text-dark"
												groupSeparator=" "
												prefix="€"
												disabled={true}
												precision={2}
											/>
										</Col>

										<Col>
											<CurrencyInput
												name={"raclosedmonth_" + f.id}
												value={(risk_area_summary[f.Id].closed_price.toFixed(2))}
												allowDecimals={true}
												decimalsLimit={2}
												decimalSeparator=","
												className="form-control-plaintext text-right text-dark"
												groupSeparator=" "
												prefix="- €"
												disabled={true}
												precision={2}
											/>
										</Col>

										<Col>
											<CurrencyInput
												name={"raclosedmonth_" + f.id}
												value={(risk_area_summary[f.Id].net_month.toFixed(2))}
												allowDecimals={true}
												decimalsLimit={2}
												decimalSeparator=","
												className="form-control-plaintext text-right text-info font-weight-bold"
												groupSeparator=" "
												prefix="€"
												disabled={true}
												precision={2}
											/>
										</Col>

									</Row>))
								}
							</>
							: <Row>
								<Col className="text-left font-italic">
									{contract.type === 'civil' ?
										t('contracts.messages.no_standard_cleaning')
										:
										t('contracts.messages.no_risk_area')
									}
								</Col>
							</Row>
						}

					</Container>

				</Card.Body>
				{contract.risk_area_summary && Object.keys(contract.risk_area_summary).length > 0 ?
					<Card.Footer>
						<Container fluid>
							<Row>
								<Col className="border-right ml-1 text-left">{t('global.labels.total')} </Col>
								<Col className="border-right ml-1 text-right">{risk_area_totalamount.full_size.toFixed(2)}</Col>
								<Col className="border-right ml-1 text-right">{risk_area_totalamount.closed_size.toFixed(2)}</Col>
								<Col className="border-right ml-1 text-right">{risk_area_totalamount.closed_days}</Col>
								<Col className="border-right ml-1"></Col>
								<Col className="border-right ml-1"></Col>
								<Col className="border-right ml-1">
									<CurrencyInput
										name='total_contract'
										value={risk_area_totalamount.full_month.toFixed(2)}
										allowDecimals={true}
										decimalsLimit={2}
										decimalSeparator=","
										className="form-control-plaintext text-right text-dark"
										groupSeparator=" "
										prefix="€"
										disabled={true}
										precision={2}
									/>
								</Col>
								<Col className="border-right ml-1">
									<CurrencyInput
										name='total_contract'
										value={risk_area_totalamount.closed_price.toFixed(2)}
										allowDecimals={true}
										decimalsLimit={2}
										decimalSeparator=","
										className="form-control-plaintext text-right text-dark"
										groupSeparator=" "
										prefix="- €"
										disabled={true}
										precision={2}
									/>
								</Col>
								<Col className="ml-1">
									<CurrencyInput
										name='total_contract'
										value={risk_area_totalamount.net_month.toFixed(2)}
										allowDecimals={true}
										decimalsLimit={2}
										decimalSeparator=","
										className="form-control-plaintext text-right text-info font-weight-bold"
										groupSeparator=" "
										prefix="€"
										disabled={true}
										precision={2}
									/>
								</Col>

							</Row>

						</Container>

					</Card.Footer>
					: ''}
			</Card>

		</>
	)
}



//---------------------------------------------------------------------------------------------------------------
export const MonthServiceamountList = ({ element, functions, t, canWrite, service_list, ui_list, item, setItem }) => {

	const { contract, service_dict } = useContext(GlobalDataContext)

	const [totalamount, setFeeTotalAmount] = useState({ fee: 0, year: 0, contract: 0, month: 0 })
	const serviceamount_list = contract.serviceamount_list || []


	useEffect(() => {
		let _totalamount = { ...totalamount }
		serviceamount_list.forEach(element => {

			try {
				_totalamount.month += getAmountValue(getFloatNumber(element.price))
			} catch (err) { }

			try {
				_totalamount.year += getAmountValue(element.total_year)
			} catch (err) { }

			try {
				_totalamount.contract += getAmountValue(element.total_contract)
			} catch (err) { }
		});
		setFeeTotalAmount(_totalamount)
		setItem(_totalamount.month)
	}, [])

	//	setFeeAmount(totalamount.month)



	return (
		<Card className="mb-3">
			<Card.Header>
				<Container fluid>
					<Row >
						<Col className="h4 text-primary">
							{t('global.labels.serviceamount_periodic')}
						</Col>
						<Col className="text-right">
						</Col>
					</Row>

				</Container>
			</Card.Header>
			<Card.Body>

				<Container fluid>
					{contract.serviceamount_list && contract.serviceamount_list.length > 0 ?
						<>
							<Row className="mb-1">
								<Col className="bg-secondary text-light ml-1" sm="4">{t('global.labels.name')} </Col>
								<Col className="bg-secondary text-light ml-1 font-weight-bold">{t('contract.labels.month_value')}</Col>
								<Col className="bg-secondary text-light ml-1">{t('contract.labels.year_value')}</Col>
								<Col className="bg-secondary text-light ml-1">{t('contract.labels.total_value')}<br /><i>({ui_list.contract_month[0]} mesi)</i></Col>
							</Row>
							{contract.serviceamount_list.map((i, key) => (
								<Row key={key} className="element_row pb-1 mb-1 border-bottom">
									<Col sm="4">
										{i.service && i.service.id ?
											<ServiceBadge service={service_dict[i.service.id]} freq={true} />
											: ''}
									</Col>
									<Col>
										<CurrencyInput
											name='i_month'
											value={i.price.toFixed(2)}
											allowDecimals={true}
											decimalsLimit={2}
											decimalSeparator=","
											className="form-control-plaintext text-right text-info font-weight-bold"
											groupSeparator=" "
											prefix="€"
											disabled={true}
											precision={2}
										/>
									</Col>
									<Col className="text-right">
										<CurrencyInput
											name='i_year'
											value={i.total_year.toFixed(2)}
											allowDecimals={true}
											decimalsLimit={2}
											decimalSeparator=","
											className="form-control-plaintext text-right text-dark"
											groupSeparator=" "
											prefix="€"
											disabled={true}
											precision={2}
										/>
									</Col>
									<Col className="text-right">
										<CurrencyInput
											name='i_total_contract'
											value={i.total_contract.toFixed(2)}
											allowDecimals={true}
											decimalsLimit={2}
											decimalSeparator=","
											className="form-control-plaintext text-right text-dark"
											groupSeparator=" "
											prefix="€"
											disabled={true}
											precision={2}
										/>
									</Col>
								</Row>
							))}
						</>
						:
						<Row>
							<Col className="font-italic">
								{t('global.messages.no_serviceamount')}
							</Col>
						</Row>
					}

				</Container>
			</Card.Body>
			{contract.serviceamount_list && contract.serviceamount_list.length > 0 ?
				<Card.Footer>

					<Container fluid>
						<Row >
							<Col className="border-right ml-1" sm="4">
								{t('global.labels.total')}
							</Col>
							<Col className="border-right ml-1 text-right" >
								<CurrencyInput
									name='total_contractM'
									value={totalamount.month.toFixed(2)}
									allowDecimals={true}
									decimalsLimit={2}
									decimalSeparator=","
									className="form-control-plaintext text-right text-info font-weight-bold"
									groupSeparator=" "
									prefix="€"
									disabled={true}
									precision={2}
								/>
							</Col>
							<Col className="border-right ml-1 text-right" >
								<CurrencyInput
									name='total_contractY'
									value={totalamount.year.toFixed(2)}
									allowDecimals={true}
									decimalsLimit={2}
									decimalSeparator=","
									className="form-control-plaintext text-right text-dark"
									groupSeparator=" "
									prefix="€"
									disabled={true}
									precision={2}
								/>
							</Col>
							<Col className="ml-1 text-right" >
								<CurrencyInput
									name='total_contractT'
									value={totalamount.contract.toFixed(2)}
									allowDecimals={true}
									decimalsLimit={2}
									decimalSeparator=","
									className="form-control-plaintext text-right text-dark"
									groupSeparator=" "
									prefix="€"
									disabled={true}
									precision={2}
								/>
							</Col>
						</Row>

					</Container>
				</Card.Footer>
				: ''}
		</Card>
	)
}








//-----------------------------------------
export const MonthServiceamountRequestList = ({ functions, t, item, structure }) => {

	const { contract } = useContext(GlobalDataContext)
	const { domain } = useContext(DomainContext)
	const [total_sum, setTotalSum] = useState(0)
	const [e_list, setElist] = useState()


	const [serviceamounthour_list, setServiceAmountHourList] = useState(contract.serviceamounthour_list || [])

	useEffect(() => {
		let _total_sum = 0
		serviceamounthour_list.forEach(element => {
			try {
				_total_sum += getFloatNumber(element.total)
			} catch (err) { }
		});
		console.log("TOTAL REQUEST SUM", _total_sum)
		functions.setSubTotal(_total_sum)
		setTotalSum(_total_sum.toFixed(2))

	}, [serviceamounthour_list])


	useEffect(() => {
		console.log("request SERVICE", item.serviceamounthour_list, contract.serviceamounthour_list)
		console.log("itemtest", item)
		const getMonthRequestIntervention = async () => {
			let _total_sum = 0
			const ds = new Date(item.year, item.month, 1)
			const _start_date = firebase.firestore.Timestamp.fromDate(ds)
			const _end_date = firebase.firestore.Timestamp.fromDate(addDays(new Date(item.year, item.month, getMonthLength(ds)), 1))
			let return_data = await getEventRange(domain, contract.id, _start_date, _end_date)
			console.log(return_data, _start_date, _end_date, item)
			return_data = Array.from(return_data)
			return_data = return_data.filter(e => (e.extendedProps.type === 'requested_intervention' && !e.extendedProps.is_deleted))
			console.log("____event_monthREQUESTED", return_data)
			setElist(return_data)
		}
		getMonthRequestIntervention()
		//setServiceAmountHourList(item.serviceamounthour_list || contract.serviceamounthour_list)
		if (item.serviceamounthour_list.length >= contract.serviceamounthour_list.length) {
			console.log("---item")
			setServiceAmountHourList(item.serviceamounthour_list)
		} else {
			console.log("---contract")
			setServiceAmountHourList(contract.serviceamounthour_list)
		}
	}, [item])




	const handleEditServiceamount = (value, index) => {
		console.log("SERVICE AMOUNT EDIT", value, index)
		let _serviceamounthourlist = [...serviceamounthour_list]
		_serviceamounthourlist.splice(index, 1, value)
		setServiceAmountHourList(_serviceamounthourlist)
		functions.setItem({ ...item, serviceamounthour_list: _serviceamounthourlist })
	}


	return (
		<Card className="mb-3">
			<Card.Header>
				<Container fluid>
					<Row>
						<Col className="h4 text-primary">
							{t('global.labels.serviceamount_request')}
						</Col>
						<Col className="text-right">
						</Col>
					</Row>
				</Container>
			</Card.Header>
			<Card.Body>
				<Container fluid>
					{console.log("....-----....", structure.amount_summary_list)}
					{e_list && serviceamounthour_list && serviceamounthour_list.length > 0 ?
						<>
							<Row className="mb-1">
								<Col className="bg-secondary text-light ml-1" sm="4">{t('global.labels.name')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('contract.labels.unit_value')}</Col>
								<Col className="bg-secondary text-light ml-1">Franchigia nel periodo

									<OverlayTrigger
										placement="auto"
										delay={{ show: 250, hide: 150 }}
										overlay={<Tooltip >ancora disponibili / totali per periodo</Tooltip>}>
										<FontAwesomeIcon icon="info-circle" />
									</OverlayTrigger>
								</Col>
								<Col className="bg-secondary text-light ml-1">{t('contract.labels.monthly_unit')}</Col>
								<Col className="bg-secondary text-light ml-1">{t('contract.labels.value')} totale </Col>
							</Row>
							{serviceamounthour_list
								.filter(i => i.service)
								.map((i, key) => (
									<OneRequestService
										i={i}
										key={key}
										structure_id={structure.id}
										year={item.year}
										month={item.month}
										t={t}
										event_list={e_list}
										amount_summary_list={structure.amount_summary_list
											.filter(a =>
												a.serviceamounthour_list.filter(s => s.service && s.service.id === i.service.id).length > 0
												&& a.month !== item.month
												&& a.year !== item.year)}
										handleEditServiceamount={handleEditServiceamount}
										index={key}
									/>
								))}
						</>
						:
						<Row>
							<Col className="font-italic">
								{t('global.messages.no_serviceamount')}
							</Col>
						</Row>
					}


				</Container>

			</Card.Body>
			{serviceamounthour_list && serviceamounthour_list.length > 0 ?
				<Card.Footer>
					<Container fluid>
						<Row key={'total'}>
							<Col className="border-right ml-1" sm="4">
								{t('global.labels.total')}
							</Col>
							<Col>
							</Col>
							<Col>
							</Col>
							<Col className="text-right text-info font-weight-bold">
								<CurrencyInput
									name='total_fee'
									value={total_sum}
									allowDecimals={true}
									decimalsLimit={2}
									decimalSeparator=","
									className="form-control-plaintext text-right text-info font-weight-bold"
									groupSeparator=" "
									prefix="€"
									disabled={true}
									precision={2}
								/>
							</Col>
						</Row>
					</Container>
				</Card.Footer>
				: ''}

		</Card>
	)
}


const OneRequestService = ({ t, i, structure_id, index, handleEditServiceamount, amount_summary_list, month, year, event_list }) => {
	const sp = servicePaymentModeParams_dict({ t })

	const { contract, service_dict } = useContext(GlobalDataContext)
	const { domain } = useContext(DomainContext)
	const [done_count, setDoneCount] = useState(0)

	useEffect(() => {
		if (i.service) {
			let _done_count = getServiceDoneCount(i.competence_period, i.service.id)
			console.log("-----------------------service_done", i, amount_summary_list, _done_count)
			setDoneCount(_done_count)
		}
	}, [i])


	useLayoutEffect(() => {
		console.log("LAYOUT EFFECT", "_______________________________", event_list, i)
		const e_list = event_list.filter(e => (e.extendedProps.service && e.extendedProps.service.id === i.service.id) || (e.extendedProps.service_id === i.service.id))
		console.log("''''''''''''''''''e_list", e_list)
		if (e_list.length > 0) {
			if (i.payment_mode === 'activity') {
				handleEdit(getTotalServiceQuantity(e_list))
			} else if (i.payment_mode === 'hour') {
				handleEdit(getTotalServiceQuantity(e_list))
			} else if (i.payment_mode === 'unit') {
				handleEdit(getTotalServiceQuantity(e_list))
			} else if (i.payment_mode === 'size') {
				handleEdit(getTotalServiceQuantity(e_list))
			}
		} else {
			handleEdit(0)
		}

	}, [])


	const getTotalServiceQuantity = (e_list) => {
		const b = e_list.reduce((a, v) => {
			try {
				if (v.extendedProps.quantity_done !== undefined) {
					console.log(a, v.extendedProps.quantity_done)
					return (a + parseFloat(v.exemption_count))
				} else {
					console.log(a)
					return (a + 1)
				}
			} catch (err) {
				console.err("ERROR COUNT AMOUNT", err)
				return (a + 1)
			}
		}, 0)
		return b
	}




	const handleEdit = (value) => {
		console.log("EDIT COUNT", value)
		let _element = { ...i }
		_element.count = value
		const v = getFloatNumber(value)
		if (_element.structure_exemption && _element.structure_exemption[structure_id] && getFloatNumber(_element.structure_exemption[structure_id].exemption_count) > 0) {
			//			let _done_count = getServiceDoneCount(_element.competence_period, _element.service.id)
			//tDoneCount(_done_count)
			const ec = getFloatNumber(_element.structure_exemption[structure_id].exemption_count) - done_count
			console.log("COUNT", done_count, ec)
			_element.total = 0
			if (ec - done_count < value) {
				_element.total = (v - ec) * _element.price
			}

		} else {
			_element.total = v * _element.price
		}
		console.log("EL", _element)
		//_element.total = getFloatNumber(value) * _element.price
		handleEditServiceamount(_element, index)
	}

	const getServiceDoneCount = (period, service_id) => {
		console.log("   --- --   TOTAL_COUNT", period, service_id, month, year,
			amount_summary_list//.filter(a => a.serviceamounthour_list.filter(s => s.service.id === service_id).length > 0 && a.month !== month )
		)
		if (period === 'month') {
			return 0
		} else if (period === 'year') {
			const count_list = amount_summary_list
				/* 				.filter(a => a.serviceamounthour_list.filter(s => s.service.id === service_id).length > 0
									&& a.month !== month
									&& a.year !== year
								) */
				.map(function (a) {
					console.log("---test", a, a.serviceamounthour_list.filter(s => s.service && s.service.id === service_id))
					//			a.serviceamounthour_list.filter(s => s.service.id=== service_id)[0].count
					return a.serviceamounthour_list.filter(s => s.service && s.service.id === service_id)[0]
				}).reduce(function (a, b) {
					return a + parseInt(b.count);
				}, 0);
			return count_list

		} else if (period === 'contract') {
			const count_list = amount_summary_list
				/* 				.filter(a => a.serviceamounthour_list.filter(s => s.service.id === service_id).length > 0
									&& a.month !== month
									&& a.year !== year
								) */
				.map(function (a) {
					console.log("---test", a, a.serviceamounthour_list.filter(s => s.service && s.service.id === service_id))
					//			a.serviceamounthour_list.filter(s => s.service.id=== service_id)[0].count
					return a.serviceamounthour_list.filter(s => s.service && s.service.id === service_id)[0]
				}).reduce(function (a, b) {
					return a + parseInt(b.count);
				}, 0);
			return count_list

		}
		return 0
	}



	return (

		<Row className="element_row pb-1 mb-1 border-bottom">
			<Col sm="4">
				{i.service && i.service.id ?
					<ServiceBadge service={service_dict[i.service.id]} freq={true} />
					: <>{i.description}</>}
			</Col>
			<Col>
				{i.price &&
					<CurrencyInput
						name='single_fee'
						value={i.price}
						allowDecimals={true}
						decimalsLimit={2}
						decimalSeparator=","
						className="form-control-plaintext text-right"
						groupSeparator=" "
						prefix="€"
						disabled={true}
						precision={2}
					/>
				}
			</Col>
			<Col className="text-center">
				{i.structure_exemption && i.structure_exemption[structure_id] &&
					<>
						{i.count < i.structure_exemption[structure_id].exemption_count - done_count ? i.structure_exemption[structure_id].exemption_count - done_count - i.count : 0}
						&nbsp;/&nbsp;
						{i.structure_exemption[structure_id].exemption_count}
						&nbsp;per&nbsp;
						{sp[i.competence_period].label}
					</>

				}
			</Col>
			<Col>
				<InputGroup className="mb-2" >
					<Form.Control type="number" name="count" min="0" disabled={true} value={i.count} onChange={(event) => handleEdit(event.currentTarget.value)} />
					<InputGroup.Append>
						<InputGroup.Text>
							{payment_mode_dict[i.payment_mode] ? payment_mode_dict[i.payment_mode].label_unit : '---'}
						</InputGroup.Text>
					</InputGroup.Append>
				</InputGroup>
			</Col>
			<Col className="text-right">
				<CurrencyInput
					name='total_fee'
					value={i.total.toFixed(2)}
					allowDecimals={true}
					decimalsLimit={2}
					decimalSeparator=","
					className="form-control-plaintext text-right text-info font-weight-bold"
					groupSeparator=" "
					prefix="€"
					disabled={true}
					precision={2}
				/>
			</Col>
		</Row>
	)

}



export default translate()(AmountView)




const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;
