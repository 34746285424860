import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Navbar, Container, Row, Col, Dropdown, Button, Badge, Nav, Image, Modal } from 'react-bootstrap';
import { useTranslate } from 'react-polyglot';
import { NavLink, useLocation, Link, } from "react-router-dom"
import { format, subDays, } from 'date-fns'

import { getElementPreview, myTimestampToDate, getValue } from "../util/ui_utils"
import Icon from '../components/constants/customicons'
import { getSystemMenu_list } from "../components/constants/systemsections"

import DomainContext from './contexts/domainContext'
import { firebaseAuth } from '../provider/AuthProvider'
import GlobalDataContext from './contexts/globaldataContext.js';
import PrintFooter from "./generic/PrintFooter";


const btnclass = "text-center text-center h-100 p-0 btn-block text-dark"
const dropclass = "h-100 p-1"
const colbtnclass = "align-self-start text-center border-left p-0"


const CustomToggle = React.forwardRef(({ children, onClick, href }, ref) => {
	return (
		<>
			{href && href.length > 0 ?

				<Button variant="outline-info" size="md" style={{ border: 'none' }} ref={ref} className={btnclass} href={href}>
				</Button >

				:

				<Button variant="outline-info" size="md" style={{ border: 'none' }} ref={ref} className={btnclass} onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}>
					{children}
				</Button >
			}
		</>
	);
});


function getUrl(line) {
	if (line.outside_contract) {
		return line.href
	} else {
		return "." + line.href
	}
}



const SubMenu = ({ menu_list }) => {


	const MenuVoice = ({ line, k }) => {
		if (line.type === 'divider') {
			return (
				<Dropdown.Divider />
			)
		} else if (line.type === 'link') {
			return (
				<Dropdown.Item as={NavLink} eventKey={k} to={getUrl(line)} disabled={line.disabled} style={{ whiteSpace: "pre-line" }}>{line.label}</Dropdown.Item>
			)
		} else if (line.type === 'header') {
			return (
				<Dropdown.Header><Icon icon={line.icon} title={"icon " + line.label} />{line.label} </Dropdown.Header>
			)
		} else {
			return <></>

		}
	}

	return (
		<>
			{menu_list.map((m, k) => (
				<MenuVoice line={m} key={k} k={k + 1} />
			))}
		</>
	)
}






const Menu = ({ menu, domain }) => {
	const [show, setShow] = useState(false);

	const showDropdown = (e) => {
		setShow(!show);
	}

	const hideDropdown = e => {
		setShow(false);
	}

	if (domain === 'catalog') {
		return (
			<Nav.Item as={NavLink} to={getUrl(menu)} variant={'outline-primary'} disabled={menu.disabled} >{menu.label}</Nav.Item>
		)
	} else {
		return (
			<Dropdown className={dropclass}
				show={show}
				onMouseEnter={showDropdown}
				onMouseLeave={hideDropdown}
			>
				<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" className={dropclass}>
					<img src={menu.icon} height="42"
						className="d-inline-block align-top"
						alt={menu.label} />
					<br />
					{menu.label}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<SubMenu menu_list={menu.menulist} />
				</Dropdown.Menu>
			</Dropdown>
		);

	}
}

const userDataString = localStorage.getItem('userData')
//console.log("nav userdatastring", userDataString)

let licence_status = false

const getLicenceDate = (isGlobal, domain_data) => {
	try {
		if (myTimestampToDate(getValue(domain_data, 'endDate')) >= new Date()) {

			return (
				<>
					{isGlobal ?
						<>
							Scadenza licenza:
							<b>{format(myTimestampToDate(getValue(domain_data, 'endDate')), 'dd/MM/yyyy')}</b>
						</>
						: ''}
				</>
			)
		} else if (domain_data.warning_days && subDays(myTimestampToDate(getValue(domain_data, 'endDate')), domain_data.warning_days) >= new Date()) {
			return (
				<>
					{isGlobal ?
						<>
							Scadenza licenza:
							<Badge variant="warning"> <FontAwesomeIcon icon="exclamation-triangle" /> <b>{format(myTimestampToDate(getValue(domain_data, 'endDate')), 'dd/MM/yyyy')}</b></Badge>
						</>
						: ''}
				</>
			)
		} else {
			licence_status = false
			return (
				<>
					<Badge variant="danger" size="xl">
						Licenza scaduta
						{isGlobal ?
							<b>{format(myTimestampToDate(getValue(domain_data, 'endDate')), 'dd/MM/yyyy')}</b>
							:
							''}
					</Badge>

				</>
			)

		}

	} catch (err) {
		return <></>
	}

}





/////////////////////////////////////////////////////////////////////////////////
//##
///////////////////////////////////////////////////////////////////////////////
export const NavBarGlobal = props => {
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, } = useContext(GlobalDataContext)
	const { handleSignout, token, setToken } = useContext(firebaseAuth)

	const location = useLocation();
	const t = useTranslate();

	const [isGlobal, setIsGlobal] = useState(false)
	let userData = props.uData
	try {
		if (!userData) {
			userData = userDataString ? JSON.parse(userDataString) : undefined
		}
	} catch (err) {
		console.log(err)
	}
	//console.log("NAVBAR DOMAIN", domain, userDataString, userData)

	const localsignout = async () => {
		console.log("NAVBAR Signout")
		handleSignout();
	}
	let page_permission = { isAdmin: false }
	//  console.log('pre navbar userdata', userData)
	if (userData) {
		page_permission = { isAdmin: userData.isAdmin }

		if (userData.write_page) {

			if (userData.write_page.length > 0 || userData.read_page.length > 0) {
				page_permission = { write_page: userData.write_page, read_page: userData.read_page }
			} else {
				const contractId = sessionStorage.getItem('contractId')
				try {
					let temp_permission = userData.contract_dict[contractId]
					page_permission = { write_page: [].concat.apply([], [].concat.apply([], temp_permission.map(l => l.write_page))), read_page: [].concat.apply([], [].concat.apply([], temp_permission.map(l => l.read_page))) }
					//          console.log(page_permission)
				} catch (err) {
				}
			}
		}
	}
	if (userData && domain === 'admin') {
		userData.isAdmin = true
		page_permission = 'admin'
		localStorage.setItem('userData', JSON.stringify(userData))
	}
	const global_menu = getSystemMenu_list({ t, page_permission, contract })

	const admin_menu = [
		{
			label: t('global.pages.settings'),
			icon: "/menu_icons/Impostazioni.png",
			id: 'adminsettings',
			is_menu: true,
			menulist: [
				{ type: "link", href: "/", icon: '', label: t('navs.settings.licences'), disabled: false },
				{ type: "link", href: "/systemgroup", icon: '', label: t('navs.settings.systemgroups'), disabled: false },
			]
		},
		{
			label: t('global.pages.global_parameters'),
			icon: "/menu_icons/Impostazioni.png",
			id: 'globalparameters',
			is_menu: true,
			menulist: [
				{ type: "link", href: "/mediatype", icon: '', label: t('navs.settings.mediatype'), disabled: false },
				{ type: "link", href: "/roomtypeimage", icon: '', label: t('navs.settings.roomtypeimage'), disabled: true},
				{ type: "link", href: "/contractscope", icon: '', label: t('navs.settings.contractscope'), disabled: true },
				{ type: "link", href: "/helpmanager", icon: '', label: 'Help manager', disabled: false },
			]
		},
		{
			label: t('global.pages.templates'),
			icon: "/menu_icons/Cataloghi.png",
			id: 'templates',
			is_menu: true,
			menulist: [
				{ type: "link", href: "/template", icon: '', label: t('navs.settings.template'), disabled: false },
			]
		},
		{
			label: t('global.pages.documents'),
			icon: "/menu_icons/Documenti.png",
			id: 'documents',
			is_menu: true,
			menulist: [
				{ type: "link", href: "/documentssystemadmin", icon: '', label: t('global.pages.systemdocument_management'), disabled: false },
			]
		}
	]
	const catalog_menu = [
		//{ type: "link", href: "/dpi", id: "dpi", icon: '', label: t('navs.registries.dpi'), disabled: false },
		//{ type: "link", href: "/clothing", id: "clothing", icon: '', label: t('navs.registries.clothing'), disabled: false },
		{ type: "link", href: "/machines", id: "machines", icon: '', label: t('navs.registries.machines'), disabled: false },
		{ type: "link", href: "/carts", id: "carts", icon: '', label: t('navs.registries.carts'), disabled: false },
		{ type: "link", href: "/equipments", id: "equipments", icon: '', label: t('navs.registries.equipments'), disabled: false },
		{ type: "link", href: "/products", id: "products", icon: '', label: t('navs.registries.products'), disabled: false },
		{ type: "link", href: "/materials", id: "materials", icon: '', label: t('navs.registries.cleaningmaterials'), disabled: false },
		{ type: "link", href: "/economicmaterials", id: "economicmaterials", icon: '', label: t('navs.registries.economic_material'), disabled: false },
	]

	//console.log(userData.read_page, userData.write_page)
	//console.log("NAVBAR userdata", userData)
	//  const contract = JSON.parse(sessionStorage.getItem('contract')) || {}
	//const domain_data = JSON.parse(sessionStorage.getItem('domain_data')) || {}
	//  console.log("DOMAIN DATA", domain_data)

	const mymenu = (domain === 'admin') ? admin_menu : ((domain === 'catalog') ? catalog_menu : global_menu)



	function isOutsideContract() {
		if (location.pathname === '/usersettings' || location.pathname === '/contractdashboard' || location.pathname === '/contractchoose' || (location.pathname === '/' && domain !== 'admin')) {
			return true
		} else {
			return false
		}
	}

	return (
		<>
			<Container fluid className="bg-white sticky-top">
				<Row >
					<Col sm="2" className="p-0">
						<Row>
							<Col className="text-center">
								<Navbar.Brand href={"/"}>
									<Image 
										src={domain !== 'admin' && (domain_data && domain_data.logo) ? domain_data.logo : "./Logo.Claudit.registrato.png"}
										style={{maxHeight:'40px', maxWidth:'100px', minHeight: '30px'}}
									/>

									{!isOutsideContract() ?
										<>
											{domain !== 'admin' && contract ?
												<>
													{getElementPreview(contract, '40px')}
												</>
												: ''}
										</>
										: ''
									}
								</Navbar.Brand>
							</Col>
						</Row>
						<Row>
							<Col>
								<Dropdown>
									<Dropdown.Toggle variant="light" id="dropdown-basic" className="username">
										<FontAwesomeIcon className={userData.isGlobalAdmin ? "text-danger" : ""} icon='user' /> {userData.displayName}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Header>{userData && userData.displayName ? userData.displayName : t('global.pages.user')}</Dropdown.Header>
										{!isOutsideContract && contract ?
											<>
												<Dropdown.Item as={Link} to={contract.id + "/home"}><FontAwesomeIcon icon='home' /> {t('global.pages.homepage')}</Dropdown.Item>
												<Dropdown.Divider />
											</>
											: ''}
										<Dropdown.Item as={Link} to="/usersettings"> <FontAwesomeIcon icon='user-cog' /> {t('global.pages.user_settings')}</Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item as={Link} to="#" onClick={localsignout}> <FontAwesomeIcon icon='sign-out-alt' /> {t('global.pages.logout')}</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
					</Col>

					{isOutsideContract() ?
						<>
								<Col className="align-self-center text-center h1">
									<ModalDomainInfo t={t} />
									</Col>
							<Col className="text-right" sm="2">
								{getLicenceDate(isGlobal, domain_data)}
							</Col>
						</>
						:
						<>
							{mymenu.filter(m => domain === 'catalog' || m.is_menu).map((m, k) => (
								<Col className={colbtnclass} sm="1" key={k}>
									<Menu menu={m} curlocation={props.curlocation} domain={domain} />
								</Col>

							))}
						</>
					}
				</Row>

			</Container>
		</>
	)
}


const ModalDomainInfo = ({ t, }) => {
	const { domain_data } = useContext(DomainContext)
	const [showClauditlicenceEdit, setShowClauditlicenceEdit] = useState(false)

	return (
		<>
		{domain_data?
					<Button onClick={() => setShowClauditlicenceEdit(true)} variant="primary" className="h1"><span className="h1">{domain_data.name}</span> </Button>
		:''}
			<Modal show={showClauditlicenceEdit} onHide={() => setShowClauditlicenceEdit(false)} size="lg" backdrop="static" className="bg-secondary">
				<Modal.Header closeButton >
					<Modal.Title className="text-primary">{t('licence.labels.info_domain')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<PrintFooter vertical={true}/>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowClauditlicenceEdit(false)}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}




export default NavBarGlobal;