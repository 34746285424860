import {firestore} from '../firebaseIndex'


/* REFERENCE
const newcompany = {
	name: '',
	code: '',
	vat: '',
	cf: '',
	address: {
		street: '',
		number: '',
		zip: '',
		city: '',
		state: ''
	},
	phone_list: [],
	phone: '',
	mobilephone: '',
	fax: '',
	email: '',
	pec: '',
	website: '',
	logo: '',
	referent_list: [],
	bank: '',
	payment_mode: '',
	sdi: '',
	note: '',
	supply_list: [],
	structure_list: []
}
*/


const addBaseCompany = async (domainId, contractId, data) => {
    try{
        const res = await firestore.collection('DOMAINS')
                                    .doc(domainId).collection('contracts')
                                    .doc(contractId).collection("baseCompany")
                                    .doc('BASE')
                                    .set(data)
        console.log(res)
        return data
    }catch(e){
        console.log("addBaseCompany error:", e)
        return { error: e}
    }
}

const editBaseCompany = async (domainId, contractId, data) => {
    try{
        const company = firestore.collection('DOMAINS')
        .doc(domainId).collection('contracts')
                                .doc(contractId)
                                .collection('baseCompany')
                                .doc('BASE');

        const res = await company.set(
            data
        );
        return res;
    }
    catch(e) {
        console.log(e)
        return {error: e}
    }
}

const getBaseCompany = async (domainId, contractId) => {
    const doc = await firestore.collection('DOMAINS')
    .doc(domainId).collection('contracts')
    .doc(contractId).collection('baseCompany')
    .doc('BASE').get()
    return doc.data()
}

export {addBaseCompany};
export {editBaseCompany};
export {getBaseCompany};


///////////////////////////////////////////////////////////////////////////////
//
//  STRUCTURE
//	...pavillon
//	    ...floor
//	        ...department
//
//
//

/*
const newstructure = {
		Id: 0,
		name: '',
		code: '',
		type: {},
		vat: '',
		cf: '',
		address: {
			street: '',
			number: '',
			zip: '',
			city: '',
			state: ''
		},
		phone_list: [],
		email: '',
		pec: '',
		website: '',
		logo: '',
		referent_list: [],
		sdi: '',
		note: '',
		mq: 0,
		risk_area_summary: [],
		id: 1,
		type_name: "structure",
		level: 0,
		childs: []
	}
*/

const addStructure = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addStructure error: ", e)
        return { error: e }
    }
}

const getStructureById = async (domainId, contractId, structureId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : structureId,
        }
        return data;
    } catch (e) {
        console.log("getStructure error: ", e)
        return { error: e }
    }
}

const getStructureList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .get();

        const structureList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return structureList;        
    } catch (e) {
        console.log("getStructureList error: ", e)
        return { error: e }
    }
}


const editStructure = async (domainId, contractId, structureId, data) => {
    try {
        const structure = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId);

        const res = await structure.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editStructure error: ", e)
        return { error: e }
    }
}

export const updateStructure = async (domainId, contractId, structureId, data) => {
    try {
        const structure = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId);

        const res = await structure.update(
            data
        );

        return res
    }  catch (e) {
        console.log("editStructure error: ", e)
        return { error: e }
    }
}


const deleteStructure = async (domainId, contractId, structureId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId).delete();
        return res
    }  catch (e) {
        console.log("deleteStructure error: ", e)
        return { error: e }
    }
}




export { addStructure };
export { getStructureById };
export { getStructureList };
export { editStructure };
export { deleteStructure };


///////////////////////////////////////////////////////////////////////////////
//
// PAVILLON
//
///////////////////////////////////////////////////////////////////////////////

const addPavillon = async (domainId, contractId, structureId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addPavillon error: ", e)
        return { error: e }
    }
}

const getPavillonById = async (domainId, contractId, structureId, pavillonId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getPavillon error: ", e)
        return { error: e }
    }
}

const getPavillonList = async (domainId, contractId, structureId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .get();

        const pavillonList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return pavillonList;        
    } catch (e) {
        console.log("getPavillonList error: ", e)
        return { error: e }
    }
}


const editPavillon = async (domainId, contractId, structureId, pavillonId, data) => {
    try {
        const pavillon = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId);

        const res = await pavillon.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editPavillon error: ", e)
        return { error: e }
    }
}


export const updatePavillon = async (domainId, contractId, structureId, pavillonId, data) => {
    try {
        const pavillon = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId);

        const res = await pavillon.update(
            data
        );

        return res
    }  catch (e) {
        console.log("editPavillon error: ", e)
        return { error: e }
    }
}



const deletePavillon = async (domainId, contractId, structureId, pavillonId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId).delete();
        return res
    }  catch (e) {
        console.log("deletePavillon error: ", e)
        return { error: e }
    }
}




export { addPavillon };
export { getPavillonById };
export { getPavillonList };
export { editPavillon };
export { deletePavillon };



///////////////////////////////////////////////////////////////////////////////
//
// FLOOR
//
///////////////////////////////////////////////////////////////////////////////

const addFloor = async (domainId, contractId, structureId, pavillonId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addFloor error: ", e)
        return { error: e }
    }
}

const getFloorById = async (domainId, contractId, structureId, pavillonId, floorId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getfloor error: ", e)
        return { error: e }
    }
}

const getFloorList = async (domainId, contractId, structureId, pavillonId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .get();

        const floorList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return floorList;        
    } catch (e) {
        console.log("getFloorList error: ", e)
        return { error: e }
    }
}


const editFloor = async (domainId, contractId, structureId, pavillonId, floorId, data) => {
    try {
        const floor = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId);

        const res = await floor.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editFloor error: ", e)
        return { error: e }
    }
}


export const updateFloor = async (domainId, contractId, structureId, pavillonId, floorId, data) => {
    try {
        const floor = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId);

        const res = await floor.update(
            data
        );

        return res
    }  catch (e) {
        console.log("editFloor error: ", e)
        return { error: e }
    }
}




const deleteFloor = async (domainId, contractId, structureId, pavillonId, floorId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId).delete();
        return res
    }  catch (e) {
        console.log("deleteFloor error: ", e)
        return { error: e }
    }
}




export { addFloor };
export { getFloorById };
export { getFloorList };
export { editFloor };
export { deleteFloor };

///////////////////////////////////////////////////////////////////////////////
//
// DEPARTMENT
//
///////////////////////////////////////////////////////////////////////////////

const addDepartment = async (domainId, contractId, structureId, pavillonId, floorId, data ) => {
    console.log("ADD DEPARTMENT", domainId, contractId, structureId, pavillonId, floorId, data)
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .add(data);

        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addDepartment error: ", e)
        return { error: e }
    }
}

const getDepartmentById = async (domainId, contractId, structureId, pavillonId, floorId, departmentId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getDepartment error: ", e)
        return { error: e }
    }
}

const getDepartmentList = async (domainId, contractId, structureId, pavillonId, floorId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .get();

        const departmentList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return departmentList;        
    } catch (e) {
        console.log("getDepartmentList error: ", e)
        return { error: e }
    }
}


const editDepartment = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, data) => {
    try {
        const department = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId);

        const res = await department.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editDepartment error: ", e)
        return { error: e }
    }
}

export const updateDepartment = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, data) => {
    try {
        const department = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId);

        const res = await department.update(
            data
        );

        return res
    }  catch (e) {
        console.log("editDepartment error: ", e)
        return { error: e }
    }
}




const deleteDepartment = async (domainId, contractId, structureId, pavillonId, floorId, departmentId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId).delete();
        return res
    }  catch (e) {
        console.log("deleteDepartment error: ", e)
        return { error: e }
    }
}


/////////////////////////////////////////////////////////////////////////////


export const getAllFloorInStructure = async (structureId) => {
    try {
        let res = firestore.collectionGroup('floors').where('parent_list', "array-contains", structureId)
        res = await res.get()
        const List = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return List;        
    } catch (e) {
        console.log("getAllFloorName error: ", e)
        return { error: e }
    }
}

export const getAllDepartmentInStructure= async (structureId) => {
    try {

        if( structureId ){
            let res = firestore.collectionGroup('departments').where('parent_list', "array-contains", structureId)
            res = await res.get()
            const List = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return List;      
        }
    } catch (e) {
        console.log("getAllDepartmentInStructure error: ", e)
        return { error: e }
    }
}



////////////////////////////////////////////////////////////////////////////

export const getAllStructureName = async () => {
    try {
        let res = firestore.collectionGroup('structures')
        res = await res.get()
        const List = res.docs.map(doc => {
            const firebaseData = doc.data()
            return {id: doc.id, name: firebaseData.name }
        })
        return List;        
    } catch (e) {
        console.log("getAllStructureName error: ", e)
        return { error: e }
    }
}

export const getAllPavillonName = async () => {
    try {
        let res = firestore.collectionGroup('pavillons')
        res = await res.get()
        const List = res.docs.map(doc => {
            const firebaseData = doc.data()
            return {id: doc.id, name: firebaseData.name }
        })
        return List;        
    } catch (e) {
        console.log("getAllPavillonName error: ", e)
        return { error: e }
    }
}

export const getAllFloorName = async () => {
    try {
        let res = firestore.collectionGroup('floors')
        res = await res.get()
        const List = res.docs.map(doc => {
            const firebaseData = doc.data()
            return {id: doc.id, name: firebaseData.name }
        })
        return List;        
    } catch (e) {
        console.log("getAllFloorName error: ", e)
        return { error: e }
    }
}

export const getAllDepartmentName = async (structureId) => {
    try {

        if( structureId ){
            let res = firestore.collectionGroup('departments').where('parent_list', "array-contains", structureId)
            res = await res.get()
            const List = res.docs.map(doc => {
                const firebaseData = doc.data()
                return {id: doc.id, name: firebaseData.name, parent_list:firebaseData.parent_list, referent_list: firebaseData.referent_list }
            })
            return List;      
        } else {
            let res = firestore.collectionGroup('departments')
            res = await res.get()
            const List = res.docs.map(doc => {
                const firebaseData = doc.data()
                return {id: doc.id, name: firebaseData.name, parent_list:firebaseData.parent_list, referent_list: firebaseData.referent_list }
            })
            return List;      
        }
    } catch (e) {
        console.log("getAllDeparmentName error: ", e)
        return { error: e }
    }
}




export { addDepartment };
export { getDepartmentById };
export { getDepartmentList };
export { editDepartment };
export { deleteDepartment };