import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addFailure = async (domainId, contractId, typeId, roomId, data ) => {
    try {
        let ret_data = {};

        const failure_snap = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('failures')
            .where( "room.id", "==", roomId )
            .where( "failure.id", "==", typeId )
            .where( "closed", "==", false)
            .get();
        
        if( failure_snap.docs.length > 0){  // guasto già presente
            const doc = failure_snap.docs.at(0);
            const new_count = doc.count +1;

            await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId).collection('failures')
                .doc(doc.id)
                .update({count: new_count})

            ret_data = {
                count: new_count,
                id: doc.id,
            }

        }else{ // nuovo guasto
            const wData = {
                ...data,
                count: 1
            }

            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId).collection('failures')
                .add(wData);
                
            ret_data = {
                count: 1,
                id: res.id,
            }
        }
        
        return ret_data;

    } catch (e) {
        console.log("addFailure error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getFailureById = async (domainId, contractId, failureId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('failures')
            .doc(failureId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getFailure error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getFailureList = async (domainId, contractId, {closed, roomId, typeId}={}) => {
    try {
        let query = firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('failures')

        if( closed !== undefined){
            query = query.where("closed", "==", closed)
        }

        if( roomId !== undefined){
            query = query.where("room.id", "==", roomId)
        }

        if( typeId !== undefined){
            query = query.where("failure.id", "==", typeId)
        }

        
        
        
        const res = await query.get();

        const failureList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return failureList;
        
    } catch (e) {
        console.log("getFailureList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editFailure = async (domainId, contractId, failureId, data) => {
    try {
        const failure =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('failures')
            .doc(failureId);

        const res = await failure.set(data)
            .then( () => {
                console.log("editFailure - edit successfully")
                return data
            }).catch( error => {
                console.log("editFailure - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editFailure error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteFailure = async (domainId, contractId, failureId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('failures')
            .doc(failureId).delete();
        return res
    }  catch (e) {
        console.log("deleteFailure error: ", e)
        return { error: e }
    }
}