import React, { createContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputGroup, FormControl, Button } from 'react-bootstrap';

const SearchContext = createContext()

export const SearchProvider = (props) => {
    const [searchTerm, setSearchTerm] = useState('')

    const resetSearch = () => {
        setSearchTerm('')
    }



    return (
        <SearchContext.Provider
            value={{
                searchTerm,
                setSearchTerm,
                resetSearch
            }}>

            {props.children}
        </SearchContext.Provider>
    )
}

export default SearchContext