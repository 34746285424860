import {firebase, firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addNews = async ( data ) => {
    try {
        let db_data = {...data, "createdAt": firebase.firestore.Timestamp.now()};


        const res = await firestore.collection('NEWS')
            .add(db_data);
        const newdata = {
            ...db_data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addNews error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getNewsById = async ( newsId) => {
    try {
        const res = await firestore.collection('NEWS')
            .get(newsId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getNews error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getNewsList = async ({author, is_public, contract_type, limit, query_cursor}) => {
    try {
            let query = firestore.collection('NEWS')
                .orderBy('createdAt', 'desc')
                .limit(limit)

            if( author && author !== undefined){
                query = query.where("author", "==", author)
            }

            if( is_public && is_public !== undefined){
                query = query.where("is_public", "==", is_public)
            }

            if( contract_type && contract_type !== undefined){
                query = query.where("type_list", "array-contains", contract_type)
            }

            // if there is a pivot for query starting point
            if(query_cursor && query_cursor !== undefined){
                query = query.startAfter(query_cursor)
            }
                
            const res = await query.get();
            
            if( res.docs.length > 0){    // there is one or more results
                const last = res.docs[ res.docs.length - 1];
                const newsList = res.docs.map(doc => {
                    const firebaseData = doc.data()
                    const data = {
                        ...firebaseData,
                        id : doc.id,
                    }
                    return data
                })
    
                return {newsList: newsList, 
                        query_cursor:  last};  
            
            }else{  // no results
                return {newsList: [], 
                        query_cursor:  undefined};
            }    
    } catch (e) {
        console.log("getNewsList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const editNews = async ( newsId, data) => {
    try {
        const news =  firestore.collection('NEWS')
            .doc(newsId);

        const res = await news.set(data)
            .then( () => {
                console.log("editNews - edit successfully")
                return data
            }).catch( error => {
                console.log("editNews - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editNews error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteNews = async ( newsId) => {
    try {
        const res = await firestore.collection('NEWS')
            .doc(newsId).delete();
        return res
    }  catch (e) {
        console.log("deleteNews error: ", e)
        return { error: e }
    }
}


