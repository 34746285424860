import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import DomainContext from './contexts/domainContext'


import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"

import { getSelectedElement, checkComponentPresence } from "../util/ui_utils.js"
import { ModalInitButton } from '../components/StructureGraphicElements.js'


import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { addDpi as addDpi2, editDpi as editDpi2 } from '../Firebase2/Storage2/dbDpi';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';




const section = 'dpi'
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalDpi({ item, setItem, t, is_new, small, item_list, canWrite }) {
	const { domain } = useContext(DomainContext)

	let { cid } = useParams();
	let contractId = cid

	const childRefAlert = useRef()
	const newdpi = {
		code: '',
		brand: {},
		model: '',
		note: '',
		category: {},
		minvalue: 0,
		media_list: []
	}
	const initValues = async () => {
		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		const category_list = await getBaseObjectTypeList2(domain, 'dpi_category')
		setUiList({ ...ui_list, brand_list, category_list })
		/* 		let newelement_list = await getDpiList(contractId, companyId, 'dpi', null)
				newelement_list.sort(compareName)
				setElementList(newelement_list) */
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newdpi)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newdpi.media_list = []
		setCurrentElement(newdpi)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data =  await addDpi2(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data =  await editDpi2(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data =  await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t}  is_new={is_new} canWrite={canWrite} small={small} title_new={t('dpi.buttons.dpi_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
			<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('dpis.labels.new_dpi')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('dpis.labels.edit_dpi')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('dpis.labels.view_dpi')}</span>
								</>
							]
						}
                        : {curElement.name}&nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('global.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabMedia" disabled={is_new}>{t('global.labels.imagesdocs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSave }} section={section} canWrite={canWrite} />
										</Tab.Pane>

									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)
	const updateBrands = (b) => {
		functions.updateData('brand_list', b)
	}
	const updateCategories = (b) => {
		functions.updateData('dpi_category', b)
	}

	return (
		<>
			<Form.Group as={Row} controlId="dpiName" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="dpiCode" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{/* 			<Form.Group as={Row} controlId="formDpiBrand" className="border-bottom">
				<Form.Label column sm="2">{t('dpis.labels.brand')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.brand.Id} name="brand" className="mb-2" onChange={(event) => functions.edit({ name: 'brand', type: 'select', value: getSelectedElement(event.target.value, ui_list.brand_list) })}  >
						<option value="-1">{t('dpis.labels.select_brand')}</option>
						{ui_list.brand_list
							? [Object(ui_list.brand_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					<ModalBaseObject is_new={true} small={true} cursection={{ name: 'brands', list: ui_list.brand_list }} t={t} functions={{ setCurSection: updateBrands }} />
				</Col>
			</Form.Group> */}
			<Form.Group as={Row} controlId="formDpiCategory" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.category')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.category.Id} name="category" className="mb-2" onChange={(event) => functions.edit({ name: 'category', type: 'select', value: getSelectedElement(event.target.value, ui_list.category_list) })}  >
						<option value="-1">{t('global.labels.select_category')}</option>
						{ui_list.category_list
							? [Object(ui_list.category_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'dpi_category', list: ui_list.category_list }} t={t} functions={{ setCurSection: updateCategories }} />
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="dpiNote" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}


export default translate()(ModalDpi)