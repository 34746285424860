import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addMicrobioCheck = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('MicrobioChecks')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addMicrobioCheck error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getMicrobioById = async (domainId, contractId, microbioId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('MicrobioChecks')
            .doc(microbioId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getMicrobio error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getMicrobioList = async (domainId, contractId, structureId=null) => {
    try {
        if (structureId === null || structureId === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('MicrobioChecks')
                .get();

            const microbioList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return microbioList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('MicrobioChecks')
                .where('structure_list', 'array-contains', structureId)
                .get();

            const microbioList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return microbioList;
        }
    } catch (e) {
        console.log("getMicrobioList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editMicrobio = async (domainId, contractId, microbioId, data) => {
    try {
        const microbio =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('MicrobioChecks')
            .doc(microbioId);

        const res = await microbio.set(data)
            .then( () => {
                console.log("editMicrobio - edit successfully")
                return data
            }).catch( error => {
                console.log("editMicrobio - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editMicrobio error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteMicrobio = async (domainId, contractId, microbioId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('MicrobioChecks')
            .doc(microbioId).delete();
        return res
    }  catch (e) {
        console.log("deleteMicrobio error: ", e)
        return { error: e }
    }
}




