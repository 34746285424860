import {firestore} from '../firebaseIndex'

const addProduct = async (domainId, contractId, cleaningCompanyId, warehouseName, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
        .doc(domainId)
        .collection('contracts')
            .doc(contractId)
            .collection('cleaningCompanies')
            .doc(cleaningCompanyId)
            .collection(warehouseName)
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addProduct error: ", e)
        return { error: e }
    }
}

const getProductById = async (domainId, contractId, cleaningCompanyId, warehouseName, productId) => {
    try {
        const res = await firestore.collection('DOMAINS')
        .doc(domainId)
        .collection('contracts')
            .doc(contractId)
            .collection('cleaningCompanies')
            .doc(cleaningCompanyId)
            .collection(warehouseName)
            .doc(productId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getProduct error: ", e)
        return { error: e }
    }
}

const getProductList = async (domainId, contractId, cleaningCompanyId, warehouseName, category) => {
    try {
        if (category === null) {
            const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
                .doc(contractId)
                .collection('cleaningCompanies')
                .doc(cleaningCompanyId)
                .collection(warehouseName)
                .get();

            const productList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return productList;
        } else {
            const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
                .doc(contractId)
                .collection('cleaningCompanies')
                .doc(cleaningCompanyId)
                .collection(warehouseName)
                .where('category', 'array-contains', category)
                .get();

            const productList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return productList;
        }
    } catch (e) {
        console.log("getProductList error: ", e)
        return { error: e }
    }
}


const editProduct = async (domainId, contractId, cleaningCompanyId, warehouseName, productId, data) => {
    try {
        const product = await firestore.collection('DOMAINS')
        .doc(domainId)
        .collection('contracts')
            .doc(contractId)
            .collection('cleaningCompanies')
            .doc(cleaningCompanyId)
            .collection(warehouseName)
            .doc(productId);

        const res = await product.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editProduct error: ", e)
        return { error: e }
    }
}


const deleteProduct = async (domainId, contractId, cleaningCompanyId, warehouseName, productId) => {
    try {
        const res = await firestore.collection('DOMAINS')
        .doc(domainId)
        .collection('contracts')
            .doc(contractId)
            .collection('cleaningCompanies')
            .doc(cleaningCompanyId)
            .collection(warehouseName)
            .doc(productId).delete();
        return res
    }  catch (e) {
        console.log("deleteProduct error: ", e)
        return { error: e }
    }
}




export { addProduct };
export { getProductById };
export { getProductList };
export { editProduct };
export { deleteProduct };