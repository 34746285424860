import { functions } from '../../Firebase2/firebaseIndex'


// ============================================================================
//
//
// ============================================================================
export const getAllCatalogs = () => {
  const data = {}


  const res = functions
    .httpsCallable('getAllCatalogs')(data)
    .then(result => {
      console.log("result: ", result)
      return result.data;
    })
    .catch((error) => {
      console.log("error calling cloud function: ", error)
    })
  return res
}


// ============================================================================
//
//
// ============================================================================
export const getProductsInCatalog = ({ catalogId, section, category }) => {
  console.log(catalogId, category)
  const data = {
    catalogId: catalogId,
    section: section,
    category: category,
  }


  const res = functions
    .httpsCallable('getProductsInCatalog')(data)
    .then(result => {
      console.log("result: ", result)
      return result.data;
    })
    .catch((error) => {
      console.log("error calling cloud function: ", error)
    })
  return res
}

