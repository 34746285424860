import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, FormControl, InputGroup, Row, Col, Form, Button, Tab, Nav } from 'react-bootstrap';
import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
import { format, setDay } from 'date-fns'
import { it } from 'date-fns/locale'
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
//import "@nateradebaugh/react-datetime/scss/styles.scss";
import CurrencyInput from 'react-currency-input-field';
import { myDateToTimestamp } from '../util/ui_utils.js';
import SubNavBarWarehouse from "../components/SubNavBarWarehouse.js";
const newwarehouse = {
	code: '',
	brand: '',
	purchasedate: new Date(),
	accessory_list: [],
	mainteance_list: [],
	media_list: []
}
const WarehouseView = ({ mylocation, t }) => {
	//	const { locale } = useContext(LocaleContext)

	const [showEdit, setShow] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [curElement, setCurrentElement] = useState(newwarehouse)

	//	const [lang, setLang] = useState('en')
	const handleClose = () => setShow(false);
	const handleSave = () => {
		setShow(false);
	}
	const handleShowNew = () => {
		setCurrentElement(newwarehouse)
		setNew(true)
		setShow(true)
	}
	const handleShowEdit = (id) => {
		setNew(false)
		setShow(true)
	}
	const onChangeHandler = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (event.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}

	return (
		<div>
			{/* <SubNavBarWarehouse curpage={'warehouse'} t={t} /> */}
			<Container fluid>
				<Row className="mt-1">
					<Col sm="4">
						<InputGroup className="mb-3">
							<FormControl type="text" placeholder={t('search')} className="" />
							<InputGroup.Append>
								<Button variant="outline-secondary"><FontAwesomeIcon icon="search" /></Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<Button variant="info" onClick={() => handleShowNew()}><FontAwesomeIcon icon="pencil-alt" /> {t('warehouses.buttons.edit_stocks')}</Button>
					</Col>
				</Row>
				<Row className="p-1 text-light">
					<Col className="bg-secondary ml-1">{t('warehouses.labels.code')}</Col>
					<Col className="bg-secondary ml-1">{t('warehouses.labels.model')}</Col>
					<Col className="bg-secondary ml-1">{t('warehouses.labels.category')}</Col>
					<Col className="bg-secondary ml-1"></Col>
				</Row>
				<Row className="oneCompany p-1 border-bottom">
					<Col>1</Col>
					<Col>Test</Col>
					<Col>...</Col>
					<Col></Col>
					<Col ><Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit(1)}><FontAwesomeIcon icon="pencil-alt" /> </Button><Button variant="danger" size="sm"><FontAwesomeIcon icon='trash' /> </Button></Col>

				</Row>
				<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
					<Modal.Header closeButton>
						{newEdit && <Modal.Title variant="primary">{t('warehouses.modal.titlenew')}</Modal.Title>}
						{!newEdit && <Modal.Title variant="info">{t('warehouses.modal.titleedit')}</Modal.Title>}
					</Modal.Header>
					<Modal.Body>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabA">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item variant="secondary">
											<Nav.Link variant="secondary" eventKey="tabA">{t('warehouses.labels.general')} </Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link variant="secondary" eventKey="tabB">{t('warehouses.labels.Arredies')} </Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link variant="secondary" eventKey="tabC">{t('warehouses.labels.mainteance')}</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link variant="secondary" eventKey="tabD">{t('warehouses.labels.imagesdocs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabA">
											<ElementFormA element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabB">
											<ElementFormB element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabC">
											<ElementFormC element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabD">
											<ElementFormD element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>

									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						<Button className="btn-myprimary" form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" />{t('modal.save')}
						</Button>
					</Modal.Footer>
				</Modal>
			</Container>

		</div>
	);
}

export default translate()(WarehouseView)


function ElementFormA({ element, functions, t }) {

	return (
		<>
			<Form.Group as={Row} controlId="warehouseFirstName" className="border-bottom">
				<Form.Label column sm="2">{t('warehouses.labels.model')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="Model" onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Text className="text-muted" id="warehouseName" value="">
						Insert valid model name
							    </Form.Text>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="warehouseCode" className="border-bottom">
				<Form.Label column sm="2">{t('warehouses.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="code" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formWarehouseCategory" className="border-bottom">
				<Form.Label column sm="2">{t('warehouses.labels.category')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" defaultValue="1" name="category" onChange={(event) => functions.edit(event.target)} >
						<option value="1" checked={(element.level === "-1")}>{t('warehouses.labels.select_category')}</option>
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formWarehouseBrand" className="border-bottom">
				<Form.Label column sm="2">{t('warehouses.labels.brand')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" defaultValue="1" name="brand" onChange={(event) => functions.edit(event.target)} >
						<option value="1" checked={(element.level === "-1")}>{t('warehouses.labels.select_brand')}</option>
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formMachinIsPurchase" className="border-bottom">
				<Form.Label column sm="2">{t('warehouses.labels.puchaserent')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="timing" value="purchase" checked={(element && element.purchaseorrent === 'purchase')} label={t('warehouses.labels.purchase')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="timing" value="rent" checked={(element && element.purchaseorrent === 'rent')} label={t('warehouses.labels.rent')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formWarehousePrice" className="border-bottom">
				<Form.Label column sm="2">{t('warehouses.labels.price')}</Form.Label>
				<Col sm="8">
					<CurrencyInput
						name="price"
						placeholder="€0,00"
						defaultValue={element.price}
						allowDecimals={true}
						className="form-control "
						decimalsLimit={2}
						prefix="€"
						onChange={(value, name) => functions.edit({ name, value })}
						precision={2}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formWarehousePurchasedate" className="border-bottom">
				<Form.Label column sm="2">{t('warehouses.labels.purchase_date')}</Form.Label>
				<Col sm="8">
					<DateTime initialValue={element.purchasedate} dateFormat='DD/MM/YYYY' timeFormat={false} name="purchasedate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'birthdate', value: e.toDate() })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formWarehouseSupplier" className="border-bottom">
				<Form.Label column sm="2">{t('warehouses.labels.supplier')}</Form.Label>
				<Col>
					<Row>

						<Col>
							<Form.Control type="text" name="supplier_code" onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('warehouses.labels.supplier_code')}</Form.Text>
						</Col>
						<Col>
							<Form.Control type="text" name="supplier_name" onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('warehouses.labels.supplier_name')}</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="warehouseNote" className="border-bottom">
				<Form.Label column sm="2">{t('warehouses.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}
function ElementFormB({ element, functions, t }) {
	const newaccessory = {
		code: '',
		description: '',
		warehouse_reference: '',
		quantity: 0,
		price: 0,
		is_mandatory: true
	}
	const [showAccessoryEdit, setShowAccessoryEdit] = useState(false)
	const [newAccessoryEdit, setNewQualificaionEdit] = useState(true)
	const [curAccessory, setCurAccessory] = useState(newaccessory)
	const handleCloseAccessory = () => setShowAccessoryEdit(false);
	const handleSaveAccessory = () => {
		setShowAccessoryEdit(false);
		if (newAccessoryEdit) {
			let newaccessory_list = element.accessory_list
			newaccessory_list.push(curAccessory)
			console.log(newaccessory_list)
			functions.edit({ name: 'accessory_list', value: newaccessory_list })
		} else {

		}
	}
	const handleShowNewAccessory = () => {
		setCurAccessory(newaccessory)
		setNewQualificaionEdit(true)
		setShowAccessoryEdit(true)
	}
	const handleShowEditAccessory = (i) => {
		setCurAccessory(i)
		setNewQualificaionEdit(false)
		setShowAccessoryEdit(true)
	}
	const onChangeHandlerAccessory = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurAccessory({ ...curAccessory, [name]: value })
	}
	return (
		<>
			<Form.Group as={Row} controlId="formWarehouseArredies" className="border-bottom">
				<Col>
					<Row>
						<Col><Button variant="outline-primary" size="sm" onClick={() => handleShowNewAccessory()}><FontAwesomeIcon icon="plus-square" />{t('warehouses.labels.accessory_add')}</Button></Col>
					</Row>
					{element.accessory_list && element.accessory_list.length > 0 ?
						<>
							<Row >
								<Col sm="2" small className="bg-secondary text-light ml-1">{t('warehouses.labels.accessory_code')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('warehouses.labels.accessory_description')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('warehouses.labels.accessory_warehousereference')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('warehouses.labels.accessory_quantity')}</Col>
								<Col className="bg-secondary text-light ml-1">{t('warehouses.labels.accessory_price')}</Col>
								<Col className="bg-secondary text-light ml-1">{t('warehouses.labels.accessory_mandatory')}</Col>
								<Col className="bg-secondary text-light ml-1">&nbsp;</Col>
							</Row>
							{element.accessory_list.map((i, key) => (
								<Row>
									<Col>{i.code}</Col>
									<Col>{i.description}</Col>
									<Col>{i.warehousereference}</Col>
									<Col>{i.quantity}</Col>
									<Col>{i.price}</Col>
									<Col>{i.is_mandatory}</Col>
									<Col>
										<Button size="sm" variant="info" onClick={() => handleShowEditAccessory(i)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
										<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
									</Col>
								</Row>))
							}
						</>
						: <> {t('warehouses.messages.no_accessory')}</>
					}
				</Col>
			</Form.Group>
			<Modal show={showAccessoryEdit} className="border-primary bg-secondary" size="lg">
				<Modal.Header closeButton>
					{newAccessoryEdit && <Modal.Title>{t('warehouses.modal.titlenewaccessory')} </Modal.Title>}
					{!newAccessoryEdit && <Modal.Title>{t('warehouses.modal.titleeditaccessory')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.accessory_code')}</Form.Label>
						<Col>
							<Form.Control type="text" placeholder="" defaultValue={curAccessory.code} name="code" onChange={onChangeHandlerAccessory} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.accessory_description')}</Form.Label>
						<Col>
							<Form.Control type="text" placeholder="" defaultValue={curAccessory.description} name="description" onChange={onChangeHandlerAccessory} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.accessory_warehousereference')}</Form.Label>
						<Col>
							<Form.Control type="text" placeholder="" defaultValue={curAccessory.warehouse_reference} name="warehouse_reference" onChange={onChangeHandlerAccessory} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.accessory_price')}</Form.Label>
						<Col>
							<CurrencyInput
								name="price"
								placeholder="€0,00"
								defaultValue={curAccessory.price}
								allowDecimals={true}
								className="form-control "
								decimalsLimit={2}
								prefix="€"
								onChange={(value, name) => onChangeHandlerAccessory({ name, value })}
								precision={2}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.accessory_quantity')}</Form.Label>
						<Col>
							<Form.Control type="number" min="0" placeholder="" defaultValue={curAccessory.quantity} name="quantity" onChange={onChangeHandlerAccessory} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.accessory_mandatory')}</Form.Label>
						<Col>
							<Form.Check type="radio" name="is_mandatory" value={true} checked={(curAccessory && curAccessory.is_mandatory === true)} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerAccessory(event.currentTarget)} />
							<Form.Check type="radio" name="is_mandatory" value={false} checked={(curAccessory && curAccessory.is_mandatory === false)} label={t('global.labels.no')} onChange={(event) => onChangeHandlerAccessory(event.currentTarget)} />
						</Col>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseAccessory}> {t('modal.close')}</Button>
					<Button className="btn-myprimary" form="accessoryForm" onClick={handleSaveAccessory}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
function ElementFormC({ element, functions, t }) {
	const newmainteance = {
		date: new Date(),
		description: '',
		is_recursive: false,
		color: { r: 10, g: 10, b: 90, a: 1 }
	}
	const [showMainteanceEdit, setShowMainteanceEdit] = useState(false)
	const [newMainteanceEdit, setNewQualificaionEdit] = useState(true)
	const [curMainteance, setCurMainteance] = useState(newmainteance)
	const [displayColorPicker, setShowColorPicker] = useState(false)
	const [color, setCurColor] = useState({ r: 100, g: 0, b: 0, a: 1 })
	const handleCloseMainteance = () => setShowMainteanceEdit(false);
	const styles = reactCSS({
		'default': {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: `rgba(${curMainteance.color.r}, ${curMainteance.color.g}, ${curMainteance.color.b}, ${curMainteance.color.a})`,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		},
	});
	const handleSaveMainteance = () => {
		setShowMainteanceEdit(false);
		let newmainteance_list = element.mainteance_list
		newmainteance_list.push(curMainteance)
		console.log(newmainteance_list)
		functions.edit({ name: 'mainteance_list', value: newmainteance_list })
	}
	const handleShowNewMainteance = () => {
		setCurMainteance(newmainteance)
		setNewQualificaionEdit(true)
		setShowMainteanceEdit(true)
	}
	const onChangeHandlerMainteance = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurMainteance({ ...curMainteance, [name]: value })
	}
	const handleChangeColor = (c) => {
		console.log('set color', c)
		setCurColor(c.rgb)
	}
	const handleShowColorPicker = () => {
		setShowColorPicker(!displayColorPicker)
	}
	const handleCloseColorPicker = () => {
		setShowColorPicker(false)
	}
	return (
		<>
			<Form.Group as={Row} controlId="formWarehouseCF">
				<Col>
					<Row>
						<Col><Button variant="outline-primary" size="sm" onClick={() => handleShowNewMainteance()}><FontAwesomeIcon icon="plus-square" />{t('warehouses.labels.mainteances_add')} </Button></Col>
					</Row>
					{element.mainteance_list && element.mainteance_list.length > 0 ?
						<>
							<Row className="bg-secondary text-light">
								<Col><FontAwesomeIcon icon="check" alt={t('warehouses.labels.mainteance_done')} /> </Col>
								<Col><FontAwesomeIcon icon="retweet" alt={t('warehouses.labels.mainteance_recursive')} /> </Col>
								<Col>{t('warehouses.labels.mainteance_description')} </Col>
								<Col>{t('warehouses.labels.mainteance_date')} </Col>
								<Col>{t('warehouses.labels.mainteance_price')}</Col>
								<Col>&nbsp;</Col>
							</Row>
							{element.mainteance_list.map((i, key) => (
								<Row>
									<Col></Col>
									<Col>{i.is_recursive}</Col>
									<Col>{i.description}</Col>
									<Col>{format(i.date, 'dd/MM/yy')}</Col>
									<Col>{i.price}</Col>
									<Col>
										<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" /> </Button>
										<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
									</Col>
								</Row>))
							}
						</>
						: <> {t('warehouses.messages.no_mainteance')}</>
					}
				</Col>
			</Form.Group>
			<Modal show={showMainteanceEdit} className="border-primary bg-secondary" size="lg">
				<Modal.Header closeButton>
					{newMainteanceEdit && <Modal.Title>{t('warehouses.modal.titlenewmainteance')} </Modal.Title>}
					{!newMainteanceEdit && <Modal.Title>{t('warehouses.modal.titleeditmainteance')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.mainteance_description')}</Form.Label>
						<Col>
							<Form.Control type="text" placeholder="" defaultValue={curMainteance.description} name="description" onChange={onChangeHandlerMainteance} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.mainteance_price')}</Form.Label>
						<Col>
							<CurrencyInput
								name="price"
								placeholder="€0,00"
								defaultValue={curMainteance.price}
								allowDecimals={true}
								className="form-control "
								decimalsLimit={2}
								prefix="€"
								onChange={(value, name) => onChangeHandlerMainteance({ name, value })}
								precision={2}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formQ" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.mainteance_date')} </Form.Label>
						<Col sm="8">
							<DateTime initialValue={curMainteance.date} dateFormat='DD/MM/YYYY' timeFormat={false} name="date" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerMainteance({ name: 'date', value: e.toDate() })} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formQ" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.mainteance_advicecolor')} </Form.Label>
						<Col sm="8">
							<Button variant="outline-info" onClick={handleShowColorPicker}>
								<div style={styles.color} />
							</Button>
							{displayColorPicker ? <div style={styles.popover}>
								<div style={styles.cover} onClick={handleCloseColorPicker} />
								<SketchPicker color={curMainteance.color} onChange={handleChangeColor} onChangeComplete={(e) => onChangeHandlerMainteance({ name: 'color', value: e.rgb })} />
							</div> : null}
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formQ" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.mainteance_recursive')}</Form.Label>
						<Col sm="8">
							<Form.Check type="radio" name="is_recursive" value={true} checked={(curMainteance.is_recursive === true)} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerMainteance(event.currentTarget)} />
							<Form.Check type="radio" name="is_recursive" value={false} checked={(curMainteance.is_recursive === false)} label={t('global.labels.no')} onChange={(event) => onChangeHandlerMainteance(event.currentTarget)} />
						</Col>
					</Form.Group>
					{curMainteance.is_recursive ?
						<Form.Group as={Row} controlId="formQ" className="border-bottom">
							<Form.Label column sm="2">{t('warehouses.labels.mainteance_recursive_section')} </Form.Label>
							<Col>
								<Row>
									<Col>
										<DateTime initialValue={curMainteance.recursive_end} dateFormat='DD/MM/YYYY' timeFormat={false} name="recursive_start" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerMainteance({ name: 'recursive_start', value: myDateToTimestamp(e) })} />
										<Form.Text className="text-muted">
											{t('warehouses.labels.recursive_start')}
										</Form.Text>
									</Col>
									<Col>
										<DateTime initialValue={curMainteance.recursive_end} dateFormat='DD/MM/YYYY' timeFormat={false} name="recursive_end" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerMainteance({ name: 'recursive_end', value: myDateToTimestamp(e)})} />
										<Form.Text className="text-muted">
											{t('warehouses.labels.recursive_end')}
										</Form.Text>
									</Col>
								</Row>
								<Row>
									<Col sm="2">
										<Form.Text className="text-muted">
											{t('warehouses.labels.maiteance_recursion_frequency')}
										</Form.Text>
									</Col>
									<Col sm="1">
										<Form.Check type="checkbox" value="1" label={format(setDay(new Date(), 1), 'cccccc', { locale: it })} />
									</Col>
									<Col sm="1">
										<Form.Check type="checkbox" value="2" label={format(setDay(new Date(), 2), 'cccccc', { locale: it })} />
									</Col>
									<Col sm="1">
										<Form.Check type="checkbox" value="3" label={format(setDay(new Date(), 3), 'cccccc', { locale: it })} />
									</Col>
									<Col sm="1">
										<Form.Check type="checkbox" value="4" label={format(setDay(new Date(), 4), 'cccccc', { locale: it })} />
									</Col>
									<Col sm="1">
										<Form.Check type="checkbox" value="5" label={format(setDay(new Date(), 5), 'cccccc', { locale: it })} />
									</Col>
									<Col sm="1">
										<Form.Check type="checkbox" value="6" label={format(setDay(new Date(), 6), 'cccccc', { locale: it })} />
									</Col>
									<Col sm="1">
										<Form.Check type="checkbox" value="0" label={format(setDay(new Date(), 0), 'cccccc', { locale: it })} />
									</Col>
								</Row>
							</Col>
						</Form.Group>
						: ''
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseMainteance}> {t('modal.close')}</Button>
					<Button className="btn-myprimary" form="companyForm" onClick={handleSaveMainteance}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
function ElementFormD({ element, functions, t }) {
	const newmedia = {
		description: '',
		type: 'image'
	}
	const [showMediaEdit, setShowMediaEdit] = useState(false)
	const [newMediaEdit, setNewQualificaionEdit] = useState(true)
	const [curMedia, setCurMedia] = useState(newmedia)
	const handleCloseMedia = () => setShowMediaEdit(false);
	const handleSaveMedia = () => {
		setShowMediaEdit(false);
		if (newMediaEdit) {
			let newmedia_list = element.media_list
			newmedia_list.push(curMedia)
			console.log(newmedia_list)
			functions.edit({ name: 'media_list', value: newmedia_list })
		} else {

		}
	}
	const handleShowNewMedia = () => {
		setCurMedia(newmedia)
		setNewQualificaionEdit(true)
		setShowMediaEdit(true)
	}
	const handleShowEditMedia = (i) => {
		setCurMedia(i)
		setNewQualificaionEdit(false)
		setShowMediaEdit(true)
	}
	const onChangeHandlerMedia = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurMedia({ ...curMedia, [name]: value })
	}
	return (
		<>
			<Form.Group as={Row} controlId="formWarehouseMedia" className="border-bottom">
				<Col>
					<Row>
						<Col><Button variant="outline-primary" size="sm" onClick={() => handleShowNewMedia()}><FontAwesomeIcon icon="plus-square" />{t('warehouses.labels.media_add')}</Button></Col>
					</Row>
					{element.media_list && element.media_list.length > 0 ?
						<>
							<Row >
								<Col sm="2" small className="bg-secondary text-light ml-1">{t('warehouses.labels.media_type')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('warehouses.labels.media_description')} </Col>
								<Col className="bg-secondary text-light ml-1">&nbsp;</Col>
							</Row>
							{element.media_list.map((i, key) => (
								<Row>
									<Col>{i.code}</Col>
									<Col>{i.description}</Col>
									<Col>{i.warehousereference}</Col>
									<Col>{i.quantity}</Col>
									<Col>{i.price}</Col>
									<Col>{i.is_mandatory}</Col>
									<Col>
										<Button size="sm" variant="info" onClick={() => handleShowEditMedia(i)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
										<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
									</Col>
								</Row>))
							}
						</>
						: <> {t('warehouses.messages.no_media')}</>
					}
				</Col>
			</Form.Group>
			<Modal show={showMediaEdit} className="border-primary bg-secondary" size="lg">
				<Modal.Header closeButton>
					{newMediaEdit && <Modal.Title>{t('warehouses.modal.titlenewmedia')} </Modal.Title>}
					{!newMediaEdit && <Modal.Title>{t('warehouses.modal.titleeditmedia')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.media_description')}</Form.Label>
						<Col>
							<Form.Control type="text" placeholder="" defaultValue={curMedia.description} name="description" onChange={onChangeHandlerMedia} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.media_type')}</Form.Label>
						<Col>
							<Form.Check type="radio" name="type" value="image" checked={(curMedia && curMedia.type === 'image')} label={t('global.labels.image')} onChange={(event) => onChangeHandlerMedia(event.currentTarget)} />
							<Form.Check type="radio" name="type" value="document" checked={(curMedia && curMedia.type === 'document')} label={t('global.labels.document')} onChange={(event) => onChangeHandlerMedia(event.currentTarget)} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formWarehouseCF" className="border-bottom">
						<Form.Label column sm="2">{t('warehouses.labels.media_file')}</Form.Label>
						<Col>
							<Form.Control type="file" name="file" />
						</Col>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseMedia}> {t('modal.close')}</Button>
					<Button className="btn-myprimary" form="mediaForm" onClick={handleSaveMedia}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}