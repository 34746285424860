import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addCheckEvent = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('checkagenda')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addCheckEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getCheckEventById = async (domainId, contractId, checkeventId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('checkagenda')
            .get(checkeventId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getCheckEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getCheckEventList = async (domainId, contractId, structure=null) => {
    try {
        if (structure === null || structure === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('checkagenda')
                .get();

            const checkeventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return checkeventList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('checkagenda')
                .where('extendedProps.structure', '==', structure)
                .get();

            const checkeventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return checkeventList;
        }
    } catch (e) {
        console.log("getCheckEventList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getCheckEventRange = async (domainId, contractId, startDate, endDate) => {
    try {
       const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('checkagenda')
                .where('start', ">=", startDate)
                .where('start', "<=", endDate)
                .get();

            const checkeventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return checkeventList;
        
    } catch (e) {
        console.log("getCheckEventList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editCheckEvent = async (domainId, contractId, checkeventId, data) => {
    try {
        const checkevent =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('checkagenda')
            .doc(checkeventId);

        const res = await checkevent.set(data)
            .then( () => {
                console.log("editCheckEvent - edit successfully")
                return data
            }).catch( error => {
                console.log("editCheckEvent - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editCheckEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteCheckEvent = async (domainId, contractId, checkeventId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('checkagenda')
            .doc(checkeventId).delete();
        return res
    }  catch (e) {
        console.log("deleteCheckEvent error: ", e)
        return { error: e }
    }
}





