import React  from 'react';
import { DropdownButton, ButtonGroup } from 'react-bootstrap';


import ModalTicket from '../components/ModalTicket.js';


const ticket_list = [
    'request', 'communication', 'noncompliance', 'failure', 'registry_change'
]

export const TicketDropdown = ({ t, uData, canWrite, message_list, setMessageList, ui_list, item, label, showButton }) => {
    const parent_data = item? [...item.parent_list, item]: undefined
    if (canWrite || uData || (uData.sendmessage && uData.sendmessage.length > 0)) {


        return (
            <>
                <DropdownButton as={ButtonGroup} title={label} id="bg-nested-dropdown" size="sm" variant="outline-primary">
                    {ticket_list.filter(t => t !== 'failure').filter((tk)=> 
                    uData.isGlobalAdmin || (uData.sendmessage && uData.sendmessage.indexOf(tk) >= 0)
                    ).map((tk, k) => (
                        <ModalTicket is_new={true} t={t} item={message_list} setItem={setMessageList} ui_list={ui_list} item_list={message_list} type={tk} parent_list={parent_data} is_menu={k+1} userData={uData} />
                    ))}
                </DropdownButton>
            </>
        )
    } else {
        return (<>
        </>)
    }
}