import React from 'react';


// < MATERIAL UI >
import { makeStyles, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
// </ MATERIAL UI > 


const useStyles = makeStyles((theme) => ({
    root: {
        background: 'linear-gradient(45deg, #BBBBBB 10%, #ffffff 90%)',
        border: 0,
        bottom: 10,
        color: 'white',
        height: '32px',
        padding: '0 0px',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    claudit: {
        padding: theme.spacing(0, 0),
        color: 'black',
        height: '32px',
    },
}));

export const Footer = () => {
    const classes = useStyles();
    return(
        <AppBar position="static" color="primary"  className={classes.root}>
          <Container maxWidth="md">
            <img  className={classes.claudit} 
                                src='Logo.Claudit.registrato.png' 
                                alt='claudit'
                            />
          </Container>
        </AppBar>
    );
}