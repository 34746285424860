import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Badge, Row, Col, Button, Spinner, ButtonGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import { materialIntendedUse_dict } from "../components/constants/global_definitions"

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"
import ModalCleaningmaterial from "../components/ModalCleaningmaterial"
import ModalImportCatalog from '../components/ModalImportCatalog';


import { checkComponentPresence, compareName, getMediaIcon, getElementPreview, getElementCount, getBaseObjectValue } from "../util/ui_utils.js"
import { RenderItemFileBadge } from '../components/StructureGraphicElements.js'



import { getProductList as getProductList2, deleteProduct as deleteProduct2 } from '../Firebase2/Storage2/dbWarehouse';
import { getCatalogProductList, deleteCatalogProduct} from '../Firebase2/Storage2/dbCatalogWarehouse';
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"






const companyId = '0'
const section = 'cleaningmaterial'


const CleaningmaterialsView = ({ t, uData, subsection }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, risk_area_list, risk_area_dict, } = useContext(GlobalDataContext)
	const { locale } = useContext(LocaleContext)
	const { initContract } = InitContractHelper()
	const load_page = useRef(false)


	let { cid } = useParams();
	let contractId = cid

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)


	let intended_use_dict = materialIntendedUse_dict({ t })

	const initValues = async () => {
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('cleaningmaterials') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {
			contractId = contract.id
			const brand_list = await getBaseObjectTypeList2(domain, 'brands')
			const composition_list = await getBaseObjectTypeList2(domain, 'cleaningmaterial_composition')
			const category_list = await getBaseObjectTypeList2(domain, 'cleaningmaterial_category')
			setUiList({ ...ui_list, brand_list, category_list, composition_list })
			let newelement_list = []
			if (domain === 'catalog') {
				newelement_list = await getCatalogProductList(contractId, section, null)
				if (subsection){
					newelement_list.filter(p => p.intended_use === 'economic_material')
				}else{
					newelement_list.filter(p => p.intended_use !== 'economic_material')
				}
			} else {
				newelement_list = await getProductList2(domain, contractId, companyId, section, null)
			}
			newelement_list.sort(compareName)
			setElementList(newelement_list)
			setLoading(false)
		}
	}

	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("OPERATIONS reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("OPERATIONS init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useEffect(() => {
		console.log('MATERIAL loadingcontract', contract)
		if (contract && !load_page.current) {
			initValues()
		}
	}, [contract])




	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data 
			if (domain ==='catalog'){
				return_data = await deleteCatalogProduct(contractId, section, el.id)
			}else{
				return_data = await deleteProduct2(domain, contractId, companyId, section, el.id)

			}
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('product.messages.delete_cleaningmaterial_title'), t('product.messages.delete_cleaningmaterial', { name: el.name }), cb)
	}



	const getIntendedUse = (cur_use) => {

		const use = intended_use_dict[cur_use]
		if (use) {
			return (
				<>
					{use.label}
				</>
			)

		} else {
			return <></>
		}

	}


	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={subsection==='economic_material'? t('products.labels.economic_material') : t('navs.registries.cleaningmaterials')} />
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite ?
							<>
								<ModalImportCatalog t={t} section={section} ui_list={ui_list} item_list={element_list} setItem={setElementList}  />
								<ModalCleaningmaterial is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} canWrite={canWrite} ui_list={ui_list} setUiList={setUiList} subsection={subsection}/>
							</>
							: ''}
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="1">{t('global.labels.image')}</Col>
						<Col className="bg-secondary ml-1" sm="1">{t('global.labels.code')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.name')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.brand')}</Col>
						<Col className="bg-secondary ml-1">{t('products.labels.intended_use')}</Col>
						<Col className="bg-secondary ml-1">{t('products.labels.category')}</Col>
						<Col className="bg-secondary ml-1 p-0 text-center" sm="1"><FontAwesomeIcon icon='image' />&nbsp;<FontAwesomeIcon icon='file' /></Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					: <>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row key={key} className="oneCompany p-1 border-bottom">
										<Col sm="1">{getElementPreview(i)} </Col>
										<Col sm="1">{i.code}</Col>
										<Col className="font-weight-bold">
											<Badge style={{
												background: i.color
											}}>&nbsp;</Badge>
											{i.name}
											{i.intended_use ==='economic_material'?
											<>
							<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={<Tooltip>Materiale economale</Tooltip>}>
								<FontAwesomeIcon icon="pump-soap" />
							</OverlayTrigger>
											</>
											:''}
										</Col>
										<Col>{getBaseObjectValue(i.brand, 'name', locale)}</Col>
										<Col>{getIntendedUse(i.intended_use)}</Col>
										<Col>{getBaseObjectValue(i.category, 'name', locale)}</Col>
										<Col sm="1" className="p0 text-center">
											<RenderItemFileBadge media_list={i.media_list} section={section} position="left-start" />
										</Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												<ModalCleaningmaterial is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite  && !i.catalog_id} ui_list={ui_list}  setUiList={setUiList} subsection={subsection}/>
												{canWrite ?
													<Button variant="danger" size="sm"><FontAwesomeIcon icon='trash' onClick={() => handleDelete(i)} /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							: <Row>
								<Col className="font-italic">
									{subsection ==='economic_material'?
									t('cleaningmaterials.messages.no_economicmaterial')
									:
									t('cleaningmaterials.messages.no_cleaningmaterial')
									}
								</Col>
							</Row>
						}
					</>
				}
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('navs.registries.cleaningmaterials') })} />

		</>
	);
}






export default translate()(CleaningmaterialsView)

