import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addAmount = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('amountrecords')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addAmount error: ", e)
        return { error: e }
    }
}





///////////////////////////////////////////////////////////////////////////////
export const getAmountById = async (domainId, contractId, amountId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('amountrecords')
            .get(amountId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getAmount error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getAmountList = async (domainId, contractId, structure_id=null) => {
    
    try {
        if (structure_id === null || structure_id === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('amountrecords')
                .get();

            const amountList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return amountList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('amountrecords')
                .where('structure_id', '==', structure_id)
                .get();

            const amountList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return amountList;
        }
    } catch (e) {
        console.log("getAmountList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getAmountRange = async (domainId, contractId, startDate, endDate, structure_id=null) => {
    console.log("DB AMOUNT", domainId, contractId, startDate, endDate, structure_id)
    try {
        let query = firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('amountrecords')
        if( structure_id !== undefined){
            query = query.where('structure_id', '==', structure_id)
        }
        query = query
                .where('date', ">=", startDate)
                .where('date', "<", endDate)
                
        const res = await query.get();

            const amountList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return amountList;
        
    } catch (e) {
        console.log("getAmountRange error: ", e)
        return { error: e }
    }
}






///////////////////////////////////////////////////////////////////////////////
export const editAmount = async (domainId, contractId, amountId, data) => {
    try {
        const amount =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('amountrecords')
            .doc(amountId);

        const res = await amount.set(data)
            .then( () => {
                console.log("editAmount - edit successfully")
                return data
            }).catch( error => {
                console.log("editAmount - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editAmount error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteAmount = async (domainId, contractId, amountId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('amountrecords')
            .doc(amountId).delete();
        return res
    }  catch (e) {
        console.log("deleteAmount error: ", e)
        return { error: e }
    }
}



