import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addMessageGroup = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('messagegroups')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addMessageGroup error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getMessageGroupById = async (domainId, contractId, messagegroupId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('messagegroups')
            .get(messagegroupId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getMessageGroup error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getMessageGroupList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('messagegroups')
            .get();

        const messagegroupList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return messagegroupList;
    } catch (e) {
        console.log("getMessageGroupList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const editMessageGroup = async (domainId, contractId, messagegroupId, data) => {
    try {
        const messagegroup =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('messagegroups')
            .doc(messagegroupId);

        const res = await messagegroup.set(data)
            .then( () => {
                console.log("editMessageGroup - edit successfully")
                return data
            }).catch( error => {
                console.log("editMessageGroup - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editMessageGroup error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteMessageGroup = async (domainId, contractId, messagegroupId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('messagegroups')
            .doc(messagegroupId).delete();
        return res
    }  catch (e) {
        console.log("deleteMessageGroup error: ", e)
        return { error: e }
    }
}




