import {firestore} from '../firebaseIndex' 

/**
 * 
 * @param {string} domainId 
 * @param {string} contractId 
 * @param {json} data 
 */
export const addIntervention = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('interventions')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addIntervention error: ", e)
        return { error: e }
    }
}

/**
 * 
 * @param {string} domainId 
 * @param {string} contractId 
 * @param {string} interventionId - id intervento
 */
export const getInterventionById = async (domainId, contractId, interventionId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
			.collection('interventions')
			.doc(interventionId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getIntervention error: ", e)
        return { error: e }
    }
}

/**
 * 
 * @param {string} domainId 
 * @param {string} contractId 
 * @param {string} structure - structure id (optional)
 * @param {string} type - type of intervention (optional)
 */
export const getInterventionList = async (domainId, contractId, structure=null, type=null) => {
    try {
        if (structure === null) {
            if ( type === null){
                const res = await firestore.collection('DOMAINS')
                    .doc(domainId)
                    .collection('contracts')
                    .doc(contractId)
                    .collection('baseCompany')
                    .doc('BASE')
                    .collection('interventions') 
                    .get();

                const interventionList = res.docs.map(doc => {
                    const firebaseData = doc.data()
                    const data = {
                        ...firebaseData,
                        id : doc.id,
                    }
                    return data
                })
                return interventionList;
            }else{
                const res = await firestore.collection('DOMAINS')
                    .doc(domainId)
                    .collection('contracts')
                    .doc(contractId)
                    .collection('baseCompany')
                    .doc('BASE')
                    .collection('interventions')
                    .where('type', '==', type)
                    .get();

                const interventionList = res.docs.map(doc => {
                    const firebaseData = doc.data()
                    const data = {
                        ...firebaseData,
                        id : doc.id,
                    }
                    return data
                })
                return interventionList;
            }
        } else {
            if(type === null){
                const res = await firestore.collection('DOMAINS')
                    .doc(domainId)
                    .collection('contracts')
                    .doc(contractId)
                    .collection('baseCompany')
                    .doc('BASE')
                    .collection('interventions')
                    .where('structures', 'array-contains', structure)
                    .get();

                const interventionList = res.docs.map(doc => {
                    const firebaseData = doc.data()
                    const data = {
                        ...firebaseData,
                        id : doc.id,
                    }
                    return data
                })
                return interventionList;
            }else{
                const res = await firestore.collection('DOMAINS')
                    .doc(domainId)
                    .collection('contracts')
                    .doc(contractId)
                    .collection('baseCompany')
                    .doc('BASE')
                    .collection('interventions')
                    .where('structures', 'array-contains', structure)
                    .where('type', '==', type)
                    .get();

                const interventionList = res.docs.map(doc => {
                    const firebaseData = doc.data()
                    const data = {
                        ...firebaseData,
                        id : doc.id,
                    }
                    return data
                })
                return interventionList;
            }
        }
    } catch (e) {
        console.log("getIntervention error: ", e)
        return { error: e }
    }
}

/**
 * 
 * @param {string} domainId 
 * @param {string} contractId 
 * @param {string} interventionId 
 * @param {json} data 
 */
export const editIntervention = async (domainId, contractId, interventionId, data) => {
    try {
        const intervention = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('interventions')
            .doc(interventionId);

        const res = await intervention.set(
            data
        ).then( () => {
			console.log("editIntervention - edit successfully")
			return data
		}).catch( error => {
			console.log("editIntervention - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editIntervention error: ", e)
        return { error: e }
    }
}

/**
 * 
 * @param {string} domainId 
 * @param {string} contractId 
 * @param {string} interventionId 
 */
export const deleteIntervention = async (domainId, contractId, interventionId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('interventions')
            .doc(interventionId).delete();
        return res
    }  catch (e) {
        console.log("deleteIntervention error: ", e)
        return { error: e }
    }
}