
let eventGuid = 0

export const INITIAL_EVENTS = [
/*  {
    id: createEventId(),
    title: 'All-day event',
    extendedProps:{

    subject: 'all day event',
    location: 'mylocation',
    note:'',
    },
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    extendedProps:{
    subject: 'timed event',
    location: 'mylocatio22',
    note:'',
    },
    start: todayStr + 'T12:00:00',
    end: todayStr + 'T12:30:00'
  }*/
]

export function createEventId() {
  return String(eventGuid++)
}