import { Container, Row, Col  } from 'react-bootstrap';
import React, { useState } from 'react';
import { translate } from 'react-polyglot'
import systemparams from "../components/constants/systemparams"
/* class ErrorView extends Component {
	render() {
		return (
			<Container fluid>
				<Row>
					<Col className="h1 text-center">
							Caricamento pagina
						</Col>
				</Row>
				<Row>
					<Col className="mt-2 text-center border-top pt-3">
						<img
							src="/Logo.Claudit.registrato.png"
							height="80"
							className="d-inline-block align-middle"
							alt="CloudIt"
						/>
					</Col>
				</Row>
			</Container>
		);
	}
} */
const ErrorView = (props) => {
	document.title = systemparams.sw_name
	const [pageStatus, setPageStatus] = useState('Caricamento pagina')
	setTimeout(() => {
		setPageStatus('Dominio non disponibile')

	}, 6000);
	return (
		<Container fluid>
			<Row>
				<Col className="h1 text-center">
					{pageStatus}
				</Col>
			</Row>
			<Row>
				<Col className="mt-2 text-center border-top pt-3">
					<img
						src="/Logo.Claudit.registrato.png"
						height="80"
						className="d-inline-block align-middle"
						alt="CloudIt"
					/>
				</Col>
			</Row>
		</Container>

	)

}
export default translate()(ErrorView);