import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Image, Row, Col, Button, Spinner, Card } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useTranslate } from 'react-polyglot';
import { format } from 'date-fns'
import { useParams } from "react-router-dom"

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'




import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"


import { myTimestampToDate, getValue, propComparator } from "../util/ui_utils.js"
import { mediaType_dict } from '../components/constants/global_definitions.js';


import { getSystemDocumentList } from '../Firebase2/Storage2/dbSystemDocument.js';





let load_system = true

const compareFilename = (a, b) =>{

    console.log(a.tracker.source_name, b.tracker.source_name)
	if (a.tracker.source_name < b.tracker.source_name) {
		return -1;
	}
	if (a.tracker.source_name > b.tracker.source_name) {
		return 1;
	}
	return 0;
//	(a, b) => a.tracker.source_name === b.tracker.source_name ? 0 : a.tracker.source_name < b.tracker.source_name ? -1 : 1
//	(a, b) => a.tracker.path === b.tracker.path ? 0 : a.tracker.path < b.tracker.path ? -1 : 1
 //   (a,b) => a.id === a.id ? 0: a.id<a.id ? -1: 1

}
////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
////////////////////////////////////////////////////////////////////////////////////////////////////////
const DocumentsSystemView = ({ t, uData }) => {

    const childRefAlert = useRef()

    const [loading, setLoading] = useState(true)

    const [media_list, setMediaList] = useState([])

    


    useEffect(() => {
 
        const fetchData = async () =>{
            const ret_data = await getSystemDocumentList({})
            console.log(ret_data)
            const ordered = ret_data.systemdocumentList.sort(compareFilename)
            console.log(ret_data.systemdocumentList, ordered)
            if (!ret_data.error){
                setMediaList(ret_data.systemdocumentList.sort(compareFilename))
            }
            setLoading(false)
        }
        fetchData()
    }, [])





    const getElementCount  =(t) =>{

        if (media_list){
		    return (<>{media_list.length} {t('global.labels.documents')}</>)
        }else{
            return (<>0 {t('global.labels.documents')}</> )
        }
    }

    return (
        <>
            <AlertSection ref={childRefAlert} />
            <Container className="titleStickyTop" fluid>
                <Row className="mt-1">
                    <Col className="h3">
                        <PageTitle title={t('global.pages.systemdocument_management')} />
                    </Col>
                </Row>

            </Container>
            <Container fluid>

                {loading ?
                    <Row>
                        <Col>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    :


                    <Row>{media_list?
                        <>
                            {media_list.map((f, d_index) => (
                                <OneFile doc={f} key={d_index} />
                            ))}
                        </>
                        :<Col><i>Nessun documento presente</i></Col>}
                    </Row>
                }
            </Container>
            <Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount( t)} />
        </>
    );
}






/*const OneImage = ({ doc }) => {
    return (
        <Image className="imageDocumentViewerSmall" src={doc.url} />
    )
}

const OneDocument = ({ doc }) => {
    const t = useTranslate();
    const getLabel = (doc) => {
        try {
            return t(type_dict[doc.type].label)
        } catch (err) {
            return doc.type
        }
    }
    return (

        <Row className="border-bottom mb-1 pb-1">
            <Col>
                {doc.description.length > 0 ? doc.description : <i>doc.source_name</i>}
            </Col>
            <Col>
                {getLabel(doc)}
            </Col>
            <Col sm="2" className="p-1 text-center">
                <Button size="sm" className="mr-1" variant="primary" href={doc.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" /> {t('media.buttons.open')}</Button>
            </Col>
        </Row>

    )
} */


const OneFile = ({ doc }) => {
    const { locale } = useContext(LocaleContext)
    const t = useTranslate();
    const type_dict = mediaType_dict({t, section:'contract'})
    const getLabel = (doc) => {
        try {
            return type_dict[doc.type].label
        } catch (err) {
            return doc.type
        }
    }

    return (
        <Col sm="6" md="4" xl="2" className="mb-2">
            <Card border="primary" className="h-100">
                {doc.type === 'image' ?
                    <span className="d-flex" style={{ maxHeight: '150px' }}>
                        <Image className="imageDocumentViewer" src={doc.url} />
                    </span>
                    : <Card.Header> {doc.description.length > 0 ? doc.description : <i>{doc.source_name}</i>}</Card.Header>
                }
                <Card.Body>
                    <Row>
                        <Col>
                            {doc.tracker.source_name}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="font-italic">
                            {getLabel(doc)}
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col>
                            <small className="text-muted">data inserimento: {doc.createdAt ? format(myTimestampToDate(doc.createdAt), 'dd/MM/yyyy HH:mm') : "non disponibile"}</small>
                        </Col>
                        <Col className="text-right" sm="4">
                            <Button size="sm" className="mr-1" variant="primary" href={doc.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" /> {t('media.buttons.open')}</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Col>


    )

}


export default translate()(DocumentsSystemView)