import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from '../util/contract.js';

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"
import ModalMachine from "../components/ModalMachine"
import ModalImportCatalog from '../components/ModalImportCatalog';

import { checkComponentPresence, getMediaIcon, getElementPreview, getElementCount, getBaseObjectValue, compareName } from "../util/ui_utils.js"
import { RenderItemFileBadge } from '../components/StructureGraphicElements.js'







import { getProductList as getProductList2, deleteProduct as deleteProduct2 } from '../Firebase2/Storage2/dbWarehouse';
import { getCatalogProductList, deleteCatalogProduct} from '../Firebase2/Storage2/dbCatalogWarehouse';
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"




let load_contract = true
let load_page = false
const companyId = '0'
const section = 'machine'

////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const MachinesView = ({ t, uData }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()
	let { cid } = useParams();
	let contractId = cid

	const { locale } = useContext(LocaleContext)
	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')

	const initValues = async () => {
		console.log(uData)
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('machines') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {
			contractId = contract.id
		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		const category_list = await getBaseObjectTypeList2(domain, 'machine_category')
		setUiList({ ...ui_list, brand_list, category_list })
		let newelement_list = []
		if (domain === 'catalog') {
			newelement_list = await getCatalogProductList(contractId, section, null)
		} else {
			newelement_list = await getProductList2(domain, contractId, companyId, section, null)
		}
		newelement_list.sort(compareName)
		setElementList(newelement_list)
		setLoading(false)
		} else {
			console.log('problem')
		}
	}

	const onUpdateData = (data, b) => {
		setUiList({ ...ui_list, [data]: b.list })
	}



	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("MACHINES reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("MACHINES init contract return", ret_data)
			}
		}
		if (!contract) {
			loadData()
		}
	}, [])


	useEffect(() => {
		console.log('MACHINES loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])



	const handleDelete = (el) => {
		console.log(domain, contractId, el)
		const cb = async () => {
			let return_data
			if (domain ==='catalog'){
				return_data = await deleteCatalogProduct(contractId, section, el.id)
			}else{
				return_data = await deleteProduct2(domain, contractId, companyId, section, el.id)

			}
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('product.messages.delete_machine_title'), t('product.messages.delete_machine', { name: el.name }), cb)
	}

	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('navs.registries.machines')} />
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite ?
							<>
								<ModalImportCatalog t={t} section={section} ui_list={ui_list} item_list={element_list} setItem={setElementList}  />
								<ModalMachine is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} canWrite={canWrite} ui_list={ui_list} setUiList={setUiList}/>
							</>
							: ''}
					</Col>
				</Row>
				{!loading && element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="1"></Col>
						<Col className="bg-secondary ml-1" sm="2">{t('machines.labels.code')}</Col>
						<Col className="bg-secondary ml-1">{t('machines.labels.model')}</Col>
						<Col className="bg-secondary ml-1">{t('machines.labels.brand')}</Col>
						<Col className="bg-secondary ml-1">{t('machines.labels.category')}</Col>
						<Col className="bg-secondary ml-1 p-0 text-center" sm="1"><FontAwesomeIcon icon='image' />&nbsp;<FontAwesomeIcon icon='file' /></Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					: <>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row className="oneCompany p-1 border-bottom">
										<Col sm="1">{getElementPreview(i)} </Col>
										<Col sm="2">{i.code}</Col>
										<Col><b>{i.name}</b></Col>
										<Col>{getBaseObjectValue(i.brand, 'name', locale)}</Col>
										<Col>{getBaseObjectValue(i.category, 'name', locale)}</Col>
										<Col sm="1" className="p0 text-center">
											<RenderItemFileBadge media_list={i.media_list} section={section} position="left-start" />
										</Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												<ModalMachine is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite  && !i.catalog_id} ui_list={ui_list}  setUiList={setUiList}/>
												{canWrite ?
													<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							: <Row>
								<Col className="font-italic">
									{t('machines.messages.no_machine')}
								</Col>
							</Row>
						}
					</>
				}
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('navs.registries.machines') })} />

		</>
	);
}












export default translate()(MachinesView)