import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, InputGroup, ButtonGroup, Card, Badge } from 'react-bootstrap';

import styles from '../components/constants/styles.js'
import { roomComposition_dict } from "../components/constants/global_definitions"

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';
import EditContext from "../components/contexts/edit.js"

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"

import { checkComponentPresence, compareName, propComparator } from "../util/ui_utils.js"
import { MultipleToggleEdit, AddListButton } from './StructureGraphicElements.js'




import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import {
	addFurniture as addFurniture2,
	getFurnitureList as getFurnitureList2,
	editFurniture as editFurniture2,
	deleteFurniture as deleteFurniture2,

	addEnvironmentElement as addEnvironmentElement2,
	getEnvironmentElementList as getEnvironmentElementList2,
	editEnvironmentElement as editEnvironmentElement2,
	deleteEnvironmentElement as deleteEnvironmentElement2,

	addEconomicalMaterial as addEconomicalMaterial2,
	getEconomicalMaterialList as getEconomicalMaterialList2,
	editEconomicalMaterial as editEconomicalMaterial2,
	deleteEconomicalMaterial as deleteEconomicalMaterial2
} from '../Firebase2/Storage2/dbRoomMaterial';
import { getProductList } from '../Firebase2/Storage2/dbWarehouse';

//const contractId = sessionStorage.getItem('contractId')
const companyId = '0'

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function ElementFormComposition({ element, functions, t, is_type, canWrite }) {
	const is_operation = false
	const { domain } = useContext(DomainContext)
	const { contractId, contract } = useContext(GlobalDataContext)
	const edit = useContext(EditContext)
	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const roomcomposition_dict = roomComposition_dict({ t })

	const newcomponent = {
		name: '',
		type: '',
		control_list: [],
		quantity: 1
	}

	const newgood = {
		name: '',
		control_list: [],
		category: '',
	}




	const [furniture_list, setFurnitureList] = useState([])
	const [environment_element_list, setEnvironmentElementList] = useState([])
	const [economic_material_list, setEconomicMaterialList] = useState([])
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [newComponentEdit, setNewComponentEdit] = useState(true)
	const [curComponent, setCurComponent] = useState(newcomponent)
	const [showGoodEdit, setShowGoodEdit] = useState(false)
	const [curGood, setCurGood] = useState(newgood)
	const [newEdit, setNewEdit] = useState(true)



	const handleCloseComposition = () => setShowComponentEdit(false);

	const handleOpenComposition = () => {
		setCurComponent(newcomponent)
		setNewComponentEdit(true)
		setShowComponentEdit(true)
	}

	//---------------generic function
	const handleAddEl = (el, section) => {
		console.log("ADD EL", section, el)
		let editlist = element[section]
		if (editlist) {
			const c = checkComponentPresence(el, element[section], 'id')
			if (c >= 0) {
				editlist[c].quantity = parseInt(editlist[c].quantity) + 1
			} else {
				if (el.name.toLowerCase().indexOf('cestino') >= 0) {
					el.is_trash = true
				}
				editlist.push({ ...el, quantity: 1 })
			}
		} else {
			editlist = [{ ...el, quantity: 1 }]
		}
		functions.edit({ name: section, value: editlist })
	}



	const handleSubtractEl = (el, section) => {
		console.log("SUB EL", section, el)
		let editlist = element[section]
		const c = checkComponentPresence(el, element[section], 'id')
		if (editlist[c].quantity > 1) {
			editlist[c].quantity = parseInt(editlist[c].quantity) - 1
		} else {

		}
		functions.edit({ name: section, value: editlist })
	}



	const handleChangeElement = (el, section) => {
		console.log("SUB EL", section, el)
		let editlist = element[section]
		const c = checkComponentPresence(el, element[section], 'id')
		editlist[c] = el
		functions.edit({ name: section, value: editlist })

	}

	/* 
		const handleChangeElementControl = (el, section) => {
			console.log("control", el, section, el.index, element[section], element[section].control_list)
			let editlist = element[section]
			try {
				editlist[el.index].control_list = el.value
				functions.edit({ name: section, value: editlist })
			} catch (err) {
	
			}
		}
		const handleDeleteElControl = (index, control_index, list) => {
			let editlist = element[list]
			let editcontrol = editlist[index].control_list
			editcontrol.splice(control_index, 1)
			editlist[index].control_list = editcontrol
			functions.edit({ name: list, value: editlist })
		}
	 */
	const handleDeleteEl = (el, list_name) => {
		console.log(el, list_name)
		const cb = () => {
			let editlist = element[list_name]
			const c = checkComponentPresence(el, element[list_name], 'id')
			editlist.splice(c, 1)
			functions.edit({ name: list_name, value: editlist })
		}

		if (list_name === 'furniture_list') {
			childRefDelete.current.confirmDelete(roomcomposition_dict[list_name].delete_title, roomcomposition_dict[list_name].delete, cb)
		} else if (list_name === 'environment_element_list') {
			childRefDelete.current.confirmDelete(roomcomposition_dict[list_name].delete_title, roomcomposition_dict[list_name].delete, cb)
		} else if (list_name === 'economic_material_list') {
			childRefDelete.current.confirmDelete(roomcomposition_dict[list_name].delete_title, roomcomposition_dict[list_name].delete, cb)
		}
	}

	const handleChangeParamEl = (el, event, list) => {
		let editlist = element[list]
		const c = checkComponentPresence(el, element[list], 'id')
		let { name, value, type } = event
		console.log(name, value, type)
		if (element.furniture_list[c][name]) {
			element[list][c][name] = false
		} else {
			if (name === 'in_patient_unit') {
				element[list][c].quantity = element.patient_unit
			}
			element[list][c][name] = true
		}
		functions.edit({ name: list, value: editlist })
	}

	const onUpdatePatientQuantity = (el) => {
		const value = el.value
		let editlist_f = [...element.furniture_list]
		let editlist_e = [...element.environment_element_list]
		editlist_e.forEach(e => {
			if (e.in_patient_unit) {
				e.quantity = value
			}
		})
		editlist_f.forEach(e => {
			if (e.in_patient_unit) {
				e.quantity = value
			}
		})
		functions.setItem({ ...element, patient_unit: value, furniture_list: editlist_f, environment_element_list: editlist_e })
	}
	//----good

	const handleShowNewGood = (list) => {
		newgood.good_type = list
		setCurGoodSection(roomcomposition_dict[list])
		setCurGood(newgood)
		setNewEdit(true)
		setShowGoodEdit(true)
	}

	const handleShowEditGood = (i, list) => {
		i.good_type = list
		setCurGoodSection(roomcomposition_dict[list])
		setCurGood(i)
		setNewEdit(false)
		setShowGoodEdit(true)
	}

	const handleCloseGood = () => setShowGoodEdit(false);

	const onChangeHandlerGood = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurGood({ ...curGood, [name]: value })
	}

	const handleSaveGood = async () => {
		console.log(curGoodSection, newEdit, curGood)
		if (curGoodSection.id === 'furniture_list') {
			if (newEdit) {
				let return_data = await addFurniture2(domain, contractId, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...furniture_list]
					newlist.push(return_data)
					setFurnitureList(newlist)
					setShowGoodEdit(false);
				}
			} else {
				let return_data = await editFurniture2(domain, contractId, curGood.id, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...furniture_list]
					const c = checkComponentPresence(curGood, furniture_list, 'id')
					newlist.splice(c, 1, curGood)
					setFurnitureList(newlist)
					setShowGoodEdit(false);
				}
			}


		} else if (curGoodSection.id === 'environment_element_list') {
			if (newEdit) {
				let return_data = await addEnvironmentElement2(domain, contractId, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...environment_element_list]
					newlist.push(return_data)
					setEnvironmentElementList(newlist)
					setShowGoodEdit(false);
				}
			} else {
				let return_data = await editEnvironmentElement2(domain, contractId, curGood.id, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...environment_element_list]
					const c = checkComponentPresence(curGood, environment_element_list, 'id')
					newlist.splice(c, 1, curGood)
					setEnvironmentElementList(newlist)
					setShowGoodEdit(false);
				}
			}

		} else if (curGoodSection.id === 'economic_material_list') {
			if (newEdit) {
				let return_data = await addEconomicalMaterial2(domain, contractId, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...economic_material_list]
					newlist.push(return_data)
					setEconomicMaterialList(newlist)
					setShowGoodEdit(false)
				}
			} else {
				let return_data = await editEconomicalMaterial2(domain, contractId, curGood.id, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...economic_material_list]
					const c = checkComponentPresence(curGood, economic_material_list, 'id')
					newlist.splice(c, 1, curGood)
					setEconomicMaterialList(newlist)
					setShowGoodEdit(false)
				}
			}

		}

	}

	const handleDeleteGood = (el, category_type) => {
		console.log('delete')
		const cb = async () => {
			let return_data
			if (category_type === 'furniture_list') {
				const editlist = [...furniture_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				return_data = await deleteFurniture2(domain, contractId, el.id)
				setFurnitureList(editlist)

			} else if (category_type === 'environment_element_list') {
				return_data = await deleteEnvironmentElement2(domain, contractId, el.id)
				const editlist = [...environment_element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setEnvironmentElementList(editlist)

			} else if (category_type === 'economic_material_list') {
				return_data = await deleteEconomicalMaterial2(domain, contractId, el.id)
				const editlist = [...economic_material_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setEconomicMaterialList(editlist)

			}
			console.log(return_data)
		}
		console.log(el)
		childRefDelete.current.confirmDelete(roomcomposition_dict[category_type].delete_title, roomcomposition_dict[category_type].delete, cb)
	}


	const [ui_list, setUiList] = useState({})
	const initValues = async () => {
		let floor_list = await getBaseObjectTypeList2(domain, 'floors')
		floor_list = floor_list.sort(compareName)
		let newfurniture_list = await getFurnitureList2(domain, contractId)
		let newenvironment_element_list = await getEnvironmentElementList2(domain, contractId)
		let neweconomic_material_list = await getEconomicalMaterialList2(domain, contractId)
		let newelementcm_list = await getProductList(domain, contractId, companyId, 'cleaningmaterial', null)
		let newelementp_list = await getProductList(domain, contractId, companyId, 'product', null)
		setUiList({ ...ui_list, floor_list, cleaningmaterial_list: [...newelementcm_list.filter(p => p.intended_use === 'economic_material').map(p => ({ ...p, section: 'cleaningmaterial' })), ...newelementp_list.filter(p =>p.intended_use &&  p.intended_use.indexOf('economic_material')>=0).map(p => ({ ...p, section: 'product' }))].sort(propComparator('name')) })
		setFurnitureList(newfurniture_list.sort(compareName))
		setEnvironmentElementList(newenvironment_element_list.sort(compareName))
		setEconomicMaterialList(neweconomic_material_list.sort(compareName))
	}


	const [curGoodSection, setCurGoodSection] = useState(roomcomposition_dict['furniture_list'])




	const OneRoomElement = ({ i, k, section, showComponentEdit, canWrite, is_operation }) => {
		//console.log("GOOD LLIST", section, element_list)
		return (

			<Row key={k} className={styles.source_row}>
				<Col>{i.name}</Col>
				{section === 'economic_material' && !is_type ?
					<>
						<Col>
							<Form.Control size="sm" type="text" defaultValue={i.istatus} name="status" onChange={(event) => handleChangeParamEl(i, event.target, section)} />
						</Col>
						<Col>
							<Form.Control size="sm" type="text" defaultValue={i.badge} name="badge" onChange={(event) => handleChangeParamEl(i, event.target, section)} />
						</Col>
					</>
					: ''}
				{section === 'furniture_list' ?
					<>
						<Col sm="2">
							{showComponentEdit ?
								<Form.Check type="checkbox" className="ml-2" name="is_trash" value="pu" checked={i.is_trash} onChange={(event) => handleChangeParamEl(i, event.currentTarget, section)} />
								:
								(i.is_trash ?
									<FontAwesomeIcon className="text-secondary" icon="trash-alt" />
									: ''
								)
							}
						</Col>
						{element.has_patient_unit ?
							<Col sm="2">
								{showComponentEdit ?
									<Form.Check type="checkbox" className="ml-2" name="in_patient_unit" value="pu" checked={i.in_patient_unit} onChange={(event) => handleChangeParamEl(i, event.currentTarget, section)} />
									:
									(i.in_patient_unit ?
										<FontAwesomeIcon className="text-primary" icon="user" />
										: ''
									)
								}
							</Col>
							: ''}
					</>

					: ''}
				{canWrite ?
					<>
						{section === 'economic_material_list' ?
							<Col sm="2" >
								<Row>
									<Col>
										{i.refill_element_list && i.refill_element_list.length > 0 ?
											i.refill_element_list.map((r, rk) => (
												<Row key={rk}><Col>{r.name} </Col></Row>
											))
											:
										<></>
										}
									</Col>
									<Col sm="3" className="p-0 text-center">
										<ModalElementOptions t={t} curGood={i} material_list={ui_list.cleaningmaterial_list} onChangeHandlerEl={(el) => handleChangeElement(el, section)} />
									</Col>
								</Row>
							</Col>
							: ''}
						<Col className="text-center" sm="5" md="4" xl="3" >
							<InputGroup>
								<InputGroup.Prepend>
									< Button size="sm" variant="outline-info" onClick={() => handleSubtractEl(i, section)}><FontAwesomeIcon icon="minus" /> </Button>
								</InputGroup.Prepend>
								<InputGroup.Append>
									<InputGroup.Text>
										{i.quantity}
										{element.has_patient_unit && i.in_patient_unit && i.quantity < element.patient_unit ?
											<FontAwesomeIcon icon="exclamation-triangle" />
											: ''}

									</InputGroup.Text>
								</InputGroup.Append>
								{/* 								<FormControl aria-describedby="basic-addon1" value={i.quantity} />
								{element.has_patient_unit && i.in_patient_unit && i.quantity < element.patient_unit ?
									<InputGroup.Append>
										<InputGroup.Text className="bg-danger text-light" >
											<FontAwesomeIcon icon="exclamation-triangle" />
										</InputGroup.Text>
									</InputGroup.Append>
									: ''} */}
								<InputGroup.Append>
									<Button size="sm" variant="outline-info" onClick={() => handleAddEl(i, section)}><FontAwesomeIcon icon="plus" /> </Button>
								</InputGroup.Append>
								<InputGroup.Append>
									<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteEl(i, section)} /> </Button>
								</InputGroup.Append>
							</InputGroup>
						</Col>
					</>
					:

					<Col sm="5" md="4" xl="3">
						{i.quantity}
						{element.has_patient_unit && i.in_patient_unit && i.quantity < element.patient_unit ?
							<FontAwesomeIcon icon="exclamation-triangle" />
							: ''}
					</Col>
				}

			</Row>
		)

	}



	const OneGoodElement = ({ i, k, list }) => {
		const handleAddElement = (i) => {
			handleAddEl(i, list)
		}
		return (
			<Row key={k} className="border-bottom p-1">
				<Col sm="1" className="p-0 text-center">
					<AddListButton i={i} add_action={handleAddElement} element_list={element[list]} increment={true} />
				</Col>
				<Col sm="3">{i.category}</Col>
				<Col>{i.name}</Col>
				{canWrite ?
					<Col sm="1" className="p-0 text-center">
						<ButtonGroup>
							<Button size="sm" variant="outline-info" onClick={() => handleShowEditGood(i, list)}><FontAwesomeIcon icon="pencil-alt" /></Button>
							<Button size="sm" variant="outline-danger" onClick={() => handleDeleteGood(i, list)}><FontAwesomeIcon icon="trash" /> </Button>
						</ButtonGroup>
					</Col>
					: ''}
			</Row>
		)
	}


	const OneSection = ({ i, k, list }) => {
		let good_list = furniture_list
		if (list === 'environment_element_list') {
			good_list = environment_element_list
		} else if (list === 'economic_material_list') {
			good_list = economic_material_list
		}

		const [searchTerm, setSearchTerm] = useState('')
		/* 		const handleChangeSearch = (section, s) => {
					console.log(section, s)
					if (s && s.value.length > 0) {
						setSearchTerm(s.value)
					} else {
						setSearchTerm('')
					}
				} */
		return (
			<>
				<Card className="mb-4">
					<Card.Header>
						<Row className="h5">
							<Col sm="7" className="text-primary">
								{i.title}
								{element[list] && element[list].length > 0 ?
									<Badge variant="primary">{element[list].length}</Badge>
									: <></>}

							</Col>
							{showComponentEdit ?
								<>
									<Col className="text-info">{i.available}</Col>
									<Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewGood(list)}><FontAwesomeIcon icon="plus-square" /> {i.add}</Button></Col>
								</>
								: ''}
						</Row>

					</Card.Header>
					<Card.Body>
						<Row>
							{element[list] && element[list].length > 0 ?
								<Col >
									<Row >
										<Col className="bg-secondary text-light">{t('structure.labels.component_name')} </Col>
										{list === 'economic_material_list' && !is_type ?
											<>
												<Col className="bg-secondary text-light ml-1">{t('globals.labels.status')} </Col>
												<Col className="bg-secondary text-light ml-1">{t('globals.labels.badge')} </Col>
											</>
											: ''}

										{list === 'furniture_list' ?
											<>
												<Col className="bg-secondary text-light ml-1" sm="2" md="2" xl="2" >{t('structure.labels.trash')}
												</Col>
												{element.has_patient_unit ?
													<Col className="bg-secondary text-light ml-1" sm="2" md="2" xl="2" >{t('structure.labels.patient_unit')} </Col>
													: ''}
											</>
											: ''}
										{list === 'economic_material_list' ?
											<Col className="bg-secondary text-light ml-1 text-center" sm="2">{t('globals.labels.refill')} </Col>
											: ''}

										<Col className="bg-secondary text-light ml-1 text-center" sm="4" md="3" xl="3" >{t('structure.labels.component_quantity')} </Col>
									</Row>
									<Row className="source_list_container">
										<Col>
											{element[list].map((i, key) => (

												<OneRoomElement i={i} is_operation={is_operation} key={key} k={key} section={list} showComponentEdit={showComponentEdit} element_list={element[list]} canWrite={canWrite} />

											))}
										</Col>
									</Row>

								</Col>
								: <Col className="font-italic"> {i.empty}</Col>
							}
							{showComponentEdit
								?
								<>
									<Col className="border-left editSection">
										{good_list && good_list.length > 0 ?
											<>
												<Row >
													<Col className="bg-info text-light ml-1" sm="1">&nbsp;</Col>
													<Col className="bg-info text-light ml-1" sm="3">{t('structure.labels.component_category')} </Col>
													<Col className="bg-info text-light ml-1">{t('structure.labels.component_name')} </Col>
													<Col className="bg-info text-light ml-1 text-center" sm="2">{t('global.labels.actions')} </Col>
												</Row>
												<Row className="source_list_container">
													<Col>
														{good_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm[list].toLowerCase()) >= 0))).map((i, key) => (
															<OneGoodElement i={i} key={k} k={k} list={list} canWrite={canWrite} is_operation={is_operation} />
														))}
													</Col>
												</Row>
											</>
											: <i> {i.empty}</i>
										}
									</Col>
								</>
								: ''
							}
						</Row>

					</Card.Body>
				</Card>
			</>
		)

	}




	useEffect(() => {
		initValues()
	}, [])

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
			{contract.type === 'hospital' ?
				<Form.Group as={Row} controlId="roomtypeName" className="border-bottom">
					{edit && edit.editMultiple ?
						<MultipleToggleEdit param={'glass_percentage'} />
						: ''}
					<Form.Label column sm="2">{t('structure.labels.patient_unit')}</Form.Label>
					<Col>
						<Form.Check type="radio" name="has_patient_unit" value={true} checked={element.has_patient_unit} disabled={edit && edit.editMultiple && edit.editParamList.indexOf('has_patient_unit') === -1} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
						<Form.Check type="radio" name="has_patient_unit" value={false} checked={!element.has_patient_unit} disabled={edit && edit.editMultiple && edit.editParamList.indexOf('has_patient_unit') === -1} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					<Col sm="8">
						<Form.Control type="number" min="0" disabled={!element.has_patient_unit || (edit && edit.editMultiple && edit.editParamList.indexOf('has_patient_unit') === -1)} name="patient_unit" value={element.patient_unit} onChange={(event) => onUpdatePatientQuantity(event.currentTarget)} className="mb-2" />
						<Form.Text className="text-muted">
							{t('global.labels.number')}
						</Form.Text>
					</Col>
				</Form.Group>
				: ''}
			{canWrite ?
				<Row className="mb-1">
					<Col className="text-right">
						{showComponentEdit ?
							<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComposition()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
							:
							<Button variant="outline-primary" size="sm" onClick={() => handleOpenComposition()}><FontAwesomeIcon icon="pencil-alt" /> {t('structure.labels.component_mod')}</Button>
						}
					</Col>
				</Row>
				: ''}

			{Object.keys(roomcomposition_dict).map((section, k) => (
				<OneSection i={roomcomposition_dict[section]} list={section} key={k} />
			))}






			<Modal show={showGoodEdit} onHide={handleCloseGood} >
				<Modal.Header closeButton>
					{newEdit && <Modal.Title>{curGoodSection.new}</Modal.Title>}
					{!newEdit && <Modal.Title>{curGoodSection.edit}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curGood.name} type="text" name="name" onChange={(event) => onChangeHandlerGood(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.category')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curGood.category} type="text" name="category" onChange={(event) => onChangeHandlerGood(event.target)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseGood}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="companyForm" onClick={handleSaveGood}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>


		</>
	)
}



const OneElement = ({ i, handleDeleteMaterial }) => {
	return (
		<Row className="border-bottom row_element">
			<Col>
				{i.name}
			</Col>
			<Col sm="4" md="3" xl="3">
				{i.unit_measure}
			</Col>
			<Col sm="1" className="p-0 text-center">
				<Button size="sm" variant="outline-danger" onClick={() => handleDeleteMaterial(i)}><FontAwesomeIcon icon="trash" /> </Button>
			</Col>

		</Row>
	)
}


const OneMaterialElement = ({ i, element_list, handleAddMaterial }) => {
	console.log(i)
	return (
		<Row>
			<Col sm="1" className="p-0 text-center">
				<AddListButton i={i} add_action={handleAddMaterial} element_list={element_list} />
			</Col>
			<Col>
				{i.name}
			</Col>
			<Col>
				{i.section === 'product' ? 'prodotto' : 'materiale'}
			</Col>

		</Row>
	)
}

////--------------------------------------------------------------///
const ModalElementOptions = ({ t, curGood, onChangeHandlerEl, material_list }) => {
	const [showModal, setShowModal] = useState(false)
	const [_curGood, _setCurGood] = useState(curGood)

	const [showComponentEdit, setShowComponentEdit] = useState(false)


	const handleCloseModal = () => {
		setShowModal(false)
	}
	const handleShowModal = () => {
		_setCurGood(curGood)
		setShowModal(true)

	}

	const handleOpenComposition = (s) => {
		setShowComponentEdit(s)
	}

	const handleAddMaterial = (el) => {
		let editlist = _curGood.refill_element_list ? [..._curGood.refill_element_list] : []
		const c = checkComponentPresence(el, _curGood.refill_element_list, 'id')
		if (c < 0) {
			editlist.push({ name: el.name, id: el.id, max_refill_quanty: -1, unit_measure: el.section === 'product' ? 'ml' : 'pz' })
		}
		_setCurGood({ ..._curGood, refill_element_list: editlist })

	}
	const handleDeleteMaterial = (el) => {
		let editlist = [..._curGood.refill_element_list]
		const c = checkComponentPresence(el, _curGood.refill_element_list, 'id')
		editlist.splice(c, 1)
		_setCurGood({ ..._curGood, refill_element_list: editlist })
	}

	const handleSave = () => {
		onChangeHandlerEl(_curGood)

	}

	return (
		<>
			<Button size="sm" variant="outline-info" onClick={() => handleShowModal()}><FontAwesomeIcon icon="pencil-alt" /></Button>
			<Modal show={showModal} onHide={handleCloseModal} className="bg-secondary" size="xl" >
				<Modal.Header closeButton>
					<b>{_curGood.name}</b>: Materiale per rifornimenti
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Row>

							<Col className="text-right">
								{showComponentEdit ?
									<Button variant="outline-secondary" size="sm" onClick={() => handleOpenComposition(false)}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
									:
									<Button variant="outline-primary" size="sm" onClick={() => handleOpenComposition(true)}><FontAwesomeIcon icon="pencil-alt" /> {t('structure.labels.component_mod')}</Button>
								}
							</Col>
						</Row>
						<Row>
							{_curGood.refill_element_list && _curGood.refill_element_list.length > 0 ?
								<Col >
									<Row >
										<Col className="bg-secondary text-light">{t('structure.labels.component_name')} </Col>
										{/* 										<Col className="bg-secondary text-light ml-1 text-center" sm="4" md="3" xl="3" >{t('global.labels.max_quantity')} </Col>
										*/}
										<Col className="bg-secondary text-light ml-1 text-center" sm="4" md="3" xl="3" > {t('global.labels.measure_unity')} </Col>
										<Col className="bg-secondary text-light ml-1" sm="1">{t('global.labels.actions')}</Col>
									</Row>
									<Row className="source_list_container">
										<Col>
											{_curGood.refill_element_list.map((i, key) => (
												<OneElement i={i} key={key} k={key} showComponentEdit={showComponentEdit} handleDeleteMaterial={handleDeleteMaterial} />
											))}
										</Col>
									</Row>

								</Col>
								: <Col className="font-italic"> Nessun prodotto per il refill inserito</Col>
							}
							{showComponentEdit
								?
								<>
									<Col className="border-left editSection">
										{material_list && material_list.length > 0 ?
											<>
												<Row >
													<Col className="bg-info text-light ml-1" sm="1">&nbsp;</Col>
													<Col className="bg-info text-light ml-1">{t('structure.labels.component_name')} </Col>
												</Row>
												<Row className="source_list_container">
													<Col>
														{material_list.map((i, k) => (
															<OneMaterialElement i={i} key={k} k={k} element_list={_curGood.refill_element_list || []} handleAddMaterial={handleAddMaterial} />
														))}
													</Col>
												</Row>
											</>
											: <i>Nessun materiale disponibile</i>
										}
									</Col>
								</>
								: ''
							}
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => handleCloseModal()}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" onClick={() => handleSave()}>
						<FontAwesomeIcon icon="save" />
						{t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}