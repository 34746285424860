import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab, InputGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { BlockPicker } from 'react-color';
import reactCSS from 'reactcss'
//import "@nateradebaugh/react-datetime/scss/styles.scss";

import { materialIntendedUse_dict } from "../components/constants/global_definitions"

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"

import { getSelectedElement, checkComponentPresence, getValue } from "../util/ui_utils.js"
import { ModalInitButton } from '../components/StructureGraphicElements.js'



import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { addProduct as addProduct2, editProduct as editProduct2, } from '../Firebase2/Storage2/dbWarehouse';
import { addCatalogProduct, editCatalogProduct} from '../Firebase2/Storage2/dbCatalogWarehouse';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';


const companyId = '0'
//const contractId = sessionStorage.getItem('contractId')

const section = 'cleaningmaterial'

function ModalCleaningmaterial({ item, setItem, t, is_new, small, item_list, index, canWrite, ui_list, setUiList, subsection }) {
	const { domain  } = useContext(DomainContext)

	const childRefAlert = useRef()

	let { cid } = useParams();
	let contractId = cid

	const newcleaningmaterial = {
		code: '',
		brand: {},
		model: '',
		note: '',
		microchip_id: '',
		size_w: 0,
		size_h: 0,
		yield_size: 0,
		life_quantity: 0,
		life_measure: 'washes',
		net_weight: 0,
		category: {},
		minstock: 0,
		yield: 18,
		media_list: [],
		intended_use: subsection === 'economic_material'?' economic_material':'dusting_furniture',
		color: { r: 255, g: 255, b: 255, a: 1 }
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newcleaningmaterial)
	const [editedElement, setEditedElement] = useState(false)

	const initValues = async () => {
		//const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		//const category_list = await getBaseObjectTypeList2(domain, 'cleaningmaterial_category')
		//setUiList({ ...ui_list, brand_list, category_list })
		/* 		let newelement_list = await getCleaningmaterialList(contractId, companyId, 'cleaningmaterial', null)
		newelement_list.sort(compareName)
		setElementList(newelement_list) */
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const handleShowNew = () => {
		newcleaningmaterial.media_list = []
		setCurrentElement(newcleaningmaterial)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		let return_data
		if (is_new) {
			if (domain ==='catalog'){
				return_data =  await addCatalogProduct(contractId, section, curElement)
			}else{
				return_data =  await addProduct2(domain, contractId, companyId, section, curElement)
			}
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			if (domain ==='catalog'){
				return_data =  await editCatalogProduct(contractId, section, curElement.id, curElement)
			}else{
				return_data =  await editProduct2(domain, contractId, companyId, section, curElement.id, curElement)
			}
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={subsection==='economic_material'?t('cleaningmaterial.buttons.economicmaterial_add'):t('cleaningmaterial.buttons.cleaningmaterial_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{subsection==='economic_material'?t('cleaningmaterials.labels.new_economicmaterial'):t('cleaningmaterials.labels.new_cleaningmaterial')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{subsection==='economic_material'?t('cleaningmaterials.labels.edit_economicmaterial'):t('cleaningmaterials.labels.edit_cleaningmaterial')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{subsection==='economic_material'?t('cleaningmaterials.labels.view_economicmaterial'):t('cleaningmaterials.labels.view_cleaningmaterial')}</span>
								</>
							]
						}
                        : {curElement.name}&nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav variant="pills" className="flex-column">
											<Nav.Item className="mb-1">
												<Nav.Link variant="secondary" eventKey="tabDetails">{t('global.labels.details')} </Nav.Link>
											</Nav.Item>
											<Nav.Item className="mb-1">
												<Nav.Link variant="secondary" eventKey="tabSpecifications">{t('global.labels.specifications')} </Nav.Link>
											</Nav.Item>
											<Nav.Item className="mb-1">
												<Nav.Link variant="secondary" eventKey="tabMedia">{t('global.labels.imagesdocs')} </Nav.Link>
											</Nav.Item>
										</Nav>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData, setEditedElement: setEditedElement, setItem: setCurrentElement }} canWrite={canWrite} subsection={subsection}/>
										</Tab.Pane>
										<Tab.Pane eventKey="tabSpecifications">
											<ElementFormSpecifications element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler }} section={section} canWrite={canWrite} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element, functions, ui_list, t, canWrite, subsection }) {
	const { locale } = useContext(LocaleContext)
	const [displayColorPicker, setShowColorPicker] = useState(false)
	const [color, setCurColor] = useState('#aaaaaa')

	let intended_use_dict = materialIntendedUse_dict({ t , subsection})
	const defaultcolors = [
		'#aaaaaa', '#3366ff', '#003300', '#990033', '#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#607d8b", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800",
	]

	const styles = reactCSS({
		'default': {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: element.color
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		},
	});

	const onChangeIntendedUse = (el) => {
		const use = intended_use_dict[el.value]
		console.log(el.value, use, intended_use_dict)
		if (use) {
			//FIXME correggere hextorgb
			functions.setItem({ ...element, intended_use: el.value, color: use.color })
		} else {
			functions.setItem({ ...element, intended_use: el.value })
		}
		functions.setEditedElement(true)
	}

	const updateBrands = (b) => {
		functions.updateData('brand_list', b)
	}

	const updateCategory = (b) => {
		functions.updateData('category_list', b)
	}

	const onChangeHandlerColor = (el) => {
		functions.edit({ name: 'color', value: el.hex })
	}

	const handleChangeColor = (c) => {
		console.log('set color', c)
		setCurColor(c.hex)
	}
	const handleShowColorPicker = () => {
		setShowColorPicker(!displayColorPicker)
	}
	const handleCloseColorPicker = () => {
		setShowColorPicker(false)
	}
	return (
		<>
			<Form.Group as={Row} controlId="cleaningmaterialName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="cleaningmaterialCode" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formCleaningmaterialBrand" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.brand')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.brand.Id} name="brand" className="mb-2" onChange={(event) => functions.edit({ name: 'brand', type: 'select', value: getSelectedElement(event.target.value, ui_list.brand_list) })}  >
						<option value="-1">{t('global.labels.select_brand')}</option>
						{console.log(ui_list.brand_list)}
						{ui_list.brand_list
							? [Object(ui_list.brand_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name? f.name[locale]: '---'}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'brands', list: ui_list.brand_list }} t={t} functions={{ setCurSection: updateBrands }} />
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formCleaningmaterialBrand" className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.intended_use')}</Form.Label>
				<Col sm="8">
					{subsection && subsection === 'economic_material'?
						<>{t('products.labels.economic_material')}</>
					:
					<Form.Control as="select" value={element.intended_use} name="intended_use" className="mb-2" onChange={(event) => onChangeIntendedUse({ name: 'intended_use', type: 'select', value: event.target.value })}  >
						{Object.keys(intended_use_dict).map((f, k) => (
							<option key={k} value={f} >{intended_use_dict[f].label}</option>
						))}
					</Form.Control>
					}
				</Col>
			</Form.Group>
			<Row className="border-bottom pb-2 mb-2">
				<Col sm="2">{t('objecttype.labels.color')}</Col>
				<Col>
					<Button variant="outline-info" onClick={handleShowColorPicker}>
						<div style={styles.color} />
					</Button>
					{displayColorPicker ? <div style={styles.popover}>
						<div style={styles.cover} onClick={handleCloseColorPicker} />
						<BlockPicker color={color} colors={defaultcolors} triangle="hide" onChange={handleChangeColor} onChangeComplete={onChangeHandlerColor} />
					</div> : null}
				</Col>
			</Row>
			<Form.Group as={Row} controlId="formCleaningmaterialCategory" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.category')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.category.Id} name="category" className="mb-2" onChange={(event) => functions.edit({ name: 'category', type: 'select', value: getSelectedElement(event.target.value, ui_list.category_list) })}  >
						<option value="-1">{t('cleaningmaterials.labels.select_category')}</option>
						{ui_list.category_list
							? [Object(ui_list.category_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'cleaningmaterial_category', list: ui_list.category_list }} t={t} functions={{ setCurSection: updateCategory }} />
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="cleaningmaterialStock" className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.minstock')}</Form.Label>
				<Col sm="8">
					<Form.Control type="number" name="minstock" value={element.minstock} min="0" className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="cleaningmaterialNote" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormSpecifications({ element, functions, ui_list, t, canWrite }) {
	const life_measure_list = [
		{ id: 'mat1', name: t('material.labels.washes') },
		{ id: 'mat2', name: t('material.labels.day') },
		{ id: 'mat3', name: t('material.labels.month') },
		{ id: 'mat4', name: t('material.labels.year') }
	]

	return (
		<>
			<Form.Group as={Row} controlId="formEquipmentColor" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.size')}</Form.Label>
				<Col>
					<InputGroup>
						<Form.Control type="number" name="size_w" min="0" aria-label="size_w" defaultValue={element.size_w} onChange={(event) => functions.edit(event.currentTarget)} />
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon1">cm</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
					<Form.Text className="text-muted">
						{t('equipments.labels.size_w')}
					</Form.Text>
				</Col>
				<Col>
					<InputGroup>
						<Form.Control type="number" name="size_h" min="0" defaultValue={element.size_h} onChange={(event) => functions.edit(event.currentTarget)} />
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon1">cm</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
					<Form.Text className="text-muted" >
						{t('equipments.labels.size_h')}
					</Form.Text>
				</Col>
				<Col>
					<InputGroup>
						<Form.Control type="number" name="net_weight" min="0" step="0.1" defaultValue={element.net_weight} onChange={(event) => functions.edit(event.currentTarget)} />
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
					<Form.Text className="text-muted">
						{t('equipments.labels.weight')}
					</Form.Text>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="cleaningmaterialName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.yield')}</Form.Label>
				<Col sm="8">
					<InputGroup className="mb-1">
						<Form.Control type="number" min="1" name="yield" value={element.yield} onChange={(event) => functions.edit(event.currentTarget)} />
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon1">mq</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEquipmentColor" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.life')}</Form.Label>
				<Col>
					<Form.Control type="number" name="life_quantity" min="0" aria-label="life_quantity" defaultValue={element.life_quantity} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Text className="text-muted">
						{t('equipments.labels.number')}
					</Form.Text>
				</Col>
				<Col>
					<Form.Control as="select" value={getValue(element.life_measure, 'id')} name="life_measure" className="mb-2" onChange={(event) => functions.edit({ name: 'life_measure', type: 'select', value: getSelectedElement(event.target.value, life_measure_list, 'id') })}  >
						<option value="-1">{t('cleaningmaterial.labels.select_composition')}</option>
						{life_measure_list
							? [Object(life_measure_list).map((f, k) => (
								<option key={k} value={f.id} >{f.name}</option>
							))]
							: ''
						}
					</Form.Control>
					<Form.Text className="text-muted" >
						{t('equipments.labels.measure')}
					</Form.Text>
				</Col>
			</Form.Group>
		</>
	)
}




export default translate()(ModalCleaningmaterial)