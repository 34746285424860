import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { FirebaseContext } from './components/Firebase';
import AuthProvider from './provider/AuthProvider'
import { BrowserRouter} from "react-router-dom";
import {GlobalDataProvider} from "./components/contexts/globaldataContext"

ReactDOM.render(
  <BrowserRouter >
    <AuthProvider>
        <App />
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
/*ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <App />
    </FirebaseContext.Provider>,
    document.getElementById('root')
);*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about workarea workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
