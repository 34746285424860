import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Spinner, ButtonGroup, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { format, getDate } from 'date-fns'

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalCustomerSatisfaction from "../components/ModalCustomerSatisfaction"
import ModalCustomerSatisfactionResult from "../components/ModalCustomerSatisfactionResult"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"

import { InitContractHelper } from '../util/contract.js';
import { checkComponentPresence, compareName, getElementPreview, getBaseObjectValue, getElementCount, myTimestampToDate } from "../util/ui_utils.js"
import { RenderItemFileBadge } from '../components/StructureGraphicElements.js'


import { getCustomerSatisfactionList as getCustomerSatisfactionList2, deleteCustomerSatisfaction as deleteCustomerSatisfaction2 } from '../Firebase2/Storage2/dbCustomerSatisfaction';
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"



//const contractId = sessionStorage.getItem('contractId')
const companyId = '0'
const section = 'customersatisfaction'
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const CustomerSatisfactionsView = ({ t, uData }) => {
let load_page = false

	const { locale } = useContext(LocaleContext)
	const { domain } = useContext(DomainContext)
	const { contract, structureElements } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()
	let { cid } = useParams();
	let contractId = cid

	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		console.log(uData)
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('customersatisfaction') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		console.log(domain, contractId, companyId)
		if (contract && domain.length > 0) {

			const brand_list = await getBaseObjectTypeList2(domain, 'brands')
			const category_list = await getBaseObjectTypeList2(domain, 'customersatisfaction_category')
			setUiList({ ...ui_list, brand_list, category_list })
			let newelement_list = await getCustomerSatisfactionList2(domain, contractId)
			newelement_list.sort(compareName)
			setElementList(newelement_list)
			setLoading(false)
			load_page = true
		} else {
			console.log("init contract return")

		}
	}


	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("iCUSTOMERSATISFACTION reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("CUSTOMERSATISFACTION init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])




	useEffect(() => {
		console.log('customersatisfaction loadingcontract', load_page)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteCustomerSatisfaction2(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('customersatisfaction.messages.delete_customersatisfaction_title'), t('customersatisfaction.messages.delete_customersatisfaction', { name: el.name }), cb)
	}







	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('navs.pages.customersatisfaction')} />
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite ?
							<ModalCustomerSatisfaction is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
							: ''}
					</Col>
				</Row>
				{!loading && element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="2">Codice</Col>
						<Col className="bg-secondary ml-1">Sondaggio</Col>
						<Col className="bg-secondary ml-1">Inizio</Col>
						<Col className="bg-secondary ml-1">Fine</Col>
						<Col className="bg-secondary ml-1">Stato</Col>
						<Col className="bg-secondary ml-1">Lista strutture</Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">Azione</Col>
					</Row>
					: ''}
			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row key={key} className="element_row p-1 border-bottom " >
										<Col sm="2">{i.code}</Col>
										<Col><b>{i.name}</b> <Badge variant="secondary">{i.question_list.length} quesiti</Badge></Col>
										<Col>
											{format(myTimestampToDate(i.startDate), 'dd/MM/yyyy')}
										</Col>
										<Col>
											{format(myTimestampToDate(i.endDate), 'dd/MM/yyyy')}
										</Col>
										<Col>
											<CampaignStatus i={i} t={t} />
										</Col>
										<Col>
											{i.structure_list && i.structure_list.length > 0 ?
												[i.structure_list.map(s_id => (
													<Badge variant="info" key={s_id}>{structureElements.all_structure_dict[s_id]?structureElements.all_structure_dict[s_id].name:"---"}</Badge>
												))]
												: ''
											}
										</Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												<ModalCustomerSatisfaction is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
												<ModalCustomerSatisfactionResult t={t} item={i} />
												{canWrite ?
													<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							: <>
								<Row>
									<Col className="font-italic">
										{t('customersatisfactions.messages.no_customersatisfaction')}
									</Col>
								</Row>
							</>
						}
					</>
				}



			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('global.labels.customersatisfaction_list') })} />
		</>
	);
}


export const CampaignStatus = ({i, t}) => {
		const now = new Date()
		if (myTimestampToDate(i.startDate) < now && myTimestampToDate(i.endDate) > now) {
			return (
				<>
					<FontAwesomeIcon icon="play-circle" className="text-success" />&nbsp;
					{t('customersatisfaction.labels.is_running')}
				</>
			)
		} else if (myTimestampToDate(i.endDate) < now) {
			return (
				<>
					<FontAwesomeIcon icon="square" className="text-danger" />&nbsp;
					{t('customersatisfaction.labels.is_ended')}
				</>
			)
		} else if (myTimestampToDate(i.startDate) > now) {
			return (
				<>
					<FontAwesomeIcon icon="pause-circle" className="text-secondary" />&nbsp;
					{t('customersatisfaction.labels.not_started')}
				</>
			)
		}
	}


export default translate()(CustomerSatisfactionsView)



