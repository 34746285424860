import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Container, Row, Col, Button, ButtonGroup, Spinner, Card, Accordion, Modal, Form } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useTranslate } from 'react-polyglot';
import { useParams } from "react-router-dom"

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"


import { InitContractHelper } from '../util/contract.js';
import { getSelectedElement, checkComponentPresence, compareName, getStructureFromWorkareas, myTimestampToDate } from "../util/ui_utils.js"
import { StructureGroupIcon } from '../components/StructureGraphicElements.js'



import { getFileTrackerList as getFileTrackerList2 } from "../Firebase2/Storage2/dbFileStorage"
import {
    getPavillonList as getPavillonList2,
    getFloorList as getFloorList2,
    getAllDepartmentInStructure as getAllDepartmentInStructure2,
} from '../Firebase2/Storage2/dbBaseCompany';

import { getAllRoomList } from '../Firebase2/Storage2/dbRoom.js';
import { ModalDocument } from '../components/modal/ModalDocumentPreview';
import { format } from 'date-fns';


//const contractId = sessionStorage.getItem('contractId')

let userData = {}
try {
    userData = JSON.parse(localStorage.getItem('userData'))
}
catch (err) {
    console.log('#########-----error userData', localStorage.getItem('userData'))
}

let type_dict = {
    'file': { value: 'file', label: 'global.labels.document', icon: "file" },
    'image': { value: 'image', label: 'global.labels.image', icon: "image" },
    'map': { value: 'map', label: 'global.labels.structure_map', icon: "map" }
}

const getElementCount = ({ t, document_count }) => {
    return (<>{document_count} {t('global.labels.documents')}</>)
}
const getStructureName = (_structure_list, s_id) => {
    if (s_id !== 0 && _structure_list.filter(s => s.id === s_id)[0] !== undefined) {
        return _structure_list.filter(s => s.id === s_id)[0].name

    } else {
        return 'struttura'
    }
    return
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
////////////////////////////////////////////////////////////////////////////////////////////////////////
const DocumentsView = ({ t, uData }) => {

    const { domain } = useContext(DomainContext)
    const { contract, structureElements, roomtype_list, roomtype_dict, all_workarea_dict } = useContext(GlobalDataContext)

    let { cid } = useParams();
    let contractId = contract ? contract.id : undefined
    const childRefAlert = useRef()
    const { initContract } = InitContractHelper()
    const [loading, setLoading] = useState(true)
    const [docList, setDocList] = useState([])

    const [structure_list, setStructureList] = useState([])
    const [element_list, setElementList] = useState({})
    const [viewMode, setViewMode] = useState('groupElement')
    //    const [department_document_list, setDepartmentDocumentList] = []
    const [ui_list, setUiList] = useState({})
    const [filterData, setFilterData] = useState({ structure: [], floor: [], department: [] })
    const [filterAppliedList, setFilterAppliedList] = useState({ structure: [], floor: [], department: [] })
    const [doc_count, setDocCount] = useState(0)

    /*     const onSelectStructure = (s) => {
            setCurStructure(s)
        }
    
        useEffect(() => {
            if (curStructure) {
    
                initData()
            }
        }, [curStructure])
    
    
        const initData = () => {
    
        } */


    const checkMediaList = (media_list, doc_id) => {
        for (let a = 0; a < media_list.length; a++) {
            if (media_list[a].tracker && media_list[a].tracker.id === doc_id) {
                return media_list[a]
            }
        }
        return false
    }



    const initValues = async () => {

        //        let userData = uData
        let document_count = 0
        console.log(contractId, domain)
        if (contractId && domain.length > 0) {

            const list = await getFileTrackerList2(domain, contractId, ['structure', 'room'])
            //        const new_list = createSectionIdList(list)
            let temp_element_list = {}
            let cur_section = -1
            let _structure_list = structureElements.structure_list
            let permission_department_list = []
            let permission_structure_list = []
            if (userData && userData.contract_dict && userData.contract_dict[contractId]) {
                console.log('userData contract', userData.contract_dict[contractId])
                if (userData.contract_dict[contractId][0].mode === 'workarea') {
                    const permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
                    permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
                    console.log("permission_structure_list", permission_structure_list)
                    _structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
                } else {
                    if (userData.contract_dict[contractId][0].structure) {
                        if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
                            permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
                            console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
                        }
                        if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
                            permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
                            _structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

                        }
                        console.log("permission structure_list", permission_structure_list)
                    }
                }
            }
            console.log("ST LIST", structure_list)
            setStructureList(_structure_list)
            let tdepartment_list = []
            let troom_list = []
            for (let s of _structure_list) {
                const _dep_list = Object.values(structureElements.all_department_dict).filter(d => d.parent_list[0] === s.id)
                //let tlist = await getAllDepartmentInStructure2(s.id)
                console.log("department in structure", s.id, _dep_list?.map(d => ({id: d.id, name: d.name})))
                if (_dep_list && _dep_list.length > 0) {
                    tdepartment_list = [...tdepartment_list, ..._dep_list]
                }
                let rlist = await getAllRoomList({ structureId: s.id })
                if (rlist && rlist.length > 0) {
                    troom_list = [...troom_list, ...rlist]
                }

            }


            const elementdoc_list = list.filter(f => f.item_reference && f.section === 'structure')
            console.log(elementdoc_list)
            ///DEPARTMENT DOC
            const section_list = await elementdoc_list.reduce(async (promisedList, doc) => {
                let section_list = [];
                section_list = await promisedList
                let elem;
                if (doc.item_reference && !temp_element_list[doc.item_reference.id]) {
                    elem = getSelectedElement(doc.item_reference.id, tdepartment_list, 'id')
                }
                console.log("ELEMENT FROM doc (department?)", elem, checkComponentPermission(elem.parent_list, permission_department_list))
                if (elem && elem.id && checkComponentPermission(elem.parent_list, permission_department_list)) {
                    if (!section_list[elem.id]) {
                        cur_section = doc.section
                        section_list[elem.id] = { ...elem, doc_list: [doc], doc_dict: { [doc.id]: doc }, element_list: {}, not_image_count: 0, label: elem.name, room_list: {} }
                    } else {
                        section_list[elem.id].doc_list.push(doc)
                        section_list[elem.id].doc_dict[doc.id] = doc
                    }
                    if (elem.media_list && checkMediaList(elem.media_list, doc.id)) {
                        section_list[elem.id].element_list[elem.id] = elem
                        section_list[elem.id].not_image_count += 1
                        document_count += 1
                    }
                } else {
                    let elem_def = { name: 'Altri elementi di struttura', id: 0, media_list: [] }
                    elem = { name: 'Altri elementi', id: 0, media_list: [] }
                    if (doc.item_reference.category === 1) {
                        elem = structureElements.all_pavillon_dict[doc.item_reference.id]
                    } if (doc.item_reference.category === 2) {
                        elem = structureElements.all_floor_dict[doc.item_reference.id]
                    }
                    const s_id = elem.parent_list ? elem.parent_list[0] : 0
                    console.log("s_id", s_id, doc)
                    if (s_id !== 0 || checkStructurePermission(doc, _structure_list.map(s => s.id))) {

                        const structure_name = getStructureName(_structure_list, s_id)
                        if (!section_list[s_id]) {
                            cur_section = doc.section
                            section_list[s_id] = { name: `Altri documenti ${structure_name}`, doc_list: [doc], doc_dict: { [doc.id]: doc }, element_list: { 0: elem_def }, not_image_count: 0, label: elem.name, room_list: {} }
                        } else {
                            section_list[s_id].doc_list.push(doc)
                            section_list[s_id].doc_dict[doc.id] = doc
                        }

                        if (s_id === 0) {
                            section_list[s_id].element_list[elem.id].media_list.push(doc)
                            section_list[s_id].not_image_count += 1
                            document_count += 1
                        } else if (elem.media_list && checkMediaList(elem.media_list, doc.id)) {
                            section_list[s_id].element_list[elem.id] = elem
                            section_list[s_id].not_image_count += 1
                            document_count += 1
                        }
                    }

                }
                return section_list
            }, {})

            const roomdoc_list = list.filter(f => f.item_reference && f.section === 'room')
            /// ROOM DOC
            const roomsection_list = await roomdoc_list.reduce(async (promisedList, doc) => {
                //console.log(doc)
                //            console.log(doc.item_reference)
                let rsection_list = [];
                rsection_list = await promisedList
                let elem;
                if (doc.item_reference && !temp_element_list[doc.item_reference.id]) {
                    //                    elem = getRoomById(doc.item_reference.id, tdepartment_list, 'id')
                    elem = getSelectedElement(doc.item_reference.id, troom_list, 'id')
                }
                if (elem && elem.id && checkComponentPermission(elem.parent_list, permission_department_list)) {

                    if (elem.media_list && checkMediaList(elem.media_list, doc.id)) {
                        if (!section_list[elem.parent_list[3]]) {
                            cur_section = doc.section
                            //DEPARMENT NOT PRESENT (OR WITHOUT DOCUMENT)
                            const dep = structureElements.all_department_dict[elem.parent_list[3]]
                            console.log("NEW", elem.parent_list[3], elem.id)
                            section_list[elem.parent_list[3]] = {
                                ...dep, doc_list: [], doc_dict: {}, element_list: {}, not_image_count: 0, label: dep.name, room_list: {
                                    [elem.id]: { ...elem, doc_list: [doc], doc_dict: { [doc.id]: doc }, element_list: {}, not_image_count: 0, label: elem.name, room_list: {} }
                                }
                            }
                        } else {
                            if (section_list[elem.parent_list[3]].room_list[elem.id]) {
                                section_list[elem.parent_list[3]].room_list[elem.id].doc_list.push(doc)
                                section_list[elem.parent_list[3]].room_list[elem.id].doc_dict[doc.id] = doc
                            } else {
                                section_list[elem.parent_list[3]].room_list[elem.id] = { ...elem, doc_list: [doc], doc_dict: { [doc.id]: doc }, element_list: {}, not_image_count: 0, label: elem.name, room_list: {} }
                            }
                        }
                        section_list[elem.parent_list[3]].room_list[elem.id].element_list[elem.id] = elem
                        //                        const elem_media = checkMediaList(elem.media_list, doc.id)
                        section_list[elem.parent_list[3]].not_image_count += 1
                        section_list[elem.parent_list[3]].room_list[elem.id].not_image_count += 1
                        document_count += 1
                    }
                }
                return rsection_list
            }, {})

            setUiList({ ...ui_list, structure_list, roomtype_dict, roomtype_list })
            setDocCount(document_count)
            console.log(section_list, document_count)
            setDocList(section_list)
            setLoading(false)
        } else {
            let contractId = sessionStorage.getItem('contractId')
            console.log("\tneed contract", contractId)
            if (contractId && contractId !== null) {
                const ret_data = await initContract(contractId)
                console.log(ret_data)
                //initValues()
            }

        }
    }



    useEffect(() => {
        initValues()
    }, [])

    useEffect(() => {
        initValues()

    }, [contract])


    function checkFilter(dd) {
        const d = docList[dd]
        let show = false
        if (filterAppliedList.structure.length === 0) {
            show = true
        } else {
            if (d.parent_list && filterAppliedList.structure.indexOf(d.parent_list[0]) !== -1) {
                if (filterAppliedList.pavillon.length === 0) {
                    show = true
                } else {
                    if (filterAppliedList.pavillon.indexOf(d.parent_list[1]) !== -1) {
                        if (filterAppliedList.floor.length === 0) {
                            show = true
                        } else {
                            if (filterAppliedList.floor.indexOf(d.parent_list[1]) !== -1) {
                                show = true
                            }

                        }
                    }
                }
            }
        }
        return show
    }



    return (
        <>
            <AlertSection ref={childRefAlert} />
            <Container className="titleStickyTop" fluid>
                <Row className="mt-1">
                    <Col sm="6">
                        <PageTitle title={t('global.pages.structuredocument_management')} />
                    </Col>
                    {doc_count > 0 ?
                        <Col className="text-center">
                            <ModalFilter t={t} structure_list={structure_list} functions={{ edit: '' }} filterAppliedList={filterAppliedList} filterData={filterData} setFilterAppliedList={setFilterAppliedList} setFilterData={setFilterData} />
                        </Col>
                        : ''}
                </Row>
            </Container>
            <Container fluid>


                {loading ?
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    :
                    <>
                        {doc_count > 0 ?
                            <Row>
                                <Accordion as={Col}>
                                    {Object.keys(docList).filter(d => checkFilter(d)).map((section, index) => (
                                        <OneDepartment key={index} index={index} section={section} docList={docList} t={t} element_list={element_list} viewMode={viewMode} ui_list={ui_list} />
                                    ))}
                                </Accordion>
                            </Row>
                            : <Row>
                                <Col className="font-italic">
                                    {t('documents.message.no_documents')}
                                </Col>
                            </Row>
                        }
                    </>
                }
            </Container>
            <Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, document_count: doc_count })} />

        </>
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkComponentPermission(parent_list, permission_department_list) {
    if (permission_department_list.length === 0) {
        return true
    } else {
        if (parent_list && permission_department_list.indexOf(parent_list[3]) !== -1) {
            return true
        }
    }
    return false
}

function checkStructurePermission(doc, structure_list) {
    console.log("CHECK STRUCTURE", structure_list, doc)
    if (structure_list.length === 0) {
        return true
    } else {
        if (structure_list.includes(doc.item_reference?.id)) {
            return true
        }
    }
    return false

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalFilter = ({ t, structure_list, filterAppliedList, filterData, setFilterAppliedList, setFilterData }) => {

    const { contractId, service_dict } = useContext(GlobalDataContext)
    const { domain } = useContext(DomainContext)
    userData = JSON.parse(localStorage.getItem('userData'))

    const [showFilter, setShowFilter] = useState(false)
    const [loading, setLoading] = useState(false)


    //filter
    const handleCloseFilter = () => {
        setShowFilter(false)
    }

    const resetAppliedFilter = (section) => {
        setFilterAppliedList({ structure: [], pavillon: [], floor: [], department: [] })
        setShowFilter(false)
    }


    /*         const updateAppliedFilter = (section, elem) => {
                const c = filterAppliedList[section].indexOf(elem)
                let f_data = [...filterAppliedList[section]]
                if (c === -1) {
                    f_data.push(elem)
                } else {
                    f_data.splice(c, 1)
                }
                setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
            } */


    const onChangeStructure = async (el) => {
        //	setLoading(true)
        console.log(el, el.value)
        const structure = getSelectedElement(el.value, structure_list, 'id')
        let return_data = await getPavillonList2(domain, contractId, el.value)
        setLoading(true)
        if (return_data && return_data.error) {
            //				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
        } else {
            return_data = return_data.sort(compareName)
            setFilterData({ ...filterData, pavillon: return_data, floor: [], department: [], })
            setFilterAppliedList({ structure: [el.value], pavillon: [], floor: [], department: [], })
        }
        setLoading(false)

    }

    const onChangePavillon = async (el) => {
        //	setLoading(true)
        //setRoomList([])
        setLoading(true)
        const pavillon = getSelectedElement(el.value, filterData.pavillon, 'id')
        console.log(el.value, pavillon)
        if (pavillon.id) {
            let return_data = await getFloorList2(domain, contractId, filterAppliedList.structure[0], pavillon.id)
            setLoading(true)
            if (return_data && return_data.error) {
                //				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
            } else {
                return_data = return_data.sort(compareName)
                setFilterData({ ...filterData, floor: return_data, department: [], })
                setFilterAppliedList({ ...filterAppliedList, pavillon: [pavillon.id], floor: [], department: [], })
            }
        } else {
            setFilterData({ ...filterData, floor: [], department: [], })
            setFilterAppliedList({ ...filterAppliedList, pavillon: [], floor: [], department: [], })

        }
        setLoading(false)
    }
    const onChangeFloor = async (el) => {
        //	setLoading(true)
        //setRoomList([])
        setLoading(true)
        const floor = getSelectedElement(el.value, filterData.floor, 'id')
        console.log(el.value, floor)
        if (floor.id) {
            setLoading(true)
        }
        setLoading(false)
    }



    return (
        <>
            <ButtonGroup>
                <Button size="sm" variant="info" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
                {filterAppliedList.structure.length > 0 &&
                    <>
                        <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter()}><FontAwesomeIcon icon="times" /></Button>
                    </>
                }
            </ButtonGroup>


            <Modal show={showFilter} onHide={handleCloseFilter} size="xl">
                <Modal.Header>
                    <Modal.Title>
                        <FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col sm="3">
                                {structure_list && structure_list.length > 0 &&
                                    <Form.Control as="select" className="mb-2" name="structure" onChange={(event) => onChangeStructure(event.currentTarget)} >
                                        <option value="-1" >{t('structure.labels.select_structure')}</option>
                                        {structure_list.map((f, k) => (
                                            <option key={k} value={f.id} >{f.name}</option>
                                        ))}
                                    </Form.Control>
                                }
                            </Col>
                            <Col sm="3">
                                {filterData.pavillon && filterData.pavillon.length > 0 ?
                                    <Form.Control as="select" className="mb-2" name="pavillon" onChange={(event) => onChangePavillon(event.currentTarget)} >
                                        <option value="-1" >{t('structure.labels.select_pavillon')}</option>
                                        {filterData.pavillon.map((f, k) => (
                                            <option key={k} value={f.id} >{f.name}</option>
                                        ))}
                                    </Form.Control>
                                    : ''}
                            </Col>
                            <Col sm="3">
                                {filterData.floor && filterData.floor.length > 0 ?
                                    <Form.Control as="select" className="mb-2" name="floor" onChange={(event) => onChangeFloor(event.currentTarget)} >
                                        <option value="-1" >{t('structure.labels.select_floor')}</option>
                                        {filterData.floor.map((f, k) => (
                                            <option key={k} value={f.id} >{f.name}</option>
                                        ))}
                                    </Form.Control>
                                    : ''
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {loading ? <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner> : ''}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilter}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneDepartment = ({ docList, section, index, t, ui_list, element_list }) => {

    let category_list = []

    for (let d = 0; d < docList[section].doc_list.length; d++) {
        if (docList[section].doc_list[d].item_reference && docList[section].doc_list[d].item_reference.category && checkComponentPresence(docList[section].doc_list[d].item_reference.category, category_list, 'Id') === -1) {
            category_list.push(docList[section].doc_list[d].item_reference.category)
        }
    }

    const [fileCount, setFileCount] = useState(docList[section].not_image_count)

    const [filterCategoryList, setFilterCategoryList] = useState([])

    return (

        <Card key={index}>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={section} className="h3">
                    <span className="h5">{docList[section].name}</span> <Badge variant="secondary">{fileCount} {t('global.labels.documents')}</Badge>
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={section}>
                <Card.Body>

                    <Row>
                        {docList[section].element_list &&
                            <>
                                {Object.entries(docList[section].element_list)
                                    .filter(e => e[1].media_list && e[1].media_list.length > 0)
                                    .map((elem, k) => (
                                        (filterCategoryList.length === 0 || (elem[1].category && checkComponentPresence(elem[1].category, filterCategoryList) > -1)) &&
                                        <OneElement element={elem[1]} key={k} is_room={false} />


                                    ))}

                            </>
                        }
                        {docList[section].room_list &&
                            <>
                                {Object.keys(docList[section].room_list).map((r, r_index) => (
                                    <OneElement element={docList[section].room_list[r]} key={r} is_room={true} ui_list={ui_list} />
                                ))}
                            </>
                        }
                    </Row>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )

}





///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneElement = ({ element, is_room, ui_list }) => {
    const { roomtype_dict } = useContext(GlobalDataContext)

    const t = useTranslate();
    let name = <b>{element.name}</b>
    if (is_room) {
        name = <><b>{element.name}</b> {roomtype_dict[element.roomtype.id].name}</>
    }


    return (
        <Col sm="12" md="6" xl="4" className="mb-2">
            <Card border={is_room ? "info" : "primary"} className="h-100">
                <Card.Header>
                    <StructureGroupIcon level={element.level} />
                    {name}
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        {element.media_list ?
                            <Row>
                                <Col>
                                    <Row className="mb-1 section_title" >
                                        <Col className="bg-light text-secondary ml-1">{t('global.labels.description')} </Col>
                                        <Col className="bg-light text-secondary ml-1">{t('global.labels.type')}</Col>
                                        <Col className="bg-light text-secondary ml-1" sm="2"></Col>
                                    </Row>
                                    {element.media_list.map((e, k) => (
                                        <OneDocument doc={e} key={k} />
                                    ))}
                                </Col>
                            </Row>
                            : ''}
                    </Container>
                </Card.Body>
                {element.media_list &&
                    <Card.Footer>
                        <i>{element.media_list.length} {element.media_list.length === 1 ? 'documento' : 'documenti'}</i>
                    </Card.Footer>
                }
            </Card>
        </Col>
    )

}

const OtherSectionData = ({ category, c_name }) => {
    if (category === 2 || category === 1 || category === 0) {
        return (
            <span className="small">
                <StructureGroupIcon level={category} item_name={c_name} show_label={true} /></span>

        )
    } else if (category === 1) {
        return (
            <span className="small">Padiglione {c_name} </span>
        )
    } else {
        return (
            <></>

        )
    }


}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneDocument = ({ doc }) => {
    const t = useTranslate();
    const getLabel = (doc) => {
        try {
            return t(type_dict[doc.type].label)
        } catch (err) {
            return doc.type
        }
    }
    return (

        <Row className="border-bottom mb-1 pb-1">
            <Col>
                <Row>
                    {doc.item_reference &&
                        <Col>
                            <OtherSectionData category={doc.item_reference.category} c_name={doc.item_reference.name} />
                        </Col>
                    }
                </Row>
                <Row>
                    <Col>
                        {doc.description}<br />
                        <i>{doc.tracker ? doc.tracker.source_name : doc.source_name}</i><br />
                        {doc.insered_at && format(myTimestampToDate(doc.insered_at), 'dd/MM/yy HH:mm')}
                    </Col>
                </Row>
            </Col>
            <Col>

                {getLabel(doc)}
            </Col>
            <Col sm="2" className="p-1 text-center">
                <ModalDocument t={t} photo={doc.url} name={doc.tracker ? doc.tracker.source_name : doc.source_name} />
            </Col>
        </Row>

    )
}




/* const OneFile = ({ doc }) => {
    const { locale } = useContext(LocaleContext)
    const t = useTranslate();
    const getLabel = (doc) => {
        try {
            return t(type_dict[doc.type].label)
        } catch (err) {
            return doc.type
        }
    }
    const FileIcon = ({ doc }) => {
        if (type_dict[doc.type].icon !== null) {
            return (
                <FontAwesomeIcon icon={type_dict[doc.type].icon} />
            )
        } else {
            return (
                ''
            )
        }
 
    }
    return (
        <Col sm="6" md="4" xl="2" className="mb-2">
            <Card border="primary" className="h-100">
                {doc.type === 'image' ?
                    <span className="d-flex" style={{ maxHeight: '150px' }}>
                        <Image className="imageDocumentViewer" src={doc.url} />
                    </span>
                    : <Card.Header> {doc.description.length > 0 ? doc.description : <i>{doc.source_name}</i>}</Card.Header>
                }
                <Card.Body>
                    <h3>{doc.name}</h3>
                    <Row>
                        <Col className="text-secondary">{t('documents.labels.' + doc.section)}: {doc.item_reference && doc.item_reference.category ? <>{getValue(doc.item_reference.category.name, locale)}</> : ''}</Col>
                    </Row>
                    <Row>
                        <Col>
                            {doc.description}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {getLabel(doc)}
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col>
                            <small className="text-muted">data inserimento: {doc.insered_at ? format(getMyDate(doc.insered_at), 'dd/MM/yyyy HH:mm') : "non disponibile"}</small>
                        </Col>
                        <Col className="text-right" sm="4">
                            <Button size="sm" className="mr-1" variant="primary" href={doc.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" /> {t('media.buttons.open')}</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Col>
 
 
    )
 
} */


export default translate()(DocumentsView)