import { firestore } from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addControlGroup = async (domainId, contractId, data) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('controlgroups')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addControlGroup error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getControlGroupById = async (domainId, contractId, controlgroupId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('controlgroups')
            .get(controlgroupId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id: res.id,
        }
        return data;
    } catch (e) {
        console.log("getControlGroup error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getControlGroupList = async (domainId, contractId, subject = null) => {
    try {
        if (subject === null || subject === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('controlgroups')
                .get();

            const controlgroupList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id: doc.id,
                }
                return data
            })
            return controlgroupList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('controlgroups')
                .where('subject', '==', subject)
                .get();

            const controlgroupList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id: doc.id,
                }
                return data
            })
            return controlgroupList;
        }
    } catch (e) {
        console.log("getControlGroupList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getControlGroupListByStructId = async (domainId, contractId, structId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('controlgroups')
            .where('structure_list', 'array-contains', structId)
            .get();

        const controlgroupList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return controlgroupList;

    } catch (e) {
        console.log("getControlGroupListByStructId error: ", e)
        return { error: e }
    }
}






///////////////////////////////////////////////////////////////////////////////
export const editControlGroup = async (domainId, contractId, controlgroupId, data) => {
    try {
        const controlgroup = firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('controlgroups')
            .doc(controlgroupId);

        const res = await controlgroup.set(data)
            .then(() => {
                console.log("editControlGroup - edit successfully")
                return data
            }).catch(error => {
                console.log("editControlGroup - edit error: ", error)
                return { error: error }
            });

        return res
    } catch (e) {
        console.log("editControlGroup error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteControlGroup = async (domainId, contractId, controlgroupId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('controlgroups')
            .doc(controlgroupId).delete();
        return res
    } catch (e) {
        console.log("deleteControlGroup error: ", e)
        return { error: e }
    }
}




