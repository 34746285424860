import {firestore} from '../firebaseIndex'
import axios from 'axios';
import {GFUNC_DOMAIN} from '../../components/constants/cloudfunctionparams'
//import { useState } from 'react'; 

/*
baseData = {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    contractId: ""
    companyId: ""
    groups: []
}

userData = {
    createAt: "",
    email: "",
    username: "",
    userId: "",
    contractId: ""
    companyId: ""
    groups: []
}
*/


const addUser = async (domainId, baseData) => {
    const newUserData = {
        username: baseData.username,
        email: baseData.email,
        password: baseData.password,
        confirmPassword: baseData.confirmPassword,
        systemgroups: baseData.systemgroups || [],
        contractgroups: baseData.contractgroups || [],
        domainId: domainId,
        status: baseData.status,
    };
    const res = await axios
        .post(GFUNC_DOMAIN+'/createUserSimple2', newUserData)
        .then((response) => {
            //DEBUG: console.log(userId)
            return {userId: response.data.userId}
        })
        .catch((e) => {
            //DEBUG: console.log(e)
            return {error: e}
        });
    
    return res
}

const getUserData = async (domainId, userId) => {
    try{
        const docRef = await firestore.collection('DOMAINS')
                                    .doc(domainId)
                                    .collection('users')
                                    .doc(userId);

        
        const res = await docRef.get()
            .then( function(doc) {
                if (doc.exists) {
                    console.log("user data:", doc.data());
                    return doc.data()
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such user!");
                    return {}
                }
            }).catch(function(e) {
                console.log("Error getting user:", e);
                return {error: e}
            });

        return res;
    }
    catch(e) {
        console.log("getUserData error: ", e)
        return {error: e}
    }
}

const editUserData = async (domainId, userId, userData) => {
    try{
        const user = firestore.collection('DOMAINS')
                            .doc(domainId)
                            .collection('users')
                            .doc(userId);

        const res = await user.set(
            userData
        );
        return res;
    }
    catch(e) {
        return {error: e}
    }
}



const getUserList = async (domainId, contractId) => {
    try {
            const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('users')
            .get();

            const userList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
        })
        return userList;
        
    } catch (e) {
        console.log("getUserList error: ", e)
        return { error: e }
    }
}

const delUser =  async (userId) => {

}

export {addUser}
export {getUserList}
export {getUserData}
export {editUserData}
export {delUser}
