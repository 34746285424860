import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addDilutionsystem = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('dilutionsystemcollection')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addDilutionsystem error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getDilutionsystemById = async (domainId, contractId, dilutionsystemId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('dilutionsystemcollection')
            .doc(dilutionsystemId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getDilutionsystem error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getDilutionsystemList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('dilutionsystemcollection')
            .get();

        const dilutionsystemList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return dilutionsystemList;
        
    } catch (e) {
        console.log("getDilutionsystemList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editDilutionsystem = async (domainId, contractId, dilutionsystemId, data) => {
    try {
        const dilutionsystem =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('dilutionsystemcollection')
            .doc(dilutionsystemId);

        const res = await dilutionsystem.set(data)
            .then( () => {
                console.log("editDilutionsystem - edit successfully")
                return data
            }).catch( error => {
                console.log("editDilutionsystem - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editDilutionsystem error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteDilutionsystem = async (domainId, contractId, dilutionsystemId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('dilutionsystemcollection')
            .doc(dilutionsystemId).delete();
        return res
    }  catch (e) {
        console.log("deleteDilutionsystem error: ", e)
        return { error: e }
    }
}


