import {firestore} from '../firebaseIndex'

const addRoom = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .add(data);

        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addRoom error: ", e)
        return { error: e }
    }
}

const getRoomById = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, roomId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .doc(roomId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getDepartment error: ", e)
        return { error: e }
    }
}

const getRoomList = async (domainId, contractId, structureId, pavillonId, floorId, departmentId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .get();

        const roomList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return roomList;        
    } catch (e) {
        console.log("getRoomList error: ", e)
        return { error: e }
    }
}



const getAllRoomList = async ({structureId, pavillonId, floorId, departmentId} ) => {
    try {
        let res = firestore.collectionGroup('rooms')
        if(structureId !== undefined){
            res = res.where("parent_list", "array-contains", structureId)
        }
        if(pavillonId !== undefined){
            res = res.where("parent_list", "array-contains", pavillonId)
        }
        if(floorId !== undefined){
            res = res.where("parent_list", "array-contains", floorId)
        }
        if(departmentId !== undefined){
            res = res.where("parent_list", "array-contains", departmentId)
        }

        console.log("getAllRoomList: pointA (", structureId, ", ", pavillonId, ", ", floorId, ", ", departmentId, ")"  )
        res = await res.get()
        console.log("getAllRoomList: pointB")

        const roomList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
//            console.log("getAllRoomList -> room path: ", doc.ref.path)
            return data
        })
        return roomList;        
    } catch (e) {
        console.log("getRoomList error: ", e)
        return { error: e }
    }
}




export const getAllRoomListStatus = async ( {structureId, pavillonId, floorId, departmentId, status} ) => {
    try {
        let res = firestore.collectionGroup('rooms')
        if(structureId !== undefined){
            res = res.where("parent_list", "array-contains", structureId)
        }
        if(pavillonId !== undefined){
            res = res.where("parent_list", "array-contains", pavillonId)
        }
        if(floorId !== undefined){
            res = res.where("parent_list", "array-contains", floorId)
        }
        if(departmentId !== undefined){
            res = res.where("parent_list", "array-contains", departmentId)
        }
        res = res.where("status", "==", status)

        res = await res.get()

        const roomList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return roomList;        
    } catch (e) {
        console.log("getRoomList error: ", e)
        return { error: e }
    }
}





const editRoom = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, roomId, data) => {
    try {
        const room = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .doc(roomId);

        const res = await room.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editRoom error: ", e)
        return { error: e }
    }
}


const deleteRoom = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, roomId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .doc(roomId).delete();
        return res
    }  catch (e) {
        console.log("deleteRoom error: ", e)
        return { error: e }
    }
}




export { addRoom };
export { getRoomById };
export { getRoomList };
export { getAllRoomList };
export { editRoom };
export { deleteRoom };