import React from 'react';


// < MATERIAL UI >
import { makeStyles, } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
//NOT USED: import Menu from '@material-ui/core/Menu';

import Grid from '@material-ui/core/Grid';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
// </ MATERIAL UI > 

import { useTranslate } from 'react-polyglot';


// -------- STYLE -------------
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
}));



// ----------------------------------------------------------------------------
export const NavBarProceduralGuideline = (props) => {
    const t = useTranslate();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    // -------------------------------
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    // -------------------------------
    const handleOpen = () => {
        setOpen(true);
    };

    // -------------------------------
    const handleClose = (event) => {
        setOpen(false);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    // -------------------------------
    return (
        <div className={classes.root}>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'menu-list-services' : undefined}
                aria-owns='menu-list-services'
                aria-haspopup="true"
                onClick={handleToggle}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                style={{ borderRadius: "10%" }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <img src="/menu_icons/Servizi.png" height="42"
                            className="d-inline-block align-top"
                            alt={t('global.pages.service')}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" noWrap>
                            {t('global.pages.service')}
                         </Typography>
                    </Grid>
                </Grid>

                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <MenuList autoFocusItem={open} id="menu-list-services" >
                                    <MenuItem onClick={handleClose}>{t('global.pages.operations')}</MenuItem>
                                    <MenuItem onClick={handleClose}>{t('global.pages.services')}</MenuItem>
                                    <MenuItem onClick={handleClose}>{t('global.pages.room_type')}</MenuItem>
                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </IconButton>
        </div>
    );
}