import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container,  Row, Col, Form, Button, InputGroup, ButtonGroup, Spinner, ProgressBar } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import Footer from "../components/Footer"
import PageTitle from "../components/PageTitle"
import SearchSection from "../components/SearchSection"

import { ServiceBadge,  } from '../components/StructureGraphicElements.js'
import { checkComponentPresence, compareName, getValue } from "../util/ui_utils.js"


import { getRoomTypeList_page, editRoomType as editRoomType2 } from '../Firebase2/Storage2/dbBaseStruct';


let load_page=false


function getElementCount(elem_list, filterList, searchTerm) {
	if (elem_list) {
		const filt = elem_list.filter(i => (filterList.length === 0 || filterList.indexOf(i.type) >= 0))
			.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
		if ((filterList.length === 0 && searchTerm.length < 2) || filt.length === elem_list.length) {
			return elem_list.length
		} else {
			return filt.length + "/" + elem_list.length
		}
	} else {
		return undefined

	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const YieldView = ({ t, uData }) => {
	const { domain , domain_data} = useContext(DomainContext)
	const { contract,  service_dict } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()
	let { cid } = useParams();
	let contractId = cid

	const [element_list, setElementList] = useState([])
	const [oldelement_list, setOldElementList] = useState([])
	//	const [ui_list, setUiList] = useState({})
	const [showEdit, setShowEdit] = useState(false)
	const [curElement, setCurElement] = useState({})
	const [curValue, setCurValue] = useState({ index: 0, s: {} })
	const [editedElement, setEditedElement] = useState(false)

	const [loading, setLoading] = useState(true)
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)
	const [globalEdit, setGlobalEdit] = useState(false)
	const [filterAppliedList ] = useState({ type: [] })

	const onCloseGlobalEdit = async (save) => {
		if (save) {
			setGlobalEdit(false)
			for (let el of element_list.filter(e => e.edited)) {
				delete el.edited
				let return_data =  await editRoomType2(domain, contractId, el.id, el)
				if (return_data && return_data.error) {
					childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					childRefAlert.current.addAlert('success', t('global.messages.saved_ok'))
				}
			}
		} else {
			setGlobalEdit(false)
			setElementList(oldelement_list)

		}
		setEditedElement(false)
	}
	const handleEnableGlobalEdit = () => {
		setOldElementList(JSON.parse(JSON.stringify(element_list)))
		setGlobalEdit(true)
		setEditedElement(false)
	}


	const handleClose = () => setShowEdit(false);

	const handleSave = async () => {

		setShowEdit(false);
		setEditedElement(false)

		const edit_element = { ...curElement }
		edit_element.service_list.splice(curValue.index, 1, curValue.s)
		const c = checkComponentPresence(curElement, element_list, 'id')
		let newelement_list = [...element_list]
		newelement_list.splice(c, 1, curElement)
		setElementList(newelement_list)
		let return_data = await editRoomType2(domain, contractId, curElement.id, curElement)
		if (return_data && return_data.error) {
			childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
		} else {
			childRefAlert.current.addAlert('success', t('global.messages.saved_ok'))
		}

	}


	const handleShowEdit = (el, sk, s) => {
		setCurElement(el)
		setCurValue({ index: sk, s: s })
		setShowEdit(true)
		setEditedElement(false)
	}

	

	const onChangeHandleGlobalElement = (el) => {
		setEditedElement(true)
		console.log(el)
		let tmp_element_list = [...element_list]
		tmp_element_list[el.roomtype_index].service_list[el.service_index].hourlyyield = el.value
		tmp_element_list[el.roomtype_index].edited = true
		console.log(tmp_element_list)
		setElementList(tmp_element_list)
	}
	const onChangeHandlerElement = (el) => {
		const edit_service = { ...curValue.s }
		edit_service.hourlyyield = el.value
		setCurValue({ index: curValue.index, s: edit_service })
		setEditedElement(true)
	}


	const initValues = async () => {
		console.log("yeldsummary udata", uData)
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('yield_summary') !== -1)) {
			setWritePage(true)
		}
		if (contract && domain.length > 0) {
			contractId = contract.id
			load_page=true
			setLoading(true)
			let rt_list = []
			let do_query = true
			let cursor = undefined
			while (do_query){
				const r_data = await getRoomTypeList_page(domain, contractId, cursor, 50)
				if (r_data.query_cursor!== null){  
					rt_list = [...rt_list, ...r_data.roomTypeList]
				    cursor = r_data.query_cursor
				}else{
					do_query = false
				}
			}
			let  newelement_list = rt_list
			newelement_list.sort(compareName)
			//console.log(newelement_list)
			setElementList(newelement_list)
			setLoading(false)
		}
	}


	useEffect(() => {
		async function loadData() {
			//const contractId = cid
			console.log("OPERATIONS reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("OPERATIONS init contract return", ret_data)
			}
		}
		if (!contract) {
			loadData()
		}
	}, [])


	useEffect(() => {
		console.log('OPERATIONS loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])



	return (
		<div>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid className="titleStickyTop">
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('global.pages.yieldsummary')} />
					</Col>
					<Col sm={2}>
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col className="text-right">
						{canWrite && globalEdit ?
							<ButtonGroup>
								<Button variant="secondary" className="mr-1" onClick={() => onCloseGlobalEdit(false)}><FontAwesomeIcon icon='times' />{t('global.labels.close')}</Button>
								<Button variant={editedElement ? "warning" : "primary"} onClick={() => onCloseGlobalEdit(true)}><FontAwesomeIcon icon='check' />{editedElement ? <FontAwesomeIcon icon="save" /> : ''} {t('global.labels.save')}</Button>
							</ButtonGroup>
							:
							<Button variant="info" onClick={() => handleEnableGlobalEdit()}><FontAwesomeIcon icon='pencil-alt' />  {t('interventions.buttons.enable_edit')}</Button>
						}
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="5"> {t('global.labels.roomtype')}</Col>
						<Col className="bg-secondary ml-1">
									{t('global.labels.service_list')}
						</Col>
						<Col className="bg-secondary ml-1" sm="3">
									{t('structure.labels.hourlyield')}
						</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>

						<Col className="text-center">
							<>
								<Spinner animation="border" role="status" />
							           &nbsp;
							          Caricamento in corso
								          </>
						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.map((i, key) => (
									<>
										{searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
											?
											<Row key={key} className=" oneRoomType p-1 border-bottom">
												<Col sm="5" className={i.edited ? "bg-warning" : ""}>
													<b>{i.name}</b>
												</Col>
												<Col>
													{i.service_list ?
														(i.service_list.map((s, sk) => (
															<>
																{s.service ?
																	<>
																		{s.service.type === 'periodic' ?
																			<Row key={sk} className={sk<i.service_list.length-1?"element_row border-bottom":"element_row "}>
																				<Col>
																					<ServiceBadge service={service_dict[s.service.id]} freq={true} />
																				</Col>
																				{globalEdit ?
																					<Col>
																						<InputGroup>
																							<Form.Control value={s.hourlyyield} type="number" step="0.01" min="0" name="hourlyyield" onChange={(el) => onChangeHandleGlobalElement({ value: el.currentTarget.value, service_index: sk, roomtype_index: key })} />
																							<InputGroup.Append>
																								<InputGroup.Text>
																									m<sup>2</sup> /  h
																							</InputGroup.Text>
																							</InputGroup.Append>
																						</InputGroup>
																					</Col>
																					:
																					<>
																					<Col sm="1" className="p-1">
																						<ProgressBar now={parseInt(s.hourlyyield)/10} />
																						</Col>
																					<Col sm="2" className="text-right">
																						{s.hourlyyield} m<sup>2</sup> /  h&nbsp;
																			{canWrite ?
																							<Button variant="info" size="sm" onClick={(el) => handleShowEdit(i, sk, s)}><FontAwesomeIcon icon='pencil-alt' /> </Button>
																							: ''}
																					</Col>
																					</>
																				}
																			</Row>

																			:
																			[s.service.type === 'ordinary' ?
																				<Row key={sk} className={sk<i.service_list.length-1?"element_row border-bottom": "element_row"}>
																					<Col>
																						<ServiceBadge service={service_dict[s.service.id]} freq={true} />
																					</Col>
																					{globalEdit ?
																						<Col>
																							<InputGroup>
																								<Form.Control value={s.hourlyyield} type="number" step="0.01" min="0" name="hourlyyield" onChange={(el) => onChangeHandleGlobalElement({ value: el.currentTarget.value, service_index: sk, roomtype_index: key })} />
																								<InputGroup.Append>
																									<InputGroup.Text>
																										m<sup>2</sup> /  h
																							</InputGroup.Text>
																								</InputGroup.Append>
																							</InputGroup>
																						</Col>
																						:
																					<>
																					<Col sm="1" className="p-1">
																						<ProgressBar now={parseInt(s.hourlyyield)/10} />
																						</Col>
																					<Col sm="2" className="text-right">
																							{s.hourlyyield} m<sup>2</sup> / h&nbsp;
																				{canWrite ?
																								<Button variant="info" size="sm" onClick={(el) => handleShowEdit(i, sk, s)}><FontAwesomeIcon icon='pencil-alt' /> </Button>
																								: ''}
																						</Col>
																						</>
																					}
																				</Row>
																				:

																				<Row key={sk} className="element_row">
																					<Col>
																						<ServiceBadge service={service_dict[s.service.id]} freq={true} />
																					</Col>
																				</Row>
																			]
																		}
																	</>
																	:
																	<Row key={sk}><Col>{s.name}</Col></Row>
																}
															</>
														)))
														: ''}
												</Col>
												{/* <Col>

											{i.furniture_list && i.furniture_list.length > 0 ?

												<>
													<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderPopover(t('global.labels.furnitures'), i.furniture_list)}>
														<Badge variant="secondary" className="btn mr-1">{i.furniture_list.length} {t('global.labels.furnitures')} </Badge>
													</OverlayTrigger>
												</>
												: ''
											}
											{i.environment_element_list && i.environment_element_list.length > 0 ?
												<>
													<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderPopover(t('global.labels.environment_element'), i.environment_element_list)}>
														<Badge variant="secondary" className="btn mr-1">{i.environment_element_list.length} {t('global.labels.environment_elements')} </Badge>
													</OverlayTrigger>
												</>
												: ''
											}
											{i.economic_material_list && i.economic_material_list.length > 0 ?
												<>
													<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderPopover(t('global.labels.economic_material'), i.economic_material_list)}>
														<Badge variant="secondary" className="btn mr-1">{i.economic_material_list.length} {t('global.labels.economic_material')} </Badge>
													</OverlayTrigger>
												</>
												: ''
											}
											{i.has_patient_unit && i.patient_unit > 0 ?
												<>
													<FontAwesomeIcon icon="user" /> &times; {i.patient_unit}
												</>
												: ''}
										</Col> */}

											</Row>
											: ''
										}
									</>
								))}
							</>
							: <> <Row><Col className="font-italic">
								{t('roomtype.messages.no_roomtype')}
							</Col></Row>
							</>
						}
					</>
				}
			</Container>
			<Footer count_text={loading? <Spinner animation="border" role="status" /> : getElementCount(element_list, filterAppliedList.type, searchTerm) + " " + t('global.labels.services')} />

			<Modal show={showEdit} onHide={handleClose} backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title className="text-info">{t('roomtypes.labels.edit_yield')} | {getValue(curValue.s.service, 'name')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<InputGroup className="mb-2" >
							<Form.Control type="number" min="0" step="0.1" placeholder="" value={curValue.s.hourlyyield} name="hourlyyield" onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
							<InputGroup.Append>
								<InputGroup.Text>
									m<sup>2</sup> / h
      							</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default translate()(YieldView)


