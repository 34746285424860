import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addWorkflow = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('workflowcollection')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addWorkflow error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getWorkflowById = async (domainId, contractId, workflowId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('workflowcollection')
            .doc(workflowId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getWorkflow error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getWorkflowList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('workflowcollection')
            .get();

        const workflowList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return workflowList;
        
    } catch (e) {
        console.log("getWorkflowList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editWorkflow = async (domainId, contractId, workflowId, data) => {
    try {
        const workflow =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('workflowcollection')
            .doc(workflowId);

        const res = await workflow.set(data)
            .then( () => {
                console.log("editWorkflow - edit successfully")
                return data
            }).catch( error => {
                console.log("editWorkflow - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editWorkflow error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteWorkflow = async (domainId, contractId, workflowId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('workflowcollection')
            .doc(workflowId).delete();
        return res
    }  catch (e) {
        console.log("deleteWorkflow error: ", e)
        return { error: e }
    }
}


