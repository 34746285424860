import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from '../contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab, InputGroup, ButtonGroup, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { format, setDay, differenceInMinutes, addDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { BlockPicker } from 'react-color';
import reactCSS from 'reactcss'
import { useParams } from "react-router-dom"
import DateTime from 'react-datetime';

import DomainContext from '../contexts/domainContext'

import { workshiftType_dict } from "../../components/constants/global_definitions"

import ModalBaseObject from "../ModalBaseObject"
import AlertSection from "../AlertSection.js"

import { getSelectedElement, checkComponentPresence, myTimestampToDate, setMyDate, makeid, getTimeStr, myDateToTimestamp } from "../../util/ui_utils.js"
import { ModalInitButton } from '../StructureGraphicElements.js'

import firebase from 'firebase/app'

import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../../Firebase2/Storage2/dbBaseObject"
import { addWorkshift as addWorkshift2, editWorkshift as editWorkshift2 } from '../../Firebase2/Storage2/dbWorkshift';
import { editFileTracker as editFileTracker2 } from '../../Firebase2/Storage2/dbFileStorage.js';




const section = 'workshift'
const defaultcolors = [
	'#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#607d8b", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800",
]

const wd = ["1", "2", "3", "4", "5", "6", "0"]
let d_start = new Date()
let d_end = new Date()
d_start.setHours(8, 0, 0)
d_end.setHours(18, 0, 0)
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalWorkshift({ item, setItem, t, is_new, small, item_list, canWrite }) {
	const { domain } = useContext(DomainContext)

	let { cid } = useParams();
	let contractId = cid

	const childRefAlert = useRef()
	const newworkshift = {
		code: '',
		name: '',
		color: { r: 0, g: 0, b: 0, a: 1 },
		week_frequency: {},
		month_frequency: [],
		timeslot_list: [],
		workrest_sequence: [],
		work_days: 0,
		rest_days: 0,
		note: '',

	}
	const initValues = async () => {
		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		const category_list = await getBaseObjectTypeList2(domain, 'workshift_category')
		setUiList({ ...ui_list, brand_list, category_list })
		/* 		let newelement_list = await getWorkshiftList(contractId, companyId, 'workshift', null)
				newelement_list.sort(compareName)
				setElementList(newelement_list) */
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newworkshift)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newworkshift.media_list = []
		setCurrentElement(newworkshift)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	const updateElement = (el) => {
		setCurrentElement(el)
		setEditedElement(true)

	}
	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addWorkshift2(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editWorkshift2(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('workshift.buttons.workshift_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('workshifts.labels.new_workshift')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('workshifts.labels.edit_workshift')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('workshifts.labels.view_workshift')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<ElementFormDetails element={curElement} t={t} setElement={updateElement} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, setElement, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)
	const [displayColorPicker, setShowColorPicker] = useState(false)
	const [color, setCurColor] = useState({ r: 0, g: 0, b: 0, a: 1 })
	const [totalMinutes, setTotalMinutes] = useState(0)

	const workshift_type = workshiftType_dict({ t })

	const styles = reactCSS({
		'default': {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: `rgba(${element.color.r}, ${element.color.g}, ${element.color.b}, ${element.color.a})`,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		},
	});
	const updateCategories = (b) => {
		functions.updateData('workshift_category', b)
	}

	const onChangeHandlerColor = (el) => {
		functions.edit({ name: 'color', value: el.rgb })
	}
	const handleChangeColor = (c) => {
		console.log('set color', c)
		setCurColor(c.rgb)
	}
	const handleShowColorPicker = () => {
		setShowColorPicker(!displayColorPicker)
	}
	const handleCloseColorPicker = () => {
		setShowColorPicker(false)
	}

	const onUpdateWorkRest = (el) => {
		const { name, value } = el
		let edit_el = { ...element }
		edit_el[name] = value
		if (element.workrest_sequence.length < parseInt(edit_el.work_days) + parseInt(edit_el.rest_days)) {
			console.log("ADD", element.workrest_sequence.length, parseInt(edit_el.work_days) + parseInt(edit_el.rest_days))
			while (element.workrest_sequence.length < (parseInt(edit_el.work_days) + parseInt(edit_el.rest_days))) {
				edit_el.workrest_sequence.push({ ...default_wr_element })
			}
		} else if (element.workrest_sequence.length > parseInt(edit_el.work_days) + parseInt(edit_el.rest_days)) {
			while (element.workrest_sequence.length > (parseInt(edit_el.work_days) + parseInt(edit_el.rest_days))) {
				edit_el.workrest_sequence.splice(edit_el.workrest_sequence.length, 1)
			}
		}
		console.log(edit_el)
		setElement(edit_el)
	}

	const onChangeFrequency = (el) => {
		const { name, value } = el
		console.log(name, value)
		let edit_freq = { ...element[name] }
		if (el.checked) {
			console.log(d_start, d_end)
			edit_freq[value] = {
				start: firebase.firestore.Timestamp.fromDate(d_start),
				end: firebase.firestore.Timestamp.fromDate(d_end),
			}
			console.log(edit_freq[value])
		} else {
			delete edit_freq[value]
		}
		functions.edit({ name: name, value: edit_freq })
	}

	const updateFrequencyTime = (name, id, el) => {
		console.log(el, name, id)
		if (el) {
			let edit_freq = { ...element[name] }
			edit_freq[id][el.name] = el.value
			functions.edit({ name: name, value: edit_freq })
		}

	}

	useEffect(() => {
		setTotalMinutes(totalWorkshiftMinutes())

	}, [element])

	const totalWorkshiftMinutes = () => {
		let total_minutes = 0
		try {

			if (element.category === 'monthdays') {
				total_minutes = Object.values(element.month_frequency).map(i => differenceInMinutes(myTimestampToDate(i.end), myTimestampToDate(i.start))).reduce((p, n) => p + n)
			} else if (element.category === 'weekdays') {
				total_minutes = Object.values(element.week_frequency).map(i => differenceInMinutes(myTimestampToDate(i.end), myTimestampToDate(i.start))).reduce((p, n) => p + n)
			} else {

			}
		} catch (err) {

		}
		return total_minutes
	}

	return (
		<>
			<Form.Group as={Row} controlId="workshiftName" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="workshiftCode" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Row className="border-bottom mb-1 pb-1">
				<Col sm="2">{t('objecttype.labels.color')}</Col>
				<Col>
					<Button variant="outline-info" onClick={handleShowColorPicker}>
						<div style={styles.color} />
					</Button>
					{displayColorPicker ? <div style={styles.popover}>
						<div style={styles.cover} onClick={handleCloseColorPicker} />
						<BlockPicker color={color} colors={defaultcolors} triangle="hide" onChange={handleChangeColor} onChangeComplete={onChangeHandlerColor} />
					</div> : null}
				</Col>
			</Row>
			<Form.Group as={Row} controlId="formWorkshiftCategory" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.category} name="category" className="mb-2" onChange={(event) => functions.edit(event.target)}  >
						<option value="-1">{t('global.labels.select_type')}</option>
						{Object.keys(workshift_type).map((fk, k) => (
							<option key={k} value={workshift_type[fk].id} >{workshift_type[fk].label}</option>
						))}
					</Form.Control>
				</Col>
			</Form.Group>
			{element.category === 'monthdays' ?
				<Form.Group as={Row} className="pb-1">
					{[...Array(31)].map((k, i) => (
						<Col key={"m_" + i} sm="6" className="border-bottom border-right pt-1 ">
							<Row>
								<Col>
									<Form.Check type="checkbox" name="month_frequency" checked={element.month_frequency[i]} value={i} label={i + 1} onChange={(event) => onChangeFrequency(event.currentTarget)} />
								</Col>
								{element.month_frequency[i] ?
									<TimeSelector t={t} element={element.month_frequency[i]} element_name='month_frequency' id={i} functions={{ updateTime: updateFrequencyTime }} />
									: ''}
							</Row>
						</Col>
					))}
				</Form.Group>
				: ''}
			{element.category === 'weekdays' ?
				<>
					<Form.Group as={Row} className="pb-1">
						<Col>
							{wd.map((w, k) => (
								<Row key={w} className="border-bottom border-right pt-1" >
									<Col key={k}>
										<Form.Check type="checkbox" name="week_frequency" checked={element.week_frequency[w]} value={w} label={format(setDay(new Date(), w), 'cccccc', { locale: it })} onChange={(event) => onChangeFrequency(event.currentTarget)} />
									</Col>
									{element.week_frequency[w] ?
										<TimeSelector t={t} element={element.week_frequency[w]} element_name='week_frequency' id={w} functions={{ updateTime: updateFrequencyTime }} />
										: ''}
								</Row>
							))}
						</Col>
					</Form.Group>
				</>
				: ''
			}


			{
				element.category === 'workrestdays' ?
					<>
						<Form.Group as={Row} className="border-bottom pb-1">
							<Form.Label column sm="2">{t('workshifts.labels.days_count')}</Form.Label>
							<Col>
								<InputGroup>
									<Form.Control type="number" name="work_days" value={element.work_days} min="1" onChange={(event) => onUpdateWorkRest(event.currentTarget)} />
									<InputGroup.Append>
										<InputGroup.Text id="basic-addon1">lavorativi</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Col>
							<Col>
								<InputGroup>
									<Form.Control type="number" name="rest_days" value={element.rest_days} min="1" onChange={(event) => onUpdateWorkRest(event.currentTarget)} />
									<InputGroup.Append>
										<InputGroup.Text id="basic-addon1">riposo</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Col>
						</Form.Group>
						<Row className="border-bottom mb-1 pb-1">
							<Col sm="2">{t('workshift.labels.timeslots')}</Col>
							<Col>
								<TimeSlots t={t} element={element} functions={functions} canWrite={canWrite} />
							</Col>

						</Row>
						<Form.Group as={Row} className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('workshift.labels.broken_timeshift')}</Form.Label>
							<Col>
								<Form.Check type="radio" name="broken_timeshift" value={true} checked={element.broken_timeshift} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
								<Form.Check type="radio" name="broken_timeshift" value={false} checked={!element.broken_timeshift} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
							</Col>
						</Form.Group>
						<WorkRestSequence t={t} element={element} functions={functions} canWrite={canWrite} />
					</>
					: ''
			}
			{element.category !== 'workrestdays' ?
				<Row className="border-bottommb-1 pb-1">
					<Col className="bg-secondary text-white">
						<FontAwesomeIcon icon="info-circle" />
						minuti totali: {totalMinutes}
					</Col>
				</Row>
				: ''}
			<Form.Group as={Row} controlId="workshiftNote" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}

///////////////////////////////////////////////////////////////////////
const WorkRestSequence = ({ t, element, functions, canWrite }) => {

	console.log(parseInt(element.work_days) + parseInt(element.rest_days))
	const [sequence, setSequence] = useState([])
	useEffect(() => {
		initSequence()
	}, [])

	const initSequence = () => {

	}


	const onUpdateWorkRestElement = (el, index) => {
		let { name, value } = el
		console.log(name, value, index)
		let edit_list = [...element.workrest_sequence]
		console.log("PRE", edit_list[index][name])
		if (name === 'broken_timeshift_list') {
			let oldcheck = []
			try {
				oldcheck = [...edit_list[index][name]]
			} catch (err) { }
			if (el.checked) {
				if (value === '-1') {
					oldcheck = ['-1']
				} else {
					if (oldcheck.indexOf('-1') >= 0) {
						oldcheck.splice(oldcheck.indexOf('-1'), 1)

					}
					if (oldcheck.indexOf(value) < 0) {
						oldcheck.push(value)
					}
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			edit_list[index] = { ...edit_list[index], broken_timeshift_list: oldcheck, role: value }
		} else {
			edit_list[index] = { ...edit_list[index], [name]: value }
		}
		console.log(edit_list)
		functions.edit({ name: 'workrest_sequence', value: edit_list })
	}

	return (
		<>
			<Row className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workshift.labels.workrest_sequence')}

					{parseInt(element.rest_days) !== element.workrest_sequence.filter(s => parseInt(s.role) === -1).length ?
						<Badge variant="warning"><FontAwesomeIcon icon="exclamation-triangle" /></Badge>
						: ''}
				</Form.Label>
				<Col>
					{element.broken_timeshift ?
						<Row>
							<Col sm="1">
							</Col>
							<Col>
								{t('workshift.labels.rest')}
							</Col>
							{element.timeslot_list.map((ts, kk) => (
								<Col key={kk}>
									{ts.description}
								</Col>
							))}
						</Row>
						: ''}

					{element.workrest_sequence.map((w, k) => (
						<Row key={k} className="border-bottom">
							<Col sm="1"><Badge variant="info">giorno {k + 1}</Badge></Col>
							{element.broken_timeshift ?
								<>
									<Col>
										<Form.Check type="checkbox" name="broken_timeshift_list" checked={w.broken_timeshift_list.indexOf('-1') >= 0} value={-1} alt={t('workshift.labels.rest')} onChange={(event) => onUpdateWorkRestElement(event.currentTarget, k)} />
									</Col>
									{element.timeslot_list.map((ts, kk) => (
										<Col key={kk}>
											<Form.Check type="checkbox" name="broken_timeshift_list" checked={w.broken_timeshift_list.indexOf(ts.id) >= 0} value={ts.id} alt={ts.description} onChange={(event) => onUpdateWorkRestElement(event.currentTarget, k)} />
										</Col>
									))}
								</>
								:
								<Col>
									<Form.Control as="select" value={w.role} name='role' className="mb-2" onChange={(event) => onUpdateWorkRestElement(event.target, k)}  >
										<option value="-1">{t('workshift.labels.rest')}</option>
										{element.timeslot_list.map((ts, kk) => (
											<option key={kk} value={ts.id} >{ts.description}</option>
										))}
									</Form.Control>
								</Col>
							}
						</Row>
					))}
				</Col>
			</Row>
		</>
	)
}

const default_wr_element = { role: -1, broken_timeshift_list: [] }


///////////////////////////////////////////////////////////////////////
const TimeSlots = ({ t, element, functions, canWrite }) => {

	const handleChangeTimeslot = (el) => {
		console.log("handleAddTimeslot", el)
		functions.edit(el)
	}

	const removeTimeslot = (index, t) => {
		let newtimeslot_list = [...element.timeslot_list]
		newtimeslot_list.splice(index, 1)
		functions.edit({ name: 'timeslot_list', value: newtimeslot_list })

	}

	const WeekHourSlot = ({ ts }) => {
		return (

			<>
				{wd.map((w, k) => (
					<Row key={w}>
						{ts.week_timeslot[w] ?
							<>
								<Col>
									{format(setDay(new Date(), w), 'ccc', { locale: it })}
								</Col>
								<Col>
									{format(myTimestampToDate(ts.week_timeslot[w].start), 'HH:mm')}
									&nbsp;-&nbsp;
									{format(myTimestampToDate(ts.week_timeslot[w].end), 'HH:mm')}
								</Col>
							</>
							: ''}
					</Row>
				))}
			</>
		)

	}

	return (
		<>
			<Row>
				<Col className="text-right mb-1">
					<ModalTimeslot is_new={true} t={t} item={element} parent_list={element} setItem={handleChangeTimeslot} canWrite={canWrite} />
				</Col>
			</Row>
			<Row>

			</Row>
			{element.timeslot_list.map((ts, k) => (
				<Row key={k} className="border-bottom mb-1 pb-1">
					{console.log(ts)}
					<Col>
						{ts.description}
					</Col>
					<Col>
						<WeekHourSlot ts={ts} />
					</Col>
					<Col sm="1 p-0 text-center">
						{canWrite ?
							<ButtonGroup>
								<ModalTimeslot item={ts} parent_list={element} index={k} setItem={handleChangeTimeslot} t={t} canWrite={canWrite} />
								<Button variant="outline-danger" size="sm" onClick={() => removeTimeslot(k, ts)}><FontAwesomeIcon icon='trash' /> </Button>
							</ButtonGroup>
							: ''}
					</Col>
				</Row>
			))}
		</>
	)
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalTimeslot({ item, index, setItem, t, is_new, small, parent_list, canWrite }) {
	const newtimeslot = {
		id: makeid(6),
		description: '',
		global_timeslot: { start: firebase.firestore.Timestamp.fromDate(d_start), end: firebase.firestore.Timestamp.fromDate(d_end) },
		week_timeslot: {}
	}
	const [showTimeslotEdit, setShowTimeslotEdit] = useState(false)
	const [curTimeslot, setCurTimeslot] = useState(newtimeslot)
	const [editedElement, setEditedElement] = useState(false)

	const handleCloseTimeslot = () => setShowTimeslotEdit(false);
	const handleSaveTimeslot = async () => {
		setShowTimeslotEdit(false);
		console.log("Edit", is_new, index)
		if (is_new) {
			let newtimeslot_list = [...item.timeslot_list]
			newtimeslot_list.push(curTimeslot)
			setItem({ name: 'timeslot_list', value: newtimeslot_list })
		} else {
			let newtimeslot_list = [...parent_list.timeslot_list]
			newtimeslot_list.splice(index, 1, curTimeslot)
			setItem({ name: 'timeslot_list', value: newtimeslot_list })

		}
	}

	const handleShowNewTimeslot = () => {
		console.log('show new')
		setCurTimeslot(newtimeslot)
		setShowTimeslotEdit(true)
		setEditedElement(false)
	}

	const handleShowEditTimeslot = () => {
		setCurTimeslot(item)
		setShowTimeslotEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerTimeslot = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curTimeslot[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		if (name === 'global_timeslot') {
			let _week_timeslot = {}
			for (const c in curTimeslot.week_timeslot) {
				_week_timeslot[c] = {
					start: replaceTime(value.start || firebase.firestore.Timestamp.fromDate(d_start), curTimeslot.week_timeslot[c].start, value.start),
					end: replaceTime(value.end || firebase.firestore.Timestamp.fromDate(d_end), curTimeslot.week_timeslot[c].end, value.end)
				}
			}
			console.log("NEW WEEK", _week_timeslot)
			setCurTimeslot({ ...curTimeslot, 'global_timeslot': value, 'week_timeslot': _week_timeslot })
		} else {
			setCurTimeslot({ ...curTimeslot, [name]: value })
		}
		setEditedElement(true)

	}

	const replaceTime = (source_time, destination_time) => {
		const s_time = myTimestampToDate(source_time)
		let d_time = myTimestampToDate(destination_time)
		d_time.setHours(s_time.getHours())
		d_time.setMinutes(s_time.getMinutes())
		return firebase.firestore.Timestamp.fromDate(d_time)
	}

	const onChangeFrequency = (el) => {
		const { name, value } = el
		console.log(name, value)
		let edit_freq = { ...curTimeslot[name] }
		if (el.checked) {
			console.log(d_end, d_start)
			if (curTimeslot.same_time) {
				edit_freq[value] = {
					start: curTimeslot.global_timeslot.start,
					end: curTimeslot.global_timeslot.end
				}
			} else {

				edit_freq[value] = {
					start: firebase.firestore.Timestamp.fromDate(d_start),
					end: firebase.firestore.Timestamp.fromDate(d_end)
				}
			}
		} else {
			delete edit_freq[value]
		}
		onChangeHandlerTimeslot({ name: name, value: edit_freq })
	}

	const updateFrequencyTime = (name, id, el) => {
		console.log(name)
		if (el) {
			let edit_freq = { ...curTimeslot[name] }
			if (name === 'week_timeslot') {
				edit_freq[id][el.name] = el.value
			} else {
				edit_freq[el.name] = el.value
			}
			onChangeHandlerTimeslot({ name: name, value: edit_freq })
		}
	}


	const totalWeekHour = () => {
		let total = 0
		for (const tk in curTimeslot.week_timeslot) {
			const el = curTimeslot.week_timeslot[tk]
			total += differenceInMinutes(myTimestampToDate(el.end), myTimestampToDate(el.start))
		}
		return getTimeStr(total, t)

	}

	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNewTimeslot()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNewTimeslot()} variant="outline-primary" size="sm"><FontAwesomeIcon icon='plus-square' /> {t('workshifts.labels.timeslot_add')}</Button>
				)
				: <Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowEditTimeslot()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showTimeslotEdit} onHide={handleCloseTimeslot} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('workshifts.labels.new_timeslot')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('workshifts.labels.edit_timeslot')}</span>
							</>
						}
						&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="workshiftName" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="description" value={curTimeslot.description} onChange={(event) => onChangeHandlerTimeslot(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('workshift.labels.same_time')}</Form.Label>
							<Col sm="2">
								<Form.Check type="radio" name="same_time" value={true} checked={curTimeslot.same_time} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerTimeslot(event.currentTarget)} />
								<Form.Check type="radio" name="same_time" value={false} checked={!curTimeslot.same_time} label={t('global.labels.no')} onChange={(event) => onChangeHandlerTimeslot(event.currentTarget)} />
							</Col>
							{curTimeslot.same_time ?
								<Col>
									<Row>
										<TimeSelector t={t} element={curTimeslot.global_timeslot} element_name='global_timeslot' functions={{ updateTime: updateFrequencyTime }} />
									</Row>
								</Col>
								: ''}
						</Form.Group>
						<Form.Group as={Row} controlId="workshiftName" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('workshifts.labels.timing')}</Form.Label>
							<Col sm="8">
								{wd.map((w, k) => (
									<Row key={k} className="border-bottom border-right mb-1 " >
										<Col>
											<Form.Check type="checkbox" name="week_timeslot" checked={curTimeslot.week_timeslot[w]} value={w} label={format(setDay(new Date(), w), 'cccccc', { locale: it })} onChange={(event) => onChangeFrequency(event.currentTarget)} />
										</Col>
										{curTimeslot.week_timeslot[w] && !curTimeslot.same_time ?
											<TimeSelector t={t} element={curTimeslot.week_timeslot[w]} element_name='week_timeslot' id={w} functions={{ updateTime: updateFrequencyTime }} />
											: ''}
									</Row>
								))}
								{Object.keys(curTimeslot.week_timeslot).length > 0 ?
									<Row className="bg-info text-light p-1">
										<Col>
											<FontAwesomeIcon icon="info-circle" />
											{Object.keys(curTimeslot.week_timeslot).length} giorni
										</Col>
										<Col>
											{totalWeekHour()}
										</Col>
									</Row>
									: ''}
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseTimeslot}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveTimeslot}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const TimeSelector = ({ element, t, functions, id, element_name }) => {
	const getDiffTime = (start, end) => {
		if (start < end) {
			return differenceInMinutes(end, start)
		} else {
			return differenceInMinutes(addDays(end, 1), start)
		}
	}
	return (
		<>
			<Col >
				<DateTime initialValue={element && element.start ? myTimestampToDate(element.start) : d_start} dateFormat={false} timeFormat='HH:mm' name="start" locale="it" closeOnSelect={true} onChange={(e) => functions.updateTime(element_name, id, { name: 'start', value: setMyDate(e) })} />
				<Form.Text className="text-muted">
					{t('workareas.labels.start_time')}
				</Form.Text>
			</Col>
			<Col >
				<DateTime initialValue={element && element.end ? myTimestampToDate(element.end) : d_end} dateFormat={false} timeFormat='HH:mm' name="end" locale="it" closeOnSelect={true} onChange={(e) => functions.updateTime(element_name, id, { name: 'end', value: setMyDate(e) })} />
				<Form.Text className="text-muted">
					{t('workareas.labels.end_time')}
				</Form.Text>
			</Col>
			<Col>
				{element && element.end && element.start ?
					<>
						{getDiffTime(myTimestampToDate(element.start), myTimestampToDate(element.end))} minuti
						<Form.Text>
							durata
						</Form.Text>
					</>
					: ''}
			</Col>
		</>
	)

}


export default translate()(ModalWorkshift)