import {firestore} from '../firebaseIndex'

const addEmployer = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('employers')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addEmployer error: ", e)
        return { error: e }
    }
}

const addBatchEmployer = async (domainId, contractId, data_array ) => {
    try {
        var batch = firestore.batch()

        data_array.forEach((doc) => {
            batch.set( firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('employers').doc(), doc);
          }
        )
        
        // Commit the batch
        const res = await batch.commit().then(  function () {
            return true;
        }).catch( (e) => {
            console.log("addBatchEmployer error [batch]: ", e)
            return false;
        })

        return res
    } catch (e) {
        console.log("addBatchEmployer error: ", e)
        return { error: e }
    }
}


const getEmployerById = async (domainId, contractId, employerId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('employers')
            .doc(employerId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getEmployerById error: ", e)
        return { error: e }
    }
}

const getEmployerList = async (domainId, contractId, {company, category} ) => {
    try {
        let res = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('employers')
        if (category !== null && category !== undefined) {
            res = res.where('category', 'array-contains', category)
        }
        if (company !== null && company !== undefined) {
            res = res.where('company', '==', company)
        }
        res = await res.get();

        const employerList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return employerList;

    } catch (e) {
        console.log("getEmployerList error: ", e)
        return { error: e }
    }
}


const editEmployer = async (domainId, contractId, employerId, data) => {
    try {
        const employer = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('employers')
            .doc(employerId);

        const res = await employer.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editEmployer error: ", e)
        return { error: e }
    }
}


const deleteEmployer = async (domainId, contractId, employerId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('employers')
            .doc(employerId).delete();
        return res
    }  catch (e) {
        console.log("deleteEmployer error: ", e)
        return { error: e }
    }
}




export { addEmployer };
export { getEmployerById };
export { getEmployerList };
export { editEmployer };
export {addBatchEmployer}
export { deleteEmployer };