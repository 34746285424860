import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, ButtonGroup, Form, Modal, ProgressBar } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { MediaPreview } from "../StructureGraphicElements"

import { mediaType_dict } from "../constants/global_definitions"

import { uploadFileComplete, editFileTracker } from '../../Firebase2/Storage2/dbFileStorage';
import { addSystemDocument, deleteSystemDocument, editSystemDocument } from '../../Firebase2/Storage2/dbSystemDocument';

import DomainContext from '../contexts/domainContext'
import GlobalDataContext from '../contexts/globaldataContext.js';



const domain = 'admin'
const contractId = '0'



function ModalAdminUploader({ is_new, element, functions, t, section, canWrite }) {
	const type_dict = mediaType_dict({ t: t, section: section })


	const newmedia = {
		description: '',
		type: 'file',
		category: '',
		preview: ''
	}

	const [showMediaEdit, setShowMediaEdit] = useState(false)
	const [curMedia, setCurMedia] = useState(newmedia)
	const [uploadingState, setUploadingState] = useState(-1)

	const handleCloseMedia = () => setShowMediaEdit(false);

	const handleSaveMedia = async () => {
		console.log("START SAVE")

		const cb_set_download = async (url, tracker) => {
			console.log(url)
			curMedia.url = url
			curMedia.tracker = tracker
			curMedia.file = ''
			curMedia.section = section
			//					curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
			const ret_data = await addSystemDocument(curMedia)
			console.log(ret_data)
			setShowMediaEdit(false);
			functions.edit(ret_data, is_new)
		}

		const cb_progress = (status, byte, total) => {
			console.log(status, byte, total)
			try {
				setUploadingState(100 * byte / total)
			} catch (e) {
				console.warn("handleSaveMedia - error: ", handleSaveMedia)
			}
		}


		if (is_new) {
			console.log(curMedia)
			if (curMedia.type !== 'link') {

				if (curMedia.file) {

					console.log(curMedia.file)
					const mypath = contractId + '/' + section
					console.log(mypath)

					if (element && element.id) {
						const mediainfo = { type: curMedia.type, description: curMedia.description, source_name: curMedia.file[0].name, section: section, item_reference: {} }
						let file_data = uploadFileComplete(domain, contractId, mypath, curMedia.file[0], curMedia.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_set_download })
						console.log('file_data', file_data)

					} else {
						const mediainfo = { type: curMedia.type, description: curMedia.description, source_name: curMedia.file[0].name, section: section }
						const file_data = uploadFileComplete(domain, contractId, mypath, curMedia.file[0], curMedia.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_set_download })
						console.log('file_data', file_data)

					}
					curMedia.file = ''
					curMedia.path = mypath

				}
			} else {
				curMedia.file = ''
				curMedia.section = section
				//					curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
				const ret_data =  addSystemDocument(curMedia)
				console.log(ret_data)
				setShowMediaEdit(false);
				functions.edit(ret_data, is_new)
			}
		} else {
			if (curMedia.type !== 'link') {
				const tracker_data = { ...curMedia.tracker, item_reference: { id: element.id, name: element.name, category: element.category || '' } }
				const ret_data = await editFileTracker(domain, contractId, curMedia.tracker.id, tracker_data)
				if (ret_data.error) {

				} else {

				editSystemDocument(curMedia.id, curMedia)
				setShowMediaEdit(false);
				functions.edit(curMedia)
				}
			} else {
				editSystemDocument(curMedia.id, curMedia)
				setShowMediaEdit(false);
				functions.edit(curMedia)

			}
		}
	}


	const handleShowNewMedia = () => {
		setCurMedia(newmedia)
		setShowMediaEdit(true)
		setUploadingState(-1)
	}
	const handleShowEditMedia = (i, docindex) => {
		setCurMedia(element)
		setShowMediaEdit(true)
		setUploadingState(-1)
	}

	const onChangeHandlerMedia = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'
		}
		if (name !== undefined) {
			console.log(name, value)
			if (curMedia.description.length === 0 && curMedia.type !== 'link' && el.files) {
				setCurMedia({ ...curMedia, description: el.files[0].name, file: value })
			} else {
				setCurMedia({ ...curMedia, [name]: value })
			}
		}
	}

	const getFileLabel = (i_type) => {
		try {
			return type_dict[i_type].label
		} catch (err) {
			return i_type
		}
	}


	return (
		<>
			{is_new ?
				<Button variant="outline-primary" size="sm" onClick={() => handleShowNewMedia()}><FontAwesomeIcon icon="plus-square" />{t('products.labels.media_add')}</Button>
				:
				<Button size="sm" variant="info" onClick={() => handleShowEditMedia()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}




			<Modal show={showMediaEdit} className="border-primary bg-secondary" size="lg">
				<Modal.Header >
					{is_new && <Modal.Title>{t('products.modal.titlenewmedia')} </Modal.Title>}
					{!is_new && <Modal.Title>{t('products.modal.titleeditmedia')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.title')}</Form.Label>
							<Col>
								<Form.Control type="text" className="mb-2" value={curMedia.description} name="description" onChange={(event) => onChangeHandlerMedia(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
							<Col>
								<Form.Control as="select" value={curMedia.type} name="status" className="mb-2" onChange={(event) => onChangeHandlerMedia({ name: 'type', type: 'select', value: event.target.value })}  >

									{Object.keys(type_dict).map((td, k) => (
										<option key={k} value={type_dict[td].value}>{type_dict[td].label} </option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						{curMedia.type === 'link' ?
							<>
								<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
									<Form.Label column sm="2">{t('global.labels.url')}</Form.Label>
									<Col>
										<Form.Control type="text" className="mb-2" defaultValue={'http://'} value={curMedia.url} name="url" onChange={(event) => onChangeHandlerMedia(event.target)} />
										<Form.Text className="text-muted">
											inserire l'url completo
										</Form.Text>
									</Col>
								</Form.Group>
							</>



							: <>
								{is_new ?
									<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
										<Form.Label column sm="2">{t('global.labels.media_file')}</Form.Label>
										<Col>
											<Form.Control type="file" name="file" onChange={(event) => onChangeHandlerMedia(event.target)} />
										</Col>
									</Form.Group>
									:
									<Row>
										<Col sm="2">
											{t('global.labels.media_file')}
										</Col>
										<Col>
											{curMedia.tracker?  curMedia.tracker.source_name:''}
										</Col>
									</Row>
								}
								{uploadingState > -1 ?
									<Row>
										<Col>
											<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
										</Col>
									</Row>
									: <></>}
							</>}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseMedia}> {t('modal.close')}</Button>
						<Button className="btn-myprimary" form="mediaForm" onClick={handleSaveMedia}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



export default translate()(ModalAdminUploader)