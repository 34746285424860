import {firestore} from '../firebaseIndex'

export const addCatalogProduct = async (contractId, catalogName, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
        .doc("catalog")
        .collection('contracts')
            .doc(contractId)
            .collection(catalogName)
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addCatalogProduct error: ", e)
        return { error: e }
    }
}

export const getCatalogProductById = async (contractId, catalogName, catalogproductId) => {
    try {
        const res = await firestore.collection('DOMAINS')
        .doc("catalog")
        .collection('contracts')
            .doc(contractId)
            .collection(catalogName)
            .doc(catalogproductId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getCatalogProduct error: ", e)
        return { error: e }
    }
}

export const getCatalogProductList = async (contractId, catalogName, category) => {
    try {
        if (category === null) {
            const res = await firestore.collection('DOMAINS')
            .doc("catalog")
            .collection('contracts')
                .doc(contractId)
                .collection(catalogName)
                .get();

            const catalogproductList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return catalogproductList;
        } else {
            const res = await firestore.collection('DOMAINS')
            .doc("catalog")
            .collection('contracts')
                .doc(contractId)
                .collection(catalogName)
                .where('category', 'array-contains', category)
                .get();

            const catalogproductList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return catalogproductList;
        }
    } catch (e) {
        console.log("getCatalogProductList error: ", e)
        return { error: e }
    }
}


export const editCatalogProduct = async (contractId, catalogName, catalogproductId, data) => {
    try {
        const catalogproduct = await firestore.collection('DOMAINS')
        .doc("catalog")
        .collection('contracts')
            .doc(contractId)
            .collection(catalogName)
            .doc(catalogproductId);

        const res = await catalogproduct.set(data)
            .then( () => {
                console.log("editCatalogProduct - edit successfully")
                return data
            }).catch( error => {
                console.log("editCatalogProduct - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editCatalogProduct error: ", e)
        return { error: e }
    }
}


export const deleteCatalogProduct = async (contractId, catalogName, catalogproductId) => {
    try {
        const res = await firestore.collection('DOMAINS')
        .doc("catalog")
        .collection('contracts')
            .doc(contractId)
            .collection(catalogName)
            .doc(catalogproductId).delete();
        return res
    }  catch (e) {
        console.log("deleteCatalogProduct error: ", e)
        return { error: e }
    }
}




