import {firebase, firestore} from '../firebaseIndex'



export const addTrainer = async (domainId, contractId, data ) => {
    console.log(domainId, contractId, data)
    try {
        let db_data = {...data, "createdAt": firebase.firestore.Timestamp.now()};


        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainers')
            .add(db_data);
        const newdata = {
            ...db_data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addTrainer error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTrainerById = async (domainId, contractId, trainerId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
			.collection('trainers')
			.doc(trainerId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getTrainer error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTrainerList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainers')
            .get();

        const trainerList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return trainerList;  
    } catch (e) {
        console.log("getTrainer error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editTrainer = async (domainId, contractId, trainerId, data) => {
    try {
        const trainer = firestore.collection('DOMAINS')
            .doc(domainId)  
            .collection('contracts')
            .doc(contractId)
            .collection('trainers')
            .doc(trainerId);

        const res = await trainer.set(
            data
        ).then( () => {
			console.log("editTrainer - edit successfully")
			return data
		}).catch( error => {
			console.log("editTrainer - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editTrainer error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteTrainer = async (domainId, contractId, trainerId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainers')
            .doc(trainerId).delete();
        return res
    }  catch (e) {
        console.log("deleteTrainer error: ", e)
        return { error: e }
    }
}