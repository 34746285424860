export const getObjectType_list = (props) => {
    const t = props.t
    const mylist =  {
		risk_areas: { name: 'risk_areas', label_multi: t('objecttype.labels.risk_areas'), label_one: t('objecttype.labels.risk_area') },
		referent_role: { name: 'referent_role', label_multi: t('objecttype.labels.referent_roles'), label_one: t('objecttype.labels.referent_role') },
		roles: { name: 'roles', label_one: t('objecttype.labels.management_role'), label_multi: t('objecttype.labels.management_roles') },
		operational_role: { name: 'operational_role', label_multi: t('objecttype.labels.operational_roles'), label_one: t('objecttype.labels.operational_role') },
		annotation_types: { name: 'annotation_types', label_one: t('objecttype.labels.annotation_type'), label_multi: t('objecttype.labels.annotation_types') },
		study_titles: { name: 'study_titles', label_one: t('objecttype.labels.study_title'), label_multi: t('objecttype.labels.study_titles') },
		floorings: { name: 'floorings', label_one: t('objecttype.labels.flooring'), label_multi: t('objecttype.labels.floorings') },
		packs: { name: 'packs', label_one: t('objecttype.labels.pack'), label_multi: t('objecttype.labels.packs') },
		packages: { name: 'packages', label_one: t('objecttype.labels.package'), label_multi: t('objecttype.labels.packages') },
		element_status: { name: 'element_status', label_one: t('objecttype.labels.element_status'), label_multi: t('objecttype.labels.element_status') },
		brands: { name: 'brands', label_one: t('objecttype.labels.brand'), label_multi: t('objecttype.labels.brands') },
		product_category: { name: 'product_category', label_one: t('objecttype.labels.product_category'), label_multi: t('objecttype.labels.product_categorys') },
		dpi_category: { name: 'dpi_category', label_one: t('objecttype.labels.dpi_category'), label_multi: t('objecttype.labels.dpi_categorys') },
		clothing_category: { name: 'clothing_category', label_one: t('objecttype.labels.clothing_category'), label_multi: t('objecttype.labels.clothing_categorys') },
		equipment_category: { name: 'equipment_category', label_one: t('objecttype.labels.equipment_category'), label_multi: t('objecttype.labels.equipment_categorys') },
		machine_category: { name: 'machine_category', label_one: t('objecttype.labels.machine_category'), label_multi: t('objecttype.labels.machine_categories') },
		cleaningmaterial_category: { name: 'cleaningmaterial_category', label_one: t('objecttype.labels.cleaningmaterial_category'), label_multi: t('objecttype.labels.cleaningmaterial_categorys') },
		cleaningmaterial_composition: { name: 'cleaningmaterial_composition', label_one: t('objecttype.labels.cleaningmaterial_composition'), label_multi: t('objecttype.labels.cleaningmaterial_compositions') },
		equipment_material: { name: 'equipment_material', label_one: t('objecttype.labels.equipment_material'), label_multi: t('objecttype.labels.equipment_materials') },
		qualification_title: { name: 'qualification_title', label_one: t('objecttype.labels.qualification_title'), label_multi: t('objecttype.labels.qualification_titles') },
		dilution_system: { name: 'dilution_system', label_one: t('objecttype.labels.dilution_system'), label_multi: t('objecttype.labels.dilution_systems') },
		product_colorcode: { name: 'product_colorcode', label_one: t('objecttype.labels.product_colorcode'), label_multi: t('objecttype.labels.product_colorcodes') },
    }
    return mylist
}
/*
rmoved elements
		floors: { name: 'floors', label_one: t('objecttype.labels.floor'), label_multi: t('objecttype.labels.floors') },
		permission_tags: { name: 'permission_tags', label_one: t('objecttype.labels.permission_tag'), label_multi: t('objecttype.labels.permission_tags') },
		workarea: { name: 'workarea', label_multi: t('objecttype.labels.workareas'), label_one: t('objecttype.labels.workarea') },
		rooms: { name: 'rooms', label_one: t('objecttype.labels.room'), label_multi: t('objecttype.labels.rooms') },
		pavillons: { name: 'pavillons', label_one: t('objecttype.labels.pavillon'), label_multi: t('objecttype.labels.pavillons') },
		departments: { name: 'departments', label_one: t('objecttype.labels.department'), label_multi: t('objecttype.labels.departments') },
		group_locations: { name: 'group_locations', label_one: t('objecttype.labels.group_location'), label_multi: t('objecttype.labels.group_locations') },


*/