import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputGroup, Container, FormControl, Row, Col, Button, ButtonGroup, Spinner, Image } from 'react-bootstrap';
//import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import { translate } from 'react-polyglot'
import { format, subDays } from 'date-fns'
//import CurrencyInput from 'react-currency-input-field';
//import "@nateradebaugh/react-datetime/scss/styles.scss";
import { compareName, myTimestampToDate } from "../util/ui_utils.js"

import PageTitle from "../components/PageTitle"
import SearchSection from "../components/SearchSection"
import ModalRoomTypeImage from '../components/modal/ModalRoomTypeImage'


import { getDomainList as getDomainList2 } from '../Firebase2/Storage2/dbDomain';
import { getSystemGroupList as getSystemGroupList2 } from '../Firebase2/Storage2/dbGroup';





const contractId = sessionStorage.getItem('contractId')

const newroomtypeimage = {
	name: '',
	code: '',
	logo: '',
	basemail: '',
	/* 	startDate: firebase.firestore.Timestamp.now(),
		endDate: firebase.firestore.Timestamp.now(),
		creation_date: firebase.firestore.Timestamp.now(), */
}



const RoomTypeImageView = ({ mylocation, t }) => {

	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [curElement, setCurrentElement] = useState(newroomtypeimage)
	const [searchTerm, setSearchTerm] = useState('')

	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')

		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}


	const handleChangeSearch = (s) => {
		console.log(s)
		if (s.value.length > 0) {
			setSearchTerm(s.value)
		} else {
			setSearchTerm('')
		}
	}

	const [ui_list, setUiList] = useState({})



	const initValues = async () => {
		setLoading(true)
		let domainlist = await getDomainList2(contractId)
		let glist = await getSystemGroupList2()
		let group_dict = {}
		glist.map(g => (group_dict[g.id] = g))
		domainlist = domainlist.filter(c => c.domain !== 'news' && c.domain !== 'catalog')
		domainlist.sort(compareName)
		setElementList(domainlist)
		setLoading(false)
	}

	useEffect(() => {
		initValues()
	}, [])

	return (
		<div>
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col className="h3">
						<PageTitle title={t('global.pages.roomtypeimages')} admin={true} />
					</Col>
					<Col sm={4}>
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<ModalRoomTypeImage is_new={true} t={t} item={element_list} setItem={setElementList} ui_list={ui_list} />
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="1" md="1">{t('global.labels.description')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.contract_type')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.contract_scope')}</Col>
						<Col className="bg-secondary ml-1 text-center" sm="2" md="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list && element_list.length > 0 ?
					<>
						{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
							<Row key={key} className={"p-1 border-bottom" + (i.domain === 'admin' ? ' bg-danger text-light' : '')}>
								<Col>
									{i.description}
								</Col>
								<Col>
								<Row>
									{i.media && i.media.length > 0
										?
										<Image style={{ maxWidth: '50px' }} src={i.media} fluid className="mr-1" />
										: ''}
								</Row>
								</Col>
								<Col className="text-center" sm="2" md="1">
									<ButtonGroup>
										<ModalRoomTypeImage t={t} item={i} setItem={onChangeHandler} ui_list={ui_list} item_list={element_list} setParentList={setElementList} index={key}  />
									</ButtonGroup>
								</Col>

							</Row>
						))}
					</>
					: <> {t('licence.messages.no_roomtypeimage')}
					</>
				}
			</Container>
		</div>
	);
}


export default translate()(RoomTypeImageView)


