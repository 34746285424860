import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, FormControl, Row, Col, Form, Button, Tab, Nav, InputGroup, Spinner } from 'react-bootstrap';
import LocaleContext from '../components/contexts/locale.js'
import ListStructureGroupItem from "../components/ListStructureGroupItem.js"
import { translate } from 'react-polyglot'
import { getSelectedElement, checkComponentPresence, getElementCount } from "../util/ui_utils.js"
import ModalStructureGroupItem from "../components/ModalStructureGroupItem"

import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"


import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { addCleaningCompany as addCleaningCompany2, 
	editCleaningCompany as editCleaningCompany2, 
	getCleaningCompanyList as getCleaningCompanyList2 } from "../Firebase2/Storage2/dbCleaningCompany"
	
import DomainContext from '../components/contexts/domainContext'


const element_list = [];

const contractId = sessionStorage.getItem('contractId')


const newcleaningcompany = {
	name: '',
	code: '',
	vat: '',
	cf: '',
	address: {
		street: '',
		number: '',
		zip: '',
		city: '',
		state: ''
	},
	phone_list: [],
	phone: '',
	mobilephone: '',
	fax: '',
	email: '',
	pec: '',
	website: '',
	logo: '',
	referent_list: [],
	bank: '',
	payment_mode: '',
	sdi: '',
	note: '',
	supply_list: [],
	structure_list: []
}
const referent_role_list = [
	{ Id: 1, name: { en: 'titolare', it: 'titlare' } },
	{ Id: 2, name: { en: 'direttore ', it: 'direttore commerciale' } }
]
const CleaningCompanyView = ({ t, uData }) => {
	const { domain } = useContext(DomainContext)

	//const { locale } = useContext(LocaleContext)
	const [showEdit, setShow] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [curElement, setCurrentElement] = useState(newcleaningcompany)
	const [element_list, setElementList] = useState([])
	const [loading, setLoading] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const [editedElement, setEditedElement] = useState(false)
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('cleaningcompanies') !== -1)) {
			setWritePage(true)
		}
		const newelement_list = await getCleaningCompanyList2(domain, contractId)
		setElementList(newelement_list)
	}


	useEffect(() => {
		setLoading(true)
		initValues()
	}, [])

	
	useEffect(() => {
		setLoading(false)
	}, [element_list])

	const handleClose = () => setShow(false);


	const handleSave = async () => {
		if (newEdit) {
			let return_data = await addCleaningCompany2(domain, contractId,  curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				//childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const newlist = [...element_list]
				newlist.push(return_data)
				setElementList(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editCleaningCompany2(domain, contractId, curElement.id, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				//childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const newlist = [...element_list]
				const c = checkComponentPresence(curElement, element_list, 'id')
				newlist.splice(c, 1, curElement)
				setElementList(newlist)
				setShow(false);
			}
		}
	}


	const handleShowNew = () => {
		setCurrentElement(newcleaningcompany)
		setNew(true)
		setShow(true)
		setEditedElement(false)
	}


	const handleShowEdit = (el) => {

		setCurrentElement(el)
		setNew(false)
		setShow(true)
		setEditedElement(false)
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')

		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	return (
		<div>
			<Container fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('navs.registries.cleaningcompanies')} />
					</Col>
					<Col sm="2">

						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite?
						<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' />{t('cleaningcompany.buttons.add_new')}</Button>
						:''}
					</Col>
				</Row>
			
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list && element_list.length > 0 ?
					<>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ml-1">{t('global.labels.name')}</Col>
							<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element_list.map((i, key) => (
							<Row key={key} className="oneCompany p-1 border-bottom">
								{console.log('data', i)}
								<Col><b>{i.name}</b></Col>
								<Col className="text-center" sm="1">
									<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit(i)}><FontAwesomeIcon icon="pencil-alt" /></Button>
									{canWrite?
									<Button variant="danger" size="sm"><FontAwesomeIcon icon='trash' /> </Button>
									:''}
								</Col>

							</Row>
						))}
					</>
					: <> <Row><Col>
						 {t('company.messages.no_company')}
					</Col></Row>
					</>
				}
			
			
			

				<Modal show={showEdit} onHide={handleClose} size="xl">
					<Modal.Header closeButton className={editedElement? "bg-warning" : ""} >
						{newEdit && <Modal.Title variant="primary">{t('cleaningcompany.modal.titlenew')} {editedElement? <FontAwesomeIcon icon="save" />:'' }</Modal.Title>}
						{!newEdit && <Modal.Title variant="info">{t('cleaningcompany.modal.titleedit')} {editedElement? <FontAwesomeIcon icon="save" />:'' }</Modal.Title>}
					</Modal.Header>
					<Modal.Body>
						<Container>

						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3} lg="2">
									<Nav variant="pills" className="flex-column">
										<Nav.Item variant="secondary">
											<Nav.Link eventKey="tabDetails">{t('global.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="tabReferent">{t('suppliers.labels.referent')}</Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabReferent">
											<ElementFormReferent element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						{canWrite?
						<Button className={editedElement? "btn-warning" : "btn-primary"} form="cleaningcompanyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						:''}
					</Modal.Footer>
				</Modal>
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('navs.registries.cleaningcompanies') })} />
		</div>
	);
}

export default translate()(CleaningCompanyView)


function ElementFormDetails({ element, functions, t }) {
	const newphone = {
		number: '',
		description: ''
	}

	const handleAddNewPhone = () => {
		let newphone_list = [...element.phone_list]
		newphone_list.push(newphone)
		functions.edit({ name: 'phone_list', value: newphone_list })
	}
	const handleEditPhone = (el, index) => {
		let editlist = [...element.phone_list]
		let { name, value, type } = el
		let editphone = { ...editlist[index], [name]: value }
		editlist.splice(index, 1, editphone)
		functions.edit({ name: 'phone_list', value: editlist })
	}
	const handleDeletePhone = (index) => {
		let editlist = element.phone_list
		console.log('index old', index, editlist)
		editlist.splice(index, 1)
		functions.edit({ name: 'phone_list', value: editlist })
	}
	return (
		<>
			<Form.Group as={Row} id="formSupplierBusinessname" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.businessname')} </Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="name" defaultValue={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierCode" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.code')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="code" defaultValue={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierVat" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.vat')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="vat" defaultValue={element.vat} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierCf" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.cf')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="cf" defaultValue={element.cf} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="supplierAddress" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.address')} </Form.Label>
				<Col>
					<Row>
						<Col>
							<Form.Control type="text" sub="address" controlId="supplierStreet" name="street" defaultValue={element.address.street} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.street')}
							    </Form.Text>
						</Col>
						<Col sm="2">
							<Form.Control type="text" sub="address" controlId="supplierZip" name="zip" value={element.address.zip}onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.zip')}
							    </Form.Text>
						</Col>
					</Row>
					<Row>
						<Col >
							<Form.Control type="text" sub="address" controlId="supplierCity" name="city" value={element.address.city}  onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							    </Form.Text>
						</Col>
						<Col sm="2">
							<Form.Control type="text" sub="address" controlId="supplierState" name="state" value={element.address.state} placeholder="State" onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							    </Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formcleaningcompanyCF" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.phone')}</Form.Label>
				{element.phone_list && element.phone_list.length > 0
					?
					<Col>
						<Row>
							<Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleAddNewPhone()}><FontAwesomeIcon icon="plus-square" />{t('global.labels.phone_add')}</Button></Col>
						</Row>
						{element.phone_list.map((i, key) => (
							<Row key={key}>
								<Col sm="4">
									<Form.Control type="text" name="description" defaultValue={i.description} onChange={(event) => handleEditPhone(event.currentTarget, key)} />
									<Form.Text className="text-muted">
										{t('global.labels.description')}
							    </Form.Text>
								</Col>
								<Col sm="4">
									<Form.Control type="text" name="number" defaultValue={i.number} onChange={(event) => handleEditPhone(event.currentTarget, key)} />
									<Form.Text className="text-muted">
										{t('global.labels.number')}
							    </Form.Text>
								</Col>
								<Col>
									{key === element.phone_list.length - 1 ?
										<Button variant="outline-danger" size="sm" onClick={() => handleDeletePhone(key)}><FontAwesomeIcon icon="trash" /></Button>
										: <> </>
									}
								</Col>
							</Row>
						))}
					</Col>
					: <> <Col> {t('global.messages.no_phone')}</Col>
						<Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleAddNewPhone()}><FontAwesomeIcon icon="plus-square" />{t('global.labels.phone_add')}</Button></Col>
					</>
				}
			</Form.Group>
			<Form.Group as={Row} controlId="formcleaningcompanyCF" className="border-bottom">
				<Form.Label column sm="2">Email</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="email" defaultValue={element.email} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formcleaningcompanyCF" className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.website')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" placeholder="www.mywebsite.it" name="website" defaultValue={element.website} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formcleaningcompanyCF" className="border-bottom">
				<Form.Label column sm="2">Pec</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="pec" defaultValue={element.pec} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formcleaningcompanyCF" className="border-bottom">
				<Form.Label column sm="2">SDI</Form.Label>
				<Col sm="8">
					<Form.Control type="text"  name="sdi" defaultValue={element.sdi} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formcleaningcompanyCF" className="border-bottom">
				<Form.Label column sm="2">Logo</Form.Label>
				<Col sm="8">
					<Form.Control type="file" placeholder="CF Number" name="logo" />
					<Form.Text className="text-muted">
						{t('global.labels.logo_size')} 84x285px
							    </Form.Text>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formcleaningcompanyNote" className="border-bottom">
				<Form.Label column sm="2">Note</Form.Label>
				<Col sm="8">
					<Form.Control type="text"  name="cf" defaultValue={element.note} />
				</Col>
			</Form.Group>

		</>
	)
}

function ElementFormReferent({ element, functions, t }) {

	const { locale } = useContext(LocaleContext)
	const newreferent = {
		Id: 0,
		firstname: '',
		lastname: '',
		phone: '',
		mobilephone: '',
		fax: '',
		email: '',
		role: {}
	}
	const [showReferentEdit, setShowReferentEdit] = useState(false)
	const [newReferentEdit, setNewReferentEdit] = useState(true)
	const [curReferent, setCurReferent] = useState(newreferent)
	//----referent
	const handleCloseReferent = () => setShowReferentEdit(false);
	const handleSaveReferent = () => {
		if (newReferentEdit) {
			let newreferent_list = element.referent_list
			newreferent_list.push(curReferent)
			console.log(newreferent_list)
			functions.edit({ name: 'referent_list', value: newreferent_list })
		} else {
			let editlist = element.referent_list
			const c = checkComponentPresence(curReferent, element.referent_list)
			editlist.splice(c, 1, curReferent)
			functions.edit({ name: 'referent_list', value: editlist })
		}
		setShowReferentEdit(false);
	}
	const handleDeleteReferent = (el) => {
		console.log(el)
		let editlist = element.referent_list
		const c = checkComponentPresence(el, element.referent_list)
		editlist.splice(c, 1)
		functions.edit({ name: 'referent_list', value: editlist })
	}
	const handleShowNewReferent = () => {
		setCurReferent(newreferent)
		setNewReferentEdit(true)
		setShowReferentEdit(true)
	}
	const handleShowEditReferent = (i) => {
		console.log('edit', i)
		setCurReferent(i)
		setNewReferentEdit(false)
		setShowReferentEdit(true)
	}
	const onChangeHandlerReferent = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurReferent({ ...curReferent, [name]: value })
	}
	return (
		<>
			<Row>

				<Col><Button variant="outline-primary" size="sm" onClick={() => handleShowNewReferent()}><FontAwesomeIcon icon="plus-square" />{t('suppliers.labels.referent_add')}</Button></Col>
			</Row>
			<Row className="border-bottom  border-right mb-1">
				{element.referent_list && element.referent_list.length > 0 ?
					<Col>
						<Row >
							<Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
							<Col className="bg-secondary text-light ml-1">{t('suppliers.labels.role')} </Col>
							<Col className="bg-secondary text-light ml-1">&nbsp;</Col>
						</Row>
						{element.referent_list.map((i, key) => (
							<Row key={key}>
								<Col>{i.firstname}</Col>
								<Col>
									{i.role.name[locale]}
								</Col>
								<Col>
									<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditReferent(i)} /> </Button>
									<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteReferent(i)} /> </Button>
								</Col>
							</Row>))
						}
					</Col>
					: <Col> {t('suppliers.messages.no_referent')}</Col>
				}
			</Row>
			<Modal show={showReferentEdit} onHide={handleCloseReferent}>
				<Modal.Header closeButton>
					{newReferentEdit && <Modal.Title>{t('suppliers.labels.new_referent')}</Modal.Title>}
					{!newReferentEdit && <Modal.Title>{t('suppliers.labels.edit_referent')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="firstname" value={curReferent.firstname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierLastName" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.lastname')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" placeholder="Enter last name" name="lastname" value={curReferent.lastname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="2">{t('supliers.labels.referent.role')} </Form.Label>
							<Col sm="8">
								<Form.Control as="select" className="mb-2" defaultValue={curReferent.role.Id} name="role" onChange={(event) => onChangeHandlerReferent({ name: 'role', type: 'select', value: getSelectedElement(event.target.value, referent_role_list) })} >
									<option value="-1" >{t('equipments.labels.select_role')}</option>
									{referent_role_list.map((i, key) => (
										<option key={key} value={i.Id}  >{i.name[locale]}</option>
									))}
								</Form.Control>

							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.phone')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" className="mb-2" name="phone" onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="2">Email</Form.Label>
							<Col sm="8">
								<Form.Control type="email" className="mb-2" name="email" onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseReferent}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="cleaningcompanyForm" onClick={handleSaveReferent}>
						<FontAwesomeIcon icon="save" />{t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>

		</>
	)
}

function ElementFormStructure({ element, functions, t }) {
	const newroom = {
		id: 7,
		code: "",
		name: "",
		//		type_name: "room",
		level: 4,
		end_item: true,
		risk_area: {},
		risk_area_color: 'red',
		mq: 10,
		interventions: [],
		room_type: {},
		environment_element_list: [],
		floor: {},
		economic_material_list: [],
		furniture_list: [],
		groups: []

	}
	const newstructure = {
		Id: 0,
		name: '',
		mq: 0,
		note: '',
		risk_area_summary: [],
		childs: []
	}
	const newgroup = {
		Id: 0,
		name: '',
		mq: 0,
		note: '',
		size:0,
		parent_list: [],
		risk_area_summary: [],
		childs: []
	}


	const [showPavillonEdit, setShowPavillonEdit] = useState(false)
	const [newSubGroupEdit, setNewPavillonEdit] = useState(true)
	const [curSubGroup, setCurSubGroup] = useState(newgroup)
	const [curGroup, setCurGroup] = useState(newgroup)
	const [curStructure, setCurStructure] = useState(newstructure)
	const handleShowNew = (el) => {
		console.log(el)
		if (el.level === 0) { // structure -> add pavvillon
			newgroup.level = 1
			setCurGroup(el)
			setCurSubGroup(newgroup)
			setNewPavillonEdit(true)
			setShowPavillonEdit(true)

		} else if (el.level === 1) {// pavillon -> add floor
			newgroup.level = 2
			setCurGroup(el)
			setCurSubGroup(newgroup)
			setNewPavillonEdit(true)
			setShowPavillonEdit(true)

		} else if (el.level === 2) {// floor -> add departments
			newgroup.level = 3
			setCurGroup(el)
			setCurSubGroup(newgroup)
			setNewPavillonEdit(true)
			setShowPavillonEdit(true)
		} else if (el.level === 3) {// departments -> add room
			setNewRoomEdit(true)
			setShowEditRoom(true)
			setCurRoom(newroom)
		}
	}
	const handleShowEditPavillon = (i) => {
		setCurSubGroup(i)
		setNewPavillonEdit(false)
		setShowPavillonEdit(true)
	}
	const [showEditRoom, setShowEditRoom] = useState(false);
	const [newRoomEdit, setNewRoomEdit] = useState(true)
	const [curRoom, setCurRoom] = useState({})
	const handleEditOneRoom = (i) => {
		setShowEditRoom(true)
		setCurRoom(i)
	}
	const handleCloseRoom = () => setShowEditRoom(false);
	const handleSaveRoom = (event) => {
		setShowEditRoom(false);
	}
	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined
		try {
			sub = el.getAttribute('sub')
		} catch{
			sub = undefined;
		}
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curRoom[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curRoom[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurRoom({ ...curRoom, [name]: value })
	}
	const handleShowEdit = (i) => {

	}
	const getGroupName = (level) => {
		if (level === 0) {
			return t('cleaningcompany.labels.structure')
		} else if (level === 1) {
			return t('global.labels.pavillon')
		} else if (level === 2) {
			return t('global.labels.floor')
		} else if (level === 3) {
			return t('global.labels.department')
		}
	}
	return (
		<>
				<Row className="p-1 text-light">
					<Col className="bg-secondary ml-1"> {t('global.labels.name')}</Col>
					<Col className="bg-secondary ml-1" sm="1"> 
                                                {t('global.labels.risk_area')}
					</Col>
					<Col className="bg-secondary ml-1" sm="1"> {t('global.labels.size')}</Col>
					<Col className="bg-secondary ml-1" sm="1"></Col>
				</Row>
			<Row><Col>
				</Col>
				<Col>
				</Col>
				<Col sm="1">
				</Col>
				<Col sm="1">
					{curStructure.size}
				</Col>
				<Col sm="1"className="text-right">
 	         	<ModalStructureGroupItem item={element} setItem={functions.edit} is_new={true} t={t} is_structure={true}/>
			</Col></Row>
			{element.childs.map((i, key) => (
				<ListStructureGroupItem key={key} item={i} is_summary={false} parent={element} setParent={setCurStructure} level={0} functions={{ show: handleShowEdit, add: handleShowNew, editgroup: handleShowEditPavillon, editroom: handleEditOneRoom }} />
			))}
		</>
	)
}
function ElementFormStructureDetails({ element, functions, t }) {
	return (
		<>
			<Form.Group as={Row} controlId="formStructureCode" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.code')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" defaultValue={element.code} name="code" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')} </Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="name" defaultValue={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierNote" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="note" defaultValue={element.note} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}
function ElementFormRoomDetails({ element, functions, t }) {
	const { locale } = useContext(LocaleContext)
	return (
		<>
			<Form.Group as={Row} controlId="formStructureCode" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.code')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" defaultValue={element.code} name="code" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.structure')} </Form.Label>
				<Col sm="8">
					<Form.Control as="select" className="mb-2" defaultValue="1" name="role" onChange={(event) => functions.edit(event.target)} >
						<option value="1" checked={(element.structure === "-1")}>{t('structure.labels.select_structure')}</option>
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.roomtype')} </Form.Label>
				<Col sm="8">
					<Form.Control as="select" className="mb-2" defaultValue="1" name="roomtype" onChange={(event) => functions.edit(event.target)} >
						<option value="1" checked={(element.roomtype === "-1")}>{t('structure.labels.select_roomtype')}</option>
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				<Form.Label column sm="2">
                                                {t('global.labels.risk_area')}
					</Form.Label>
				<Col sm="8">
					<Form.Control as="select" className="mb-2" defaultValue="1" name="risk_area" onChange={(event) => functions.edit(event.target)} >
						<option value="1" checked={(element.risk_area === "-1")}>{t('structure.labels.select_risk_area')}</option>
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureCode" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.size')}</Form.Label>
				<Col sm="8">
					<InputGroup className="mb-2">
						<FormControl type="number" min="0" step="0.01" defaultValue={element.size} name="size" onChange={(event) => functions.edit(event.currentTarget)} />
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon1"> {t('structure.labels.mq')} </InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureCF" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.decommissioned')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="is_decommissioned" value={true} checked={element.is_decommissioned === true} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="is_decommissioned" value={false} checked={element.is_decommissioned === false} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierNote" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="note" defaultValue={element.note} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}
