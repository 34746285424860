import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Table, Button, Modal, Form } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { getMatrix } from './StructureGraphicElements';
import { checkStrengthType_dict } from './constants/global_definitions';











const matrix_list = [
	{ min_index: 2, max_index: 8, level1: 2, level2: 2, level3: 3, lqa4: 0, lqa6: 0, lqa10: 0, pqa: 0, pql: 0 },
	{ min_index: 9, max_index: 15, level1: 2, level2: 3, level3: 5, lqa4: 0, lqa6: 0, lqa10: 0, pqa: 0, pql: 0 },
	{ min_index: 16, max_index: 25, level1: 3, level2: 5, level3: 8, lqa4: 0, lqa6: 0, lqa10: 1, pqa: 91.90, pql: 52.80 },
	{ min_index: 26, max_index: 50, level1: 5, level2: 8, level3: 13, lqa4: 0, lqa6: 1, lqa10: 2, pqa: 96.10, pql: 55.20 },
	{ min_index: 51, max_index: 90, level1: 5, level2: 13, level3: 20, lqa4: 1, lqa6: 2, lqa10: 3, pqa: 96.60, pql: 42.10 },
	{ min_index: 91, max_index: 150, level1: 8, level2: 20, level3: 32, lqa4: 2, lqa6: 3, lqa10: 5, pqa: 98.80, pql: 41.60 },
	{ min_index: 151, max_index: 280, level1: 13, level2: 32, level3: 50, lqa4: 3, lqa6: 5, lqa10: 7, pqa: 98.80, pql: 21.20 },
	{ min_index: 281, max_index: 500, level1: 20, level2: 50, level3: 80, lqa4: 5, lqa6: 7, lqa10: 10, pqa: 99.00, pql: 32.80 },
	{ min_index: 501, max_index: 1200, level1: 32, level2: 80, level3: 125, lqa4: 7, lqa6: 10, lqa10: 14, pqa: 98.80, pql: 0.80 },
	{ min_index: 1201, max_index: 3200, level1: 50, level2: 125, level3: 200, lqa4: 10, lqa6: 14, lqa10: 21, pqa: 99.00, pql: 0.10 },
	{ min_index: 3201, max_index: 10000, level1: 80, level2: 200, level3: 315, lqa4: 14, lqa6: 21, lqa10: 29, pqa: 99.00, pql: 0.00 }

]

const CheckMatrix = ({ t, uData }) => {


	const [selectedLevel, setSelectedLevel] = useState(2)
	const [selectedLqa, setSelectedLqa] = useState(10)
	const [selectedCount, setSelectedCount] = useState(-1)
	const [selectedRow, setSelectedRow] = useState(-1)
	const [showCheckMatrix, setShowCheckMatrix] = useState(false)
	const handleCloseMatrix = () => {
		setShowCheckMatrix(false)
	}

	const [selectedStrengthMode, setSelectedStrengthMode] = useState('ordinary')


	const strengthType = checkStrengthType_dict({ t })
	const matrix_data = getMatrix({ global: true })


	const onChangeSelected = (level, row) => {
		setSelectedLevel(level)
		setSelectedCount(matrix_data.level_matrix[row][`level${level}`])
	}

	const getClassName = (level, count, c_level, c_count) => {
		if (level === c_level && count === c_count) {
			return 'bg-success'
		} else if (level === c_level) {
			return 'bg-warning'
		} else {
			return ''
		}



	}


	return (
		<>
			<Button className="ml-1" variant="info" size="sm" onClick={() => setShowCheckMatrix(true)}><FontAwesomeIcon icon="info-circle" /> Prospetti UNI EN13549 </Button>
			<Modal show={showCheckMatrix} onHide={handleCloseMatrix} dialogClassName="custom-modal" className="bg-secondary" backdrop="static" >
				<Modal.Header closeButton className="bg-info" >
					<Modal.Title className="text-light">
						<FontAwesomeIcon icon="info-circle" />  Prospetti UNI EN13549
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="border-info">
					<Container fluid>
						{/* <Row>
							<Col className="text-center">
								<Table bordered hover size="sm" striped>
									<thead>
										<tr>
											<th colSpan="4" rowSpan="2">&nbsp;</th>
											<th colSpan="3">LQA</th>
											<th colSpan="2" rowSpan="2">&nbsp;</th>
										</tr>
										<tr>
											<th style={{ cursor: "pointer" }} className={selectedLqa === 4 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLqa(4)}>4%</th>
											<th style={{ cursor: "pointer" }} className={selectedLqa === 6 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLqa(6)}>6.50%</th>
											<th style={{ cursor: "pointer" }} className={selectedLqa === 10 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLqa(10)}>10%</th>
										</tr>
										<tr>
											<th rowSpan="2">Numerosità del lotto</th>
											<th colSpan="3">Numerosità del campione<br />(ISO 2859-1:1999)</th>
											<th rowSpan="2" className={selectedLqa === 4 ? "bg-warning" : ''} >Ac <br /><i>(n°)</i></th>
											<th rowSpan="2" className={selectedLqa === 6 ? "bg-warning" : ''} >Ac <br /><i>(n°)</i></th>
											<th rowSpan="2" className={selectedLqa === 10 ? "bg-warning" : ''} >Ac <br /><i>(n°)</i></th>
											<th rowSpan="2">PQA</th>
											<th rowSpan="2">PQL</th>
										</tr>
										<tr>
											<th style={{ cursor: "pointer" }} className={selectedLevel === 1 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLevel(1)}>Livello 1</th>
											<th style={{ cursor: "pointer" }} className={selectedLevel === 2 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLevel(2)}>Livello 2</th>
											<th style={{ cursor: "pointer" }} className={selectedLevel === 3 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLevel(3)}>Livello 3</th>
										</tr>
									</thead>
									<tbody>
										{matrix_list.map((row, key) => (
											<tr key={key}>
												<td>{row.min_index} - {row.max_index}</td>
												<td className={selectedLevel === 1 ? "bg-warning" : ''}>{row.level1}</td>
												<td className={selectedLevel === 2 ? "bg-warning" : ''}>{row.level2}</td>
												<td className={selectedLevel === 3 ? "bg-warning" : ''}>{row.level3}</td>
												<td className={selectedLqa === 4 ? "bg-warning" : ''} >{row.lqa4}</td>
												<td className={selectedLqa === 6 ? "bg-warning" : ''} >{row.lqa6}</td>
												<td className={selectedLqa === 10 ? "bg-warning" : ''} >{row.lqa10}</td>
												<td>{row.pqa}</td>
												<td>{row.pql}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Col>
						</Row> */}
						<Form.Group as={Row} className="border-bottom mb-1">
							<Form.Label column sm="2">{t('check.labels.strengthtype')}</Form.Label>
							<Col sm="8">
								<Form.Control as="select" className="mb-2" value={selectedStrengthMode} name="receiver_group" onChange={(event) => setSelectedStrengthMode(event.currentTarget.value)} >
									{Object.keys(strengthType).map((r, rk) => (
										<option key={r} value={r} >{strengthType[r].code} - {strengthType[r].label}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						<Row>
							<Col>
							</Col>
						</Row>
						<Row className="mb-1">
							<Col className="text-center bg-secondary ml-1 text-white" sm="2">
								Min
							</Col>
							<Col className="text-center bg-secondary ml-1 text-white" sm="2">
								Max
							</Col>
							<Col>
								<Row>
									<Col className="text-center bg-secondary ml-1 text-white">
										Numerosità del campione (ISO 2859-1:1999)
									</Col>

								</Row>
								<Row className="text-center bg-secondary text-white">
									<Col className={selectedLevel === 1 ? 'bg-warning' : ''} onClick={() => setSelectedLevel(1)}>
										Livello 1
									</Col>
									<Col className={selectedLevel === 2 ? 'bg-warning' : ''} onClick={() => setSelectedLevel(2)}>
										Livello 2
									</Col>
									<Col className={selectedLevel === 3 ? 'bg-warning' : ''} onClick={() => setSelectedLevel(3)}>
										Livello 3
									</Col>
								</Row>
							</Col>
						</Row>
						{matrix_data.level_matrix.map((r, key) => (
							<Row key={r.min_index} className={selectedRow === parseInt(key) ? 'bg-warning' : 'element_row'}>
								<Col style={{ cursor: 'pointer' }} onClick={() => onChangeSelected(selectedLevel, key)} sm="2">
									{r.min_index}
								</Col>
								<Col style={{ cursor: 'pointer' }} onClick={() => onChangeSelected(selectedLevel, key)} sm="2">
									{r.max_index}
								</Col>
								<Col>
									<Row>
										<Col style={{ cursor: 'pointer' }} className={getClassName(selectedLevel, selectedCount, 1, r.level1)} onClick={() => onChangeSelected(1, key)}>
											{r.level1}
										</Col>
										<Col style={{ cursor: 'pointer' }} className={getClassName(selectedLevel, selectedCount, 2, r.level2)} onClick={() => onChangeSelected(2, key)}>
											{r.level2}
										</Col>
										<Col style={{ cursor: 'pointer' }} className={getClassName(selectedLevel, selectedCount, 3, r.level3)} onClick={() => onChangeSelected(3, key)}>
											{r.level3}
										</Col>

									</Row>
								</Col>

							</Row>
						))}
						<Row>
							<Col>
							Tipologia di verifica di conformità 
							</Col>
						</Row>
						<Row>
							<Col className="text-center bg-secondary ml-1 text-white" sm="1">Num Campioni</Col>
							<Col>
								<Row>
									<Col className="text-center bg-secondary ml-1 text-white">
										<Row>
											<Col>
												Lqa 4%
											</Col>
										</Row>
										<Row>
											<Col>
												Ac
											</Col>
											<Col>
												Pqa
											</Col>
											<Col>
												Pql
											</Col>
										</Row>
									</Col>
									<Col className="text-center bg-secondary ml-1 text-white">
										<Row>
											<Col>
												Lqa 6.5%
											</Col>
										</Row>
										<Row>
											<Col>
												Ac
											</Col>
											<Col>
												Pqa
											</Col>
											<Col>
												Pql
											</Col>
										</Row>
									</Col>
									<Col className="text-center bg-secondary ml-1 text-white">
										<Row>
											<Col>
												Lqa 10%
											</Col>
										</Row>
										<Row>
											<Col>
												Ac
											</Col>
											<Col>
												Pqa
											</Col>
											<Col>
												Pql
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
						{Object.keys(matrix_data.matrix[selectedStrengthMode]).map((ok, key) => (
							<Row key={ok} className={parseInt(selectedCount) === parseInt(ok) ? 'bg-warning' : 'element_row'}>
								<Col sm="1">
									{ok}
								</Col>
								<Col>
									<Row>

										{Object.values(matrix_data.matrix[selectedStrengthMode][ok]).map((row, ok) => (
											<Col key={ok}>
												<Row>
													<Col>
														{row.ac}
													</Col>
													<Col>
														{row.pqa}%
													</Col>
													<Col>
														{row.pql}%
													</Col>
												</Row>
											</Col>
										))}
									</Row>
								</Col>
							</Row>
						))}

					</Container>
				</Modal.Body>
				<Modal.Footer className="bg-info">
					<Button variant="secondary" onClick={handleCloseMatrix}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default translate()(CheckMatrix)

