import { firebase, storageRef } from '../firebaseIndex'
import {firestore} from '../firebaseIndex'


export const uploadFileComplete = async (domainId, contractId, dir, filedata, _contentType,  fileInfo, {cbProgress, cbSetDownload}) => {
    const fileId = await getFileTrackerEmptyId(domainId, contractId)
    const path = dir + "/" + fileId

    const localCbSetDownload = async (url) => {
        const newdata = {
                ...fileInfo,
                id: fileId,
                path: path,
                url: url
            }
        const tracker = await addFileTrackerWithId(domainId, contractId, fileId, newdata )
        console.log("uploadFileComplete: ----", tracker)
        if(cbSetDownload){
            cbSetDownload(url, tracker)    
        }
    }

    uploadFile( path, filedata, _contentType, {cbProgress: cbProgress, cbSetDownload: localCbSetDownload})
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  FIREBASE STORAGE 
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const uploadFile = async (path, filedata, _contentType, {cbProgress, cbSetDownload}) => {
    const metadata = {
        contentType: _contentType,
    };
    

    // Upload the file and metadata
    var uploadTask = storageRef.child(path).put(filedata, metadata);

    var curProgress = [0, 0]

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed', function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        curProgress = [snapshot.bytesTransferred, snapshot.totalBytes]
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
        }
        if(cbProgress){
            cbProgress(snapshot.state, snapshot.bytesTransferred, snapshot.totalBytes)
        }
    }, function (error) {
        // Handle unsuccessful uploads
        if(cbProgress){
            cbProgress(firebase.storage.TaskState.ERROR, curProgress[0], curProgress[1])
        }
    }, function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('File available at', downloadURL);
            cbProgress(firebase.storage.TaskState.SUCCESS, curProgress[0], curProgress[1])
            cbSetDownload(downloadURL)
        });
    });
}


export const downloadFile = async (path, cb) => {
    // Create a reference to the file we want to download
    var starsRef = storageRef.child(path);

    // Get the download URL
    starsRef.getDownloadURL().then(function(url) {
    // Insert url into an <img> tag to "download"
        console.log(url)
        cb(url)
    }).catch(function(error) {

    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
        case 'downloadFile: storage/object-not-found':
        // File doesn't exist
        console.log("downloadFile: file doesn't exist")
        break;

        case 'storage/unauthorized':
        // User doesn't have permission to access the object
        console.log("downloadFile: User doesn't have permission")
        break;

        case 'storage/canceled':
        // User canceled the upload
        console.log("downloadFile: User canceled the upload")
        break;

        case 'storage/unknown':
        // Unknown error occurred, inspect the server response
        console.log("downloadFile: Unknown error occurred")
        break;
    }
    });
}

///////////////////////////////////////////////////////////////////////////////
//
//  File tracker on db
//
///////////////////////////////////////////////////////////////////////////////
export const getFileTrackerEmptyId = async (domainId, contractId ) => {
    try {
        const doc = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('FileTrackers')
            .doc();
        return doc.id
    } catch (e) {
        console.log("getFileTrackerEmptyId error: ", e)
        return { error: e }
    }
}


export const addFileTrackerWithId = async (domainId, contractId, fileId, data ) => {
    try {
        const doc = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('FileTrackers')
            .doc(fileId);

        const newdata = {
            ...data,
            id: doc.id,
            insered_at: firebase.firestore.Timestamp.fromDate(new Date()),
            }
        const res = await doc.set(
            newdata
        ).then( () => {
			console.log("addFileTrackerWithId - set successfully")
			return newdata
		}).catch( error => {
			console.log("addFileTrackerWithId - set error: ", error)
			return {error: error}
		});
        
        return res
    } catch (e) {
        console.log("addFileTracker error: ", e)
        return { error: e }
    }
}




export const addFileTracker = async (domainId, contractId, data ) => {
    try {
        const doc = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('FileTrackers')
            .doc();

        const newdata = {
            ...data,
            id: doc.id,
            }
        const res = doc.set(newdata);
        
        return res
    } catch (e) {
        console.log("addFileTracker error: ", e)
        return { error: e }
    }
}

export const getFileTrackerById = async (domainId, contractId, fileId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('FileTrackers')
            .get(fileId);

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getFileTrackerById error: ", e)
        return { error: e }
    }
}

export const getFileTrackerList = async (domainId, contractId, section) => {
    console.log(section)
    try {
        if (section) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('FileTrackers')
                .where('section', 'in', section)
                .get();

            const fileTrackerList = res.docs.map( doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return fileTrackerList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('FileTrackers')
                .orderBy('section')
                .get();

            const fileTrackerList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
//                console.log("getFileTrackerList: ", data)
                return data
            })
            return fileTrackerList;

        }
    } catch (e) {
        console.log("getFileTrackerList error: ", e)
        return { error: e }
    }
}


export const editFileTracker = async (domainId, contractId, fileTrackerId, data) => {
    try {
        const fileTracker = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('FileTrackers')
            .doc(fileTrackerId);

        const res = await fileTracker.set(
            data
        ).then( () => {
			console.log("editFileTracker - edit successfully")
			return data
		}).catch( error => {
			console.log("editFileTracker - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editFileTracker error: ", e)
        return { error: e }
    }
}


export const deleteFileTracker = async (domainId, contractId, fileTrackerId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('FileTrackers')
            .doc(fileTrackerId).delete();
        return res
    }  catch (e) {
        console.log("deleteFileTracker error: ", e)
        return { error: e }
    }
}
