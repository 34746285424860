import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, InputGroup, Nav, Tab, ProgressBar, Image } from 'react-bootstrap';
import { translate } from 'react-polyglot'

import firebase from 'firebase/app'
import { addDomain as addDomain2, editDomain as editDomain2 } from '../../Firebase2/Storage2/dbDomain';
import { uploadFileComplete as uploadFileComplete2 } from '../../Firebase2/Storage2/dbFileStorage';
import { contractScope_dict, contractType_dict, contractSector_dict } from '../constants/global_definitions';
import { ModalInitButton } from '../StructureGraphicElements';




function ModalRoomTypeImage({ item, setItem, t, is_new, small, index, item_list, setParentList, checkDomain, ui_list }) {

	const newroomtypeimage = {
		description: '',
		path: '',
		originalpath: [],
		contract_type_list: [],
		contract_scope_list: [],
		creation_date: firebase.firestore.Timestamp.now(),

	}

	const [showRoomTypeImageEdit, setShowRoomTypeImageEdit] = useState(false)
	const [curElement, setCurElement] = useState(newroomtypeimage)
	const [editedElement, setEditedElement] = useState(false)

	const [isInvalid, setIsInvalid] = useState(false)
	const [uploadingState, setUploadingState] = useState(0)

	const handleCloseRoomTypeImage = () => setShowRoomTypeImageEdit(false);

	const cb_progress = (status, byte, total) => {
		console.log(status, byte, total)
		try {
			setUploadingState(100 * byte / total)
		} catch (e) {
			console.warn("handleSaveMedia - error: ", e)
		}
	}
	const section = 'domain'
	const handleSaveRoomTypeImage = async () => {

		setShowRoomTypeImageEdit(false);

		if (is_new) {
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.media = url
					curElement.media_tracker = tracker
				}
				delete curElement.file
				const return_data = await addDomain2(curElement.domain, curElement)
				let newroomtypeimage_list = [...item]
				newroomtypeimage_list.push(return_data)
				console.log(newroomtypeimage_list)
				setItem(newroomtypeimage_list)
				setEditedElement(false)
			}

			if (curElement.file) {
				const mypath = curElement.domain + '/' + section
				const mediainfo = { description: 'media', source_name: curElement.file[0].name, section: section, item_reference: {} }
				let file_data = uploadFileComplete2(curElement.domain, '0', mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}

		} else {
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.media = url
					curElement.media_tracker = tracker
				}
				delete curElement.file
				let newroomtypeimage_list = [...item_list]
				newroomtypeimage_list.splice(index, 1, curElement)
				setParentList(newroomtypeimage_list)
				const return_data = await editDomain2(curElement.domain, curElement)
				setEditedElement(false)
			}
			if (curElement.file) {
				const mypath = curElement.domain + '/' + section
				const mediainfo = { description: 'media', source_name: curElement.file[0].name, section: section, item_reference: {} }
				console.log(mediainfo, curElement.file[0])
				let file_data = uploadFileComplete2(curElement.domain, '0', mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}
		}
	}


	const handleShowNewRoomTypeImage = () => {
		console.log('show new')
		setIsInvalid(false)
		setCurElement(newroomtypeimage)
		setShowRoomTypeImageEdit(true)
		setEditedElement(false)
	}


	const handleShowEditRoomTypeImage = () => {
		if (!item.color) {
			item.color = { ...newroomtypeimage.color }
		}
		setIsInvalid(false)
		setCurElement(item)
		setShowRoomTypeImageEdit(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 100)
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curElement[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'

		}
		console.log(name, value)
		setCurElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}



	return (
		<>
			<ModalInitButton t={t}  is_new={is_new} small={small} title_new={t('structure.labels.roomtypeimage_add')} action_new={handleShowNewRoomTypeImage} action_edit={handleShowEditRoomTypeImage} action_info={handleShowEditRoomTypeImage} />
			<Modal show={showRoomTypeImageEdit} onHide={handleCloseRoomTypeImage} dialogClassName="custom-modal" backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					{is_new && <Modal.Title className="text-primary">{t('licence.labels.new_roomtypeimage')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!is_new && <Modal.Title className="text-info">{t('licence.labels.edit_roomtypeimage')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey={curElement.id === 'admin' ? "tabUsers" : "tabDetails"}>
							<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler, checkDomain: checkDomain, setIsInvalid: setIsInvalid, isInvalid: isInvalid }} is_new={is_new} uploadingState={uploadingState} />
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseRoomTypeImage}>
						{t('modal.close')}
					</Button>
					<Button form="companyForm" disabled={isInvalid} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveRoomTypeImage}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, functions, ui_list, t, is_new, uploadingState }) {

    const contract_scope_dict = contractScope_dict({t})
    const contract_sector_dict = contractSector_dict({ t })
    const contract_type_dict = contractType_dict({ t })



	return (
		<>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="description" value={element.description} onChange={(event) => functions.edit(event.target)} />
				</Col>
			</Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
                <Col>
                    <Form.Check type="checkbox" name="contract_type_list" value="hospital" checked={element.contract_type_list.indexOf('hospital')>=0} label={t('contract.labels.type_hospital')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="checkbox" name="contract_type_list" value="civil" checked={element.contract_type_list.indexOf('civil')>=0} label={t('contract.labels.type_civil')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.scope')}</Form.Label>
                <Col>
				{Object.values(contract_scope_dict).map((s, sk) => (
                    <Form.Check type="checkbox" name="contract_scope_list" value={s.id} disabled={element.contract_type_list.indexOf(s.type)<0} checked={element.contract_scope_list.indexOf(s.id)>=0} label={s.label} onChange={(event) => functions.edit(event.currentTarget)} />
				))}
                </Col>
            </Form.Group>
			<Form.Group as={Row} controlId="formProductCF" className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.media')}</Form.Label>
				<Col>
					<Form.Control type="file" name="file" onChange={(event) => functions.edit(event.target)} />
				</Col>
				<Col>
					{element.media
						?
						<>

							<Image style={{ height: '50px' }} src={element.media} fluid />
							<Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'media', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
						</>
						: ''}
				</Col>
			</Form.Group>
			{uploadingState > -1 ?
				<Row>
					<Col>
						<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
					</Col>
				</Row>
				: <></>}
		</>
	)
}


export default translate()(ModalRoomTypeImage)