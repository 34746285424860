import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, InputGroup, FormControl, Row, Col, Form, Button, Tab, Nav, Spinner,  ButtonGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
//import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import LocaleContext from '../components/contexts/locale.js'
import ModalBaseObject from "../components/ModalBaseObject"
//import { format, setDay } from 'date-fns'
//import { it } from 'date-fns/locale'
//import { SketchPicker } from 'react-color';
//import reactCSS from 'reactcss'
//import "@nateradebaugh/react-datetime/scss/styles.scss";
//import CurrencyInput from 'react-currency-input-field';
import { getSelectedElement,    getMediaIcon, getElementPreview } from "../util/ui_utils.js"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ElementFormMedia from "../components/FormMedia.js"
import ModalClient from "../components/ModalClient"




import { getBaseObjectTypeList as getBaseObjectTypeList2} from "../Firebase2/Storage2/dbBaseObject"
import DomainContext from '../components/contexts/domainContext'

import firebase from 'firebase/app'




	const newclient = {
		code: '',
		startDate: firebase.firestore.Timestamp.now(),
		endDate: firebase.firestore.Timestamp.now(),
		creation_date: firebase.firestore.Timestamp.now(),
		name: '',
		logo: '',
		username: ''
	}

const ClientsView = ({ mylocation, t }) => {
	const { domain } = useContext(DomainContext)


	const childRefAlert = useRef()
	const childRefDelete = useRef()


	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [showEdit, setShow] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [curElement, setCurrentElement] = useState(newclient)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const initValues = async () => {
		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		const category_list = await getBaseObjectTypeList2(domain, 'client_category')
		setUiList({ ...ui_list, brand_list, category_list })
	//	let newelement_list = await getClientList(contractId, companyId, 'client', null)
	//	newelement_list.sort(compareName)
	//	setElementList(newelement_list)
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	useEffect(() => {
		setLoading(true)
		initValues()
	}, [])

	useEffect(() => {
		console.log('get element list')
		setLoading(false)
	}, [element_list])


	const handleClose = () => setShow(false);

	const handleSave = async () => {
/* 		if (newEdit) {
			let return_data = await addClient(contractId, companyId, 'client', curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const newlist = [...element_list]
				newlist.push(return_data)
				setElementList(newlist)

				setShow(false);
			}
		} else {
			let return_data = await editClient(contractId, companyId, 'client', curElement.id, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m=0; m<curElement.media_list.length; m++){
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.description = curElement.media_list[m].description
					editTrackData.type = curElement.media_list[m].type
					editTrackData.section ='client'
					editTrackData.item_reference = {category: curElement.category, name: curElement.name, id: curElement.id}
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker(contractId, editTrackData.id, editTrackData)
					console.log("edit media return ", return_data)
				}
				const newlist = [...element_list]
				const c = checkComponentPresence(curElement, element_list, 'id')
				newlist.splice(c, 1, curElement)
				setElementList(newlist)

				setShow(false);
			}
		} */
	}
	const handleShowEdit = (el) => {
		setCurrentElement(el)
		setNew(false)
		setShow(true)
		setEditedElement(false)
	}

	const handleDelete = (el) => {
		console.log(el)
/* 		const cb = async () => {
			let return_data = await deleteClient(contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('client.messages.delete_client_title'), t('client.messages.delete_client'),  cb) */
	}

	const onChangeHandler = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (event.checked) {
				oldcheck[value] = true;
			} else {
				//oldcheck.splice(value, 1);
				delete oldcheck[value]
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	return (
		<div>

			{/* <SubNavBarRegistry curpage={'clients'} t={t} /> */}
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid>
				<Row className="mt-1">
					<Col className="h3">
						{t('navs.registries.claudit_clients')}
					</Col>
					<Col sm="4">
						<InputGroup className="mb-3">
							<FormControl type="text" placeholder={t('search')} className="" />
							<InputGroup.Append>
								<Button variant="outline-secondary"><FontAwesomeIcon icon="search" /></Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<ModalClient is_new={true} t={t} item={element_list} setItem={setElementList} />
					</Col>
				</Row>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list && element_list.length > 0 ?
					<>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ml-1" sm="1"></Col>
							<Col className="bg-secondary ml-1" sm="2">{t('global.labels.code')}</Col>
							<Col className="bg-secondary ml-1">{t('global.labels.name')}</Col>
							<Col className="bg-secondary ml-1">{t('global.labels.start')}</Col>
							<Col className="bg-secondary ml-1">{t('global.labels.end')}</Col>
							<Col className="bg-secondary ml-1 p-0 text-center" sm="1"><FontAwesomeIcon icon='image' />&nbsp;<FontAwesomeIcon icon='file' /></Col>
							<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element_list.map((i, key) => (
							<Row key={key} className="oneCompany p-1 border-bottom" >
								<Col sm="1">{getElementPreview(i)} </Col>
								<Col sm="2">{i.code}</Col>
								<Col><b>{i.name}</b></Col>
								<Col sm="1" className="p0 text-center">{getMediaIcon(i)}</Col>
								<Col sm="1" className="text-center">
									<ButtonGroup>
										<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit(i)}><FontAwesomeIcon icon="pencil-alt" /></Button>
										<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
									</ButtonGroup>
								</Col>

							</Row>
						))}
					</>
					: <> <Row><Col>
						{t('clients.messages.no_client')}
					</Col></Row>
					</>
				}





				<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
						{newEdit && <Modal.Title variant="primary">{t('clients.modal.titlenew')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
						{!newEdit && <Modal.Title variant="info">{t('clients.modal.titleedit')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					</Modal.Header>
					<Modal.Body>
						<Container fluid>

							<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
								<Row>
									<Col sm={3}>
										<Nav variant="pills" className="flex-column">
											<Nav.Item variant="secondary">
												<Nav.Link variant="secondary" eventKey="tabDetails">{t('clients.labels.details')} </Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link variant="secondary" eventKey="tabMedia" disabled={newEdit}>{t('clients.labels.imagesdocs')} </Nav.Link>
											</Nav.Item>
										</Nav>
									</Col>
									<Col sm={9}>
										<Tab.Content>
											<Tab.Pane eventKey="tabDetails">
												<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} />
											</Tab.Pane>
											<Tab.Pane eventKey="tabMedia">
												<ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSave }} section='client' />
											</Tab.Pane>

										</Tab.Content>
									</Col>
								</Row>
							</Tab.Container>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" />{t('modal.save')}
						</Button>
					</Modal.Footer>
				</Modal>
			</Container>

		</div >
	);
}

export default translate()(ClientsView)


function ElementFormDetails({ element, functions, ui_list, t }) {
	const { locale } = useContext(LocaleContext)
	const updateBrands = (b) => {
		functions.updateData('brand_list', b)
	}
	const updateCategories = (b) => {
		functions.updateData('client_category', b)
	}

	return (
		<>
			<Form.Group as={Row} controlId="clientName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="clientCode" className="border-bottom">
				<Form.Label column sm="2">{t('clients.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formClientBrand" className="border-bottom">
				<Form.Label column sm="2">{t('clients.labels.brand')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.brand.Id} name="brand" className="mb-2" onChange={(event) => functions.edit({ name: 'brand', type: 'select', value: getSelectedElement(event.target.value, ui_list.brand_list) })}  >
						<option value="-1">{t('clients.labels.select_brand')}</option>
						{ui_list.brand_list
							? [Object(ui_list.brand_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					<ModalBaseObject is_new={true} small={true} cursection={{ name: 'brands', list: ui_list.brand_list }} t={t} functions={{ setCurSection: updateBrands }} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formClientCategory" className="border-bottom">
				<Form.Label column sm="2">{t('clients.labels.category')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.category.Id} name="category" className="mb-2" onChange={(event) => functions.edit({ name: 'category', type: 'select', value: getSelectedElement(event.target.value, ui_list.category_list) })}  >
						<option value="-1">{t('clients.labels.select_category')}</option>
						{ui_list.category_list
							? [Object(ui_list.category_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					<ModalBaseObject is_new={true} small={true} cursection={{ name: 'client_category', list: ui_list.category_list }} t={t} functions={{ setCurSection: updateCategories }} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="clientStock" className="border-bottom">
				<Form.Label column sm="2">{t('clients.labels.minstock')}</Form.Label>
				<Col sm="8">
					<Form.Control type="number" name="minstock" value={element.minvalue} min="0" className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="clientNote" className="border-bottom">
				<Form.Label column sm="2">{t('clients.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}

