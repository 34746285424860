import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Container, FormControl, Row, Col, Button, Tooltip, OverlayTrigger, Popover, ListGroup, ButtonGroup, InputGroup, Spinner, Image, Modal } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"


import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"
import DomainContext from '../components/contexts/domainContext'

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"

import PageTitle from "../components/PageTitle"
import SearchSection from "../components/SearchSection"
import Footer from "../components/Footer"
import FrequencySelector from '../components/FrequencySelector'
import ModalRoomType from '../components/ModalRoomType'

import { compareName, compareOrder, getValue, checkComponentPresence, myTimestampToDate } from "../util/ui_utils.js"
import { WeekDays, ServiceBadge, RiskareaBadge } from '../components/StructureGraphicElements.js'




import { getRoomTypeList_page, deleteRoomType as deleteRoomType2 } from '../Firebase2/Storage2/dbBaseStruct';
import { getResultQuestionList as getResultQuestionList2, } from "../Firebase2/Storage2/dbChecks"



//const contractId = sessionStorage.getItem('contractId')

const companyId = "0"

const two_row_limit = 5
const popover_mode = ['focus', 'hover']
let load_page = false

const defaultfilter = { risk_area: [], service:[] }






///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderPopover = (title, element_list) => {
	return (
		<Popover id="popover-basic" className="bg-info border border-info">
			<Popover.Title as="h3">
				{title}
			</Popover.Title>
			<Popover.Content className="bg-info">
				{element_list ?
					<ListGroup variant="flush" className={element_list.length > two_row_limit ? "d-inline-block" : "d-block"}>
						{element_list.map((r, k) => (
							<ListGroup.Item key={k} className={element_list.length > two_row_limit ?
								"p-2 border-left w-50 " + (k % 2 === 0 ? 'float-left' : 'float-right')
								: ""}>
								<span className="h6 mr-1">
									<Badge variant="warning" className="h5">{r.quantity} x</Badge>
								</span>
								{r.name}
								{r.refill_element_list && r.refill_element_list.length>0?
								<>&nbsp;(<FontAwesomeIcon icon="pump-soap" /> {r.refill_element_list.length})</>
								:<></>}
							</ListGroup.Item>
						))}
					</ListGroup>
					: ''
				}
			</Popover.Content>
		</Popover>
	)
}





///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderWarningTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Nessuna operazione associata
	</Tooltip>
);





///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function getElementCount(elem_list, filterList, searchTerm) {
	if (elem_list) {
		const filt = elem_list.filter(i => (filterList.length === 0 || checkRiskareaFilter(i, filterList)))
			.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
		if ((filterList.length === 0 && searchTerm.length < 2) || filt.length === elem_list.length) {
			return elem_list.length
		} else {
			return filt.length + "/" + elem_list.length
		}
	} else {
		return undefined

	}
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const RoomTypesView = ({ t, uData }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, service_dict, risk_area_list, risk_area_dict, operation_dict } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()
	let { cid } = useParams();
	let contractId = cid
	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})

	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)
	const [filterAppliedList, setFilterAppliedList] = useState(defaultfilter)


	const initValues = async () => {
		if (myTimestampToDate(getValue(domain_data, 'endDate')) > new Date() && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('room_types') !== -1)) {
			setWritePage(true)
		}
		if (contract && domain.length > 0) {
			setLoading(true)
			contractId = contract.id
			let global_question_list = await getResultQuestionList2(domain, contractId)
			setUiList({ ...ui_list, service_dict, operation_dict, risk_area_list, risk_area_dict, global_question_list })
			let rt_list = []
			let do_query = true
			let cursor = undefined
			while (do_query){
				const r_data = await getRoomTypeList_page(domain, contractId, cursor, 50)
				if (r_data.query_cursor!== null){  
					rt_list = [...rt_list, ...r_data.roomTypeList]
					cursor = r_data.query_cursor
				}else{
					do_query = false
				}
			}
			let  newelement_list = rt_list
			newelement_list.sort(compareName)
			console.log(newelement_list)
			setElementList(newelement_list)
			setLoading(false)


		} else {
			console.log("problem")
		}
	}




	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("ROOMTYPE reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("ROOMTYPE init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useEffect(() => {
		console.log('ROOMTYPE loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])




	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	return (
		<div>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid className="titleStickyTop">
				<Row className="mt-1">
					<Col sm="4">
						<PageTitle title={t('global.pages.room_type')} />
					</Col>
					<Col className="text-center">
						<ModalFilter t={t} ui_list={ui_list} filterAppliedList={filterAppliedList} setFilterAppliedList={setFilterAppliedList} defaultfilter={defaultfilter} e_list={element_list}/>
					</Col>
					<Col sm={1}>
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col sm={2} style={{ textAlign: 'right' }}>
						{canWrite ?
							<ModalRoomType is_new={true} setItem={setElementList} element_list={element_list} canWrite={canWrite} ui_list={ui_list} />
							: ''}
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="1">{t('global.labels.image')}</Col>
						<Col className="bg-secondary ml-1" sm="2"> {t('global.labels.room')}</Col>
						<Col className="bg-secondary ml-1" sm="1">
											{contract.type === 'civil' ?
												t('global.labels.standard_cleaning')
												:
												t('global.labels.risk_area')
											}
							</Col>
						<Col className="bg-secondary ml-1"> <Row><Col>{t('global.labels.service_list')}</Col><Col>{t('frequency.labels.frequency')}</Col></Row></Col>
						<Col className="bg-secondary ml-1" sm="2"> {t('global.labels.composition')}</Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list
									.filter(i => (filterAppliedList.risk_area.length === 0 ||
										checkRiskareaFilter(i, filterAppliedList.risk_area)) &&
										(filterAppliedList.service.length === 0 ||
										checkServiceFilter(i, filterAppliedList.service))
									)
									.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, k) => (
										<OneRoomType key={k} roomtype={i} element_list={element_list} setElementList={setElementList} ui_list={ui_list} canWrite={canWrite}
											childRefDelete={childRefDelete} t={t} childRefAlert={childRefAlert} />
									))}
							</>
							: <> <Row><Col className="font-italic">
								{t('roomtype.messages.no_roomtype')}
							</Col></Row>
							</>
						}
					</>
				}
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount(element_list, filterAppliedList.risk_area, searchTerm) + " " + t('global.labels.roomtypes')} />
		</div>
	);
}






////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneRoomType = ({ roomtype, ui_list, canWrite, setElementList, element_list, childRefDelete, t, childRefAlert }) => {
	const { domain } = useContext(DomainContext)
	const { contractId, service_dict, risk_area_dict } = useContext(GlobalDataContext)

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteRoomType2(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('roomtype.messages.delete_roomtype_title'), t('roomtype.messages.delete_roomtype', { name: el.name }), cb)
	}



	return (
		<Row className="element_row p-1 border-bottom">
			<Col sm="1" className="p-1">
				{roomtype.image_reference ?
					<Image src={"image_reference/" + roomtype.image_reference} thumbnail />
					: ''}
			</Col>
			<Col sm="2">
				<b>{roomtype.name}</b>
			</Col>
			<Col sm="1">
				{roomtype.risk_area.Id ?
					<RiskareaBadge riskarea={risk_area_dict[getValue(roomtype.risk_area, 'Id')]} />
					: ''}
			</Col>
			<Col>
				{roomtype.service_list ?
					(roomtype.service_list.map((s, sk) => (
						<Row key={sk} className="border-bottom">
							{s.service ?
								<>
									<Col>
										{!s.operation_list || (s.operation_list && s.operation_list.length === 0) ?
											<OverlayTrigger
												placement="auto"
												delay={{ show: 250, hide: 150 }}
												overlay={renderWarningTooltip}
											><Badge variant="warning" size="md" className="mr-1">
													<FontAwesomeIcon icon="exclamation-triangle" />
												</Badge>
											</OverlayTrigger>
											: ''}
										{s.check_list && Object.keys(s.check_list).length > 0 ?
											<OverlayTrigger
												placement="auto"
												delay={{ show: 250, hide: 150 }}
												overlay={<Tooltip >{Object.keys(s.check_list).length} elementi di controllo</Tooltip>}>
												<Badge variant="secondary" className="mr-1"><FontAwesomeIcon icon="check" /></Badge>
											</OverlayTrigger>
											: ''}
										<Badge>
											{s.lqa}
										</Badge>
										<ServiceBadge service={service_dict[s.service.id]} freq={true} />
									</Col>
									<Col>
										{s.service.type === 'periodic' ?
											<FrequencySelector element={s} mode='read_graphical' />
											:
											[s.service.type === 'ordinary' ?
												<WeekDays weekdays={s.weekdays} />
												: ''
											]
										}
									</Col>
								</>
								: <Col ><FontAwesomeIcon icon="exclamation-triangle" /></Col>

							}
						</Row>
					)))
					: ''}
				{roomtype.has_microbiological_check ?
					<OverlayTrigger
						placement="auto"
						delay={{ show: 250, hide: 150 }}
						overlay={<Tooltip >{t('structure.labels.has_microbiological_check')}</Tooltip>}>
						<Badge variant="success" className="mr-1"><FontAwesomeIcon icon="flask" /></Badge>
					</OverlayTrigger>
					: ''}
			</Col>
			<Col sm="2">

				{roomtype.furniture_list && roomtype.furniture_list.length > 0 ?

					<>
						<OverlayTrigger trigger={popover_mode} placement="auto" overlay={renderPopover(t('global.labels.furnitures'), roomtype.furniture_list)}>
							<Badge variant="secondary" className="btn mr-1">{roomtype.furniture_list.length} {t('global.labels.furnitures')} </Badge>
						</OverlayTrigger>
					</>
					: ''
				}
				{roomtype.environment_element_list && roomtype.environment_element_list.length > 0 ?
					<>
						<OverlayTrigger trigger={popover_mode} placement="auto" overlay={renderPopover(t('global.labels.environment_element'), roomtype.environment_element_list)}>
							<Badge variant="secondary" className="btn mr-1">{roomtype.environment_element_list.length} {t('global.labels.environment_elements')} </Badge>
						</OverlayTrigger>
					</>
					: ''
				}
				{roomtype.economic_material_list && roomtype.economic_material_list.length > 0 ?
					<>
						<OverlayTrigger trigger={popover_mode} placement="auto" overlay={renderPopover(t('global.labels.economic_material'), roomtype.economic_material_list)}>
							<Badge variant="secondary" className="btn mr-1">{roomtype.economic_material_list.length} {t('global.labels.economic_material')} </Badge>
						</OverlayTrigger>
					</>
					: ''
				}<br />
				{roomtype.has_patient_unit && roomtype.patient_unit > 0 ?
					<>
						<FontAwesomeIcon icon="user" /> &times; {roomtype.patient_unit}
					</>
					: ''}
			</Col>
			<Col sm="1" className="text-center p-1">
				<ButtonGroup>
					<ModalRoomType item={roomtype} t={t} setItem={setElementList} element_list={element_list} canWrite={canWrite} ui_list={ui_list} />
					{canWrite ?
						<Button variant="danger" size="sm" onClick={(el) => handleDelete(roomtype)}><FontAwesomeIcon icon='trash' /> </Button>
						: ''}
				</ButtonGroup>
			</Col>

		</Row>

	)
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkRiskareaFilter(i, filterdata) {
	let ispresent = false
	if (i.risk_area && i.risk_area.Id && filterdata.indexOf(i.risk_area.Id) >= 0) {
		ispresent = true
	}
	return ispresent
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkServiceFilter(i, filterdata) {
	let ispresent = false
	console.log("-----------  -------------       ---", i.service_list, filterdata)
	if (i.service_list && i.service_list.some(s => (s.service && filterdata.indexOf(s.service.id)>=0)) ) {
		ispresent = true
	}
	return ispresent
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkRiskareaPresence(e_list, d) {
			for (let e of e_list) {
				if (e.risk_area && e.risk_area.Id === d) {
					return true
				}
			}
	return false
}


////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
export const ModalFilter = ({ t, ui_list, filterAppliedList, setFilterAppliedList, defaultfilter, e_list }) => {
	//	const { intervention_dict } = useContext(InterventionContext)
	const { contract, service_dict, risk_area_list, risk_area_dict, operation_dict } = useContext(GlobalDataContext)


	const [showFilter, setShowFilter] = useState(false)
	const [service_list, setServiceList] = useState([])


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const initValues = async () => {
		let servicelist = Object.values(service_dict)
		servicelist.sort(compareName)
		setServiceList(servicelist)
	}
	useEffect(() =>{
		console.log("FILTER -> ", filterAppliedList)
	},[filterAppliedList])

	const resetAppliedFilter = (section) => {
		if (section) {
			setFilterAppliedList({ ...filterAppliedList, [section]: [] })
		} else {
			setFilterAppliedList(defaultfilter)
		}
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	useEffect(() => {
		initValues()
	}, [service_dict])

	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.risk_area.length > 0 ?
					<>
						{risk_area_list.filter(d =>filterAppliedList.risk_area.indexOf(d.Id) !== -1 ).map((d, k) => (
							<Button key={k} size="sm" 
								variant="secondary"
								onClick={() => updateAppliedFilter('risk_area', d.Id)}>
								<RiskareaBadge riskarea={risk_area_dict[getValue(d, 'Id')]} />
								<FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.risk_area.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('risk_area')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{filterAppliedList.service.length > 0 ?
					<>
						{service_list.filter(s => filterAppliedList.service.indexOf(s.id)>=0).map((d, k) => (
							<Button key={k} size="sm" 
								variant="secondary"
								onClick={() => updateAppliedFilter('service', d.id)}>
												<ServiceBadge service={d} />
								<FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.service.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('service')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>
						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col>
							{contract && contract.type ==='civil'?
                                                t('global.labels.standard_cleaning')
                                            :
                                                t('global.labels.risk_area')
                                            }							
											</Col>
						</Row>
						<Row>
							{risk_area_list ?
								<>
									{risk_area_list.filter(d  => checkRiskareaPresence(e_list, d.Id)).map((d, k) => (
										<Col sm="4" key={d.Id}>
											<Button block size="sm" className="ml-1 mb-1 text-left"  variant={filterAppliedList.risk_area.indexOf(d.Id) === -1 ? "outline-secondary" : "secondary"}
												onClick={(e) => updateAppliedFilter('risk_area', d.Id)}>
												<RiskareaBadge riskarea={risk_area_dict[getValue(d, 'Id')]} />
											</Button>
										</Col>
									))}
								</>
								: ''
							}
						</Row>
						<Row>
							<Col>
								{t('global.labels.services')}
							</Col>
						</Row>
						<Row>
							{service_dict ?
								<>
									{service_list.map((d, k) => (
										<Col sm="4" key={k}>
											<Button block size="sm" className="ml-1 mb-1 text-left" variant={filterAppliedList.service.indexOf(d.id) === -1 ? "outline-secondary" : "secondary"}
												onClick={(e) => updateAppliedFilter('service', d.id)}>
												<ServiceBadge service={d} freq={true}/>
											</Button>
										</Col>
									))}
								</>
								: ''
							}
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}





export default translate()(RoomTypesView)

