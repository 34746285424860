import React, { useState, useEffect, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, ButtonGroup, Popover, Badge, OverlayTrigger } from 'react-bootstrap';
import { translate } from 'react-polyglot'

import DomainContext from './contexts/domainContext'
import EditContext from "../components/contexts/edit.js"
import GlobalDataContext from './contexts/globaldataContext.js';

import ModalStructureGroupItem from './ModalStructureGroupItem'
import ModalRoomItem from './ModalRoomItem'
import ListRoomItem from './ListRoomItem'
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"

import { rgba2hex, compareName, getFloatNumber, compareOrder, checkComponentPresence, checkDepartmentPermission, compareNameInt } from '../util/ui_utils'
import { ItemProgress, StructureGroupIcon, RenderItemFileBadge, ItemNote } from '../components/StructureGraphicElements.js'

import { deletePavillon as deletePavillon2, deleteFloor as deleteFloor2, deleteDepartment as deleteDepartment2 } from '../Firebase2/Storage2/dbBaseCompany';

import {
	editPavillon as editPavillon2,
	editFloor as editFloor2,
	editDepartment as editDepartment2
} from '../Firebase2/Storage2/dbBaseCompany';

const section = 'structure'


const hasRoom = (item_id, level, room_list) => {

	const filtered_room_list = room_list.filter(r => r.parent_list[level] === item_id)
	if (filtered_room_list.length > 0) {
		return filtered_room_list.length
	} else {
		return false
	}
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ListStructureGroupItem({ item, parent, setParent, refreshParent, functions, level, is_summary, selectedWorkareas, curWorkarea, t, structureId, contractId, searchTerm, isVariation, ui_list, structureElements, canWrite, permission_department_list }) {

	const { domain } = useContext(DomainContext)
	const { updatePavillon, updateFloor, updateDepartment, updateRoom } = useContext(GlobalDataContext)
	const edit = useContext(EditContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()



	const [open, setOpen] = useState(getDefaultOpen(level, permission_department_list));
	const [curItem, setCurItem] = useState(item)
	const [rowHover, toggleHover] = useState(false)
	const [multiToggle, setMultiToggle] = useState(false)

	const [stElem, setStElem] = useState({
		pavillon_list: []
	})


	useEffect(() => {
		if (searchTerm && searchTerm.length > 0 || edit.fiterUnusedRoom) {
			setOpen(true)
		}

	}, [searchTerm])

	useEffect(() => {
		if (edit.filterUnusedRoom) {

			setOpen(true)
		}
	}, [edit.filterUnusedRoom])

	const refreshParentData = async (editItem) => {
		//    console.log("refreshParent", editItem)
		functions.setsaving(true)
		functions.setedit(true)
		let risk_area_summary_decommissioned = { total: { room_count: 0, size: 0 } }
		let risk_area_summary_suspended = { total: { room_count: 0, size: 0 } }
		let risk_area_summary_active = { total: { room_count: 0, size: 0 } }
		let risk_area_summary = { total: { room_count: 0, size: 0 } }
		let item_size = 0
		let room_count = 0;
		if (editItem.childs) {
			for (let c = 0; c < editItem.childs.length; c++) {
				console.log('child ', c, editItem.childs.length, editItem.level, editItem.name, editItem.childs[c])
				let cur_c = editItem.childs[c]
				item_size += getFloatNumber(cur_c.size)
				if (editItem.level === 3) { // department: room children
					console.log(cur_c.status)
					room_count += 1;
					if (cur_c.risk_area) {
						if (risk_area_summary[cur_c.risk_area.Id]) {
							risk_area_summary[cur_c.risk_area.Id].size += getFloatNumber(cur_c.size)
							risk_area_summary[cur_c.risk_area.Id].room_count += 1
						} else {
							risk_area_summary[cur_c.risk_area.Id] = { size: getFloatNumber(cur_c.size), room_count: 1, color: rgba2hex(cur_c.risk_area.color) }
						}
						risk_area_summary.total.size += getFloatNumber(cur_c.size)
						risk_area_summary.total.room_count += 1
						if (cur_c.status === 'suspended') {
							if (risk_area_summary_suspended[cur_c.risk_area.Id]) {
								risk_area_summary_suspended[cur_c.risk_area.Id].size += getFloatNumber(cur_c.size)
								risk_area_summary_suspended[cur_c.risk_area.Id].room_count += 1
							} else {
								risk_area_summary_suspended[cur_c.risk_area.Id] = { size: getFloatNumber(cur_c.size), room_count: 1, color: rgba2hex(cur_c.risk_area.color) }
							}
							risk_area_summary_suspended.total.size += getFloatNumber(cur_c.size)
							risk_area_summary_suspended.total.room_count += 1

						} else if (cur_c.status === 'decommissioned') {
							if (risk_area_summary_decommissioned[cur_c.risk_area.Id]) {
								risk_area_summary_decommissioned[cur_c.risk_area.Id].size += getFloatNumber(cur_c.size)
								risk_area_summary_decommissioned[cur_c.risk_area.Id].room_count += 1
							} else {
								risk_area_summary_decommissioned[cur_c.risk_area.Id] = { size: getFloatNumber(cur_c.size), room_count: 1, color: rgba2hex(cur_c.risk_area.color) }
							}
							risk_area_summary_decommissioned.total.size += getFloatNumber(cur_c.size)
							risk_area_summary_decommissioned.total.room_count += 1

						} else {
							if (risk_area_summary_active[cur_c.risk_area.Id]) {
								risk_area_summary_active[cur_c.risk_area.Id].size += getFloatNumber(cur_c.size)
								risk_area_summary_active[cur_c.risk_area.Id].room_count += 1
							} else {
								risk_area_summary_active[cur_c.risk_area.Id] = { size: getFloatNumber(cur_c.size), room_count: 1, color: rgba2hex(cur_c.risk_area.color) }
							}
							risk_area_summary_active.total.size += getFloatNumber(cur_c.size)
							risk_area_summary_active.total.room_count += 1
						}
					}
				} else {
					room_count += cur_c.room_count
					for (let r in cur_c.risk_area_summary) {
						if (risk_area_summary[r]) {
							risk_area_summary[r].size += getFloatNumber(cur_c.risk_area_summary[r].size)
							risk_area_summary[r].room_count += cur_c.risk_area_summary[r].room_count
						} else {
							risk_area_summary[r] = { size: getFloatNumber(cur_c.risk_area_summary[r].size), room_count: cur_c.risk_area_summary[r].room_count, color: cur_c.risk_area_summary[r].color }
						}
					}
					for (let r in cur_c.risk_area_summary_suspended) {
						if (risk_area_summary_suspended[r]) {
							risk_area_summary_suspended[r].size += getFloatNumber(cur_c.risk_area_summary_suspended[r].size)
							risk_area_summary_suspended[r].room_count += cur_c.risk_area_summary_suspended[r].room_count
						} else {
							risk_area_summary_suspended[r] = { size: getFloatNumber(cur_c.risk_area_summary_suspended[r].size), room_count: cur_c.risk_area_summary_suspended[r].room_count, color: cur_c.risk_area_summary_suspended[r].color }
						}
					}
					for (let r in cur_c.risk_area_summary_decommissioned) {
						if (risk_area_summary_decommissioned[r]) {
							risk_area_summary_decommissioned[r].size += getFloatNumber(cur_c.risk_area_summary_decommissioned[r].size)
							risk_area_summary_decommissioned[r].room_count += cur_c.risk_area_summary_decommissioned[r].room_count
						} else {
							risk_area_summary_decommissioned[r] = { size: getFloatNumber(cur_c.risk_area_summary_decommissioned[r].size), room_count: cur_c.risk_area_summary_decommissioned[r].room_count, color: cur_c.risk_area_summary_decommissioned[r].color }
						}
					}
					for (let r in cur_c.risk_area_summary_active) {
						if (risk_area_summary_active[r]) {
							risk_area_summary_active[r].size += getFloatNumber(cur_c.risk_area_summary_active[r].size)
							risk_area_summary_active[r].room_count += cur_c.risk_area_summary_active[r].room_count
						} else {
							risk_area_summary_active[r] = { size: getFloatNumber(cur_c.risk_area_summary_active[r].size), room_count: cur_c.risk_area_summary_active[r].room_count, color: cur_c.risk_area_summary_active[r].color }
						}
					}
				}
			}
		}
		let p_childs = [...parent.childs]
		const c = checkComponentPresence(editItem, parent.childs, 'id')
		editItem = { ...editItem, risk_area_summary: risk_area_summary, size: item_size, room_count: room_count, risk_area_summary_active, risk_area_summary_decommissioned, risk_area_summary_suspended }
		p_childs.splice(c, 1, editItem)
		console.log("edited childs", p_childs)
		console.log("!!!!update group size&summary:(level, size, ra_summary, count)", editItem.level, item_size, risk_area_summary, room_count)

		if (editItem.level !== 0) {
			refreshParent({ ...parent, 'childs': p_childs })
		} else {
			setParent({ ...editItem, risk_area_summary: risk_area_summary, size: item_size, room_count: room_count, risk_area_summary_active, risk_area_summary_decommissioned, risk_area_summary_suspended })
		}
		//setItem({ ...editItem, risk_area_summary: risk_area_summary, size: item_size, room_count: room_count, risk_area_summary_active, risk_area_summary_decommissioned, risk_area_summary_suspended })
		setCurItem({ ...editItem, risk_area_summary: risk_area_summary, size: item_size, room_count: room_count, risk_area_summary_active, risk_area_summary_decommissioned, risk_area_summary_suspended })
		editItem.childs = []
		functions.setsaving(false)

	}









	const handleDelete = (el) => {
		console.log(el)
		const cb = () => {
			const editlist = [...parent.childs]
			const c = checkComponentPresence(el, editlist, 'id')
			editlist.splice(c, 1)
			setParent({ ...parent, childs: editlist })
		}
		let el_type = t('global.labels.structure')
		if (el.level === 1) {
			el_type = t('global.labels.pavillon')
		} else if (level === 2) {
			el_type = t('global.labels.floor')
		} else if (level === 3) {
			el_type = t('global.labels.department')
		}
		const cb_db = async () => {
			if (curItem.level === 1) {
				const return_data = await deletePavillon2(domain, contractId, item.parent_list[0], el.id, el)
				if (return_data && return_data.error) {
					childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					cb()
				}
			} else if (el.level === 2) {
				const return_data = await deleteFloor2(domain, contractId, item.parent_list[0], item.parent_list[1], el.id, el)
				if (return_data && return_data.error) {
					childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					cb()
				}
			} else if (el.level === 3) {
				const return_data = await deleteDepartment2(domain, contractId, item.parent_list[0], item.parent_list[1], item.parent_list[2], el.id, el)
				if (return_data && return_data.error) {
					childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error, domain, contractId, item.parent_list[0], item.parent_list[1], item.parent_list[2], el.id, el)
				} else {
					cb()
				}
			}

		}
		childRefDelete.current.confirmDelete(t('global.messages.delete_element_title'), t('global.messages.delete_structure_element', { type: el_type, name: el.name }), cb_db)
	}




	const populateChilds = async () => {
		//    console.log('populate child', curItem.level)




		function addChilds(return_data, reverse) {
			if (permission_department_list && permission_department_list.length > 0) {
				if (curItem.level !== 3) {

					const permission_department = structureElements.department_list.filter(d => permission_department_list.indexOf(d.id) !== -1)
					return_data = return_data.filter(p => checkDepartmentPermission({ level: p.level, department_list: permission_department, element_id: p.id }))
				} else {
					console.log("is permitted pavillon", curItem.name, return_data)
				}
			}
			//console.log('(post filter) level', item.level, curItem.level)
			console.log(return_data)
			if (reverse) {
				return_data = return_data.sort(compareOrder).reverse()
			} else {
				return_data = return_data.sort(compareNameInt)
			}
			console.log("AFTER SORT", return_data)
			setCurItem({ ...item, 'childs': return_data })

		}



		if (curItem.level === 0) {
			if (structureElements.pavillon_list) {
				let return_data = structureElements.pavillon_list.filter(p => p.parent_list[0] === structureId)
				addChilds(return_data)
			}

		} else if (curItem.level === 1) {
			if (structureElements.floor_list) {
				let return_data = structureElements.floor_list.filter(p => (p.parent_list && p.parent_list[1] === item.id))
				addChilds(return_data, true)
			}

		} else if (curItem.level === 2) {
			if (structureElements.department_list) {
				let return_data = structureElements.department_list.filter(p => (p.parent_list && p.parent_list[2] === item.id))
				addChilds(return_data)
			}

		} else if (curItem.level === 3) {
			let return_data = structureElements.room_list.filter(p => (p.parent_list && p.parent_list[3] === item.id))
			console.log(structureElements)
			console.log(structureElements.room_list.filter(p => (p.parent_list && p.parent_list[3] === item.id)))
			addChilds(return_data)
		}
	}



	useEffect(() => {
		setStElem(structureElements)
		//    populateChilds()
	}, [])


	useEffect(() => {
		console.log("populate ST ELEMEN", stElem)
		populateChilds()
	}, [stElem])



	const updateCurItem = (d) => {
		const pre_c = {...curItem}
		console.log(pre_c)
		console.log(d)
		if (d.level === 1) {
			const el_id = checkComponentPresence(d, stElem.pavillon_list, 'id')
			const el_list = stElem.pavillon_list.splice(el_id, 1, d)
			setStElem({ ...stElem, all_pavillon_dict: { ...stElem.all_pavillon_dict, [d.id]: d }, pavillon_list: el_list })
			updatePavillon(d.id, d)
		} else if (d.level === 2) {
			const el_id = checkComponentPresence(d, stElem.floor_list, 'id')
			const el_list = stElem.floor_list.splice(el_id, 1, d)
			setStElem({ ...stElem, all_floor_dict: { ...stElem.all_floor_dict, [d.id]: d }, floor_list: el_list })
			updateFloor(d.id, d)
		} else if (d.level === 3) {
			const el_id = checkComponentPresence(d, stElem.department_list, 'id')
			const el_list = stElem.department_list.splice(el_id, 1, d)
			setStElem({ ...stElem, all_department_dict: { ...stElem.all_department_dict, [d.id]: d }, department_list: el_list })
			updateDepartment(d.id, d)
		}
		setCurItem({...d, 'childs':pre_c.childs} )
	}



	const handleToggleMultiEdit = () => {
		setMultiToggle(!multiToggle)
		edit.multiToggleEditList(curItem, refreshParentData, multiToggle)
	}


	const onClickAdd = () => {
		functions.addToWorkarea(item)
		//    setIsPresent(true)
	}
	if (isVariation) {
		const c_room = hasRoom(item.id, curItem.level, structureElements.room_list)
		if (c_room) {

			return (
				<span key={item.id}>
					{/* <Row className={"border-bottom border-secondary " + (rowHover ? "bg-light" : "")} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)} > */}
					<Row className="border-bottom border-secondary element_row">
						<Col>
							{curItem.level === 0
								? <span className="levelbox mr-1 " style={{ backgroundColor: '#111' }}>&nbsp;</span>
								: (curItem.level > 0
									? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
									: '')
							}
							{curItem.level === 1 && !curItem.end_item
								? <span className="levelbox mr-1 " style={{ backgroundColor: '#003366' }}>&nbsp;</span>
								: (curItem.level > 1
									? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
									: '')
							}
							{curItem.level === 2 && !curItem.end_item
								? <span className="levelbox mr-1 " style={{ backgroundColor: '#0066cc' }}>&nbsp;</span>
								: (curItem.level > 2
									? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
									: '')
							}
							{curItem.level === 3 && !curItem.end_item
								? <span className="levelbox mr-1 " style={{ backgroundColor: '#99ccff' }}>&nbsp;</span>
								: (curItem.level > 3
									? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
									: '')
							}
							{curItem.level === 4 && !curItem.end_item
								? <span className="levelbox mr-1 " style={{ backgroundColor: '#99ccff' }}>&nbsp;</span>
								: (curItem.level > 4
									? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
									: '')
							}

		
							<Badge>

								{!curItem.end_item || curItem.level !== 0 ?
									[<Button
										key={curItem.id}
										size="sm"
										variant="secondary"
										onClick={() => setOpen(!open)}
										aria-controls="collapseRow"
										aria-expanded={open}
										className="align-bottom"
										disabled={!(curItem.childs && curItem.childs.length > 0) ? 'disabled' : ''}
									>
										{open ? <FontAwesomeIcon icon="chevron-circle-up" /> : <FontAwesomeIcon icon="chevron-circle-down" />}
									</Button>] : <span className="levelbox mr-5 " style={{ backgroundColor: '#99c99f' }}>&nbsp;</span>
								}
							</Badge>


							<StructureGroupIcon level={curItem.level} />
							{curItem.name}
							{curItem.note && curItem.note.length > 0 &&
								<OverlayTrigger trigger={['focus', 'hover']} rootClose placement="right-start" overlay={ItemNote(curItem.note)}>
									<Badge size="sm" className="ml-1" variant="info"><FontAwesomeIcon icon="comment-alt" /></Badge>
								</OverlayTrigger>

								}


						</Col>
						<Col sm="1">
							{c_room}
						</Col>
						<Col sm="1" className="text-center text-secondary">
							{curItem.code && curItem.code.length > 0 ?
								<i>{curItem.code}</i>
								: ''}
						</Col>
					</Row>

					{open ?
						<Row id="container" className={` ${!item.end_item ? "" : ""} `} >
							<Col>{curItem.childs &&
								<Row>
									{curItem.level === 3
										?
										<Col>
											{curItem.childs.map((i, k) => (
												<ListRoomItem key={k + i.id} item={i} level={level + 1} parent={curItem} setParent={refreshParentData} is_summary={is_summary} selectedWorkareas={selectedWorkareas} isVariation={isVariation}
													curWorkarea={curWorkarea} functions={functions} t={t} structureId={structureId} contractId={contractId} searchTerm={searchTerm} ui_list={ui_list} structureElements={structureElements} canWrite={canWrite} permission_department_list={permission_department_list} />
											))}
										</Col>
										:
										<Col>
											{curItem.childs.map((i, k) => (
												<ListStructureGroupItem key={k + i.id} item={i} level={level + 1} parent={curItem} setParent={setCurItem} refreshParent={refreshParentData} is_summary={is_summary} isVariation={isVariation}
													curWorkarea={curWorkarea} selectedWorkareas={selectedWorkareas} functions={functions} t={t} structureId={structureId} contractId={contractId} searchTerm={searchTerm} ui_list={ui_list} structureElements={structureElements} canWrite={canWrite} permission_department_list={permission_department_list} />
											))}
										</Col>
									}
								</Row>
								}
							</Col>
						</Row>
						: ''
					}
				</span>

			);

		} else {
			return (<></>)

		}

	} else {


		return (
			<span key={item.id}>
				<DeleteConfirmationSection ref={childRefDelete} />
				<AlertSection ref={childRefAlert} />
				{/* <Row className={"border-bottom border-secondary " + (rowHover ? "bg-light" : "")} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)} > */}
				<Row className="border-bottom border-secondary element_row">
					<AddButton selectedWorkareas={selectedWorkareas} onClickAdd={onClickAdd} ispresent={false} item={item} other_present_list={[]} />
					{selectedWorkareas && selectedWorkareas.length > 0
						?
						<Col sm="1">
							{canWrite ?
								<Button disabled size="sm" variant="secondary" ><FontAwesomeIcon className="text-warning" icon="arrow-left" />&nbsp;<FontAwesomeIcon icon="plus-square" /></Button>
								: ''}
						</Col>
						: ''
					}
					<Col>
						{curItem.level === 0
							? <span className="levelbox mr-1 " style={{ backgroundColor: '#111' }}>&nbsp;</span>
							: (curItem.level > 0
								? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
								: '')
						}
						{curItem.level === 1 && !curItem.end_item
							? <span className="levelbox mr-1 " style={{ backgroundColor: '#003366' }}>&nbsp;</span>
							: (curItem.level > 1
								? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
								: '')
						}
						{curItem.level === 2 && !curItem.end_item
							? <span className="levelbox mr-1 " style={{ backgroundColor: '#0066cc' }}>&nbsp;</span>
							: (curItem.level > 2
								? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
								: '')
						}
						{curItem.level === 3 && !curItem.end_item
							? <span className="levelbox mr-1 " style={{ backgroundColor: '#99ccff' }}>&nbsp;</span>
							: (curItem.level > 3
								? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
								: '')
						}
						{curItem.level === 4 && !curItem.end_item
							? <span className="levelbox mr-1 " style={{ backgroundColor: '#99ccff' }}>&nbsp;</span>
							: (curItem.level > 4
								? <span className="levelbox mr-1 " style={{ backgroundColor: 'rgba(0,0,0,0)', borderColor: "rgba(0,0,0,0)" }}>&nbsp;</span>
								: '')
						}

						{edit.editMultiple && curItem.level === 3 ?
							<Button variant="outline-info" className="mr-1" size="sm" onClick={() => handleToggleMultiEdit()}><FontAwesomeIcon icon="check" /></Button>
							: ''
						}
						<Badge>

							{!curItem.end_item || curItem.level !== 0 ?
								[<Button
									key={curItem.id}
									size="sm"
									variant="secondary"
									onClick={() => setOpen(!open)}
									aria-controls="collapseRow"
									aria-expanded={open}
									className="align-bottom"
									disabled={!(curItem.childs && curItem.childs.length > 0) ? 'disabled' : ''}
								>
									{open ? <FontAwesomeIcon icon="chevron-circle-up" /> : <FontAwesomeIcon icon="chevron-circle-down" />}
								</Button>] : <span className="levelbox mr-5 " style={{ backgroundColor: '#99c99f' }}>&nbsp;</span>
							}
						</Badge>


						<StructureGroupIcon level={curItem.level} />
						{curItem.name}
						{curItem.note && curItem.note.length > 0  &&
							<OverlayTrigger trigger={['focus', 'hover']} rootClose placement="right-start" overlay={ItemNote(curItem.note)}>
								<Badge size="sm" className="ml-1" variant="info"><FontAwesomeIcon icon="comment-alt" /></Badge>
							</OverlayTrigger>
							}
						{curItem.risk_area_summary_suspended && curItem.risk_area_summary_suspended.total.room_count > 0 &&
							<OverlayTrigger trigger={['focus', 'hover']} rootClose placement="right-start" overlay={renderStatusPopover(`${curItem.risk_area_summary_suspended.total.room_count} locali sospesi`)}>
							<Badge variant="danger" className="ml-1"><FontAwesomeIcon icon="hourglass" />{curItem.risk_area_summary_suspended.total.room_count}</Badge>
							</OverlayTrigger>
							
						}
						{curItem.risk_area_summary_decommissioned && curItem.risk_area_summary_decommissioned.total.room_count > 0 &&
							<OverlayTrigger trigger={['focus', 'hover']} rootClose placement="right-start" overlay={renderStatusPopover(`${curItem.risk_area_summary_decommissioned.total.room_count} locali dismessi`)}>
							<Badge variant="dark" className="ml-1"><FontAwesomeIcon icon="exclamation-circle" />{curItem.risk_area_summary_decommissioned.total.room_count}</Badge>
							</OverlayTrigger>
						}


					</Col>
					<Col sm="1" className="text-center">
						{!is_summary && curItem.media_list && curItem.media_list.length > 0 ?
							<RenderItemFileBadge media_list={curItem.media_list} section={section} position="auto" />

							: ''}
					</Col>
					<Col sm="1" className="text-center text-secondary">
						{curItem.code && curItem.code.length > 0 ?
							<i>{curItem.code}</i>
							: ''}
					</Col>
					<Col sm="1" className="text-center">
						{getFloatNumber(curItem.size).toFixed(2)}
					</Col>
					<Col sm="1">

					</Col>
					<Col sm="4" md="2">
						{!is_summary && (!permission_department_list || permission_department_list.length === 0) ?
							<>
								{curItem.risk_area_summary ? <ItemProgress risk_list={curItem.risk_area_summary} item={curItem} ui_list={ui_list} use_room_count={true} /> : ''}
							</>
							: ''
						}
					</Col>
					{!is_summary ?
						<Col sm="2" className="text-right">
							<ButtonGroup>
								{canWrite && !edit.editMultiple ?
									<>
										{curItem.level === 3
											?
											<ModalRoomItem parent={curItem} setItem={setCurItem} is_new={true} t={t} hover={rowHover} structureId={structureId} contractId={contractId} ui_list={ui_list} setParent={refreshParentData} structureElements={structureElements} canWrite={canWrite} />
											: <ModalStructureGroupItem item={curItem} setItem={setCurItem} is_new={true} t={t} hover={rowHover} is_summary={is_summary} structureId={structureId} contractId={contractId} ui_list={ui_list} structureElements={structureElements} canWrite={canWrite} />
										}
									</>
									: ''}
								{curItem.level !== 0 && !edit.editMultiple ?
									<>

										<ModalStructureGroupItem item={curItem} setItem={updateCurItem} is_new={false} t={t} hover={rowHover} structureId={structureId} contractId={contractId} ui_list={ui_list} structureElements={structureElements} canWrite={canWrite} />

										{canWrite ?
											<Button variant="danger" size="sm"><FontAwesomeIcon icon='trash' onClick={() => handleDelete(curItem)} /> </Button>
											: ''}
									</>
									: ''}

							</ButtonGroup>
						</Col>
						:
						<Col sm="1">
						</Col>
					}
				</Row>

				{open ?
					<Row id="container" className={` ${!item.end_item ? "" : ""} `} >
						<Col>{curItem.childs
							?
							<Row>
								{curItem.level === 3
									?
									<Col>
										{console.log(curItem.childs)}
										{curItem.childs.map((i, k) => (
											<ListRoomItem key={k + i.id} item={i} level={level + 1} parent={curItem} setParent={refreshParentData} is_summary={is_summary} selectedWorkareas={selectedWorkareas} isVariation={isVariation}
												curWorkarea={curWorkarea} functions={functions} t={t} structureId={structureId} contractId={contractId} searchTerm={searchTerm} ui_list={ui_list} structureElements={structureElements} canWrite={canWrite} permission_department_list={permission_department_list} />
										))}
									</Col>
									:
									<Col>
										{curItem.childs.map((i, k) => (
											<ListStructureGroupItem key={k + i.id} item={i} level={level + 1} parent={curItem} setParent={setCurItem} refreshParent={refreshParentData} is_summary={is_summary} isVariation={isVariation}
												curWorkarea={curWorkarea} selectedWorkareas={selectedWorkareas} functions={functions} t={t} structureId={structureId} contractId={contractId} searchTerm={searchTerm} ui_list={ui_list} structureElements={structureElements} canWrite={canWrite} permission_department_list={permission_department_list} />
										))}
									</Col>
								}
							</Row>
							: ''}
						</Col>
					</Row>
					: ''
				}
			</span>

		);
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderStatusPopover = (text) => {


		return (

			<Popover id="popover-basic" className="bg-info border border-info">
				<Popover.Content >
					<Row>
						<Col>
							{text}
						</Col>
					</Row>
					<Row>
					</Row>
				</Popover.Content>
			</Popover>


		)
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddButton = ({ selectedWorkareas, onClickAdd, ispresent, other_present_list, item }) => {
	const edit = useContext(EditContext)

	//console.log("ADD BUTTOn refresh", item.id, ispresent, edit.workareaRoomList.filter(r => r.id === item.id).length>0, edit.workareaRoomList)



	if (selectedWorkareas && selectedWorkareas.length > 0) {
		const w_list = other_present_list

		const pres = edit.workareaRoomList.filter(r => r.id === item.id).length > 0

		return (
			<Col sm="1" className="p-1">
				<Button size="sm" variant="outline-primary" onClick={onClickAdd}><FontAwesomeIcon className="text-warning" icon="arrow-left" />&nbsp;<FontAwesomeIcon icon="plus-square" /></Button>
			</Col>
		)

	} else {
		return <></>
	}
}







//////////////////////////////////////////////////////////////
const getDefaultOpen = (level, permission_department_list) => {
	if (level <= 0) {
		return true
	} else {
		if (permission_department_list.length === 1) {
			return true
		} else {

			return false
		}
	}
}



export default translate()(ListStructureGroupItem)