import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Form, Button, Modal, Container } from 'react-bootstrap';
import GlobalDataContext from './contexts/globaldataContext';
import { checkComponentPresence } from '../util/ui_utils';
import DomainContext from './contexts/domainContext';
import DeleteconfirmationSection from './DeleteConfirmationSection';
import AlertSection from './AlertSection';
import { addBeacon, deleteBeacon, getBeacon, getBeaconFromDepartment, updateBeacon } from '../Firebase2/Storage2/dbBeacon';

export const IdentifierList = ({ element, functions, t, canWrite }) => {

	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)
	const [beaconList, setBeaconList] = useState([])
	const childRefAlert = useRef()
	const childRefDelete = useRef()
	const getBeaconList = async () => {
		const b_list = await getBeaconFromDepartment(domain, contract.id, element.id)
		console.log("GET BEACON LIST", domain, contract.id, element.id, b_list)
		setBeaconList(b_list)
	}
	useEffect(() => {

		getBeaconList()

	}, [])

	const refreshBeacons = () => {
		getBeaconList()

	}

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteBeacon(domain, contract.id, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...beaconList]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setBeaconList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('identifier.messages.delete_identifier_title'), t('identifier.messages.delete_identifier', { name: el.id }), cb)
	}

	return (

		<>
			<Row>
				<Col className="text-right">
					<ModalIdentifier is_new={true} t={t} department={element} refreshData={refreshBeacons()} />
				</Col>
			</Row>

			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.identifier_list')}</Form.Label>
				<DeleteconfirmationSection ref={childRefDelete} />
				<AlertSection ref={childRefAlert} />
				{beaconList.length > 0
					?
					<Col>
						<Row className="mb-1 mt-1">
							<Col className="bg-secondary text-light ml-1">{t('global.labels.id')} </Col>
							<Col className="bg-secondary text-light ml-1">{t('global.labels.description')} </Col>
							<Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{beaconList.map((i, key) => (
							<Row key={key}>
								<Col >
									{i.id}
								</Col>
								<Col >
									{i.description}
								</Col>
								<Col className="text-right" sm="1">
									{canWrite &&
										<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
									}
									{/* 							<Col className="text-right">
								{key === element.identifier_list.length - 1 ?
									<Button variant="outline-danger" size="sm" onClick={() => handleDeleteIdentifier(key)}><FontAwesomeIcon icon="trash" /></Button>
									: <> </>
								}
							</Col> */}
								</Col>
							</Row>
						))}
					</Col>
					: <> <Col className="font-italic"> {t('global.messages.no_identifier')}</Col>
						<Col className="text-right">
						</Col>
					</>
				}
			</Form.Group>
		</>
	)
}

const newclauditpoint = {
	uid: '',
	id: '',
	description: ''
}


const ModalIdentifier = ({ element, refreshData, t, is_new, department, small }) => {
	const [showEdit, setShow] = useState(false)
	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)
	const [curElement, setCurrentElement] = useState(newclauditpoint)
	const [editedElement, setEditedElement] = useState(false)
	const [notFree, setNotFree] = useState(false)
	const handleShowNew = () => {
		setCurrentElement({
			...newclauditpoint,
			departmentId: department.id,
			departmentPath: getPath()
		})
		setShow(true)
		setNotFree(false)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setNotFree(false)
		setCurrentElement(element)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}

	const getPath = () => {
			return `DOMAINS/${domain}/contracts/${contract.id}/baseCompany/BASE/structures/${department.parent_list[0]}/pavillons/${department.parent_list[1]}/floors/${department.parent_list[2]}/departments/${department.id}`
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		if (name === 'uid') {
			setCurrentElement({ ...curElement, [name]: value, id: value })

		} else {
			setCurrentElement({ ...curElement, [name]: value })

		}
		setEditedElement(true)
	}


	const handleClose = () => setShow(false);

	const handleSave = async (force) => {
		console.log("____________________________________SAVE_____________________________", is_new)
		const d_data = await getBeacon(domain, contract.id, curElement.uid)
		console.log("GET DATA PREADD", d_data)
		if (d_data && !d_data.error) {
			setNotFree(true)
		} else {
			if (contract.id) {

				if (is_new || force) {
					const res = await addBeacon(domain, contract.id, curElement.id, curElement)
					console.log(res)
					//addBeacon()
				} else {
					const res = await updateBeacon(d_data, contract.id, curElement.id, curElement)

				}
			} else {
				alert("contratto non definito: eseguire nuovamente l'operazione")
			}
		}
		setShow(false)
		refreshData()
	}

	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNew()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('clauditpoint.buttons.clauditpoint_add')}</Button>
				)
				: <Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('clauditpoints.labels.new_clauditpoint')}</span>
							</>
							:
							<>
								<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('clauditpoints.labels.edit_clauditpoint')}</span>
							</>
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<span style={{fontSize:'0.8em'}}>

						${curElement.departmentPath}
						</span>
						<Form.Group as={Row} controlId="clauditpointFirstName" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">ID / MacAdress</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="uid" defaultValue={curElement.uid} onChange={(event) => onChangeHandler(event.currentTarget)} />
								<Form.Text className="text-muted">
									formato XX:XX: ... :XX
								</Form.Text>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="clauditpointdescription" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="description" defaultValue={curElement.description} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						{notFree &&
							<Row>
								<Col className="bg-warning">
									Beacon già associato ad un reparto. Spostare su questo reparto?
								</Col>
							</Row>
						}

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{notFree ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={() => handleSave(true)}>
							<FontAwesomeIcon icon="save" /> Sposta e aggiorna beacon
						</Button>
						:
						<Button disabled={curElement.uid.length === 0} className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
					}
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default IdentifierList