import React from 'react';
import { Form, Row, Col, InputGroup, Badge } from 'react-bootstrap';
import { useTranslate } from 'react-polyglot';
import {format, setMonth} from "date-fns"
import { it } from 'date-fns/locale'
import { getValue } from "../util/ui_utils.js"
import {frequency_dict} from "./constants/global_definitions"


const frequency_month_list = [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre'
]

const frequency_month_list_short = [
    'g',
    'f',
    'm',
    'a',
    'm',
    'g',
    'l',
    'a',
    's',
    'o',
    'n',
    'd'
]

const FrequencySelector = ({ mode, element, functions }) => {
    const t = useTranslate();
    const frequency_elements = frequency_dict({t})
    //console.log(mode, element)

    if (element.frequency_mode){
        element.frequency = {element:element.frequency_mode, times: element.frequency_times, start_month: element.frequency_start || 0}
    }
    if (mode === 'read') {
        if (element.frequency) {

            return (
                <>
                    <b>{element.frequency.times}</b> &nbsp;
                    {element.frequency && element.frequency.times > 1 ?
                        <>{t('frequency.labels.times')}&nbsp;</>
                        : <>{t('frequency.labels.time')}&nbsp;</>
                    }
                    {frequency_elements[element.frequency.element].label}
                </>

            )
        } else {
            return ''
        }
    }else if (mode === 'read_graphical'){
        if (element.frequency) {

            const sel_freq = frequency_elements[element.frequency.element]
            let month_interval = 1
            if (sel_freq.year_count<12){
                month_interval = 12/sel_freq.year_count
            }
            const items = []
            let sel_month = 0
            try{
                sel_month = element.frequency.start_month ? parseInt(element.frequency.start_month): 0
            }catch(err){

            }
            for (let m=0; m<12; m++){
                if(sel_month === m){
                    items.push(<Badge key={m} size="md" variant="warning">{format(setMonth(new Date(), m), 'LLLLL', { locale: it })}</Badge>)
                    sel_month = sel_month+month_interval
                }else{
                    items.push(<Badge key={m} size="md" variant="light">{format(setMonth(new Date(), m), 'LLLLL', { locale: it })}</Badge>)
                }

            }
            return (
                <>
                {items}
                </>

            )
        } else {
            return ''
        }


    } else {

        return (
            <>

                    <Form.Group as={Row} >
                        <Col >
                            <InputGroup>
                                <Form.Control type="number" min="1" name="times" value={getValue(element.frequency, 'times')} sub="frequency" onChange={(event) => functions.edit(event.currentTarget)} />
                                <InputGroup.Append>
                                <InputGroup.Text>
                                    {element.frequency && element.frequency.times > 1 ?
                                        <>{t('frequency.labels.times')}&nbsp;</>
                                        : <> {t('frequency.labels.time')}&nbsp;</>
                                    }
                                </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col >
                            <Form.Control as="select" value={getValue(element.frequency, "element")} name="element" sub="frequency" className="mb-2" onChange={(event) => functions.edit(event.target)}  >
                                {Object.keys(frequency_elements).map((fk, k) => (
                                    <option key={k} value={frequency_elements[fk].value} >
                                        {frequency_elements[fk].label}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col>
                        {element.frequency.element && frequency_elements[element.frequency.element] && frequency_elements[element.frequency.element].year_count<12?
                        <>
                            <Form.Control as="select" value={getValue(element.frequency, "start_month")} name="start_month" sub="frequency" className="mb-2" onChange={(event) => functions.edit(event.target)}  >
                                {frequency_month_list.map((f, k) => (
                                    <option key={k} value={k} >
                                        {f}
                                    </option>
                                ))}
                            </Form.Control>
                                        <Form.Text className="text-muted">
                                            Mese inizio indicativo
                                            </Form.Text>
                        </>:''}
                        </Col>
                    </Form.Group>
            </>
        )
    }
}
export default FrequencySelector