import {firebase, firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addSystemDocument = async ( data ) => {
    try {
        let db_data = {...data, "createdAt": firebase.firestore.Timestamp.now()};


        const res = await firestore.collection('SYSTEMDOCUMENT')
            .add(db_data);
        const newdata = {
            ...db_data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addSystemDocument error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getSystemDocumentById = async ( systemdocumentId) => {
    try {
        const res = await firestore.collection('SYSTEMDOCUMENT')
            .get(systemdocumentId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getSystemDocument error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getSystemDocumentList = async ({is_public, contract_type, query_cursor}) => {
    try {
            let query = firestore.collection('SYSTEMDOCUMENT')
                .orderBy('createdAt', 'desc')
//                .limit(limit)

            if( is_public && is_public !== undefined){
                query = query.where("is_public", "==", is_public)
            }

            if( contract_type && contract_type !== undefined){
                query = query.where("type_list", "array-contains", contract_type)
            }

            // if there is a pivot for query starting point
            if(query_cursor && query_cursor !== undefined){
                query = query.startAfter(query_cursor)
            }
                
            const res = await query.get();
            
            if( res.docs.length > 0){    // there is one or more results
                const last = res.docs[ res.docs.length - 1];
                const systemdocumentList = res.docs.map(doc => {
                    const firebaseData = doc.data()
                    const data = {
                        ...firebaseData,
                        id : doc.id,
                    }
                    return data
                })
    
                return {systemdocumentList: systemdocumentList, 
                        query_cursor:  last};  
            
            }else{  // no results
                return {systemdocumentList: [], 
                        query_cursor:  undefined};
            }    
    } catch (e) {
        console.log("getSystemDocumentList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const editSystemDocument = async ( systemdocumentId, data) => {
    try {
        const systemdocument =  firestore.collection('SYSTEMDOCUMENT')
            .doc(systemdocumentId);

        const res = await systemdocument.set(data)
            .then( () => {
                console.log("editSystemDocument - edit successfully")
                return data
            }).catch( error => {
                console.log("editSystemDocument - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editSystemDocument error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteSystemDocument = async ( systemdocumentId) => {
    try {
        const res = await firestore.collection('SYSTEMDOCUMENT')
            .doc(systemdocumentId).delete();
        return res
    }  catch (e) {
        console.log("deleteSystemDocument error: ", e)
        return { error: e }
    }
}


