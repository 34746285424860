import React, { useState, useContext, useEffect, useRef } from 'react';
import {Footer} from '../components/generic/Footer';
import {NavBarGlobal} from '../components/NavBar/NavBarGlobal'

import { Container, Row, Col, Button, ButtonGroup, Tooltip, OverlayTrigger, Popover, ListGroup, Spinner, Badge, Form} from 'react-bootstrap';
import PageTitle from "../components/PageTitle"
import { useParams } from "react-router-dom"

//import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

//import { serviceType_list, good_dict } from "../components/constants/global_definitions"



import firebase from 'firebase';


import {addCheckResults, getCheckResults} from "../components/FirebaseApi/CheckResultProxy"
import {getStatusSnapshotRange} from '../Firebase2/Storage2/dbStatusSnapshot'
import {getAmountRange} from '../Firebase2/Storage2/dbAmountRecords'
import {deleteTraining} from '../Firebase2/Storage2/dbTraining'




const companyId = "0"
const two_row_limit = 5
let load_page = false


const TestView = ({ t, uData }) => {
    const { domain } = useContext(DomainContext)
	const { contract, risk_area_list, risk_area_dict, } = useContext(GlobalDataContext)

	console.log('DOMAIN', domain)
	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()








	let { cid } = useParams();
	let contractId = cid






    const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)


	const [strStartDate, setStrStartDate] = useState("2021_05_12")
	const [strEndDate, setStrEndDate] = useState("2021_05_12")




    // === TEST addCheckResults ........
	const testButton = () =>{
		const check = {
			creation_date : firebase.firestore.Timestamp.now(),
			check_timing : [{start:  firebase.firestore.Timestamp.now(), end: firebase.firestore.Timestamp.fromDate(new Date(2021,4,20))}],
			end_check: firebase.firestore.Timestamp.now(),
			lqa : 0.5,
			lqr: 0.87,
			status:'completed',

		}
		addCheckResults({DomainId:domain, ContractId: contractId, StructId:'3UVoS0WHXR3U774BDGo8', ControlModeType:'autocheck_results', CheckResult: check})
	}


	// === TEST statusSnapshot .......
	const testButtonStatusSnapshot = async () =>{
		const res = await getStatusSnapshotRange(domain, contractId, '3UVoS0WHXR3U774BDGo8', strStartDate, strEndDate)
		console.log("=========== TEST ===========");
		console.log(`  ---> getStatusSnapshotRange : (${strStartDate}, ${strEndDate})`, res);
		res.map( (statusSnapshot) => {
			console.log("statusSnapshot: ", statusSnapshot)
		})
	}


	// === TEST amountRecords .......
	const testButtonAmountRecords = async () =>{
		const start = firebase.firestore.Timestamp.fromDate(new Date(2021,4,1))
		const end = firebase.firestore.Timestamp.fromDate(new Date(2021,4,30))

		const res = await getAmountRange(domain, contractId, start, end)
		console.log(`#### TEST ####  ---> getAmountRange :`, res);
		res.map( (record) => {
			console.log("record: ", record)
		})
	}



	// === TEST Training .......
	const testButtonDeleteTraining = async () =>{
		const res = await deleteTraining(domain, contractId, 'ZtDZQa88xZdLJ3DvUHR2')
		console.log("=========== TEST ===========");
		console.log(`  ---> deleteTraining: ${res}`);
	}



    
	const initValues = async () => {
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('test') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {
			contractId = contract.id
        }
    }



    useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("TEST reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("TEST init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useEffect(() => {
		console.log('TEST loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])





    return(
        <div>
            <NavBarGlobal/>
            PAGINA DI TEST
            <Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col >
						<PageTitle title="TEST getStatusSnapshotRange" />
						<Button onClick={testButtonStatusSnapshot} disabled>TEST statusSnapshot</Button>
						<Row><Form.Control type="text" placeholder="start" value={strStartDate} onChange={ (event) => setStrStartDate(event.currentTarget.value)}/></Row>
						<Row><Form.Control type="text" placeholder="end" value={strEndDate} onChange={ (event) => setStrEndDate(event.currentTarget.value)}/> </Row>
					</Col>
                </Row>
				<Row className="mt-1">
					<Col >
						<PageTitle title="TEST amountRecords" />
						<Button onClick={testButtonAmountRecords} disabled>TEST amountRecords</Button>
					</Col>
                </Row>

				<Row className="mt-1">
					<Col >
						<PageTitle title="TEST Training" />
						<Button onClick={testButtonDeleteTraining}>TEST Delete Training</Button>
					</Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    )

}

export default (TestView)