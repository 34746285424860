import React, { useState, forwardRef, useImperativeHandle, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DateTime from 'react-datetime';
import 'react-day-picker/lib/style.css';
import { format, getDay, differenceInMinutes, set, addHours } from 'date-fns'
import { it } from 'date-fns/locale'

import firebase from 'firebase/app'
import GlobalDataContext from '../contexts/globaldataContext.js';

import { myTimestampToDate, myDateToTimestamp, getValue, getSelectedElementB, getFormattedDate } from "../../util/ui_utils.js"
import { ServiceBadge, RiskareaBadge, RoomInfoBadge, Frequency, IsModified } from '../StructureGraphicElements.js'
import { interventionType_dict } from '../constants/global_definitions.js';






///////////////////////////////////////////
const ModalRequestGenerator = forwardRef((props, ref) => {
    const def_dialog = {
        message: '',
        title: 'conferma',
    }
    const intervention_type_dict = interventionType_dict({ t: props.t })
    const { service_dict, contract } = useContext(GlobalDataContext)
    const [curTicket, setCurTicket] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [editedElement, setEditedElement] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState(def_dialog)
    const [editDateReference, setEditDateReference] = useState({ start: new Date(), end: new Date() })

    useImperativeHandle(ref, () => ({
        setTicket(event, saveCallback, cref, info) {
            console.log(cref, info)
            setConfirmDialog({
                event: event,
                saveCallback: saveCallback,
                cref: cref,
                info: info,
            })
            /*  try {
                 setEditDateReference({ start: Object.assign(event.start), end: Object.assign(event.end) })
             } catch (err) {
                 console.log(err)
             } */
            setCurTicket(event)
            setShowModal(true)
            setEditedElement(false)
        }
    }));


    const handleClose = () => {
        if (confirmDialog.undoCallback) {
            try {
                confirmDialog.undoCallback()
            } catch (err) {
                console.log("(DeleteconfirmationSection) ", err)
            }
        }
        setShowModal(false)
    }



    const handleSave = async () => {
        let _event = { ...curTicket }
        if (confirmDialog.saveCallback) {
            try {
                confirmDialog.saveCallback(_event, confirmDialog.cref, confirmDialog.info)
            } catch (err) {
                console.log("|||||error|||||", err)
            }

        }
        setShowModal(false)
    }

    const handleRecover = () => {
        let _event = { ...curTicket, start: firebase.firestore.Timestamp.fromDate(curTicket.start), end: firebase.firestore.Timestamp.fromDate(curTicket.end), extendedProps: { ...curTicket.extendedProps, is_deleted: false } }
        delete _event.extendedProps.summary
        delete _event.extendedProps.intervention_data
        if (confirmDialog.saveCallback) {
            try {
                confirmDialog.saveCallback(_event, confirmDialog.cref, confirmDialog.info)
            } catch (err) {
                console.log("|||||error|||||", err)
            }
        }
        setShowModal(false)
    }
    const handleDelete = () => {
        console.log(curTicket)
        let _event = {
            ...curTicket,
            start: firebase.firestore.Timestamp.fromDate(curTicket.start),
            end: curTicket.end !== null ? firebase.firestore.Timestamp.fromDate(curTicket.end) : firebase.firestore.Timestamp.fromDate(addHours(curTicket.start, 1)),
            extendedProps: { ...curTicket.extendedProps, is_deleted: true }
        }
        delete _event.extendedProps.summary
        delete _event.extendedProps.intervention_data
        if (confirmDialog.saveCallback) {
            try {
                confirmDialog.saveCallback(_event, confirmDialog.cref, confirmDialog.info)
            } catch (err) {
                console.log("|||||error|||||", err)
            }
        }
        setShowModal(false)

    }

    const onChangeHandler = (event) => {
        let { name, value, type } = event;
        console.log(name, value)
        if (name === 'start' || name === 'end') {
            try {
                value = value.toDate()
                const ds = (name === 'start') ? myTimestampToDate(editDateReference.start) : myTimestampToDate(editDateReference.end)
                console.log(ds)
                value = set(value, { date: ds.getDate(), month: ds.getMonth(), year: ds.getFullYear() })
                //value.setDate(curEvent.extendedProps.originalEnd.getDate())
                //value.setMonth(curEvent.extendedProps.originalEnd.getMonth())
            } catch (err) {
                console.error(err)
            }
            if (!curTicket.extendedProps.is_modified) {
                setCurTicket({ ...curTicket, [name]: value, extendedProps: { ...curTicket.extendedProps, is_modified: true, originalStart: myDateToTimestamp(curTicket.start), originalEnd: myDateToTimestamp(curTicket.end) } })
            } else {
                setCurTicket({ ...curTicket, [name]: value })
            }
        } else if (name === 'date') {
            let _start = value.toDate()
            let _end = value.toDate()
            _start = set(_start, { hours: curTicket.start.getHours(), minutes: curTicket.start.getMinutes(), seconds: 0 })
            _end = set(_end, { hours: curTicket.end.getHours(), minutes: curTicket.end.getMinutes(), seconds: 0 })
            //console.log(_start, _end)

            setEditDateReference({ start: Object.assign(_start), end: Object.assign(_end) })
            if (!curTicket.extendedProps.is_modified) {
                setCurTicket({ ...curTicket, start: _start, end: _end, extendedProps: { ...curTicket.extendedProps, is_modified: true, originalStart: myDateToTimestamp(curTicket.start), originalEnd: myDateToTimestamp(curTicket.end) } })
            } else {
                setCurTicket({ ...curTicket, start: _start, end: _end })
            }

        } else {
            if (type === 'radio') {
                if (value === 'true') {
                    value = true
                } else if (value === 'false') {
                    value = false
                }
            }
            let newprops = { ...curTicket.extendedProps, [name]: value }
            setCurTicket({ ...curTicket, extendedProps: newprops })
        }
        setEditedElement(true)
    }




    return <div className="request_container">
        {curTicket.service ?
            <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal" backdrop="static" className="bg-secondary" scrollable={true}>
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
                    <Modal.Title>
                            <FontAwesomeIcon icon="plus-square" className="mr-1" /> Crea nuovi interventi a richiesta
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row className="border-bottom pb-1 mb-1">
                            <Col>
                                <ServiceBadge service={service_dict[curTicket.service.id]} freq={true} />
                            </Col>
                        </Row>
                        {/*                             <Form.Group as={Row} className="border-bottom pb-1">
                                <Form.Label column sm="2">{props.t('workareas.labels.date')}</Form.Label>
                                <Col sm="6">
                                    <DateTime initialValue={myTimestampToDate(curTicket.start)} dateFormat={'DD/MM/yyyy'} timeFormat={false} name="date" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'date', value: e })} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="border-bottom pb-1">
                                <Form.Label column sm="2">{props.t('workareas.labels.start_time')}</Form.Label>
                                <Col sm="6">
                                    <DateTime initialValue={myTimestampToDate(curTicket.start)} dateFormat={false} timeFormat='HH:mm' name="start" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'start', value: e })} />
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row} className="border-bottom pb-1">
                                <Form.Label column sm="2">{props.t('workareas.labels.end_time')}</Form.Label>
                                <Col sm="6">
                                    <DateTime initialValue={myTimestampToDate(curTicket.end)} dateFormat={false} timeFormat='HH:mm' name="end" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'end', value: e })} />
                                </Col>
                            </Form.Group> */}

                        <Row className="border-bottom pb-1">
                            <Form.Label column sm="2">{props.t('global.labels.note')}</Form.Label>
                            <Col>
                                <Form.Control as="textarea" rows="3" name="note" defaultValue={curTicket.note} onChange={(event) => onChangeHandler(event.currentTarget)} className="mb-2" />
                            </Col>
                        </Row>
                        <Row>
                            {curTicket.room_list ?
                                <Col>
                                    <Row className="p-1 text-light mt-4">
                                        <Col className="ml-1 text-info" >{props.t('workarea.labels.roomlist')}</Col>
                                    </Row>
                                    <Row className="p-1 mb-1">
                                        <Col className="ml-1 bg-secondary text-light" sm="3" >
                                            <Row className="border-bottom border-light">
                                                <Col sm="2" className="text-warning">
                                                    Seleziona
                                                </Col>
                                                <Col>
                                                    {props.t('global.labels.name')}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {props.t('structure.labels.structure')}
                                                </Col>
                                                <Col>
                                                    {props.t('global.labels.pavillon')}
                                                </Col>
                                                <Col>
                                                    {props.t('structure.labels.floor')}
                                                </Col>
                                                <Col>
                                                    {props.t('global.labels.department')}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="ml-1 bg-secondary text-light" >{props.t('structures.labels.room_type')}</Col>
                                        <Col className="ml-1 bg-secondary text-light text-center" sm="1" >m<sup>2</sup></Col>
                                        <Col className="ml-1 bg-secondary text-light text-center" sm="1" >
                                            {contract && contract.type === 'civil' ?
                                                props.t('global.labels.standard_cleaning_short')
                                                :
                                                props.t('global.labels.risk_area_short')
                                            }
                                        </Col>
                                        <Col className="ml-1 bg-secondary text-light" sm="1">Cdc</Col>
                                        <Col className="ml-1 bg-secondary text-light" >Data</Col>
                                        <Col className="ml-1 bg-secondary text-light" >Dalle</Col>
                                        <Col className="ml-1 bg-secondary text-light" >Alle</Col>
                                        <Col className="ml-1 bg-secondary text-light" > Area di lavoro</Col>
                                    </Row>
                                    {curTicket.room_list.map((room, index) => (
                                        <OneRoomRow key={index} index={index} room={room} intervention_type={curTicket.type} curEvent={curTicket} t={props.t} ticket={curTicket} functions={{ edit: onChangeHandler }} permission_data={props.permission_data} />
                                    ))}
                                </Col>
                                : <></>}
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {props.t('modal.close')}
                    </Button>
                    {props.canWrite ?
                        <>
                            <Button className="btn-danger" disabled={!isValid(curTicket)} onClick={handleDelete}>
                                <FontAwesomeIcon icon="trash" /> {props.t('ticket.modal.delete_request')}
                            </Button>
                            <Button disabled={!isValid(curTicket)} className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
                                <FontAwesomeIcon icon="save" /> {props.t('modal.create_intervention')} ({curTicket.room_list.filter(r => r.to_assign).length})
                            </Button>
                        </>
                        : ''}
                </Modal.Footer>
            </Modal>
            : <></>}
    </div>
})



/* 

const getIntervetionTitle = (start, end) => {
    try {
        return (<>
            {format(start, 'cccc dd/MM (HH:mm', { locale: it })} - {format(end, 'HH:mm)', { locale: it })}
        </>)
    } catch (err) {
        return <>Data non valida</>

    }
}


const getIntervetionLength = (start, end) => {
    try {
        return (<>{differenceInMinutes(end, start)} minuti </>)

    } catch (err) {
        return <></>

    }
}

 */




const isValid = (ev) => {
    try {
        const rl = ev.room_list.filter(r => {

            const d = differenceInMinutes(r.end, r.start)
            return r.to_assign && !isNaN(d) && d > 0
        })
        return rl.length > 0
    } catch (err) {
        return false
    }
}



// --------------------------------------------------------
const OneRoomRow = ({ room, index, t, ticket, functions }) => {
    const { structureElements, risk_area_dict } = useContext(GlobalDataContext)
    const _room = structureElements.all_room_dict[room.id]


    const onChangeHandlerRoom = (el, index) => {
        let editlist = [...ticket.room_list]
        let { name, value, type } = el;
        console.log(name, value, type)
        if (type === 'checkbox') {
            let oldcheck = true
            if (el.checked) {
                oldcheck = true
            } else {
                oldcheck = false
            }
            value = oldcheck
        }
        if (name === 'start' || name === 'end') {
            try {
                value = value.toDate()
                const ds = (name === 'start') ? room.start : room.end
                console.log(ds)
                value = set(value, { date: ds.getDate(), month: ds.getMonth(), year: ds.getFullYear() })
                //value.setDate(curEvent.extendedProps.originalEnd.getDate())
                //value.setMonth(curEvent.extendedProps.originalEnd.getMonth())
                editlist[index][name] = value
            } catch (err) {
                console.error(err)
            }
        } else if (name === 'date') {
            let _start = value.toDate()
            let _end = value.toDate()
            _start = set(_start, { hours: room.start.getHours(), minutes: room.start.getMinutes(), seconds: 0 })
            _end = set(_end, { hours: room.end.getHours(), minutes: room.end.getMinutes(), seconds: 0 })
            editlist[index].start = _start
            editlist[index].end = _end

        } else {
            console.log(index, editlist[index])
            editlist[index][name] = value
        }
        functions.edit({ name: 'room_list', value: editlist })
    }
    return (
        <Row key={room.id + index} className='border-bottom border-left pb-1 mt-1 bg-light border-success' >
            <Col sm="3">
                <Row>
                    <Col sm="2">
                        {room.assigned ?
                            <>
                        <OverlayTrigger overlay={<Tooltip>locale già assegnato ad un intervento</Tooltip>} trigger={['focus', 'hover']} rootClose placement='right-start'>
                                <FontAwesomeIcon icon="check-square" />
                        </OverlayTrigger>)
                            </>
                            : <></>}
                        {room.assigned || !room.permission ?
                            <></>
                            :
                            <Form.Check type="checkbox" className="ml-2" name="to_assign" checked={room.to_assign} onChange={(event) => onChangeHandlerRoom(event.currentTarget, index)} />
                        }
                    </Col>
                    <Col>
                        {_room.name}
                    </Col>
                </Row>
                <Row>
                    <Col className="section_title text-muted">
                        <RoomInfoBadge parent_list={_room.parent_list} expanded={true} />
                    </Col>
                </Row>
            </Col>
            <Col className="text-muted">
                {_room.roomtype.name}
            </Col>
            <Col sm="1">{_room.size}</Col>
            <Col sm="1" className="text-center">
                <RiskareaBadge riskarea={risk_area_dict[_room.risk_area.Id]} />
            </Col>
            <Col sm="1">
                {getValue(_room, 'cdc')}
            </Col>
            <Col >
                <DateTime inputProps={{ disabled: !room.to_assign }} initialValue={myTimestampToDate(room.start)} dateFormat={'DD/MM/yyyy'} timeFormat={false} name="date" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerRoom({ name: 'date', value: e }, index)} />
            </Col>
            <Col>
                <DateTime inputProps={{ disabled: !room.to_assign }} initialValue={myTimestampToDate(room.start)} dateFormat={false} timeFormat='HH:mm' name="start" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerRoom({ name: 'start', value: e }, index)} />
            </Col>

            <Col >
                <DateTime inputProps={{ disabled: !room.to_assign }} initialValue={myTimestampToDate(room.end)} dateFormat={false} timeFormat='HH:mm' name="end" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerRoom({ name: 'end', value: e }, index)} />
            </Col>
            <Col >
                {room.w_list.length > 0 ?
                    <Form.Control disabled={!room.to_assign} as="select" className="mb-2" name="workarea_id" value={room.workarea_id} onChange={(event) => onChangeHandlerRoom(event.currentTarget, index)} >
                        <option value="-1" >{t('ticket.labels.select_workarea')}</option>
                        {room.w_list.map((w, kk) => (
                            <option key={kk} value={w.id} >{w.name}</option>
                        ))}
                    </Form.Control>
                    : <i>
                        Locale non inserito in nessuna area di lavoro
                    </i>}
            </Col>
        </Row>
    )

}

export default ModalRequestGenerator