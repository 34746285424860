import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Spinner, ButtonGroup, Modal, Image, Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { checkIfImageExists } from '../../util/ui_utils';
import { MediaPreview } from '../StructureGraphicElements';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.js',
	import.meta.url,
).toString();
export const ModalDocument = ({ t, photo, name, data }) => {
	const [showEdit, setOpen] = useState(false)
	const [isImage, setIsImage] = useState(false)
	const [isPdf, setIsPdf] = useState(false)
	const [pageNumber, setPageNumber] = useState(1)
	const [numPages, setNumPages] = useState(0);

	const handleClose = () => {
		setOpen(false)
	}
	const ref = useRef()

	useEffect(() => {
		setIsPdf(false)
		checkIfImageExists(photo, (is) => {
			setIsImage(is)
		})
		/* try {
			if (name.endsWith('.pdf')) {
				setIsPdf(true)
			} else {
				setIsPdf(false)
			}
		} catch (err) {
			console.log(err, name)
			setIsPdf(false)
		} */
	}, [])

	const downloadFile = async (url) => {
		const res = await axios.get(url)
		console.log(res)
		const xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		const a = document.createElement("a");
		xhr.onload = (event) => {
			const blob = xhr.response;
			a.href = window.URL.createObjectURL(new Blob([blob], {
				type: res.headers['content-type']
			}));
			a.download = name
			a.click();
		};
		xhr.open('GET', url);
		xhr.send();
	}
	function onDocumentLoadSuccess(numPages) {
		console.log("PDF", numPages._pdfInfo)
		setNumPages(numPages._pdfInfo.numPages);

	}
	return (
		<>
			{name && name.length > 0 ?
				<OverlayTrigger trigger={['hover', 'focus']} placement='right-start' overlay={<Popover id="popover-basic" className="bg-info border border-info" container={ref} placement='right-start'>
					<Popover.Content>
						{name}
					</Popover.Content>
				</Popover>}>
					<Button variant="outline-primary" onClick={() => setOpen(true)}>
						{isImage ?
							<Image src={photo} style={{ maxHeight: '35px' }} fluid alt="image" />
							:
							<FontAwesomeIcon icon="file" />
						}

					</Button>
				</OverlayTrigger>
				:
				<Button variant="outline-primary" onClick={() => setOpen(true)}>
					{isImage ?
						<Image src={photo} style={{ maxHeight: '35px' }} fluid alt="image" />
						:
						<FontAwesomeIcon icon="file" />
					}

				</Button>
			}
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size={isImage || isPdf ? "xl" : 'md'}>
				<Modal.Body>
					<Container fluid>
						{name}<br />
						{isImage &&
							<Image src={photo} fluid />
						}
						{isPdf &&
							<Document file={photo} onLoadSuccess={onDocumentLoadSuccess} className="text-center">
								<Page pageNumber={pageNumber} height={500} className="bg-secondary p-2 text-center" />
							</Document>
						}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Container fluid>

						<Row>
							{isPdf &&
								<Col>
									<ButtonGroup>
										<Button disabled={pageNumber === 1} onClick={() => setPageNumber(n => n - 1)}><FontAwesomeIcon icon="arrow-circle-left" /></Button>
										<Button disabled>{pageNumber} / {numPages}</Button>
										<Button disabled={pageNumber === numPages} onClick={() => setPageNumber(n => n + 1)}><FontAwesomeIcon icon="arrow-circle-right" /></Button>
									</ButtonGroup>
								</Col>

							}
							<Col>
								<Button onClick={() => downloadFile(photo)} >DOWNLOAD</Button>
							</Col>
							<Col className="text-right">
								<Button variant="secondary" onClick={handleClose}>
									{t('modal.close')}
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}