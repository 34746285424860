import React, { createContext, useState } from 'react'
import { checkComponentPresence } from '../../util/ui_utils'

const EditContext = createContext()

export const EditProvider = (props) => {
    const [editGlobal, setEditGlobal] = useState(false)
    const [editMultiple, setEditMultiple] = useState(false)
    const [editList, setEditList] = useState([])
    const [editParamList, setEditParamList] = useState([])
    const [workareaRoomList, setWorkareaRoomList] = useState([])
    const [filterUnusedRoom, setFilterUnusedRoom] = useState(false)
    const [editParentFunctions, setEditParentFunctions] = useState({})

    const resetEditList = () => {

        setEditList([])
        setEditParentFunctions({})
        setEditParamList([])
    }


    const toggleEditList = (elem, parent_id, parent, parent_function) => {
        const c = checkComponentPresence(elem, editList, 'id')
        if (c === -1) {
            setEditList((e) => [...e, elem])
            let tempPList = { ...editParentFunctions }
            tempPList[parent_id] = { edit: parent_function, parent: parent }
            setEditParentFunctions(tempPList)
        } else {
            const e_list = [...editList]
            e_list.splice(c, 1)
            setEditList(e_list)
        }
    }

    const multiToggleEditList = (parent, parent_function, toggle) => {
        let tmp_list = []
        let add_parent = true
        let tempPList = { ...editParentFunctions }
        if (toggle) {
            let e_list = [...editList]
            parent.childs.map(elem => {
                const c = checkComponentPresence(elem, e_list, 'id')
                e_list.splice(c, 1)
                return true
            })
            setEditList(e_list)
            delete tempPList[parent.id]
        } else {
            parent.childs.map(elem => {
                const c = checkComponentPresence(elem, editList, 'id')
                if (c === -1) {
                    tmp_list.push(elem)
                } else {
                    add_parent = false
                }
                return true
            })
            setEditList([...editList, ...tmp_list])
            if (add_parent) {
                tempPList[parent.id] = { edit: parent_function, parent: parent }
            }
        }
        setEditParentFunctions(tempPList)
    }

    const toggleEditParamList = (elemId) => {
        const c = editParamList.indexOf(elemId)
        if (c === -1) {
            setEditParamList([...editParamList, elemId])

        } else {
            const e_list = [...editParamList]
            e_list.splice(c, 1)
            setEditParamList(e_list)
        }
    }

    return (
        <EditContext.Provider
            value={{
                editGlobal,
                setEditGlobal,
                editMultiple,
                setEditMultiple,
                editList,
                setEditList,
                toggleEditList,
                multiToggleEditList,
                editParamList,
                setEditParamList,
                toggleEditParamList,
                resetEditList,
                editParentFunctions,
                workareaRoomList,
                setWorkareaRoomList,
                filterUnusedRoom,
                setFilterUnusedRoom

            }}>

            {props.children}
        </EditContext.Provider>
    )
}
export default EditContext