import {functions} from '../../Firebase2/firebaseIndex'


// ============================================================================
//
//
// ============================================================================
export const getCheckResults = ( { DomainId, ContractId, StructId, ControlModeType, FromDate, ToDate} ) => {
  console.log(DomainId, ContractId, StructId, ControlModeType, FromDate, ToDate)
    const data ={ DomainId: DomainId,
        ContractId: ContractId,
        StructId: StructId,
        ControlModeType: ControlModeType,
        FromDate: FromDate,
        ToDate: ToDate,
    }

    
    const res = functions
          .httpsCallable('getCheckResult')(data)
          .then(result => {
            console.log("Result:  ", result)
            return result.data;
          }) 
          .catch((error) => {
            console.log("Error calling cloud function:  ", error)
          })
    return res
}


// ============================================================================
// 
//
// ============================================================================
export const addCheckResults = ( { DomainId, ContractId, StructId, ControlModeType, CheckResult} ) => {
    const data ={ DomainId: DomainId,
        ContractId: ContractId,
        StructId: StructId,
        ControlModeType: ControlModeType,
        CheckResult: CheckResult,
    }

    functions
          .httpsCallable('addCheckResult')(data)
          .then(result => {
            console.log("Result: ", result)
            return result.data;
          }) 
          .catch((error) => {
            console.log("Error calling cloud function: ", error)
          })
}







// ============================================================================
//
//
// ============================================================================
export const getGroupCheckResults = ( { DomainId, ContractId, GroupId, ControlModeType, FromDate, ToDate} ) => {
  console.log(DomainId, ContractId, GroupId, ControlModeType, FromDate, ToDate)
    const data ={ DomainId: DomainId,
        ContractId: ContractId,
        GroupId: GroupId,
        ControlModeType: ControlModeType,
        FromDate: FromDate,
        ToDate: ToDate,
    }

    
    const res = functions
          .httpsCallable('getGroupCheckResult')(data)
          .then(result => {
            console.log("Result: ", result)
            return result.data;
          }) 
          .catch((error) => {
            console.log("Error calling cloud function: ", error)
          })
    return res
}


// ============================================================================
//
//
// ============================================================================
export const addGroupCheckResults = ( { DomainId, ContractId, GroupId, ControlModeType, CheckResult} ) => {
    const data ={ DomainId: DomainId,
        ContractId: ContractId,
        GroupId: GroupId,
        ControlModeType: ControlModeType,
        CheckResult: CheckResult,
    }

    functions
          .httpsCallable('addGroupCheckResults')(data)
          .then(result => {
            console.log("Result: ", result)
            return result.data;
          }) 
          .catch((error) => {
            console.log("Error calling cloud function: ", error)
          })
}