import { firestore } from '../firebaseIndex'

export const addRoomType = async (domainId, contractId, data) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addRoomType error: ", e)
        return { error: e }
    }
}

export const getRoomTypeById = async (domainId, contractId, roomTypeId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .get(roomTypeId);

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id: res.id,
        }
        return data;
    } catch (e) {
        console.log("getRoomType error: ", e)
        return { error: e }
    }
}

export const getRoomTypeList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .get();

        console.log("RES", res)
        const roomTypeList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return roomTypeList;

    } catch (e) {
        console.log("getRoomTypeList error: ", e)
        return { error: e }
    }
}



// ****************************************************************************
// Get roomType list with pagination
export const getRoomTypeList_page = async (domainId, contractId, query_cursor, limit = 25) => {
    try {
        let query = firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')

         // if there is a pivot for query starting point
         if(query_cursor && query_cursor !== undefined){
            query = query.startAfter(query_cursor)
        }

        const res = await query
            .limit(limit)
            .get();

        // DEBUG: console.log("RES", res)
 
        let last = null;
        if( res.docs.length > 0){    // there is one or more results
            last = res.docs[ res.docs.length - 1];
        }
 
        const roomTypeList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return {roomTypeList: roomTypeList,
            query_cursor: last};

    } catch (e) {
        console.log("getRoomTypeList error: ", e)
        return { error: e }
    }
}

export const editRoomType = async (domainId, contractId, roomTypeId, data) => {
    try {
        const doc = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .doc(roomTypeId);

        const res = await doc.set(
            data
        );

        return res
    } catch (e) {
        console.log("editRoomType error: ", e)
        return { error: e }
    }
}


export const deleteRoomType = async (domainId, contractId, roomTypeId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomtypes')
            .doc(roomTypeId).delete();
        return res
    } catch (e) {
        console.log("deleteRoomType error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////



