import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, ButtonGroup, Spinner, Badge, ProgressBar, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { addMinutes, format, subDays, isSameDay } from 'date-fns';
import { it } from 'date-fns/locale'
import DateTime from 'react-datetime';
import { useReactToPrint } from 'react-to-print';

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import { firebase } from '../Firebase2/firebaseIndex'

import { getElementPreview, getValue, getSelectedElement, myTimestampToDate, getSelectedElementB, compareStartDateParam, getBaseObjectValue, filterWeekDay, getStructureFromWorkareas } from "../util/ui_utils.js"


import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import SearchSection from "../components/SearchSection"

import { EventRoomList } from '../components/RoomSelector'


import { getInterventionLogList } from '../Firebase2/Storage2/dbInterventionLog';
import { getInterventionById as getInterventionById2 } from '../Firebase2/Storage2/dbIntervention'
import { ServiceBadge, StructureGroupIcon } from '../components/StructureGraphicElements';
import { addDays } from '@fullcalendar/react';
import PrintFooter from '../components/generic/PrintFooter';
import { getEmployerById } from '../Firebase2/Storage2/dbEmployers';




//const contractId = sessionStorage.getItem('contractId')


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function getElementCount(elem_list, filterList, searchTerm) {
	if (elem_list) {
		const filt = elem_list.filter(i => (filterList.length === 0 || filterList.indexOf(i.type) >= 0))
			.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
		if ((filterList.length === 0 && searchTerm.length < 2) || filt.length === elem_list.length) {
			return elem_list.length
		} else {
			return filt.length + "/" + elem_list.length
		}
	} else {
		return undefined

	}
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ExecutionreportsView = ({ t, uData }) => {
	let load_page = false
	const { domain, domain_data } = useContext(DomainContext)

	const { contract, structureElements } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()
	let { cid } = useParams();

	let contractId = cid
	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)
	const [selectedStartDate, setSelectedStartDate] = useState(new Date())
	const [selectedEndDate, setSelectedEndDate] = useState(new Date())
	const [intervention_dict, setInterventionDict] = useState({})
	const [show_duration, setShowDuration] = useState('day')





	useEffect(() => {
		console.log("CHANGE DATE")
		let d_start = new Date(selectedStartDate)
		let d_end = new Date(selectedEndDate)
		if (d_start <= d_end) {

			d_start.setHours(0)
			d_start.setMinutes(0)
			d_end.setHours(23)
			d_end.setMinutes(59)
			loadReport(d_start, d_end)
		}
	}, [selectedStartDate, selectedEndDate])

	const loadReport = async (start, end) => {
		setLoading(true)
		console.log(start, end)
		let temp_intervention_dict = { ...intervention_dict }
		const ev_status_list = await getInterventionLogList(domain, contractId, null, null, firebase.firestore.Timestamp.fromDate(start), firebase.firestore.Timestamp.fromDate(end))
		let operator_dict = {}
		for (const evs of ev_status_list) {
			console.log("EV", evs)
			const intervention = temp_intervention_dict[evs.intervention_id] || await getInterventionById2(domain, contractId, evs.intervention_id)
			console.log("intervention", intervention)
			if (evs.operator_data) {
				operator_dict[evs.operator_id] = evs.operator_data
			} else {
				if (!operator_dict[evs.operator_id]) {
					const op = await getEmployerById(domain, contractId, evs.operator_id)
					console.log("OPERATOR", op)
					if (!op.error) {
						operator_dict[evs.operator_id] = op
					}
				}
				if (operator_dict[evs.operator_id]) {
					console.log("---update operator", operator_dict[evs.operator_id])
					evs.operator_data = operator_dict[evs.operator_id]
				}
			}
			if (intervention) {
				evs.intervention_data = intervention
				temp_intervention_dict[evs.intervention_id] = intervention
			}
		}
		console.log(ev_status_list)
		setInterventionDict(temp_intervention_dict)
		setElementList(ev_status_list.sort(compareStartDateParam))
		setLoading(false)

	}

	const [filterAppliedList, setFilterAppliedList] = useState({ type: [] })

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		console.log('index', c)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}

	useEffect(() => {
		if (show_duration === 'month') {

		} else if (show_duration === 'week') {

		} else {

		}

	}, [show_duration])

	const initValues = async () => {
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('executionreports') !== -1)) {
			setWritePage(true)
		}
		let d_start = new Date()
		let d_end = new Date()
		d_start.setHours(0)
		d_start.setMinutes(0)
		d_end.setHours(23)
		d_end.setMinutes(59)
		setLoading(true)
		if (contract && domain.length > 0) {
			load_page = true
			loadReport(d_start, d_end)
		} else {
			console.log("init contract return")
		}
		setLoading(false)
	}





	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("executionreport reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("EXECUTIONREPORT init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useEffect(() => {
		console.log('executionreport loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])

	const valid = function (current) {
		return current.isBefore(new Date())
	}

	return (
		<div>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col sm="6">
						<PageTitle title={t('global.pages.executionreport')} counter={<b>{format(selectedStartDate, 'dd/MM/yy', { locale: it })} - {format(selectedEndDate, 'dd/MM/yy', { locale: it })}</b>} />
					</Col>
					<Col className="text-center">
						<ButtonGroup size="sm">
							<Button size="sm" variant="outline-secondary" disabled >Dal</Button>
							<Button size="sm" variant="secondary" onClick={() => setSelectedStartDate(subDays(selectedStartDate, 1))} ><FontAwesomeIcon icon="arrow-circle-left" /> </Button>
							<Button size="sm" variant={isSameDay(selectedStartDate, new Date()) ? "secondary" : "outline-secondary"} onClick={() => setSelectedStartDate(new Date())} ><FontAwesomeIcon icon="home" /> </Button>
							<DateTime isValidDate={valid} value={selectedStartDate} dateFormat='DD/MM/YYYY' timeFormat={false} locale="it" closeOnSelect={true} name="creation_date" onChange={(e) => setSelectedStartDate(e.toDate())} />
							<Button size="sm" variant="secondary" disabled={isSameDay(selectedStartDate, new Date())} onClick={() => setSelectedStartDate(addDays(selectedStartDate, 1))} ><FontAwesomeIcon icon="arrow-circle-right" /> </Button>
						</ButtonGroup>
					</Col>
					<Col className="text-center">
						<ButtonGroup size="sm">
							<Button size="sm" variant="outline-secondary" disabled >Al</Button>
							<Button size="sm" variant="secondary" onClick={() => setSelectedEndDate(subDays(selectedEndDate, 1))} ><FontAwesomeIcon icon="arrow-circle-left" /> </Button>
							<Button size="sm" variant={isSameDay(selectedEndDate, new Date()) ? "secondary" : "outline-secondary"} onClick={() => setSelectedEndDate(new Date())} ><FontAwesomeIcon icon="home" /> </Button>
							<DateTime isValidDate={valid} value={selectedEndDate} dateFormat='DD/MM/YYYY' timeFormat={false} locale="it" closeOnSelect={true} name="creation_date" onChange={(e) => setSelectedEndDate(e.toDate())} />
							<Button size="sm" variant="secondary" disabled={isSameDay(selectedEndDate, new Date())} onClick={() => setSelectedEndDate(addDays(selectedEndDate, 1))} ><FontAwesomeIcon icon="arrow-circle-right" /> </Button>
						</ButtonGroup>
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1">{t('global.labels.intervention')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.service')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.date')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.programmed_intervention_time')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.execution_time')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.validation')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.map((e, key) => (
									<OneReport e={e} key={e.id} t={t} />
								))}
							</>
							:
							<i>
								{t('executionreports.messages.no_executionreport')}
							</i>
						}
					</>
				}
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount(element_list, filterAppliedList.type, searchTerm) + " " + t('global.labels.executionreport')} />
		</div>
	);
}



const OneReport = ({ e, t }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, roomtype_dict, service_dict, structureElements } = useContext(GlobalDataContext)
	const [showRoom, setShowRoom] = useState(false)
	const [intervention, setIntervention] = useState(e.intervention_data)
	const [filtered_room_list, setFilteredRoomList] = useState([])

	const componentRef = useRef();

	useEffect(() => {

		if (Object.keys(roomtype_dict).length > 0 && e.intervention_data) {

			setIntervention(e.intervention_data)
			const _intervention = e.intervention_data
			const _filtered_room_list = (_intervention.type === 'periodic') ? _intervention.room_list :
				_intervention.room_list.filter(room => {
					const rt = roomtype_dict[room.roomtype.id] || roomtype_dict[room.roomtype.id.id]

					return filterWeekDay(getSelectedElementB(
						_intervention.service.id, rt.service_list, 'service', 'id'
					).weekdays, e.start, _intervention.custom_weekdays, room.id)
				}
				)
			setFilteredRoomList(_filtered_room_list)
		}

	}, [roomtype_dict])


	const handlePrint = useReactToPrint({
		pageStyle: pageStyle,
		content: () => componentRef.current
	});

	if (intervention && Object.keys(roomtype_dict).length > 0) {


		return (
			<Row className="p-1 border-bottom">
				<Col>
					<Row>
						<Col>{intervention.name}</Col>
						<Col>
							<ServiceBadge service={service_dict[getBaseObjectValue(intervention, 'service', 'id')]} freq={true} />
						</Col>
						<Col>
							{format(myTimestampToDate(e.start), 'ccc dd/MM', { locale: it })}
						</Col>
						<Col >
							<i>
								{format(myTimestampToDate(intervention.start), 'HH:mm', { locale: it })}&nbsp;-&nbsp;
								{format(myTimestampToDate(intervention.end), 'HH:mm', { locale: it })}
							</i>
						</Col>
						<Col>
							{format(myTimestampToDate(e.start), 'HH:mm', { locale: it })}&nbsp;-&nbsp;
							{format(myTimestampToDate(e.end), 'HH:mm', { locale: it })}
						</Col>
						<Col>
							{e.validate_status ?
								<Validation validate_status={e.validate_status} end_validation={addMinutes(myTimestampToDate(e.end), intervention.max_check_delay)} />
								: ''}
						</Col>
					</Row>
					<Row>
						<Card as={Col}>
							<Card.Header className="p-1">
								<Row>
									<Col onClick={() => setShowRoom(!showRoom)} as={Button} className="btn-light text-left pl-4">
										<FontAwesomeIcon icon="info-circle" /> {filtered_room_list.length} {t('global.labels.rooms')}
										{intervention.department_list ?
											<>
												{intervention.department_list.map((d, k) => (
													<Badge variant="light" key={k} className="mr-1">
														{structureElements.all_department_dict[d].name}
													</Badge>
												))}
											</>
											: ''}
										{/* 										<ContainerInfoPopover w={intervention} t={t} />
 */}									</Col>
									<Col sm="2">
										<ProgressBar>
											<ProgressBar now={Object.values(e.room_done_status).filter(r => r.done).length * 100 / filtered_room_list.length} style={{ backgroundColor: 'green' }} />
											<ProgressBar now={Object.values(e.room_done_status).filter(r => !r.done).length * 100 / filtered_room_list.length} style={{ backgroundColor: 'red' }} />
										</ProgressBar>
									</Col>
									<Col className="text-center" sm="2">
										<Button size="sm" onClick={handlePrint} ><FontAwesomeIcon icon="print" /> Stampa</Button>
									</Col>
								</Row>
							</Card.Header>
							{showRoom ?
								<Card.Body>
									<Row class="p-1 section_title mb-2 pb-1">
										<Col className="bg-light text-secondary ml-1" sm="6">
											<Row>
												<Col>
													<StructureGroupIcon level={0} key={'level0'} />
												</Col>
												<Col>
													<StructureGroupIcon level={1} key={'level1'} />
												</Col>
												<Col>
													<StructureGroupIcon level={2} key={'level2'} />
												</Col>
												<Col>
													<StructureGroupIcon level={3} key={'level3'} />
												</Col>
											</Row>
										</Col>
										<Col className="bg-light text-secondary ml-1">
											{t('global.labels.room')}
										</Col>
										<Col className="bg-light text-secondary ml-1">
											<Row>
												<Col>
													Stato
												</Col>
												<Col>
													Matricola
												</Col>

											</Row>

										</Col>

									</Row>
									<Row>
										<EventRoomList 
											room_list={filtered_room_list} 
											roomtype_dict={roomtype_dict} 
											today_room_dict={structureElements.all_room_dict} 
											one_event_status={e}
											/>
									</Row>
								</Card.Body>
								: ''}
						</Card>
					</Row>
				</Col>
				<div style={{ display: "none" }}>

					<Col ref={componentRef}>
						<Row>
							<Col sm="1">
							</Col>
							<Col className="ml-1 mr-1 border-left border-right">
								<Row className="border-top mt-1 pt-1">
									<Col className="text-center">
										<img
											src={domain !== 'admin' && (domain_data && domain_data.logo) ? domain_data.logo : "./Logo.Claudit.registrato.png"}
											height="40"
											className="d-inline-block align-center"
											alt=""
										/>

									</Col>
									<Col className="text-center">
										{getElementPreview(contract, '40px')}
									</Col>
								</Row>

								<Row className="border-bottom text-center mb-3 pb-3 pt-3 mt-3 border-top bg-info text-light header_row">
									<Col className="h3">
										Registro ClauditTrace {contract.name}
									</Col>
								</Row>
								<Row className="border-bottom">
									<Col>
										Data
									</Col>
									<Col>
										{format(myTimestampToDate(e.start), 'EEEE dd/MM/yyyy', { locale: it })}
									</Col>
								</Row>
								<Row className="border-bottom">
									<Col>
										Intervento
									</Col>
									<Col>{intervention.name}</Col>
								</Row>
								<Row className="border-bottom">
									<Col>
										Prestazione
									</Col>
									<Col>
										<ServiceBadge service={service_dict[getBaseObjectValue(intervention, 'service', 'id')]} freq={true} />
									</Col>
								</Row>
								<Row className="border-bottom">
									<Col>
										Fascia oraria programmata
									</Col>
									<Col>
										<i>
											{format(myTimestampToDate(intervention.start), 'HH:mm', { locale: it })}&nbsp;-&nbsp;
											{format(myTimestampToDate(intervention.end), 'HH:mm', { locale: it })}
										</i>
									</Col>
								</Row>
								<Row className="border-bottom">
									<Col>
										Orario esecuzione
									</Col>
									<Col>
										{format(myTimestampToDate(e.start), 'HH:mm', { locale: it })}&nbsp;-&nbsp;
										{format(myTimestampToDate(e.end), 'HH:mm', { locale: it })}
									</Col>
								</Row>
								<Row className="border-bottom">
									<Col>
										Valutazione
									</Col>
									<Col>
										{e.validate_status ?
											<Validation validate_status={e.validate_status} end_validation={addMinutes(myTimestampToDate(e.end), intervention.max_check_delay)} />
											: ''}
									</Col>
								</Row>
								{intervention.department_list ?
									<Row className="border-bottom">
										<Col>
											Reparti presenti
										</Col>
										<Col>
											<>
												{intervention.department_list.map((d, k) => (
													<>
														{structureElements.all_department_dict[d].name},&nbsp;
													</>
												))}
											</>
										</Col>
									</Row>
									: ''}
							</Col>
							<Col sm="1">
							</Col>
						</Row>

									<Row class="p-1 section_title mb-3 border-bottom">
										<Col className="bg-light text-secondary ml-1" xs="6">
											<Row>
												<Col>
													Struttura
												</Col>
												<Col>
													Padiglione
												</Col>
												<Col>
													Piano
												</Col>
												<Col>
													Reparto
												</Col>
											</Row>
										</Col>
										<Col className="bg-light text-secondary ml-1" >
											{t('global.labels.room')}
										</Col>
										<Col className="bg-light text-secondary ml-1" >
											<Row>
												<Col>Stato</Col>
												<Col>Matricola</Col>
											</Row>

										</Col>
									</Row>
									<Row>
										<EventRoomList 
										room_list={filtered_room_list} 
										roomtype_dict={roomtype_dict} 
										today_room_dict={structureElements.all_room_dict} 
										one_event_status={e} 
										do_print={true} />
									</Row>
									<Row>
										<Col className="bg-light  ml-1" xs="3">
											{filtered_room_list.length} {t('global.labels.rooms')}
										</Col>
										<Col className="bg-light text-secondary" xs="3">
											<ProgressBar>
												<ProgressBar now={Object.values(e.room_done_status).filter(r => r.done).length * 100 / filtered_room_list.length} style={{ backgroundColor: 'green' }} />
												<ProgressBar now={Object.values(e.room_done_status).filter(r => !r.done).length * 100 / filtered_room_list.length} style={{ backgroundColor: 'red' }} />
											</ProgressBar>
										</Col>
						</Row>
						<PrintFooter />
					</Col>
				</div>


			</Row>
		)
	} else {
		return (
			<Row className="element_row p-1 border-bottom">
				<Col>
					<Spinner animation="grow" role="status" />
				</Col>
				<Col>
					{format(myTimestampToDate(e.start), 'HH:mm', { locale: it })}&nbsp;-&nbsp;
					{format(myTimestampToDate(e.end), 'HH:mm', { locale: it })}
				</Col>

			</Row>

		)
	}
}


const Validation = ({ validate_status, end_validation }) => {
	const now = new Date()
	if (validate_status === 'true') {
		return (
			<OverlayTrigger
				placement="auto"
				delay={{ show: 250, hide: 150 }}
				overlay={<Tooltip >Servizio valutato positivamente</Tooltip>}>
				<Badge variant="success"><FontAwesomeIcon icon="check" /> Conforme</Badge>
			</OverlayTrigger>)
	} else if (validate_status === 'false') {
		return (
			<OverlayTrigger
				placement="auto"
				delay={{ show: 250, hide: 150 }}
				overlay={<Tooltip >Servizio valutato negativamente</Tooltip>}>
				<Badge variant="danger"><FontAwesomeIcon icon="times" /> Non conforme</Badge>
			</OverlayTrigger>)
	} else {
		if (end_validation >= now) {
			return (
				<OverlayTrigger
					placement="auto"
					delay={{ show: 250, hide: 150 }}
					overlay={<Tooltip >Servizio ancora non valutato</Tooltip>}>
					<Badge variant="warning"><FontAwesomeIcon icon="question-circle" /> Da validare (entro le ore {format(end_validation, 'HH:mm')})</Badge>
				</OverlayTrigger>)
		} else {
			return (
				<OverlayTrigger
					placement="auto"
					delay={{ show: 250, hide: 150 }}
					overlay={<Tooltip >Servizio valutato in maniera automatico dal sistema</Tooltip>}>
					<Badge variant="light" className="text-success border border-success">Validato automaticamente</Badge>
				</OverlayTrigger>)
		}
	}
}






















const pageStyle = `
html:{
    size: 90mm 60mm;
    backgroundColor: #fffff;

}
    .qrcard{
        width: 90mm;
        height: 60mm;
    }
  @page {
    size: 90mm 60mm;
    backgroundColor: #fffff;
  }

  @media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body {
	  height: initial !important;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	}
	.page-break {
	  margin-top: 5rem;
	  display: block;
	  background-color: red;

	  page-break-before: auto;
	  page-break-after: auto;
	}
	  .element_row_inverted{
  			background-color: #fff;
	  }
	  .header_row {
		print-color-adjust:exact;
		background-color: #ffaabb !important;
	  }
	  .badge{
		print-color-adjust:exact;
		background-color: !important;
	  }
	div.page-footer {
		display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		background-color: #fff;
		page-break-after: always;
	  }
  }
`;



export default translate()(ExecutionreportsView)


