import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Spinner, Dropdown, Image, ProgressBar, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'

export const FailureSelector = ({element, onChangeHandler, ui_list}) => {
    const [selectedFailure, setSelectedFailure] = useState()

    const handleSelectedFailure = (f) => {
        setSelectedFailure(f)
        onChangeHandler({name: 'message', value: f.description})
    }

	return (
        <Row>
            <Col sm="2" className={element.message.length === 0 && 'text-danger'}>
            Messaggio
            </Col>
            <Col>
            {console.log("FAILURE LIST", ui_list)}
            {ui_list.failure_presets?.map((f, k) => (
                <Row key={k}>
                    <Col>
                    <Button size="sm" variant={selectedFailure && selectedFailure.id === f.id?"info": "outline-info"} onClick={() => handleSelectedFailure(f)}>
                    {f.description}
                    </Button>
                    </Col>
                </Row>
            ))}
            </Col>
        </Row>


	)

}
