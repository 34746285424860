import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Form, Button, Card, ButtonGroup, Spinner, InputGroup, OverlayTrigger, Popover, ProgressBar, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { format, setMonth, setDate, addMonths, endOfMonth } from 'date-fns'
import { it } from 'date-fns/locale'
import DateTime from 'react-datetime';

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import { samplingplanTiming_list, checkgroupMode_dict, checkgroupType_dict } from "../components/constants/global_definitions"

import { compareOrder, getValue, rgba2hex, checkgroupComponentPresence, myTimestampToDate, getSelectedElement, getSelectedElementB, getFloatNumber, checkComponentPresence } from '../util/ui_utils.js';
import { RiskareaBadge, RoomInfoBadge, ServiceBadge } from "../components/StructureGraphicElements"

import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import GlobalQuestion from "../components/GlobalQuestion.js"
import Footer from "../components/Footer"


import { getAllRoomList } from '../Firebase2/Storage2/dbRoom.js';
import { deleteControlGroup, getControlGroupList, editControlGroup } from '../Firebase2/Storage2/dbControlGroup'

import firebase from 'firebase/app'
import ModalControlGroup from '../components/ModalControlGroup.js';

const popoverTiming = { show: 250, hide: 100 }


let load_page = false
let page_count = 6



////////////////////////////////////////////////////////////////////////////////////////////////////////
function getElementCount(elem_list) {
	if (elem_list) {
		return elem_list.length
	} else {
		return undefined

	}
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const ControlGroupView = ({ t, uData }) => {
	const { domain } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict } = useContext(GlobalDataContext)


	const childRefAlert = useRef()
	const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid
	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(false)
	const [structure_list, setStructureList] = useState([])
	const [element_list, setElementList] = useState([])
	const [velement_list, setVElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [globalEdit, setGlobalEdit] = useState(false)
	const [paginationOffset, setPaginationOffset] = useState(0)

	async function getData() {

		let return_data = await getControlGroupList(domain, contractId)
		setElementList(return_data)
	}

	useEffect(()=>{
		console.log(element_list.length)
		let t_list = [...element_list]
		setVElementList(t_list.splice(0, page_count))
	}, [element_list])


	const initValues = async () => {
		const userData = uData
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('samplingplan') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		load_page = true

		let tstructure_list = structureElements.structure_list
		let edit_permission_department_list = []
		let permission_structure_list = []
		if (userData && userData.contract_dict) {
			console.log('userData contract', userData.contract_dict[contractId])
			if (userData.contract_dict[contractId] && userData.contract_dict[contractId][0].structure) {
				if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
					edit_permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
					console.log("permission department_list", edit_permission_department_list, userData.contract_dict[contractId][0].department)
				}
				if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
					permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
					tstructure_list = tstructure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

				}
				console.log("permission structure_list", permission_structure_list)
			}
		}
		let room_structure_dict = {}
		for (const s of tstructure_list) {
			const room_list = await getAllRoomList({ structureId: s.id })
			if (room_list && room_list.length > 0) {
				room_structure_dict[s.id] = room_list
			}
		}
		setStructureList(tstructure_list)
		getData()
		setUiList({ ...ui_list, risk_area_list, risk_area_dict, roomtype_list, roomtype_dict, room_structure_dict })
		setLoading(false)

	}


	useEffect(() => {
		load_page = false
		setLoading(true)
		async function loadData() {
			let contractId = cid
			console.log("SAMPLING PLAN reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("SAMPLING PLAN init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])


	useEffect(() => {
		console.log('SAMPLING PLAN change contract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])


	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteControlGroup(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('controlgroup.messages.delete_controlgroup_title'), t('controlgroups.messages.delete_controlgroup', { controlgroup: el.name }), cb)
	}

	const editGroupStructure = async (structure_id, group_id) => {

		let edit_list = []
		for (let g of element_list) {
			if (g.structure_list && g.structure_list.indexOf(structure_id) >= 0) {
				let st_list = [...g.structure_list]
				st_list.splice(g.structure_list.indexOf(structure_id), 1)
				console.log("REMOVE", st_list)
				g = { ...g, structure_list: st_list }
				await editControlGroup(domain, contractId, g.id, g)
			}
			if (g.id === group_id) {
				console.log(g.structure_list)
				const st_list = [...g.structure_list, structure_id]
				console.log("ADD", st_list)
				g = { ...g, structure_list: st_list }
				console.log("edit group", g)
				await editControlGroup(domain, contractId, g.id, g)
			}
			edit_list.push(g)
		}
		console.log(edit_list)
		setElementList(edit_list)
	}


	const handleRemove = async (structure_id, group_id) => {
		let edit_list = []
		for (let g of element_list) {
			if (g.structure_list && g.structure_list.indexOf(structure_id) >= 0) {
				let st_list = [...g.structure_list]
				st_list.splice(g.structure_list.indexOf(structure_id), 1)
				console.log("ADD", st_list)
				g = { ...g, structure_list: st_list }
				console.log("edit group", g)
				await editControlGroup(domain, contractId, g.id, g)
			}
			edit_list.push(g)
		}
		console.log(edit_list)
		setElementList(edit_list)

	}

	const handleEnableGlobalEdit = () => {
		setGlobalEdit(true)
	}

	const onCloseGlobalEdit = async (save) => {
		setGlobalEdit(false)
	}



	const decrementPagination = () => {
		if (paginationOffset >= page_count) {
			setPaginationOffset(paginationOffset - page_count)
		} else {
			setPaginationOffset(0)
		}
	}

	useEffect(() =>{
		let t_list = [...element_list]
		setVElementList(t_list.splice(paginationOffset, page_count))
	}, [paginationOffset])

	const incrementPagination = () => {
		if (paginationOffset + page_count < element_list.length) {
			setPaginationOffset(paginationOffset + page_count)
		}
	}



	return (
		<>
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('global.pages.checkgroup')} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite ?
							<ModalControlGroup is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
							: ''}
					</Col>
					<Col>
						{globalEdit ?
							<>
								<Button variant="secondary" className="mr-1" onClick={() => onCloseGlobalEdit(false)}><FontAwesomeIcon icon='times' /> {t('global.labels.close')}</Button>
							</>
							:
							<Button variant="info" onClick={() => handleEnableGlobalEdit()}><FontAwesomeIcon icon='pencil-alt' />  {t('interventions.buttons.enable_edit')}</Button>
						}
					</Col>
				</Row>
			</Container>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid>
				{loading ?
					<Row>
						<Col>
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:

					<Row className="mt-1">
						{structure_list && structure_list.length > 0 ?
							<Col>
								<Row>
									<Col sm="3">
										<Row>
											<Col>
												{t('checkgroup.labels.checkgroups')}
											</Col>
										</Row>
										<Row>
											<Col>
											{element_list.length> page_count?
												<ButtonGroup>
													<Button variant="outline-secondary" onClick={decrementPagination} disabled={paginationOffset===0} size="sm"><FontAwesomeIcon icon="angle-left" /></Button>
													<Button variant="outline-info" disabled>{paginationOffset + page_count }/{element_list.length}</Button>
													<Button variant="outline-secondary" onClick={incrementPagination} disabled={paginationOffset + page_count >= element_list.length} size="sm"><FontAwesomeIcon icon="angle-right" /></Button>
												</ButtonGroup>
											:''}
											</Col>
										</Row>

									</Col>
									<Col>
										{velement_list.length > 0 ?
											<Row>
												{velement_list.map((g, k) => (
													<Col key={k} className="text-center">
														<Row>
															<Col>
																{g.name}
															</Col>
														</Row>
														<Row>
															<Col>
																{g.structure_list ?
																	<Badge variant={g.structure_list.length > 0 ? "info" : "warning"} className="mr-2">{g.structure_list.length}</Badge>
																	: ''}
																<ButtonGroup>

																	<ModalControlGroup is_new={false} t={t} item={g} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
																	{canWrite && globalEdit ?
																		<Button variant="danger" size="sm"><FontAwesomeIcon icon='trash' onClick={() => handleDelete(g)} /></Button>
																		: ''}
																</ButtonGroup>
															</Col>
														</Row>
													</Col>
												))}
												{globalEdit ?
													<Col sm="1">
													</Col>
													: ''}


											</Row>
											: ''}

									</Col>
								</Row>
								{velement_list.length===0?
								<i>Nessun gruppo di controllo inserito</i>:''}
								<Row>
									<Col className="bg-secondary text-light ml-1" sm="3">
										{t('global.labels.name')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										Assegna gruppo
									</Col>
								</Row>
								{structure_list.map((s, key) => (
									<Row className="mb-1 pb-1 border-bottom" key={key}>
										<Col className="font-weight-bold" sm="3">
											{s.name}
										</Col>
										<Col>
											<Row>
												{velement_list.map((g, k) => (
													<Col key={k} className="text-center">
														{globalEdit ?
															<Form.Check type="radio" name={`structuregroup` + s.id} value={g.id} checked={g.structure_list && g.structure_list.indexOf(s.id) >= 0} label="" onChange={(event) => editGroupStructure(s.id, g.id)} />
															:
															<>
																{g.structure_list && g.structure_list.indexOf(s.id) >= 0 ?
																	<FontAwesomeIcon icon="check" />
																	: ''}
															</>
														}
													</Col>

												))}
												{globalEdit && velement_list.length>0?
													<Col sm="1">
														<Button variant="outline-danger" size="sm"><FontAwesomeIcon icon='trash' onClick={() => handleRemove(s.id)} /></Button>
													</Col>
													: ''}
											</Row>
										</Col>
									</Row>
								))}
							</Col>
							:
							<Col className="font-italic">
								{t('structure.labels.no_structure')}
							</Col>
						}
					</Row>
				}

			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount(element_list ) + " " + t('global.labels.controlgroups')} />
		</>
	);
}









export default translate()(ControlGroupView)