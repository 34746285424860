import {firestore} from '../firebaseIndex'

export const addRoomCheck = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomchecks')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addRoomCheck error: ", e)
        return { error: e }
    }
}

export const getRoomCheckById = async (domainId, contractId, roomCheckId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomchecks')
            .get(roomCheckId);

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getRoomCheck error: ", e)
        return { error: e }
    }
}

export const getRoomCheckList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('structures')
                .doc('global')
                .collection('roomchecks')
                .get();

            const roomCheckList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return roomCheckList;
        
    } catch (e) {
        console.log("getRoomCheckList error: ", e)
        return { error: e }
    }
}


export const editRoomCheck = async (domainId, contractId, roomCheckId, data) => {
    try {
        const doc = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomchecks')
            .doc(roomCheckId);

        const res = await doc.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editRoomCheck error: ", e)
        return { error: e }
    }
}


export const deleteRoomCheck = async (domainId, contractId, roomCheckId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('structures')
            .doc('global')
            .collection('roomchecks')
            .doc(roomCheckId).delete();
        return res
    }  catch (e) {
        console.log("deleteRoomCheck error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
