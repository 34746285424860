import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Card, ButtonGroup } from 'react-bootstrap';
import { translate, useTranslate } from 'react-polyglot'
import { getElementPreview } from "../util/ui_utils.js"
import QRCode from 'qrcode.react'
import { useParams } from "react-router-dom"
import { useReactToPrint } from 'react-to-print';


import DomainContext from './contexts/domainContext'

const companyId = "0"



function ModalUserPreview({ item }) {
    const t = useTranslate();
    const { domain } = useContext(DomainContext)
    const componentRef = useRef();

	let { cid } = useParams();
	let contractId = cid

    const [showEmployeePreview, setShowEmployeeEdit] = useState(false)
    const [editedElement, setEditedElement] = useState(false)

    const handleClosePreviewEmployee = () => setShowEmployeeEdit(false);

    const handleShowPreviewEmployee = () => {
        setShowEmployeeEdit(true)

    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: pageStyle
    });
    return (
        <>
            <Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowPreviewEmployee()}><FontAwesomeIcon icon="id-card-alt" /> </Button>
            <Modal show={showEmployeePreview} onHide={handleClosePreviewEmployee} backdrop="static" className="bg-secondary">
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        <FontAwesomeIcon icon="id-card-alt" /> <span className="text-info">{t('employes.labels.view_employee')}</span>
                        :&nbsp;{item.displayName}
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Card className="personalcard" ref={componentRef}>
                            <Card.Header >
                                <FontAwesomeIcon icon='user' className="margin-right-icon" /> <b>{item.displayName}</b>
                            </Card.Header>
                            <Card.Body>
                                <Row className="">
                                    <Col>
							            <QRCode value={item.id} />
                                    </Col>
                                    <Col className="p-0">
                                        <Row>
                                            <Col className="text-center">
                                                <img
                                                    src={"/Logo.Claudit.registrato.png"}
                                                    height="50"
                                                    alt=""
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePreviewEmployee}>
                        {t('modal.close')}
                    </Button>
                    <Button variant="info" onClick={handlePrint}>
                        {t('modal.print')}
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}


const pageStyle = `
  @page {
    size: 90mm 60mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;




export default translate()(ModalUserPreview)