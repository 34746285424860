import React from "react";
import PropTypes from "prop-types";
import iconPath from "../../assets/iconsLib";

const Icon = (props) => {
    const curIcon = iconPath[props.icon]
    
    //console.log(curIcon)
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={curIcon.viewBox} aria-labelledby="title">
            <title id="title">{props.title}</title>
            {curIcon.paths?curIcon.paths.map((p, kp) => {
                    return (<path key={kp} fill={props.color} d={p} />)
                    }):<></>
                }        
            {curIcon.polygons?curIcon.polygons.map((p, kp) => {
                    return (<polygon key={kp} fill={props.color} points={p} />)
                    }):<></>
            }
        </svg>
    );
};


Icon.defaultProps = {
    width: 32,
    height: 32,
    color: "#000000",
    viewBox: "0 0 32 32",
    className: "icon",
    title: "icon"
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    viewBox: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
};


export default Icon;