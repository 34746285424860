import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addMediaType = async (data) => {
    try {
        const res = await firestore.collection('SETTINGS')
            .doc("UI_PARAMS").collection('media_types')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addMediaType error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const getMediaTypeById = async (mediaTypeId) => {
    try {
        const res = await firestore.collection('SETTINGS')
            .doc("UI_PARAMS").collection('media_types')
            .get(mediaTypeId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getMediaType error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getMediaTypeList = async (section) => {
    try {
        if (section === null || section === undefined) {
            const res = await firestore.collection('SETTINGS')
                .doc("UI_PARAMS").collection('media_types')
                .get();

            const mediatypeList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return mediatypeList;
        }else{
            const res = await firestore.collection('SETTINGS')
                .doc("UI_PARAMS").collection('media_types')
                .where('section', '==', section)
                .get();

            const mediatypeList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return mediatypeList;
        }
    } catch (e) {
        console.log("getMediaTypeList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const editMediaType = async (mediaTypeId, data) => {
    try {
        const mediatype =  firestore.collection('SETTINGS')
            .doc("UI_PARAMS").collection('media_types')
            .doc(mediaTypeId);

        const res = await mediatype.set(data)
            .then( () => {
                console.log("editMediaType - edit successfully")
                return data
            }).catch( error => {
                console.log("editMediaType - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editMediaType error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteMediaType = async (mediaTypeId) => {
    try {
        const res = await firestore.collection('SETTINGS')
            .doc("UI_PARAMS").collection('media_types')
            .doc(mediaTypeId).delete();
        return res
    }  catch (e) {
        console.log("deleteMediaType error: ", e)
        return { error: e }
    }
}




