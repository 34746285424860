import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, ButtonGroup, Container, Modal, Form, Nav, Tab } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import QRCode from 'qrcode.react'

import LocaleContext from './contexts/locale.js'
import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';


import { StructureGroupIcon, renderPhoneList } from '../components/StructureGraphicElements.js'

import PhoneList from "../components/PhoneList.js"
import IdentifierList from "../components/IdList.js"
import ModalBaseObject from "../components/ModalBaseObject"
import ModalDepartmentPreview from './ModalDepartmentPreview.js';

import ElementFormMedia from "../components/FormMedia.js"

import { getSelectedElement, checkComponentPresence } from "../util/ui_utils.js"

import { getBeaconFromDepartment } from '../Firebase2/Storage2/dbBeacon';
import {
	addPavillon as addPavillon2,
	addFloor as addFloor2,
	addDepartment as addDepartment2,
	editPavillon as editPavillon2,
	editFloor as editFloor2,
	editDepartment as editDepartment2
} from '../Firebase2/Storage2/dbBaseCompany';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage';
//const contractId = sessionStorage.getItem('contractId')

const section = 'structure'



function ModalStructureGroupItem({ is_new, item, setItem, t, is_structure, structureId, ui_list, structureElements, canWrite }) {

	const { domain } = useContext(DomainContext)
    const { contractId } = useContext(GlobalDataContext)

	let new_pl = []
	if (item.parent_list) {
		new_pl = [...item.parent_list]

	}
	let newitem = {
		name: '',
		mq: 0,
		size: 0,
		room_count: 0,
		note: '',
		cdc: '',
		order: 0,
		parent_list: new_pl,
		risk_area_summary: {},
		media_list: [],
		childs: []
	}
	const [showItemEdit, setShowItemEdit] = useState(false)
	const [curItem, setCurItem] = useState(item)
	const [editedElement, setEditedElement] = useState(false)
	const [beaconList, setBeaconList] = useState([])

	const handleShowEditItem = (i) => {
		setShowItemEdit(true)
	}

	const handleShowNewItem = () => {
		newitem.level = item.level + 1
		console.log("open dialog", newitem.level)
		setCurItem(newitem)
		setShowItemEdit(true)
		setEditedElement(false)
	}
	const handleCloseItem = () => {
		setShowItemEdit(false)
		setEditedElement(false)
	}
	const handleSaveItem = async () => {
		if (is_new) {
			console.log(contractId, structureId, curItem)
			curItem.parent_list.push(item.id)
			if (curItem.level === 1) {
				const return_data = await addPavillon2(domain, contractId, structureId, curItem)
				console.log("edit", return_data)
				let mylist = [...item.childs]
				mylist.push(curItem)
				console.log('ADD level', item.level, curItem.level, return_data)
				setItem({ ...item, 'childs': mylist })
			} else if (curItem.level === 2) {
				const return_data = await addFloor2(domain, contractId, structureId, item.id, curItem)
				console.log("edit", return_data)
				let mylist = [...item.childs]
				mylist.push(curItem)
				console.log('ADD level', item.level, curItem.level, return_data)
				setItem({ ...item, 'childs': mylist })
			} else if (curItem.level === 3) {
				const return_data = await addDepartment2(domain, contractId, structureId, item.parent_list[1], item.id, curItem)
				console.log("edit", return_data)
				console.log('addDepartment', return_data)
				let mylist = [...item.childs]
				mylist.push(curItem)
				console.log('ADD level', item.level, curItem.level, return_data)
				setItem({ ...item, 'childs': mylist })
			}
		} else {
			if (curItem.media_list) {
				for (let m = 0; m < curItem.media_list.length; m++) {
					let editTrackData = curItem.media_list[m].tracker
					editTrackData.item_reference = { category: curItem.level, name: curItem.name, id: curItem.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
			}
			if (curItem.level === 1) {
				console.log("EDIT PAVILLON")
				const return_data = await editPavillon2(domain, contractId, item.parent_list[0], curItem.id, curItem)
			} else if (curItem.level === 2) {
				console.log("EDIT FLOOR")
				const return_data = await editFloor2(domain, contractId, item.parent_list[0], item.parent_list[1], curItem.id, curItem)
			} else if (curItem.level === 3) {
				console.log("EDIT DEPARTMENT")
				const return_data = await editDepartment2(domain, contractId, item.parent_list[0], item.parent_list[1], item.parent_list[2], curItem.id, curItem)
			}
			setItem(curItem)
		}
		setShowItemEdit(false)
		setEditedElement(false)
	}

	const getGroupName = (level) => {
		if (level === 0) {
			return t('company.labels.structure')
		} else if (level === 1) {
			return t('global.labels.pavillon')
		} else if (level === 2) {
			return t('structure.labels.floor')
		} else if (level === 3) {
			return t('global.labels.department')
		}
	}

	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined
		try {
			sub = el.getAttribute('sub')
		} catch {
			sub = undefined;
		}
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curItem[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curItem[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurItem({ ...curItem, [name]: value })
		setEditedElement(true)
	}


	const PathName = () => {
		try {

			if (curItem.level === 1) {
				return (
					<span className="text-muted font-italic">
					</span>
				)
			} else if (curItem.level === 2) {
				return (
					<span className="text-muted font-italic">
						{structureElements.pavillon_list.filter(p => p.id === item.parent_list[1])[0].name}
					</span>
				)
			} else if (curItem.level === 3) {
				return (
					<span className="text-muted font-italic">
						{structureElements.pavillon_list.filter(p => p.id === item.parent_list[1])[0].name}
						&nbsp;| &nbsp;
						{structureElements.floor_list.filter(p => p.id === item.parent_list[2])[0].name}
					</span>
				)
			}
		} catch (err) {
			return (<></>)
		}
	}



/* 

	useEffect(() => {

		const getBeaconList = async () => {
			const b_list = await getBeaconFromDepartment(domain, contractId, curItem.id)
			console.log("GET BEACON LIST", domain, contractId, curItem.id, b_list)
			setBeaconList(b_list)
			setCurItem({...curItem, 
			identifier_list: b_list})
		}
		if (showItemEdit && curItem.level===3 && curItem.id){
			getBeaconList()
		}

	},[showItemEdit])
 */

	return (
		<>
			{is_new
				? <Button variant="primary" size="sm"><FontAwesomeIcon icon="plus-square" onClick={handleShowNewItem} /></Button>
				: <Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEditItem()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showItemEdit} className="bg-secondary" size="xl" backdrop="static">
				<Modal.Header className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" className="mr-1" />
								<StructureGroupIcon level={curItem.level} /> <span className="text-primary">{t('global.labels.new')} {getGroupName(curItem.level)} </span>
							</>
							:
							<>
								<FontAwesomeIcon icon="pencil-alt" className="mr-1" />
								<StructureGroupIcon level={curItem.level} /> <span className="text-info">{t('global.labels.edit')} {getGroupName(curItem.level)} </span>
							</>
						}
						<PathName className="mr-1 ml-1" /> | {item.name}  {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Tab.Container id="left-tabs-menu" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('products.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabMedia" disabled={is_new}>{t('structure.labels.imagesdocs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curItem} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curItem} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSaveItem }} section={section} canWrite={canWrite} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					{curItem.level === 3 && !is_new ?
						<ModalDepartmentPreview element={curItem} t={t} />
						: ''}
					<Button variant="secondary" onClick={handleCloseItem}>
						{t('global.labels.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveItem}>
							<FontAwesomeIcon icon="save" /> {t('global.labels.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}





function ElementFormDetails({ element, functions, t, ui_list, canWrite }) {
	return (
		<>
			<Form.Group as={Row} controlId="formStructureCode" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.costcenter')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" defaultValue={element.code} name="code" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')} </Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="name" defaultValue={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{element.level === 2 ?
				<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
					<Form.Label column sm="2">{t('global.labels.order')} </Form.Label>
					<Col sm="6">
						<Form.Control className="mb-2" type="number" name="order" defaultValue={element.order} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
				</Form.Group>
				: ''
			}
			{element.level === 3 ?
				<>
					<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
						<Form.Label column sm="2">{t('global.labels.referent_list')} </Form.Label>
						<Col>
							<ElementFormReferent element={element} functions={functions} t={t} ui_list={ui_list} canWrite={canWrite} />
						</Col>
					</Form.Group>

					{element.id &&
					<IdentifierList element={element} functions={functions} t={t} canWrite={canWrite} />
					}
					<QRCode value={JSON.stringify({ id: element.id, nome: element.name, dimensione: element.size, parent_list: element.parent_list })} />
				</>
				: ''}
			<Form.Group as={Row} controlId="formSupplierNote" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="note" defaultValue={element.note} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}






function ElementFormReferent({ element, functions, t, ui_list, canWrite }) {

	const { locale } = useContext(LocaleContext)

	const newreferent = {
		Id: 0,
		firstname: '',
		lastname: '',
		phone_list: [],
		phone: '',
		mobilephone: '',
		fax: '',
		email: '',
		role: {}
	}

	const [showReferentEdit, setShowReferentEdit] = useState(false)
	const [newReferentEdit, setNewReferentEdit] = useState(true)
	const [curReferent, setCurReferent] = useState(newreferent)
	const [editedElement, setEditedElement] = useState(false)

	//----referent
	const handleCloseReferent = () => setShowReferentEdit(false);
	const handleSaveReferent = () => {
		if (newReferentEdit) {
			let newreferent_list = element.referent_list || []
			newreferent_list.push(curReferent)
			console.log(newreferent_list)
			functions.edit({ name: 'referent_list', value: newreferent_list })
		} else {
			let editlist = element.referent_list
			const c = checkComponentPresence(curReferent, element.referent_list)
			editlist.splice(c, 1, curReferent)
			functions.edit({ name: 'referent_list', value: editlist })
		}
		setShowReferentEdit(false);
		setEditedElement(false)
	}
	const handleDeleteReferent = (el) => {
		console.log(el)
		let editlist = element.referent_list
		const c = checkComponentPresence(el, element.referent_list)
		editlist.splice(c, 1)
		functions.edit({ name: 'referent_list', value: editlist })
	}
	const handleShowNewReferent = () => {
		setCurReferent(newreferent)
		setNewReferentEdit(true)
		setShowReferentEdit(true)
		setEditedElement(false)
	}
	const handleShowEditReferent = (i) => {
		console.log('edit', i)
		setCurReferent(i)
		setNewReferentEdit(false)
		setEditedElement(false)
		setShowReferentEdit(true)
	}
	const onChangeHandlerReferent = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurReferent({ ...curReferent, [name]: value })
		setEditedElement(true)
	}
	const updateRoleList = (b) => {
		functions.updateData('referent_role', b)
	}
	const getReferentRole = (i) => {
		try {
			return i.role.name[locale]
		} catch (err) {
			return ''
		}
	}
	return (
		<>
			<Row>
				<Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewReferent()}><FontAwesomeIcon icon="plus-square" />{t('suppliers.labels.referent_add')}</Button></Col>
			</Row>
			<Row className="border-bottom  border-right mb-1">
				{element.referent_list && element.referent_list.length > 0 ?
					<Col>
						<Row className="mb-1 mt-1">
							<Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
							<Col className="bg-secondary text-light ml-1">{t('global.labels.lastname')} </Col>
							<Col className="bg-secondary text-light ml-1">{t('global.labels.role')} </Col>
							<Col className="bg-secondary text-light ml-1">{t('global.labels.phone')} </Col>
							<Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element.referent_list.map((i, key) => (
							<Row key={key}>
								<Col>
									{i.firstname}
								</Col>
								<Col>
									{i.lastname}
								</Col>
								<Col>
									{getReferentRole(i)}
								</Col>
								<Col>
									{renderPhoneList(i.phone_list)}
								</Col>
								<Col sm="1" className="p-1">
									<ButtonGroup>
										<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditReferent(i)} /> </Button>
										{canWrite ?
											<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteReferent(i)} /> </Button>
											: ''}
									</ButtonGroup>
								</Col>
							</Row>))
						}
					</Col>
					: <Col className="font-italic"> {t('suppliers.messages.no_referent')}</Col>
				}
			</Row>


			<Modal show={showReferentEdit} onHide={handleCloseReferent} size="xl" className="bg-secondary" backdrop="static">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					{newReferentEdit && <Modal.Title variant="primary">{t('suppliers.labels.new_referent')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!newReferentEdit && <Modal.Title variant="info">{t('suppliers.labels.edit_referent')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" p name="firstname" value={curReferent.firstname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierLastName" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.lastname')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="lastname" value={curReferent.lastname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="2">{t('supliers.labels.referent.role')} </Form.Label>
							<Col sm="8">
								<Form.Control as="select" className="mb-2" defaultValue={curReferent.role.Id} name="role" onChange={(event) => onChangeHandlerReferent({ name: 'role', type: 'select', value: getSelectedElement(event.target.value, ui_list.referent_role_list) })} >
									<option value="-1" >{t('equipments.labels.select_role')}</option>
									{ui_list.referent_role_list.map((i, key) => (
										<option key={key} value={i.Id}  >{i.name[locale]}</option>
									))}
								</Form.Control>
							</Col>
							<Col sm="2">
								<ModalBaseObject is_new={true} small={true} cursection={{ name: 'referent_role', list: ui_list.referent_role_list }} t={t} functions={{ setCurSection: updateRoleList }} />
							</Col>
						</Form.Group>
						<PhoneList element={curReferent} functions={{ edit: onChangeHandlerReferent }} t={t} canWrite={canWrite} />
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="2">Email</Form.Label>
							<Col sm="8">
								<Form.Control type="email" className="mb-2" name="email" value={curReferent.email} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseReferent}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveReferent}>
							<FontAwesomeIcon icon="save" />{t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>

		</>
	)
}







export default translate()(ModalStructureGroupItem)