import React, { useState, useEffect } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from './constants/itemtypes'
import { Image } from 'react-bootstrap';
import { ControllerPoint } from './ControllerPoint'
import update from 'immutability-helper'
import {getColor} from './StructureGraphicElements'
let styles = {
  width: 450,
  position: 'relative',
}
export const ControllerImageContainer = ({ pointlist, setPoint, background, selectedElement, setSelectedElement, service, zoom}) => {
  const hideSourceOnDrag = false
  console.log("pointlist", pointlist)
  const [boxes, setBoxes] = useState(pointlist)
  const [, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset()
      console.log("(ControllerImageContainer) ", item.left, item.top, delta)
      const left = Math.round(item.left + (delta.x/zoom))
      const top = Math.round(item.top+ (delta.y/zoom))
      console.log("save", left, top)
      moveBox(item.id, left, top)
      return undefined
    },
  })
  const moveBox = (id, left, top) => {
    console.log("(ControllerImageContainer) moveBox", id)
    setBoxes(
      update(boxes, {
        [id]: {
          $merge: { left, top },
        },
      }),
    )
  }
  useEffect(() => {
    //		initValues()
    console.log('update', pointlist)
    setBoxes(pointlist)
  }, [pointlist])
  useEffect(() => {
    setPoint(boxes)
  }, [boxes])

  if (boxes) {
    const zoomstyle = {...styles, width: styles.width*zoom}

    return (
      <>
        <div ref={drop} style={zoomstyle}>
          <Image src={'image_reference/' + background} fluid />
          {Object.keys(boxes).map((key) => {
            const { left, top, point_id } = boxes[key]
            let selected = false;
            console.log('selected', selectedElement, key)
            if (selectedElement === key) { selected = true }
            return (
              <ControllerPoint
                key={key}
                id={key}
                setSelectedElement={setSelectedElement}
                selected={selected}
                left={left}
                top={top}
                children={point_id}
                hideSourceOnDrag={hideSourceOnDrag}
                service_color={getColor(service).background}
                zoom={zoom}
              >
              </ControllerPoint>
            )
          })}
        </div>
      </>
    )
  } else {
    return (
      <Image src={'image_reference/' + background} fluid />

    )

  }
}
