import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import DateTime from 'react-datetime';
import { format, setDay } from 'date-fns'
import { it } from 'date-fns/locale'
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
//import "@nateradebaugh/react-datetime/scss/styles.scss";
import CurrencyInput from 'react-currency-input-field';

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"

import { getSelectedElement, checkComponentPresence, myTimestampToDate } from "../util/ui_utils.js"
import { ModalInitButton } from '../components/StructureGraphicElements.js'

import firebase from 'firebase/app'

import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { addProduct as addProduct2,  editProduct as editProduct2,} from '../Firebase2/Storage2/dbWarehouse';
import { addCatalogProduct, editCatalogProduct} from '../Firebase2/Storage2/dbCatalogWarehouse';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';


const companyId = '0'
//const contractId = sessionStorage.getItem('contractId')

const section = 'machine'

////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalMachine({ item, setItem, t, is_new, small, item_list, ui_list, setUiList,  canWrite }) {
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()
	let { cid } = useParams();
	let contractId = cid
	const newmachine = {
		code: '',
		brand: {},
		category: {},
		name: '',
		purchasedate: firebase.firestore.Timestamp.now(),
		purchasemode: 'purchase',
		accessory_list: [],
		mainteance_list: [],
		for_disinfection: false,
		regulation_code17272: false,
		reigstration_medical_code: '',

		media_list: [],
		note: ''
	}
	const initValues = async () => {
		console.log(ui_list)
//		const brand_list =  await getBaseObjectTypeList2(domain, 'brands')
//		const category_list = await getBaseObjectTypeList2(domain, 'machine_category')
//		setUiList({ ...ui_list, brand_list, category_list })
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newmachine)
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		console.log(ui_list)
		newmachine.media_list = []
		setCurrentElement(newmachine)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		let return_data
		if (is_new) {
			if (domain ==='catalog'){
				return_data =  await addCatalogProduct(contractId, section, curElement)
			}else{
				return_data =  await addProduct2(domain, contractId, companyId, section, curElement)
			}
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			if (domain ==='catalog'){
				console.log("FROM CATALOG", contractId, section, curElement)
				return_data =  await editCatalogProduct(contractId, section, curElement.id, curElement)
			}else{
				return_data =  await editProduct2(domain, contractId, companyId, section, curElement.id, curElement)
			}
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t}  is_new={is_new} canWrite={canWrite} small={small} title_new={t('machine.buttons.machine_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('machines.labels.new_machine')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('machines.labels.edit_machine')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('machines.labels.view_machine')}</span>
								</>
							]
						}
                        : {curElement.name}&nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item variant="secondary" className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('machines.labels.general')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabAccessory">{t('machines.labels.accessories')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabMainteance">{t('machines.labels.mainteance')}</Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabMedia" disabled={is_new}>{t('machines.labels.imagesdocs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabAccessory">
											<ElementFormAccessory element={curElement} t={t} functions={{ edit: onChangeHandler }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMainteance">
											<ElementFormMainteance element={curElement} t={t} functions={{ edit: onChangeHandler }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curElement} t={t} functions={{ edit: onChangeHandler }} section={section} canWrite={canWrite} />
										</Tab.Pane>

									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)
    const { domain } = useContext(DomainContext)
	const updateBrands = (b) => {
		functions.updateData('brand_list', b)
	}
	const updateCategory = (b) => {
		functions.updateData('category_list', b)
	}

	return (
		<>
			<Form.Group as={Row} controlId="machineFirstName" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('machines.labels.model')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" value={element.name} name="name" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="machineCode" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('machines.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" value={element.code} name="code" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formProductBrand" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('machines.labels.brand')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.brand.Id} name="brand" className="mb-2" onChange={(event) => functions.edit({ name: 'brand', type: 'select', value: getSelectedElement(event.target.value, ui_list.brand_list) })}  >
						<option value="-1">{t('machines.labels.select_brand')}</option>
						{ui_list.brand_list
							? [Object(ui_list.brand_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'brands', list: ui_list.brand_list }} t={t} functions={{ setCurSection: updateBrands }} />
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formProductCategory" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('machines.labels.category')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.category.Id} name="category" className="mb-2" onChange={(event) => functions.edit({ name: 'category', type: 'select', value: getSelectedElement(event.target.value, ui_list.category_list) })}  >
						<option value="-1">{t('machines.labels.select_category')}</option>
						{ui_list.category_list
							? [Object(ui_list.category_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'machine_category', list: ui_list.category_list }} t={t} functions={{ setCurSection: updateCategory }} />
						: ''}
				</Col>
			</Form.Group>
					<Form.Group as={Row} className="border-bottom">
						<Form.Label column sm="2">{t('machine.labels.for_disinfection')}</Form.Label>
						<Col>
							<Form.Check type="radio" name="for_disinfection" value={true} checked={element.for_disinfection} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Check type="radio" name="for_disinfection" value={false} checked={!element.for_disinfection} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
						</Col>
					</Form.Group>
					{element.for_disinfection?
					<>
			<Form.Group as={Row} controlId="machineCode" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('machines.labels.registration_medical_code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" value={element.registration_medical_code} name="registration_medical_code" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
					<Form.Group as={Row} className="border-bottom">
						<Form.Label column sm="2">{t('machine.labels.registration_code17272')}</Form.Label>
						<Col>
							<Form.Check type="radio" name="registration_code17272" value={true} checked={element.registration_code17272} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Check type="radio" name="registration_code17272" value={false} checked={!element.registration_code17272} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
						</Col>
					</Form.Group>
					</>:''}
			<Form.Group as={Row} controlId="formMachinIsPurchase" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('machines.labels.puchaserent')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="purchasemode" value="purchase" checked={(element && element.purchasemode === 'purchase')} label={t('machines.labels.purchase')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="purchasemode" value="rent" checked={(element && element.purchasemode === 'rent')} label={t('machines.labels.rent')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{domain !== 'catalog'?
			<>
			<Form.Group as={Row} controlId="formMachinePrice" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('machines.labels.price')}</Form.Label>
				<Col sm="8">
					<CurrencyInput
						name="price"
						placeholder="€0,00"
						defaultValue={element.price}
						allowDecimals={true}
						className="form-control "
						decimalsLimit={2}
						decimalSeparator=","
						groupSeparator="."
						prefix="€"
						onChange={(value, name) => functions.edit({ name, value })}
						precision={2}
						turnOffSeparators={true}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formMachinePurchasedate" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('machines.labels.purchase_date')}</Form.Label>
				<Col sm="8">
					<DateTime value={myTimestampToDate(element.purchasedate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="purchasedate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'purchasedate', value: firebase.firestore.Timestamp.fromDate(e.toDate()) })} />
				</Col>
			</Form.Group>
			</>
			:''}
			<Form.Group as={Row} controlId="machineNote" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('machines.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormAccessory({ element, functions, t, canWrite }) {
	const newaccessory = {
		code: '',
		description: '',
		machine_reference: '',
		quantity: 0,
		price: 0,
		is_mandatory: true
	}
	const [showAccessoryEdit, setShowAccessoryEdit] = useState(false)
	const [newAccessoryEdit, setNewQualificaionEdit] = useState(true)
	const [curAccessory, setCurAccessory] = useState(newaccessory)
	const handleCloseAccessory = () => setShowAccessoryEdit(false);
	const handleSaveAccessory = () => {
		setShowAccessoryEdit(false);
		if (newAccessoryEdit) {
			let newaccessory_list = element.accessory_list
			newaccessory_list.push(curAccessory)
			console.log(newaccessory_list)
			functions.edit({ name: 'accessory_list', value: newaccessory_list })
		} else {

		}
	}
	const handleShowNewAccessory = () => {
		setCurAccessory(newaccessory)
		setNewQualificaionEdit(true)
		setShowAccessoryEdit(true)
	}
	const handleShowEditAccessory = (i) => {
		setCurAccessory(i)
		setNewQualificaionEdit(false)
		setShowAccessoryEdit(true)
	}
	const onChangeHandlerAccessory = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurAccessory({ ...curAccessory, [name]: value })
	}
	return (
		<>
			<Form.Group as={Row} controlId="formMachineArredies" className="border-bottom">
				<Col>
					<Row>
						<Col className="text-right">
							<Button variant="outline-primary" size="sm" onClick={() => handleShowNewAccessory()}><FontAwesomeIcon icon="plus-square" />{t('machines.labels.accessory_add')}</Button>
						</Col>
					</Row>
					{element.accessory_list && element.accessory_list.length > 0 ?
						<>
							<Row >
								<Col sm="2" small className="bg-secondary text-light ml-1">{t('machines.labels.accessory_code')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('machines.labels.accessory_description')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('machines.labels.accessory_machinereference')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('machines.labels.accessory_quantity')}</Col>
								<Col className="bg-secondary text-light ml-1">{t('machines.labels.accessory_price')}</Col>
								<Col className="bg-secondary text-light ml-1">{t('machines.labels.accessory_mandatory')}</Col>
								<Col className="bg-secondary text-light ml-1">&nbsp;</Col>
							</Row>
							{element.accessory_list.map((i, key) => (
								<Row>
									<Col>{i.code}</Col>
									<Col>{i.description}</Col>
									<Col>{i.machinereference}</Col>
									<Col>{i.quantity}</Col>
									<Col>{i.price}</Col>
									<Col>{i.is_mandatory}</Col>
									<Col>
										<Button size="sm" variant="info" onClick={() => handleShowEditAccessory(i)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
										{canWrite ?
											<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
											: ''}
									</Col>
								</Row>))
							}
						</>
						: <i> {t('machines.messages.no_accessory')}</i>
					}
				</Col>
			</Form.Group>
			<Modal show={showAccessoryEdit} className="border-primary bg-secondary" size="lg">
				<Modal.Header closeButton>
					{newAccessoryEdit && <Modal.Title>{t('machines.modal.titlenewaccessory')} </Modal.Title>}
					{!newAccessoryEdit && <Modal.Title>{t('machines.modal.titleeditaccessory')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Form.Group as={Row} controlId="formMachineCF" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.accessory_code')}</Form.Label>
							<Col>
								<Form.Control type="text" placeholder="" value={curAccessory.code} name="code" onChange={onChangeHandlerAccessory} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formMachineCF" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.accessory_description')}</Form.Label>
							<Col>
								<Form.Control type="text" placeholder="" value={curAccessory.description} name="description" onChange={onChangeHandlerAccessory} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formMachineCF" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.accessory_price')}</Form.Label>
							<Col>
								<CurrencyInput
									name="price"
									placeholder="€0,00"
									defaultValue={curAccessory.price}
									allowDecimals={true}
									className="form-control "
									decimalsLimit={2}
									prefix="€"
									onChange={(value, name) => onChangeHandlerAccessory({ name, value })}
									precision={2}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formMachineCF" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.accessory_quantity')}</Form.Label>
							<Col>
								<Form.Control type="number" min="0" placeholder="" value={curAccessory.quantity} name="quantity" onChange={onChangeHandlerAccessory} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formMachineCF" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.accessory_mandatory')}</Form.Label>
							<Col>
								<Form.Check type="radio" name="is_mandatory" value={true} checked={(curAccessory && curAccessory.is_mandatory === true)} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerAccessory(event.currentTarget)} />
								<Form.Check type="radio" name="is_mandatory" value={false} checked={(curAccessory && curAccessory.is_mandatory === false)} label={t('global.labels.no')} onChange={(event) => onChangeHandlerAccessory(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseAccessory}> {t('modal.close')}</Button>
					{canWrite ?
						<Button className="btn-myprimary" form="accessoryForm" onClick={handleSaveAccessory}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormMainteance({ element, functions, t, canWrite }) {
	const newmainteance = {
		date: new Date(),
		description: '',
		is_recursive: false,
		color: { r: 10, g: 10, b: 90, a: 1 }
	}
	const [showMainteanceEdit, setShowMainteanceEdit] = useState(false)
	const [newMainteanceEdit, setNewQualificaionEdit] = useState(true)
	const [curMainteance, setCurMainteance] = useState(newmainteance)
	const [displayColorPicker, setShowColorPicker] = useState(false)
	const [color, setCurColor] = useState({ r: 100, g: 0, b: 0, a: 1 })
	const handleCloseMainteance = () => setShowMainteanceEdit(false);
	const styles = reactCSS({
		'default': {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: `rgba(${curMainteance.color.r}, ${curMainteance.color.g}, ${curMainteance.color.b}, ${curMainteance.color.a})`,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		},
	});
	const handleSaveMainteance = () => {
		setShowMainteanceEdit(false);
		let newmainteance_list = element.mainteance_list
		newmainteance_list.push(curMainteance)
		console.log(newmainteance_list)
		functions.edit({ name: 'mainteance_list', value: newmainteance_list })
	}
	const handleShowNewMainteance = () => {
		setCurMainteance(newmainteance)
		setNewQualificaionEdit(true)
		setShowMainteanceEdit(true)
	}
	const onChangeHandlerMainteance = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurMainteance({ ...curMainteance, [name]: value })
	}
	const handleChangeColor = (c) => {
		console.log('set color', c)
		setCurColor(c.rgb)
	}
	const handleShowColorPicker = () => {
		setShowColorPicker(!displayColorPicker)
	}
	const handleCloseColorPicker = () => {
		setShowColorPicker(false)
	}
	return (
		<>
			<Form.Group as={Row} controlId="formMachineCF">
				<Col>
					<Row>
						<Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewMainteance()}><FontAwesomeIcon icon="plus-square" />{t('machines.labels.mainteances_add')} </Button></Col>
					</Row>
					{element.mainteance_list && element.mainteance_list.length > 0 ?
						<>
							<Row className="bg-secondary text-light">
								<Col><FontAwesomeIcon icon="check" alt={t('machines.labels.mainteance_done')} /> </Col>
								<Col><FontAwesomeIcon icon="retweet" alt={t('machines.labels.mainteance_recursive')} /> </Col>
								<Col>{t('machines.labels.mainteance_description')} </Col>
								<Col>{t('machines.labels.mainteance_date')} </Col>
								<Col>{t('machines.labels.mainteance_price')}</Col>
								<Col>&nbsp;</Col>
							</Row>
							{element.mainteance_list.map((i, key) => (
								<Row>
									<Col></Col>
									<Col>{i.is_recursive}</Col>
									<Col>{i.description}</Col>
									<Col>{format(i.date, 'dd/MM/yy')}</Col>
									<Col>{i.price}</Col>
									<Col>
										<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" /> </Button>
										{canWrite ?
											<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
											: ''}
									</Col>
								</Row>))
							}
						</>
						: <i> {t('machines.messages.no_mainteance')}</i>
					}
				</Col>
			</Form.Group>
			<Modal show={showMainteanceEdit} className="border-primary bg-secondary" size="lg">
				<Modal.Header closeButton>
					{newMainteanceEdit && <Modal.Title>{t('machines.modal.titlenewmainteance')} </Modal.Title>}
					{!newMainteanceEdit && <Modal.Title>{t('machines.modal.titleeditmainteance')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Form.Group as={Row} controlId="formMachineCF" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.mainteance_description')}</Form.Label>
							<Col>
								<Form.Control type="text" placeholder="" defaultValue={curMainteance.description} name="description" onChange={onChangeHandlerMainteance} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formMachineCF" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.mainteance_price')}</Form.Label>
							<Col>
								<CurrencyInput
									name="price"
									placeholder="€0,00"
									defaultValue={curMainteance.price}
									allowDecimals={true}
									className="form-control "
									decimalsLimit={2}
									prefix="€"
									onChange={(value, name) => onChangeHandlerMainteance({ name, value })}
									precision={2}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formQ" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.mainteance_date')} </Form.Label>
							<Col sm="8">
								<DateTime initialValue={curMainteance.date} dateFormat='DD/MM/YYYY' timeFormat={false} name="date" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerMainteance({ name: 'date', value: e.toDate() })} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formQ" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.mainteance_advicecolor')} </Form.Label>
							<Col sm="8">
								<Button variant="outline-info" onClick={handleShowColorPicker}>
									<div style={styles.color} />
								</Button>
								{displayColorPicker ? <div style={styles.popover}>
									<div style={styles.cover} onClick={handleCloseColorPicker} />
									<SketchPicker color={curMainteance.color} onChange={handleChangeColor} onChangeComplete={(e) => onChangeHandlerMainteance({ name: 'color', value: e.rgb })} />
								</div> : null}
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formQ" className="border-bottom">
							<Form.Label column sm="2">{t('machines.labels.mainteance_recursive')}</Form.Label>
							<Col sm="8">
								<Form.Check type="radio" name="is_recursive" value={true} checked={(curMainteance.is_recursive === true)} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerMainteance(event.currentTarget)} />
								<Form.Check type="radio" name="is_recursive" value={false} checked={(curMainteance.is_recursive === false)} label={t('global.labels.no')} onChange={(event) => onChangeHandlerMainteance(event.currentTarget)} />
							</Col>
						</Form.Group>
						{curMainteance.is_recursive ?
							<Form.Group as={Row} controlId="formQ" className="border-bottom">
								<Form.Label column sm="2">{t('machines.labels.mainteance_recursive_section')} </Form.Label>
								<Col>
									<Row>
										<Col>
											<DateTime initialValue={curMainteance.recursive_end} dateFormat='DD/MM/YYYY' timeFormat={false} name="recursive_start" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerMainteance({ name: 'recursive_start', value: e.toDate() })} />
											<Form.Text className="text-muted">
												{t('machines.labels.recursive_start')}
											</Form.Text>
										</Col>
										<Col>
											<DateTime initialValue={curMainteance.recursive_end} dateFormat='DD/MM/YYYY' timeFormat={false} name="recursive_end" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerMainteance({ name: 'recursive_end', value: e.toDate() })} />
											<Form.Text className="text-muted">
												{t('machines.labels.recursive_end')}
											</Form.Text>
										</Col>
									</Row>
									<Row>
										<Col sm="2">
											<Form.Text className="text-muted">
												{t('machines.labels.maiteance_recursion_frequency')}
											</Form.Text>
										</Col>
										<Col sm="1">
											<Form.Check type="checkbox" value="1" label={format(setDay(new Date(), 1), 'cccccc', { locale: it })} />
										</Col>
										<Col sm="1">
											<Form.Check type="checkbox" value="2" label={format(setDay(new Date(), 2), 'cccccc', { locale: it })} />
										</Col>
										<Col sm="1">
											<Form.Check type="checkbox" value="3" label={format(setDay(new Date(), 3), 'cccccc', { locale: it })} />
										</Col>
										<Col sm="1">
											<Form.Check type="checkbox" value="4" label={format(setDay(new Date(), 4), 'cccccc', { locale: it })} />
										</Col>
										<Col sm="1">
											<Form.Check type="checkbox" value="5" label={format(setDay(new Date(), 5), 'cccccc', { locale: it })} />
										</Col>
										<Col sm="1">
											<Form.Check type="checkbox" value="6" label={format(setDay(new Date(), 6), 'cccccc', { locale: it })} />
										</Col>
										<Col sm="1">
											<Form.Check type="checkbox" value="0" label={format(setDay(new Date(), 0), 'cccccc', { locale: it })} />
										</Col>
									</Row>
								</Col>
							</Form.Group>
							: ''
						}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseMainteance}> {t('modal.close')}</Button>
					{canWrite ?
						<Button className="btn-myprimary" form="companyForm" onClick={handleSaveMainteance}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}






export default translate()(ModalMachine)