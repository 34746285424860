import {firestore} from '../firebaseIndex'


/*
data = {
    name: "string",
	startDate: firebase.firestore.Timestamp.fromDate(new Date()),
	endDate: firebase.firestore.Timestamp.fromDate(new Date()),
	ownedby: ["2222",]  <--- array of strings 
}
*/

const addContract = async (domainId, data) => {
    console.log("addContract: start")
    const contracts = await firestore.collection('DOMAINS')
                                    .doc(domainId)
                                    .collection('contracts')
    console.log("addContract - contracts: " , contracts)
    if(contracts === null){
        console.log("addContract: collection('contracts') not available")
    }

    const res = await firestore.collection('DOMAINS')
                                .doc(domainId)
                                .collection('contracts')
                                .add(data);
    console.log("addContract - res: ", res)
    const newdata = {
        ...data,
        id: res.id,
    }
    return newdata
}

const editContract = async (domainId, contractId, data) => {
    delete data.structureList
    try {
        const contract = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
        const res = await contract.set(
            data
        ).then( () => {
			console.log("editContract - edit successfully")
			return data
		}).catch( error => {
			console.log("editContract - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editContract error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
const getContractById = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getContractById error: ", e)
        return { error: e }
    }
}



const getContractList = async (domainId, adminUserId) => {
    if(adminUserId === null || adminUserId === undefined){
        const snapshot = await firestore.collection('DOMAINS')
                                        .doc(domainId)
                                        .collection('contracts')
                                        .get()
        const contractList = snapshot.docs.map( doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return contractList;
    }else{
        const snapshot = await firestore.collection('DOMAINS')
                                        .doc(domainId)
                                        .collection('contracts')
                                        .where('ownedby', 'array-contains', adminUserId)
                                        .get()
        const contractList = snapshot.docs.map( doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return contractList;
    }
}


const getContractListExpanded = async (domainId, adminUserId) => {
    let q = await firestore.collection('DOMAINS')
                            .doc(domainId)
                            .collection('contracts')

    
    if( !(adminUserId === null || adminUserId === undefined) ){
        q = q.where('ownedby', 'array-contains', adminUserId)
    }

    const snapshot = await q.get()
    
    //const snapshot = await firestore.collection('contracts').get();

    const contractList = snapshot.docs.map( async (doc) => {
        const firebaseData = doc.data()
        const data = {
            ...firebaseData,
            id: doc.id,
        }
        
        const docBaseCompany = await firestore.collection('DOMAINS')
                                            .doc(domainId)
                                            .collection('contracts')
                                            .doc(data.id)
                                            .collection('baseCompany')
                                            .doc('BASE')
                                            .get()
        const baseCompany = docBaseCompany.data()
        
        if(baseCompany !== undefined){
            data.baseCompany = baseCompany
        }
        

        const resStructure = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(data.id)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .get();
        const structureList = resStructure.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
        data.structureList = structureList


        return data
    })

    const endList = Promise.all(contractList).then(values => {
        //console.log(values)
        return values
    })
    //return contractList;
    return endList
}


export {addContract}
export {editContract}
export {getContractList}
export {getContractListExpanded}
export {getContractById}

