import React, { useState, useContext, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Modal, Form, FormControl, InputGroup, Nav, Tab, Badge, Spinner, ButtonGroup, DropdownButton, Popover, ListGroup } from 'react-bootstrap';
import { format, setHours, setMinutes, setSeconds, differenceInCalendarDays, differenceInDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { translate } from 'react-polyglot'
import QRCode from 'qrcode.react'
import DateTime from 'react-datetime';
import firebase from 'firebase/app'

import GlobalDataContext from '../../contexts/globaldataContext.js';
import LocaleContext from '../../contexts/locale.js'
import EditContext from "../../contexts/edit.js"
import { firebaseAuth } from '../../../provider/AuthProvider.js';
import AlertSection from '../../AlertSection.js';
import { makeid, checkComponentPresence, getSelectedElement, getFloatNumber, myTimestampToDate, rgba2hex, getValue, getBaseObjectValue, isEditable, propComparator, checkDatePresence, getTimeValue, getFormattedDate, getSelectedElementB }  from '../../../util/ui_utils.js';
import { MultipleToggleEdit, renderItemNote, ServiceBadge, WeekDays, renderActivityPeriodListPopover, Frequency, ResultBar } from '../../StructureGraphicElements.js'
import { checkNextUpdate, getPlannedDays } from './sectionModalRoom.js';


////////////////////////////////////////////////////////////////////////////////////////////////////////
export function ElementFormParamHistory({ element, functions, param, t, param_list, label, canWrite, base_object }) {

	const edit = useContext(EditContext)
	const { locale } = useContext(LocaleContext)
	const { contract, contractId, risk_area_list, risk_area_dict, } = useContext(GlobalDataContext)
	const { userData } = useContext(firebaseAuth)
	const [showHistory, setShowHistory] = useState(false)

	const childRefAlert = useRef()

	let now = new Date()
	now.setHours(0, 0, 0)
	const newstatus = {
		value: element[param],
		prev_value: element[param] || '',
		startDate: firebase.firestore.Timestamp.now(),
		creationDate: firebase.firestore.Timestamp.now(),
		param: param,
		id: makeid(6),
		user_id: localStorage.getItem('userId'),
		userName: userData.displayName || 'admin'
	}

	const nextnewstatus = {
		value: element[param],
		prev_value: element[param] || '',
		startDate: firebase.firestore.Timestamp.now(),
		creationDate: firebase.firestore.Timestamp.now(),
		param: param,
		id: makeid(6),
		user_id: localStorage.getItem('userId'),
		userName: userData.displayName || 'admin'
	}

	const [showParamEdit, setShowParamEdit] = useState(false)
	const [newParamEdit, setNewParamEdit] = useState(true)
	const [curParam, setCurParam] = useState(newstatus)
	const [nextParam, setNextParam] = useState(nextnewstatus)
	const [editedElement, setEditedElement] = useState(false)
	const [with_end, setWithEnd] = useState(false)

	//----status
	const handleCloseParam = () => setShowParamEdit(false);
	const handleSaveParam = () => {
		if (edit.editMultiple) {
			let can_save = true
			edit.editList.map(r => {
				if (r.planning_event_list) {
					let editlist = [...r.planning_event_list]
					can_save = can_save && !checkDatePresence(editlist.filter(e => e.type === param), myTimestampToDate(curParam.startDate))
					editlist.push(curParam)
					if (with_end) {
						editlist.push(nextParam)
					}
					console.log("MULTIROOM CHECK EVENT LIST", editlist)
				}
				console.log(can_save)
			})
			if (can_save) {
				let editlist = []
				if (element.planning_event_list) {
					editlist = [...element.planning_event_list]
				}
				editlist.push(curParam)
				let next_update = checkNextUpdate(element.next_update, curParam.startDate)
				if (with_end) {
					editlist.push(nextParam)
				}
				editlist.sort(propComparator('startDate'))
				if (next_update < firebase.firestore.Timestamp.now()) {
					console.log("need recalc MULTI")
					functions.setItem({ ...element, planning_event_list: editlist, next_update: next_update, needRecalc: true })
				} else {
					functions.setItem({ ...element, planning_event_list: editlist, next_update: next_update })
				}
				functions.setEdit(true)
				setShowParamEdit(false);
			} else {
				childRefAlert.current.addAlert('danger', t('structure.messages.status_overlap'))
			}
		} else {
			let editlist = []
			let can_save = true
			if (element.planning_event_list) {
				editlist = [...element.planning_event_list]
			}
			if (newParamEdit) {
				can_save = can_save && !checkDatePresence(editlist.filter(e => e.type === param), myTimestampToDate(curParam.startDate))
				if (can_save) {

					editlist.push(curParam)
					if (with_end) {
						editlist.push(nextParam)
					}
					editlist.sort(propComparator('startDate'))
					console.log(editlist)
				}
			} else {
				const c = checkComponentPresence(curParam, element.planning_event_list, 'id')
				if (c > -1) {
					editlist.splice(c, 1, curParam)
				}
				const d = checkComponentPresence(nextParam, element.planning_event_list, 'id')
				if (d > -1) {
					editlist.splice(d, 1, nextParam)
				}
			}
			if (can_save) {
				let next_update = checkNextUpdate(element.next_update, curParam.startDate)
				//			functions.edit({ name: 'planning_event_list', value: editlist })
				console.log("NEXT UPDATE", next_update, curParam.startDate)
				if (next_update < firebase.firestore.Timestamp.now()) {
					console.log("need recalc")
					functions.setItem({ ...element, planning_event_list: editlist, next_update: next_update, needRecalc: true })
				} else {
					functions.setItem({ ...element, planning_event_list: editlist, next_update: next_update })
				}
				functions.setEdit(true)
				console.log('can save', editlist)
				setShowParamEdit(false);
			} else {
				console.log('overlap')
				childRefAlert.current.addAlert('danger', t('structure.messages.status_overlap'))
			}
		}
	}


	const handleDeleteParam = (el) => {
		console.log(el)
		let editlist = element.planning_event_list
		const c = checkComponentPresence(el, element.planning_event_list, 'id')
		console.log(c)
		if (c > -1) {
			editlist.splice(c, 1)
			console.log('new_list', editlist)
			functions.edit({ name: 'planning_event_list', value: editlist })
		}
	}


	const handleShowNewParam = () => {
		console.log(base_object, param, element[param], param_list, element)
		/* 		newstatus.value = ''
				if (param_list) {
					if (base_object){
						newstatus.value = param_list[0]
					}else{
						if (param_list.length>0){
							newstatus.value = param_list[0].value
						}
					}
				} */
		newstatus.value = element[param]
		nextnewstatus.value = element[param]
		newstatus.prev_value = element[param] || ''
		nextnewstatus.prev_value = element[param] || ''
		setCurParam(newstatus)
		setNewParamEdit(true)
		setShowParamEdit(true)
		setEditedElement(false)
		setWithEnd(false)
	}
	const handleShowEditParam = (i, index) => {
		console.log('edit', i, index)
		setCurParam(i)
		setNewParamEdit(false)
		setShowParamEdit(true)
		setEditedElement(false)
	}
	const onChangeWe = (event) => {
		console.log(event)
		let { value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
				setWithEnd(true)
			} else if (value === 'false') {
				setWithEnd(false)
				value = false
			}
		}
	}


	const onChangeHandlerParam = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value, curParam)
		setCurParam({ ...curParam, [name]: value })
		if (name === 'value') {
			setNextParam({ ...nextParam, prev_value: value })
		}
		setEditedElement(true)
	}

	const onChangeHandlerNextParam = (event) => {
		let { name, value, type } = event
		if (type === 'radio') {
			console.log("is radio")
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		if (name === 'next_value') {
			name = 'value'
		}
		console.log(event, type, name, value, nextParam)
		setNextParam({ ...nextParam, [name]: value })
		setEditedElement(true)
	}



	return (
		<>
			{edit.editMultiple ?
				<MultipleToggleEdit param={param} />
				: ''}
			<Col>
				<Row>
					<Col className="text-right">
						<Button variant="outline-primary" size="sm" disabled={edit.editMultiple && edit.editParamList.indexOf(param) === -1} onClick={() => handleShowNewParam()}><FontAwesomeIcon icon="plus-square" /> {t('structure.labels.planning_add')} ({label})</Button>
					</Col>
				</Row>
				{element.planning_event_list && element.planning_event_list.filter(e => e.param === param).length > 0 ?
					<Row>

						<Col>
							{t('structure.labels.planning')} {label} &nbsp;
							{element.planning_event_list && element.planning_event_list.filter(e => (e.param === param && myTimestampToDate(e.startDate) < now)).length > 0 ?
								(showHistory ?
									<Button variant="secondary" onClick={() => setShowHistory(false)} size="sm"><FontAwesomeIcon icon="history" />{t('global.labels.hide_history')}</Button>
									:
									<Button variant="outline-secondary" onClick={() => setShowHistory(true)} size="sm"><FontAwesomeIcon icon="history" />{t('global.labels.view_history')}</Button>
								)

								: ''}
						</Col>
					</Row>
					: ''}
				<Row>
					{element.planning_event_list ?
						<Col>
							{element.planning_event_list.filter(e => (e.param === param && myTimestampToDate(e.startDate) >= now)).length > 0 ?
								<>
									<Row>
										<Col className="bg-secondary text-light ml-1">{label}</Col>
										<Col className="bg-secondary text-light ml-1" sm="2">{t('global.labels.date')} </Col>
										<Col className="bg-secondary text-light ml-1" >{t('global.labels.user')} </Col>
										<Col className="bg-secondary text-light ml-1 text-center" sm="2">{t('global.labels.actions')}</Col>
									</Row>
									{element.planning_event_list.filter(e => (e.param === param && myTimestampToDate(e.startDate) >= now)).map((i, key) => (
										<Row key={"next_" + key} className="border-bottom mb-1 pt-1 text-secondary">
											{param_list ?
												<Col className="font-weight-bold">
													{base_object ?
														<>
															{getBaseObjectValue(getSelectedElement(i.value.Id, param_list, 'Id'), 'name', locale)}</>
														:
														<>{getValue(getSelectedElement(i.value, param_list, 'id'), 'label')}</>
													}
												</Col>
												:
												<Col className="font-weight-bold">{i.value}</Col>
											}
											<Col sm="2">
												{format(myTimestampToDate(i.startDate), 'dd/MM/yyyy')}
												{i.note && i.note.length > 0 ?
													<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={renderItemNote(i.note)}>
														<Badge size="sm" variant="info"><FontAwesomeIcon icon="comment-alt" /></Badge>
													</OverlayTrigger>
													: ''}
												{getPlannedDays(myTimestampToDate(i.startDate), element.planning_event_list.filter(e => (e.param === param))[key + 1], t)}
											</Col>
											<Col>
												{i.userName}
											</Col>
											<Col className="text-center" sm="2">
												{!edit.editMultiple &&
													<ButtonGroup>
														<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditParam(i, key)} /></Button>
														{canWrite &&
															<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteParam(i, key)} /></Button>
														}
													</ButtonGroup>
												}
											</Col>
										</Row>
									))}
								</>
								: ''}
							{showHistory && element.planning_event_list && element.planning_event_list.filter(e => (e.param === param && myTimestampToDate(e.startDate) < now)).length > 0 ?
								<>
									{!element.planning_event_list.filter(e => (e.param === param && myTimestampToDate(e.startDate) >= now)).length > 0 ?
										<Row>
											<Col className="bg-secondary text-light ml-1">{t('global.labels.value')} </Col>
											<Col className="bg-secondary text-light ml-1">{t('global.labels.date')} </Col>
											<Col className="bg-secondary text-light ml-1" >{t('global.labels.user')} </Col>
											<Col className="bg-secondary text-light ml-1 text-center" sm="2">{t('global.labels.actions')}</Col>
										</Row>
										: ''}
									{element.planning_event_list.filter(e => (e.param === param && myTimestampToDate(e.startDate) < now)).map((i, key) => (
										<Row key={"pre_" + key} className="border-bottom mb-1 pt-1">
											{param_list ?
												<Col className="font-weight-bold">
													{base_object ?
														<>
															{getBaseObjectValue(getSelectedElement(i.value.Id, param_list, 'Id'), 'name', locale)}</>
														:
														<>{getValue(getSelectedElement(i.value, param_list, 'id'), 'label')}</>
													}
												</Col>
												:
												<Col className="font-weight-bold">{i.value}</Col>
											}
											<Col>
												{format(myTimestampToDate(i.startDate), 'dd/MM/yyyy')}
												{i.note && i.note.length > 0 ?
													<OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={renderItemNote(i.note)}>
														<Badge size="sm" variant="info"><FontAwesomeIcon icon="comment-alt" /></Badge>
													</OverlayTrigger>
													: ''}&nbsp;
												{getPlannedDays(myTimestampToDate(i.startDate), element.planning_event_list.filter(e => (e.param === param))[key + 1], t)}

											</Col>
											<Col>
												{i.userName}
											</Col>
											<Col className="text-center" sm="2">
												{!edit.editMultiple &&
													<ButtonGroup>
														<Button size="sm" variant="outline-info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditParam(i, key)} /></Button>
														{canWrite &&
															<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteParam(i, key)} /></Button>
														}
													</ButtonGroup>
												}
											</Col>
										</Row>
									))}


								</>
								: ''}
						</Col>
						: ''
					}
				</Row>
			</Col>





			<Modal show={showParamEdit} onHide={handleCloseParam} size="xl" className="bg-secondary" backdrop="static">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					{newParamEdit ?
						<Modal.Title variant="primary">{t('structure.labels.new_plan')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>
						:
						<Modal.Title variant="info">{t('structure.labels.edit_plan')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<AlertSection ref={childRefAlert} />
					<Container fluid>
						<Form.Group as={Row} controlId="value" className="border-bottom">
							<Form.Label column sm="2">{label} </Form.Label>
							{param_list ?
								<Col sm="8">
									{base_object ?
										<>
											{param_list.map((p, k) => (
												<>
													<Form.Check key={k} type="radio" name="value" value={p.Id} checked={getValue(curParam.value, 'Id') === p.Id} label={p.name[locale]} onChange={(event) => onChangeHandlerParam({ name: "value", type: 'select', value: getSelectedElement(event.target.value, param_list, 'Id') })} />
												</>
											))}
										</>
										:
										<>
											{param_list.map((p, k) => (
												<Form.Check key={k} type="radio" name="value" value={p.id} checked={curParam.value === p.id} label={p.label} onChange={(event) => onChangeHandlerParam(event.currentTarget)} />
											))}
										</>}
								</Col>
								:
								<Col sm="8">
									<Form.Control className="mb-2" type="text" name="value" value={curParam.value} onChange={(event) => onChangeHandlerParam(event.currentTarget)} />
								</Col>
							}
						</Form.Group>
						<Form.Group as={Row} className="border-bottom pb-2">
							<Form.Label column sm="2">{t('structure.labels.suspended_from')} </Form.Label>
							<Col sm="8">
								<DateTime initialValue={myTimestampToDate(curParam.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerParam({ name: 'startDate', value: firebase.firestore.Timestamp.fromDate(setSeconds(setMinutes(setHours(e.toDate(), 0), 0), 0)) })} />
							</Col>
						</Form.Group>
						{newParamEdit ?
							<Form.Group as={Row} controlId="withEnd" className="border-bottom">
								<Form.Label column sm="2">{t('structure.labels.set_end')}</Form.Label>
								<Col sm="8">
									<Form.Check type="radio" name="with_end" value={true} checked={with_end} label={t('global.labels.yes')} onChange={(event) => onChangeWe(event.currentTarget)} />
									<Form.Check type="radio" name="with_end" value={false} checked={!with_end} label={t('global.labels.no')} onChange={(event) => onChangeWe(event.currentTarget)} />
								</Col>
							</Form.Group>
							: ''}
						{with_end ?
							<>
								<Form.Group as={Row} className="border-bottom">
									<Form.Label column sm="2">
										{t('structure.labels.suspended_to')}
									</Form.Label>
									<Col sm="8">
										<DateTime initialValue={myTimestampToDate(nextParam.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerNextParam({ name: 'startDate', value: firebase.firestore.Timestamp.fromDate(setSeconds(setMinutes(setHours(e.toDate(), 0), 0), 0)) })} />
										<Form.Text className="text-muted">
											{t('structure.labels.excluded')}
										</Form.Text>
									</Col>
									<Col>
										{differenceInCalendarDays(myTimestampToDate(nextParam.startDate), myTimestampToDate(curParam.startDate))} {t('global.labels.day')}
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="nextValue" className="border-bottom">
									<Form.Label column sm="2">{label} ({t('global.labels.back_value')})</Form.Label>
									{param_list ?
										<Col sm="8">
											{base_object ?
												<>
													{param_list.map((p, k) => (
														<>
															<Form.Check key={k} type="radio" name="next_value" value={p.Id} checked={getValue(nextParam.value, 'Id') === p.Id} label={p.name[locale]} onChange={(event) => onChangeHandlerNextParam({ name: "value", type: 'select', value: getSelectedElement(event.target.value, param_list, 'Id') })} />
														</>
													))}
												</>
												:
												<>
													{param_list.map((p, k) => (
														<Form.Check key={k} type="radio" name="next_value" value={p.id} checked={nextParam.value === p.id} label={p.label} onChange={(event) => onChangeHandlerNextParam(event.currentTarget)} />
													))}
												</>}
										</Col>
										:
										<Col sm="8">
											<Form.Control className="mb-2" type="text" name="next_value" value={nextParam.value} onChange={(event) => onChangeHandlerNextParam(event.currentTarget)} />
										</Col>
									}
								</Form.Group>
							</>
							: ''}
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('structure.labels.note')}</Form.Label>
							<Col sm="8">
								<Form.Control className="mb-2" type="text" name="note" value={curParam.note} onChange={(event) => onChangeHandlerParam(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					{t('global.labels.planned_at')} {format(myTimestampToDate(curParam.creationDate), 'dd/MM/yyyy')}
					<Button variant="secondary" onClick={handleCloseParam}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className="btn-primary" form="companyForm" onClick={handleSaveParam}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>

		</>
	)
}
