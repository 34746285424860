import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, InputGroup, FormControl, Row, Col, Button, Spinner, ButtonGroup } from 'react-bootstrap';
//import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
//import { format, setDay } from 'date-fns'
//import { it } from 'date-fns/locale'
//import { SketchPicker } from 'react-color';
//import reactCSS from 'reactcss'
//import "@nateradebaugh/react-datetime/scss/styles.scss";
//import CurrencyInput from 'react-currency-input-field';
import { checkComponentPresence, compareName, getMediaIcon, getElementPreview, getBaseObjectValue } from "../util/ui_utils.js"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalMediaType from "../components/ModalMediaType"
import PageTitle from "../components/PageTitle"
import SearchSection from "../components/SearchSection"

import { getMediaTypeList, deleteMediaType } from '../Firebase2/Storage2/dbGlobalSettings';


import DomainContext from '../components/contexts/domainContext'


const contractId = sessionStorage.getItem('contractId')

const MediaTypesView = ({ t, uData }) => {

	const { locale } = useContext(LocaleContext)
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const icon_list = ['file', 'image', 'map', 'book', 'certificate', 'archive']

	const section_list = {
		contract: { label: t('global.labels.contract') },
		structure: { label: t('structure.labels.department') },
		room: { label: t('global.labels.room') },
		product: { label: t('global.labels.product') },
		equipment: { label: t('global.labels.equipment') },
		machine: { label: t('global.labels.machine') },
		cart: { label: t('global.labels.cart') },
		material: { label: t('global.labels.material') },
	}
	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [newelement_list, setNewElementList] = useState([])
	const [oldelement_list, setOldElementList] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)
	const [showArchived, setShowArchived] = useState(false)
	const [filterAppliedList, setFilterAppliedList] = useState({ section: [] })

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		console.log('index', c)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}

	const initValues = async () => {
		console.log(uData)
		setLoading(true)
		let telement_list = await getMediaTypeList()
		telement_list = telement_list.sort(compareName)
		setElementList(telement_list)
		setNewElementList([...telement_list])
		setLoading(false)
	}



	useEffect(() => {
		initValues()
	}, [])


	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteMediaType(el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('mediatype.messages.delete_mediatype_title'), t('mediatype.messages.delete_mediatype'), cb)
	}





	return (
		<div>

			{/* <SubNavBarRegistry curpage={'mediatypes'} t={t} /> */}
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('navs.settings.mediatype')} admin={true}/>
					</Col>
					<Col className="text-center">
						<ButtonGroup size="sm" className="mr-1">
							{filterAppliedList.section.length > 0 ?
								<Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'section': [] })}><FontAwesomeIcon icon="filter" /><FontAwesomeIcon icon="times" /></Button>
								:

								<Button disabled variant="secondary"><FontAwesomeIcon icon="filter" /></Button>
							}
							{Object.keys(section_list).map((g, k) => (
								<Button key={k} size="sm" disabled={element_list && element_list.filter(f=> f.section===g).length===0} variant={filterAppliedList['section'].indexOf(g) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('section', g)}>
									{section_list[g].label}
								</Button>
							))}
						</ButtonGroup>
					</Col>
					<Col sm="2">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<ModalMediaType is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} icon_list={icon_list} section_list={section_list} />
					</Col>
				</Row>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list && element_list.length > 0 ?
					<>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ml-1">{t('global.labels.name')}</Col>
							<Col className="bg-secondary ml-1">{t('global.labels.icon')}</Col>
							<Col className="bg-secondary ml-1">{t('global.labels.section')}</Col>
							<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element_list
							.filter(i => (filterAppliedList.section.length === 0 || filterAppliedList.section.indexOf(i.section) >= 0))
							.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
							.map((i, key) => (
								<Row key={key} className="element_row p-1 border-bottom " >
									{i.isArchived ?
										<Col><FontAwesomeIcon icon="archive" /><s>{i.name}</s></Col>
										:
										<Col><b>{i.name}</b></Col>
									}
									<Col>
										<FontAwesomeIcon icon={i.icon} />&nbsp;
										{i.isThumb?'anteprima':''}
									</Col>
									<Col>{section_list[i.section].label}</Col>
									<Col sm="1" className="text-center">
										<ButtonGroup>
											<ModalMediaType is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite} icon_list={icon_list} section_list={section_list} />
											{i.isArchived ?
												<Button variant="danger" onClick={() => handleDelete(i)} ><FontAwesomeIcon icon="trash" /></Button>
												: ''}
										</ButtonGroup>
									</Col>

								</Row>
							))}
					</>
					: <> <Row><Col>
						{t('mediatypes.messages.no_mediatype')}
					</Col></Row>
					</>
				}




			</Container>

		</div >
	);
}

export default translate()(MediaTypesView)



