import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap';
const SortButton = ({ col, sort, callback }) => {
	const handleSort = (ev) => {
		if (ev !== sort.col){
			callback({col: ev, cresc:true})
		}else{
			callback({col: sort.col, cresc:!sort.cresc})

		}
    }
    return (
        <Button size="sm" variant="secondary" sortCol={col} onClick={() => handleSort( col)}>
            <FontAwesomeIcon icon=
                {sort.col === col ?
                        (sort.cresc
                            ? 'sort-up'
                            : 'sort-down'
                        )
                    : 'sort'
                }
            /></Button>
    )
}
export default SortButton;
