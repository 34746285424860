import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addInterventionReportValidation = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('interventionreportvalidationrecords')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addInterventionReportValidation error: ", e)
        return { error: e }
    }
}





///////////////////////////////////////////////////////////////////////////////
export const getInterventionReportValidationById = async (domainId, contractId, interventionreportvalidationId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('interventionreportvalidationrecords')
            .get(interventionreportvalidationId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getInterventionReportValidation error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getInterventionReportValidationList = async (domainId, contractId, workarea_id=null) => {
    
    try {
        if (workarea_id === null || workarea_id === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('interventionreportvalidationrecords')
                .get();

            const interventionreportvalidationList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return interventionreportvalidationList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('interventionreportvalidationrecords')
                .where('workarea_id', '==', workarea_id)
                .get();

            const interventionreportvalidationList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return interventionreportvalidationList;
        }
    } catch (e) {
        console.log("getInterventionReportValidationList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getInterventionReportValidationRange = async (domainId, contractId, startDate, endDate, workarea_id=null) => {
    //console.log("DB INTERVENTIONREPORTVALIDATION", domainId, contractId, startDate, endDate, workarea_id)
    try {
        let query = firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('interventionreportvalidationrecords')
        if( workarea_id !== undefined){
            query = query.where('workarea_id', '==', workarea_id)
        }
        query = query
                .where('date', ">=", startDate)
                .where('date', "<", endDate)
                
        const res = await query.get();

            const interventionreportvalidationList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return interventionreportvalidationList;
        
    } catch (e) {
        console.log("getInterventionReportValidationRange error: ", e)
        return { error: e }
    }
}






///////////////////////////////////////////////////////////////////////////////
export const editInterventionReportValidation = async (domainId, contractId, interventionreportvalidationId, data) => {
    try {
        const interventionreportvalidation =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('interventionreportvalidationrecords')
            .doc(interventionreportvalidationId);

        const res = await interventionreportvalidation.set(data)
            .then( () => {
                //console.log("editInterventionReportValidation - edit successfully")
                return data
            }).catch( error => {
                //console.log("editInterventionReportValidation - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editInterventionReportValidation error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteInterventionReportValidation = async (domainId, contractId, interventionreportvalidationId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('interventionreportvalidationrecords')
            .doc(interventionreportvalidationId).delete();
        return res
    }  catch (e) {
        console.log("deleteInterventionReportValidation error: ", e)
        return { error: e }
    }
}



