import React  from 'react';
import { Button, ButtonGroup  } from 'react-bootstrap';
import { myTimestampToDate } from '../util/ui_utils';

export const YearSelector = ({startDate, endDate, changeYear, selectedYear}) => {
    console.log(startDate, endDate)
    const startyear = myTimestampToDate(startDate).getFullYear()
    const endyear = myTimestampToDate(endDate).getFullYear()
    let curyear = startyear
    let yearlist = []
    while (curyear <= endyear) {
        yearlist.push(curyear)
        curyear = curyear + 1
    }
    return (
        <ButtonGroup>
            {yearlist.map((y, k) => (
                <Button variant={y === selectedYear ? 'info' : 'outline-info'} onClick={() => changeYear(y)} key={k} >{y}</Button>
            ))}
        </ButtonGroup>
    )

}