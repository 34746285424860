import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addSupplier = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('suppliercollection')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addSupplier error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getSupplierById = async (domainId, contractId, supplierId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('suppliercollection')
            .doc(supplierId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getSupplier error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getSupplierList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('suppliercollection')
            .get();

        const supplierList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return supplierList;
        
    } catch (e) {
        console.log("getSupplierList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editSupplier = async (domainId, contractId, supplierId, data) => {
    try {
        const supplier =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('suppliercollection')
            .doc(supplierId);

        const res = await supplier.set(data)
            .then( () => {
                console.log("editSupplier - edit successfully")
                return data
            }).catch( error => {
                console.log("editSupplier - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editSupplier error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteSupplier = async (domainId, contractId, supplierId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('suppliercollection')
            .doc(supplierId).delete();
        return res
    }  catch (e) {
        console.log("deleteSupplier error: ", e)
        return { error: e }
    }
}


