import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Image, ProgressBar, ButtonGroup, Row, Col, Form, Badge, Button, Card, InputGroup, Spinner, Nav, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
import { format, add, differenceInMonths, differenceInDays, subDays } from 'date-fns'

import DomainContext from '../components/contexts/domainContext'
import EditContractContext, { EditContractProvider } from '../components/contexts/editContractContext.js';

import { contractScope_dict, contractSector_dict, contractStatus_dict, contractStatus_list, contractType_dict } from "../components/constants/global_definitions"

import Footer from "../components/Footer"
import ElementFormUsers from '../components/FormUsers'
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import { ModalLoadOneContract, getContractDescription } from "../components/page_sections/contract_common"

import { getSelectedElement, getValue, getFloatNumber, myTimestampToDate, compareOrder, compareName, checkComponentPresence, getElementPreview, getAmountValue, myDateToTimestamp, getBaseObjectValue } from "../util/ui_utils.js"

import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { getContractListExpanded as getContractListExpanded2, addContract as addContract2, editContract as editContract2 } from '../Firebase2/Storage2/dbContract';
import { getSystemGroupList as getSystemGroupList2 } from '../Firebase2/Storage2/dbGroup';
import { getTemplateList, contractFromContract, contractFromTemplate, templateFromContract } from "../components/FirebaseApi/template_api.tsx"
import { ModalContract } from '../components/modal/ModalContract.js';
import firebase from 'firebase/app'


let page_licence_status = true

const popoverTiming = { show: 250, hide: 100 }
const section = 'contract'

const newcontract = {
    code: '',
    name: '',
    company: {},
    logo: '',
    website: '',
    structure: {},
    extension_month: 0,
    extension_month_active: false,
    geographic_area: '',
    auction_base: 0,
    rebate_rate: 0,
    object: '',
    ownedBy: [],
    startDate: firebase.firestore.Timestamp.now(),
    endDate: firebase.firestore.Timestamp.now(),
    creation_date: firebase.firestore.Timestamp.now(),
    end_precheck: firebase.firestore.Timestamp.now(),
    status: 'draft',
    type: 'hospital',
    scope: 'hospital',
    sector: 'private',
    cig: '',
    execution_mode: 'auto',
    risk_area_amount: {},
    serviceamount_list: [],
    servicehouramount_list: [],
    day_alert: 15,
    yeld_mq: 18,
    autocheck: [],
    contradictory: [],
    client_checks: [],
    check_mode: 'complete',
    control_validation_mode: 'global',
    is_archived: false,
}


const newcompany = {
    name: '',
    code: '',
    vat: '',
    cf: '',
    address: {
        street: '',
        number: '',
        zip: '',
        city: '',
        state: ''
    },
    phone_list: [],
    email: '',
    pec: '',
    website: '',
    logo: '',
    referent_list: [],
    management_group_list: [],
    bank: '',
    payment_mode: '',
    sdi: '',
    note: '',
    supply_list: [],
    structure_list: []
}






const checkContractEndDate = (endDate, extension, extension_active, alert_days) => {



    let final_date = myTimestampToDate(endDate)
    if (extension && extension_active) {
        final_date = add(final_date, { months: extension })
    }

    if (alert_days !== undefined) {
        const warning_date = subDays(final_date, alert_days)
        const diff = differenceInDays(final_date, warning_date)
        const diff2 = differenceInDays(final_date, new Date())
        console.log(warning_date, diff, diff2, alert_days)
        if (diff2 < diff) {
            if (diff2 < 0) {
                return <Badge variant='danger'>terminato</Badge>
            } else {
                return <Badge variant='warning'>in scadenza</Badge>
            }
        } else {
            return <></>
        }

    } else {
        if (new Date() >= final_date) {
            //END 
            return 1
        } else if (new Date() >= myTimestampToDate(endDate)) {
            //EXTENSION TIME
            return 0
        } else {
            // IN TIME
            return -1
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ContractDashboard = ({ t, uData, fromMenu }) => {
    const { domain, domain_data } = useContext(DomainContext)
    //console.log("DOMAIN contractdasboard", domain, domain_data)
    let userData = uData

    const childRefAlert = useRef()
    const childRefDelete = useRef()


    const contract_scope_dict = contractScope_dict({ t })
    const contract_sector_dict = contractSector_dict({ t })
    const contract_type_dict = contractType_dict({ t })

    //	 const { locale } = useContext(LocaleContext)
    const [element_list, setElementList] = useState([])
    const [loading, setLoading] = useState(false)

    const [canWrite, setWritePage] = useState(false)
    //    const [template_list, setTemplateList] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [filterData, setFilterData] = useState({ geographic_area: [], type: [], sector: [] })
    const [filterAppliedList, setFilterAppliedList] = useState({ geographic_area: [], type: [], sector: [] })
    const [viewArchived, setViewArchived] = useState(false)




    //filter
    const handleCloseFilter = () => {
        setShowFilter(false)
    }

    const resetAppliedFilter = (section) => {
        setFilterAppliedList({ ...filterAppliedList, [section]: [] })
        setShowFilter(false)
    }


    const updateAppliedFilter = (section, elem) => {
        const c = filterAppliedList[section].indexOf(elem)
        console.log('index', c)
        let f_data = [...filterAppliedList[section]]
        if (c === -1) {
            f_data.push(elem)
        } else {
            f_data.splice(c, 1)
        }
        setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
    }


    const [isGlobal, setIsGlobal] = useState(false)
    const [ui_list, setUiList] = useState({})



    const initValues = async () => {

        const company_list = []
        const structure_list = []
        const message_group_list = []
        setLoading(true)
        if (uData && (userData.isGlobalAdmin || uData.write_page.indexOf('contractdashboard') !== -1)) {
            setWritePage(true)
        }
        if (domain) {
            if (myTimestampToDate(getValue(domain_data, 'endDate')) < new Date()) {
                page_licence_status = false
            }
            let risk_area_list = await getBaseObjectTypeList2(domain, 'risk_areas')
            if (risk_area_list && !risk_area_list.error) {
                risk_area_list.sort(compareOrder)
            }
            const referent_role_list = await getBaseObjectTypeList2(domain, 'referent_role')
            const roles_list = await getBaseObjectTypeList2(domain, 'roles')
            const operative_role_list = await getBaseObjectTypeList2(domain, 'operative_role')
            let grlist = await getSystemGroupList2()
            let groupsystem_dict = {}
            grlist.map(g => (groupsystem_dict[g.id] = g))
            let risk_area_dict = Object.assign({}, ...risk_area_list.map((x) => ({ [x.Id]: x })))
            let filter_data = { geographic_area: [], type: [] }
            let newelement_list = []
            let templ_list = await getTemplateList(false)
            let template_list = []
            if (templ_list) {
                try {
                    template_list = templ_list.templateList.sort(compareName)
                    //                    setTemplateList(templ_list.templateList.sort(compareName))
                } catch (err) {
                    template_list = []

                }
            } else {
                templ_list = []
            }
            console.log("template_list", template_list)
            setUiList({ ...ui_list, roles_list: roles_list, operative_role_list: operative_role_list, company_list, structure_list, risk_area_list, risk_area_dict, referent_role_list, group_list: grlist, group_dict: groupsystem_dict, message_group_list, template_list })
            if (userData) {
                if (userData.isGlobalAdmin || ((!userData.contract_dict || Object.keys(userData.contract_dict).length === 0) && (userData.write_page.length || userData.write_page.length > 0))) {

                    newelement_list = await getContractListExpanded2(domain)
                    //            newelement_list = (domain === 'old') ? await getContractListExpanded() : await getContractListExpanded2(domain)
                    console.log('ALL contract list', newelement_list)
                    setIsGlobal(true)
                } else {
                    console.log("CONTRACTDATA not globaladmin userData", userData)
                    if (userData.contract_dict) {
                        newelement_list = await getContractListExpanded2(domain)
                        newelement_list = newelement_list.filter(m => (Object.keys(userData.contract_dict).indexOf(m.id) !== -1))
                    }
                }
                if (userData.isGlobalAdmin) {
                    setIsGlobal(true)
                    setWritePage(true)
                }
                //} else {
                //                newelement_list =  await getContractListExpanded2(domain)

            }
            newelement_list.map(c => {
                if (filter_data.geographic_area.indexOf(c.geographic_area) === -1) {
                    filter_data.geographic_area.push(c.geographic_area)
                }
                if (filter_data.type.indexOf(c.type) === -1) {
                    filter_data.type.push(c.type)
                }
            })
            setFilterData(filter_data)
            newelement_list = newelement_list.sort(compareName)
            setElementList(newelement_list)

        }
        setLoading(false)
    }



    useLayoutEffect(() => {
        console.log("Contract userData", userData)
    }, [userData])



    useEffect(() => {

        initValues()
        //}, [domain])
    }, [])






    return (
        <>
            <EditContractProvider>

                <DeleteConfirmationSection ref={childRefDelete} />
                <AlertSection ref={childRefAlert} />
                {console.log("--------DOMAINDATA", domain_data)}
                {domain_data && domain_data?.licence_status !== false &&
                    <Container fluid className="bg-white titleStickyTop">
                        {isGlobal && canWrite ?
                            <Row>
                                {element_list && element_list.filter(c => c.is_archived).length > 0 ?
                                    <Col>
                                        {viewArchived ?
                                            <Button size="sm" variant={viewArchived ? 'danger' : "outline-danger"} onClick={() => setViewArchived(!viewArchived)}>{t('contract.labels.hide_archived')}</Button>
                                            :
                                            <Button size="sm" variant={viewArchived ? 'danger' : "outline-danger"} onClick={() => setViewArchived(!viewArchived)}>{t('contract.labels.view_archived')}</Button>
                                        }
                                    </Col>
                                    : ''}
                                <Col className="text-right">
                                    <ModalDomainUser t={t} ui_list={ui_list} contract_list={element_list} />
                                </Col>
                            </Row>
                            : ''}
                        <Row>

                            {domain_data && domain_data?.licence_status !== false &&
                                <Col className="text-center">
                                    {element_list && element_list.length > 1 ?
                                        <ButtonGroup>
                                            <Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
                                            {filterAppliedList.geographic_area.length > 0 ?
                                                <>
                                                    {filterAppliedList.geographic_area.map((g, k) => (
                                                        <Button key={k} size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('geographic_area', g)}>
                                                            {g.length > 0 ? g : <i>non impostata</i>}
                                                        </Button>
                                                    ))}
                                                    <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('geographic_area')}><FontAwesomeIcon icon="times" /></Button>
                                                </>
                                                : ''}
                                            {filterAppliedList.type.length > 0 ?
                                                <>
                                                    {filterAppliedList.type.map((g, k) => (
                                                        <Button key={k} size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('type', g)}>
                                                            {contract_type_dict[g].label}
                                                        </Button>
                                                    ))}

                                                    <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('type')}> <FontAwesomeIcon icon="times" /></Button>
                                                </>
                                                : ''}
                                            {filterAppliedList.sector.length > 0 ?
                                                <>
                                                    {filterAppliedList.sector.map((g, k) => (
                                                        <Button key={k} size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('sector', g)}>
                                                            {contract_sector_dict[g].label}
                                                        </Button>
                                                    ))}

                                                    <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('sector')}> <FontAwesomeIcon icon="times" /></Button>
                                                </>
                                                : ''}
                                        </ButtonGroup>
                                        : ''}

                                </Col>
                            }
                        </Row>
                    </Container>
                }
                {domain_data && domain_data.licence_status !== false &&
                    <Container fluid>
                        {console.log("DOMAIN DATA", domain_data.licence_status)}
                        {loading ?
                            <Row>
                                <Col className="text-center">
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </Col>
                            </Row>
                            :
                            <>
                                {element_list && element_list.length > 0 ?
                                    <>

                                        <Row>

                                            {element_list.filter(c => (
                                                (viewArchived || !c.is_archived) && (
                                                    ((filterAppliedList.geographic_area.length === 0 || filterAppliedList.geographic_area.indexOf(c.geographic_area) !== -1)
                                                        &&
                                                        (filterAppliedList.type.length === 0 || filterAppliedList.type.indexOf(c.type) !== -1)
                                                        &&
                                                        (filterAppliedList.sector.length === 0 || filterAppliedList.sector.indexOf(c.sector) !== -1))
                                                )
                                            )
                                            ).map((i, key) => (
                                                <Col key={key} sm="12" md="6" xl="3" className="mt-2">
                                                    {console.log("----isglobal", isGlobal, canWrite)}
                                                    <OneContract contract={i} t={t} canWrite={canWrite} isGlobal={isGlobal} setUiList={setUiList} ui_list={ui_list} setElementList={setElementList} element_list={element_list} fromMenu={fromMenu} isGlobalAdmin={userData.isGlobalAdmin} />
                                                </Col>
                                            ))}


                                            {page_licence_status && isGlobal && (!domain_data.has_max_contract || element_list.filter(c => !c.is_archived).length <= domain_data.max_contract) ?
                                                <Col sm="12" md="6" xl="3" className="mt-2">
                                                    <Card className="h-100">
                                                        <Card.Body>
                                                            <Card.Text>
                                                                <ModalContract is_new={true} element_list={element_list} setElementList={setElementList} ui_list={ui_list} t={t} canWrite={canWrite} isGlobal={isGlobal} setUiList={setUiList} isGlobalAdmin={userData.isGlobalAdmin} />
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                : ''}
                                        </Row>
                                    </>
                                    : <> <Row><Col className="font-italic">
                                        {t('contracts.messages.no_contract')}
                                    </Col>
                                        {(page_licence_status && isGlobal) ?
                                            <Col sm="12" md="6" xl="3" className="mt-2">
                                                <Card className="h-100">
                                                    <Card.Body>
                                                        <Card.Text>
                                                            <ModalContract is_new={true} element_list={element_list} setElementList={setElementList} ui_list={ui_list} t={t} canWrite={canWrite} isGlobal={isGlobal} setUiList={setUiList} isGlobalAdmin={userData.isGlobalAdmin} />
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            : ''}
                                    </Row>
                                    </>
                                }
                            </>
                        }













                        <Modal show={showFilter} onHide={handleCloseFilter} size="xl">
                            <Modal.Header>
                                <Modal.Title>
                                    <FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container>
                                    <Row>
                                        <Col>
                                            {t('contracts.labels.filter_geographics')}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {filterData && filterData.geographic_area ?
                                                <ButtonGroup>
                                                    {filterData.geographic_area.map((g, k) => (
                                                        <Button key={k} size="sm" variant={filterAppliedList['geographic_area'].indexOf(g) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('geographic_area', g)}>
                                                            {g.length > 0 ? g : <i>non impostata</i>}
                                                        </Button>
                                                    ))}
                                                    {filterAppliedList.geographic_area.length > 0 ?
                                                        <Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'geographic_area': [] })}><FontAwesomeIcon icon="times" /></Button>
                                                        : ''}
                                                </ButtonGroup>
                                                : ''}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {t('contracts.labels.filter_type')}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {filterData && filterData.type ?
                                                <ButtonGroup>
                                                    {filterData.type.map((g, k) => (
                                                        <Button key={k} size="sm" variant={filterAppliedList['type'].indexOf(g) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('type', g)}>
                                                            {t('contract.labels.type_' + g)}
                                                        </Button>
                                                    ))}
                                                    {filterAppliedList.type.length > 0 ?
                                                        <Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'type': [] })}><FontAwesomeIcon icon="times" /></Button>
                                                        : ''}
                                                </ButtonGroup>
                                                : ''}

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {t('contracts.labels.filter_sector')}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <Button size="sm" variant={filterAppliedList['sector'].indexOf('private') === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('sector', 'private')}>
                                                    {t('contract.labels.scope_private')}
                                                </Button>
                                                <Button size="sm" variant={filterAppliedList['sector'].indexOf('public') === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('sector', 'public')}>
                                                    {t('contract.labels.scope_public')}
                                                </Button>
                                                {filterAppliedList.sector.length > 0 ?
                                                    <Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'sector': [] })}><FontAwesomeIcon icon="times" /></Button>
                                                    : ''}
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {t('contracts.labels.filter_ecolabel')}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseFilter}>
                                    {t('modal.close')}
                                </Button>
                            </Modal.Footer>
                        </Modal>






                    </Container>
                }
                <Footer />
            </EditContractProvider>
        </ >
    );
}








///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneContract = ({ contract, t, ui_list, setUiList, element_list, setElementList, canWrite, isGlobal, fromMenu, isGlobalAdmin }) => {

    const endDateStatus = checkContractEndDate(contract.endDate, contract.extension_month, contract.extension_month_active)
    const warningDays = checkContractEndDate(contract.endDate, contract.extension_month, contract.extension_month_active, contract.day_alert)
    const status_dict = contractStatus_dict({ t })
    return (
        <>
            <Card className={contract.is_archived ? "border-danger h-100" : "h-100"}>
                <Card.Header bg={(myTimestampToDate(contract.endDate) < new Date() ? "secondary" : "")}
                    className={(endDateStatus === 0 ? "text-warning" : (endDateStatus === 1 ? "text-danger" : ""))}
                >

                    {contract.is_archived &&
                        <FontAwesomeIcon icon="trash" />}
                    <b>{contract.name}</b>
                    {contract.managed_by_ecolabel &&
                        <Badge variant="light">{contract.ecolabel_denomination}</Badge>
                    }
                    {endDateStatus === 0 &&
                        <Spinner animation="grow" role="status" size="sm">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    }
                    {warningDays}
                </Card.Header>
                <Card.Body>
                    <Container fluid >

                        <Row className="">
                            <Col>
                                {t('contract.labels.start')}
                            </Col>
                            <Col >
                                {t('contract.labels.end')}
                            </Col>
                            {(contract.extension_month && contract.extension_month_active && contract.extension_month > 0) ?
                                <Col>
                                    {t('contract.labels.extension_month')}
                                </Col>
                                : ''
                            }
                        </Row>
                        <Row className="border-bottom mb-1 pb-2 font-weight-bold">
                            <Col className="text-info">
                                {format(myTimestampToDate(contract.startDate), 'dd/MM/yyyy')}
                            </Col>
                            <Col className={` ` + (endDateStatus === 0 ? "text-warning" : (endDateStatus === 1 ? "text-danger" : "text-info"))}>
                                {format(myTimestampToDate(contract.endDate), 'dd/MM/yyyy')}
                            </Col>
                            {contract.extension_month && contract.extension_month_active && contract.extension_month > 0 ?
                                <Col className={` ` + (endDateStatus === 0 ? "text-warning" : (endDateStatus === 1 ? "text-danger" : "text-info"))}>
                                    <> {contract.extension_month} {t('global.labels.months')}</>
                                </Col>
                                : ''
                            }
                        </Row>
                        {contract.end_precheck && myTimestampToDate(contract.end_precheck) <= new Date() && (contract.status === 'draft' || contract.status === 'testing') ?
                            <Row>
                                <Col className='bg-warning'>
                                    Verifica di conformità terminata
                                </Col>

                            </Row>
                            : ''}
                        <Row>
                            <Col>
                                {contract.company ?
                                    <>    {t('global.labels.basecomany')}: <b>{contract.company.name}</b></>
                                    : ''
                                }
                            </Col>
                            <Col sm="3">{getElementPreview(contract)} </Col>
                        </Row>
                        <Row>
                            <Col>
                                {getContractDescription(contract, t)}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {contract.status &&
                                    <>
                                        {status_dict[contract.status].label}
                                    </>
                                }
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer className={contract.is_archived ? "bg-danger" : ''}>
                    <Row >
                        <Col className="">
                            <ButtonGroup>
                                <ModalLoadOneContract element={contract} t={t} preload_contract={fromMenu} />
                                <ModalContract key={contract.id} element={contract} element_list={element_list} setElementList={setElementList} ui_list={ui_list} t={t} canWrite={canWrite} isGlobal={isGlobal} setUiList={setUiList} isGlobalAdmin={isGlobalAdmin} />
                            </ButtonGroup>
                        </Col>
                        {/*                         {!contract.structureList &&
                            <Col className="text-right">
                                <FontAwesomeIcon icon='exclamation-circle' /> <span className="text-muted">inserimento dati non completo</span>
                            </Col>
                            
                        } */}

                    </Row>

                </Card.Footer>
            </Card>
        </>
    )

}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalDomainUser = ({ t, contract_list, ui_list }) => {
    const { domain } = useContext(DomainContext)
    const [showEditUsers, setShowUsers] = useState(false);
    const handleCloseUsers = () => setShowUsers(false);
    const [isInvalid, setIsInvalid] = useState(false)
    const [editedElement, setEditedElement] = useState(false)

    return (
        <>
            <Button variant="outline-info" onClick={() => setShowUsers(true)}>

                <img src="/menu_icons/Gestione_utente.png" height="42"
                    className="d-inline-block align-top mr-1"
                    alt="utenti" />

                {t('navs.pages.users')}</Button>

            <Modal show={showEditUsers} onHide={handleCloseUsers} dialogClassName="custom-modal" backdrop="static" className="bg-secondary" scrollable={true}>
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        {t('navs.pages.users')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <ElementFormUsers t={t} contract_list={contract_list} functions={{ setIsInvalid: setIsInvalid, isInvalid: isInvalid }} ui_list={ui_list} domain={domain} />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUsers}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}




export default translate()(ContractDashboard)
