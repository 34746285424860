import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { translate } from 'react-polyglot'











const matrix_list = [
	{ min_index: 2, max_index: 8, level1: 2, level2: 2, level3: 3, lqa4: 0, lqa6: 0, lqa10: 0, pqa: 0, pql: 0 },
	{ min_index: 9, max_index: 15, level1: 2, level2: 3, level3: 5, lqa4: 0, lqa6: 0, lqa10: 0, pqa: 0, pql: 0 },
	{ min_index: 16, max_index: 25, level1: 3, level2: 5, level3: 8, lqa4: 0, lqa6: 0, lqa10: 1, pqa: 91.90, pql: 52.80 },
	{ min_index: 26, max_index: 50, level1: 5, level2: 8, level3: 13, lqa4: 0, lqa6: 1, lqa10: 2, pqa: 96.10, pql: 55.20 },
	{ min_index: 51, max_index: 90, level1: 5, level2: 13, level3: 20, lqa4: 1, lqa6: 2, lqa10: 3, pqa: 96.60, pql: 42.10 },
	{ min_index: 91, max_index: 150, level1: 8, level2: 20, level3: 32, lqa4: 2, lqa6: 3, lqa10: 5, pqa: 98.80, pql: 41.60 },
	{ min_index: 151, max_index: 280, level1: 13, level2: 32, level3: 50, lqa4: 3, lqa6: 5, lqa10: 7, pqa: 98.80, pql: 21.20 },
	{ min_index: 281, max_index: 500, level1: 20, level2: 50, level3: 80, lqa4: 5, lqa6: 7, lqa10: 10, pqa: 99.00, pql: 32.80 },
	{ min_index: 501, max_index: 1200, level1: 32, level2: 80, level3: 125, lqa4: 7, lqa6: 10, lqa10: 14, pqa: 98.80, pql: 0.80 },
	{ min_index: 1201, max_index: 3200, level1: 50, level2: 125, level3: 200, lqa4: 10, lqa6: 14, lqa10: 21, pqa: 99.00, pql: 0.10 },
	{ min_index: 3201, max_index: 10000, level1: 80, level2: 200, level3: 315, lqa4: 14, lqa6: 21, lqa10: 29, pqa: 99.00, pql: 0.00 }

]

const CheckMatrix = ({ t, uData }) => {


	const [selectedLevel, setSelectedLevel] = useState(2)
	const [selectedLqa, setSelectedLqa] = useState(10)

















	return (
		<div>
			<Container fluid>
				<Row className="mt-1">
					<Col className="h5 text-primary">
						Matrice ISO 2859
					</Col>
				</Row>
				<Row>
					<Col className="text-center">
						<Table bordered hover size="sm" striped>
							<thead>
								<tr>
									<th colspan="4" rowspan="2">&nbsp;</th>
									<th colspan="3">LQA</th>
									<th colspan="2" rowspan="2">&nbsp;</th>
								</tr>
								<tr>
									<th style={{ cursor: "pointer" }} className={selectedLqa === 4 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLqa(4)}>4%</th>
									<th style={{ cursor: "pointer" }} className={selectedLqa === 6 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLqa(6)}>6.50%</th>
									<th style={{ cursor: "pointer" }} className={selectedLqa === 10 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLqa(10)}>10%</th>
								</tr>
								<tr>
									<th rowspan="2">Numerosità del lotto</th>
									<th colspan="3">Numerosità del campione<br />(ISO 2859-1:1999)</th>
									<th rowspan="2" className={selectedLqa === 4 ? "bg-warning" : ''} >Ac <br /><i>(n°)</i></th>
									<th rowspan="2" className={selectedLqa === 6 ? "bg-warning" : ''} >Ac <br /><i>(n°)</i></th>
									<th rowspan="2" className={selectedLqa === 10 ? "bg-warning" : ''} >Ac <br /><i>(n°)</i></th>
									<th rowspan="2">PQA</th>
									<th rowspan="2">PQL</th>
								</tr>
								<tr>
									<th style={{ cursor: "pointer" }} className={selectedLevel === 1 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLevel(1)}>Livello 1</th>
									<th style={{ cursor: "pointer" }} className={selectedLevel === 2 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLevel(2)}>Livello 2</th>
									<th style={{ cursor: "pointer" }} className={selectedLevel === 3 ? "bg-warning" : 'bg-secondary text-light'} onClick={() => setSelectedLevel(3)}>Livello 3</th>
								</tr>
							</thead>
							<tbody>
								{matrix_list.map((row, key) => (
									<tr key={key}>
										<td>{row.min_index} - {row.max_index}</td>
										<td className={selectedLevel === 1 ? "bg-warning" : ''}>{row.level1}</td>
										<td className={selectedLevel === 2 ? "bg-warning" : ''}>{row.level2}</td>
										<td className={selectedLevel === 3 ? "bg-warning" : ''}>{row.level3}</td>
										<td className={selectedLqa === 4 ? "bg-warning" : ''} >{row.lqa4}</td>
										<td className={selectedLqa === 6 ? "bg-warning" : ''} >{row.lqa6}</td>
										<td className={selectedLqa === 10 ? "bg-warning" : ''} >{row.lqa10}</td>
										<td>{row.pqa}</td>
										<td>{row.pql}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Col>
				</Row>

			</Container>
		</div>
	);
}

export default translate()(CheckMatrix)

