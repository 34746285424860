import React, { useState, useContext, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Form, Button, } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { format } from 'date-fns'
import QRCode from 'qrcode.react'
import { firebase, auth, } from '../Firebase2/firebaseIndex.js';
import LocaleContext from '../components/contexts/locale.js'
import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"
import ModalUserPassword from "../components/ModalUserPassword"

/* var recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
	'size': 'invisible',
	'callback': function(response) {
	  // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
	  onSolvedRecaptcha();
	}
	}); */

let verificationId = null




const UserSettingsView = ({ t, uData }) => {
	console.log(uData)

	const childRefAlert = useRef()

	const { locale } = useContext(LocaleContext)
	const { setLocale } = useContext(LocaleContext)
	const [curuser, setCuruser] = useState({})
	const [message, setMessage] = useState()
	const [editedElement, setEditedElement] = useState(false)
	const [verificationId, setVerificationId] = useState()


	const [recaptchaVerifier, setCaptchaVerifier] = useState()


	const onChangeLocale = (newLocale) => {
		setLocale(newLocale.value)
		localStorage.setItem('language', newLocale.value)
	}

	const onChangeHandler = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curuser[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log("CHANGE OPERATION", name, value)
		setCuruser({ ...curuser, [name]: value })
		setEditedElement(true)

	}

	const doEMailVerification = () => {
		curuser.sendEmailVerification().then(() => {
			setMessage(`email di verifica inviata all'indirizzo ${curuser.email}`)
		})

	}




	const save = () => {

	}


	const doCheckPhone = () => {
		/*
				let recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container-id', {
					size: 'invisible',
					callback: (response) => {
						console.log('successfully created the captcha');
						console.log(response);
					},
				}
				);
				curuser.multiFactor.getSession()
				.then(function (multiFactorSession) {
					// Specify the phone number and pass the MFA session.
					let phoneInfoOptions = {
						phoneNumber: phoneNumber,
						session: multiFactorSession
					};
					let phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
					// Send SMS verification code.
		
					console.log(curuser, phoneNumber, phoneInfoOptions,  recaptchaVerifier)
		
					const prov = phoneAuthProvider.verifyPhoneNumber(
						phoneInfoOptions, recaptchaVerifier);
						console.log("PROVIDER", prov)
					return prov
				})
					.then(function (verificationId) {
						setVerificationId(verificationId)
						// Ask user for the verification code.
		
					}).catch(function (e) {
						if (e.code === 'auth/requires-recent-login') {
							setMessage("esegui logout e login prima di procedere")
						}
						recaptchaVerifier.clear();
						console.log(e)
					})
		*/
		console.log(curuser.phone_number)
		sendSMS(curuser.phone_number);
	}

	const sendSMS = async (phoneNumber) => {
		try {
			console.log("phone: ", phoneNumber)
			const session = await curuser.multiFactor.getSession()
			const phoneInfoOptions = {
				phoneNumber,
				session
			};
			const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
			const verificationId = await phoneAuthProvider.verifyPhoneNumber(
				phoneInfoOptions, recaptchaVerifier);
			console.log("verification ID: ", verificationId)
			setVerificationId(verificationId)
		} catch (e) {
			if (e.code === 'auth/requires-recent-login') {
				setMessage("esegui logout e login prima di procedere")
			}else{
				alert("IDENTITY ERROR..." + e.code +":"+ e.message)
			}
			console.log(e)
		}
	}




	useEffect(() => {
		const user = auth.currentUser
		setCuruser(user)
		setMessage()

		try {
			let _recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container-id', {
				size: 'invisible',
				callback: (response) => {
					console.log('successfully created the captcha');
					console.log(response);
				},
			}
			);
			setCaptchaVerifier(_recaptchaVerifier)
		} catch (e) {
			console.log(e)
		}


	}, [])

	return (
		<>

			<AlertSection ref={childRefAlert} />
			<Container fluid>
				<Row className="mt-1">
					<Col >
						<PageTitle title={t('global.pages.user_settings')} />
					</Col>
					<Col sm="2" className='right'>
						<Button onClick={save}>Salva</Button>
					</Col>
				</Row>
				<Row >
					<Col className="bg-danger text-center text-light">

						{message}
					</Col>
				</Row>
				<div id="recaptcha-container-id"></div>
				<Row className="mt-4 ">
					<Col sm="4">
					</Col>
					<Col sm="4">
						{uData.id &&
							<ModalUserPassword name={uData.displayName} user_id={uData.id} childRefAlertOr={childRefAlert} />
						}
					</Col>
				</Row>
				{curuser &&
					<>

						<Row>
							{console.log("CUR USER FIREBASE", curuser)}
							<Col>
								Email Verificata
							</Col>
							<Col>
								{curuser.emailVerified ? <>Si</> : <>No <Button onClick={doEMailVerification}>Esegui Verifica</Button></>}
							</Col>
						</Row>
						{curuser.multiFactor && curuser.multiFactor.enrolledFactors && curuser.multiFactor.enrolledFactors.length > 0 ?

							<OneMultifactor value={curuser.multiFactor.enrolledFactors[0]} />
							:
							<Row>
								<Col sm="4">
									{t('user_settings.labels.phone_number')} 
								</Col>
								<Col sm="6">
									<Form.Control type="text" name="phone_number" value={curuser.phone_number} onChange={(event) => onChangeHandler(event.target)} className="mb-2" />
									<Form.Label>

									{curuser.phone_number && curuser.phone_number.length<7
									&&
									<>(inserisci prefisso +39 se non presente)</>
								}
									</Form.Label>
								</Col>
								<Col>
									<Button onClick={doCheckPhone}>Esegui Verifica</Button>
								</Col>
							</Row>
						}
					</>
				}

				{verificationId &&
					<VerifyCodeSection verificationId={verificationId} curuser={curuser} />
				}

				<Form.Group as={Row} controlId="formUserCategory" className="border-bottom">
					<Col sm="2">
					</Col>
					<Form.Label column sm="2">{t('user_settings.labels.language')}</Form.Label>
					<Col sm="8">
						<Form.Control as="select" defaultValue={locale} className="mb-2" name="language" onChange={(event) => onChangeLocale(event.target)} >
							<option value="it">italiano</option>
							<option value="en">english</option>
						</Form.Control>
					</Col>
				</Form.Group>
				<Row>

				</Row>
				{uData.id ?
					<QRCode value={uData.id} />
					: ''}
			</Container >

		</>
	);
}

export default translate()(UserSettingsView)


const OneMultifactor = ({ value }) => {
	return (
		<Row>
			<Col>
				Secondo Fattore di verifica
			</Col>
			<Col>
				Tipo: {value.factorId}
			</Col>
			<Col>
				Numero: {value.phoneNumber}
			</Col>
			<Col>
				{value.enrollmentTime}
			</Col>
		</Row>
	)
}


function ElementFormLogs({ element, functions, t }) {

	return (
		<>
			<Form.Group as={Row} controlId="formUserCF">
				<Col>
					<Row>
					</Row>
					{element.log_list && element.log_list.length > 0 ?
						<>
							<Row className="bg-secondary text-light">
								<Col>{t('users.labels.log_description')} </Col>
								<Col>{t('users.labels.log_date')} </Col>
								<Col>&nbsp;</Col>
							</Row>
							{element.log_list.map((i, key) => (
								<Row>
									<Col></Col>
									<Col>{i.is_recursive}</Col>
									<Col>{i.description}</Col>
									<Col>{format(i.date, 'dd/MM/yy')}</Col>
									<Col>{i.price}</Col>
									<Col>
										<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" /> </Button>
										<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
									</Col>
								</Row>))
							}
						</>
						: <> {t('users.messages.no_log')}</>
					}
				</Col>
			</Form.Group>
		</>
	)
}



const VerifyCodeSection = ({ verificationId, curuser }) => {
	const [verificationCode, setVerificationCode] = useState('')


	const verifyCode = () => {
		console.log(verificationId, verificationCode)
		let cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
		let multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
		// Complete enrollment.
		console.log(multiFactorAssertion)
		return curuser.multiFactor.enroll(multiFactorAssertion, curuser.DisplayName);
	}

	return (
		<>


			< Row >

				<Col sm="4">
					Codice di verifica
				</Col>
				<Col sm="6">
					<Form.Control type="text" name="verification_code" value={verificationCode} onChange={(el) => setVerificationCode(el.target.value)} className="mb-2" />
				</Col>
				<Col sm="2">
					<Button onClick={verifyCode}>Conferma codice di verifica</Button>
				</Col>

			</Row>

		</>

	)
}
