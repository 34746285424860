import React, { createContext, useState, useEffect } from 'react'

const EditContractContext = createContext()

export const EditContractProvider = (props) => {
	const [structure_list, setStructureList] = useState([])


    
    return (

        <EditContractContext.Provider
            value={{
				structure_list, setStructureList,
            }}>
        {props.children}

        </EditContractContext.Provider>
    )
}
export default EditContractContext