import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container,  Row, Col, Button, Spinner, ButtonGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalFailureType from "../components/ModalFailureType"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"

import { InitContractHelper } from '../util/contract.js';
import { checkComponentPresence, compareName,  getElementPreview, getBaseObjectValue, getElementCount } from "../util/ui_utils.js"
import { RenderItemFileBadge } from '../components/StructureGraphicElements.js'


import { getFailureTypeList as getFailureTypeList2, deleteFailureType as deleteFailureType2 } from '../Firebase2/Storage2/dbFailureType';
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { getMessageGroupList } from '../Firebase2/Storage2/dbMessageGroup';



//const contractId = sessionStorage.getItem('contractId')
const companyId = '0'
let load_page =false 
const section = 'failuretype'
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const FailureTypesView = ({ t, uData }) => {

	const { locale } = useContext(LocaleContext)
	const { domain, domain_data } = useContext(DomainContext)
	const { contract  } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()
	let { cid } = useParams();
	let contractId = cid

	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		console.log(uData)
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('failuretype') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		console.log(domain, contractId, companyId)
		if (contract && domain.length > 0) {

			const category_list = await getBaseObjectTypeList2(domain, 'failuretype_category')
        	const _msggroup_list = await getMessageGroupList(domain, contractId)
			console.log(_msggroup_list.sort(compareName))
			setUiList({ ...ui_list, category_list ,msggroup_list: _msggroup_list})
			let newelement_list = await getFailureTypeList2(domain, contractId)
			newelement_list.sort(compareName)
			setElementList(newelement_list)
			setLoading(false)
			load_page = true
		} else {
			console.log("init contract return")

		}
	}


	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("iFAILURETYPE reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("FAILURETYPE init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])




	useEffect(() => {
		console.log('failuretype loadingcontract', load_page)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data =await deleteFailureType2(domain, contractId,  el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('failuretype.messages.delete_failuretype_title'), t('failuretype.messages.delete_failuretype', { name: el.name }), cb)
	}







	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('navs.registries.failuretype')} />
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite ?
							<ModalFailureType is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} ui_list={ui_list} canWrite={canWrite} />
							: ''}
					</Col>
				</Row>
				{!loading && element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="2">{t('global.labels.code')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.name')}</Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}
			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row key={key} className="element_row p-1 border-bottom " >
										<Col sm="2">{i.code}</Col>
										<Col><b>{i.description}</b></Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												<ModalFailureType is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} ui_list={ui_list} canWrite={canWrite} />
												{canWrite ?
													<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							: <>
								<Row>
									<Col className="font-italic">
										{t('failuretypes.messages.no_failuretype')}
									</Col>
								</Row>
							</>
						}
					</>
				}



			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('global.labels.failuretype') })} />
		</>
	);
}




export default translate()(FailureTypesView)



