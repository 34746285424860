import React, {useState} from 'react';
import { authMethods } from '../Firebase2/authmethods'
import { useHistory } from "react-router-dom"

export const firebaseAuth = React.createContext()

const AuthProvider = (props) => {
    const [inputs, setInputs] = useState({email: '', password: ''})
    const [errors, setErrors] = useState([])
    const [twofactor_errors, setTwofactorErrors] = useState()

    const [token, setToken] = useState(null)
    const [userData, setUserData] = useState(undefined)

  const history = useHistory()

    const handleSignup = () => {
        //authMethods.signup(inputs.email, inputs.password, setErrors, setToken)
    }

    const handleSignin = () => {
        authMethods.signin(inputs.email, inputs.password, setErrors, setToken, setTwofactorErrors)
    }
    const handleSigningTwofactor = (credential) => {
        authMethods.signinTwofactor(credential, setToken)
    }

    const handleSignout = async () => {
    setToken(null)
    await localStorage.removeItem('contractId')
    await localStorage.removeItem('userId')
    await localStorage.removeItem('userData')
    await localStorage.removeItem('token')
    await localStorage.removeItem('AuthToken')
    await sessionStorage.removeItem('contractId')
    await sessionStorage.removeItem('userId')
    await sessionStorage.removeItem('userData')
        setUserData(undefined)
        authMethods.signout(setErrors, setToken)
      history.push("../")
    }

    return (
        <firebaseAuth.Provider
            value={{
                handleSignup,
                handleSignin,
                token,
                setToken,
                inputs,
                setInputs,
                errors,
                twofactor_errors,
                setTwofactorErrors,
                handleSigningTwofactor,
                handleSignout,
                userData,
                setUserData
            }}>
            {props.children}

        </firebaseAuth.Provider>
    );
};

export default AuthProvider;