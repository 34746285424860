import React, { Component, useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, InputGroup, Container, FormControl, Row, Col, Form, Button, ButtonGroup, Tab, Nav, Navbar } from 'react-bootstrap';
//import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
//import { format, setDay } from 'date-fns'
//import { it } from 'date-fns/locale'
//import CurrencyInput from 'react-currency-input-field';
//import "@nateradebaugh/react-datetime/scss/styles.scss";
import SubNavBarService from "../components/SubNavBarService.js";
const newrule = {
	businessname: '',
	code: '',
	vat: '',
	cf: '',
	address: {
		street: '',
		number: '',
		zip: '',
		city: '',
		state: ''
	},
	phone: '',
	mobilephone: '',
	fax: '',
	email: '',
	pec: '',
	referent: {
		firstname: '',
		lastname: '',
		phone: '',
		mobilephone: '',
		fax: '',
		email: '',
		role: ''
	},
	bank: '',
	payment_mode: '',
	sdi: '',
	note: '',
	supply_list: []
}
const element_list = [
	{
		name: 'pippo',
		lastname: 'primo',
		birthdate: new Date(),
		qualification_list: []
	}

]

const workarea_list = [
	{ name: 'giro 1', room_list: [] },
	{ name: 'giro 2', room_list: [] },
]

const room_type_list = [
	{ name: 'bagno', id: 1 },
	{ name: 'corridoio', id: 2 },
	{ name: 'ingresso', id: 3 },
	{ name: 'stanza', id: 4 }
]

const furniture_list = [
	{ name: 'cestino', id: 11 },
	{ name: 'appendino', id: 12 },
	{ name: 'finestra', id: 13 },
	{ name: 'porta', id: 14 },
	{ name: 'lavandino', id: 15 },

]

const RulesView = ({ mylocation, t }) => {
	//const { locale } = useContext(LocaleContext)
	const [showEdit, setShow] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [curElement, setCurrentElement] = useState(newrule)

//	const [lang, setLang] = useState('en')
	const handleClose = () => setShow(false);
	const handleSave = () => {
		setShow(false);
	}
	const handleShowNew = () => {
		setCurrentElement(newrule)
		setNew(true)
		setShow(true)
	}
	/*
	const handleSubmit = (event) => {
		console.log(event)
		console.log('submit')
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		console.log(form)
	}
	*/
	const handleShowEdit = (id) => {
		setNew(false)
		setShow(true)
	}
	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')

		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}
	const onChangeHandlerSubData = (el) => {
		//		onChangeHandler({name: sub, value:new_sub})
	}

	return (
		<div>
			{/* <SubNavBarService curpage={'rules'} t={t} /> */}
			<Container fluid>
				<Row className="mt-1">
					<Col className="h3">
						{t('global.pages.methodology')}
					</Col>
					<Col sm={8}>
						<Col sm={4}>
							<InputGroup className="mb-3">
								<FormControl type="text" placeholder={t('search')} className="" />
								<InputGroup.Append>
									<Button variant="outline-secondary"><FontAwesomeIcon icon="search" /></Button>
								</InputGroup.Append>
							</InputGroup>
						</Col>
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('rules.buttons.add_new')}</Button>
					</Col>
				</Row>
				<Row className="p-1 text-light">
					<Col className="bg-secondary ml-1">{t('rules.labels.rule')}</Col>
					<Col className="bg-secondary ml-1">{t('rules.labels.reference')}</Col>
					<Col className="bg-secondary ml-1"></Col>
				</Row>
				<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
					<Modal.Header closeButton>
						{newEdit && <Modal.Title>{t('rules.modal.titlenew')}</Modal.Title>}
						{!newEdit && <Modal.Title>{t('rules.modal.titleedit')}</Modal.Title>}
					</Modal.Header>
					<Modal.Body>
							<ElementFormRules element={curElement} t={t} functions={{ edit: onChangeHandler }} />
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						<Button disabled className="btn-myprimary" form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" />{t('modal.save')}
						</Button>
					</Modal.Footer>
				</Modal>
			</Container>
		</div>
	);
}

export default translate()(RulesView)

function ElementFormDetails({ element, functions, t }) {

	return (
		<>
			<Form.Group as={Row} controlId="formRuleNme" className="border-bottom">
				<Form.Label column sm="2">{t('rules.labels.name')} </Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="name" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formRuleCode" className="border-bottom">
				<Form.Label column sm="2">{t('rules.labels.code')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="code" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}

function ElementFormEquipments({ element, functions, t }) {
	const newequipment = {
		code: '',
		name: '',
		type: '',
		quantity: 0
	}
	const [showEquipmentEdit, setShowEquipmentEdit] = useState(false)
	const [showEquipmentChoose, setShowEquipmentChoose] = useState(false)
	const [newEquipmentEdit, setNewEquipmentEdit] = useState(true)
	const [curEquipment, setCurEquipment] = useState(newequipment)
	const [isUpdating, setUpdating] = useState(false)
	const handleCloseEquipment = () => setShowEquipmentEdit(false);
	const handleCloseChooseEquipment = () => setShowEquipmentChoose(false);
	const handleSaveEquipment = () => {
		setShowEquipmentEdit(false);
		if (newEquipmentEdit) {
			let newequipment_list = element.equipment_list
			newequipment_list.push(curEquipment)
			console.log(newequipment_list)
			functions.edit({ name: 'equipment_list', value: newequipment_list })
		} else {

		}
	}
	const handleSaveChooseEquipment = () => {
		setShowEquipmentEdit(false);
	}
	const handleShowNewEquipment = () => {
		setCurEquipment(newequipment)
		setNewEquipmentEdit(true)
		setShowEquipmentEdit(true)
	}
	const handleShowEditEquipment = (i) => {
		setCurEquipment(i)
		setNewEquipmentEdit(false)
		setShowEquipmentEdit(true)
	}
	const handleShowChooseEquipment = () => {
		setShowEquipmentChoose(true)
	}
	const onChangeHandlerEquipment = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurEquipment({ ...curEquipment, [name]: value })
	}
	return (
		<>
			<Form.Group as={Row} controlId="formEquipmentEquipment" className="border-bottom">
				<Col>
					<Row>
						<Col><Button variant="outline-primary" size="sm" onClick={() => handleShowChooseEquipment()}><FontAwesomeIcon icon="plus-square" />{t('equipments.labels.equipment_add')}</Button></Col>
					</Row>
					{element.equipment_list && element.equipment_list.length > 0 ?
						<>
							<Row >
								<Col className="bg-secondary text-light ml-1">{t('equipments.labels.code')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('equipments.labels.name')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('equipments.labels.type')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('equipments.labels.quantity')} </Col>
								<Col className="bg-secondary text-light ml-1">&nbsp;</Col>
							</Row>
							{element.equipment_list.map((i, key) => (
								<Row>
									<Col>{i.code}</Col>
									<Col>{i.name}</Col>
									<Col>{i.type}</Col>
									<Col>{i.quantity}</Col>
									<Col>
										<Button size="sm" variant="info" onClick={() => handleShowEditEquipment(i)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
										<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
									</Col>
								</Row>))
							}
						</>
						: <> {t('equipments.messages.no_equipment')}</>
					}
				</Col>
			</Form.Group>

			<Modal show={showEquipmentChoose} className="border-primary bg-secondary" size="xl">
				<Modal.Header closeButton>
					<Modal.Title>{t('equipments.modal.titlechooseequipment')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							{t('global.labels.filters')}
						</Col>
						<Col>
							<ButtonGroup aria-label="filter group">

								<Button variant="outline-secondary">{t('rules.labels.equipments')} </Button>
								<Button variant="outline-secondary">{t('rules.labels.carts')} </Button>
								<Button variant="outline-secondary">{t('rules.labels.machines')} </Button>
								<Button variant="outline-secondary">{t('rules.labels.products')} </Button>
								<Button variant="outline-secondary"><FontAwesomeIcon icon="times" /></Button>
							</ButtonGroup>
						</Col>
					</Row>
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1">{t('rules.labels.code')}</Col>
						<Col className="bg-secondary ml-1">{t('rules.labels.model')}</Col>
						<Col className="bg-secondary ml-1">{t('rules.labels.brand')}</Col>
						<Col className="bg-secondary ml-1">{t('rules.labels.type')}</Col>
						<Col className="bg-secondary ml-1"></Col>
					</Row>
					<Row>
						<Col>{t('rules.labels.code')}</Col>
						<Col>{t('rules.labels.model')}</Col>
						<Col>{t('rules.labels.brand')}</Col>
						<Col>{t('rules.labels.type')}</Col>
						<Col><Form.Control type="number" min="0" name="quantity"></Form.Control></Col>
						<Col>
							<Button size="sm" variant="secondary" ><FontAwesomeIcon icon="info-circle" /> </Button>
							<Button size="sm" variant="outline-primary"><FontAwesomeIcon icon="check" /> </Button>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseChooseEquipment}> {t('modal.close')}</Button>
					<Button className="btn-myprimary" form="equipmentForm" onClick={handleSaveChooseEquipment}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

function ElementFormRules({ element, functions, t }) {
	const newrule = {
		rule_type: '',
		rule_limit: 0,
		rule_subject: -1,
		rule_subject_list: {},
		equipment: '',
	}
	const [showRuleEdit, setShowRuleEdit] = useState(false)
	const [newRuleEdit, setNewRuleEdit] = useState(true)
	const [curRule, setCurRule] = useState(newrule)
	const [isUpdating, setUpdating] = useState(false)
	const handleCloseRule = () => setShowRuleEdit(false);
	const handleCloseEditRule = () => setShowRuleEdit(false);
	const handleSaveRule = () => {
		setShowRuleEdit(false);
		if (newRuleEdit) {
			let newrule_list = element.rule_list
			newrule_list.push(curRule)
			console.log(newrule_list)
			functions.edit({ name: 'rule_list', value: newrule_list })
		} else {

		}
	}
	const handleSaveEditRule = () => {
		setShowRuleEdit(false);
	}
	const handleShowNewRule = () => {
		setCurRule(newrule)
		setNewRuleEdit(true)
		setShowRuleEdit(true)
	}
	const handleShowEditRule = (i) => {
		setCurRule(i)
		setNewRuleEdit(false)
		setShowRuleEdit(true)
	}

	const onChangeHandlerRule = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curRule[name]
			if (event.checked) {
				oldcheck[value] = true;
			} else {
				delete oldcheck[value];
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurRule({ ...curRule, [name]: value })
	}
	const selectAll = (subject) => {
		let check_list = {}
		if (subject === 'room') {
			for (let i = 0; i < room_type_list.length; i++) {
				check_list[room_type_list[i].id] = true
			}
		} else if (subject === 'furniture') {
			for (let i = 0; i < furniture_list.length; i++) {
				check_list[furniture_list[i].id] = true
			}

		}
		setCurRule({ ...curRule, 'rule_subject_list': check_list })
	}
	const unselectAll = (subject) => {
		setCurRule({ ...curRule, 'rule_subject_list': {} })
	}
	return (
		<>
					<Form.Group as={Row} controlId="formRuleRule" className="border-bottom">
						<Form.Label column sm="2">{t('rules.labels.rule_type')} </Form.Label>
						<Col>
							<Form.Control as="select" className="mb-2" defaultValue="1" name="rule_type" onChange={(event) => onChangeHandlerRule(event.target)} >
								<option value="-1" checked={(element.rule_type === "-1")}>{t('general.labels.select_option')} </option>
								<option value="use" checked={(element.rule_type === "use")}>{t('rules.options.rule_type_use')} </option>
								<option value="limit" checked={(element.rule_type === "limit")}>{t('rules.options.rule_type_limitation')} </option>
							</Form.Control>
						</Col>
						<Col>
							<Form.Control as="select" className="mb-2" defaultValue="1" name="equipment" onChange={(event) => onChangeHandlerRule(event.target)} >
								<option value="-1" checked={(element.equipment === "-1")}>{t('general.labels.select_option')} </option>
							</Form.Control>
							<Form.Text>
								select product/equipment
							</Form.Text>
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="eventLocation">
						<Form.Label column sm="2">
							{curRule.rule_type === 'use'
								? <>{t('rules.options.for')}</>
								: <>{t('rules.options.every')}</>
							}
						</Form.Label>
						{curRule.rule_type === 'limit'
							? <Col>
								<Form.Control type="number" defaultValue="0" name="rule_limit" min="0" />
								<Form.Text>{t('rules.labels.quantity')} </Form.Text>
							</Col>
							: <></>
						}
						<Col>
							<Form.Control as="select" className="mb-2" defaultValue="1" name="rule_subject" onChange={(event) => onChangeHandlerRule(event.target)} >
								<option value="-1" checked={(element.rule_subject === "-1")}>{t('general.labels.select_option')} </option>
								<option value="room" checked={(element.rule_subject === "room")}>{t('rules.options.rule_room')} </option>
								<option value="size" disabled={curRule.rule_type !== 'limit'} checked={(element.rule_subject === "size")}>{t('rules.options.rule_size')} </option>
								<option value="furniture" checked={(element.rule_subject === "furniture")}>{t('rules.options.rule_furniture')} </option>
							</Form.Control>
						</Col>

					</Form.Group>
					{curRule.rule_subject === 'room' ?
						<Form.Group as={Row} id="formRuleRoom" className="border-bottom">
							<Form.Label column sm="2">
								{t('rules.options.rule_room')}
							</Form.Label>
							<Col>
								<Row>
									<Col>
										<ButtonGroup classname="">
											<Button variant="outline-info" size="sm" onClick={() => selectAll('room')}><FontAwesomeIcon icon={['far', "check-square"]} /></Button>
											<Button variant="outline-info" size="sm" onClick={() => unselectAll('room')}><FontAwesomeIcon icon={['far', "square"]} /></Button>
										</ButtonGroup>
										{t('rules.labels.name')} </Col>
								</Row>
								<Row>

								{room_type_list.map((i, key) => (
									<Col sm="3">
										<Form.Check type='checkbox' checked={curRule.rule_subject_list[i.id]} name="rule_subject_list" value={i.id} id={`rule_room-${i.id}`} label={i.name} onChange={(event) => onChangeHandlerRule(event.target)} />
									</Col>
								))}
								</Row>
							</Col>
						</Form.Group>
						: curRule.rule_subject === 'furniture' ?
							<Form.Group as={Row} id="formRuleFurniture" className="border-bottom">
								<Form.Label column sm="2">
									{t('rules.options.rule_furniture')}
								</Form.Label>
								<Col>
									<Row>
										<Col>
											<ButtonGroup classname="">
												<Button variant="outline-info" size="sm" onClick={() => selectAll('furniture')}><FontAwesomeIcon icon={['far', "check-square"]} /></Button>
												<Button variant="outline-info" size="sm" onClick={() => unselectAll('furniture')}><FontAwesomeIcon icon={['far', "square"]} /></Button>
											</ButtonGroup>
											{t('rules.labels.name')} </Col>
									</Row>
									<Row>

										{furniture_list.map((i, key) => (
											<Col sm="3">
												<Form.Check type='checkbox' checked={curRule.rule_subject_list[i.id]} name="rule_subject_list" value={i.id} id={`rule_furniture-${i.id}`} label={i.name} onChange={(event) => onChangeHandlerRule(event.target)} />
											</Col>
										))}
									</Row>
								</Col>
							</Form.Group>
							:
							<></>
					}
					{curRule.rule_subject === 'room' || curRule.rule_subject === 'furniture'
						? <>
						</>
						: <></>
					}


		</>
	)

}