import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Badge, Container, ButtonGroup, Row, Col, Form, Button, Tab, Nav, OverlayTrigger, Spinner, Tooltip, Card } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import 'moment/locale/it';
import 'moment/locale/de';

import { processCheckSection_dict, materialIntendedUse_dict, timingMode_dict, processcontroltiming_dict } from "../components/constants/global_definitions"
import styles from '../components/constants/styles.js'


import LocaleContext from "../components/contexts/locale.js"
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';
//import EditContext, { EditProvider } from "../components/contexts/edit.js"


import { ItemProgress, ServiceBadge } from '../components/StructureGraphicElements.js'
import { checkComponentPresence, rgba2hex, getTimeValue, compareName, getValue, getSelectedElement, compareOrder, makeid, getElementPreview, getFixedNumber , getBaseObjectValue} from "../util/ui_utils.js"


import AlertSection from "../components/AlertSection.js"
import ModalCheckQuestion from '../components/ModalCheckQuestion.js';
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import SearchSection from "../components/SearchSection"
import Footer from "../components/Footer"
import { ElementFormNoncomplianceAction } from "../components/ElementFormNoncomplianceAction"



import { editIntervention as editIntervention2, getInterventionById as getInterventionById2, deleteIntervention as deleteIntervention2 } from '../Firebase2/Storage2/dbIntervention'
import { editWorkarea as editWorkarea2, getWorkareaList as getWorkareaList2 } from '../Firebase2/Storage2/dbWorkarea';

import { getProductList as getProductList2 } from '../Firebase2/Storage2/dbWarehouse'
import { getDpiList as getDpiList2 } from '../Firebase2/Storage2/dbDpi'
import {
	getEconomicalMaterialList as getEconomicalMaterialList2
} from '../Firebase2/Storage2/dbRoomMaterial';
import { getProcessQuestionList as getProcessQuestionList2, deleteProcessQuestion as deleteProcessQuestion2 } from "../Firebase2/Storage2/dbChecks"
import { getResultQuestionList as getResultQuestionList2, deleteResultQuestion as deleteResultQuestion2 } from "../Firebase2/Storage2/dbChecks"



////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const GlobalQuestion = ({ t, uDatau, check_type, canWrite  }) => {
	let load_page = useRef(false)
	const { domain} = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const [mycheck_type, setCheckType] = useState(check_type)
	const { contract, structureElements, roomtype_dict, risk_area_list, risk_area_dict, service_dict, operation_dict } = useContext(GlobalDataContext)
	//	const [roomList, setRoomList] = useContext(RoomContext)

	let structure_list = structureElements.structure_list
	let { cid } = useParams();
	let contractId = cid


	const childRefAlert = useRef()
	const childRefDelete = useRef()


	//	const [structure_list, setStructureList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [workarea_list, setWorkareaList] = useState([])
	//	const [intervention_dict, setInterventionDict] = useState({})
	const [loading, setLoading] = useState(true)
	const [searchTerm, setSearchTerm] = useState('')
	const [element_list, setElementList] = useState([])


	const initValues = async () => {
		if (mycheck_type==='results'){
			const global_question_list =  await getResultQuestionList2(domain, contractId) 
			console.log(global_question_list, mycheck_type)
			setElementList(global_question_list)
		}else if (mycheck_type ==='process'){
			const global_question_list = await getProcessQuestionList2(domain, contractId)
			console.log(global_question_list, mycheck_type)
			setElementList(global_question_list)
		}else{
			setElementList([])

		}
		setLoading(false)
	}




	useEffect(() => {
		setCheckType(mycheck_type)
	},[])



	useEffect(() => {
		if (contract && !load_page.current) {
			initValues()
		}
	}, [mycheck_type])



	const handleDelete = (el) => {
		console.log("DELETE", check_type, el)
		const cb = async () => {
			let return_data = (check_type ==='results')? await deleteResultQuestion2(domain, contractId, el.id) : await deleteProcessQuestion2(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('checks.messages.delete_question_title'), t('checks.messages.delete_question', { question: el.name }), cb)
	}







	return (
		<>

			<AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
				<Row className="mt-1">
					<Col className="h5 text-primary">
						{t('checks.labels.global_questions')} 
					</Col>
					{canWrite ?
						<Col style={{ textAlign: 'right' }}>
							<ModalCheckQuestion is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} check_type={check_type} />
						</Col>
						: ''}
				</Row>
				{element_list && element_list.length > 0 ?
					<>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ml-1" sm="3">{t('global.labels.title')}</Col>
							<Col className="bg-secondary ml-1">{t('global.labels.text')}</Col>
							<Col className="bg-secondary ml-1" sm="2">{t('workarea.labels.weight_coefficient')}</Col>
							<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
							<Row key={key} className="element_row p-1 border-bottom " >
								<Col className="font-weight-bold" sm="3">{i.name}</Col>
								<Col>{i.text}
									{i.note ?
										<>
											<br />{i.note}
										</>
										: ''}
								</Col>
								<Col sm="2">{i.coefficient}</Col>
								<Col sm="1" className="text-center">
									<ButtonGroup>
										{canWrite ?
											<>
												<ModalCheckQuestion is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} check_type={check_type} />
												<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
											</>
											: ''}
									</ButtonGroup>
								</Col>
							</Row>
						))}
					</>
					: <>
						<Row>
							<Col className="font-italic">
								{t('checks.messages.no_question')}
							</Col>
						</Row>
					</>
				}

		</>
	);
}






export default translate()(GlobalQuestion)