import {firebase, firestore} from '../firebaseIndex'



///////////////////////////////////////////////////////////////////////////////
export const getStatusSnapshotRange = async (domainId, contractId, structureId, startDate, endDate) => {
    try {
       const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId).collection('baseCompany')
                .doc("BASE").collection('structures')
                .doc(structureId).collection('statusSnapshots')
                .where(firebase.firestore.FieldPath.documentId(), '>=', startDate)
                .where(firebase.firestore.FieldPath.documentId(), '<', endDate)
                .get();

            const statusSnapshotList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return statusSnapshotList;
        
    } catch (e) {
        console.log("getStatusSnapshotRange error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editStatusSnapshot = async (domainId, contractId, structureId, statusSnapshotId, data) => {
    try {
        const statusSnapshot =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('baseCompany')
            .doc("BASE").collection('structures')
            .doc(structureId).collection('statusSnapshots')
            .doc(statusSnapshotId);

        const res = await statusSnapshot.set(data)
            .then( () => {
                console.log("editStatusSnapshot - edit successfully")
                return data
            }).catch( error => {
                console.log("editStatusSnapshot - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editStatusSnapshot error: ", e)
        return { error: e }
    }
}



