import React, { useContext, useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Button, ButtonGroup, Badge, } from 'react-bootstrap';
import Chart from "react-apexcharts";
import { format } from 'date-fns'
import { useParams } from "react-router-dom"
import { it } from 'date-fns/locale'

import LocaleContext from "../contexts/locale.js"
import DomainContext from '../contexts/domainContext'
import DashboardContext from '../contexts/dashboardContext.js';
import GlobalDataContext from '../contexts/globaldataContext.js';

import { myTimestampToDate } from "../../util/ui_utils.js"

import { firebase } from '../../Firebase2/firebaseIndex'

import { getControlGroupList } from '../../Firebase2/Storage2/dbControlGroup.js';
import { getSamplingPlanList } from "../../Firebase2/Storage2/dbSamplingPlan"


//////////////////////////////////////////////////////////////////
export const PlanReportSection = ({ t }) => {


    const { domain } = useContext(DomainContext)
    const { contract, now } = useContext(GlobalDataContext)
    const {
        filterAppliedStructure,
        permission_data,
    } = useContext(DashboardContext)

    let { cid } = useParams();
    let contractId = cid
    const [controlgroup_list, setControlgroupList] = useState([])
    const [all_controlgroup_list, setAllControlgroupList] = useState([])
    const [currentControlMode, setCurrentControlMode] = useState()
    const [element_list, setElementList] = useState([])

    useEffect(() => {
        if (filterAppliedStructure) {
            setControlgroupList(all_controlgroup_list.filter(g => g.structure_list.indexOf(filterAppliedStructure) >= 0))
        } else {
            setControlgroupList(all_controlgroup_list)
        }
    }, [filterAppliedStructure])


    useEffect(() => {
        console.log("permission_data check", permission_data)

        const initValues = async () => {
            let ret_data = await getControlGroupList(domain, contractId)
            const permission_structure_list = permission_data.structure_list
            if (permission_structure_list.length > 0) {
                ret_data = ret_data.filter(g => (g.structure_list && g.structure_list.some(d => (permission_structure_list.indexOf(d)) >= 0)))
            } else {
                ret_data = ret_data.filter(g => (g.structure_list && g.structure_list.length > 0))
            }
            setControlgroupList(ret_data)
            setAllControlgroupList(ret_data)
            let cur_check = ''
            if (contract.autocheck && contract.autocheck.length > 0) {
                cur_check = 'autocheck'
            } else if (contract.contradictory && contract.contradictory.length > 0) {
                cur_check = 'contradictory'
            } else if (contract.client_checks && contract.client_checks.length > 0) {
                cur_check = 'client_checks'
            }
            setCurrentControlMode(cur_check)
            let return_data = await getSamplingPlanList(domain, contractId)
            console.log("====...sampling plan list", return_data)
            const _now = firebase.firestore.Timestamp.fromDate(now)
            return_data = return_data.filter(p => p.startDate <= _now && p.endDate >= _now)
            setElementList(return_data)

        }
        if (permission_data) {
            initValues()
        }

    }, [permission_data])




    return (

        <>
            <Row className="mb-1">
                <Col className="text-center">
                    <ButtonGroup>
                        {contract && contract.autocheck && contract.autocheck.length > 0 ?
                            <Button size="sm" variant={currentControlMode === 'autocheck' ? 'secondary' : 'outline-secondary'} onClick={() => setCurrentControlMode('autocheck')}>{t('contract.labels.autocheck')}</Button>
                            : ''}
                        {contract && contract.contradictory && contract.contradictory.length > 0 ?
                            <Button size="sm" variant={currentControlMode === 'contradictory' ? 'secondary' : 'outline-secondary'} onClick={() => setCurrentControlMode('contradictory')}>{t('contract.labels.contradictory')}</Button>
                            : ''}
                        {contract && contract.client_checks && contract.client_checks.length > 0 ?
                            <Button size="sm" variant={currentControlMode === 'client_checks' ? 'secondary' : 'outline-secondary'} onClick={() => setCurrentControlMode('client_checks')}>{t('contract.labels.client_checks')}</Button>
                            : ''}
                    </ButtonGroup>

                </Col>
            </Row>
            {controlgroup_list.map((g, key) => (
                <Row className="mb-1 pb-1 border-bottom" key={key}>
                    <Col className="font-weight-bold border-right" sm="2">
                        {g.name}<br />
                        {getIpg(g.id, element_list, contract.check_weight, currentControlMode)}
                    </Col>
                    {element_list.filter(plan => g.id === plan.controlgroup_id && plan.control_mode === currentControlMode).length > 0 ?
                        <>
                            {element_list.filter(plan => g.id === plan.controlgroup_id && plan.control_mode === currentControlMode && plan.check_type === 'results').map((plan, kp) => (
                                <Col key={'r' + kp} className="border-right">
                                    <Row className="border-bottom">
                                        <Col className="text-center font-weight-bold">
                                            {plan.check_type === 'results' ?
                                                'risultato'
                                                : 'processo'}
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col className="text-center">
                                            {format(myTimestampToDate(plan.startDate), 'MMM', { locale: it })}
                                            &nbsp;-&nbsp;
                                            {format(myTimestampToDate(plan.endDate), 'MMM', { locale: it })}
                                        </Col>
                                    </Row>
                                    <Row>

                                        {plan.total_data && plan.total_data.check_done > 0 ?
                                            <>
                                                {/* 													<ProgressBar>
														<ProgressBar now={(plan.total_data.check_done - plan.total_data.failed_check) * 100 / plan.total_data.ac_check_count} style={{ backgroundColor: 'green' }} />
														<ProgressBar now={plan.total_data.failed_check * 100 / plan.total_data.ac_check_count} style={{ backgroundColor: 'red' }} />
													</ProgressBar> */}
                                                <PlanChart
                                                    data={{
                                                        ...plan.total_data,
                                                        sampling_count: contract.control_validation_mode === 'global' ? plan.total_data.sampling_count : plan.total_data.sum_sampling_count
                                                    }}
                                                    key={plan.control_mode}
                                                />
                                            </>
                                            : ''}

                                    </Row>
                                    <Row>
                                        {plan.total_data.failed_check > plan.total_data.sum_ac_check_count ?
                                            <Col className="text-center">
                                                IR:
                                            </Col>
                                            :
                                            <Col className="text-center">
                                                IPc: {plan.total_data && plan.total_data.clean_index && plan.total_data.clean_index.toFixed(2)}
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            ))}
                            {element_list.filter(plan => g.id === plan.controlgroup_id && plan.control_mode === currentControlMode && plan.check_type === 'process').map((plan, kp) => (
                                <Col key={"p" + kp}>
                                    <Row className="border-bottom">
                                        <Col className="text-center font-weight-bold">
                                            processo
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col className="text-center">
                                            {format(myTimestampToDate(plan.startDate), 'MMM', { locale: it })}
                                            &nbsp;-&nbsp;
                                            {format(myTimestampToDate(plan.endDate), 'MMM', { locale: it })}
                                        </Col>
                                    </Row>
                                    <Row>
                                        {plan.total_data  &&
                                            <>
                                            {console.log({ ...plan.total_data, sampling_count: plan.total_data.data.sampling_count },plan.control_mode )}
                                                {/* 													<ProgressBar>
														<ProgressBar now={(plan.total_data.check_done - plan.total_data.failed_check) * 100 / plan.total_data.ac_check_count} style={{ backgroundColor: 'green' }} />
														<ProgressBar now={plan.total_data.failed_check * 100 / plan.total_data.ac_check_count} style={{ backgroundColor: 'red' }} />
													</ProgressBar> */}
                                                 <PlanChart
                                                    data={{ ...plan.total_data, sampling_count: plan.total_data.data.sampling_count }}
                                                    key={plan.control_mode}
                                                /> 
                                            </>
                                            }

                                    </Row>
                                    <Row>
                                        {plan.total_data.failed_check > plan.total_data.sum_ac_check_count ?
                                            <Col className="text-center">
                                                IR:
                                            </Col>
                                            :

                                            <Col className="text-center">
                                                IPp: {plan.total_data && plan.total_data.clean_index && plan.total_data.clean_index.toFixed(2)}
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            ))}
                        </>
                        :
                        <Col className="text-secondary font-italic">
                            nessun piano di campionamento definito per questa tipologia di controllo
                        </Col>
                    }
                </Row>
            ))}
        </>
    )
}


const getIpg = (g_id, element_list, check_weight, currentControlMode) => {
    try {
        if (element_list.filter(plan => g_id === plan.controlgroup_id && plan.control_mode === currentControlMode).length > 0) {
            let ipc = 1
            let ipp = 1
            ipc = element_list.filter(plan => g_id === plan.controlgroup_id && plan.control_mode === currentControlMode && plan.check_type === 'results')[0].total_data.clean_index
            ipp = element_list.filter(plan => g_id === plan.controlgroup_id && plan.control_mode === currentControlMode && plan.check_type === 'process')[0].total_data.clean_index


            let ipg = 1
            if (check_weight[currentControlMode]) {
                ipg = (ipc * parseInt(check_weight[currentControlMode].results) / 100 + ipp * parseInt(check_weight[currentControlMode].process) / 100).toFixed(2)
            } else {
                ipg = (ipc * 0.5 + ipp * 0.5).toFixed(2)
            }
            if (isNaN(ipg)) {
                return <Badge variant="info">IPG: </Badge>

            } else {

                return <Badge>IPG: {ipg}</Badge>
            }
        } else {
            return <></>
        }
    } catch (err) {
        console.log(err)

    }
    return <></>

}


////////////////////////////////////////////////////////////////////////////////////////////
export const PlanChart = ({ data }) => {
    const { locale } = useContext(LocaleContext)
    const { contract } = useContext(GlobalDataContext)
    const [mydata, setData] = useState([1, 0, 2])
    const [options, setOptions] = useState({})

    useLayoutEffect(() => {
        const d_total = contract.control_validation_mode === 'global' ? data.sampling_count : data.sum_sampling_count
        console.log(data.check_done, data.failed_check)
        //console.log(data.sampling_count, data.check_done)
        const done = data.check_done || 0
        const failed = data.failed_check || 0
        const series = [
            done - failed,
            failed,
            d_total-done-failed
        ]
        const myoption = {
            labels: ['positivi', 'negativi', 'da eseguire'],
            chart: {
                type: 'donut',
            },
            colors: ['#00ff00', '#ff0000', '#848484'],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: false,
                                fontSize: '8px'
                            },
                            value: {
                                fontSize: '14px',
                            },
                            total: {
                                show: true,
                                color: '#000',
                                label: 'controlli',
                            },
                        }
                    }
                }
            },
            legend: {
                formatter: function (myvalue, opts) {
                    console.log(myvalue, opts)
                    if (series[opts.seriesIndex] >= 0) {
                        return myvalue + ": " + series[opts.seriesIndex]
                    } else {
                        return myvalue + ": 0"

                    }
                },
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: undefined,
                textAnchor: 'middle',
                distributed: true,
                formatter: function (val, opts){
                    console.log("-------------------------------VAL CHART", val)
                    if (val >100){
                        return '~%'
                    }else{
                        return `${val.toFixed(1)}%`
                    }
                },
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors: undefined
                },
                background: {
                    enabled: true,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: '#fff',
                    opacity: 1,
                    dropShadow: {
                        enabled: false,
                    }
                },
                dropShadow: {
                    enabled: false,
                }
            }
        }
        setData(series)
        setOptions(myoption)
    }, [data])



    return (

        <Chart
            options={options}
            series={mydata}
            labels={['positivi', 'negativi', 'da eseguire']}
            type="donut"
            height="180"
        />
    )

}

