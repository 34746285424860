import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage"
import "firebase/functions"

const firebaseConfig = {
    apiKey: "AIzaSyCNDD1IjpBvk-NzusbF_l7bb75EZJqYBS0",
    authDomain: "clouditbase.firebaseapp.com",
    databaseURL: "https://clouditbase.firebaseio.com",
    projectId: "clouditbase",
    storageBucket: "clouditbase.appspot.com",
    messagingSenderId: "832151037060",
    appId: "1:832151037060:web:0aad36a16dddfad8b44ed1",
    measurementId: "G-VSR8SYE9HE"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

export {firebase}
export const auth = firebase.auth();
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier
export const PhoneAuthProvider = firebase.auth.PhoneAuthProvider
export const getAuth = firebase.auth.getAuth

export const firestore = firebase.firestore();
export const storageRef = firebase.storage().ref();
export const functions = firebase.app().functions('europe-west1');
//DEBUG on LOCAL EMULATOR: functions.useFunctionsEmulator('http://localhost:5000')
export default {firebaseConfig,}