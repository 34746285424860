import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  Container,  Row, Col, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import { serviceType_list } from "../components/constants/global_definitions"
import { checkComponentPresence, compareCode, getValue, myTimestampToDate, getSelectedElement } from "../util/ui_utils.js"


import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import ModalService from '../components/ModalService'
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import SearchSection from "../components/SearchSection"
import { ServiceBadge } from '../components/StructureGraphicElements.js'


import { getServiceList as getServiceList2, deleteService as deleteService2 } from '../Firebase2/Storage2/dbService';





//const contractId = sessionStorage.getItem('contractId')

const newservice = {
	name: '',
	code: '',
	element_list: [],
	type_list: [],
	type: 'ordinary'
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function getElementCount(elem_list, filterList, searchTerm) {
	if (elem_list) {
		const filt = elem_list.filter(i => (filterList.length === 0 || filterList.indexOf(i.type) >= 0))
			.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
		if ((filterList.length === 0 && searchTerm.length < 2) || filt.length === elem_list.length) {
			return elem_list.length
		} else {
			return filt.length + "/" + elem_list.length
		}
	} else {
		return undefined

	}
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ServicesView = ({ t, uData }) => {
let load_page = false
	const { domain, domain_data } = useContext(DomainContext)

	const service_type = serviceType_list({ t })
	const { contract} = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()
	let { cid } = useParams();

	let contractId = cid
	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [curElement, setCurrentElement] = useState(newservice)
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')

		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data =  await deleteService2(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('service.messages.delete_service_title'), t('services.messages.delete_service', {name:el.name}), cb)
	}




	const [filterAppliedList, setFilterAppliedList] = useState({ type: [] })

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		console.log('index', c)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	const initValues = async () => {
		if (myTimestampToDate(getValue(domain_data, 'endDate')) > new Date() && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('services') !== -1)) {
			setWritePage(true)
		}

		setLoading(true)
		if (contract && domain.length > 0) {
			load_page=true
			let newlist = await getServiceList2(domain, contractId)
			newlist.sort(compareCode)
			console.log("source service_list", newlist)
			setElementList(newlist)
		}else{
			console.log("init contract return")
		}
		setLoading(false)
	}





	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("SERVICE reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("SERVIC init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useEffect(() => {
		console.log('SERVICE loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])


	return (
		<div>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('global.pages.services')} />
					</Col>
					<Col className="text-center">
						<ButtonGroup>
							<Button disabled variant="secondary" size="sm" ><FontAwesomeIcon icon="filter" /></Button>
							{service_type.map((g, k) => (
								<Button key={k} size="sm" disabled={element_list && element_list.filter(f => f.type === g.id).length === 0} variant={filterAppliedList['type'].indexOf(g.id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('type', g.id)}>
									<FontAwesomeIcon icon={g.icon} /> {g.label}
								</Button>
							))}
							{filterAppliedList.type.length > 0 ?
								<Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'type': [] })}><FontAwesomeIcon icon="times" /></Button>
								: ''}
						</ButtonGroup>
					</Col>
					<Col sm={2}>
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }} sm="2">
						{canWrite ?
							<ModalService is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} setParentList={setElementList} canWrite={canWrite} />
							: ''}
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="1" md="1">{t('global.labels.code')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.name')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.type')}</Col>
						<Col className="bg-secondary ml-1 text-center" sm="2" md="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (filterAppliedList.type.length === 0 || filterAppliedList.type.indexOf(i.type) !== -1)).filter(i => (
									searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
								)).map((i, key) => (
									<Row key={key} className="element_row p-1 border-bottom">
										<Col sm="1" md="1">{i.code}</Col>
										<Col>
											<ServiceBadge service={i} />
										</Col>
										<Col>
											<FontAwesomeIcon icon={getValue(getSelectedElement(i.type, service_type, 'id'), 'icon')} />&nbsp;
									{getValue(getSelectedElement(i.type, service_type, 'id'), 'label')}
										</Col>
										<Col className="text-center" sm="2" md="1">
											<ButtonGroup>
												<ModalService t={t} item={i} setItem={onChangeHandler} item_list={element_list} setParentList={setElementList} index={key} canWrite={canWrite} />
												{canWrite ?
													<Button variant="danger" size="sm"><FontAwesomeIcon icon='trash' onClick={() => handleDelete(i)} /></Button>
													: ''}
											</ButtonGroup>
										</Col>


									</Row>
								))}
							</>
							:
							<i>
								{t('services.messages.no_service')}
							</i>
						}
					</>
				}
			</Container>
            <Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount(element_list, filterAppliedList.type, searchTerm) + " " + t('global.labels.services')} />
		</div>
	);
}

export default translate()(ServicesView)


