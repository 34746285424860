import React, { useContext, useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Popover, Image, Button, ListGroup, OverlayTrigger, ProgressBar, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, setDay } from 'date-fns'
import { it } from 'date-fns/locale'
import { useTranslate } from 'react-polyglot'

import LocaleContext from "../components/contexts/locale.js"
import EditContext from "../components/contexts/edit.js"
import GlobalDataContext from './contexts/globaldataContext.js';

import { serviceType_dict, mediaType_dict } from "../components/constants/global_definitions"
import { filterRoom } from '../components/RoomSelector'

import { compareOrder, rgba2hex, myTimestampToDate, getValue, checkComponentPresence, getObjectValue, checkIfImageExists } from '../util/ui_utils'
import FrequencySelector from '../components/FrequencySelector'
import { ModalDocument } from './modal/ModalDocumentPreview.js';




const now = new Date()

export function ItemProgress({ risk_list, item, mode, use_room_count }) {
    const t = useTranslate();
    const { locale } = useContext(LocaleContext)
    const { contract, risk_area_list, risk_area_dict } = useContext(GlobalDataContext)
    //console.log("risk_area list", ui_list.risk_area_list)
    //    risk_list = risk_list.sort(compareOrder)

    function getRoomCount(ri) {
        //console.log(use_room_count, ri)
        if (use_room_count) {
            return ri.room_count
        } else {
            return ri.count
        }
    }
    const renderPopover = (risk_list, item) => {
        //console.log(risk_list)
        const getRAName = (i) => {
            try {
                return risk_area_dict[i].name[locale]
            } catch (err) {
                return ""
            }
        }
        return (
            <Popover id="popover-basic" className="bg-info border border-info">
                <Popover.Title as="h3">
                    {contract.type === 'civil' ?
                        t('global.labels.standard_cleaning_summary')
                        :
                        t('global.labels.risk_area_summary')
                    } <i>(m<sup>2</sup>)</i>
                </Popover.Title>
                <Popover.Content>
                    {risk_area_list ?
                        <ListGroup variant="flux">
                            {risk_area_list.map((ri, rkey) => (
                                (risk_list[ri.Id] ?
                                    <ListGroup.Item key={rkey}>
                                        <Row>
                                            <Col>
                                                <Badge style={{ background: rgba2hex(ri.color) }} className="mr-1">&nbsp;&nbsp;</Badge>
                                                {getRAName(ri.Id)}: {risk_list[ri.Id].size.toFixed(2)} m<sup>2</sup>  | {getRoomCount(risk_list[ri.Id])} locali
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    : '')
                            ))}
                        </ListGroup>
                        :
                        <>
                            {risk_list ?
                                <ListGroup variant="flush">
                                    {Object.keys(risk_list).sort(compareOrder).map((i, k) => (

                                        <ListGroup.Item key={k}>
                                            {risk_list[i].order}
                                            <Badge className="mr-1" style={{
                                                background: rgba2hex(risk_list[i].color)
                                            }}>&nbsp;&nbsp;</Badge>{getRAName(i)}: {risk_list[i].size.toFixed(2)} m<sup>2</sup>  | {getRoomCount(risk_list[i])} locali
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                                : ''
                            }
                        </>
                    }
                </Popover.Content>
            </Popover>

        )
    }

    return (
        <>
            <Row>
                <Col className="p-1">
                    <OverlayTrigger trigger={['hover', 'focus']} placement="auto-start" overlay={renderPopover(risk_list, item)}>
                        <ProgressBar>
                            {risk_area_list ?
                                (risk_area_list.map((ri, rkey) => (
                                    (risk_list[ri.Id] ?
                                        <ProgressBar key={rkey} now={risk_list[ri.Id].size * 100 / item.size} style={{ backgroundColor: rgba2hex(ri.color) }} />

                                        : '')
                                )))
                                :
                                (Object.keys(risk_list).sort(compareOrder).map((i, k) => (
                                    <ProgressBar key={k} now={risk_list[i].size * 100 / item.size} style={{ backgroundColor: rgba2hex(risk_list[i].color) }} />
                                )))
                            }
                        </ProgressBar>
                    </OverlayTrigger>

                </Col>
            </Row>
        </>
    )


}

//////////////////////////////////////////////////////////////////////////////////////////////////
export const EmployerIcon = ({ operating_units, employer_list }) => {
    const op_unit = parseInt(operating_units)
    const emp_count = employer_list && employer_list.length > 0 ? employer_list.length : 0
    let color = 'warning'
    if (emp_count === 0 || emp_count < op_unit) {
        color = 'danger'
    } else if (emp_count === op_unit) {
        color = 'success'
    }
    return (
        <Badge variant={color}>
            <FontAwesomeIcon icon="user" />&nbsp;
            {emp_count} / {op_unit}
        </Badge>
    )


}



////////////////////////////////////////////////////////////////////////////////////////////////////////
export const PageButton = ({ page_name, page, curPage, setCurPage }) => {
    return (
        <Button variant={curPage === page ? 'primary' : 'outline-primary'} block onClick={() => setCurPage(page)} >{page_name}</Button>
    )
}

//////////////////////////////////////////////////////////////////////////////////////////////////
export function ContainerInfoPopover({ w, t }) {
    const { structureElements } = useContext(GlobalDataContext)
    const renderContainerInfoPopover = () => {

        return (
            <Popover id="popover-container" className="bg-info border border-info">
                <Popover.Content as={Container}>
                    <Col>
                        <Row className="border-bottom border-light">

                            {w.structure_list && w.structure_list.length > 0 ?
                                <>
                                    <Col sm="3">
                                        <StructureGroupIcon level={0} key={'level0'} />
                                    </Col>
                                    <Col>
                                        {w.structure_list.map((d, dk) => (
                                            <span key={dk}>{getValue(structureElements.all_structure_dict[d], 'name')} </span>
                                        )).reduce((prev, curr) => [prev, ', ', curr])}
                                    </Col>
                                </>
                                : ''}
                        </Row>
                        <Row className="border-bottom border-light">
                            {w.department_list && w.department_list.length > 0 ?
                                <>
                                    <Col sm="3">
                                        <StructureGroupIcon level={3} key={'level3'} />
                                    </Col>
                                    <Col>
                                        {w.department_list.map((d, dk) => (
                                            <span key={dk}>{getValue(structureElements.all_department_dict[d], 'name')} </span>
                                        )).reduce((prev, curr) => [prev, ', ', curr])}
                                    </Col>
                                </>
                                : ''}
                        </Row>
                    </Col>
                </Popover.Content>
            </Popover>
        )
    }
    return (
        <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={renderContainerInfoPopover()}>
            <Badge variant="secondary" className="ml-3 mr-3"><FontAwesomeIcon icon="info-circle" className="mr-2" />{w.room_list.length}  {t('global.labels.rooms')}</Badge>
        </OverlayTrigger>

    )

}



export function MessageIcon({ type }) {

    if (type === 'communication') {
        return <FontAwesomeIcon icon="comment" className="text-info" />
    } else if (type === 'noncompliance') {
        return <FontAwesomeIcon icon="bell" className="text-warning" />
    } else if (type === 'request') {
        return <FontAwesomeIcon icon="exclamation-triangle" className="text-danger" />
    } else if (type === 'failure') {
        return <FontAwesomeIcon icon="wrench" className="text-info" />
    } else {
        return <></>

    }

}

export const MultipleToggleEdit = ({ param }) => {
    const edit = useContext(EditContext)
    return (
        <Col sm="1" className={edit.editMultiple && edit.editParamList.indexOf(param) !== -1 ? 'bg-warning text-right' : 'text-right'}>
            {edit.editParamList.indexOf(param) !== -1 ?
                <Button onClick={() => edit.toggleEditParamList(param)} size="sm" variant="info">
                    <FontAwesomeIcon icon="times" />
                </Button>
                :
                <Button onClick={() => edit.toggleEditParamList(param)} size="sm" variant="outline-warning">
                    <FontAwesomeIcon icon="pencil-alt" />
                </Button>
            }
        </Col>

    )

}

export function StructureGroupIcon({ level, item_name, show_label }) {
    const t = useTranslate()
    if (level === 0) {
        return (<Badge style={{ backgroundColor: '#111' }} className="text-light mr-1"  >
            <FontAwesomeIcon icon="hospital-alt" />
            {item_name ?
                <>
                    {show_label && <>{t('global.labels.structure')}&nbsp;</>}{item_name}
                </>
                :
                <>
                    {t('structure.labels.structure')}:&nbsp;
                </>
            }
        </Badge>
        )
    } else if (level === 1) {
        return (
            <Badge style={{ backgroundColor: '#003366' }} className="text-light mr-1" >
                {/*         <FontAwesomeIcon style={{color: '#003366'}} icon="clinic-medical" /> */}
                <FontAwesomeIcon icon="clinic-medical" />
                {item_name ?
                    <>
                        {show_label && <>{t('global.labels.pavillon')}&nbsp;</>}{item_name}
                    </>
                    :
                    <>
                        {t('global.labels.pavillon')}:&nbsp;
                    </>
                }
            </Badge>
        )

    } else if (level === 2) {
        return (
            <Badge style={{ backgroundColor: '#0066cc' }} className="text-light mr-1" >
                <FontAwesomeIcon className="text-light" icon="list-ol" />
                {item_name ?
                    <>
                        {show_label && <>{t('global.labels.floor')}&nbsp;</>}{item_name}
                    </>
                    :
                    <>
                        {t('structure.labels.floor')}:&nbsp;
                    </>
                }
            </Badge>
        )
    } else if (level === 3) {
        return (
            <Badge style={{ backgroundColor: '#99ccff' }} className="mr-1">
                <FontAwesomeIcon style={{ backgroundColor: '#99ccff' }} icon="stethoscope" />
                {item_name ?
                    <>
                        {show_label && <>{t('global.labels.department')}&nbsp;</>}{item_name}
                    </>
                    :
                    <>
                        {t('global.labels.department')}:&nbsp;
                    </>
                }
            </Badge>
        )
    } else if (level === 4) {
        return (
            <Badge style={{ backgroundColor: '#99ccff' }} className="text-light mr-1 ">
                <FontAwesomeIcon style={{ backgrounColor: '#99ccff' }} icon="bed" />
                {item_name ?
                    <>
                        {show_label && <>{t('global.labels.room')}&nbsp;</>}{item_name}
                    </>
                    :
                    <>
                        {t('global.labels.room')}:&nbsp;
                    </>
                }
            </Badge>
        )
    } else {
        return <></>
    }

}

/////////////////////////////////////////////////////////////////////////////////////////////////////
export function WeekDays({ weekdays, curDay, customFrequency }) {
    const wd = ["1", "2", "3", "4", "5", "6", "0"]
    //console.log("WEEKDAYS", weekdays, customFrequency)
    const items = []

    for (let d = 0; d < 7; d++) {
        if (weekdays.indexOf(wd[d]) >= 0) {
            if (wd[d] === curDay) {
                items.push(<Badge key={d} size="md" variant="primary">{format(setDay(new Date(), wd[d]), 'ccccc', { locale: it })}</Badge>)
            } else {
                items.push(<Badge key={d} size="md" variant={customFrequency ? "info" : "success"}>{format(setDay(new Date(), wd[d]), 'ccccc', { locale: it })}</Badge>)
            }
        } else {
            items.push(<Badge key={d} size="md" variant="light">{format(setDay(new Date(), wd[d]), 'ccccc', { locale: it })}</Badge>)
        }
    }
    return (
        <span className="h5">
            {items}
        </span>

    )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const FrequencyRender = ({ element }) => {
    if (element.service.type === 'periodic') {
        return <FrequencySelector element={element} mode='read_graphical' />
    } else if (element.service.type === 'ordinary') {
        return <WeekDays weekdays={element.weekdays} />
    } else {
        return ""
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////
export const renderPhoneList = (phone_list, col) => {
    try {

        if (col) {
            return (
                <Row>

                    {phone_list.map((p, k) => (
                        <Col key={k}>
                            <i>{p.description ? <>{p.description}:&nbsp;</> : ''}</i>{p.number}
                        </Col>
                    ))}
                </Row>
            )

        } else {
            return (
                <>{phone_list.map((p, k) => (
                    <Row key={k}>
                        <Col >
                            <i>{p.description ? <>{p.description}:&nbsp;</> : ''}</i>{p.number}
                        </Col>
                    </Row>
                ))}
                </>
            )

        }
    } catch (err) {
        console.log("phoneError", phone_list, err)
        return (
            <>/</>
        )

    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////

export const getRoomStatus = (room, t, status_list, response) => {
    if (room.status_planning && room.status_planning.length > 0) {
        const actual_status = room.status_planning.filter(s => (
            myTimestampToDate(s.startDate) <= now && (!s.with_end || (s.with_end && myTimestampToDate(s.endDate) >= now))
        ))
        if (actual_status.length !== 0) {
            if (!response) {
                return (
                    <>
                        {status_list[actual_status[0].type].label}
                    </>
                )
            } else {
                console.log(room.id, actual_status[0].type)
                return [actual_status[0].type, actual_status[0].type]
            }

        } else {
            if (!response) {
                return (
                    <>{t('structure.labels.active')}</>
                )
            } else {
                console.log(room.id)
                return ['active', {}]
            }
        }
    } else {
        if (!response) {
            return (
                <>{t('structure.labels.active')}</>
            )
        } else {
            return ['active', {}]
        }
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////
export const ServiceBadge = ({ service, freq }) => {
    const t = useTranslate()
    try {
        //			const service = ui_list.service_dict[s.id]

        const service_type = serviceType_dict({ t })
        return (
            <>
                <Badge style={{
                    background: rgba2hex(service.color),
                    border: '1px solid #fff'
                }}>
                    {freq ?
                        <FontAwesomeIcon icon={getValue(service_type[service.type], 'icon')} />
                        :
                        <>&nbsp;</>
                    }
                </Badge>&nbsp;
                {service.name}

            </>
        )
    } catch (err) {
        return (<Badge><FontAwesomeIcon icon="exclamation-triangle" /></Badge>)
    }

}


/////////////////////////////////////////////////////////////////////////////////////////////////////
export const RiskareaBadge = ({ riskarea }) => {
    const { locale } = useContext(LocaleContext)
    try {
        //			const service = ui_list.service_dict[s.id]
        return (
            <>

                <Badge key={riskarea.Id}>
                    <Badge style={{
                        background: rgba2hex(riskarea.color),
                        border: "1px solid #fff"
                    }}>&nbsp;&nbsp;</Badge>&nbsp;
                    {riskarea.name[locale]}
                </Badge>
            </>
        )
    } catch (err) {
        console.log(err)
        return (<Badge ><FontAwesomeIcon icon="exclamation-triangle" /></Badge>)
    }

}


/////////////////////////////////////////////////////////////////////////////////////////////////////
export const renderFrequency = (s, service_id, ui_list, curDay) => {
    try {
        const i = ui_list.roomtype_dict[s]
        if (i.service_list) {
            return (
                i.service_list.map((s, sk) => (
                    <>
                        {s.service && s.service.id === service_id ?
                            <>
                                {s.service.type === 'periodic' ?
                                    <FrequencySelector element={s} mode='read' />
                                    :
                                    [s.service.type === 'ordinary' ?
                                        <Row key={sk}>
                                            {/* <Col>{s.service.name}</Col> */}
                                            <Col>
                                                <WeekDays weekdays={s.weekdays} curDay={curDay} />
                                            </Col>
                                        </Row>
                                        :

                                        <Row key={sk}>
                                            <Col>{s.service.name}</Col>
                                        </Row>
                                    ]
                                }
                            </>
                            :
                            <Row key={sk}><Col>{s.name}</Col></Row>
                        }
                    </>
                ))
            )
        } else {
            return 'noserv'
        }
    } catch (err) {
        console.log(err)
        return ''
    }
}



/////////////////////////////////////////////////////////////////////////////////////////////////////
export const Frequency = ({ roomtype_id, service_id, curDay, customFrequency, roomtype_dict, rt }) => {
    try {
        //console.log("FREQ DICT", roomtype_dict)
        const _rt = rt || roomtype_dict[roomtype_id]
        if (_rt.service_list) {
            //            const s = _rt.service_list.filter(s => s.service && s.service.id === service_id)
            return (
                _rt.service_list.map((s, sk) => (
                    <span key={sk}>
                        {s.service && s.service.id === service_id ?
                            <>
                                {s.service.type === 'periodic' ?
                                    <FrequencySelector element={s} mode='read_graphical' />
                                    :
                                    [s.service.type === 'ordinary' ?
                                        <Row key={sk}>
                                            <Col>
                                                <WeekDays weekdays={customFrequency ? customFrequency : s.weekdays} customFrequency={customFrequency} curDay={curDay} />
                                            </Col>
                                        </Row>
                                        :

                                        <Row key={sk}>
                                            <Col>{s.service.name}</Col>
                                        </Row>
                                    ]
                                }
                            </>
                            :
                            <Row key={sk}><Col>{s.name}</Col></Row>
                        }
                    </span>
                ))
            )
        } else {
            return 'no serv'
        }
    } catch (err) {
        console.log("ERRORE", err)
        return 'ERROR'
    }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
export const getColor = (c) => {
    try {
        return { background: `rgba(${c.color.r}, ${c.color.g}, ${c.color.b},1)` }
    } catch (err) {
        return { background: '#aaa' }
    }
}



/////////////////////////////////////////////////////////////////////////////////////////////////////
export const MediaPreview = ({ i }) => {
    const [isImage, setIsImage] = useState(false)
    useEffect(() => {
        if (i && i.url) {
            checkIfImageExists(i.url, (is) => {
                setIsImage(is)
            })
        }
    }, [])
    console.log(i)

    try {
        if (isImage) {
            return (<Image src={i.url} fluid />)
        } else {
            return (
                <>
                    {i.source_name}
                    <Button size="sm" className="mr-1" variant="primary" href={i.url} target="_blank" download={"filename.test"}><FontAwesomeIcon icon="external-link-alt" />A-apri file</Button>
                </>
            )
        }


    } catch (err) {
        try {
            return (<Button size="sm" className="mr-1" variant="primary" href={i.url} target="_blank" download={"filename.test"}><FontAwesomeIcon icon="external-link-alt" />A-Apri file</Button>)
        } catch {
            return (<></>)
        }



    }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////
export const RenderItemFileBadge = ({ media_list, section, position }) => {
    const pos = position || "auto"
    const t = useTranslate()
    const type_dict = mediaType_dict({ t: t, section: section })


    const getFileLabel = (i_type) => {
        try {
            return type_dict[i_type].label
        } catch (err) {
            return i_type
        }
    }


    const renderItemFilePopover = (media_list) => {


        return (
            <Popover id="popover-basic" className="bg-success border border-success">
                <Popover.Content className="bg-success">
                    {media_list ?
                        <>
                            <ListGroup>
                                {media_list.map((i, k) => (
                                    <ListGroup.Item key={k} variant="light">
                                        <Row>
                                            <Col className="font-weight-bold text-dark"><FontAwesomeIcon icon={i.type} /> {getFileLabel(i.type)}</Col>
                                            <Col >{i.description}</Col>
                                            <Col sm="2" className="text-center p-1">
                                                <ModalDocument t={t} photo={i.url} name={i.tracker && i.tracker.source_name} />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </>
                        : ''
                    }
                </Popover.Content>
            </Popover>

        )
    }



    if (media_list && media_list.length > 0) {
        //FIXME getMediaIcon
        return (

            <OverlayTrigger trigger={['click']} rootClose placement={pos} overlay={renderItemFilePopover(media_list)}>

                <Button size="sm" variant="success"><FontAwesomeIcon icon="file" />&nbsp;<Badge variant="light">{media_list.length}</Badge></Button>
            </OverlayTrigger>
        )
    } else {
        return ('')
    }
}




/////////////////////////////////////////////////////////////////////////////////////////////////////
export const renderItemNote = (note) => {

    return (
        <Popover id="popover-basic" className="bg-info border border-info">
            <Popover.Content className="bg-info text-light">
                {note}
            </Popover.Content>
        </Popover>

    )
}


const RoomInfoPopover = (parents, all_structure_dict, all_pavillon_dict, all_floor_dict, all_department_dict, expanded) => {



    const PlaceText = ({ parents, level }) => {
        const [level_name, setLevelName] = useState('')
        /* 
                const getStructureName = async (dp_id) => {
                    try {
                        return (all_structure_dict[dp_id].name)
                    } catch (err) {
                        return ('')
        
                    }
                }
                const getPavillonName = async (dp_id) => {
                    try {
                        return (all_pavillon_dict[dp_id].name)
                    } catch (err) {
                        return ('')
        
                    }
                }
        
                const getFloorName = async (dp_id) => {
                    try {
                        return (all_floor_dict[dp_id].name)
        
                    } catch (err) {
                        return ('')
        
                    }
                }
        
                const getDepartmentName = async (dp_id) => {
                    try {
                        return (all_department_dict[dp_id].name)
                    } catch (err) {
                        return ('')
        
                    }
                } */

        const initValues = async () => {

            if (level === 0) {
                setLevelName(getObjectValue(all_structure_dict, parents[0], 'name'))
            } else if (level === 1) {
                setLevelName(getObjectValue(all_pavillon_dict, parents[1], 'name'))
            } else if (level === 2) {
                setLevelName(getObjectValue(all_floor_dict, parents[2], 'name'))
            } else if (level === 3) {
                setLevelName(getObjectValue(all_department_dict, parents[3], 'name'))

            }
        }


        useEffect(() => {
            initValues()
        }, [])
        try {
            return (
                <Col className="border-top border-light">
                    {level_name}
                </Col>
            )
        } catch (err) {
            return (
                <Col>
                </Col>
            )
        }
    }



    if (expanded) {

        return (
            <Row>
                <PlaceText parents={parents} level={0} key={'level0_1'} className="border-right" />
                <PlaceText parents={parents} level={1} key={'level1_1'} className="border-right" />
                <PlaceText parents={parents} level={2} key={'level2_1'} className="border-right" />
                <PlaceText parents={parents} level={3} key={'level3_1'} className="border-right" />
            </Row>
        )

    } else {

        return (
            <Popover id="popover-basic" className="bg-info border border-info">
                <Popover.Content>

                    <StructureGroupIcon level={0} key={'level0'} />
                    <PlaceText parents={parents} level={0} key={'level0_1'} />
                    <StructureGroupIcon level={1} key={'level1'} />
                    <PlaceText parents={parents} level={1} key={'level1_1'} />
                    <StructureGroupIcon level={2} key={'level2'} />
                    <PlaceText parents={parents} level={2} key={'level2_1'} />
                    <StructureGroupIcon level={3} key={'level3'} />
                    <PlaceText parents={parents} level={3} key={'level3_1'} />
                </Popover.Content>
            </Popover>

        )
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////
export const RoomInfoBadge = ({ parent_list, plain_string, expanded }) => {
    const { structureElements } = useContext(GlobalDataContext)

    const structure_dict = structureElements.all_structure_dict
    const pavillon_dict = structureElements.all_pavillon_dict
    const floor_dict = structureElements.all_floor_dict
    const department_dict = structureElements.all_department_dict

    if (expanded) {
        return (
            <>
                {RoomInfoPopover(parent_list, structure_dict, pavillon_dict, floor_dict, department_dict, true)}
            </>
        )
    } else {
        return (
            <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={RoomInfoPopover(parent_list, structure_dict, pavillon_dict, floor_dict, department_dict)}>
                <Badge size="sm" variant="info" className="mr-1"><FontAwesomeIcon icon="info-circle" /></Badge>
            </OverlayTrigger>
        )

    }

}



/////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalInitButton = ({ is_new, canWrite, small, title_new, action_new, title_edit, action_edit, action_info, t, is_secondary }) => {
    if (is_new) {
        if (small) {
            return (
                <Button onClick={() => action_new()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>

            )
        } else {
            if (is_secondary) {
                return (
                    <Button onClick={() => action_new()} size="sm" variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> {title_new}</Button>
                )

            } else {
                return (
                    <Button onClick={() => action_new()}><FontAwesomeIcon icon='plus-square' /> {title_new}</Button>
                )

            }
        }
    } else {
        if (canWrite) {
            return (
                <Button className="mr-1" variant="info" size="sm" onClick={() => action_edit()}><FontAwesomeIcon icon="pencil-alt" />{title_edit}</Button>
            )

        } else {
            return (
                <Button className="mr-1" variant="info" size="sm" onClick={() => action_info()}><FontAwesomeIcon icon="info-circle" /> </Button>
            )
        }
    }
}


////////////////////////////////////////////////////////////////////////////////////////////////////
export const AddListButton = ({ i, element_list, add_action, increment }) => {
    if (element_list && checkComponentPresence(i, element_list, 'id') !== -1) {
        return (
            <Button size="sm" variant="secondary" disabled={!increment}><FontAwesomeIcon icon="check" /><FontAwesomeIcon icon="plus-square" /> </Button>
        )
    } else {
        return (
            <Button size="sm" variant="light" onClick={() => add_action(i)}><FontAwesomeIcon className="text-warning" icon="arrow-left" /><FontAwesomeIcon icon="plus-square" /> </Button>
        )

    }
}






/////////////////////////////////////////////////////////////////////////////////////////////////////
export const ItemNote = (note) => {

    return (
        <Popover id="popover-basic" className="bg-info border border-info">
            <Popover.Content className="bg-info text-light">
                {note}
            </Popover.Content>
        </Popover>

    )
}


const badge_size = 20

//////////////////////////////////////////////////////////////////////////////////////////////////////
export const ResultBadge = ({ res, ratio }) => {
    let rt = 1
    try {
        rt = parseInt(ratio)
    } catch (err) {
        console.log(ratio, err)

    }

    if (res === 'true') {

        return (
            <Badge variant="success" style={{ width: badge_size * rt }}>&nbsp;</Badge>
        )
    } else if (res === 'unavailable') {
        return (
            <Badge variant="warning" style={{ width: badge_size * rt }}>&nbsp;</Badge>
        )
    } else {
        return (
            <Badge variant="danger" style={{ width: badge_size * rt }}>&nbsp;</Badge>
        )

    }
}



export const TicketPath = ({ t, ticket, full }) => {
    const { structureElements } = useContext(GlobalDataContext)

    const getDepartmentPath = (department_id) => {
        const dep = structureElements.all_department_dict[department_id]
        if (dep) {
            const pav = structureElements.all_pavillon_dict[dep.parent_list[1]]
            const floor = structureElements.all_floor_dict[dep.parent_list[2]]
            return (

                <>
                    {getValue(pav, 'name')}
                    &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                    {getValue(floor, 'name')}
                    &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                    {getValue(dep, 'name')}
                </>
            )
        } else {
            return (

                <>
                    &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                    <FontAwesomeIcon icon="exclamation-triangle" />&nbsp;
                </>
            )

        }

    }
    console.log("t_PATH", ticket)
    return (

        <>
            {
                ticket.room_list && ticket.room_list.length > 0 ?
                    <>
                        {ticket.room_list.length} locali &nbsp;
                        {full ? <>

                            {ticket.room_list.map((r, k) => (
                                <Row key={k}>
                                    <Col>
                                        {getValue(structureElements.all_structure_dict[r.parent_list[0]], 'name')}
                                        &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                                        {getValue(structureElements.all_pavillon_dict[r.parent_list[1]], 'name')}
                                        &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                                        {getValue(structureElements.all_floor_dict[r.parent_list[2]], 'name')}
                                        &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                                        {getValue(structureElements.all_department_dict[r.parent_list[3]], 'name')}
                                        &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                                        {getValue(structureElements.all_room_dict[r.id], 'name')}
                                    </Col>
                                    <Col>
                                        [{getValue(getValue(structureElements.all_room_dict[r.id], 'roomtype'), 'name')}]
                                    </Col>
                                </Row>
                            ))}
                        </>
                            :
                            <TicketRoomPopup troom_list={ticket.room_list} />
                        }

                    </>
                    :
                    ticket.structure ?
                        <>
                            {getValue(structureElements.all_structure_dict[ticket.structure.id], 'name')}
                            &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                            {ticket.department && ticket.department.id ?
                                <>
                                    {getDepartmentPath(ticket.department.id)}
                                    &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                                    {ticket.room && ticket.room.id ?
                                        <>{getValue(structureElements.all_room_dict[ticket.room.id], 'name')}<i>[{getValue(getValue(structureElements.all_room_dict[ticket.room.id], 'roomtype'), 'name')}]</i></>
                                        :
                                        <i>tutti i locali</i>
                                    }
                                </>
                                :
                                <i>tutti i reparti</i>}
                        </>
                        :
                        <i>tutte le strutture</i>
            }
        </>
    )

}



export const TicketUserName = ({ ticket }) => {
    if (ticket.message_list && ticket.message_list.length > 0) {
        return (<>{ticket.userName}</>)
    } else {
        if (ticket.userName) {
            return (<>{ticket.userName}</>)
        } else {
            return (<>...</>)
        }
    }

}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const TicketRoomPopup = ({ troom_list }) => {
    const { structureElements } = useContext(GlobalDataContext)
    const ref = useRef()


    return (
        <>
            <OverlayTrigger trigger={['hover', 'focus']} placement='right-start' overlay={

                <Popover id="popover-basic" className="bg-info border border-info" container={ref} placement='right-start'>
                    <Popover.Content>
                        <ListGroup variant="flush">
                            {troom_list.map((room, k) => (
                                <ListGroup.Item key={k}>

                                    {getValue(structureElements.all_structure_dict[room.parent_list[0]], 'name')}
                                    &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                                    {getValue(structureElements.all_pavillon_dict[room.parent_list[1]], 'name')}
                                    &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                                    {getValue(structureElements.all_floor_dict[room.parent_list[2]], 'name')}
                                    &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                                    {getValue(structureElements.all_department_dict[room.parent_list[3]], 'name')}
                                    &nbsp;<FontAwesomeIcon icon="angle-right" />&nbsp;
                                    {getValue(structureElements.all_room_dict[room.id], 'name')}&nbsp;
                                    <i>[{getValue(getValue(structureElements.all_room_dict[room.id], 'roomtype'), 'name')}]</i>

                                </ListGroup.Item>

                            ))}
                        </ListGroup>
                    </Popover.Content>
                </Popover>
            }>
                <Button size="sm"><FontAwesomeIcon icon="info-circle" />  </Button>
            </OverlayTrigger>
        </>
    )
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const TicketStatus = ({ e, show_history, ticket_type_dict }) => {
    const renderHistoryPopover = () => {
        return (
            <Popover>
                <Popover.Content>
                    <ListGroup>

                        {e.status_history.map((s, sk) => (
                            <ListGroup.Item key={sk}>
                                {format(myTimestampToDate(s.status_date), 'dd/MM/yyyy HH:mm')}: <b>{ticket_type_dict[e.type].status[s.code_id]}</b>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Popover.Content>
            </Popover>

        )
    }
    try {
        if (show_history) {
            // return (<>{ticket_type_dict[e.type].status[e.status_history[e.status_history.length - 1].code_id]}</>)
            return (<OverlayTrigger overlay={renderHistoryPopover()} trigger={['focus', 'hover']} rootClose placement='right-start'>
                <Badge>{ticket_type_dict[e.type].status[e.status_history[e.status_history.length - 1].code_id]}</Badge>
            </OverlayTrigger>)

        } else {
            return (<>{ticket_type_dict[e.type].status[e.status]}</>)

        }
    } catch (err) {
        return (<>-</>)
    }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const IsDeleted = ({ is_deleted, legend }) => {
    if (is_deleted) {
        return (
            <Badge variant="danger">
                <FontAwesomeIcon icon="trash" className="text-white" />
                {legend ?
                    'cancellato'
                    : ''}
            </Badge>
        )
    } else {
        return <></>
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
export const IsDone = ({ contract_execution_mode, end, done, legend, report, deleted, operator, employer_dict, showTime }) => {
    //console.log("DONE", done, contract_execution_mode)
    if (deleted) {
        return (<></>)
    } else {

        if (contract_execution_mode === undefined || contract_execution_mode === 'auto') {
            if (end !== null && end <= new Date()) {
                if (report) {
                    return (
                        <FontAwesomeIcon icon="check-square" />
                    )
                } else {
                    return (

                        <Badge variant="success">
                            <FontAwesomeIcon icon="check" className="text-light" />
                            {legend ?
                                'eseguito (automatico)'
                                : ''}
                        </Badge>
                    )
                }
            } else {
                if (report) {
                    return (
                        <FontAwesomeIcon className="text-light" icon="square" />
                    )
                } else {
                    return (<></>)

                }
            }
        } else if (contract_execution_mode === 'manual') {
            if (end !== null && end <= new Date()) {
                if (done) {
                    if (report) {
                        return (
                            <FontAwesomeIcon icon="check-square" />
                        )
                    } else {

                        return (
                            <Badge variant="success">
                                <FontAwesomeIcon icon="check" className="text-light" />
                                {legend ?
                                    'eseguito (manuale)'
                                    : ''}
                            </Badge>
                        )
                    }

                } else {
                    if (report) {
                        return (
                            <FontAwesomeIcon className="text-light" icon="square" />
                        )
                    } else {

                        return (
                            <Badge variant="success" className="text-danger">
                                <FontAwesomeIcon icon="times" className="text-danger" />
                                {legend ?
                                    'non eseguito'
                                    : ''}
                            </Badge>
                        )
                    }
                }
            } else {
                if (report) {
                    return (
                        <FontAwesomeIcon className="text-light" icon="square" />
                    )
                } else {
                    return (<></>)
                }
            }
        } else {
            if (report) {
                if (done && done.room_done_status) {
                    console.log("DONE", done.room_done_status, Object.values(done.room_done_status).filter(r => r.done === true).length > 1)
                    if (Object.values(done.room_done_status).filter(r => r.done === true).length > 1) {
                        return (
                            <Badge variant="success" className='w-100'>
                                Tr <FontAwesomeIcon icon="check" className="text-light" />
                                {legend &&
                                    <>{`eseguito (Trace: ${Object.values(done.room_done_status).filter(r => r.done === true).length} locali)`}</>
                                }
                                {operator &&
                                    <>{employer_dict[done.operator_id] ? employer_dict[done.operator_id].register : 'operatore eliminato'}</>
                                }
                                {console.log(employer_dict)}
                                    {showTime &&
                                    <>
                                    ({format(myTimestampToDate(done.start), 'HH:mm')} - {format(myTimestampToDate(done.end), 'HH:mm')})
                                    </>}
                            </Badge>
                        )
                    } else {
                        return (
                            <Badge variant="success" className="text-danger">
                                Tr <FontAwesomeIcon icon="times" className="text-danger" />
                                {legend ?
                                    'non eseguito'
                                    : ''}
                            </Badge>
                        )

                    }

                } else {
                    return (
                        <FontAwesomeIcon className="text-light" icon="square" />
                    )
                }
            } else {
                return <></>
            }
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const IsModified = ({ is_modified, legend }) => {
    if (is_modified) {
        return (
            <Badge variant="light" className="border-info">
                <FontAwesomeIcon icon="pencil-alt" className="text-info" />
                {legend ?
                    'modificato manualmente rispetto alla programmazione originale'
                    : ''}
            </Badge>
        )
    } else {
        return <></>
    }
}



////////////////////////////////////////////////////////////////////////
export const getMatrix = ({ count, level, strength_mode, global: global_data }) => {

    const level_matrix = [
        { min_index: 0, max_index: 0, level1: 0, level2: 0, level3: 0 },
        { min_index: 1, max_index: 1, level1: 1, level2: 1, level3: 1 },
        { min_index: 2, max_index: 8, level1: 2, level2: 2, level3: 3 },
        { min_index: 9, max_index: 15, level1: 2, level2: 3, level3: 5 },
        { min_index: 16, max_index: 25, level1: 3, level2: 5, level3: 8 },
        { min_index: 26, max_index: 50, level1: 5, level2: 8, level3: 13 },
        { min_index: 51, max_index: 90, level1: 5, level2: 13, level3: 20 },
        { min_index: 91, max_index: 150, level1: 8, level2: 20, level3: 32 },
        { min_index: 151, max_index: 280, level1: 13, level2: 32, level3: 50 },
        { min_index: 281, max_index: 500, level1: 20, level2: 50, level3: 80 },
        { min_index: 501, max_index: 1200, level1: 32, level2: 80, level3: 125 },
        { min_index: 1201, max_index: 3200, level1: 50, level2: 125, level3: 200 },
        { min_index: 3201, max_index: 10000, level1: 80, level2: 200, level3: 315 },
        { min_index: 10001, max_index: 35000, level1: 125, level2: 315, level3: 500 },
        { min_index: 35001, max_index: 150000, level1: 200, level2: 500, level3: 800 },
        { min_index: 150001, max_index: 500000, level1: 315, level2: 800, level3: 1250 },
        { min_index: 500001, max_index: 10000000, level1: 500, level2: 1250, level3: 2000 }
    ]
    const matrix = {
        'ordinary': {
            5: { lqa4: { ac: 0, pqa: 0, pql: 0, }, lqa6: { ac: 0, pqa: 0, pql: 0, }, lqa10: { ac: 1, pqa: 91.9, pql: 52.8 }, },
            8: { lqa4: { ac: 0, pqa: 0, pql: 0, }, lqa6: { ac: 1, pqa: 90.9, pql: 51.8 }, lqa10: { ac: 2, pqa: 96.2, pql: 55.2 }, },
            13: { lqa4: { ac: 1, pqa: 90.7, pql: 52.6, }, lqa6: { ac: 2, pqa: 95.2, pql: 52.0, }, lqa10: { ac: 3, pqa: 96.6, pql: 42.1, }, },
            20: { lqa4: { ac: 2, pqa: 95.6, pql: 56.3, }, lqa6: { ac: 3, pqa: 96.3, pql: 43.3, }, lqa10: { ac: 5, pqa: 98.8, pql: 41.6, }, },
            32: { lqa4: { ac: 3, pqa: 96.2, pql: 45.4, }, lqa6: { ac: 5, pqa: 98.4, pql: 38.7, }, lqa10: { ac: 7, pqa: 98.8, pql: 21.2, }, },
            50: { lqa4: { ac: 5, pqa: 98.6, pql: 43.5, }, lqa6: { ac: 7, pqa: 98.5, pql: 21.5, }, lqa10: { ac: 10, pqa: 99.0, pql: 32.8, }, },
            80: { lqa4: { ac: 7, pqa: 98.5, pql: 24.2, }, lqa6: { ac: 10, pqa: 98.6, pql: 7.0, }, lqa10: { ac: 14, pqa: 98.8, pql: 0.8, }, },
            125: { lqa4: { ac: 10, pqa: 98.8, pql: 10.3, }, lqa6: { ac: 14, pqa: 98.4, pql: 1.0, }, lqa10: { ac: 21, pqa: 99.0, pql: 0, }, },
            200: { lqa4: { ac: 14, pqa: 98.5, pql: 1.5, }, lqa6: { ac: 21, pqa: 98.9, pql: 0, }, lqa10: { ac: 0, pqa: 0, pql: 0, }, },
            315: { lqa4: { ac: 21, pqa: 99.6, pql: 0.1, }, lqa6: { ac: 0, pqa: 0, pql: 0, }, lqa10: { ac: 0, pqa: 0, pql: 0, }, }
        },
        'reinforced': {
            8: { lqa4: { ac: 0, pqa: 0, pql: 0, }, lqa6: { ac: 0, pqa: 0, pql: 0, }, lqa10: { ac: 1, pqa: 81.3, pql: 25.5, }, },
            13: { lqa4: { ac: 0, pqa: 0, pql: 0, }, lqa6: { ac: 1, pqa: 79.5, pql: 24.7, }, lqa10: { ac: 2, pqa: 86.6, pql: 20.2, }, },
            20: { lqa4: { ac: 1, pqa: 81.0, pql: 28.9, }, lqa6: { ac: 2, pqa: 86.3, pql: 22.2, }, lqa10: { ac: 3, pqa: 86.7, pql: 10.7, }, },
            32: { lqa4: { ac: 2, pqa: 86.5, pql: 24.4, }, lqa6: { ac: 3, pqa: 84.8, pql: 10.5, }, lqa10: { ac: 5, pqa: 90.6, pql: 5.1, }, },
            50: { lqa4: { ac: 3, pqa: 86.1, pql: 13.5, }, lqa6: { ac: 5, pqa: 89.6, pql: 5.7, }, lqa10: { ac: 8, pqa: 94.2, pql: 1.08, }, },
            80: { lqa4: { ac: 5, pqa: 89.9, pql: 7.1, }, lqa6: { ac: 8, pqa: 92.5, pql: 1.7, }, lqa10: { ac: 12, pqa: 94.6, pql: 0.2, }, },
            125: { lqa4: { ac: 8, pqa: 93.6, pql: 2.9, }, lqa6: { ac: 12, pqa: 93.7, pql: 0.2, }, lqa10: { ac: 18, pqa: 95.7, pql: 0, }, },
            200: { lqa4: { ac: 12, pqa: 94.0, pql: 0.4, }, lqa6: { ac: 18, pqa: 93.7, pql: 0, }, lqa10: { ac: 0, pqa: 0, pql: 0, }, },
            315: { lqa4: { ac: 18, pqa: 94.9, pql: 0, }, lqa6: { ac: 0, pqa: 0, pql: 0, }, lqa10: { ac: 0, pqa: 0, pql: 0, }, },

        },
        'reduced': {
            2: { lqa4: { ac: 0, pqa: 0, pql: 0, }, lqa6: { ac: 0, pqa: 0, pql: 0, }, lqa10: { ac: 0, pqa: 99.0, pql: 91.0, }, },
            3: { lqa4: { ac: 0, pqa: 0, pql: 0, }, lqa6: { ac: 0, pqa: 81.7, pql: 52.2, }, lqa10: { ac: 1, pqa: 97.2, pql: 78.4, }, },
            5: { lqa4: { ac: 0, pqa: 81.5, pql: 52.8, }, lqa6: { ac: 1, pqa: 96.3, pql: 74.7, }, lqa10: { ac: 1, pqa: 91.9, pql: 52.8, }, },
            8: { lqa4: { ac: 1, pqa: 96.2, pql: 75.2, }, lqa6: { ac: 1, pqa: 90.9, pql: 51.8, }, lqa10: { ac: 2, pqa: 96.2, pql: 55.2, }, },
            13: { lqa4: { ac: 1, pqa: 90.7, pql: 52.6, }, lqa6: { ac: 2, pqa: 95.20, pql: 52.0, }, lqa10: { ac: 3, pqa: 96.2, pql: 42.1, }, },
            20: { lqa4: { ac: 2, pqa: 95.6, pql: 56.3, }, lqa6: { ac: 3, pqa: 96.3, pql: 43.3, }, lqa10: { ac: 5, pqa: 98.9, pql: 41.6, }, },
            32: { lqa4: { ac: 3, pqa: 96.2, pql: 45.4, }, lqa6: { ac: 5, pqa: 98.4, pql: 38.7, }, lqa10: { ac: 7, pqa: 98.8, pql: 21.2, }, },
            50: { lqa4: { ac: 5, pqa: 98.8, pql: 43.5, }, lqa6: { ac: 7, pqa: 98.5, pql: 21.5, }, lqa10: { ac: 10, pqa: 99.0, pql: 32.8, }, },
            80: { lqa4: { ac: 7, pqa: 98.5, pql: 24.2, }, lqa6: { ac: 10, pqa: 96.6, pql: 7.0, }, lqa10: { ac: 0, pqa: 0, pql: 0, }, },
            125: { lqa4: { ac: 10, pqa: 98.8, pql: 10.3, }, lqa6: { ac: 0, pqa: 0, pql: 0, }, lqa10: { ac: 0, pqa: 0, pql: 0, }, },
        },


    }
    if (global_data) {
        return {
            level_matrix, matrix
        }

    } else {
        let sel_row = level_matrix[0]
        for (let r = 0; r < level_matrix.length; r++) {
            if (level_matrix[r].max_index >= count && level_matrix[r].min_index <= count) {
                sel_row = level_matrix[r]
            }
        }
        const sel_count = sel_row[level]
        console.log(count, sel_row, strength_mode, sel_count, matrix[strength_mode][sel_count])
        const sel_rowb = matrix[strength_mode][sel_count]

        if (sel_rowb) {
            return { ...sel_rowb, sampling_count: sel_count }
        } else {
            return { lqa4: { ac: 0, pqa: 0, pql: 0, }, lqa6: { ac: 0, pqa: 0, pql: 0, }, lqa10: { ac: 0, pqa: 0, pql: 0, }, sampling_count: sel_row[level] }
        }
    }


}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const renderActivityPeriodListPopover = (period_list) => {
    return (
        <Popover id="popover-basic" className="bg-info border border-info">
            <Popover.Title as="h3">Periodi di attività inseriti<br />
            </Popover.Title>
            <Popover.Content>
                {period_list ?
                    <ListGroup>
                        {period_list.map((r, k) => (
                            <ListGroup.Item key={k} className="p-2">
                                {r.description} ({format(myTimestampToDate(r.startDate), 'dd/MM/yyyy')} {format(myTimestampToDate(r.endDate), 'dd/MM/yyyy')})
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    : ''
                }
            </Popover.Content>
        </Popover>
    )
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ResultBar = ({ res_dict, length }) => {

    const ref = useRef(null)
    const res_color = {
        true: 'green',
        false: 'red',
        unavailable: 'lightgrey'
    }
    const res_label = {
        true: 'positivi',
        false: 'negativi',
        unavailable: 'non disponibile'
    }

    const res_list = ['true', 'false', 'unavailable']


    return (
        <Row>
            <Col className="p-1">
                <div ref={ref}>

                    <OverlayTrigger trigger={['hover', 'focus']} placement='right-start' overlay={

                        <Popover id="popover-basic" className="bg-info border border-info" container={ref} placement='right-start'>
                            <Popover.Content>
                                <ListGroup variant="flush">
                                    {res_list.filter(a => Object.keys(res_dict).indexOf(a) >= 0).map((a, k) => (
                                        <ListGroup.Item key={k}>
                                            <Badge className="mr-1" style={{
                                                background: res_color[a]
                                            }}>&nbsp;&nbsp;</Badge>{res_label[a]}: {res_dict[a]}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Popover.Content>
                        </Popover>
                    }>
                        <ProgressBar>
                            {res_list.filter(a => Object.keys(res_dict).indexOf(a) >= 0).map((a, k) => (
                                <ProgressBar now={parseInt(res_dict[a] * 100 / length)} style={{ backgroundColor: res_color[a] }} key={k} />
                            ))}
                        </ProgressBar>
                    </OverlayTrigger>
                </div>
            </Col>
        </Row>
    )
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const SecondarySection = ({ ev, intervention_data, short, long }) => {
    const { service_dict, structureElements, roomtype_dict } = useContext(GlobalDataContext)
    if (intervention_data && intervention_data.secondary_service_list && intervention_data.secondary_service_list.length > 0) {
        for (const s of intervention_data.secondary_service_list) {
            const room_count = intervention_data.room_list.filter(_room => filterRoom(ev, structureElements.all_room_dict[_room.id], roomtype_dict[structureElements.all_room_dict[_room.id].roomtype.id], s)).length
            //            console.log(room_count)
            if (room_count > 0) {
                if (short) {
                    return (
                        <FontAwesomeIcon icon="plus-square" />
                    )
                } else if (long) {
                    return (
                        <>Prestazione secondaria <FontAwesomeIcon icon="plus-square" /> <ServiceBadge service={service_dict[s]} freq={true} /> </>
                    )
                } else {

                    return (
                        <><FontAwesomeIcon icon="plus-square" /> <ServiceBadge service={service_dict[s]} freq={true} /> ({room_count} locali)</>
                    )
                }


            } else {
                return (
                    <></>
                )

            }

        }


    }
    return (
        <></>
    )
}

const img_ext = ['.jpg', '.jpeg', '.png', '.gif']

export const isImageFromName = (f_name) => {
    if (f_name) {
        const p_name = p_name.toLowerCase()
        if (img_ext.some(s => p_name.endsWith(s))) {
            return true
        }
        return false
    } else {
        return false
    }
}