import { format } from 'date-fns';
import React, { useContext  } from 'react';
import {  Row, Col, Image } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { myTimestampToDate } from '../../util/ui_utils';

import DomainContext from '../contexts/domainContext'
import GlobalDataContext from '../contexts/globaldataContext';
import { renderPhoneList } from '../StructureGraphicElements.js';

const PrintFooter = ({ t, vertical }) => {

    const {  domain_data } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)
    const getAddress = () => {
        if (domain_data.address) {
            const ad = domain_data.address
            if (ad.street.length>0){

            return (
                <Row>
                    <Col>
                        {ad.street}, {ad.number} - {ad.zip} - {ad.city} {ad.city}
                    </Col>
                </Row>
            )
            }else{
                return (<></>)
            }
        }
    }


    if (vertical) {
        return (
            <div >
                <Row>
                    <Col className="text-center">
                        {getAddress()}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        {renderPhoneList(domain_data.phone_list)}
                    </Col>
                </Row>
                <Row>
                    {domain_data.email && domain_data.email.length > 0 &&
                        <Col className="text-center">
                            <i>Email</i>: {domain_data.email}
                        </Col>
                    }
                </Row>
                <Row>
                    {domain_data.pec && domain_data.pec.length > 0 &&
                        <Col className="text-center">
                            <i>Pec</i>: {domain_data.pec}
                        </Col>
                    }
                </Row>
                <Row>
                    {domain_data.website && domain_data.website.length > 0 &&
                        <Col className="text-center">
                            {domain_data.website}
                        </Col>
                    }
                </Row>
                <Row>
                    {domain_data.vat && domain_data.vat.length > 0 &&
                        <Col className="text-center">
                            <i>P.Iva</i> {domain_data.vat}
                        </Col>
                    }
                </Row>
                <Row>
                    {domain_data.cf && domain_data.cf.length > 0 &&
                        <Col className="text-center">
                            <i>C.F.</i> {domain_data.cf}
                        </Col>
                    }
                </Row>
                <Row>
                    {domain_data.registro_imprese && domain_data.registro_imprese.length > 0 &&
                        <Col className="text-center">
                            <i>Registro imprese:</i> {domain_data.registro_imprese}
                        </Col>
                    }
                </Row>
                <Row>
                    {domain_data.capitale_sociale && domain_data.capitale_sociale.length > 0 &&
                        <Col className="text-center">
                            <i>Capitale Sociale:</i> {domain_data.capitale_sociale}
                        </Col>
                    }
                </Row>
                <Row>
                    {domain_data.rea && domain_data.rea.length > 0 &&
                        <Col className="text-center">
                            <i>REA:</i> {domain_data.rea}
                        </Col>
                    }
                </Row>
                <Row>
					{domain_data.logo_certificate &&
                        <Col className="text-center">
							<Image style={{ height: '150px' }} src={domain_data.logo_certificate} fluid />
						</Col>
    }
                </Row>
                {contract &&
                <Row>
					{contract.ecolabel_logo &&
                        <Col className="text-center">
							<Image style={{ height: '150px' }} src={contract.ecolabel_logo} fluid />
						</Col>
						}
					{contract.ecolabel_description && contract.ecolabel_description.length>0 &&
                        <Col className="text-center">
                            {contract.ecolabel_description}
						</Col>
						}
                </Row>
                }
            </div>
        )

    } else {
        return (
            <div className="page-footer">
                <Row className="border-top">
                    <Col className="text-center">
                        {getAddress()}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        {renderPhoneList(domain_data.phone_list)}
                    </Col>
                    {domain_data.email && domain_data.email.length > 0 &&
                        <Col className="text-center">
                            <i>Email:</i> {domain_data.email}
                        </Col>
                    }
                    {domain_data.pec && domain_data.pec.length > 0 &&
                        <Col className="text-center">
                            <i>Pec:</i> {domain_data.pec}
                        </Col>
                    }
                    {domain_data.website && domain_data.website.length > 0 &&
                        <Col className="text-center">
                            {domain_data.website}
                        </Col>
                    }
					{domain_data.logo_certificate &&
						<Col>
							<Image style={{ height: '20px' }} src={domain_data.logo_certificate} fluid />
						</Col>
						}
                        {contract && <>
					{contract.ecolabel_logo && 
                        <Col className="text-center">
							<Image style={{ height: '20px' }} src={contract.ecolabel_logo} fluid />
						</Col>
						}
					{contract.ecolabel_description && contract.ecolabel_description.length>0 &&
                        <Col className="text-center">
                            {contract.ecolabel_description}
                            {contract.ecolabel_number &&<i><br/>
                            &nbsp;n°{contract.ecolabel_number} valida fino al {format(myTimestampToDate(contract.ecolabel_expiration), 'dd/MM/yyyy')}
                            </i>}
						</Col>
						}
                        </>}
                </Row>
                <Row>
                    {domain_data.vat && domain_data.vat.length > 0 &&
                        <Col className="text-center">
                            <i>P.Iva</i> {domain_data.vat}
                        </Col>
                    }
                    {domain_data.cf && domain_data.cf.length > 0 &&
                        <Col className="text-center">
                            <i>C.F.</i> {domain_data.cf}
                        </Col>
                    }
                    {domain_data.registro_imprese && domain_data.registro_imprese.length > 0 &&
                        <Col className="text-center">
                            <i>Registro imprese:</i> {domain_data.registro_imprese}
                        </Col>
                    }
                    {domain_data.capitale_sociale && domain_data.capitale_sociale.length > 0 &&
                        <Col className="text-center">
                            <i>Capitale Sociale:</i> {domain_data.capitale_sociale}
                        </Col>
                    }
                    {domain_data.rea && domain_data.rea.length > 0 &&
                        <Col className="text-center">
                            <i>REA:</i> {domain_data.rea}
                        </Col>
                    }
                </Row>
            </div>
        );
    }
}

export default translate()(PrintFooter)

