import {firestore} from '../firebaseIndex'


/////////////////////////////////////////////////////////////////////////////////////
//
//                                 SERVICE
//
/////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////
export const addService = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('services')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addService error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getServiceById = async (domainId, contractId, ServiceId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('services')
            .get(ServiceId);

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getService error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getServiceList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('services')
                .get();

            const ServiceList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return ServiceList;
        
    } catch (e) {
        console.log("getServiceList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getServiceDict = async (domainId, contractId, type) => {
    try {
        if(type){
            const res = await firestore.collection('DOMAINS')
                    .doc(domainId)
                    .collection('contracts')
                    .doc(contractId)
                    .collection('services')
                    .where('type', '==', type)
                    .get();

                const ServiceList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })

            const serviceDict = ServiceList.reduce( (r, x) => ( {...r, [x.id]:x}), {})
            return serviceDict;
        }else{
            const res = await firestore.collection('DOMAINS')
                    .doc(domainId)
                    .collection('contracts')
                    .doc(contractId)
                    .collection('services')
                    .get();

                const ServiceList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })

            const serviceDict = ServiceList.reduce( (r, x) => ( {...r, [x.id]:x}), {})
            return serviceDict;
        }
    } catch (e) {
        console.log("getServiceDict error: ", e)
        return { error: e }
    }
}





///////////////////////////////////////////////////////////////////////////////
export const editService = async (domainId, contractId, ServiceId, data) => {
    try {
        const doc = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('services')
            .doc(ServiceId);

        const res = await doc.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editService error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteService = async (domainId, contractId, ServiceId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('services')
            .doc(ServiceId).delete();
        return res
    }  catch (e) {
        console.log("deleteService error: ", e)
        return { error: e }
    }
}


/////////////////////////////////////////////////////////////////////////////////////
//
//                                 OPERATION
//
/////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////
export const addOperation = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('operations')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addOperation error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getOperationById = async (domainId, contractId, OperationId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('operations')
            .get(OperationId);

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getOperation error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getOperationList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('operations')
                .get();

            const OperationList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return OperationList;
        
    } catch (e) {
        console.log("getOperationList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getOperationDict = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('operations')
                .get();

            const OperationList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })

        const OperationDict = OperationList.reduce( (r, x) => ( {...r, [x.id]:x}), {})
        return OperationDict;

    } catch (e) {
        console.log("getOperationDict error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////

export const editOperation = async (domainId, contractId, OperationId, data) => {
    console.log(domainId, contractId, OperationId, data)
    try {
        const doc = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('operations')
            .doc(OperationId);

        const res = await doc.set(
            data
        );

        return data
    }  catch (e) {
        console.log("editOperation error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteOperation = async (domainId, contractId, OperationId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('operations')
            .doc(OperationId).delete();
        return res
    }  catch (e) {
        console.log("deleteOperation error: ", e)
        return { error: e }
    }
}

