import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import Chart from "react-apexcharts";
import { Container, Row, Col, Button, Modal, Form, Nav, Tab, ButtonGroup, ProgressBar } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import DateTime from 'react-datetime';

import { useReactToPrint } from 'react-to-print';
import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';


import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"

import { myTimestampToDate, getSelectedElement, checkComponentPresence, makeid, getElementPreview } from "../util/ui_utils.js"
import { ModalInitButton } from '../components/StructureGraphicElements.js'


import { getCustomerSatisfactionResultList } from '../Firebase2/Storage2/dbCustomerSatisfactionResults';
import { format } from 'date-fns';
import { CampaignStatus } from '../pages/CustomerSatisfactionView.js';
import PrintFooter from './generic/PrintFooter.js';
import { getDefinitionItemList } from '../Firebase2/Storage2/dbDefinition.js';





////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalCustomerSatisfactionResult({ item, setItem, t, is_new, small, item_list, index, canWrite }) {
	const { domain, domain_data } = useContext(DomainContext)

	const { contract } = useContext(GlobalDataContext)
	const componentRef = useRef();

	let { cid } = useParams();
	let contractId = cid


	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(item)
	const [global_param_list, setGlobalParamList] = useState([])
	const [result_list, setResultList] = useState([])
	const [res_summary, setResSummary] = useState({})


	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
	}


	const initValues = async () => {
		const res_data = await getCustomerSatisfactionResultList(domain, contractId, item.id)
		setResultList(res_data)
		let newelement_list = await getDefinitionItemList(domain, contractId)
		setGlobalParamList(newelement_list.filter(e => e.category === 'global_param' && e.check_type === 'customer_satisfaction'))
		let _res_summary = {}
		const s_data = res_data.map(r => r.result_data)
		for (const q in item.question_list) {
			const one_value = s_data.map(d => d[item.question_list[q].id])
			_res_summary[item.question_list[q].id] = one_value
			console.log(one_value)

		}
		console.log(_res_summary)
		setResSummary(_res_summary)

	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handlePrint = useReactToPrint({
		content: () => {
			const tableStat = componentRef.current.cloneNode(true);
			const PrintElem = document.createElement('div');
			PrintElem.appendChild(tableStat);
			return PrintElem;
		},
		pageStyle: pageStyle,
		documentTitle: `${item.code} ${item.name} | Risultati campagna }`
	});

	return (
		<>
			<Button variant="outline-info" onClick={() => handleShowEdit()} ><FontAwesomeIcon icon="eye" /></Button>
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton  >
					<Modal.Title>
						<FontAwesomeIcon icon="info" /> <span className="text-info">{t('customersatisfactions.labels.view_customersatisfaction_result')}</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid >
						<CommonCustomerSatisfactionSection t={t} item={item} result_list={result_list} res_summary={res_summary} />
					</Container>
					<div className="d-none">

						<div ref={componentRef} className="print_page">
							<style type="text/css" media="print">{"\
  @page {\ size: portrait\ }\
"}</style>
							<Row className="printonly_visible mb-2">
								<Col xs="2">
									<img
										src={domain !== 'admin' && (domain_data && domain_data.logo) ? domain_data.logo : "./Logo.Claudit.registrato.png"}
										height="40"
										className="d-inline-block align-center"
										alt={domain_data && domain_data.logo ? domain_data.name : "Claudit"}
									/>
								</Col>
								<Col>
									Risultati campagna: {item.name}
								</Col>
								<Col xs="2">
									{getElementPreview(contract, '40px')}
								</Col>
							</Row>
							{global_param_list.map((g, k) => (
								<React.Fragment key={k}>

									<Row>
										<Col>
											{g.definition}
										</Col>
									</Row>
									<Row>
										<Col>
											{g.scope_and_exclusions}
										</Col>
									</Row>
								</React.Fragment>

							))}
							<Row className="mt-1 mb-1 border-bottom border-top">
								<Col>
									dal	{format(myTimestampToDate(item.startDate), 'dd/MM/yyyy')}&nbsp;
									al {format(myTimestampToDate(item.endDate), 'dd/MM/yyyy')}
								</Col>
								<Col>
									stato: <CampaignStatus i={item} t={t} />
								</Col>
							</Row>
							<CommonCustomerSatisfactionSection t={t} item={item} result_list={result_list} res_summary={res_summary} />
							<PrintFooter t={t} />
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="info" onClick={handlePrint}>
						<FontAwesomeIcon icon="print" />  {t('modal.print')}
					</Button>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}






const CommonCustomerSatisfactionSection = ({ item, result_list, res_summary, t }) => {
	return (
		<>
			<Row>
				<Col>
					{result_list.length} questionari compilati
				</Col>
			</Row>
			{
				result_list.length > 0 ?

					<>
						<Row >
							<Col className="bg-secondary text-light ml-1">{t('customersatisfaction.labels.question')} </Col>
							<Col className="bg-secondary text-light ml-1">{t('customersatisfaction.labels.result')} </Col>
						</Row>
						{item.question_list.map((r, key) => (
							<Row key={key} className="border-bottom mb-1 pb-1 element_row">
								<Col>
									{r.question}
								</Col>
								<Col>
									{r.question_type === 'radio' ?
										<AnswerSummary answer_list={r.answer_list} response={res_summary[r.id]} />
										:
										<AnswerSummaryMulti answer_list={r.answer_list} response={res_summary[r.id]} />
									}
								</Col>
							</Row>
						))}
					</>
					: <Row><Col><i> {t('customersatisfaction.messages.no_result')} </i></Col></Row>
			}
		</>

	)
}




const AnswerSummary = ({ answer_list, response }) => {
	const [mydata, setData] = useState([])
	const [options, setOptions] = useState({})

	useLayoutEffect(() => {
		let _series = answer_list.map((a, k) => ({ answer: a.answer, icon_list: a.icon_list, y: response ? response.filter(b => b === k).length : 0 }))
		_series.sort((a, b) => b.y - a.y)
		setData(_series.sort())
	}, [])
	const total_response = response ? response.length: 0
	return (
		<>
			{mydata && mydata.length > 0 ?
				<>
					{mydata.map((a, k) => (
						<Row key={k}>
							<Col>
								{a.answer}
								{a.icon_list.map((i, ki) => (
									<FontAwesomeIcon icon={i} key={ki} />
								))}
							</Col>
							<Col>
								{a.y}
							</Col>
							<Col>
								<ProgressBar now={100*a.y/total_response} />
							</Col>
						</Row>
					))}
				</> : ''}
		</>
	)
}

const AnswerSummaryMulti = ({ answer_list, response }) => {
	const [mydata, setData] = useState([])
	useLayoutEffect(() => {
		let _series = answer_list.map((a, k) => ({ answer: a.answer, icon_list: a.icon_list, y: response ? response.flat().filter(b => b === k).length : 0 }))
		_series.sort((a, b) => b.y - a.y)
		setData(_series.sort())
	}, [])
	const total_response = response? response.flat().length: 0
	return (
		<>
			{mydata && mydata.length > 0 ?
				<>
					{mydata.map((a, k) => (
						<Row key={k}>
							<Col>
								{a.answer}
								{a.icon_list.map((i, ki) => (
									<FontAwesomeIcon icon={i} key={ki} />
								))}
							</Col>
							<Col>
								{a.y}
							</Col>
							<Col>
								<ProgressBar now={100*a.y/total_response} />
							</Col>
						</Row>
					))}
				</> : ''}
		</>

	)
}

export default translate()(ModalCustomerSatisfactionResult)




const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm;
	  height: 297mm;
	  margin:5mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  div.page-footer{
	display:none;
  }
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
	display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


