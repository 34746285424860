import {firestore} from '../firebaseIndex' 


/**
 * 
 * @param {string} domainId 
 * @param {string} contractId 
 * @param {string} event_id if not null, list will be filtered by event_id
 * @param {*} validate_status if not null, list will be filtered by validate_status
 * @param {Timestamp} start_date if not null, list will be filtered by start_date
 * @param {Timestamp} end_date if not null, list will be filtered by end_date 
 * @returns intervention log list filtered by event_id and validate_status
 */
 export const getInterventionLogList = async (domainId, contractId, event_id=null, validate_status=null, start_date=null, end_date=null) => {
    try {

        console.log(domainId, contractId)
        let query = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('interventionsLog')

        if( event_id !== null){
            query = query.where("event_id", "==", event_id)
        }

        if( validate_status !== null){
            query = query.where("validate_status", "==", validate_status)
        }

        if( start_date !== null){
            query = query.where("start", ">=", start_date)
        }

        if( end_date !== null){
            query = query.where("start", "<=", end_date)
        }


        const res = await query.get();

        const interventionLogList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return interventionLogList;
            
    } catch (e) {
        console.log("getInterventionLog error: ", e)
        return { error: e }
    }
}
 


 export const getInterventionLogListRoom = async (domainId, contractId, room_id, limit) => {
    try {

        console.log(domainId, contractId)
        let query = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('interventionsLog')

        if( room_id !== null){
//            query = query.where('room_done_status', "array-contains", room_id)
            query = query.orderBy(`room_done_status.${room_id}.lastUpdate`, 'desc')
            //query = query.where (`room_done_status.${room_id}.done`, "==" , true)
            query = query.limit(1)
        }
        if (limit){
            query = query.limit(limit)

        }


       
        const res = await query.get();

        console.log("RES", res)
        const interventionLogList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
                return data
        })
        return interventionLogList;
            
    } catch (e) {
        console.log("getInterventionLog error: ", e)
        return { error: e }
    }
}