import {firestore} from '../firebaseIndex'


///////////////////////////////////////////////////////////////////////////////
export const addContractGroup = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('contractgroups')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addContractGroup error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getContractGroupById = async (domainId, contractId, groupId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
 			.collection('contractgroups')
			.doc(groupId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getContractGroup error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getContractGroupList = async (domainId, contractId) => {
    try {
           const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('contractgroups')
                .get();

            const contractgroupList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return contractgroupList;


    } catch (e) {
        console.log("getContractGroupList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getContractGroupDict = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('contractgroups')
            .get();

        const contractgroupList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })

        const contractgroupDict = contractgroupList.reduce( (r, x) => ( {...r, [x.id]:x}), {})
        return contractgroupDict;

    } catch (e) {
        console.log("getContractGroupDict error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const editContractGroup = async (domainId, contractId, groupId, data) => {
    try {
        const contractgroup = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('contractgroups')
            .doc(groupId);

        const res = await contractgroup.set(
            data
        ).then( () => {
			console.log("editContractGroup - edit successfully")
			return data
		}).catch( error => {
			console.log("editContractGroup - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editContractGroup error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const deleteContractGroup = async (domainId, contractId, groupId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('contractgroups')
            .doc(groupId).delete();
        return res
    }  catch (e) {
        console.log("deleteContractGroup error: ", e)
        return { error: e }
    }
}




// =========================================================================================================================
// =========================================================================================================================
// =========================================================================================================================
// =========================================================================================================================
// =========================================================================================================================
// =========================================================================================================================



///////////////////////////////////////////////////////////////////////////////
export const addSystemGroup = async (data ) => {
    try {
        const res = await firestore.collection('systemgroups')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addSystemGroup error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getSystemGroupById = async (groupId) => {
    try {
        const res = await firestore.collection('systemgroups')
			.doc(groupId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getSystemGroup error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getSystemGroupList = async () => {
    try {
           const res = await firestore.collection('systemgroups')
                .get();

            const systemgroupList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return systemgroupList;


    } catch (e) {
        console.log("getSystemGroupList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getSystemGroupDict = async () => {
    try {
        const res = await firestore.collection('systemgroups')
            .get();

        const systemgroupList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })

        const systemgroupDict = systemgroupList.reduce( (r, x) => ( {...r, [x.id]:x}), {})
        return systemgroupDict;

    } catch (e) {
        console.log("getSystemGroupDict error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const editSystemGroup = async ( groupId, data) => {
    try {
        const systemgroup = firestore.collection('systemgroups')
            .doc(groupId);

        const res = await systemgroup.set(
            data
        ).then( () => {
			console.log("editSystemGroup - edit successfully")
			return data
		}).catch( error => {
			console.log("editSystemGroup - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editSystemGroup error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const deleteSystemGroup = async (groupId) => {
    try {
        const res = await firestore.collection('systemgroups')
            .doc(groupId).delete();
        return res
    }  catch (e) {
        console.log("deleteSystemGroup error: ", e)
        return { error: e }
    }
}