import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import DomainContext from './contexts/domainContext'


import ModalBaseObject from "./ModalBaseObject"
import AlertSection from "./AlertSection.js"
import ElementFormMedia from "./FormMedia.js"

import { getSelectedElement, checkComponentPresence } from "../util/ui_utils.js"
import { ModalInitButton } from './StructureGraphicElements.js'


import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { editFailure } from '../Firebase2/Storage2/dbFailure';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';
//import { firestore } from 'firebase';
import firebase from 'firebase/app'




const section = 'failure'
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalFailure({ item, setItem, t, is_new, small, item_list, canWrite }) {
	const { domain } = useContext(DomainContext)

	let { cid } = useParams();
	let contractId = cid

	const childRefAlert = useRef()
	const newfailure = {
		code: '',
		brand: {},
		model: '',
		note: '',
		category: {},
		minvalue: 0,
		media_list: []
	}
	const initValues = async () => {
		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		const category_list = await getBaseObjectTypeList2(domain, 'failure_category')
		setUiList({ ...ui_list, brand_list, category_list })
		/* 		let newelement_list = await getFailureList(contractId, companyId, 'failure', null)
				newelement_list.sort(compareName)
				setElementList(newelement_list) */
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newfailure)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newfailure.media_list = []
		setCurrentElement(newfailure)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async (mode) => {
		curElement.status = mode
		curElement.closed = true
		curElement.closedAt = firebase.firestore.Timestamp.now()
		let return_data = await editFailure(domain, contractId, curElement.id, curElement)
		console.log("edit", return_data)
		if (return_data && return_data.error) {
			childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
		} else {
			console.log(item_list)
			let edit_list = [...item_list]
			const c = checkComponentPresence(curElement, item_list, 'id')
			edit_list.splice(c, 1, curElement)
			setItem(edit_list)
			setShow(false);
		}
	}

	return (
		<>
			{canWrite && !curElement.closed &&
				<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('failure.buttons.failure_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
				
			}
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="md">
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('failures.labels.new_failure')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('failures.labels.edit_failure_status')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('failures.labels.view_failure')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement && <FontAwesomeIcon icon="save" />}
					</Modal.Title>
				</Modal.Header>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite && !curElement.closed &&
						<>
							<Button className={editedElement ? "btn-warning" : "btn-success"} form="companyForm" onClick={() => handleSave('close')}>
								<FontAwesomeIcon icon="check" /> {t('failure.labels.close_failure')}
							</Button>
							<Button className={editedElement ? "btn-warning text-light " : "btn-outline-secondary text-light"} form="companyForm" onClick={() => handleSave('notvalid')}>
								<FontAwesomeIcon icon="trash" /> {t('failure.labels.invalidate_failure')}
							</Button>
						</>
						}
				</Modal.Footer>
			</Modal>
		</>
	)
}




export default translate()(ModalFailure)