import {firestore} from '../firebaseIndex'

const addCustomerSatisfactionResult = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactionresults')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addCustomerSatisfactionResult error: ", e)
        return { error: e }
    }
}

const getCustomerSatisfactionResultById = async (domainId, contractId, customersatisfactionresultId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactionresults')
            .doc(customersatisfactionresultId)
            .doc();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getCustomerSatisfactionResultById error: ", e)
        return { error: e }
    }
}

const getCustomerSatisfactionResultList = async (domainId, contractId, cs_id ) => {
    try {
        let res = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactionresults')
        if (cs_id !== null && cs_id !== undefined) {
            res = res.where('customer_satisfaction_id', '==', cs_id)
        }
        res = await res.get();

        const customersatisfactionresultList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return customersatisfactionresultList;

    } catch (e) {
        console.log("getCustomerSatisfactionResultList error: ", e)
        return { error: e }
    }
}


const editCustomerSatisfactionResult = async (domainId, contractId, customersatisfactionresultId, data) => {
    try {
        const customersatisfactionresult = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactionresults')
            .doc(customersatisfactionresultId);

        const res = await customersatisfactionresult.set(
            data
        );

        return res
    }  catch (e) {
        console.log("editProduct error: ", e)
        return { error: e }
    }
}


const deleteCustomerSatisfactionResult = async (domainId, contractId, customersatisfactionresultId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('customersatisfactionresults')
            .doc(customersatisfactionresultId).delete();
        return res
    }  catch (e) {
        console.log("deleteCustomerSatisfactionResult error: ", e)
        return { error: e }
    }
}




export { addCustomerSatisfactionResult };
export { getCustomerSatisfactionResultById };
export { getCustomerSatisfactionResultList };
export { editCustomerSatisfactionResult };
export { deleteCustomerSatisfactionResult };