import React, { useState, useContext, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Button, Tooltip, Popover, ListGroup, OverlayTrigger } from 'react-bootstrap';

import LocaleContext from './contexts/locale.js'
import GlobalDataContext from './contexts/globaldataContext.js';
import { translate } from 'react-polyglot'
import { format, setDay, } from 'date-fns'
import { it } from 'date-fns/locale'

import styles from './constants/styles.js'


import { ServiceBadge, RiskareaBadge } from './StructureGraphicElements.js'
import { getFloatNumber, getTimeStr } from "../util/ui_utils"

const contractId = sessionStorage.getItem('contractId')
const contract = JSON.parse(sessionStorage.getItem('contract'))



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function ModalTimeAnalisys({ workarea, t, ui_list, element, is_workarea }) {

	const { contract, structureElements, roomtype_dict } = useContext(GlobalDataContext)
	const [showInterventionHourAnalisys, setShowEditInterventionHourAnalisys] = useState(false)

	//-----hour analisys form
	const handleShowInterventionHourAnalisys = () => {
		setShowEditInterventionHourAnalisys(true)
	}

	const handleCloseHourAnalisys = () => setShowEditInterventionHourAnalisys(false);



	const wd = ["1", "2", "3", "4", "5", "6", "0"]
	let service_present = {}
	let weekdays_summary = {
		0: { data: [], risk_area_summary: [] },
		1: { data: [], risk_area_summary: [] },
		2: { data: [], risk_area_summary: [] },
		3: { data: [], risk_area_summary: [] },
		4: { data: [], risk_area_summary: [] },
		5: { data: [], risk_area_summary: [] },
		6: { data: [], risk_area_summary: [] },
	}

	try {
		if (element.type === 'ordinary') {
			//element.room_list.map((r) => {
			for (const _room of element.room_list) {
				const room = structureElements.all_room_dict[_room.id]
				if (room){

				const roomtype = roomtype_dict[room.roomtype.id]
				const wd = element.custom_weekdays && element.custom_weekdays[room.id]
				if (roomtype) {
					roomtype.service_list.map(s => {
						if (service_present[s.service.id]) {
							service_present[s.service.id].size += getFloatNumber(room.size)
						} else {
							const service = ui_list.service_dict[s.service.id]
							service_present[s.service.id] = { ...service, size: room.size, yield: s.hourlyyield }
						}
						if (wd) {
							element.custom_weekdays[room.id].map(d => {
								weekdays_summary[d].data.push({ size: room.size, yeild: s.hourlyyield, value: room.size / getFloatNumber(s.hourlyyield) })
								if (weekdays_summary[d].risk_area_summary.indexOf(room.risk_area.Id) === -1) {
									weekdays_summary[d].risk_area_summary.push(room.risk_area.Id)
								}
							})

						} else {
							if (s.service && s.service.id === element.service.id) {
								s.weekdays.map(d => {
									weekdays_summary[d].data.push({ size: room.size, yeild: s.hourlyyield, value: room.size / getFloatNumber(s.hourlyyield) })
									if (weekdays_summary[d].risk_area_summary.indexOf(room.risk_area.Id) === -1) {
										weekdays_summary[d].risk_area_summary.push(room.risk_area.Id)
									}
								}
								)
							}

						}
					})
				}
				}
			}
			//			})

		} else {
			for (const _room of element.room_list) {
				const room = structureElements.all_room_dict[_room.id]
				//			element.room_list.map((r) => {
				if (room) {
					const roomtype = ui_list.roomtype_dict[room.roomtype.id]
					if (roomtype) {
						roomtype.service_list.map(s => {
							let s_count = 0
							if (s.service && (is_workarea || s.service.id === element.service.id)) {
								if (service_present[s.service.id]) {
									service_present[s.service.id].size += getFloatNumber(room.size)
									service_present[s.service.id].total_yield += (getFloatNumber(room.size) / getFloatNumber(s.hourlyyield))
									service_present[s.service.id].data.push({
										name: room.name,
										yield: s.hourlyyield,
										size: room.size,
										risk_area: room.risk_area,
										roomtype_name: ui_list.roomtype_dict[room.roomtype.id].name
									})
								} else {
									const service = ui_list.service_dict[s.service.id]
									//service_present[s.service.id] = { ...service, size: r.size, yield: s.hourlyyield }
									service_present[s.service.id] = {
										...service, size: getFloatNumber(room.size),
										data: [
											{ name: room.name, yield: s.hourlyyield, size: room.size, risk_area: room.risk_area, roomtype_name: ui_list.roomtype_dict[room.roomtype.id].name }],
										total_yield: (getFloatNumber(room.size) / getFloatNumber(s.hourlyyield))
									}
								}
								s_count += 1
							}
							return s_count
						})
					}
				}else{
					console.warn(element.name, _room, room)
				}
			}
			//			})

		}

	} catch (err) {
		console.log(err)
	}

	try {

		Object.keys(weekdays_summary).map((one_day) => {
			//console.log(weekdays_summary[one_day])
			if (weekdays_summary[one_day].data.length !== 0) {
				const b = weekdays_summary[one_day].data.reduce((a, b) => ({ value: a.value + b.value, size: getFloatNumber(a.size) + getFloatNumber(b.size) }));
				//console.log("---------------", b)
				weekdays_summary[one_day].total = b
			} else {
				weekdays_summary[one_day].total = { value: 0, size: 0 }
			}
		})
	} catch (err) { console.log(err) }
	//console.log(weekdays_summary)






	try {
		return (
			<>
				<Button variant="outline-primary" size="sm" onClick={() => handleShowInterventionHourAnalisys()} ><FontAwesomeIcon icon="clock" />{t('workarea.labels.hour_analisys')}</Button>

				<Modal show={showInterventionHourAnalisys} onHide={handleCloseHourAnalisys} size="xl" className="bg-secondary">
					<Modal.Header closeButton>
						<Modal.Title> {workarea ? <>{workarea.name} | </> : ''}{element.name}<span className="text-primary">&nbsp;<FontAwesomeIcon icon="clock" /> &nbsp;{t('workarea.labels.hour_analisys')} </span>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							{element.type === 'ordinary' ?
								<>
									<Row>
										<Col sm="3" className="h5 text-info">
											<FontAwesomeIcon icon="info-circle" className="text-info" />
											&nbsp;
											{t('workarea.labels.summary_weekdays')}
										</Col>
										<Col>
											<ServiceBadge service={ui_list.service_dict[element.service.id]} />
										</Col>
									</Row>
									<Row >
										<Col className="bg-secondary text-light ml-1">{t('global.labels.day')} </Col>
										<Col className="bg-secondary text-light ml-1">{t('global.labels.size')} <i>(m<sup>2</sup>)</i></Col>
										<Col className="bg-secondary text-light ml-1">{t('global.labels.room_count')} </Col>
										<Col className="bg-secondary text-light ml-1">
											{contract.type === 'civil' ?
												t('global.labels.standard_cleaning')
												:
												t('global.labels.risk_area')
											}
										</Col>
										<Col className="bg-secondary text-light ml-1">{t('structure.labels.hourlyield')}</Col>
									</Row>
									{wd.map((s, k) => (
										<OneDayRow key={k} s={s} weekdays_summary={weekdays_summary} ui_list={ui_list} t={t} />
									))}
								</>



								:


								<>
									{is_workarea ?
										<Row className="border-bottom mb-1 bg-light">
											<Col className="h5 text-info">
												<FontAwesomeIcon icon="info-circle" className="text-info" />
												&nbsp;
												{t('workarea.labels.summary_service')}
											</Col>
										</Row>
										: ''}
									<Row >
										<Col className="bg-secondary text-light ml-1" sm="4" >{t('global.labels.service')} </Col>
										<Col className="bg-secondary text-light ml-1" sm="1">{t('global.labels.size')} </Col>
										<Col className="bg-secondary text-light ml-1" sm="1">{t('global.labels.room_count')}</Col>
										<Col className="bg-secondary text-light ml-1 ">
											<Row>
												<Col className="text-center font-weight-bold">
													{t('global.labels.total')}
												</Col>
											</Row>
											<Row>
												<Col className="text-right">
													{t('global.labels.day')}
												</Col>
												<Col className="text-right">
													{t('global.labels.week')}
												</Col>
												<Col className="text-right">
													{t('global.labels.months')}
												</Col>
												<Col className="text-right">
													{t('global.labels.year')}
												</Col>
											</Row>
										</Col>
									</Row>
									{Object.keys(service_present).map((s, k) => (
										<Row key={k} className="border-bottom mb-1 pb-1">
											<Col sm="4" >
												<ServiceBadge service={ui_list.service_dict[s]} freq={is_workarea} />
											</Col>
											<Col sm="1" className="text-right">
												{service_present[s].size.toFixed(2)} <span className="text-muted">m<sup>2</sup></span>
											</Col>
											<Col sm="1" className="text-center">
												{service_present[s].data.length}
												&nbsp;
												<OverlayTrigger trigger={['hover', 'focus']} placement="right-start" overlay={renderRoomSummaryPopover(service_present[s].data, ui_list, t, contract.type)}>
													<FontAwesomeIcon icon="info-circle" />
												</OverlayTrigger>
											</Col>
											<Col>
												<Row>
													<Col className="text-right">
														{getTimeStr(service_present[s].total_yield * 60, t)}
													</Col>
													<Col className="text-right">
														{getTimeStr(service_present[s].total_yield * 60 * 7, t)}
													</Col>
													<Col className="text-right">
														{getTimeStr(service_present[s].total_yield * 60 * 7 * 4.34, t)}
													</Col>
													<Col className="text-right">
														{getTimeStr(service_present[s].total_yield * 60 * 7 * 4.34 * 12, t)}
													</Col>
												</Row>

											</Col>

										</Row>

									))}
								</>}

						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseHourAnalisys}>
							{t('modal.close')}
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		)
	} catch (err) {
		console.log(err)
		return ('')
	}
}



///////////////////////////////////////////////////////////////////////////////////////
const OneDayRow = ({ s, k, weekdays_summary, ui_list, t }) => {
	const { locale } = useContext(LocaleContext)

	const day = weekdays_summary[s]


	const getTotalSize = (t) => {
		try {
			return (t.size.toFixed(2))
		} catch (err) {
			return 0
		}
	}




	return (
		<Row className="element_row border-bottom">
			<Col>
				{format(setDay(new Date(), s), 'cccc', { locale: it })}
			</Col>
			<Col>
				{getTotalSize(day.total)}
			</Col>
			<Col>
				{day.data.length}
			</Col>
			<Col>
				{ui_list.risk_area_list.map((ri, rkey) => (
					(day.risk_area_summary.indexOf(ri.Id) !== -1 ?
						<span key={rkey} className="mr-1 border-secondary">
							<RiskareaBadge riskarea={ui_list.risk_area_dict[ri.Id]} />
						</span>
						: '')
				))}
			</Col>
			<Col>
				{getTimeStr(day.total.value * 60, t)}
			</Col>
		</Row>
	)
}


const renderRoomSummaryPopover = (room_data, ui_list, t, contract_type) => {

	return (
		<Popover id="popover-basic" className="bg-info border border-info">
			<Popover.Title as="h3">
				{t('workarea.labels.room_summary')}
			</Popover.Title>
			<Popover.Content>
				{room_data ?
					<>
						<ListGroup variant="flush" >
							<ListGroup.Item key="a" variant="info">
								<Container fluid >
									<Row >
										<Col className="p-1">
											{t('global.labels.name')}
										</Col>
										<Col className="p-1 text-center" sm="2">
											m<sup>2</sup>
										</Col>
										<Col className="p-1 text-center" sm="2">
											m<sup>2</sup> / h
										</Col>
										<Col className="p-1 text-center" sm="2">
											min
										</Col>
										<Col className="p-1 text-center" sm="2">
											{contract_type === 'civil' ?
												t('global.labels.standard_cleaning_short')
												:
												t('global.labels.risk_area_short')
											}
										</Col>
									</Row>
								</Container>
							</ListGroup.Item>
							{room_data.map((r, k) => (
								<ListGroup.Item key={k} action variant="light" className="p-1">
									<Container fluid>

										<Row className="p-0">
											<Col className="p-1 text-dark ">
												<b>{r.name}</b>&nbsp;<i>{r.roomtype_name}</i>
											</Col>
											<Col className="p-1 text-center" sm="2">
												{r.size}
											</Col>
											<Col className="p-1 text-center" sm="2">
												{r.yield}
											</Col>
											<Col className="p-1 text-center" sm="2">
												{(getFloatNumber(r.size) / getFloatNumber(r.yield) * 60).toFixed()}
											</Col>
											<Col className="p-1 text-center" sm="2">
												<RiskareaBadge riskarea={ui_list.risk_area_dict[r.risk_area.Id]} />
											</Col>

										</Row>
									</Container>
								</ListGroup.Item>
							))}
						</ListGroup>
					</>
					: ''
				}
			</Popover.Content>
		</Popover>

	)
}









export default translate()(ModalTimeAnalisys)