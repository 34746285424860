import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Spinner, ButtonGroup, Badge, Card } from 'react-bootstrap';


import { ModalInitButton } from '../StructureGraphicElements.js'

import { getSelectedElement, checkComponentPresence, compareName, getValue } from "../../util/ui_utils.js"


import { getMessageGroupList } from '../../Firebase2/Storage2/dbMessageGroup';


let preload = false

////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalUserMessageGroup = ({ item, setItem, t, is_new, item_list, canWrite, param_name, index, domain, contract_list }) => {

    const newmessagegroup = {
        contract_id: -1,
        message_group_id: -1,
        message_group_name: '',
        send_mail_notification: false
    }


    const [msggroup_list, setMsgGroupList] = useState([])

    const [showMessagegroupEdit, setShowMessagegroupEdit] = useState(false)
    const [curMessagegroup, setCurMsgGroup] = useState(newmessagegroup)
    const [editedElement, setEditedElement] = useState(false)
    const [loading, setLoading] = useState(false)




    const onChangeContract = async (el) => {
        console.log("contractid, preload", domain, el, preload )
        setLoading(true)
        if (preload) {
            console.log("USE PRELOAD")
//            setCurMsgGroup({ ...curMessagegroup, contract_id: el.value })
        } else {
            setCurMsgGroup({ ...curMessagegroup, contract_id: el.value, message_group_id: -1 })
        }

            preload = false
        let return_data = await getMessageGroupList(domain, el.value)
        if (return_data && return_data.error) {
            console.log(return_data)
            //				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
        } else {
            const _msggroup_list = return_data.sort(compareName)
            setMsgGroupList(_msggroup_list)
        }
        setLoading(false)
    }

/* 


    useEffect(() => {
        console.log("UPDATE contract contract group: preload", preload)
        async function preloadNext() {
            const ret_data = await onChangeContract({ value: item.contract_id })
            if (item.message_group_id === -1) {
                console.log("END LOAD INITIAL DATA contract")
                preload = false
            }
        }
        if (preload && showMessagegroupEdit) {
            preloadNext()
        }

    }, [curMessagegroup.contract_id]) */





    const handleCloseMessagegroup = () => setShowMessagegroupEdit(false);
    const handleSaveMessagegroup = async () => {
        if (is_new) {
            let newmessagegroups = item_list || []
            newmessagegroups.push(curMessagegroup)
            console.log(newmessagegroups)
            setItem({ name: param_name, value: newmessagegroups })
        } else {
            let editlist = item_list
            //            const c = checkComponentPresence(curMessagegroup, element.messagegroups)
            editlist.splice(index, 1, curMessagegroup)
            setItem({ name: param_name, value: editlist })
        }
        setShowMessagegroupEdit(false);
        setEditedElement(false)
    }



    const handleShowNew = () => {
        preload = false
        setCurMsgGroup(newmessagegroup)
        setMsgGroupList([])
        setShowMessagegroupEdit(true)
        setEditedElement(false)
    }

    const handleShowEdit = () => {
        preload = true
        setCurMsgGroup(item)
        setShowMessagegroupEdit(true)
        setEditedElement(false)
        async function preloadNext() {
            const ret_data = await onChangeContract({ value: item.contract_id })
            if (item.message_group_id === -1) {
                console.log("END LOAD INITIAL Edited DATA ")
                preload = false
            }
        }
        if (preload) {
            preloadNext()
        }
    }





    const onChangeHandlerMessagegroup = (el) => {
        let { name, value, type } = el
        let sub = undefined;
        try {
            sub = el.getAttribute('sub') || el.sub
        } catch (err) { }
        console.log(name, value, type, sub)
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = curMessagegroup[name]
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        if (name == 'message_group_id') {
            setCurMsgGroup({ ...curMessagegroup, [name]: value, message_group_name: getValue(getSelectedElement(value, msggroup_list, 'id'), 'name') })
        } else {

            setCurMsgGroup({ ...curMessagegroup, [name]: value })
        }
        setEditedElement(true)
    }



    return (
        <>

            <ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={false} title_new={param_name === "messagegroups" ? t('user.labels.add_message_group') : t('user.labels.add_sendmessage_group')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
            <Modal show={showMessagegroupEdit} onHide={handleCloseMessagegroup} className="bg-secondary" size="xl">
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
                    <Modal.Title>
                        {is_new ?
                            <>
                                <FontAwesomeIcon icon="plus-square" />
                                <span className='text-primary'>{t('users.modal.title_newmessagegroup')} </span>
                            </>
                            : <>
                                <FontAwesomeIcon icon="pencil-alt" />
                                <span className="text-info">{t('users.modal.editmessagegroup')} </span>
                            </>
                        }
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Group as={Row} controlId="formEquipmentBrand" className="border-bottom">
                            <Form.Label column sm="2">{t('global.labels.contract')}</Form.Label>
                            <Col sm="8">
                                <Form.Control as="select" value={curMessagegroup.contract_id} name="message_id" className="mb-2" onChange={(event) => onChangeContract({ name: 'contract_id', type: 'select', value: event.target.value })}  >
                                    <option value="-1" >{t('contract.labels.select_contract')}</option>
                                    {contract_list.map((f, k) => (
                                        <option key={k} value={f.id} >{f.name}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        {curMessagegroup.contract_id !== -1 ?
                            <>
                                <Form.Group as={Row} controlId="userGroups" className="pb-1">
                                    <Form.Label column sm="2">{t('users.labels.messagegroup')}
                                        {loading ? <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner> : ''}</Form.Label>
                                    <Col sm="3">
                                        {msggroup_list.length > 0 ?
                                            <Form.Control as="select" className="mb-2" name="message_group_id" value={curMessagegroup.message_group_id} onChange={(event) => onChangeHandlerMessagegroup(event.currentTarget)} >
                                                <option value="-1" >{t('users.labels.select_group')}</option>
                                                {msggroup_list
                                                    ? [msggroup_list.map((f, k) => (
                                                        <option key={k} value={f.id} >{f.name}</option>
                                                    ))]
                                                    : ''
                                                }
                                            </Form.Control>
                                            : <i>{t('user.messages.no_messagegroup')}</i>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="userGroups" className="pb-1">
                                <Form.Label column sm="2">{t('user.labels.send_mail_notification')}</Form.Label>
                                <Col sm="2">
                                    <Form.Check type="radio" disabled name="send_mail_notification" value={true} checked={curMessagegroup.send_mail_notification} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerMessagegroup(event.currentTarget)} />
                                    <Form.Check type="radio" disabled name="send_mail_notification" value={false} checked={!curMessagegroup.send_mail_notification} label={t('global.labels.no')} onChange={(event) => onChangeHandlerMessagegroup(event.currentTarget)} />
                                    <Form.Text className="text-muted">
                                        {t('user.labels.send_mail_notification_explained')}
                                    </Form.Text>
                                </Col>
                                </Form.Group>
                            </> : ''}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMessagegroup}>
                        {t('modal.close')}
                    </Button>
                    <Button disabled={curMessagegroup.message_group_id === -1} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveMessagegroup}>
                        <FontAwesomeIcon icon="save" /> {t('modal.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}