import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addResultQuestion = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('resultquestions')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addResultQuestion error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const getResultQuestionById = async (domainId, contractId, resultquestionId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('resultquestions')
            .get(resultquestionId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getResultQuestion error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getResultQuestionList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('resultquestions')
            .get();

        const resultquestionList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        
        return resultquestionList;
    } catch (e) {
        console.log("getResultQuestionList error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const editResultQuestion = async (domainId, contractId, resultquestionId, data) => {
    try {
        const resultquestion =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('resultquestions')
            .doc(resultquestionId);

        const res = await resultquestion.set(data)
            .then( () => {
                console.log("editResultQuestion - edit successfully")
                return data
            }).catch( error => {
                console.log("editResultQuestion - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editResultQuestion error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteResultQuestion = async (domainId, contractId, resultquestionId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('resultquestions')
            .doc(resultquestionId).delete();
        return res
    }  catch (e) {
        console.log("deleteResultQuestion error: ", e)
        return { error: e }
    }
}





export const addProcessQuestion = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('processquestions')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addProcessQuestion error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const getProcessQuestionById = async (domainId, contractId, processquestionId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('processquestions')
            .get(processquestionId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getProcessQuestion error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getProcessQuestionList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('processquestions')
            .get();

        const processquestionList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        
        return processquestionList;
    } catch (e) {
        console.log("getProcessQuestionList error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const editProcessQuestion = async (domainId, contractId, processquestionId, data) => {
    try {
        const processquestion =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('processquestions')
            .doc(processquestionId);

        const res = await processquestion.set(data)
            .then( () => {
                console.log("editProcessQuestion - edit successfully")
                return data
            }).catch( error => {
                console.log("editProcessQuestion - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editProcessQuestion error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteProcessQuestion = async (domainId, contractId, processquestionId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('processquestions')
            .doc(processquestionId).delete();
        return res
    }  catch (e) {
        console.log("deleteProcessQuestion error: ", e)
        return { error: e }
    }
}




