import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { translate, useTranslate } from 'react-polyglot'


const SearchSection = ({ searchTerm, setSearchTerm }) => {
    const t = useTranslate()
    const [searchInput, setSearchInput] = useState('')
    const handleChangeSearch = (s) => {
        setSearchInput(s.value)
        console.log(s)
        if (s.value.length > 1) {
            setSearchTerm(s.value)
        } else {
            setSearchTerm('')
        }
    }

    const handleResetSearch = () =>{
            setSearchTerm('')
            setSearchInput('')

    }

    return (
        <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <Button disabled variant="outline-secondary"><FontAwesomeIcon icon="search" /></Button>
            </InputGroup.Prepend>
            <FormControl type="text" value={searchInput} placeholder={t('search')} onChange={(event) => handleChangeSearch(event.target)} />
            {searchTerm.length > 0 ?
                <InputGroup.Append>
                    <Button variant="outline-secondary" onClick={() => handleResetSearch()}><FontAwesomeIcon icon="times" /></Button>
                </InputGroup.Append>
                : ''}
        </InputGroup>
    )
}
export default translate()(SearchSection)
